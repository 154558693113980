import store from "../redux/store";


export const COL_PREF = "cp-"
export const getColumns = (screenId, view) => {
    const identityId = COL_PREF + store.getState()?.identity?.data?.identityId
    let initColumns = JSON.parse(localStorage.getItem(identityId));
    if(!!view){
        if(!!initColumns && !!initColumns[screenId])
            return initColumns[screenId][view];
        else 
            return {};
    }
    if(!!initColumns && !!initColumns[screenId])
        return initColumns[screenId];

    return {};
} 

export const setColumns = (screenId, columns, view) => {
    const identityId = COL_PREF + store.getState()?.identity?.data?.identityId
    let initColumns = JSON.parse(localStorage.getItem(identityId));

    if(!!view){
        initColumns = {
            ...initColumns,
            [screenId]:{
                ...!!initColumns?initColumns[screenId]??{}:{},
                [view]: {...(!!initColumns?!!initColumns[screenId]?initColumns[screenId][view]??{}:{}:{}), ...columns}}
            }
        localStorage.setItem(identityId, JSON.stringify(initColumns));
    }
    else{
        localStorage.setItem(identityId, JSON.stringify({...initColumns,[screenId]:columns}));
    }
}

export const cmpCol = (initCol, strCol) => {
    let cols = {};

    Object.keys(initCol).map(col => {
        cols[col] = (strCol==null) ? initCol[col] : (strCol[col]??initCol[col]);
    })

    return cols
}

export const setViewType = (screenId, view) => {
    const identityId = COL_PREF + store.getState()?.identity?.data?.identityId
    let initColumns = JSON.parse(localStorage.getItem(identityId));

    if(!!initColumns && !!initColumns[screenId]){
        initColumns = {
            ...initColumns,
            [screenId]:{
                ...initColumns[screenId],
                viewType: view
            }
        }
    }
    else {
        initColumns = {
            ...initColumns,
            [screenId]:{
                viewType: view
            }
        }
    }

    localStorage.setItem(identityId, JSON.stringify(initColumns));

}

export const getViewType = (screenId) => {
    const identityId = COL_PREF + store.getState()?.identity?.data?.identityId
    let initColumns = JSON.parse(localStorage.getItem(identityId));

    if(!!initColumns && !!initColumns[screenId]){
        return initColumns[screenId].viewType
    }

    return null;
}