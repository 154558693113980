import { Layer, Line } from "react-konva";

let arr = [];
for (let i = -10000; i < 10000; i += 100) {
  if (i === 0)
    continue;
  arr.push(i);
}

const Axes = () => {
  return (
    <Layer>
      {arr.map(item => {
        return (
          <>
            <Line points={[-10000, item, 10000, item]} stroke={"lightgrey"} strokeScaleEnabled={false} />
            <Line points={[item, 10000, item, -10000]} stroke={"lightgrey"} strokeScaleEnabled={false} />
          </>
        );
      })}
      <Line points={[-10000, 0, 10000, 0]} stroke={"lightgrey"} strokeScaleEnabled={false} />
      <Line points={[0, 10000, 0, -10000]} stroke={"lightgrey"} strokeScaleEnabled={false} />
    </Layer>
  );
};

Axes.propTypes = {};
Axes.defaultProps = {};

export default Axes;