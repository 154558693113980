/***
 *
 * Controller class for user.
 * @file PAdmin.js
 * @description PAdmin component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import React, { useEffect } from "react";
// import PropTypes from 'prop-types';
import "./PAdmin.scss";
import Header from "../_builder/Header";
import Select from "react-select"
import { Toggle } from "../../../components";
import { useSelector } from "react-redux";
import { VENUE_STATE, VENUE_STATE_MAP } from "../../../utility/constants";
import REDUX_WORKER from "../../../redux/workers";
import store from "../../../redux/store";
import { activeVenueActions, breadcrumbActions } from "../../../redux/slices";
import createRequest, { services } from "../../../services";
import { useState } from "react";
import { make_custom_toast } from "../../../helpers/toasts";
import { CatchedWebError } from "../../../configs";
import { Input, Button, FormFeedback } from "reactstrap";


export const UpdateVenueState = (setError, venue, state) => {

  let submitData = { state: Number(state) }
  // if ([VENUE_STATE.DISABLED].includes(Number(state))) {
  //   if (reason.length < 1) {
  //     setError("Required")
  //     return;
  //   }
  //   submitData.reason = reason;
  // }
  const { context, run } = createRequest(services.venue.EDIT, [venue.venueId], submitData);

  run()
    .then(() => {
      make_custom_toast("success", "Venue Updated", "Venue State Updated Successfully!");
      store.dispatch(activeVenueActions.setActiveVenue({ ...venue, state: Number(state) }));
    })
    .catch(err => {
      make_custom_toast("error", "Venue Update Failed", (new CatchedWebError(err)).message, true);
    })
}


const PAdmin = () => {
  const venue = useSelector(store => store.activeVenue.data);
  const [fwUpdate, setFwUpdate] = useState(true);
  // const [reason, setReason] = useState(venue.reason);
  const [state, setState] = useState(venue.state);
  const [error, setError] = useState(null);
  const venuecrumb = useSelector(store => store.breadcrumb.venuecrumb);
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);

  const updateFW = () => {
    const { run } = createRequest(services.fwupgrade.UPDATE_VENUE_FW, [venue.venueId, !fwUpdate])

    run()
      .then(() => {
        make_custom_toast("success", "Firmware", "Firmware settings updated");
        setFwUpdate(prevState => !prevState)
      })
  }

  useEffect(() => {
    const { run } = createRequest(services.fwupgrade.GET_VENUE_SCHEDULE, [venue.venueId]);
    run().then(response => {
      setFwUpdate(response.data.enabledFirmware)
    })

    if (venuecrumb.venueId !== venue.venueId)
      REDUX_WORKER.getVenueParent(venue.venueId, activeOrgId);

    store.dispatch(breadcrumbActions.setData([...venuecrumb.parentPath,
    {
      text: venue?.venueName,
      active: false,
    },
    {
      path: `/organization/${activeOrgId}/venues/${venue?.venueId}/padmin`,
      text: "P-Admin",
      active: true,
    }
    ]))

  }, [venue, venuecrumb]);

  return (
    <div className="PAdmin" data-testid="PAdmin">
      <Header />
      <div className="rounded bg-white p-1 pb-3 mt-1 shadow-sm full-height">
        <Toggle value={fwUpdate} label="Enable Firmware Update" onClick={() => {
          updateFW(fwUpdate)
        }} />

        {/* <hr /> */}

        {/* <div className="mt-50" style={{ width: "50%" }}>
          <div>State<span className="text-danger">*</span></div>
          <Select
            options={VENUE_STATE_MAP}
            defaultValue={VENUE_STATE_MAP.find(it => it.value == state)}
            onChange={(e) => {
              setState(e.value);
            }}
          />
        </div> */}
        {/* {([VENUE_STATE.DISABLED].includes(Number(state))) ? <div className="mt-50" style={{ width: "50%" }}>
          <div>Reason<span className="text-danger">*</span></div>
          <Input
            value={reason}
            invalid={error?.length > 0}
            onChange={(e) => {
              if (e.target.value.length > 0)
                setError(null)
              setReason(e.target.value);
            }}
          />
          <FormFeedback>{error}</FormFeedback>
        </div> : <></>} */}
        {/* <div className="mt-1 w-50 ">
          <Button.Ripple color="primary" className="small-add-button" onClick={() => UpdateVenueState(setError, venue, state)}>Submit</Button.Ripple>
        </div> */}
      </div>
    </div>
  );
};

PAdmin.propTypes = {};

PAdmin.defaultProps = {};

export default PAdmin;
