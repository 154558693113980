import { v4 as uuidv4 } from 'uuid';
import { checkInfraType } from './helper';
const ch = require("cnvx");

/**
 * Convert date string to local date
 * @param {string} dateString - date string
 * @returns Local Date String
 */
export function getFormattedDate(dateString) {
  const date = new Date(dateString);
  return date.toLocaleDateString();
}

/**
 * Convert math coordinates to graph coordinates
 * @param {Point} point - Math Coorindates
 * @returns {{x: number, y: number}} - Graph Coordinates
 */
export function $g(point) {
  return { x: point.x, y: -point.y };
}

/**
 * Convert graph coorindates to math coordinates
 * @param {{x: number, y: number}} graph_coord - Graph Coordinates
 * @returns {Point} - Math Coordinates
 */
export function $r(graph_coord) {
  return new Point(graph_coord.x, -graph_coord.y);
}

/**
 * Convert degrees to radians
 * @param {number} angleInDegrees 
 * @returns {number} Radians
 */
function toRadians(angleInDegrees) {
  return (angleInDegrees * Math.PI) / 180;
}

/**
 * Get the pivot point from the given list of `Wall`s.
 * @param {Array<Wall>} walls 
 * @returns {Point} Pivot Point
 */
function getPivotFromWalls(walls) {
  let minX = walls[0].from.x, minY = walls[0].from.y, maxX = walls[0].from.x, maxY = walls[0].from.y;
  for (const wall of walls) {
    minX = Math.min(minX, wall.from.x, wall.to.x);
    minY = Math.min(minY, wall.from.y, wall.to.y);
    maxX = Math.max(maxX, wall.from.x, wall.to.x);
    maxY = Math.max(maxY, wall.from.y, wall.to.y);
  }
  return new Point((minX + maxX) / 2, (minY + maxY) / 2);
}

/**
 * Point Representation
 */
export class Point {
  constructor(x, y) {
    /**
     * X Coordinate
     * @type number
     */
    this.x = x;

    /**
     * Y Coordinate
     * @type number
     */
    this.y = y;
  }

  /**
   * Return a new `Point` translated by the given amount
   * @param {number} x 
   * @param {number} y 
   * @returns {Point} Translated Point
   */
  translated(x, y) {
    return new Point(this.x + x, this.y + y);
  }

  /**
   * Translate and return the current `Point` in-place
   * @param {number} x 
   * @param {number} y 
   * @returns {Point} the current object (for chaining)
   */
  translate(x, y) {
    this.x += x;
    this.y += y;
    return this;
  }

  /**
   * Return a new `Point` translated by the given amount
   * @param {number} angleInDegrees 
   * @returns {Point} Rotated Point
   */
  rotated(angleInDegrees) {
    const angleInRadians = toRadians(angleInDegrees);
    return new Point(
      Math.cos(angleInRadians) * this.x - Math.sin(angleInRadians) * this.y,
      Math.sin(angleInRadians) * this.x + Math.cos(angleInRadians) * this.y
    );
  }

  /**
   * Rotate and return the current `Point` in-place
   * @param {number} angleInDegrees 
   * @returns {Point} the current object (for chaining)
   */
  rotate(angleInDegrees) {
    const angleInRadians = toRadians(angleInDegrees);
    const cos = Math.cos(angleInRadians);
    const sin = Math.sin(angleInRadians);
    const x = cos * this.x - sin * this.y;
    const y = sin * this.x + cos * this.y;
    this.x = x;
    this.y = y;
    return this;
  }

  /**
   * Return a new `Point` rotated about another point by the given amount
   * @param {Point} point 
   * @param {number} angleInDegrees 
   * @returns {Point} Rotated Point
   */
  rotatedAbout(point, angleInDegrees) {
    return this.translated(-point.x, -point.y).rotated(angleInDegrees).translated(point.x, point.y);
  }

  /**
   * Rotate and return the current `Point` in-place about another point by given amount
   * @param {Point} point 
   * @param {number} angleInDegrees 
   * @returns {Point} the current object (for chaining)
   */
  rotateAbout(point, angleInDegrees) {
    return this.translate(-point.x, -point.y).rotate(angleInDegrees).translate(point.x, point.y);
  }
}

export class Transform {
  constructor(position = new Point(0, 0)) {
    this.rotation = 0;
    this.position = position;
  }
}

export class Wall {
  /**
   * Wall Constructor
   * @param {{
   *   id: string,
   *   loc: Array<number>,
   *   material: string
   * }} rawWallObject 
   */
  constructor(rawWallObject) {
    this.id = rawWallObject.id;
    this.from = new Point(rawWallObject.loc[0], rawWallObject.loc[1]);
    this.to = new Point(rawWallObject.loc[2], rawWallObject.loc[3]);
    this.material = rawWallObject.material;
  }

  /**
   * Creates raw wall data
   * @returns raw wall data
   */
  toRawWall(newFrom, newTo) {
    return {
      id: this.id,
      loc: [newFrom.x, newFrom.y, newTo.x, newTo.y],
      material: this.material
    };
  }

  /**
   * Creates raw wall data after applying given transform about a given pivot (which also acts as initial position)
   * @param {Transform} transform 
   * @param {Point} pivot 
   */
  toRawWallWithTransformation(pivot, movedBy, rotatedBy) {
    // I wrote the following logic but forgot why it works (so please don't remove the following line)
    const originAfterRotation = (new Point(0, 0)).rotatedAbout(pivot, rotatedBy);

    const newFrom = this.from.rotatedAbout(pivot, rotatedBy).translated(movedBy.x - originAfterRotation.x, movedBy.y - originAfterRotation.y);
    const newTo = this.to.rotatedAbout(pivot, rotatedBy).translated(movedBy.x - originAfterRotation.x, movedBy.y - originAfterRotation.y);

    return this.toRawWall(newFrom, newTo);
  }
}

export class LayoutData {
  /**
   * LayoutData constructor
   * @param {{
   *   dimensions: {
   *     length: number?,
   *     width: number?,
   *     height: number?,
   *     area: number?
   *   }?,
   * imageLink:string,
   * layoutType:number,
   *   walls: Array<{
   *     id: string,
   *     loc: Array<number>,
   *     material: string
   *   }>
   * }} rawLayoutData 
   */
  constructor(layout, point, imageLink = '', layoutType, component) {
    // rawLayoutData is layoutJson
    const rawLayoutData = layout.layoutJson
    this.dimensions = rawLayoutData.dimensions;


    // old code addedbyrd
    // let allWalls = []
    // for (const i in rawLayoutData.components) {
    //   const eachComponentWalls = rawLayoutData.components[i].walls.map(wall => new Wall(wall));
    //   allWalls.push(...eachComponentWalls)
    // }
    let eachComponentWalls = []
    if (component) {
      eachComponentWalls = component.walls.map(wall => new Wall(wall));
    }


    // hinthere for adding walls
    this.components = rawLayoutData.components
    this.walls = eachComponentWalls;
    this.layoutType = layoutType
    this.imageLink = imageLink;
    this.pivot = getPivotFromWalls(eachComponentWalls);
    this.transform = new Transform(point);
    this.addedLayoutId = layout.id
  }

  /** Bakes transform into the Layout Data
   * @returns Raw Layout Data
   */
  toRawLayoutData() {
    const movedBy = new Point(this.transform.position.x, this.transform.position.y);
    const rotatedBy = this.transform.rotation;

    return {
      addedLayoutId: this.addedLayoutId,
      dimensions: this.dimensions,
      components: this.components,
      imageLink: this.imageLink,
      layoutType: this.layoutType,
      removedApList: this.removedApList,
      removedCableDropList: this.removedCableDropList,
      walls: this.walls.map(wall => wall.toRawWallWithTransformation(this.pivot, movedBy, rotatedBy))
    };
  }
}

export class EditableLayout {

  /**
   * @param {string} name
   * @param {Array<{
   *   venueId: number,
   *   name: string,
   *   layoutType: 1 | 2 | 3,
   *   layoutFile: string,
   *   infraPositions: Array<{
   *     infra_type_id: number,
   *  location:{
   *     x: number,
   *     y: number
   *    }
   *   }>?,
   *   layoutJson: {
   *     dimensions: {
   *       length: number?,
   *       width: number?,
   *       height: number?,
   *       area: number?
   *     }?,
   *     walls: Array<{
   *       id: string,
   *       loc: Array<number>,
   *       material: string
   *     }>
   *   },
   *   isActive: boolean,
   *   id: number,
   *   createdAt: string,
   *   updatedAt: string
   * }>?} rawLayouts 
   */


  constructor(rawLayouts) {
    /**
     * Infra Positions
     * @type {Object.<string, { infra_type_id: number, x: number, y: number }>}
     */
    let ifpos = {};
    if (rawLayouts && rawLayouts.length > 0 && rawLayouts[0].infraPositions) {
      for (const infraPos of rawLayouts[0].infraPositions) {
        const newUuid = uuidv4()
        ifpos[newUuid] = infraPos;
        ifpos[newUuid].infraCategory = checkInfraType(infraPos.infraTypeId)
      }
    }

    // add cable drop from response of layout here, just like infraList

    let cableDrop = {}
    if (rawLayouts && rawLayouts.length > 0 && rawLayouts[0].cableDrop) {
      for (const cable of rawLayouts[0].cableDrop) {
        cableDrop[uuidv4()] = cable;
      }
    }

    // console.log('rdebug switch infralist:', ifpos)
    this.infraPositions = ifpos;
    this.cableDropList = cableDrop
    this.defaultName = (rawLayouts && rawLayouts.length > 0 && rawLayouts[0].name) || "";
    this.removedApList = []
    this.removedCableDropList = []
    this.drawnLines = []

    /**
     * Layouts Data
     * @type {Object.<string, LayoutData>}
     */

    // hinthere layouts are made here which are shown in canvas, with editableLayout.layouts, when added from side menu to canvas its added in this list
    let layouts = {};
    let componentsList = []
    if (rawLayouts && rawLayouts[0]?.layoutJson) {
      componentsList = rawLayouts[0].layoutJson.components
      for (const component of componentsList)
        for (const layout of rawLayouts) {
          layouts[uuidv4()] = new LayoutData(layout, undefined, layout.imageLayoutLink, layout.layoutType, component);
        }
    }
    this.layouts = layouts;
  }

  /**
   * Creates deep copy of the current object
   * @returns {EditableLayout}
   */
  copy() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }



  /**
   * Creates deep copy of the current object without Infra
   * @returns {EditableLayout}
   */
  copyWithoutInfra() {
    const x = this.copy();
    x.infraPositions = {};
    return x;
  }

  /**
   * Get merged data
   * @returns {{
   *   infraPositions: Array<{
   *     infra_type_id: number,
   *     x: number,
   *     y: number
   *   }>,
   *   layoutJson: {
   *     dimensions: {
   *       length: number?,
   *       width: number?,
   *       height: number?,
   *       area: number?
   *     }?,
   *     layoutJson: {
   *       dimensions: {
   *       length: number?,
   *       width: number?,
   *       height: number?,
   *       area: number?
   *      }?,
   *      walls: Array<{
   *       id: string,
   *       loc: Array<number>,
   *       material: string
   *      }>
   *     }
   *   }
   * }}
   */
  getMergedLayouts() {
    let walls = [];
    let infraPositions = [];
    let cableDrop = []
    let area = 0;
    let components = []
    let addedLayoutIdList = []

    let tempRemovedApList = this.removedApList
    let tempRemovedCableDropList = this.removedCableDropList

    for (const uuid in this.layouts) {
      let rawLayoutData = this.layouts[uuid].toRawLayoutData();
      // hinthere all layouts get merged here, so make components of all layouts in one single list

      // components.push(...rawLayoutData.components)



      addedLayoutIdList.push(rawLayoutData.addedLayoutId)

      const singleComponentData = {
        id: uuid,
        walls:
          rawLayoutData.walls.map((wall, index) => ({
            ...wall,
            id: `${uuid}-${index}`
          }))

      }
      components.push(singleComponentData)


      area += (rawLayoutData.dimensions?.area ?? 0);
      walls = walls.concat(rawLayoutData.walls.map((wall, index) => ({
        ...wall,
        id: `${uuid}-${index}`
      })))
    }

    for (const uuid in this.infraPositions) {
      const singleInfraPosition = this.infraPositions[uuid]
      //  {
      //   infra_type_id: this.infraPositions[uuid].infra_type_id,
      //   location: {
      //     x:.x,
      //     y: this.infraPositions[uuid].y
      //   }
      // }
      infraPositions.push(singleInfraPosition)

    }

    for (const uuid in this.cableDropList) {
      const singleCableDrop = this.cableDropList[uuid]
      //  {
      //   infra_type_id: this.infraPositions[uuid].infra_type_id,
      //   location: {
      //     x:.x,
      //     y: this.infraPositions[uuid].y
      //   }
      // }
      cableDrop.push(singleCableDrop)

    }

    return {
      addedLayoutId: addedLayoutIdList,
      infraPositions: infraPositions,
      cableDrop: cableDrop,
      removedApList: tempRemovedApList,
      removedCableDropList: tempRemovedCableDropList,
      layoutJson: {
        dimensions: {
          length: 0,
          width: 0,
          height: 0,
          area: area
        },
        components: components
      }
    };
  }
  clearRemovedList() {
    this.removedApList = []
    this.removedCableDropList = []
  }
}

/**
 * Ported ConvexHull Function Compatible with new `LayoutData`
 * @param {LayoutData} layout 
 * @returns 
 */
export function convexHull(layout) {
  let points = [];
  for (const wall of layout.walls) {
    points.push([wall.from.x, wall.from.y]);
    points.push([wall.to.x, wall.to.y]);
  }
  const chInputPoints = [];
  for (const point of points) {
    chInputPoints.push({ x: point[0], y: point[1] });
  }
  const hullPoints = ch(chInputPoints);
  let hull = [];
  for (const l of hullPoints) {
    hull.push(l.x);
    hull.push(l.y);
  }
  return hull;
}

