/***
 *
 * Controller class for user.
 * @file SearchIconAddon.js
 * @description SearchIconAddon component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React from "react";
// import PropTypes from 'prop-types';
import "./SearchIconAddon.scss";
import { InputGroupText } from "reactstrap";
import { SearchIcon } from "../../assets/images/icons/Icons";

const SearchIconAddon = () => {
  return (
    <InputGroupText style={{ borderLeft: "0", borderTopLeftRadius: "0", borderBottomLeftRadius: "0" }}>
      <SearchIcon width={14} height={14} style={{}} />
    </InputGroupText>
  );
};

SearchIconAddon.propTypes = {};

SearchIconAddon.defaultProps = {};

export default SearchIconAddon;
