/***
 *
 * Controller class for user.
 * @file Header.js
 * @description Header component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React from "react";
// import PropTypes from 'prop-types';
import "./Header.scss";

const Header = (props) => {
  return (
    <div className="Header" data-testid="Header">
      <div className="header-inner d-flex align-items-center">
        {props.header} {props.icon}
      </div>
    </div>
  );
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
