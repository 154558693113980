import { createSlice } from "@reduxjs/toolkit";

const initialSubOrgs = {
  status: {
    loading: false,
    error: null,
    isOutdated: true,
  },
  data: [],
  orgId: null,
}

const suborgsSlice = createSlice({
  name: "suborgs",
  initialState: {...initialSubOrgs},
  reducers: {
    setIsOutdated: (state, action) => {
      state.status.isOutdated = action.payload;
    },
    setLoading: (state, action) => {
      state.status.loading = action.payload;
    },
    setOrgId: (state, action) => {
      state.orgId = action.payload;
    },
    setError: (state, action) => {
      state.status.error = action.payload;
    },
    addSubOrgs: (state, action) => {
      if (state.data === null) {
        state.data = [];
      }
      const { data, offset } = action.payload;
      if(state.data.length < 20)
        state.data = data
      else
        for (let orgPos = 0; orgPos < data.length; orgPos++) {
          state.data[orgPos + offset] = data[orgPos];
        }
    },
    setSubOrgs: (state, action) => {
      state.data = [...action.payload];
    },
    resetSubOrgs: (state) => {
      return {
        status: {
          loading: false,
          error: null,
          isOutdated: true,
        },
        data: [],
        orgId: null,
      };
    }
  }
});

export const suborgsReducer = suborgsSlice.reducer;
const suborgsActions = suborgsSlice.actions;
export default suborgsActions;