import PropTypes from "prop-types";

const SnappingIcon = ({svgProps, color}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none" {...svgProps}>
    <path d="M7.76799 26.8325C6.42884 26.8325 5.34375 25.7135 5.34375 24.3325C5.34375 22.9515 6.42884 21.8325 7.76799 21.8325H10.1922V21.2285" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.7083 7.83203H26.7083V26.832H7.81282C6.46688 26.832 5.375 25.706 5.375 24.318V7.83203" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.375 7.83203C5.375 6.45103 6.46009 5.33203 7.79924 5.33203H10.2235V8.14403" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.2188 21.332V7.83203" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21.0272 22.832H23.8151V11.832H13.1484V22.832H17.9969V19.789C20.7441 19.789 21.0272 22.832 21.0272 22.832Z" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.1484 16.832H15.5727" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.9387 12.332V16.832H17.7266" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M23.7992 16.832H21.375" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

SnappingIcon.propTypes = {
  svgProps: PropTypes.object,
  color: PropTypes.string
}
SnappingIcon.defaultProps = {
  svgProps: {
    width: "32",
    height: "32",
  },
  color: "#323232"
}

export default SnappingIcon;