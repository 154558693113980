import { useState } from "react";
import "../NewDashboard/NDashboard.scss";
import { ReactComponent as TrendDown } from "../../../assets/images/icons/trendDown.svg"
import { ReactComponent as TrendUp } from "../../../assets/images/icons/trendUp.svg"
import { ReactComponent as ChevDown } from "../../../assets/images/icons/RedChev.svg"
import { ReactComponent as ChevUp } from "../../../assets/images/icons/GreenChev.svg"
import { ReactComponent as Close } from "../../../assets/images/icons/close.svg"

import ProgressSkeleton from "./ProgressSkeleton";
import LightBadge from "../../../components/LightBadge";
import { Progress } from "reactstrap";
import Skeleton from "react-loading-skeleton";

const ChartCardWithProgress = (props) => {
  const { title, main, labels, series, isLoading, mainColor, onMetricClick, onTotalClick, AddAction, invertTrend = false, colors, showTotal, totalVal, singleLine, trend, setMain, progressBar } = { ...props }
  const [expand, setExpand] = useState(false);
  // const [main, setMain] = useState(main)
  const total = totalVal ?? series.reduce((acc, a) => acc + a, 0);
  return (
    <div className="status-card" style={{
      height: props.height
    }}>
      {
        singleLine
        ? <>
          <div className="d-flex align-items-center justify-content-between">
              <div className="chart-heading-single d-flex align-items-center text-color--black">
                <img className="mr-50" src={props.icon} style={{ width: '22px', height: '22px' }} />
                <div>
                  <div className="d-flex align-items-center">
                    {labels[main]}
                    {series.length > 1 &&
                      <div
                        className="material-symbols-outlined text-secondary cursor-pointer ml-50"
                        onClick={() => setExpand(prevState => !prevState)}>expand_more</div>
                    }
                  </div>
                  <div style={{minHeight:"16px"}}>
                    {trend != null && 
                      <div>
                        {(trend[main] != 0)?
                        <div className={((invertTrend ? trend[main]<0 : trend[main] >= 0) ? "positive-trend" : "negative-trend") + " d-flex align-items-center"}>
                          {trend[main] >= 0 ? <ChevUp className={invertTrend?"red-icon":""} width={12} height={12}/> : <ChevDown className={invertTrend?"green-icon":""} width={12} height={12}/>}&nbsp;
                          {trend[main]}%
                        </div> :
                        <div className="text-secondary">0%</div>}
                      </div>}
                  </div>
                </div>
              </div>

            {isLoading?
            <div className="d-flex align-items-end justify-content-end">
              <Skeleton height="28px" width="25px" className='mb-50'/>
          
              {showTotal?<><span className="text-lg forward-slash" style={{color: '#444'}}>/</span>
              <Skeleton height="15px" width="25px" className='mb-50'/></>:null}
            </div>:
            <div className="d-flex align-items-end justify-content-end">
              <div>
                <span className="chart-value-text"
                // style={{ color: colors[main] }}
                >
                  <span
                    className={onMetricClick ? "text-primary cursor-pointer " : mainColor ? mainColor : ""}
                    onClick={onMetricClick}>
                    {series[main]}
                  </span>
                  {showTotal && <span >/</span>}
                </span>
                {showTotal &&
                  <span className={onTotalClick ? "text-primary cursor-pointer chart-total-text " : "chart-total-text"}
                    onClick={onTotalClick}
                    style={{ fontWeight: 600 }}>{total}</span>}
              </div>
            </div>}
          </div>
        </>
        : <>
        <div>
          <div className="d-flex justify-content-between align-items-center"
            style={{
              height: '1.8rem'
            }}>
            <div className="chart-heading d-flex align-items-center text-color--black">
              <img src={props.icon} style={{ width: '16px', marginRight: '8px' }} />&nbsp;
              {title}
            </div>
            <div>
              {
                trend != null &&
                <div className="text-center">
                  {trend[main] != 0 ?
                    <div className={((invertTrend ? trend[main]<0 : trend[main] >= 0) ? "positive-trend" : "negative-trend") + " rounded d-flex justify-content-between"}>
                      {trend[main] >= 0 ? <TrendUp className={invertTrend?"red-icon-stroke":""} /> : <TrendDown className={invertTrend?"green-icon-stroke":""} />}&nbsp;
                      {trend[main]}%
                    </div> :
                    <LightBadge color="secondary">No Change</LightBadge>}
                </div>}
              {/* {AddAction && <span className="material-symbols-outlined chart-buttons cursor-pointer" onClick={AddAction}>Add</span>}
                  {SearchAction && <span className="material-symbols-outlined chart-buttons cursor-pointer" onClick={SearchAction}>Search</span>} */}
            </div>
          </div>
          {trend != null &&
            <div className="d-flex justify-content-end w-100 align-items-start">
              <span className='text-color--black' style={{ fontSize: "0.65rem", paddingRight: "0.5rem" }}>(Today)</span>
            </div>}
        </div>
        <div>
          {!isLoading ?
            <div className={progressBar?"d-flex flex-column justify-content-between":""}>
            {/* //progress */}
            {progressBar?<Progress
              className={`progress-pend-task-chart ${total==0?'':(main==0)?'bg-success':'bg-light-grey'}`}
              data-color={main==0?'light-grey':'success'}
              value={(series[main] / (total>0?total:1)) * 100}
              // color={main==0?'secondary':'success'}
            >
              {/* {Math.floor((series[main] / (total>0?total:1)) * 100)}&nbsp;% */}
            </Progress>:null}
            <div className={"d-flex justify-content-between align-items-end " + props.className}>
              <div>
                <span className="chart-value-text"
                // style={{ color: colors[main] }}
                >
                  <span
                    className={onMetricClick ? "text-primary cursor-pointer " : mainColor ? mainColor : ""}
                    onClick={onMetricClick}>
                    {series[main]}
                  </span>
                  {showTotal && <span >/</span>}
                </span>
                {showTotal &&
                  <span className={onTotalClick ? "text-primary cursor-pointer chart-total-text " : "chart-total-text"}
                    onClick={onTotalClick}
                    style={{ fontWeight: 600 }}>{total}</span>}
              </div>
              <div className="d-flex align-items-center main-text text-color--black"
                style={{
                  height: '1.2rem'
                }}>
                {labels[main]}
                {series.length > 1 &&
                  <span
                    className="material-symbols-outlined text-secondary cursor-pointer ml-1"
                    onClick={() => setExpand(prevState => !prevState)}>expand_more</span>
                }
              </div>
            </div>
            </div>
            : <ProgressSkeleton right={props.right} />}
        </div>
      </>}
      {expand &&
        <div className="progress-expand rounded">
          <div className="d-flex pb-50 ">
            <div className="chart-heading w-50">{title}</div>
            <div className="text-right close-button w-50" onClick={() => setExpand(false)}>
              <Close width={14} height={14} />
            </div>
          </div>
          <hr className="m-0" />
          {
            series.map((data, index) => {
              return (<div className="expand-text cursor-pointer">
                <div className="d-flex justify-content-between my-50">
                  <div className="text-primary"
                    onClick={() => { setMain(index); setExpand(false) }}
                  >
                    {labels[index]}
                  </div>
                  <div>{data}</div>
                </div>
                <div class="progress mt-50 mb-1" style={{ backgroundColor: "white", maxHeight: "0.4rem" }}>
                  <div class="progress-bar"
                    style={{ width: ((data / total) * 100), backgroundColor: colors[index] }}>
                  </div>
                </div>
              </div>)
            })
          }
          {showTotal && <div className="expand-text d-flex justify-content-between my-50">
            <span>Total</span>
            <span>{total}</span>
          </div>}
        </div>}
      <div className={`backdrop ${expand ? "" : "d-none"}`} onClick={() => setExpand(false)}></div>
    </div>
  );
};

ChartCardWithProgress.defaultProps = { title: "DONUT CARD", Pill: null, isLoading: true, showTotal: true, totalVal: null }

export default ChartCardWithProgress;