import Skeleton from "../../../../../Dashboard/Graphs/Skeleton";

const AvailableLayoutsItemSkeleton = () => (
  <div className="p-50 border rounded bg-white w-100">
    <div className="text-primary"><Skeleton width="30px" /></div>
    <div>
      <Skeleton height={100} />
    </div>
    <div className="d-flex justify-content-end">
      <Skeleton width="40px" height={20} />
    </div>
  </div>
);

AvailableLayoutsItemSkeleton.propTypes = {};
AvailableLayoutsItemSkeleton.defaultProps = {};

export default AvailableLayoutsItemSkeleton;