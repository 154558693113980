import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { DateRange } from "./Utils";
import { activeOrgActions } from "../../../redux/slices";
import "../NewDashboard/NDashboard.scss";
import { useEffect } from "react";

const DateRangeSelector = (props) => {
  const range = useSelector(state => state.activeOrg.meta.dateRange);
  const dispatch = useDispatch();
  useEffect(() => {
    if (range == 0.25) dispatch(activeOrgActions.setDateRange(6));
  }, [range, dispatch])

  // useEffect(() => {
  //   console.log("range: ",range);
  //   console.log("!range: ",!range);
  //   console.log("(!range || range == 0.25): ",(!range || range == 0.25));
  // }, [range])


  return (
    ((!range || range == 0.25) && range !== 0) ?
      <></>
      : <Select
        className="daterange-select"
        styles={{
          container: (provided, state) => ({
            ...provided,
            // maxWidth: '140px',
            minWidth: '9rem'
          }),
          control: (provided, state) => ({
            ...provided,
            minHeight: '32px',
            height: '32px',
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            height: '32px',
            display: "flex",
            alignItems: "center"
          }),
          input: (provided, state) => ({
            ...provided,
            margin: '0px',
          }),
          indicatorsContainer: (provided, state) => ({
            ...provided,
            height: "32px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }),
        }}
        value={DateRange(range)} isClearable={false} isSearchable={false} defaultValue={DateRange(range)}
        options={[DateRange(0), DateRange(6), DateRange(29)]}
        onChange={(event) => { dispatch(activeOrgActions.setDateRange(event.value)); }} />
  )
};

export default DateRangeSelector;