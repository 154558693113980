import getIdentity from "./identity.worker";
import { getActiveOrg, getActiveOrgRoles, getActiveOrgLogo } from "./activeorg.worker";
import { getOrg, getOrgRoles } from "./org.worker";
import criticalSetup from "./critical.worker";
import getIdentities from "./identities.worker";
import getSubOrgs from "./suborgs.worker";
import getOrgTypes from "./orgtypes.worker";
import { getInfraByVenue, getActiveVenue } from "./activevenue.worker";
import getInfraList from "./infraList.worker";
import getInfra from "./oneinfra.worker";
import {getDetails, getIssues} from "./oneinfra.worker";
import getInfraTypes from "./infraTypes.worker";
import { getVenueParent, getOrgParent } from "./breadcrumb.worker";
import getNetwork from "./onenetwork.worker";
import getAcceptedSubOrgs from "./acceptedSubOrgs.worker";
import getInviteSubOrgs from "./inviteSubOrgs.worker";
import getDevices from "./device.worker"
import getFilterStatus from "./filter.worker";
import RbacActiveOrgSwitch from "./rbac.worker";

const REDUX_WORKER = {
  getIdentity,
  getActiveOrg,
  criticalSetup,
  getIdentities, 
  getActiveOrgRoles,
  getSubOrgs,
  getOrgTypes,
  getOrg,
  getOrgRoles,
  getActiveVenue,
  getInfraByVenue,
  getActiveOrgLogo,
  getInfraList,
  getInfra,
  getInfraTypes,
  getVenueParent,
  getOrgParent,
  getNetwork,
  getInviteSubOrgs,
  getAcceptedSubOrgs,
  getDevices,
  getIssues,
  getDetails,
  getFilterStatus,
  RbacActiveOrgSwitch
};

export default REDUX_WORKER;