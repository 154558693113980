import { Suspense, lazy, memo } from "react";
import { useSelector } from "react-redux";
import { formatDevicesCardData, formatLoadData, formatMemoryData, formatNetworkCardData } from "../../Dashboard/NewDashboard/BlazerUtil";
import Skeleton from "../../Dashboard/Graphs/Skeleton";
const ChartError = lazy(() => import("../../Dashboard/Graphs/ChartError"));
const LoadChart = lazy(() => import("../../Dashboard/Graphs/LoadChart"));
const MemoryChart = lazy(() => import("../../Dashboard/Graphs/MemoryChart"));
const DevicesChart = lazy(() => import("../../Dashboard/Graphs/DevicesChart"));
const NetworkChart = lazy(() => import("../../Dashboard/Graphs/NetworkChart"));

// lazy(() => import("../../Dashboard/Graphs/ChartError"))

const GraphLoader = () => {
    return (
        <div className="rounded shadow-sm bg-white p-2 mb-50">
            <Skeleton
                height={"120px"}
            />
        </div>
    );
}

const OneGraph = memo((props) => {
    const infraData = useSelector(state => state.oneInfra.data)
    const chartRef = useSelector(state => {
        switch (props?.chartType) {
            case 'LOAD':
                return state?.infra_load
            case 'MEMORY':
                return state?.infra_memory
            case 'Connected Wireless Clients':
                return state?.infra_devices
            case 'TRAFFIC':
                return state?.infra_network
            default:
                return null
        }
    });
    if (chartRef?.isError)
        return (
            <Suspense fallback={<GraphLoader />}>
                <ChartError title={props?.chartType} value={chartRef?.isError} />
            </Suspense>
        )
    else {
        let formattedData;
        switch (props?.chartType) {
            case 'LOAD':
                formattedData = formatLoadData(JSON.parse(chartRef?.data));
                return (
                    <Suspense fallback={<GraphLoader />}>
                        <LoadChart
                            height={props?.height ? props?.height : "200px"}
                            width={"104%"}
                            range={props.range}
                            showPercentage={true}
                            ticks={props.ticks}
                            categories={formattedData.labels}
                            colors={["#e64a95"]}
                            series={formattedData.series}
                            isLoading={chartRef?.isLoading || (!chartRef?.isError && !chartRef?.data)}
                        />
                    </Suspense>
                );
            case 'MEMORY':
                formattedData = formatMemoryData(JSON.parse(chartRef.data));
                return (
                    <Suspense fallback={<GraphLoader />}>
                        <MemoryChart
                            height={props?.height ? props?.height : "200px"}
                            width={"104%"}
                            range={props.range}
                            showPercentage={true}
                            ticks={props.ticks}
                            categories={formattedData.labels}
                            colors={["#99d8fb"]}
                            series={formattedData.series}
                            isLoading={chartRef.isLoading || (!chartRef.isError && !chartRef.data)}
                        />
                    </Suspense>
                );
            case 'Connected Wireless Clients':
                formattedData = formatDevicesCardData(JSON.parse(chartRef.data));
                return (
                    <Suspense fallback={<GraphLoader />}>
                        <DevicesChart
                            height={props?.height ? props?.height : "200px"}
                            width={"104%"}
                            infraType={infraData.infraCategory}
                            range={props.range}
                            currentlyConnected={formattedData.currentlyConnected}
                            categories={formattedData.labels}
                            colors={["#5279CE"]}
                            series={formattedData.series}
                            isLoading={chartRef.isLoading || (!chartRef.isError && !chartRef.data)}
                            ticks={props.ticks}
                        />
                    </Suspense>
                );
            case 'TRAFFIC':
                formattedData = formatNetworkCardData(JSON.parse(chartRef.data));
                return (
                    <Suspense fallback={<GraphLoader />}>
                        <NetworkChart
                            height={props?.height ? props?.height : "200px"}
                            width={"104%"}
                            range={props.range}
                            up={formattedData.up}
                            down={formattedData.down}
                            total={formattedData.total}
                            categories={formattedData.labels}
                            colors={["#B347E6", "#F5A742"]}
                            series1={formattedData.series1}
                            series2={formattedData.series2}
                            typeSeriesTrafficDownlink={formattedData.typeSeriesTrafficDownlink}
                            typeSeriesTrafficUplink={formattedData.typeSeriesTrafficUplink}
                            isLoading={chartRef.isLoading || (!chartRef.isError && !chartRef.data)}
                            ticks={props.ticks}
                        />
                    </Suspense>
                )
            default:
                return (<></>);
        }
    }
})
export default OneGraph;