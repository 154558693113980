import PropTypes from "prop-types";
import "./AccessPointCanvas.scss";
import Canvas from "../../../Canvas";
import { CANVAS_FEATURES, LAYOUT_TYPES } from "../../../constants";
import { useEffect, useState } from "react";

/**
 * Access Points Canvas
 * @param {{
 *  layoutManager: object,
 *  layouts: Array<object>?,
 *  draggedItem: any
 * }} props 
 */
const AccessPointCanvas = (props) => {
  const [featureList, setFeatureList] = useState([CANVAS_FEATURES.AP_VIEW_AND_WIDGETS, CANVAS_FEATURES.ZOOM_WIDGETS])
  // useEffect(() => {
  //   if (props.layouts[0]?.layoutType == LAYOUT_TYPES.IMAGE) {
  //     setFeatureList([CANVAS_FEATURES.AP_VIEW_AND_WIDGETS, CANVAS_FEATURES.ZOOM_WIDGETS, CANVAS_FEATURES.NO_AXIS, CANVAS_FEATURES.SHOW_LAYOUT_IMAGE])
  //   }
  // }, [])
  return (
    <div className="AccessPointCanvas">
      <Canvas
        blank={props.blank}
        draggedItem={props.draggedItem}
        layoutManager={props.layoutManager}
        layouts={props.layouts || []}
        events={[]}
        features={featureList}
        onSave={() => { }}
      />
    </div>
  );
};

AccessPointCanvas.propTypes = {
  layoutManager: PropTypes.object,
  layouts: PropTypes.array
};
AccessPointCanvas.defaultProps = {};

export default AccessPointCanvas;