import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";

import './InstalledImageModal.scss'
import { Suspense, useState } from "react";
import DeleteIcon from '../../../assets/images/icons/Delete.svg'
import createRequest, { services } from "../../../services";
import { CatchedWebError } from "../../../configs";
import { make_custom_toast } from "../../../helpers/toasts";
import REDUX_WORKER from "../../../redux/workers";

const InstalledImageModal = (props) => {
    const { visible, setVisible, instImg, setInstImg, instCount, setInstCount, infraData, instActive, setInstActive } = props
    // const [instActive, setInstActive] = useState(0);
    const [deleting, setDeleting] = useState(false);
    const removeImg = () => {
        const { run } = createRequest(services.infra.INSTALL_IMG_DELETE, [infraData?.infraItemId, instCount[instActive]])

        if (!deleting && instCount[instActive]) {
            setDeleting(true)
            run()
                .then(() => {
                    setInstImg(prevState => {
                        let newObj = { ...prevState }
                        delete newObj[instCount[instActive]];
                        return { ...newObj }
                    });
                    setInstCount(prevState => {
                        let newArray = [...prevState];
                        newArray.splice(instActive, 1);
                        return [...newArray];
                    })
                    setInstActive(0);


                    // If No Image is present then Make Image task as false
                    if (instCount?.length == 1) {
                        const { run } = createRequest(services.telemetry.UPDATE_TASK, [infraData?.macAddress], { photos: false })
                        run()
                            .then(res => {
                                REDUX_WORKER.getInfra(infraData?.infraItemId, false)
                                setVisible(false)
                            })
                            .catch(err => {
                                setVisible(false)
                            })
                    }
                })
                .catch((err) => {
                    make_custom_toast('error', 'Infrastructure', (new CatchedWebError(err)).message)
                })
                .finally(() => setDeleting(false))
        }
    }
    return (
        <Modal centered className="InstalledImageModal" isOpen={visible} toggle={() => setVisible(false)}>
            <ModalHeader className="bg-white" toggle={() => setVisible(false)} ></ModalHeader>
            <ModalBody>
                <div className="d-flex w-100 flex-column align-items-center justify-content-between "
                    style={{ minHeight: "400px", maxHeight: "500px" }}>
                    <div style={{ position: 'relative' }}>
                        <img
                            className="inst-img-container w-100 "
                            src={instImg[instCount[instActive]]}
                        />

                        <img className="delete-img-icon cursor-pointer" onClick={removeImg} src={DeleteIcon} />
                    </div>

                    <div className="d-flex align-items-center justify-content-between mt-50 w-100 ">
                        <span
                            className="material-symbols-outlined text-primary cursor-pointer"
                            style={{ fontSize: "1.8rem" }}
                            onClick={() => { if (instActive > 0) { setInstActive(prevState => prevState - 1); } }}
                        >chevron_left</span>

                        <div className="d-flex align-items-center">
                            {instCount?.length > 0 && instCount.map((img, index) => {
                                return (
                                    <div className={`${index == instActive ? 'selected-img-dot' : 'grey-img-dot'}`} />
                                )
                            })}
                        </div>

                        <span
                            className="material-symbols-outlined text-primary cursor-pointer"
                            style={{ fontSize: "1.8rem" }}
                            onClick={() => { if (instActive < (instCount?.length - 1)) { setInstActive(prevState => prevState + 1); } }}
                        >chevron_right</span>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default InstalledImageModal;