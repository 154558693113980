/***
 *
 * Controller class for user.
 * @file InfraList.js
 * @description InfraList component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import {Row, Col, Container } from "reactstrap";
import { VeryFancyLoader } from "../../../components";
import { breadcrumbActions, infraListActions } from "../../../redux/slices";
import createRequest, { services } from "../../../services";
import "./InfraList.scss";
import useAutoclear from "../../../hooks/useAutoclear";
import LightBadge from "../../../components/LightBadge";
import { ReactComponent as MSPIcon } from "../../../assets/images/icons/MSP.svg";
import { ReactComponent as BusIcon } from "../../../assets/images/icons/BUS.svg";
import { formatInfraCardData, formatOnlineInfraData } from "../../Dashboard/NewDashboard/BlazerUtil";
import useTimedCaller from "../../Dashboard/NewDashboard/useTimedCaller";
import blazer from "../../../services/blazer.service";
import { isoDate } from "../../Dashboard/Graphs/Utils";
import Blazer from "../../../redux/slices/blazer.slice";
import ChartError from "../../Dashboard/Graphs/ChartError";
import InfraChart from "../../Dashboard/Graphs/InfraChart";
import OnlineInfraCharts from "../../Dashboard/Graphs/OnlineInfraChart";
import FirmwareProgressGraph from "../../../components/FirmwareProgressGraph";
import FirmwareReschedule from "../../../components/FirmwareRescheduleVenue";
import { BUSINESS, BUSINESS_VIEW, DEPLOYMENT_VIEW, NETWORK_VIEW, THIRD_PARTY_TITLE } from "../../../utility/constants";
import { cmpCol, getColumns } from "../../../utility/colSaver";
import { LIST_TYPES } from "../../../components/OrgWidget/utils";
const OrgWidget = lazy(() => import("../../../components/OrgWidget"));

export const statusColor = {
  "connected": "#13A877",
  "disconnected": "#ea5455",
  "demo": "#4b4b4b",
  "pending": "#ff9f43"
}

export const statusText = {
  "connected": "Online",
  "disconnected": "Offline",
  "demo": THIRD_PARTY_TITLE,
  "pending": "Pending"
}

export const orgtypesIcons = {
  1: <MSPIcon width="100%" height="100%" style={{ height: "16px", width: "16px" }} />,
  2: <MSPIcon width="100%" height="100%" style={{ height: "16px", width: "16px" }} />,
  3: <BusIcon width="100%" height="100%" style={{ height: "16px", width: "16px" }} />,
};

export const radioFormater = (radio) => {
  let str = "";

  if (!radio.supported || radio.active == null)
    return str
  if (!radio.active)
    return <div className="d-flex"><LightBadge color="secondary">Disabled</LightBadge></div>

  if (!!radio.channel)
    str += `Ch ${radio.channel}`
  if (!!radio.channel_width) {
    if (str.length)
      str += ", "
    str += `${radio.channel_width} MHz`
  }
  if (!!radio.tx_power) {
    if (str.length)
      str += ", "
    str += `${radio.tx_power} dBm TX`
  }

  return str

}

export const ipFormater = (ip = "") => {
  let ipSegments = ip.split('.');

  if (ipSegments.length < 4)
    return "";

  return <span>
    {ipSegments[0]}.
    <span className={ipSegments[0] == 10 ? "font-weight-bolder" : ""}>{ipSegments[1]}.</span>
    <span className={(ipSegments[0] == 10 || (ipSegments[0] == 192 && ipSegments[1] == 168) || (ipSegments[0] == 172 && ipSegments[1] >= 16 && ipSegments[1] <= 32)) ? "font-weight-bolder" : ""}>{ipSegments[2]}.</span>
    <span className={(ipSegments[0] == 10 || (ipSegments[0] == 192 && ipSegments[1] == 168) || (ipSegments[0] == 172 && ipSegments[1] >= 16 && ipSegments[1] <= 32)) ? "font-weight-bolder" : ""}>{ipSegments[3]}</span>
  </span>
}

const QuickFilter = ({ isActive, onClick, name, count }) => {
  return (
    <div className={"grp-btn-custom " + (isActive ? "active" : "")} onClick={onClick} >{name} ({count ?? 0})</div>
  )
}

const InfraList = () => {
  const SCREEN_ID = "infra-list"
  const [params, setParams] = useSearchParams();
  const { state } = useLocation()
  const initMount = useRef(true);
  const initColumns = {
    [NETWORK_VIEW]: {
      "Organization-Venue": true,
      "Private IP": true,
      "Public IP": false,
      "AP Profile": false,
      "Switch Profile": false,
      "RF Profile": false,
      "2G Radio": false,
      "5G Radio": false,
      "6G Radio": false,
      "Tasks": false,
      "Room": false,
      "Location": false,
      "Traffic": true,
      "Clients": true,
      "Release": true,
      "Operations": true,
      "MAC Address": true,
    },
    [BUSINESS_VIEW]: {
      "Organization-Venue": true,
      "Private IP": true,
      "Public IP": false,
      "AP Profile": false,
      "Switch Profile": false,
      "RF Profile": false,
      "2G Radio": false,
      "5G Radio": false,
      "6G Radio": false,
      "Tasks": false,
      "Room": false,
      "Location": false,
      "Traffic": true,
      "Clients": true,
      "Release": true,
      "Operations": true,
      "MAC Address": true,
    },
    [DEPLOYMENT_VIEW]: {
      "Organization-Venue": true,
      "Private IP": true,
      "Public IP": false,
      "AP Profile": false,
      "Switch Profile": false,
      "RF Profile": false,
      "2G Radio": false,
      "5G Radio": false,
      "6G Radio": false,
      "Tasks": true,
      "Room": true,
      "Location": true,
      "Traffic": true,
      "Clients": true,
      "Release": true,
      "Operations": true,
      "MAC Address": true,
    },
  }

  const mainFilterOptions = {
    [NETWORK_VIEW]: [
      {
        name: "All",
        apiValue: 'All',
        countName: 'all',
      },
      {
        name: "Online",
        apiValue: 'Online',
        countName: 'online',
      },
      {
        name: "Issues",
        apiValue: 'Issues',
        countName: 'issues',
      },
      {
        name: "Offline",
        apiValue: 'Offline',
        countName: 'offline',
      },
    ],
    [BUSINESS_VIEW]: [
      {
        name: "All",
        apiValue: 'All',
        countName: 'all',
      },
      {
        name: "Online",
        apiValue: 'Online',
        countName: 'online',
      },
      {
        name: "Issues",
        apiValue: 'Issues',
        countName: 'issues',
      },
      {
        name: "Offline",
        apiValue: 'Offline',
        countName: 'offline',
      },
    ],
    [DEPLOYMENT_VIEW]: [
      {
        name: "All",
        apiValue: 'All',
        countName: 'all',
      },
      {
        name: "Not Initialized",
        apiValue: 'Not Initialized',
        countName: 'notInitialized',
      },
      {
        name: "No Photos",
        apiValue: 'No Photos',
        countName: 'noPhotos',
      },
      {
        name: "Not Installed",
        apiValue: 'Not Installed',
        countName: 'notInstalled',
      },
      {
        name: "Not Validated",
        apiValue: 'Not Validated',
        countName: 'notValidated',
      },
    ],
  }

  const isMainFilterAllowed = (filterValue, view) => {
    return !!(mainFilterOptions[view]?.find(item => item.apiValue === filterValue))
  }

  const view = useSelector(store => store.identity.meta.view)
  const activeOrg = useSelector(store => store.activeOrg.data);
  // const identity = useSelector(store => store.identity);
  const activeOrgLoading = useSelector(store => store.activeOrg.status.loading);
  // const rebooting = useSelector(store => store.rebooting.data);
  // const stateChange = useSelector(store => store.rebooting.status);
  const range = useSelector(store => store.activeOrg.meta.dateRange);
  const infraType = useSelector(store => store.infraTypes);
  // const socketMessage = useSelector(store => store.identity.meta.updateDashboardMessage);
  const [hasMore, setHasMore] = useState(false);
  const [mainFilter, setMainFilter] = useState(() => isMainFilterAllowed(params.get("filter"), view) ? params.get("filter") : (mainFilterOptions[view] ? mainFilterOptions[view][0].apiValue : "All"));
  // const [filterCount, setFilterCount] = useState({});
  // const [filterData, setFilterData] = useState(state?.filter ? { ...filterInitial, ...state?.filter } : filterInitial);
  // const [filterSelection, setFilterSelection] = useState(state?.filter ? { ...filterInitial, ...state?.filter } : filterInitial);
  // const [filterActive, setFilterActive] = useState(!lodash.isEqual(filterData, filterInitial));
  // const [infraError, setInfraError] = useState(null)
  const [infraListLoading, setInfraListLoading] = useState(true);
  const permissions = useSelector(store => store?.rbac?.permissions);
  // const venuePermissions = useSelector(store => store?.rbac?.venuePermissions);
  const [infraList, setInfraList] = useState([])
  // const [selectedInfra, setSelectedInfra] = useState([]);
  // const [bulkResponse, setBulkResponse] = useState({});
  const infraRef = useSelector(state => state.infraDash_infra);
  const onlineInfraRef = useSelector(state => state.infraDash_online_infra);
  const [options, setOptions] = useState(null);
  // const [venueSelectorModal, setVenueSelectorModal] = useState(false);
  // const [selectedVenue, setSelectedVenue] = useState(null);
  const [added, setAdded] = useState(false);
  // const [bulkAssignSuccess, setBulkAssignSuccess] = useState(false);
  // const [bulkAssignLoading, setBulkAssignLoading] = useState(false);
  // const [search, setSearch] = useState(params.get("search") ?? "");
  // const [error, setError] = useState(null);
  // const [errorContext, setErrorContext] = useState(null)
  // const navigate = useNavigate();
  const [wasDeleted, setWasDeleted] = useState(false);
  // const [deleting, setDeleting] = useState(false);
  const [imported, setImported] = useState(false);
  // const [deleteItemId, setDeleteItemId] = useState(null);
  const [latestVersion, steLatestVersion] = useState({})
  const [cols, setCols] = useState(
    cmpCol(initColumns[view],
      getColumns(SCREEN_ID, view) ?? {})
  )
  // const [sort, setSort] = useState({
  //   orderBy: "Name",
  //   order: "ASC"
  // });
  // const [showFilter, setShowFilter] = useState(false);
  // const [isColumnSelectorOpen, setIsColumnSelectorOpen] = useState(false)
  // const [downloading, setDownloading] = useState(false)
  const [selections, setSelections] = useState({infras:{}, venues:{}});
  const [treeStruct, setTreeStruct] = useState();
  const [venueSelection, setVenueSelection] = useState({
    venues: new Set(),
    venueDelete: new Set(),
    infras: new Set(),
    infraDelete: new Set(),
    applyToOrg: null,
    // applyToOrg: props.data.applyToOrg
  });
  
  const dispatch = useDispatch();
  const { id } = useParams();

  // let timer;
  // let interval;
  // let controller;
  useTimedCaller({ service: blazer.ORG_INFRA, params: [activeOrg.orgId, true, isoDate(), new Date().getTimezoneOffset()], data: {} }, 0, activeOrg.orgId, state => state.infraDash_infra, Blazer.infraDash_infra.actions);
  useTimedCaller({ service: blazer.ORG_AP_ONLINE, params: [activeOrg.orgId, isoDate(range), isoDate(), true, new Date().getTimezoneOffset()], data: {} }, range, activeOrg.orgId, state => state.infraDash_online_infra, Blazer.infraDash_online_infra.actions);

  const getTreeStruct = (orgId) => {
    return new Promise((resolve, reject) => {
      const {run} = createRequest(services.networks.GET_ASSOCIATION, [orgId]);
      run()
      .then(response => {
        setTreeStruct(prevState => {
          return response.data
        });
        let sel = {infras:{}, venues:{}}
        Object.keys(response?.data?.infrastructure??{}).map(infra => {
          sel.infras[infra] = response?.data?.infrastructure[infra]
        })
        Object.keys(response?.data?.venue??{}).map(ven => {
          sel.venues[ven] = response?.data?.venue[ven].inheritance
        })
        setSelections({...sel})
  
  
        // setVenueSelection(prevState => {
        //   let sel = {...prevState}
  
        //   Object.keys(response?.data?.infrastructure??{}).map(infra => {
        //     if(response?.data?.infrastructure[infra] == 2)
        //       sel.infras.add(Number(infra))
        //   })
        //   Object.keys(response?.data?.venue??{}).map(ven => {
        //     if(response?.data?.venue[ven].inheritance == 2){
        //       sel.venues.add(Number(ven));
        //       response?.data?.venue[ven].infrastructure.map(infra => {
        //         sel.infras.delete(infra);
        //       })
        //     }
        //   })
        //   return {...sel} 
        // })
        resolve(response.data)
      })
      .catch(err => reject(err))
    })
  }
  // useEffect(() => {
  //   const {run} = createRequest(services.networks.GET_ASSOCIATION, [activeOrg.orgId]);
  
  //   run()
  //   .then(response => {
  //     setTreeStruct(response.data);
  //     let sel = {infras:{}, venues:{}}
  //     Object.keys(response?.data?.infrastructure??{}).map(infra => {
  //       sel.infras[infra] = response?.data?.infrastructure[infra]
  //     })
  //     Object.keys(response?.data?.venue??{}).map(ven => {
  //       sel.venues[ven] = response?.data?.venue[ven].inheritance
  //     })
  //     setSelections({...sel, orgId: null})


  //     setVenueSelection(prevState => {
  //       let sel = {...prevState}

  //       Object.keys(response?.data?.infrastructure??{}).map(infra => {
  //         if(response?.data?.infrastructure[infra] == 2)
  //           sel.infras.add(Number(infra))
  //       })
  //       Object.keys(response?.data?.venue??{}).map(ven => {
  //         if(response?.data?.venue[ven].inheritance == 2){
  //           sel.venues.add(Number(ven));
  //           response?.data?.venue[ven].infrastructure.map(infra => {
  //             sel.infras.delete(infra);
  //           })
  //         }
  //       })
  //       return {...sel} 
  //     })
  //   })
  // },[])

  // useEffect(() => {
  //     console.log("tree struct: ",treeStruct)
  // }, [treeStruct])
  // useEffect(() => {
  //     console.log("venueSelection: ",venueSelection)
  // }, [venueSelection])
  // useEffect(() => {
  //     console.log("selections: ",selections)
  // }, [selections])
  

  // const debounce = (callback) => {
  //   clearTimeout(timer);
  //   timer = setTimeout(callback, 1000);
  // }

  /// BULK ASSIGN INFRA
  // const bulkAssign = () => {
  //   setBulkAssignLoading(true);
  //   const { run } = createRequest(services.infra.MOVE, [selectedVenue.venueId], selectedInfra);

  //   run()
  //     .then((response) => {
  //       setBulkAssignSuccess(true);
  //       dispatch(infraListActions.reset());
  //       setHasMore(true);
  //       REDUX_WORKER.getInfraList(activeOrg.orgId, 0, 20, encodeURIComponent(search?.replaceAll(":", "")), sort.orderBy, sort.order, setHasMore, true);
  //       setBulkResponse(response.data);
  //       setSelectedInfra([]);
  //       setSelectedVenue(null);
  //       dispatch(activeVenueActions.resetInfra());
  //     })
  //     .catch((err) => {
  //       const x = new CatchedWebError(err)
  //       // setError();
  //       make_custom_toast('error', 'Infrastructure', x.message)
  //       setBulkResponse(err.data);
  //     })
  //     .finally(() => {
  //       setVenueSelectorModal(false);
  //       setBulkAssignLoading(false);
  //     })

  // }

  /// BREADCRUMB SETUP
  useEffect(() => {
    dispatch(breadcrumbActions.setData([
      {
        path: `/organization/${activeOrg.orgId}/infra`,
        text: "Infrastructure",
        active: true
      }
    ]))

    // if (permissions?.manageFirmware?.update) {
    //   const { run } = createRequest(services.fwupgrade.GET_LATEST_VERSION, [])
    //   run().then(response => steLatestVersion(response.data));
    // }

    // return (() => {
    //   dispatch(infraListActions.setError(null));
    // })
  }, [])

  // useEffect(() => {
  //   if (params.has("import")) {
  //     setImported(true);
  //   }
  // }, [params]);

  // useEffect(() => {
  //   if (initMount.current) {
  //     initMount.current = false
  //   }
  //   else {
  //     if (!!view &&
  //       mainFilterOptions[view]?.length > 0 &&
  //       !mainFilterOptions[view]?.find(mainFilterOption => mainFilterOption.apiValue == mainFilter)
  //     ) {
  //       if (mainFilterOptions[view]?.length > 0)
  //         setMainFilter(mainFilterOptions[view][0]?.apiValue)
  //       else
  //         setMainFilter("All")
  //     }
  //     setCols({ ...initColumns[view], ...getColumns(SCREEN_ID, view) });
  //   }
  // }, [view])

  // useEffect(() => {
  //   let updatedSearchParams = new URLSearchParams(params.toString());
  //   updatedSearchParams.set('filter', mainFilter);
  //   setParams(updatedSearchParams.toString(), { replace: true });
  //   setInfraListLoading(true);
  //   setHasMore(true);
  //   setInfraList([]);
  // }, [mainFilter]);

  // useEffect(() => {
  //   if (socketMessage) {
  //     setInfraList((prevState) => {
  //       return prevState.map((infra) => {
  //         if (infra.infraItemId == socketMessage.infraItemId) {
  //           return { ...infra, firmwareStatus: socketMessage.currentStatus, firmwareVersion: socketMessage.currentVersion };
  //         }
  //         else return infra
  //       })
  //     })
  //   }
  // }, [socketMessage])

  // const resetSideFilters = () => {
  //   setFilterData(filterInitial);
  //   setFilterSelection(filterInitial);
  //   setFilterActive(false);
  // }

  // const handleMainFilterClick = (filterMain) => {
  //   if (!infraListLoading) {
  //     setMainFilter(filterMain);
  //     resetSideFilters();
  //     fetchInfra(0, filterMain, filterInitial);
  //   }
  // }

  // const downloadCsv = () => {
  //   const allowedColumns = [
  //     "Organization-Venue",
  //     "Private IP",
  //     "Public IP",
  //     "AP Profile",
  //     "Switch Profile",
  //     "RF Profile",
  //     "2G Radio",
  //     "5G Radio",
  //     "6G Radio",
  //     "Tasks",
  //     "Room",
  //     "Location",
  //     "Release",
  //     "Operations",
  //     "MAC Address",
  //   ]
  //   const filteredColumns = Object.keys(cols ?? {}).map(col => cols[col] ? col : null).filter(it => it && allowedColumns.includes(it))
  //   setDownloading(true)
  //   axios.get(T(`/organization/${activeOrg.orgId}/dashboard/infras/export?&search=${search}&filter=${mainFilter}&timezoneOffset=${(new Date()).getTimezoneOffset()}&columns=${filteredColumns}`
  //     + (!!sort?.orderBy ? `&orderkey=${sort.orderBy}` : ``) + (!!sort?.order ? `&order=${sort.order}` : ``)
  //     + (filterData?.category ? `&infraCategory=${filterData.category}` : "")
  //     + (filterData?.infraType?.length ? `&infraType=${filterData.infraType}` : "")
  //     + (filterData?.status?.length ? `&status=${filterData.status}` : "")
  //     + (filterData?.venueId?.length ? `&venueId=${filterData.venueId}` : "")
  //     // + (filterData?.status ? `&status=${filterData.status.map(type => type.toLowerCase())}` : "")
  //     + (filterData?.orgId?.length > 0 ? `&orgId=${filterData.orgId}` : "")
  //     + (filterData?.firmwareVersion?.length > 0 ? `&firmwareVersion=${filterData.firmwareVersion}` : "")), {
  //       responseType: 'arraybuffer', headers: {
  //         authorization: "Bearer " + identity.meta.token
  //       }
  //   })
  //     .then(response => {
  //       var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  //       const url = URL.createObjectURL(blob);
  //       const link = document.createElement('a');
  //       link.href = url;
  //       let filterName = mainFilterOptions[view]?.find(option => option.apiValue == mainFilter)?.name
  //       link.setAttribute('download', `Infrastructure List${!!mainFilter && !!filterName && !filterActive ? ('-(' + capitalizeSentence(filterName) + ')') : ''}${activeOrg?.orgId != null ? ('-(' + activeOrg.orgId + ')') : ''}.xlsx`);
  //       document.body.appendChild(link);
  //       link.click();
  //       link.remove();
  //     })
  //     .catch(err => {
  //       console.log(err)
  //       make_custom_toast('error', 'Export Infrastructure List', (new CatchedWebError(err)).message)
  //     })
  //     .finally(() => {
  //       setDownloading(false)
  //     })
  // }

  // useAutoclear(wasDeleted, () => { setWasDeleted(false); });
  // useAutoclear(imported, () => { setImported(false); });
  // useAutoclear(added, () => { setAdded(false); });

  // if (Number(id) !== activeOrg.orgId || activeOrgLoading || (infraListLoading && !infraList)) {
  //   return (
  //     <div className="d-flex justify-content-center align-items-center overflow-hidden" style={{ height: "80vh" }}>
  //       <VeryFancyLoader />
  //     </div>
  //   );
  // }

  return (
    <div className="InfraList fadable" data-testid="InfraList">
      {/* {options != null && <FirmwareReschedule options={options} setModal={setOptions} updateSnoozeDate={(snzDate, infraItemId) => {
        setInfraList(prevState => {
          let index = prevState.findIndex(it => {
            return it.infraItemId == infraItemId
          })
          let newList = [...prevState]
          if (index >= 0)
            newList[index].scheduleSnoozed = snzDate
          return [...newList]
        })
      }} />} */}

      <Container fluid>
        <Row className="my-1">
          {(permissions?.manageInfra?.view) &&
            <Col xl={3} className="px-0 col-xxl-3">
              {infraRef.isError ?
                <ChartError title="Infrastructure" value={infraRef.isError} /> :
                <InfraChart className="pb-2" height={'12.6rem'} series={formatInfraCardData(JSON.parse(infraRef.data)).series} trend={formatInfraCardData(JSON.parse(infraRef.data)).trend} isLoading={infraRef.isLoading || (!infraRef.isError && !infraRef.data)} main={infraRef.active} setMain={(main) => dispatch(Blazer.infraDash_infra.actions.setActive(main))} />}
            </Col>
          }
          {(permissions?.manageInfra?.view) &&
            <Col xl={4} className="leftPush rightPush col-xxl-3">
              {onlineInfraRef.isError ?
                <ChartError title="Online Infrastructure" value={onlineInfraRef.isError} /> :
                <OnlineInfraCharts
                  ticks={3}
                  height={'143rem'}
                  categories={formatOnlineInfraData(JSON.parse(onlineInfraRef.data)).labels}
                  colors={["#ED9F9C"]}
                  series={formatOnlineInfraData(JSON.parse(onlineInfraRef.data)).series}
                  isLoading={onlineInfraRef.isLoading || (!onlineInfraRef.isError && !onlineInfraRef.data)}
                />}
            </Col>
          }
          <Col xl={5} className="leftPush rightPush col-xxl-4">
            <div className="h-100">
              <FirmwareProgressGraph orgId={activeOrg.orgId} />
            </div>
          </Col>
        </Row>
      </Container>
      <div className="bg-white rounded p-1">
          {/* selections,
        setSelections,
        setVenueSelection,
        treeStruct */}
        {infraType?.status?.loading ? null :
        <Suspense fallback={<></>}>
        <OrgWidget
          autoFocus
          listType={LIST_TYPES.INFRA_LIST}
          SCREEN_ID="infra-list"
          selections={selections}
          setSelections={setSelections}
          venueSelection={venueSelection}
          setVenueSelection={setVenueSelection}
          treeStruct={treeStruct}
          // hideAlarmIcons={true}
          filterReq={{
            CategoryFilter: true,
            InfraTypeFilter: true,
            StatusFilter: true,
            OrgFilter: activeOrg.orgTypeId != BUSINESS,
            FWFilter: true,
          }}
          getTreeStruct={getTreeStruct}
          enableOrgSelection={true}
        />
        </Suspense>}
      </div>
    </div >
  );
};

InfraList.propTypes = {};

InfraList.defaultProps = {};

export default InfraList;
