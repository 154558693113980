/***
 *
 * Controller class for user.
 * @file PromoteDesignLayoutModal.js
 * @description PromoteDesignLayoutModal component
 * @author Rajinder Singh
 * @since 22 Jan 2024
 *
 */

import React from "react";
// import PropTypes from 'prop-types';
import "./PromoteDesignLayoutModal.scss";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

const PromoteDesignLayoutModal = (props) => {
  return (
    <Modal centered isOpen={props.visible} toggle={() => { props.setVisible(null) }}>
      <ModalHeader className="bg-white p-0" toggle={() => { props.setVisible(null) }}></ModalHeader>
      <ModalBody>
        <div className="d-flex flex-column p-1 align-items-center ">
          <span className="material-symbols-outlined display-1 text-warning">error</span>
          <strong className="py-1">A design layout already exists for this venue.</strong>
          <span>
            Do you want to replace that?
          </span>
          <div className="d-flex align-items-center justify-content-center">
            <Button
              color='outline-primary'
              className=' mt-2 mr-2'
              onClick={() => {
                props.setVisible(null)
              }}
            >
              Cancel
            </Button>
            <Button
              color='primary'
              className='mt-2'
              onClick={() => {
                props.promoteToDesign(props.visible, true)
                props.setVisible(null)
              }}
            >
              Promote
            </Button>

          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

PromoteDesignLayoutModal.propTypes = {};

PromoteDesignLayoutModal.defaultProps = {};

export default PromoteDesignLayoutModal;
