/***
 * A blocking modal with spinner on it, for loading, submitting blocking items
 * @file SpinningLoaderModal.js
 * @description SpinningLoaderModal component
 * @author Utkarsh Gupta
 * @since 16 Jan 2023
 */

import React from "react";
import { Modal, ModalBody, Spinner } from "reactstrap";
import PropTypes from 'prop-types';

const SpinningLoaderModal = (props) => {
  return (
    <Modal isOpen={props.isOpen} centered>
      <ModalBody className="text-center p-4">
        <Spinner />
      </ModalBody>
    </Modal>
  );
};

SpinningLoaderModal.propTypes = { isOpen: PropTypes.bool };

SpinningLoaderModal.defaultProps = {};

export default SpinningLoaderModal;
