import PropTypes from "prop-types";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import createRequest, { services } from "../../../../services";
import { CatchedWebError } from "../../../../configs";
import InfiniteScroll from "react-infinite-scroll-component";
import LayoutListItem from "../LayoutListItem";
import { FAKE_WALL_DATA, FETCH_LIMIT_LAYOUTS, LAYOUT_TYPE, LAYOUT_TYPES } from "../constants";
import LayoutListItemSkeleton from "../LayoutListItem/LayoutListItemSkeleton";
import { wre } from "../../../../services/wre.service";
import { make_toast } from "../../../../helpers";
import LayoutDeletionConfirmation from "../LayoutDeletionConfirmation";
import Editor from "../Editor";
import { AnimatePresence } from "framer-motion";
import ImageUploadModal from "../ImageUploadModal";
import { VeryFancyLoader } from "../../../../components";
import PromoteDesignLayoutModal from "../../../../components/PromoteDesignLayoutModal";
import { make_custom_toast } from "../../../../helpers/toasts";

const mapping = {
  "all": null,
  "Single LiDAR Scan": 2,
  "Merged LiDAR Scans": 3,
  "Image": 1
};

/**
 * Layout List
 * @param {{
 *  venueId: number, listFilter: "all" | "Single LiDAR Scan" | "Merged LiDAR Scans" | "Image",
 *  editorState: {isOpen: boolean, layout: any}
 *  setEditorState: ({isOpen: boolean, layout: any}) => void,
 *  layoutViewFilter: string,
 *  uploadImageDialog: boolean,
 *  setUploadImageDialog: (boolean) => void
 * }} props 
 */
const LayoutList = (props) => {
  const [layouts, setLayouts] = useState([]);
  const [layoutsLoading, setLayoutsLoading] = useState(false);
  const [layoutsLoadingFirstTime, setLayoutsLoadingFirstTime] = useState(false);
  const [layoutsError, setLayoutsError] = useState(null);
  const [showPromoteDesignModal, setShowPromoteDesignModal] = useState(null)
  const [hasMore, setHasMore] = useState(true);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState({ id: 0, name: "" });
  const { editorState, setEditorState } = props;
  const { venueId, layoutViewFilter } = props;

  // Fetch Layouts In Chunks
  const fetchLayouts = useCallback((from, to, silent = false) => {
    if (venueId) {
      if (!silent) {
        if (from === 0) {
          setLayoutsLoadingFirstTime(true);
        } else {
          setLayoutsLoading(true);
        }
      }
      setLayoutsError(false);
      const { run } = createRequest(services.wre.GET_LAYOUTS_BY_VENUE, [venueId, from, to, mapping[layoutViewFilter]]);
      run()
        .then(response => {
          if (response.data.length !== FETCH_LIMIT_LAYOUTS) {
            setHasMore(false);
          }
          response?.data.map((layout, index) => {
            if (layout.layoutType == 1) {
              layout.layoutJson = FAKE_WALL_DATA
            }
          })
          if (silent) {
            setLayouts(response.data);
          } else {
            setLayouts(lts => lts.concat(response.data));
          }
        })
        .catch(err => {
          // HANDLE ERROR
          const x = new CatchedWebError(err);
          make_custom_toast('error', 'Venue', x.message)
          // setLayoutsError(x.message);
          setHasMore(false);
        })
        .finally(() => {
          if (!silent) {
            if (from === 0) {
              setLayoutsLoadingFirstTime(false);
            } else {
              setLayoutsLoading(false);
            }
          }
        })
    }
  }, [venueId, layoutViewFilter]);

  // Delete a layout by given ID
  const deleteLayout = useCallback((id, resolve = () => { }, reject = () => { }) => {
    setHasMore(true);
    const { run } = createRequest(wre.DELETE_LAYOUT, [id]);
    run()
      .then(_ => { document.getElementById("mainBod").scrollTop = 0; fetchLayouts(0, FETCH_LIMIT_LAYOUTS, true); resolve(); })
      .catch(err => {
        // HANDLE ERROR
        const x = new CatchedWebError(err);
        make_toast("error", x.message, true);
        reject();
      })
  }, [fetchLayouts]);

  useEffect(() => { setLayouts([]); setHasMore(true); fetchLayouts(0, FETCH_LIMIT_LAYOUTS) }, [fetchLayouts]);

  const promoteToDesign = (layoutId, force = false) => {
    const { run } = createRequest(services.wre.CREATE_DESIGN_LAYOUT, [force], { layoutId: layoutId })
    run()
      .then(res => {
        make_custom_toast('success', 'Layout', 'Promoted to design')
      })
      .catch(err => {
        setShowPromoteDesignModal(layoutId)
      })
  }


  return (
    <Fragment>
      <Row>
        {layoutsLoadingFirstTime && <Col md={12}><div className="d-flex justify-content-center pt-5"><VeryFancyLoader /></div></Col>}
        {!layoutsLoading && layoutsError && (
          <Col xs={12} className="text-center text-danger">
            {layoutsError}
          </Col>
        )}
      </Row>
      {!layoutsError && !layoutsLoadingFirstTime && !layoutsLoading && layouts.length === 0 && <Col xs={12}><div className="text-center pt-5 text-secondary">No Layouts Exist.</div></Col>}
      {!layoutsError && layouts.length > 0 &&
        <InfiniteScroll
          dataLength={layouts.length}
          next={() => { fetchLayouts(layouts.length, FETCH_LIMIT_LAYOUTS) }}
          hasMore={hasMore}
          loader={<Col md={3}><LayoutListItemSkeleton /></Col>}
          scrollThreshold={0.8}
          scrollableTarget="mainBod"
          className="row mt-1"
        >
          {layouts.map(layout => {
            return (
              <Col md={3} key={layout.id}>
                <LayoutListItem
                  layout={layout}
                  onDelete={() => { setDeleteConfirmationOpen({ id: layout.id, name: layout.name }) }}
                  promoteToDesign={() => {
                    promoteToDesign(layout.id)
                  }}
                  onHeaderClick={() => {
                    setEditorState({ isOpen: true, layout: layout, blank: false })
                  }}
                />
              </Col>
            );
          })}
        </InfiniteScroll>
      }

      {/** MODALS ============= */}
      <LayoutDeletionConfirmation
        isOpen={deleteConfirmationOpen}
        toggle={() => { setDeleteConfirmationOpen({ id: 0, name: "" }) }}
        deleteLayout={deleteLayout}
      />
      <AnimatePresence>
        {editorState.isOpen &&
          <Editor
            editorState={editorState}
            setEditorState={setEditorState}
            afterSaveSuccess={() => {
              setLayouts([]);
              setHasMore(true);
              fetchLayouts(0, FETCH_LIMIT_LAYOUTS)
            }}
          />
        }
      </AnimatePresence>
      <PromoteDesignLayoutModal
        visible={showPromoteDesignModal}
        setVisible={(show) => setShowPromoteDesignModal(show)}
        promoteToDesign={promoteToDesign}
      />
      <ImageUploadModal
        uploadImageDialog={props.uploadImageDialog}
        setUploadImageDialog={props.setUploadImageDialog}
        afterSaveSuccess={() => { setLayouts([]); setHasMore(true); fetchLayouts(0, FETCH_LIMIT_LAYOUTS) }}
      />
    </Fragment>
  );
};

LayoutList.propTypes = {
  venueId: PropTypes.number,
  listFilter: PropTypes.string,
  uploadImageDialog: PropTypes.bool,
  setUploadImageDialog: PropTypes.func,
  editorState: PropTypes.object,
  setEditorState: PropTypes.func
};
LayoutList.defaultProps = {};

export default LayoutList;
