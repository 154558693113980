/***
 *
 * Controller class for user.
 * @file CumulativeToggle.js
 * @description CumulativeToggle component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React from "react";
import { Button } from "reactstrap";
import "./CumulativeToggle.scss";

const CumulativeToggle = ({cumulative,dispatch,action,setGraphStatus}) => {
  return (
    <div className="CumulativeToggle" data-testid="CumulativeToggle">
      <div className='customButtonGroup'>
          <Button color={(cumulative?'primary':'outline-primary')}
            className={'leftToggle ' + (cumulative?'':'textBlack')} 
            onClick={() => {
              dispatch(action.setCumulative(true));
              setGraphStatus(-1);
            }}
            active={cumulative===true}>
            Cumulative
          </Button>
          <Button color={cumulative?'outline-primary':'primary'} 
            className={'rightToggle ' + (cumulative?'textBlack':'')} 
            onClick={() => {
              dispatch(action.setCumulative(false));
              setGraphStatus(-1);
              }} 
            active={cumulative===false}>
            Non - Cumulative
          </Button>
      </div>
    </div>
  );
};

CumulativeToggle.propTypes = {};

CumulativeToggle.defaultProps = {};

export default CumulativeToggle;
