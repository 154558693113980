import { activeOrgActions } from "../slices";
import store from "../store";
import createRequest, { services } from "../../services";
import { CatchedWebError } from "../../configs";
import RbacActiveOrgSwitch from "./rbac.worker";

// export const getActiveOrg = (activeOrgId) => {
//   store.dispatch(activeOrgActions.setLoading(true));
//   const { controller, run } = createRequest(services.organization.GET, [activeOrgId]);
//   run()
//     .then(response => {
//       store.dispatch(activeOrgActions.setActiveOrg(response.data));
//     })
//     .catch(err => {
//       let x = new CatchedWebError(err);
//       store.dispatch(activeOrgActions.setError(x.message));
//     })
//     .finally(() => {
//       store.dispatch(activeOrgActions.setLoading(false));
//     });
//   return controller;
// };

export const getActiveOrg = RbacActiveOrgSwitch;

export const getActiveOrgLogo = (activeOrgId) => {
  store.dispatch(activeOrgActions.setLoading(true));
  const { controller, run } = createRequest(services.organization.LOGO, [activeOrgId, 'get'], {}, true, activeOrgId);
  run()
    .then(response => {
      store.dispatch(activeOrgActions.setActiveOrgLogo(response.data));
    })
    .catch(err => {
      let x = new CatchedWebError(err);
      store.dispatch(activeOrgActions.setError(x.message));
    })
    .finally(() => {
      store.dispatch(activeOrgActions.setLoading(false));
    });
  return controller;
};

export const getActiveOrgRoles = (activeOrgId) => {
  store.dispatch(activeOrgActions.setLoading(true));
  const { controller, run } = createRequest(services.organization.GET_ROLES, [activeOrgId], {}, true, activeOrgId);
  run()
    .then(response => {
      store.dispatch(activeOrgActions.setRoles(response.data));
    })
    .catch(err => {
      let x = new CatchedWebError(err);
      store.dispatch(activeOrgActions.setError(x.message));
    })
    .finally(() => {
      store.dispatch(activeOrgActions.setLoading(false));
    });
  return controller;
}



