/***
 *
 * Controller class for user.
 * @file L2Vlan.js
 * @description L2Vlan component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader, Table, UncontrolledDropdown } from "reactstrap";
import { VeryFancyLoader } from "../../../components";
import { CatchedWebError } from "../../../configs";
import createRequest, { services } from "../../../services";
import SwitchHeader from "../_builder/SwitchHeader";
import "./L2Vlan.scss";
import {ReactComponent as Warning} from "../../../assets/images/icons/Warning.svg"
import { breadcrumbActions } from "../../../redux/slices";
import { Link, useNavigate } from "react-router-dom";
import { make_custom_toast } from "../../../helpers/toasts";
import { createErrorContext } from "../../../configs/ErrorContextMaker";
import { TICKET_CATEGORY } from "../../Tickets/TicketConstants";

export const DEFAULT_VLAN = 1

export const VlansWrapper = () => {
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const infraId = useSelector(store => store.oneInfra.data.infraItemId);
  const infraDisplayName = useSelector(store => store.oneInfra.data.infraDisplayName);
  const [currentConfigError, setCurrentConfigError] = React.useState(null);
  const [currentConfig, setCurrentConfig] = React.useState(null);
  const [silentRefetch, setSilentRefetch] = React.useState(true);
  const [currentConfigLoading, setCurrentConfigLoading] = React.useState(false);

  const navigate = useNavigate()

  useEffect(() => {
    if (infraId && silentRefetch) {
      setCurrentConfigError(null);
      setSilentRefetch(false);
      setCurrentConfigLoading(true);
      const { context, run, controller } = createRequest(services.networks.SWITCHCONFIG_GET, [infraId, activeOrgId]);
      run()
        .then(response => {
          let errorString = "Unable to process your request at this time. Please try again after sometime"
          if(!!response.data.configuration?.portsConfiguration &&
            typeof response.data.configuration?.portsConfiguration == 'object') {
            let correctConfig = true
            for(let portString of Object.keys(response.data.configuration?.portsConfiguration)) {
              if(!response.data.configuration?.portsConfiguration[portString]?.vlan) {
                correctConfig = false
                break
              }
            }
            correctConfig
            ?  setCurrentConfig(response.data)
            : setCurrentConfigError(errorString)
          }
          else
            setCurrentConfigError(errorString)
        })
        .catch(err => {
          const x = new CatchedWebError(err);
          setCurrentConfigError(x.message);
          let apiContext = createErrorContext(context, 'Unable to View L2-VLANs', TICKET_CATEGORY.INFRASTRUCTURE,err)
          make_custom_toast('error','Fetching Switch Details',x.message,true,'Create Ticket',() => {
            navigate(
              `/organization/${activeOrgId}/support/ticket/createticket`,
              {state: {ticketContext: apiContext}}
            );
          })
        })
        .finally(() => {
          setCurrentConfigLoading(false);
        })
      return () => {
        controller.abort();
      }
    }
  }, [infraId, silentRefetch]);

  const returnObject = {activeOrgId, infraId, infraDisplayName, currentConfig, setCurrentConfig, currentConfigError, setCurrentConfigError, setSilentRefetch, currentConfigLoading};

  return <>
    <L2Vlan {...returnObject} />
  </>;
  
};


// const tableData = (config) => {
//   let tableGroups = {};
//   for (let i = 0; i < 48; i++) {
//     let key = `Ethernet${i}`;
//     let vlanId = config?.configuration[key]?.vlan
//     if (vlanId) {
//       if (tableGroups[vlanId]) {
//         tableGroups[vlanId].push(i+1);
//       } else {
//         tableGroups[vlanId] = [i+1];
//       }
//     }
//   }
//   return tableGroups;
// };

/// Convert config to vlan table (only ports with trunk mode)
function tableData(config,actualToShow) {
  let portsConfig = config?.configuration?.portsConfiguration;
  let table = {};
  const configSize = Object.keys(portsConfig).length ?? 0;
  const portStringsArray = Object.keys(portsConfig)
  for (let i = 0; i < configSize; i++) {
    let key = portStringsArray[i];
    let portNumber = Number(key?.replace('Ethernet',''))
    portsConfig[key]?.vlan?.taggedVlans?.map(vlanId => {
      if(table[vlanId]?.trunkPorts instanceof Set) {
        table[vlanId].trunkPorts.add(actualToShow[portNumber])
      }
      else {
        if(table[vlanId] == null) 
          table[vlanId] = {}
        table[vlanId].trunkPorts = new Set()
        table[vlanId].trunkPorts.add(actualToShow[portNumber])
      }
    })
    let untaggedVlan = portsConfig[key]?.vlan?.untaggedVlan
    // if(portsConfig[key]?.vlan?.mode=='Access' && untaggedVlan != null) {
    if(table[untaggedVlan]?.accessPorts instanceof Set) {
      table[untaggedVlan].accessPorts.add(actualToShow[portNumber])
    }
    else {
      if(!table[untaggedVlan]) table[untaggedVlan] = {}
      table[untaggedVlan].accessPorts = new Set()
      table[untaggedVlan].accessPorts.add(actualToShow[portNumber])
    }
  }
  let vlansObj = config?.configuration?.vlans??{}
  let vlans = Object.keys(config?.configuration?.vlans??{})
  for(let i=0;i<vlans.length;i++) {
    let vlanName = vlansObj[vlans[i]].name
    let vlanIpv4 = vlansObj[vlans[i]].ipv4
    if(!!vlanName) {
      if(!table[vlans[i]]) {
        table[vlans[i]] = {name: vlanName}
      }
      else
        table[vlans[i]].name = vlanName
    }
    if(!!vlanIpv4?.subnet?.length > 0 && !!vlanIpv4?.subnet[0]["prefix"] && table[vlans[i]]) {
      table[vlans[i]].svi = vlanIpv4.subnet[0]["prefix"]
    }
    if(!!vlanIpv4?.dhcp && !!vlanIpv4?.dhcp["relay-server"]) {
      table[vlans[i]].dhcp = vlanIpv4.dhcp["relay-server"]
    }
  }
  return table
}

export const commaSeparatedList = (lst) => {
 try { lst.sort((a,b) => a-b)
  //2,8,9
  let str = "";
  let rangeStart, curr;
  for(let i=0; i< lst.length; i++) {
    //i=0
    if(!rangeStart) {
      if(lst.length == 1) {
        str+=lst[i].toString()
      }
      rangeStart = lst[i]
      curr = rangeStart
    }
    else {
      //1,2,3
      if(lst[i] === curr + 1) {
        curr = lst[i]
        if(i === lst.length - 1) {
          if(curr === rangeStart + 1)
            str+= rangeStart.toString()+","+ curr.toString()
          else
            str += (rangeStart.toString() + "-" + curr.toString())
        }
      }
      else {//1,2,4
        //1,4,7
        if(curr === rangeStart) {
          //range ends
          if(i === lst.length - 1) {
            str += (rangeStart.toString() + "," + lst[i])
          }
          else {
            str += (rangeStart.toString() + ",")
            //start new range
            rangeStart = lst[i]
            curr = lst[i]
          }
        }
        //1,2,4
        else if(curr === rangeStart + 1) {
          str += (rangeStart.toString() + ",")
          str += (curr.toString() + ",")
          if(i === lst.length-1) {
            str +=lst[i].toString()
          }
          else {
            rangeStart = lst[i]
            curr = lst[i]
          }
        }
        else {
          str += (rangeStart.toString() + "-")
          str += (curr.toString() + ",")
          if(i === lst.length-1) {
            str +=lst[i].toString()
          }
          else {
            rangeStart = lst[i]
            curr=lst[i]
          }
        }
      }
    }
  }
  return str;
}
catch(e) {
  return "";
}
  // for (let i = 0; i < lst.length - 1; i++) {
  //   str += (lst[i].toString() + ", ")
  // }
  // if (lst.length) {
  //   str += (lst[lst.length - 1]);
  // }
  // return str;
}

export function isInteger(value) {
  return /^[0-9]+$/.test(value);
}

function isRange(value) {
  return /^[0-9]+-[0-9]+$/.test(value);
}

/** Converts ports syntax (1, 3-5, 6) to array [1, 3, 4, 5, 6] */
export const parsePortsSyntax = (portsString) => {
  let ports = new Set();
  const fragments = portsString.split(",");
  for (let uncleanfragment of fragments) {
    let fragment = uncleanfragment.trim();
    if (isInteger(fragment)) {
      const num = Number(fragment);
      if (num >= 1 && num <= 48) {
        ports.add(num);
      } 
    } else if (isRange(fragment)) {
      const [start, end] = fragment.split("-").map(item => Number(item));
      if (start <= end && start >= 1 && start <= 48 && end >= 1 && end <= 48) {
        for (let i = start; i <= end; i++) {
          ports.add(i);
        }
      } else {
        return [];
      }
    } else {
      return [];
    }
  }
  return [...ports.keys()];
};

export const checkConfig = (config) => {
  let error = "Same port can not be Trunk as well as Access for the same VLAN"
  let portsConfig = config?.configuration?.portsConfiguration
  for(const key of Object.keys(portsConfig??{})) {
    if(portsConfig[key]?.vlan?.taggedVlans?.includes(portsConfig[key]?.vlan?.untaggedVlan)) {
      return {incorrect: true, error: error}
    }
  }
  return {incorrect: false, error: error}
}

const L2Vlan = ({ activeOrgId, infraId, infraDisplayName, currentConfig, currentConfigError, setCurrentConfigError, setSilentRefetch, currentConfigLoading }) => {
  const actualToShow = useSelector(store => store?.oneInfra?.actualToShow)
  const [statefulTableData, setStatefulTableData] = React.useState(null);
  const [vlansSelected, setVlansSelected] = useState({});
  const [modal, setModal] = useState([]);
  const [deleting, setDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate()

  useEffect(() => {
    if (currentConfig && !currentConfigError) {
      setStatefulTableData(tableData(currentConfig,actualToShow));
    }
  }, [currentConfig, currentConfigError]);

  const deleteVlans = useCallback((vkeys) => {
    let cfg = JSON.parse(JSON.stringify(currentConfig?.configuration?.portsConfiguration));
    for (let eth in cfg) {
      for (let vkey of vkeys) {
        // cfg[eth].vlan.mode = 'Access'
        // cfg[eth].vlan.taggedVlans = cfg[eth].vlan.taggedVlans.filter(x => x !== Number(vkey));
        if(!!cfg[eth].vlan.taggedVlans) {
          cfg[eth].vlan.taggedVlans = cfg[eth].vlan.taggedVlans?.filter(vlanId => vlanId != vkey)
        }
        if(cfg[eth].vlan.untaggedVlan == Number(vkey)) {
          cfg[eth].vlan.untaggedVlan = DEFAULT_VLAN;
        }
      }
    }
    let newConfig = { orgId: activeOrgId, configuration: {...currentConfig.configuration, portsConfiguration: cfg} };
    for (let vkey of vkeys) {
      if(!!newConfig?.configuration?.vlans[vkey])
        delete newConfig?.configuration?.vlans[vkey]
    }
    setDeleteError(false);
    setDeleting(true);
    let result = checkConfig(newConfig)
    if(result.incorrect === true) {
      setDeleteError(result.error)
      make_custom_toast('error','Deleting L2-VLAN',result.error)
      setDeleting(false);
      setSilentRefetch(true);
      return
    }

    const { context, run } = createRequest(services.networks.SWITCHCONFIG_SET, [infraId, activeOrgId], newConfig);
    run()
      .then(response => {
        setModal([]);
        setVlansSelected({});
      })
      .catch(err => {
        const x = new CatchedWebError(err);
        setDeleteError(x.message);
        let apiContext = createErrorContext(context, 'Unable to Delete L2-VLAN', TICKET_CATEGORY.INFRASTRUCTURE,err)
        make_custom_toast('error','Deleting L2-VLAN',x.message,true,'Create Ticket',() => {
          navigate(
            `/organization/${activeOrgId}/support/ticket/createticket`,
            {state: {ticketContext: apiContext}}
          );
        })
      })
      .finally(() => {
        setDeleting(false);
        setSilentRefetch(true);
      });
  }, [currentConfig, infraId, setSilentRefetch]);

  useEffect(() => {
      dispatch(breadcrumbActions.setData([
        {
          path: `/organization/${activeOrgId}/infra`,
          text: "Infrastructure",
          active: false
        },
        {
          path: `/organization/${activeOrgId}/infra/${infraId}/`,
          text: infraDisplayName,
          active: false
        },
        {
          path: `/organization/${activeOrgId}/infra/${infraId}/l2vlan`,
          text: "L2Vlan",
          active: true
        }
      ]))
  }, []);

  return (
    <div className="L2Vlan" data-testid="L2Vlan">
      <SwitchHeader />
      {/* <AlertBox color="danger" isOpen={!!currentConfigError} toggle={() => {setCurrentConfigError(null);}}>
        {currentConfigError}
      </AlertBox> */}
      {/* <VlanEditor portCount={Object.keys(currentConfig?.configuration?.portsConfiguration ?? {}).length} infraId={infraId} vlanEditor={vlanEditor} setVlanEditor={setVlanEditor} statefulTableData={statefulTableData} currentConfig={currentConfig} setSilentRefetch={setSilentRefetch} /> */}
      <Modal centered isOpen={modal.length > 0} toggle={() => {!deleting && setModal([])}}>
        <ModalHeader className="bg-white"><h4>Delete following VLAN(s)?</h4></ModalHeader>
        <ModalBody>
          {/* <AlertBox isOpen={deleteError} color="danger" toggle={() => { setDeleteError(false); }}>{deleteError}</AlertBox> */}
          <h4 className="text-center py-3">{commaSeparatedList(modal)}</h4>
          {deleting && <div className="text-danger text-center"><strong>Deleting...</strong></div>}
          <div className="text-center mb-3">Note: This only removes the association of these VLANs with their ports.</div>
          <div className="d-flex justify-content-end">
            <Button color="primary" className="mr-1" disabled={deleting} onClick={() => { setModal([]) }}>Cancel</Button>
            <Button color="outline-danger" disabled={deleting} onClick={() => { deleteVlans(modal) }}>Delete</Button>
          </div>
        </ModalBody>
      </Modal>
      <div className="bg-white p-1 rounded content-div">
        {/* <div className="d-flex justify-content-end align-items-center mb-1">
          <div>
            {Object.keys(vlansSelected).filter(it => !!vlansSelected[it]).length > 0 && <Button color="outline-danger" className="mr-1" onClick={() => { setModal(Object.keys(vlansSelected).filter(it => !!vlansSelected[it])); }}>Delete</Button>}
            <Link to={!currentConfigError?'../l2vlans/add':null}><Button color="primary" disabled={!!currentConfigError}>Add VLAN</Button></Link>
          </div>
        </div>  */}
        {currentConfigLoading
        ? <div className="w-100 pt-5 d-flex justify-content-center"><VeryFancyLoader /></div>
        :  <Table className="table-view">
              <thead>
                <tr>
                  <th>VLAN ID</th>
                  <th>Name</th>
                  <th>Trunk Ports</th>
                  <th>Access Ports</th>
                  <th>L3 SVI</th>
                  <th>DHCP Relay</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              {statefulTableData ? <tbody>
                {Object.keys(statefulTableData).map(k => {
                  let data = statefulTableData[k];
                  return (
                    <tr className="hoverable" key={k}>
                      {/* <td className="border text-center">
                        <input type="checkbox" className="check-input" checked={vlansSelected[k]} onChange={(e) => { setVlansSelected(vs => ({...vs, [k]: e.target.checked})) }} />
                      </td> */}
                      <td className="">
                        <Link to={`../l2vlans/${k}/edit`}>{k}</Link>
                      </td>
                      <td className="">
                        {data.name??"-"}
                      </td>
                      <td className="ports-ellipsed" title={commaSeparatedList(Array.from(data?.trunkPorts??[]))??"-"}>
                        {commaSeparatedList(Array.from(data?.trunkPorts??[]))??"-"}
                      </td>
                      <td className="ports-ellipsed" title={commaSeparatedList(Array.from(data?.accessPorts??[]))??"-"}>
                        {commaSeparatedList(Array.from(data?.accessPorts??[]))??"-"}
                      </td>
                      <td className="">{(!!data.dhcp && !data.svi)? <Warning height="22" width="22" />: data.svi??"-"}</td>
                      <td className="">{data.dhcp??"-"}</td>
                      <td className=" text-center">
                        <UncontrolledDropdown>
                        <DropdownToggle color="white" tag="span" className="material-symbols-outlined cursor-pointer text-primary">
                          more_vert
                        </DropdownToggle>
                          <DropdownMenu right>
                            <Link to={`../l2vlans/${k}/edit`}>
                            <DropdownItem className="w-100">
                              Edit
                            </DropdownItem>
                            </Link>
                            {/* {Number(k) != DEFAULT_VLAN
                            ? <DropdownItem className="w-100"
                                onClick={() => {setModal([k])}}>
                                Delete
                              </DropdownItem>
                            : null} */}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  );
                })}
                
              </tbody> : 
              <tbody>
                <tr>
                  <td colSpan={7} className="text-center p-5"><h5>No Assigned VLANs</h5></td>
                </tr>
              </tbody>}
            </Table>}
      </div>
    </div>
  );
};

L2Vlan.propTypes = {};

L2Vlan.defaultProps = {};

export default L2Vlan;
