import { useEffect } from "react";

function useAutoclear(variable, resetter) {
  useEffect(() => {
    if (!!variable) {
      const timeoutController = setTimeout(() => {
        resetter();
      }, 4000);

      return () => {
        clearTimeout(timeoutController);
      };
    }
  }, [variable, resetter]);
}

export default useAutoclear;