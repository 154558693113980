import PropTypes from "prop-types";
import { Layer, Line, Text } from "react-konva";
import { EditableLayout } from "../../utils";
import DrawLine from "../DrawnLine";
import { useState } from "react";

/**
 * Access Points Layer
 * @param {{
 *  scaleFactor: number,
 *  editableLayout: EditableLayout
 *  selectedAp: string,
 *  setSelectedAp: (uuid: string) => void
 * }} props 
 * @returns 
 */

const INACTIVE_COLOR = "#20c997"
const ACTIVE_COLOR = "#0d6efd"
const DrawnLines = (props) => {
    return (
        // Soon will be added here
        // <Layer>
        //     {Object.keys(props.editableLayout.infraPositions).map(uuid => {
        //         return (
        //             <DrawLine
        //                 key={uuid}
        //                 scaleFactor={props.scaleFactor}
        //                 selectedAp={props.selectedAp}
        //                 setSelectedAp={props.setSelectedAp}
        //                 data={props.editableLayout.infraPositions[uuid]}
        //                 isSelected={props.selectedAp === uuid}
        //                 uuid={uuid}
        //                 translateAp={props.translateAp}
        //                 onClick={() => {
        //                     if (props.selectedAp !== uuid) {
        //                         props.setSelectedAp(uuid)
        //                     }
        //                 }}
        //             />
        //         );
        //     })}
        // </Layer>
        <Layer  >
            {props.lines.map((line, i) => (
                <Line

                    key={i}
                    points={line.points}
                    stroke={props.selectedLineIndex == i ? ACTIVE_COLOR : INACTIVE_COLOR}
                    strokeWidth={5}
                    tension={0.5}
                    // lineCap="round"
                    lineJoin="round"
                    globalCompositeOperation={
                        true
                    }
                    onMouseEnter={e => {
                        const container = e.target.getStage().container();
                        container.style.cursor = "pointer";
                    }}
                    onMouseLeave={e => {
                        const container = e.target.getStage().container();
                        container.style.cursor = "default";
                    }}
                    onClick={() => {
                        props.setSelectedLineIndex(prevLine => {
                            if (prevLine == i) {
                                return null
                            }
                            return i
                        })
                    }}
                />
            ))}
        </Layer>
    );
};

DrawnLines.propTypes = {};
DrawnLines.defaultProps = {};

export default DrawnLines;