import { createSlice } from "@reduxjs/toolkit";

const devicesDebugInitialState = {
  status: {},
  troubleshootSuccess: false
};

const devicesDebugSlice = createSlice({
  name: "devicesDebug",
  initialState: devicesDebugInitialState,
  reducers: {
    setDevice: (state, action) => {
      let ueId = action?.payload?.ueId;
      state.status[ueId] = action?.payload;
    },
    resetDevices: (state, action) => {
      state.status = {};
    },
    setTroubleshootSuccess: (state,action) => {
      state.troubleshootSuccess = action.payload
    },
    resetTroubleshootSuccess: (state,action) => {
      state.troubleshootSuccess = false
    }
  }
});

export const devicesDebugReducer = devicesDebugSlice.reducer;
const devicesDebugActions = devicesDebugSlice.actions;
export default devicesDebugActions;

