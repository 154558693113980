import { useSelector } from "react-redux";

import ChartCardWithAreaCharts from "./ChartCardWithAreaCharts";
import { SWITCH } from "../../../utility/constants";

const DevicesChart = ({currentlyConnected, categories, series, colors, isLoading, height, width, ticks, noStyle, autoSkeleton, infraType }) => {
  const range = useSelector(store => store.activeOrg.meta.dateRange);
  
  return (
          <ChartCardWithAreaCharts
              title={`Connected ${infraType==SWITCH ? "Wired" : "Wireless"} Clients`}
              categories={categories}
              series={[series]}
              colors={colors}
              height={height}
              ticks={ticks}
              width={width}
              xlabel="Client Count"
              ylabel={range>0 ? "Date" : "Time"}
              range={range}
              isLoading={isLoading}
              noStyle={noStyle}
              autoSkeleton={autoSkeleton}
          />
  );
};

export default DevicesChart;