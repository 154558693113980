import { createSlice } from "@reduxjs/toolkit";

const criticalInitial = {
  status: "LOADING",
  loading: null,
  error: null
};

const criticalSlice = createSlice({
  name: "critical",
  initialState: criticalInitial,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    }
  }
});

export const criticalReducer = criticalSlice.reducer;
const criticalActions = criticalSlice.actions;
export default criticalActions;