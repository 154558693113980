/***
 *
 * Controller class for user.
 * @file VenueStateGroup.js
 * @description VenueStateGroup component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React from "react";
// import PropTypes from 'prop-types';
import "./VenueStateGroup.scss";
import { ReactComponent as Design } from "../../assets/images/icons/BusinessView.svg";
import { ReactComponent as Live } from "../../assets/images/icons/NetworkView.svg";
import { ReactComponent as Deployed } from "../../assets/images/icons/ProjectView.svg";
import LightBadge from "../LightBadge";
import { VENUE_STATE } from "../../utility/constants";

const VenueStateGroup = (props) => {
  const { active, clickable = false, selectedSize = 24, size = 18 } = props;
  return (
    <div className="VenueStateGroup" data-testid="VenueStateGroup">
      {
        active == VENUE_STATE.DISABLED
          ? <div>
            <LightBadge color="secondary">Disabled</LightBadge>
          </div>
          : <div className="d-flex align-items-end" style={{ gap: "0.15rem" }}>
            <div className={"live-black " + (active == VENUE_STATE.DESIGN ? "active live-green " : "") + (clickable ? "cursor-pointer" : "")}>
              <Design width={active == VENUE_STATE.DESIGN ? selectedSize : size} height={active == VENUE_STATE.DESIGN ? selectedSize : size} />
            </div>
            <div className={"deployed-black " + (active == VENUE_STATE.DEPLOYMENT ? "active deployed-green " : "") + (clickable ? "cursor-pointer" : "")}>
              <Deployed width={active == VENUE_STATE.DEPLOYMENT ? selectedSize : size} height={active == VENUE_STATE.DEPLOYMENT ? selectedSize : size} />
            </div>
            <div className={"devolping-black " + (active == VENUE_STATE.LIVE ? "active devolping-green " : "") + (clickable ? "cursor-pointer" : "")}>
              <Live width={active == VENUE_STATE.LIVE ? selectedSize : size} height={active == VENUE_STATE.LIVE ? selectedSize : size} />
            </div>
            {
              clickable
                ? <div className={"devolping-black " + (active == VENUE_STATE.DISABLED ? "active devolping-white " : "") + (clickable ? "cursor-pointer" : "")}>Disabled</div>
                : <></>
            }
          </div>
      }
    </div>
  );
};

VenueStateGroup.propTypes = {};

VenueStateGroup.defaultProps = {};

export default VenueStateGroup;
