import "./LayoutListItem.scss";
import { UncontrolledDropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import Skeleton from "../../../Dashboard/Graphs/Skeleton";

const LayoutListItemSkeleton = () => {
  return (
    <div className="LayoutListItem border rounded p-1 mb-1">
      <div className="d-flex align-items-center justify-content-between mb-50">
        <h5 className="text-primary layout-name m-0"><Skeleton width="50px" /></h5>
        <UncontrolledDropdown>
          <DropdownToggle color="white" className="material-symbols-outlined text-secondary cursor-pointer layout-options-icon" tag="span">more_vert</DropdownToggle>
          <DropdownMenu right className="p-0">
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
      <div className="text-secondary layout-updated-on"><Skeleton height /></div>
      <div className="text-primary d-inline-block py-25 mt-50 layout-type">
        <Skeleton width="60px" />
      </div>
      <div>
      <Skeleton height={178} />
      </div>
    </div>
  );
};

LayoutListItemSkeleton.propTypes = {
};
LayoutListItemSkeleton.defaultProps = {};

export default LayoutListItemSkeleton;