import { useSelector } from "react-redux";
import ChartCardWithAreaCharts from "./ChartCardWithAreaCharts";

const NetworkChart = ({categories, series1, series2, colors, isLoading, typeSeriesTrafficUplink, typeSeriesTrafficDownlink, height, width, ticks, noStyle, noCardBorder, smallTitle }) => {
  const range = useSelector(store => store.activeOrg.meta.dateRange);
  return (
      <ChartCardWithAreaCharts
          title={smallTitle?'Traffic':"Network Traffic"}
          categories={categories}
          series={[series1, series2]}
          colors={colors}
          height={height}
          range={range}
          width={width}
          isTraffic={true}
          ticks={ticks}
          xlabel={`Traffic (${typeSeriesTrafficDownlink})`}
          ylabel={range>0 ? "Date" : "Time"}
          typeSeriesTrafficUplink={typeSeriesTrafficUplink}
          typeSeriesTrafficDownlink={typeSeriesTrafficDownlink}
          isLoading={isLoading}
          showBorders={true}
          noStyle={noStyle}
          noCardBorder={noCardBorder}
          // chartOptions = {{
          //   tooltip: {
          //     custom: function({seriesIndex}) {
          //       return (`
          //       <div>
          //         <div style="background-color: #D9D9D9;height:20px"></div>
          //         <div style="padding: 0.5rem 0 0.5rem 0.5rem; font-size: 10px; word-wrap: break-word">
          //           <div style="max-width: 15rem;text-wrap: wrap; word-wrap: break-word"><b style="margin-right: 0.1rem">Infrastructure:</b> Halway 11 StoneDome Technologies</div>
          //           <div style="max-width: 15rem;text-wrap: wrap; word-wrap: break-word"><b style="margin-right: 0.1rem">Network:</b>Guest Network</div>
          //           <div style="max-width: 15rem;text-wrap: wrap; word-wrap: break-word"><b style="margin-right: 0.1rem">Band:</b>5G</div>
          //           <div style="max-width: 15rem;text-wrap: wrap; word-wrap: break-word"><b style="margin-right: 0.1rem">Channel:</b>149 (80MHz)</div>
          //         </div>
          //       </div>
          //       `)
          //     }
          // }
          // }}
      />
  );
}

export default NetworkChart;