import PropTypes from "prop-types";
import "./LayoutListItem.scss";
import { getFormattedDate } from "../utils";
import { UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { LAYOUT_TYPE } from "../constants";
import StaticLayoutRenderer from "../StaticLayoutRenderer";
import Skeleton from "../../../Dashboard/Graphs/Skeleton";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import createRequest from "../../../../services";
import { wre } from "../../../../services/wre.service";

/**
 * Layout List Item
 * @param {{
 *   layout: {
 *     "venueId": number,
 *     "name": string,
 *     "layoutType": 1 | 2 | 3,
 *     "layoutFile": string,
 *     "infraPositions": Array<{
 *       "infra_type_id": number,
 *       "x": number,
 *       "y": number
 *     }>?,
 *     "layoutJson": {
 *       "dimensions": {
 *         "length": number,
 *         "width": number,
 *         "height": number
 *         "area": number
 *       }?,
 *       "walls": Array<{
 *         "id": string,
 *         "loc": Array<number>,
 *         "material": string
 *       }>,
 *       "isActive": boolean,
 *       "id": number,
 *       "createdAt": string,
 *       "updatedAt": string
 *     }
 *   },
 *   onDelete: () => void,
 *   onHeaderClick: () => void
 * }} props - Passed down props for layout list item
 * @returns 
 */
const LayoutListItem = (props) => {
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const layoutId = props.layout.id;
  const layoutType = props.layout.layoutType;
  const layout = props.layout
  const isDesignLayout = props.isDesignLayout
  const [img, setImg] = useState(null);
  const [imgLoading, setImgLoading] = useState(true);

  useEffect(() => {
    if ((activeOrgId && layoutType === 1)) {
      // const { run, controller } = createRequest(wre.GET_IMAGE, [activeOrgId, isDesignLayout ? layout.layoutFile.slice(7) : layoutId]);
      const { run, controller } = createRequest(wre.GET_FLOORPLAN_IMAGE, [activeOrgId, layout.id]);
      run()
        .then(response => {
          setImg(response.data);

        })
        .catch(err => {
          setImg("");
        })
        .finally(() => { setImgLoading(false) })
      return () => controller && controller.abort();
    }
  }, [activeOrgId, layoutId, layoutType, isDesignLayout, layout]);

  return (
    <div className="LayoutListItem border rounded p-1 mb-1 cursor-pointer"
      onClick={() => {
        // if (props.layout.layoutType !== 1 && props.layout.layoutJson.walls.length > 0)  
        props.onHeaderClick()
      }}
    >
      <div className="d-flex align-items-center justify-content-between mb-50">
        <h5 className="text-primary layout-name m-0 cursor-pointer">
          {props.layout.name || "Untitled"}
        </h5>

        {/* removed as per discussion */}
        {/* <span class="material-symbols-outlined text-primary cursor-pointer" onClick={() => { props.onHeaderClick() }}>
          edit
        </span> */}
        {/* {
          !props.hideDropdown &&
          <UncontrolledDropdown>
            <DropdownToggle color="white" className="material-symbols-outlined text-secondary cursor-pointer layout-options-icon" tag="span">more_vert</DropdownToggle>
            <DropdownMenu right className="p-0">
              <DropdownItem className="w-100 text-danger" onClick={props.onDelete}>Delete</DropdownItem>
              <DropdownItem className="w-100 text-primary" onClick={props.promoteToDesign}>Promote to Design</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        } */}
      </div>

      {/* --- Removed Area and Tag in Figma --- */}
      {/* <div className="text-secondary layout-updated-on">Area: {(props.layout.layoutJson?.dimensions?.area ?? 0)?.toFixed(2)} m<sup>2</sup></div> */}
      {/* <div className="border-primary text-primary d-inline-block px-50 py-25 mt-50 rounded-pill layout-type">
        {LAYOUT_TYPE[props.isDesignLayout ? 4 : props.layout.layoutType]}
      </div> */}
      <div>
        {(props.layout.layoutType === 1) ?
          (imgLoading ?
            <Skeleton height="10px" /> :
            ((img === "" || img === null) ?
              <Skeleton height="176px" enableAnimation={false} /> :
              <div className="d-flex justify-content-center">
                <img height="171px" style={{ paddingTop: "5px", borderRadius: "5px", maxWidth: "100%", objectFit: "contain" }} src={img} alt={props.layout.name} />
              </div>
            )
          )
          :
          (!(props.layout?.layoutJson?.components?.length > 0) ?
            <img src={require('../../../../assets/images/icons/blank_grid.webp')} style={{ width: '100%', height: '100%' }} />
            : <StaticLayoutRenderer
              layout={props.layout.layoutJson}
              completeLayout={props.layout}
              //  width="100%" //hinthere removed byrd
              height="178px"
              hideBackGrid={props.hideBackGrid}
              showAP={props.showAP}
            />
            // : null
          )
        }
      </div>
    </div>
  );
};

LayoutListItem.propTypes = {
  layout: PropTypes.object,
  onDelete: PropTypes.func,
  promoteToDesign: PropTypes.func,
  onHeaderClick: PropTypes.func
};
LayoutListItem.defaultProps = {};

export default LayoutListItem;