/***
 *
 * Controller class for user.
 * @file NetworkImage.js
 * @description NetworkImage component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React, { useEffect } from "react";
// import PropTypes from 'prop-types';
import "./NetworkImage.scss";
import { ENTERPRISE, MPSK, PASSPHRASE, PUBLIC } from "../../utility/constants";
import networkTypePadlockClosed from "../../assets/images/Network Padlock Closed.svg";
import networkTypePadlockOpen from "../../assets/images/Network Padlock Open.svg";
import networkTypeAAA from "../../assets/images/AAA Network.svg";

const NetworkImage = (props) => {
  const {networkImage, setNetworkImage, networkType, className} = props;

  useEffect(() => {
    if(!!setNetworkImage) {
      if(networkType == PASSPHRASE) {
        setNetworkImage(networkTypePadlockClosed);
      }
      else if(networkType == PUBLIC) {
        setNetworkImage(networkTypePadlockOpen);
      }
      else if(networkType == MPSK || networkType == ENTERPRISE) {
        setNetworkImage(networkTypeAAA);
      }
    }
  }, [networkType])

  return (
    <img key={networkImage} className={`NetworkImage network-image ${className?className:''}`} alt="network type illustration" 
      src={networkImage}/>
  );
};

NetworkImage.propTypes = {};

NetworkImage.defaultProps = {
  title:false
};

export default NetworkImage;
