import { identitiesActions } from "../slices";
import store from "../store";
import createRequest, { services } from "../../services";
import { CatchedWebError } from "../../configs";

const getIdentities = (orgId, offset, limit, search, order = 'ASC', orderBy = 'userName', setHasMore = () => { }, overwrite = false, filterData) => {
  store.dispatch(identitiesActions.setLoading(true));
  const { controller, run } = createRequest(services.organization.GET_IDENTITIES, [orgId, offset, limit, search, order, orderBy, filterData?.roles, filterData?.isBlocked]);
  run()
    .then(response => {
      if (overwrite)
        store.dispatch(identitiesActions.setFreshIdentities({ data: response.data }));
      else
        store.dispatch(identitiesActions.addIdentities({ data: response.data, offset: offset }));
      setHasMore(response.data.length === limit);
    })
    .catch(err => {
      let x = new CatchedWebError(err);
      store.dispatch(identitiesActions.setError(x.message));
      setHasMore(false);
    })
    .finally(() => {
      store.dispatch(identitiesActions.setLoading(false));
    });
  return controller;
}

export default getIdentities;