/***
 *
 * Controller class for user.
 * @file TimeModal.js
 * @description TimeModal component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import React from "react";
// import PropTypes from 'prop-types';
import { 
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button
} from "reactstrap";

import { Fragment, useState } from 'react'
import Flatpickr from 'react-flatpickr'
import '@styles/react/libs/flatpickr/flatpickr.scss'


const PickerRange = (props) => {

  return (
    <Fragment>
      <Flatpickr
        id='range-picker'
        className='form-control'
        onChange={date => {
          if(date.length === 1) {
            let fromDate = new Date(date[0].getTime() - (date[0].getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
            props.setModalDateRange([fromDate,fromDate]);
          }
          else {
            let fromDate = new Date(date[0].getTime() - (date[0].getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
            let toDate = new Date(date[1].getTime() - (date[1].getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
            props.setModalDateRange([fromDate,toDate]);
          }
        }}
        options={{
          mode: 'range',
          defaultDate: [new Date().toISOString().substring(0, 10),new Date(new Date(Date.now() - 864e5)).toISOString().substring(0, 10)],
          maxDate: new Date().toISOString().substring(0, 10)
        }}
      />
    </Fragment>
  )
}

const TimeModal = (props) => {
 const [modalDateRange,setModalDateRange] = useState(props.dateRange);
 
  return (
    <Modal centered isOpen={props.isOpen} toggle={() => { props.setOpenState(false); }}>
      <ModalHeader toggle={() => { props.setOpenState(false); }}>Please select the date range</ModalHeader>
      <ModalBody>
          <PickerRange modalDateRange={modalDateRange} setModalDateRange={setModalDateRange}/>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex justify-content-center">
          <Button color="primary" onClick={() => {
            props.setDateRange(modalDateRange);
             props.setOpenState(false);
             }}>OK</Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

TimeModal.propTypes = {};

TimeModal.defaultProps = {};

export default TimeModal;
