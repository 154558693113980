/***
 *
 * Controller class for user.
 * @file InfraInfo.js
 * @description InfraInfo component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import "./InfraInfo.scss";
import createRequest, { services } from "../../services";
import { NETWORK_VIEW } from "../../utility/constants";
import { isoDate } from "../../pages/Dashboard/Graphs/Utils";

const InfraInfo = ({infraIds, type='move', orgId}) => {
  const [counts, setCounts] = useState({
    total: infraIds?.size,
    online: 0,
    clients: 0
  })
  const [loading1, setLoading1] = useState(true)
  const [loading2, setLoading2] = useState(true)


  useEffect(() => {
    // console.log(infraIds)
    setCounts(prevState => {
      return {
        ...prevState,
        total: infraIds?.size
      }
    })
  }, [infraIds])
  
  // useEffect(() => {
  //   console.log("infra ids: ",infraIds)
  // }, [infraIds])
  // useEffect(() => {
  //   console.log("counts: ",counts)
  // }, [counts])
  // useEffect(() => {
  //   console.log("loading1: ",loading1)
  // }, [loading1])
  // useEffect(() => {
  //   console.log("loading2: ",loading2)
  // }, [loading2])
  
  useEffect(() => {
    if(orgId != null) {
      const {run} = createRequest(services.telemetry.GET_INFRA_HIERARCHY_LIST_COUNT,[
        orgId,
        [],
        NETWORK_VIEW,
        '',
        {infraIds: Array.from(infraIds ?? [])},
        false
      ])
      run()
      .then(response => {
        setCounts(prevState => {return {...prevState, online: response.data.online}})
      })
      .finally(() => {
        setLoading1(false)
      })
      const {run:run2} = createRequest(services.devices.UE_FILTER_COUNT_ORG_LEVEL,[
        orgId,
        '',
        [],
        'connected',
        'all',
        new Date().getTimezoneOffset(),
        isoDate(),
        {infraIds: Array.from(infraIds ?? [])}
      ])
      run2()
      .then(response => {
        setCounts(prevState => {return {...prevState, clients: response.data.connected}})
      })
      .finally(() => {
        setLoading2(false)
      })
    }
  }, [infraIds, orgId])

  // useEffect(() => {
  //   console.log("infra ids changed")
  // }, [infraIds])
  // useEffect(() => {
  //   console.log("activeOrgId changed")
  // }, [activeOrgId])
  

  if(loading1 || loading2)
    return null

  return (
    <div className="InfraInfo w-100" data-testid="InfraInfo">
      {
        counts?.total > 0 ? (
          <>
          {`This will ${type} ${counts?.total} infrastructure`}
          {
            counts?.online != null ? 
              counts?.total == 1 ? (
                <>
                {`, it is currently ${counts?.online == 1 ?'':'not'} `}<span className="text-success">online</span>
                {
                  (counts?.online > 0 && counts?.clients != null) ? (
                    <>
                    {` and has ${counts?.clients} ${counts?.clients==1?'client':'clients'} connected to it.`}
                    </>
                  ) : '.'
                }
                </>
              ) : (
              <>
              , {counts?.online} of which are currently <span className="text-success">online</span>
              {
                (counts?.online > 0 && counts?.clients != null) ? (
                  <>
                  {` and have ${counts?.clients} ${counts?.clients==1?'client':'clients'} connected to them.`}
                  </>
                ) : '.'
              }
              </>
            ) : '.'
          }
          </> 
        ) : null
      }
    </div>
  );
};

InfraInfo.propTypes = {};

InfraInfo.defaultProps = {};

export default InfraInfo;
