const APSVG = ({ height = "24", width = "24", className }) => {
  const blue = '#1c6bd6';
  const dark = '#333333';
  return (
    <svg className={className} width={width} height={height} id="Layer_5" data-name="Layer 5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g>
        <path style={{ fill: dark }} d="m8.973,10c-.122,0-.244-.045-.341-.134-.202-.188-.213-.505-.025-.707.438-.469.958-.837,1.547-1.095.587-.257,1.211-.389,1.854-.391.646.026,1.269.125,1.857.377.591.254,1.113.618,1.554,1.084.189.201.181.518-.02.707-.201.191-.517.181-.707-.02-.346-.366-.757-.652-1.222-.852-.458-.197-.945-.297-1.446-.297h-.013c-.506.002-.995.104-1.457.307-.463.203-.872.492-1.215.86-.099.105-.232.159-.366.159Z" />
        <path style={{ fill: dark }} d="m7.144,7.459c-.122,0-.244-.044-.341-.134-.202-.188-.213-.505-.025-.707.672-.721,1.471-1.286,2.375-1.682.904-.395,1.862-.597,2.846-.6h.024c.978,0,1.929.195,2.827.58.904.388,1.708.947,2.387,1.664.189.2.181.517-.02.707-.201.19-.518.18-.707-.02-.584-.617-1.275-1.099-2.054-1.432-.773-.332-1.593-.5-2.435-.5h-.02c-.847.003-1.671.177-2.449.516-.778.34-1.466.827-2.043,1.447-.099.105-.232.159-.366.159Z" />
        <path style={{ fill: dark }} d="m4.702,5.184c-.122,0-.245-.045-.341-.135-.202-.188-.213-.504-.024-.706.985-1.057,2.155-1.886,3.479-2.463,1.324-.579,2.728-.875,4.171-.88h.039c1.432,0,2.824.286,4.14.85,1.327.57,2.504,1.391,3.496,2.439.189.201.181.518-.02.707-.201.19-.518.18-.707-.02-.898-.949-1.963-1.692-3.164-2.207-1.189-.511-2.45-.77-3.746-.77h-.034c-1.307.005-2.576.272-3.774.796-1.197.522-2.256,1.272-3.148,2.229-.098.105-.231.159-.365.159Z" />
      </g>
      <path style={{ fill: dark }} d="m14,12c1.654,0,3,1.346,3,3v4c0,1.654-1.346,3-3,3h-4c-1.654,0-3-1.346-3-3v-4c0-1.654,1.346-3,3-3h4m0-1h-4c-2.209,0-4,1.791-4,4v4c0,2.209,1.791,4,4,4h4c2.209,0,4-1.791,4-4v-4c0-2.209-1.791-4-4-4h0Z" />
    </svg>

  )
};
// ORANGE #f77a0a
// BLUE '#1c6bd6'
export default APSVG;