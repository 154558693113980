/***
 *
 * Controller class for user.
 * @file PermissionDenied.js
 * @description PermissionDenied component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React from "react";

const PermissionDenied = () => {
  return (
    <div className="PermissionDenied d-flex justify-content-center m-2" data-testid="PermissionDenied">
      <h1>Permission Denied</h1>
    </div>
  );
};

PermissionDenied.propTypes = {};

PermissionDenied.defaultProps = {};

export default PermissionDenied;
