import { deviceActions } from "../slices";
import store from "../store";
import createRequest, { services } from "../../services";
import { CatchedWebError } from "../../configs";
import { make_custom_toast } from "../../helpers/toasts";

const BanApiSortOptions = new Set([
  "blockClientId",
  "clientName",
  "macAddress",
  "networkName",
  "lastAccess",
  "bannedDate",
]);

const getDevices = (
  orgId,
  offset,
  limit,
  search,
  setHasMore,
  overwrite = false,
  sort,
  filter,
  columns,
  status,
  connectionType,
  startDate
) => {
  store.dispatch(deviceActions.setLoading(true));
  if(overwrite) {
    setHasMore(true)
  }
  if(overwrite)
    store.dispatch(deviceActions.resetSocketData());
  store.dispatch(deviceActions.setListType(status));
  store.dispatch(deviceActions.setConnectionType(connectionType));
  store.dispatch(deviceActions.setSearch(search));
  store.dispatch(deviceActions.setFilterData(filter));
  const { run, controller } =
    status === "banned"
      ? createRequest(services.networks.GET_BAN_CLIENT, [
          orgId,
          offset,
          limit,
          search,
          BanApiSortOptions.has(sort?.orderBy)
            ? sort
            : { orderBy: "blockClientId", order: "ASC" },
        ])
      : createRequest(services.devices.GET_ORG, [
          orgId,
          offset,
          limit,
          search,
          sort,
          filter,
          Object.keys(columns ?? {}).filter(
            (column) => columns[column] === true
          ),
          status,
          connectionType,
          startDate,
          new Date().getTimezoneOffset(),
        ]);

  // store.dispatch(deviceActions.setController(controller))
  run()
    .then((response) => {
      if (response.data.length < limit) setHasMore(false);
      if (overwrite) {
        if (response.data.length >= limit) setHasMore(true);
        store.dispatch(deviceActions.setItems(response.data));
      }
      else
        store.dispatch(
          deviceActions.addItems({
            data: response.data,
            // offset: offset,
            // orgId: orgId,
          })
        );
    })
    .catch((err) => {
      setHasMore(false);
      let x = new CatchedWebError(err);
      make_custom_toast("error", "Clients", x.message);
      // store.dispatch(deviceActions.setError(x.message));
      if (overwrite) store.dispatch(deviceActions.reset());
    })
    .finally(() => {
      store.dispatch(deviceActions.setLoading(false));
    });
};

export default getDevices;
