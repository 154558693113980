/***
 *
 * Controller class for user.
 * @file Activation.js
 * @description Activation component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import React, { createContext, useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import "./Activation.scss";
import { Button, Row } from "reactstrap";
import VenueInfraWidget from "../../../components/VenueInfraWidget";
import createRequest, { services } from "../../../services";
import { useSelector } from "react-redux";
import { NETWORK_ASSOCIATION } from "../../../utility/constants";

export const VenueSelectionContext = createContext(null)


const Activation = ({ stepper, handleActivation }) => {
  const activeOrg = useSelector(store => store.activeOrg.data); 
  const SCREEN_ID = "association";
  const [venueSelection, setVenueSelection] = useState({
    venues:new Set(),
    infras:new Set(),
    venueDelete:new Set(),
    infraDelete:new Set()
  })
  const [selections, setSelections] = useState({infras:{}, venues:{}})  
  const [treeStruct, setTreeStruct] = useState();

  useEffect(() => {
    const {run} = createRequest(services.networks.GET_ASSOCIATION, [activeOrg.orgId,undefined,undefined,NETWORK_ASSOCIATION]);

    run()
    .then(response => {
      setTreeStruct(response.data);
      let sel = {infras:{}, venues:{}}
      Object.keys(response?.data?.infrastructure??{}).map(infra => {
        sel.infras[infra] = response?.data?.infrastructure[infra]
      })
      Object.keys(response?.data?.venue??{}).map(ven => {
        sel.venues[ven] = response?.data?.venue[ven].inheritance
      })

      setSelections({...sel})
    })
  },[])

  // useEffect(() => {
  //   console.log("venueSel--->", venueSelection);
  // },[venueSelection])

  return (
    <div className="Activation" data-testid="Activation">
      <Row className="d-block mt-1">
        <VenueInfraWidget
          isSelectorDefault
          hideStatusIcon
          infraFocus
          // infraAPIs
          selections={selections}
          treeStruct = {treeStruct}
          setSelections={setSelections}
          venueSelection={venueSelection}
          setVenueSelection={setVenueSelection}
          associationScrn
          showAP
          initFilter= {{infraCategory:"AP"}}
          venueId={1}
          SCREEN_ID={SCREEN_ID}
          filterReq={{
            CategoryFilter: false,
            InfraTypeFilter: false,
            StatusFilter: true,
            FWFilter: true,
          }}
          startLevel={1}
        />
        {/* <VenueListing isSelector={true} /> */}
      </Row>
      <div className="d-flex justify-content-end mt-50">
        <Button.Ripple
          className="mr-1"
          color="primary"
          onClick={() => stepper.previous()}
          outline
        >
          Back
        </Button.Ripple>
        <Button.Ripple
          color="primary"
          type="submit"
          onClick={() => {
            handleActivation(venueSelection)
          }}
        >
          Complete
        </Button.Ripple>
      </div>
    </div>
  );
};

Activation.propTypes = {};

Activation.defaultProps = {};

export default Activation;
