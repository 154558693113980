import cig from "../../../assets/images/icons/CIG196.png";
import cig_1 from "../../../assets/images/marketplace/196Image.svg";
import as4630 from "../SwitchOverview/Previewer.webp";
import eap104 from "../../../assets/images/marketplace/104Image.svg";
import eap101_1 from "../../../assets/images/icons/EAP101.png";
import eap101_2 from "../../../assets/images/icons/EAP101_TOP.png";
import eap101_3 from "../../../assets/images/icons/EAP101_Side.png";
import eap102_1 from "../../../assets/images/icons/EAP102.png";
import eap102_2 from "../../../assets/images/icons/EAP102_Top.png";
import eap104_1 from "../../../assets/images/icons/EAP104.png";
export const PORT_DATA={
    10:{
    ports:[{
      maxSpeed:1000,
      editable: true,
      name:"LAN2",
    },{
      maxSpeed:1000,
      editable: true,
      name:"LAN1"
    },{
      editable: false,
      name:"Uplink (PoE)",
    }]},
    11:{
    ports:[
      {
        editable: false,
        name:"Uplink (PoE)"
      },{
        maxSpeed:2500,
        editable: true,
        name:"LAN"
    }]},
    12:{
    ports:[{
      maxSpeed:1000,
      editable: true,
      name:"LAN4"
    },{
      maxSpeed:1000,
      editable: true,
      name:"LAN3",
    },{
      maxSpeed:1000,
      editable: true,
      name:"LAN2",
    },{
      maxSpeed:1000,
      editable: true,
      name:"LAN1",
    },{
      editable: false,
      name:"UpLink(PoE)",
    }]},
    6:{
    ports:[{
        maxSpeed:5000,
        editable: true,
        name:"LAN",
    },{
      editable: false,
      name:"Uplink (PoE)"
    }]}
  }

  export const IMGS = {
    6:[cig,cig_1],
    10:[eap101_1, eap101_2, eap101_3],
    11:[eap102_1, eap102_2],
    12:[eap104_1, eap104],
    13:[as4630]
  }