export function isoDate(daysBefore = 0) {
  let date = new Date();
  let tzoffset = new Date().getTimezoneOffset();
  date.setDate(date.getDate() - daysBefore);
  return (new Date(date.getTime() - (tzoffset * 60000))).toISOString().substring(0, 10);
}

export const DateRange = (range = 6) => {
  if (range === 0) {
      return { value: 0, label: "Last 24 Hours" };
  } else if (range === 6) {
      return { value: 6, label: "Last 7 Days" };
  } else if(range===29) {
      return { value: 29, label: "Last 30 Days" };
  } 
  else if(range===0.25) {
    return { value: 0.25, label: "Last 6 Hours" };
  }
  else {
    //returning 6 as default
    return {value: 6, label:"--"}
  }
};