/***
 *
 * Controller class for user.
 * @file Events.js
 * @description Events component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */
import React, { Suspense, lazy, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Spinner, Table, UncontrolledTooltip } from "reactstrap";
import { ColumnHeader, HealthTimeline } from "../../../components";
import { breadcrumbActions } from "../../../redux/slices";
import Blazer from "../../../redux/slices/blazer.slice";
import blazer from "../../../services/blazer.service";
import { dateTimeFormatter } from "../../../utility/Localization";
import { isoDate } from "../../Dashboard/Graphs/Utils";
import useTimedCaller from "../../Dashboard/NewDashboard/useTimedCaller";
import Header from "../_builder/Header";
import "./Events.scss";
import SwitchHeader from "../_builder/SwitchHeader";
import LightBadge from "../../../components/LightBadge";
import { UPTIME } from "../Details";
import { ReactComponent as CsvIcon } from "../../../assets/images/icons/xls_icon.svg";
import { services } from "../../../services";
import { downloadSpreadsheet } from "../../../utility/Utils";
import { CatchedWebError } from "../../../configs";
import { make_custom_toast } from "../../../helpers/toasts";
import AlarmDesc from "../../../components/AlarmDesc";

const DateRangeSelector = lazy(() => import("../../Dashboard/Graphs/DateRangeSelector"));
const RefreshButton = lazy(() => import("../../Dashboard/Graphs/RefreshButton"));
const Skeleton = lazy(() => import("../../Dashboard/Graphs/Skeleton"));

export const impactColor = {
  "Critical": "danger",
  "Minor": "success"
}

const Events = () => {
  
  const identity = useSelector(store => store.identity);
  const infraData= useSelector(store => store.oneInfra.data);
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const range = useSelector(state => state.activeOrg.meta.dateRange);
  const eventRef = useSelector(state => state.infra_events);
  const healthRef = useSelector(state => state.infra_health);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [events, setEvents] = useState([])
  const [sort, setSort] = useState({
    order:"DESC",
    orderBy:"timestamp"
  })
  const [downloadingList, setDownloadingList] = useState(false);
  const infraType = useSelector(store => store.infraTypes.data);
  const [type, setType] = useState(infraType.find(itype => itype.infraTypeId === infraData.infraTypeId)?.infraCategory === 1 ?
  "AP"
  :"Switch");
  
  useTimedCaller({ service: blazer.INFRA_EVENTS, params: [infraData.macAddress, isoDate(range), isoDate(), new Date().getTimezoneOffset(), sort], data: {} }, range, infraData.macAddress, state => state.infra_events, Blazer.infra_events.actions, forceRefresh, setForceRefresh);
  useTimedCaller({ service: blazer.INFRA_HEALTH, params: [infraData.macAddress, isoDate(range), isoDate(), new Date().getTimezoneOffset()], data: {} }, range, infraData.macAddress, state => state.infra_health, Blazer.infra_health.actions, forceRefresh, setForceRefresh);
  
  useEffect(() => {
    infraType.find(itype => itype.infraTypeId === infraData.infraTypeId)?.infraCategory === 1 ?
    setType("AP")
    :setType("Switch")
  }, [infraType,infraData])
  

  useEffect(() => {
    setForceRefresh(true);
  },[sort])
  
  useEffect(() => {
    setEvents(JSON.parse(eventRef.data)?.eventsTrend);
  },[eventRef])
  
  const [active, setActive] = useState(-1);
  const dispatch = useDispatch();


  useEffect(() => {
    setActive(-1)
  },[range])

  const onClip = (from, to) => {
    setEvents(prevState => {
      let newEvents = [...prevState.filter(it => new Date(it.label).getTime()>=from && new Date(it.label).getTime()<=to)]

      return [...newEvents];
    })
  }

  const revertClip = () => {
    setEvents(JSON.parse(eventRef.data)?.eventsTrend);
  }

  useEffect(() => {
    dispatch(breadcrumbActions.setData(
     [{
        path:`/organization/${activeOrgId}/infra`,
        text:"Infrastructure",
        active:false
      },
      {
        text:infraData.infraDisplayName,
        active:false
      },{
        path:`/organization/${activeOrgId}/infra/${infraData.infraItemId}/events/`,
        text:"Timeline",
        active:true
      }]
    ))    
  },[infraData.infraItemId])

  // useEffect(() => {
  //   console.log("HealthRef--->",JSON.parse(healthRef.data));
  // },[healthRef])
  
  const handleDownload = () => {
    const apiURL = services.telemetry.DOWNLOAD_INFRA_EVENTS.urlBuilder(...[
      infraData.macAddress, 
      isoDate(range), 
      isoDate(), 
      new Date().getTimezoneOffset(), 
      sort
    ]
    )
    const fileName = `Infrastructure Events List${!!infraData.infraItemId ? ('-(' + infraData.infraItemId + ')') : ''}.xlsx`;
    setDownloadingList(true)
    downloadSpreadsheet(apiURL, fileName, identity.meta.token)
    .catch(err => {
      make_custom_toast('error',`Export Events List`,(new CatchedWebError(err)).message)
    })
    .finally(() => {
      setDownloadingList(false)
    })
  }

  return (
    <div className="Events" data-testid="Events">
      <Row>
      <Col md={8} xs={12}>
        {type==='AP'?
        <Header heading={infraData.infraDisplayName} />
      :<SwitchHeader heading={infraData.infraDisplayName} activeOrgId={activeOrgId} infraId={infraData?.infraItemId}/>}
      </Col>
      <Col md={4} xs={12} className="pt-1">
        <div className="d-flex justify-content-end align-items-center">
          <Suspense fallback={<></>}>
            <DateRangeSelector />
          </Suspense>
          <Suspense fallback={<></>}>
            <RefreshButton clickState={forceRefresh} clickHandler={setForceRefresh} clickFunc={revertClip}/>
          </Suspense>
          <Suspense fallback={<></>}>
            <CsvIcon
              height={24}
              width={24}
              className={` ml-1 ${
                downloadingList
                  ? "list-download-icon--disabled"
                  : "cursor-pointer"
              }`}
              onClick={() => {
                if (!downloadingList) {
                  handleDownload()
                }
              }}
              title={`Export List\n(maximum limit: 5000)`}
            />
          </Suspense>
        </div>
      </Col>
      </Row>
      <div className="p-2 bg-white rounded shadow-sm border">
        <div style={{minHeight:"180px"}}>
          {console.log("---", eventRef.isLoading ,healthRef.isLoading)}
          {(!eventRef.isLoading && !healthRef.isLoading)
          ? <Suspense fallback={<></>}>
          <HealthTimeline bgColor="#D9D9D9" sectionColor= "#1FC78F" active={active} data={JSON.parse(healthRef.data)?.online} events={JSON.parse(eventRef.data)?.eventsTrend}
              minDate={range>0 ? isoDate(range) : new Date(new Date().setDate(new Date().getDate()-1))} maxDate={new Date()} setActive={setActive}
              healthHeading={"Event Timeline"} on={type==='AP'? "AP" : "Switch"} onClip={onClip} revertClip={revertClip}/>
            </Suspense>
          : <Suspense fallback={<></>}><Skeleton height="170px"/></Suspense> }
        </div>
        
        <div className="mt-2" id="infra-timeline-list">
          <Table className="fixed-header table-view">
            <thead>
              <tr>
                <th style={{minWidth:"150px"}}>
                  <ColumnHeader header="TIME" attribute="timestamp" setter={setSort} sort={sort}/>
                </th>
                <th style={{minWidth:"100px"}}>
                  <ColumnHeader header="Severity" attribute="impact" setter={setSort} sort={sort}/>
                </th>
                <th style={{minWidth:"200px"}}>
                  <ColumnHeader header="Event" attribute="event" setter={setSort} sort={sort}/>
                </th>
                <th style={{minWidth:"250px"}}>DESCRIPTION</th>
              </tr>
            </thead>
            {eventRef.isLoading ? 
              <tbody>
                <tr>
                  <td colSpan={10} className="text-center p-5"><Spinner color="primary" /></td>
                </tr>
              </tbody> :
              <tbody>
                {(JSON.parse(eventRef.data)?.eventsTrend?.length>0)?
                    events?.map((event, index) => {
                    return(<tr>
                    <td>
                      <span className="cursor-pointer pr-1" id={`Events${index}`}>
                        {UPTIME(event.label,true,true,false)} ago
                      </span>
                      <UncontrolledTooltip placement="right" target={`Events${index}`}>
                        {dateTimeFormatter(event.label,"medium", "short")}
                      </UncontrolledTooltip>
                    </td>
                    <td><div className="d-flex"><LightBadge color={impactColor[event.impact]??"danger"}>{event.impact??"Critical"}</LightBadge></div></td>
                    <td>{event.display_name}</td>
                    <td><AlarmDesc alarmType={event.event_type} data={event.payload} desc={event.description} orgId={activeOrgId}/></td>
                    </tr>)
                  })
                  :<tr><td className="text-center" colSpan={10}>No Event found!</td></tr>
                }
              </tbody>}
          </Table>
        </div>
        <span className="mt-50">Showing {events?.length} result(s).</span>
        </div>
      </div>
  );
};

Events.propTypes = {};

Events.defaultProps = {};

export default Events;
