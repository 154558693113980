const SwitchSVG = ({ width = "22", height = "22", className }) => {
  const colorFill = '#333333' // '#1c6bd6'
  return (
    <svg className={className} width={width} height={height} id="Layer_5" data-name="Layer 5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path style={{ fill: colorFill }} d="m20.813,8c.103,0,.187.084.187.187v7.626c0,.103-.084.187-.187.187H3.187c-.103,0-.187-.084-.187-.187v-7.626c0-.103.084-.187.187-.187h17.626m0-1H3.187c-.656,0-1.187.531-1.187,1.187v7.626c0,.656.531,1.187,1.187,1.187h17.626c.656,0,1.187-.531,1.187-1.187v-7.626c0-.656-.531-1.187-1.187-1.187h0Z" />
      <path style={{ fill: colorFill }} d="m1.923,8c.042,0,.076.034.076.076v7.848c0,.042-.034.076-.076.076h-.847c-.042,0-.076-.034-.076-.076v-7.848c0-.042.034-.076.076-.076h.847m0-1h-.847C.482,7,0,7.482,0,8.076v7.848C0,16.518.482,17,1.076,17h.847c.594,0,1.076-.482,1.076-1.076v-7.848c0-.594-.482-1.076-1.076-1.076h0Z" />
      <path style={{ fill: colorFill }} d="m22.687,8c.172,0,.313.14.313.313v7.375c0,.172-.14.313-.313.313h-.374c-.172,0-.313-.14-.313-.313v-7.375c0-.172.14-.313.313-.313h.374m0-1h-.374c-.725,0-1.313.588-1.313,1.313v7.375c0,.725.588,1.313,1.313,1.313h.374c.725,0,1.313-.588,1.313-1.313v-7.375c0-.725-.588-1.313-1.313-1.313h0Z" />
      <path style={{ fill: colorFill }} d="m7,11v2h-2v-2h2m0-1h-2c-.552,0-1,.448-1,1v2c0,.552.448,1,1,1h2c.552,0,1-.448,1-1v-2c0-.552-.448-1-1-1h0Z" />
      <path style={{ fill: colorFill }} d="m19,11v2h-7v-2h7m0-1h-7c-.552,0-1,.448-1,1v2c0,.552.448,1,1,1h7c.552,0,1-.448,1-1v-2c0-.552-.448-1-1-1h0Z" />
      <path style={{ fill: colorFill }} d="m13.5,14c-.276,0-.5-.224-.5-.5v-3c0-.276.224-.5.5-.5s.5.224.5.5v3c0,.276-.224.5-.5.5Z" />
      <path style={{ fill: colorFill }} d="m15.5,14c-.276,0-.5-.224-.5-.5v-3c0-.276.224-.5.5-.5s.5.224.5.5v3c0,.276-.224.5-.5.5Z" />
      <path style={{ fill: colorFill }} d="m17.5,14c-.276,0-.5-.224-.5-.5v-3c0-.276.224-.5.5-.5s.5.224.5.5v3c0,.276-.224.5-.5.5Z" />
    </svg>

  )
};

export default SwitchSVG;