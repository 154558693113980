/**
 * Radio Input
 * @file RadioInput.js
 * @description Formik compatible radio input styled with Reactstrap components.
 * @author Utkarsh Gupta
 * @since 29 June 2022
 */

import React from "react";
import PropTypes from 'prop-types';
import "./RadioInput.scss";
import { useField } from "formik";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";

const RadioInput = ({children, ...props}) => {
  const [field, meta] = useField({...props, type: "radio"});
  return (
    <FormGroup check className="RadioInput" data-testid="RadioInput">
      <Label className="labelfont" for={props.id || props.name} check>
        <Input type="radio" {...field} {...props} />
        {children}
      </Label>
      {meta.touched && meta.error ? (
        <FormFeedback>{meta.error}</FormFeedback>
      ) : null}
    </FormGroup>
  );
};

RadioInput.propTypes = {
  children: PropTypes.any,
  props: PropTypes.any
};

RadioInput.defaultProps = {};

export default RadioInput;
