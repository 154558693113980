import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CatchedWebError } from "../../../configs";
import createRequest from "../../../services";
import blazer from "../../../services/blazer.service";

const SHORT_TIMEOUT = 180000; // ms
const LONG_TIMEOUT = 300000; // ms
// const REALTIME_TIMEOUT = 60000; // ms

const isTooOld = time => (Date.now() - time > LONG_TIMEOUT);


/**
 * Run refetch monitors for an API Call backed with redux (BLAZER ONLY)
 * @param {{service, params, data}} api - API Service packaged with data
 * @param {string | number} unique - Identifier
 * @param {(state) => {}} reduxReferer - Function to dig through redux 
 * @param {() => {}} reduxAction - Function to dispatch redux changes
*/

function useTimedCaller(api, range, identifier, reduxReferer, reduxAction, forced = false, setForced = () => {}, always=false) {
  const { isLoading, time, isError, data, id, period } = useSelector(reduxReferer);
  const dateRange = useSelector(store => store.activeOrg.meta.dateRange);
  const [tabActive, setTabActive] = useState(true)
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const dispatch = useDispatch();
  let interval = null

  const CallAPI = (run) => {
    if(!document.hidden || data == null)
      {
        dispatch(reduxAction.setLoading(true));
        run()
          .then(response => {
          dispatch(reduxAction.setData({ data: response.data, id: identifier, period: range }))
          dispatch(reduxAction.setSuccess(true));
          dispatch(reduxAction.setError(null));
          })
          .catch(err => {
          if(!data || period !== range){
            const x = new CatchedWebError(err);
            dispatch(reduxAction.setError(x.message));
            dispatch(reduxAction.setSuccess(false));
          }
          })
          .finally(() => {
          dispatch(reduxAction.setLoading(false));
          setForced(false);
          dispatch(reduxAction.setTime(Date.now()));
          });
      }
  }

  useEffect(() => {
    dispatch(reduxAction.setError(null));
  },[forced, always, range, dateRange, identifier, activeOrgId]);

  useEffect(() => {
    const differentRange = period !== range;
    const { run, controller } = createRequest(api.service, api.params, api.data = {});
    // (
    //   range===0.25?
    //     createRequest(api.service, [identifier, '','', new Date().getTimezoneOffset(),true], api.data = {})
    //   : createRequest(api.service, api.params, api.data = {}));
    if(api?.service===blazer?.NETWORK_NETWORK && api?.params[1]?.length === 0) {
      dispatch(reduxAction.setData({ data: null, id: identifier, period: range }))
      dispatch(reduxAction.setSuccess(true));
      dispatch(reduxAction.setLoading(false));
    }
    else {
      if(id != identifier)
        dispatch(reduxAction.setData({ data: null, id: identifier, period: null }))
      if((data == null || isTooOld(time) || forced || always || differentRange || id != identifier)
        && !(api?.service === blazer?.DEVICE_NETWORK && api?.params[0] == null)) {
          CallAPI(run);
        // console.log(data == null , isTooOld(time) , forced , differentRange , id != identifier)
      }
      // interval = setInterval(() => CallAPI(run) , range===0.25?REALTIME_TIMEOUT:SHORT_TIMEOUT)
      interval = setInterval(() => CallAPI(run) , SHORT_TIMEOUT)
    }  

  return () => {
    controller.abort();
    clearInterval(interval);
  };


  }, [identifier, range, dateRange, forced, always, activeOrgId]);
}

export default useTimedCaller;