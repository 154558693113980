/***
 *
 * Controller class for user.
 * @file ApexChartsTrendLine.js
 * @description ApexChartsTrendLine component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React, { lazy, memo, Suspense, useEffect } from "react";
import { Area, AreaChart, ResponsiveContainer } from "recharts";
// const Chart = lazy(() => import('react-apexcharts'));

const ApexChartsTrendLine = memo((props) => {
//   const options = {
//   legend: {
//       show: false
//   },
//   yaxis: {
//     min: 0
//   },
//   dataLabels: {
//       enabled: false
//   },
//   stroke:{
//     width: 3
//   },
//   colors:props.color,
//   fill: {
//     type:"solid",
//     opacity:0.2
//   },
//   chart: {
//       sparkline:{
//         enabled:true
//       },
//       parentHeightOffset: 0,
//       animations: {
//           enabled: false
//       },
//       zoom:{
//           enabled:false
//       },
//       toolbar: {
//         show: false,
//       },
//   },
//   tooltip: {
//       enabled:false,
//   }

// }

return (
    <Suspense fallback={<></>}>
        {/* {console.log("ApexCharts")} */}
        {/* <Chart options={options} series={props.series} type="area" width={"80px"} height={"40px"} /> */}
        {/* <ResponsiveContainer width="80px" height="40px"> */}
        <AreaChart
          width={80}
          height={40}
          data={props.series}
          margin={{
            top: 1,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          {props.seriesTitles?.map((title,index) => {
            return <Area type="monotone" dataKey={title} stackId="1" stroke={props.color[index]} fill={props.color[index]} strokeWidth="4" isAnimationActive={false}/>
          })}
          {/* <Area type="monotone" dataKey="pv" stackId="1" stroke="#82ca9d" fill="#82ca9d" strokeWidth="5"/> */}
        </AreaChart>
      {/* </ResponsiveContainer> */}
    </Suspense>
);
});

ApexChartsTrendLine.propTypes = {};

ApexChartsTrendLine.defaultProps = {};

export default ApexChartsTrendLine;
