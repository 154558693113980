/***
 *
 * Controller class for user.
 * @file ColSelector.js
 * @description ColSelector component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React from "react";
// import PropTypes from 'prop-types';
import "./ColSelector.scss";
import { useState } from "react";
import { Button, Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import { useEffect } from "react";
import { X } from "react-feather";

const ColSelector = (props) => {
  const {setIsOpen, setCols} = props
  const [intState, setIntState] = useState({ ...props.cols });

  useEffect(() => {
    setIntState({ ...props.cols })
  }, [props.cols])
  return (
    <div className="ColSelector h-100" data-testid="ColSelector">
      <Card className='h-100 w-100 mb-0'>
        <CardHeader className='d-flex align-items-center text-nowrap heading-style-text'>
          <span className="mr-1">Select columns you want to display</span>
          <X className='cursor-pointer' onClick={() => { setIsOpen(false) }} />
        </CardHeader>
        <CardBody className="mb-1 col-sel">
          <hr />
          {Object.keys(intState)?.map((col,key) => {
            return <div className="selector-option d-flex align-items-center pl-50" key={`${key}-col-selector`}>
              <input className="check-input cursor-pointer" type="checkbox" checked={intState[col]} onClick={() => {
                setIntState(prevState => { return { ...prevState, [col]: !prevState[col] } })
              }} 
              // for suppressing react warning of not giving a change handler 
              // (Note: checkbox inputs don't work properly if on change is used)
              onChange={() => {}}
              />
              <div className="selector-text ml-50 py-50">{col}</div>
            </div>
          })}
        </CardBody>
        <CardFooter>
          <div className='d-flex justify-content-between' size='sm'>
            {/* <Button outline color='primary'
              onClick={() => {
                props.handleClearAll();
              }}>
              Clear All
            </Button> */}
            <Button color='primary' disabled={props.disabled} 
              onClick={() => {
                setCols(intState);
                if (!!props?.onApply)
                  props.onApply(intState)
                setIsOpen(false);
              }}
            >
              Apply
            </Button>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

ColSelector.propTypes = {};

ColSelector.defaultProps = {};

export default ColSelector;
