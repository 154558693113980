/***
 *
 * Controller class for user.
 * @file Header.js
 * @description Header component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import React from "react";
import PropTypes from 'prop-types';
import "./Header.scss";
import { ReactComponent as Edit } from "../../../../assets/images/icons/Edit.svg";
import { useState } from "react";
import { make_toast } from "../../../../helpers";
import { Button, Col, Input, InputGroup, InputGroupText, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import createRequest, { services } from "../../../../services";
import { useSelector } from "react-redux";
import { CatchedWebError } from "../../../../configs";
import { useEffect } from "react";
import LightBadge from "../../../../components/LightBadge";
import { X } from "react-feather";
import { timeDiff } from "../../../Infrastructure/SwitchOverview";
import { UnbanClient } from "../../DeviceList";
import { useNavigate } from "react-router-dom";

const Header = (props) => {
  const [nickname, setNickname] = useState(props?.device?.clientName);
  const [venueId, setVenueId] = useState(props?.device?.venueId);
  const [removeNetwork, setRemoveNetwork] = useState([]);
  const [networks, setNetworks] = useState([]);
  const [removing, setRemoving] = useState(false);
  const [removeClient, setRemoveClient] = useState(null); 
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const permissions = useSelector(store => store?.rbac?.permissions);
  const venuePermissions = useSelector(store => store?.rbac?.venuePermissions);
  useEffect(() => {
    setNickname(props?.device?.clientName);
  }, [props?.device?.clientName])
  useEffect(() => {
    setVenueId(props?.device?.venueId);
  }, [props?.device?.venueId])

  const navigate = useNavigate();

  const submitNickname = () => {
    props?.setProcessing(true);
    let trimmedNickname = nickname ? nickname.trim() : '';
    let operation = (trimmedNickname == '' ? 'deleting' : 'updating');
    const { run } = operation === 'deleting'
      ? createRequest(services.devices.DELETE_NICKNAME, [props?.device?.clientMac, activeOrgId])
      : createRequest(services.devices.UPDATE_NICKNAME, [props?.device?.clientMac, activeOrgId, trimmedNickname]);
    run()
      .then(() => {
        props?.setDevice((prevState) => { return { ...prevState, clientName: operation === 'deleting' ? null : trimmedNickname } })
        make_toast("success", "Client nickname updated", true)
        props?.setEditing(false);
      })
      .catch((err) => {
        make_toast("error", (new CatchedWebError(err)).message);
      })
      .finally(() => {
        props?.setProcessing(false);
      })
  }
  const [touched, setTouched] = useState(false);
  const [validationError, setValidationError] = useState(true);

  // useEffect(() => {
  //   if(props.blockId)
  //     {
  //       const { run } = createRequest(services.networks.GET_CLIENT,[props.blockId, activeOrgId]);

  //       run()
  //       .then(response => {
  //         setNetworks([...response.data.networks])
  //       })
  //     }
  // },[activeOrgId, props.blockId])

  useEffect(() => {
    setNetworks(props.networks)
  }, [props.networks])
  
  useEffect(() => {
    validate(nickname)
  }, [nickname])

  const validate = (name) => {
    if (name?.length > 20) {
      setValidationError("Maximum 20 characters")
      return false;
    }
    else {
      setValidationError(null);
      return true;
    }
  }
  return (
    <div className="Header" data-testid="Header">
      <Row>
        <Col xs={12} md={10}>
          <div className="heading d-flex align-items-center pb-1">
            {/* <h4 className="mb-0" style={{ lineHeight: "0.7em" }}>
              {props?.editing
                ?
                <div>
                  <InputGroup className="input-group-merge" style={{ width: "22rem" }}>
                    <Input disable={props?.processing} value={nickname} id='nickname-input' autoFocus type="text" placeholder="Nickname" onChange={
                      (e) => {
                        setTouched(true);
                        setNickname(e?.target?.value)
                      }
                    }
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          if (validate(nickname) && !props?.processing)
                            submitNickname();
                        }
                      }}
                    />
                    <InputGroupText style={{ borderLeft: "0", borderTopLeftRadius: "0", borderBottomLeftRadius: "0" }}>
                      <span
                        id='nickname-input-tick'
                        className="material-symbols-outlined text-success cursor-pointer"
                        style={{ fontSize: "1.4em" }}
                        onClick={() => {
                          if (validate(nickname) && !props?.processing)
                            submitNickname();
                        }}>done</span>
                    </InputGroupText>
                  </InputGroup>
                  {touched && validationError &&
                    <div className='d-block text-danger' style={{ fontSize: "0.8rem" }}>{validationError}</div>}
                </div>
                : <>
                  <span className="text-truncate">{props.heading}</span>
                  {(venuePermissions[venueId]?.device?.update == null ? permissions?.manageDevice?.update : venuePermissions[venueId]?.device?.update) ?
                    <Edit className='ml-1 cursor-pointer' onClick={() => props?.setEditing(true)} />
                    : null}
                  <br />
                </>
              }
              {props.duration &&
                <small className="m-0 p-0">Connected since {props.duration}</small>}
            </h4> */}
            {props.from == "banned" ? (
              <div
                className="mx-1 d-flex align-items-center"
                style={{ minHeight: "36px", maxWidth: "720px" }}
              >
                <LightBadge color="danger">Banned</LightBadge>
                {networks.length > 0 && (
                  <span
                    className="ml-50"
                    style={{
                      fontSize: "0.85rem",
                      fontWeight: "700",
                      minWidth: "85px",
                    }}
                  >
                    {timeDiff(networks[0].bannedDate)} Ago
                  </span>
                )}
                <div className="d-flex" style={{ overflow: "auto" }}>
                  {networks.map((it) => {
                    return (
                      <div
                        className={`${
                          removeNetwork
                            .map((it) => it.networkId)
                            .includes(it.networkId)
                            ? "circular-badge-danger"
                            : "circular-badge"
                        } ml-50`}
                      >
                        <span className="text-truncate">{it.networkName}</span>
                        <X
                          className="ml-50 cursor-pointer"
                          onClick={() => {
                            if (
                              !removeNetwork
                                .map((it) => it.networkId)
                                .includes(it.networkId)
                            )
                              setRemoveNetwork((prevState) => [
                                ...prevState,
                                it,
                              ]);
                            else
                              setRemoveNetwork((prevState) =>
                                prevState.filter(
                                  (item) => item.networkId != it.networkId
                                )
                              );
                          }}
                          size={16}
                          strokeWidth="3px"
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </Col>
        <Col xs={12} md={2}>
          <div className="h-100 d-flex align-items-center justify-content-end">
            {!!props.setBanClient && props.from != "banned" ? (
              <Button.Ripple
                className="small-add-button mt-2 mb-50"
                disabled={props.disabled}
                color="primary"
                onClick={() => props.setBanClient(true)}
              >
                Ban Client
              </Button.Ripple>
            ) : (
              <></>
            )}
            {removeNetwork.length > 0 ? (
              <Button.Ripple
                className="small-add-button ml-1"
                color="primary"
                onClick={() => {
                  setRemoveClient(true);
                }}
              >
                Apply
              </Button.Ripple>
            ) : (
              <></>
            )}
          </div>
        </Col>
      </Row>

      <Modal
        centered
        isOpen={!!removeClient}
        toggle={() => {
          if (!removing) setRemoveClient(null);
        }}
      >
        <ModalHeader
          className="bg-white"
          toggle={() => {
            if (!removing) setRemoveClient(null);
          }}
        ></ModalHeader>
        <ModalBody>
          <div className="text-center">
            <div className="material-symbols-outlined display-2 text-warning">
              error
            </div>
            <div>
              Removing the Ban on the client will result in short disconnection
              of all clients on the network(s)
              <br />
              <h4 className="my-1">
                Are you sure you want to remove this client from banned list?
              </h4>
            </div>
            <div className="mt-2 mb-1">
              <Button.Ripple
                className="mr-1"
                color="primary"
                disabled={removing}
                onClick={() => {
                  if (!removing) setRemoveClient(null);
                }}
              >
                Cancel
              </Button.Ripple>
              <Button.Ripple
                color="danger"
                outline
                disabled={removing}
                onClick={() => {
                  setRemoving(true);
                  UnbanClient(
                    props.blockId,
                    removeNetwork.map((it) => it.networkId),
                    activeOrgId,
                    () => {
                      setNetworks((prevState) => [
                        ...prevState.filter(
                          (it) => !removeNetwork.includes(it)
                        ),
                      ]);
                      setRemoveNetwork([]);
                      setRemoveClient(null);
                      navigate(`/organization/${activeOrgId}/clients`)
                    },
                    () => {
                      setRemoving(false);
                    }
                  );
                }}
              >
                {removing ? <Spinner color="danger" size="sm" /> : "Yes"}
              </Button.Ripple>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

Header.propTypes = {
  heading: PropTypes.string
};

Header.defaultProps = {
};

export default Header;
