import { WRE_URL } from "../configs/urls";
import { B } from "../helpers";
import Service from "./Service";

const WRE = (url) => WRE_URL + url;

export const wre = {
  AP_ALGO_POS: new Service(venueId => WRE(`/venue/${venueId}/infrastructure/placement`), "GET"),

  /**
   * Validate AP Placement
   * @example
   * params = [venueId: number]
   * data = {
   *   infrastructures: [
   *     {
   *       x: number,
   *       y: number,
   *       z: number   
   *     }
   *   ],
   *   calibrationValue: number
   * }
   */
  VALIDATE: new Service(venueId => WRE(`venues/${venueId}/floor/validate`), "PUT"),


  /**
   * Get Layout List by Venue
   * @example
   * params = [id: number, offset: number, limit: number, filter=null, search: string, order_by_column: string, order: string]
   * data = {}
   */
  GET_LAYOUTS_BY_VENUE: new Service((id, offset = 0, limit = 10, filter = null, search = "", order_by_column = "name", order = "ASC") => {
    if (filter) {
      return WRE(`venues/${id}/layout?search=${search}&offset=${offset}&limit=${limit}&order_by_column=${order_by_column}&order=${order}&layout_type=${filter}`);
    }
    return WRE(`venues/${id}/layout?search=${search}&offset=${offset}&limit=${limit}&order_by_column=${order_by_column}&order=${order}`)
  }, "GET"),

  /**
   * Get Design Layout
   * @example
   * params = [venueId: number]
   */
  GET_FLOORPLAN_LIST: new Service((venueId, offset, limit, order_by_column = "name", order = "ASC") => WRE(`venues/${venueId}/floorplan?order_by_column=${order_by_column}&order=${order}` + ((offset && limit) ? `&offset=${offset}&limit=${limit}` : '')), "GET"),

  /**
   * Get Design Layout
   * @example
   * params = [id: number]
   */
  GET_DESIGN_LAYOUT: new Service(id => WRE(`venues/${id}/design`), "GET"),


  /**
   * Create new Layout
   * @example
   * params = [force: boolean]
   * data = {
   *   layoutId: number
   * }
   */
  CREATE_DESIGN_LAYOUT: new Service((force = false) => WRE(`layout/design?fmt=4&force=${force}`), "POST"),

  /**
   * Create new Layout
   * @example
   * params = [fmt: number]
   * data = {
   *   venueId: number,
   *   name: string,
   *   layoutJson: string
   * }
   */
  CREATE_LAYOUT: new Service(fmt => WRE(`layout?fmt=${fmt}`), "POST"),

  /**
   * Create new Floorplan
   * @example
   * params = [layoutId: number]
   * data = {
   *   venueId: number,
   *   name: string,
   *   layoutJson: string
   * }
   */
  CREATE_FLOORPLAN: new Service(layoutId => WRE(`floorplan` + (layoutId ? `?layout_id=${layoutId}` : '')), "POST"),

  /**
   * Update a Floorplan
   * @example
   * params = [floorplanId: number, layoutId:number]
   * data = {
   *   venueId: number,
   *   name: string,
   *   layoutJson: string
   * }
   */
  UPDATE_FLOORPLAN: new Service((floorplanId, layoutId) => WRE(`floorplan/${floorplanId}` + (layoutId ? `?layout_id=${layoutId}` : '')), "PUT"),



  /**
   * Delete a floorplan
   * @example
   * params = [floorplanId: number]
   */
  DELETE_FLOORPLAN: new Service(floorplanId => WRE(`floorplan/${floorplanId}`), "DELETE"),

  /**
   * Extract floorplan as Image
   * @example
   * params = [floorplanId: number]
   */
  EXPORT_FLOORPLAN: new Service(floorplanId => WRE(`floorplan/${floorplanId}/export`), "GET"),

  /**
   * Delete a layout
   * @example
   * params = [id: number]
   * data = {}
   */
  DELETE_LAYOUT: new Service(id => WRE(`layout/${id}`), "DELETE"),

  /**
   * Edit a Layout and/or its AP Placements
   * @example
   * params = [id: number]
   * data = {
   *   venueId: number,
   *   name: string,
   *   layoutType: number,
   *   infraPositions: [],
   *   layoutJson: string,
   *   isActive: true
   * }
   */
  EDIT_LAYOUT: new Service(id => WRE(`layout/${id}`), "PUT"),

  /**
   * Get Count of Layouts in a venue
   * @example
   * params = [venueId: number]
   * data = {
   *   count: number,
   * }
   */
  GET_LAYOUT_COUNT: new Service(venueId => WRE(`venues/${venueId}/layout/count`), "GET"),

  /**
   * Analyse a given layout
   * @example
   * params = [id: number]
   * data = {}
   */
  ANALYSE: new Service(id => WRE(`floorplan/${id}/analyze/score`), "GET"),

  /**
   * Get image of a particular layout
   * @example
   * params = [orgId: number, layoutId: number]
   * data = {}
   */
  GET_IMAGE: new Service((orgId, layoutId) => B(`organization/${orgId}/upload?filename=layout-${layoutId}&extenstion=png&folder=layout&purpose=get&withoutTimestamp=true`), "GET"),

  /**
   * Get Layout of a placed infra
   * @example
   * params = [infraItemId: number, venueId: number]
   * data = {}
   */
  GET_PLACED_FLOORPLAN: new Service((infraItemId, venueId) => B(`wre/deployed/location/${infraItemId}/floorplan?venue_id=${venueId}`), "GET"),

  GET_FLOORPLAN_IMAGE: new Service((orgId, floorplanId) => B(`organization/${orgId}/upload?filename=floorplan-${floorplanId}&extenstion=png&folder=floorplan&purpose=get&withoutTimestamp=true`), "GET"),

  /**
   * Set image of a particular layout
   * @example
   * params = [orgId: number, layoutId: number]
   * data = {}
   */
  SET_IMAGE: new Service((orgId, layoutId) => B(`organization/${orgId}/upload?filename=layout-${layoutId}&extenstion=png&folder=layout&purpose=put&withoutTimestamp=true`), "GET"),


  /**
   * Convert Planned Pin to Installed
   * @example
   * params = [ floorplanId: number]
   * data = {}
   */
  CONVERT_PLANNED_PIN: new Service(floorplanId => WRE(`floorplan/${floorplanId}/deployed/location`), "PUT"),
};