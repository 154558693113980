import { Group, Image, Rect, Text } from "react-konva";
import useImage from "use-image";
// import Infra from "./infraIcon.svg";
import Infra from "../../../../../assets/images/icons/APColored.svg";
// import InfraSelected from "./infraIconSelected.svg";
import InfraSelected from "../../../../../assets/images/icons/APColoredSelected.svg";
import { useSelector } from "react-redux";
import { Point } from "../../utils";

function truncate(val, n) {
    if (val.length > n) {
        return val.slice(0, n);
    }
    return val;
}

/**
 * Access Point
 * @param {{
 *  data: { infra_type_id: number, x: number, y: number },
 *  isSelected: boolean,
 *  scaleFactor: number,
 *  onClick: () => void
 * }} props 
 */
const DrawnLine = (props) => {
    const infraTypes = useSelector(store => store.infraTypes.data);
    const [imgr] = useImage(Infra);
    const [imgrS] = useImage(InfraSelected);
    return (
        <Group
            x={props.data.location.x * props.scaleFactor}
            y={props.data.location.y * props.scaleFactor}
            width={40}
            height={40}
            offsetX={20}
            offsetY={20}
            draggable={props.uuid == props.selectedAp}
            onDragEnd={
                (e) => {
                    const newPosition = new Point(
                        e.target.x() / props.scaleFactor,
                        e.target.y() / props.scaleFactor
                    );
                    props.translateAp(props.uuid, newPosition);
                }
            }
            onClick={props.onClick}
            onMouseEnter={(e) => {
                const container = e.target.getStage().container();
                container.style.cursor = "pointer";
            }}
            onMouseLeave={(e) => {
                const container = e.target.getStage().container();
                container.style.cursor = "default";
            }}
        >
            <Rect height={40} width={40} x={0} y={0} strokeWidth={1} dash={[2, 2]} stroke={props.isSelected ? "white" : "#5279CE"} cornerRadius={2} fill={props.isSelected ? "#5279CE" : "white"} />
            <Image image={props.isSelected ? imgrS : imgr} height={26} width={26} offsetX={-7} offsetY={0} />
            <Text offsetX={-2} offsetY={-26} fontSize={9} fill={props.isSelected ? "white" : "#5279CE"} text={truncate(infraTypes.find(item => item.infraTypeId === props.data.infraTypeId)?.infraType ?? "Unknown", 7)} />
        </Group >
    );
};

DrawnLine.propTypes = {};
DrawnLine.defaultProps = {};

export default DrawnLine;