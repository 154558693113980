import moment from "moment";

export const dateTimeFormatter = (date, dateStyle = null, timeStyle = null, withOutYear = false, withDate = false, dateFormat = true) => {

    const fixedDate = new Date(date);

    const options = {
        hour12: dateFormat,
    }

    if (withOutYear) {
        options.month = "short";
        options.day = "numeric"
        if (withDate) {
            options.hour12 = false
            options.hour = "numeric";
            options.minute = "numeric"
        }
    }
    else {
        if (!!dateStyle)
            options.dateStyle = dateStyle
        if (!!timeStyle)
            options.timeStyle = timeStyle
    }

    if ((fixedDate instanceof Date) && !isNaN(fixedDate)) {
        if (dateFormat)
            return (Intl.DateTimeFormat("default", options).format(fixedDate).replace(/( 00:)|(^00:)/g, " 12:"));

        return (Intl.DateTimeFormat("default", options).format(fixedDate).replace(/( 24:)|(^24:)/g, " 00:"));
    }
    return (date);
}

export const getTimeZone = (full = false) => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    if (full)
        return timeZone.replace("_", " ");

    return moment().tz(timeZone).format("z");
}