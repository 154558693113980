import PropTypes from "prop-types";
import "./SwitchWidget.scss";
import { AnimatePresence, motion } from "framer-motion";
import { useSelector } from "react-redux";

const switchWidgetOptionsVariants = {
    open: { width: 150 },
    close: { width: 70 }
};

/**
 * 
 * @param {{
 *  selectedSwitch: string,
 *  removeSwitch: (uuid: string) => void
 * }} props 
 * @returns 
 */
const SwitchWidget = (props) => {
    return (
        <AnimatePresence>
            <motion.div
                className="SwitchWidget d-flex align-items-center rounded-pill mx-50"
                animate={props.selectedSwitch === null ? "close" : "open"}
                variants={switchWidgetOptionsVariants}
                transition={{ duration: 0.2 }}
            >
                <div
                    title="Select a Switch to see options"
                    className="switch-widget-label rounded-pill px-75 h-100 d-flex align-items-center"
                >
                    <span>Switch</span>
                </div>
                {props.selectedSwitch !== null &&
                    <motion.div
                        className="ap-widget-options d-flex align-items-center mr-25 px-50"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.1, delay: 0.3 }}
                    >
                        <div
                            title="Remove the selected Switch from view"
                            className="switch-widget-action rounded-pill"
                            onClick={() => { props.removeSwitch(props.selectedSwitch) }}
                        >Remove</div>
                    </motion.div>
                }
            </motion.div>
        </AnimatePresence>
    );
};

SwitchWidget.propTypes = {
    selectedSwitch: PropTypes.string,
    removeSwitch: PropTypes.func
};
SwitchWidget
    .defaultProps = {};

export default SwitchWidget;