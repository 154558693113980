import PropTypes from "prop-types";
import "./WallAnnotationsCanvas.scss";
import Canvas from "../../../Canvas";
import { CANVAS_FEATURES } from "../../../constants";

/**
 * 
 * @param {{
 *   layoutManager: object,
 *   layouts: Array<{
*     "venueId": number,
*     "name": string,
*     "layoutType": 1 | 2 | 3,
*     "layoutFile": string,
*     "infraPositions": Array<{
*       "infra_type_id": number,
*       "x": number,
*       "y": number
*     }>?,
*     "layoutJson": {
*       "dimensions": {
*         "length": number,
*         "width": number,
*         "height": number
*         "area": number
*       }?,
*       "walls": Array<{
*         "id": string,
*         "loc": Array<number>,
*         "material": string
*       }>,
*       "isActive": boolean,
*       "id": number,
*       "createdAt": string,
*       "updatedAt": string
*     }  
*   }>
 * }} props 
 */
const WallAnnotationsCanvas = (props) => {
  return (
    <div className="WallAnnotationsCanvas">
      <Canvas
        layoutManager={props.layoutManager}
        features={[CANVAS_FEATURES.ZOOM_WIDGETS, CANVAS_FEATURES.WALL_COLORS]}
        events={[]}
        layouts={props.layouts || []}
        onSave={() => {}}
      />
    </div>
  );
};

WallAnnotationsCanvas.propTypes = {
  layoutManager: PropTypes.object,
  layouts: PropTypes.object
};
WallAnnotationsCanvas.defaultProps = {};

export default WallAnnotationsCanvas;