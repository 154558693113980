import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import { services } from './services';

export const AuthContext = React.createContext();
export const useAuth = () => useContext(AuthContext);

const tokenKey = "token";
const refreshTokenKey = "refreshtoken";

export const getToken = () => localStorage.getItem(tokenKey);
export const getRefreshToken = () => localStorage.getItem(refreshTokenKey);
export const setToken = (freshToken) => localStorage.setItem(tokenKey, freshToken);
export const setRefreshToken = (freshToken) => localStorage.setItem(refreshTokenKey, freshToken);
export const clearToken = (error=false) => {
  const token = getToken();
  axios.post(services.identity.USER_LOGOUT.urlBuilder(),null,
  {
    headers:{
      authorization:`Bearer ${token}`
    }
  })
  .finally(()=> {
    localStorage.removeItem(tokenKey);
    localStorage.removeItem(refreshTokenKey);
    // if(error)
      window.location.reload();
  });
}
export const refreshToken = () => {
  return new Promise((resolve, reject) => {
    axios.post(services.identity.USER_REFRESH.urlBuilder(), { refreshToken: getRefreshToken() })
      .then((response) => {
        setToken(response.data.access_token);
        setRefreshToken(response.data.refresh_token);
        resolve(response.data.access_token);
      })
      .catch(() => {
        clearToken(true);
      })
  })
}


export const AuthProvider = ({
  children
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState();

  const logout = (to) => {
    setLoading(true);
    clearToken();
    setIsAuthenticated(false);
    // setLoading(false);
  }

  const login = (token) => {
    setIsAuthenticated(true);
    setToken(token.access_token);
    setRefreshToken(token.refresh_token);
    setLoading(false);
  }

  useEffect(() => {
    // console.log(getToken());
    // if(window.location.href.indexOf("reset") > -1) {
    //   logout(window.location.href);
    // }
    // else {
    setIsAuthenticated(!!getToken());
    setLoading(false);
    // }
  }, [])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        getToken,
        setIsAuthenticated,
        setToken,
        logout,
        login
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}