import { createSlice } from "@reduxjs/toolkit";

const initialTypes = {
  status: {
    loading: null,
    error: null
  },
  data: []
}

const orgtypesSlice = createSlice({
  name: "orgtypes",
  initialState: initialTypes,
  reducers: {
    setOrgTypes: (state, action) => {
      state.data = [...action.payload];
    },
    resetOrgTypes: (state) => {
      return {
        status: {
          loading: null,
          error: null
        },
        data: []
      }
    },
    setLoading: (state, action) => {
      state.status.loading = action.payload;
    },
    setError: (state, action) => {
      state.status.error = action.payload;
    }
  }
});

export const orgtypesReducer = orgtypesSlice.reducer;
const orgtypesActions = orgtypesSlice.actions;
export default orgtypesActions;
