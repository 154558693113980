/***
 *
 * Controller class for user.
 * @file VenueStateDropdown.js
 * @description VenueStateDropdown component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React from "react";
// import PropTypes from 'prop-types';
import "./VenueStateDropdown.scss";
import Select, {components} from 'react-select'
import { VENUE_STATE, VENUE_STATE_MAP } from "../../utility/constants";
import { Venue_State_Icons } from "../../pages/Venue/NewOverview";
import {ReactComponent as Design} from "../../assets/images/icons/BusinessView.svg";
import {ReactComponent as Live} from "../../assets/images/icons/NetworkView.svg";
import {ReactComponent as Deployed} from "../../assets/images/icons/ProjectView.svg";
import { Col, Row } from "reactstrap";
import VenueStateGroup from "../VenueStateGroup";

const { Option } = components;
const IconOption = props => (
  <Option {...props}>
    {Venue_State_Icons[props.data.value]}
    {props.data.label}
  </Option>
);

const SingleValue = (props) => {
  return <components.SingleValue {...props}>
    {/* <div className="ml-50"> */}
      {Venue_State_Icons[props.data.value]}
      <span>{props.data.label}</span>
    {/* </div> */}
  </components.SingleValue>
}

const VenueStateDropdown = (props) => {
  const {selectedVenueState, onChange, disabled = false} = props;

  const handleClick = (state) => {
    if(!disabled && !!onChange){
      onChange(state);
    }
  }

  return (
    <div className="VenueStateDropdown" data-testid="VenueStateDropdown">
      <Row>
        <Col xs={9}>
          <Select 
            options={VENUE_STATE_MAP}
            isSearchable={false}
            value={VENUE_STATE_MAP.find(it => it.value == selectedVenueState)}
            onChange={e => handleClick(e.value)}
          />
        </Col>
        {selectedVenueState != VENUE_STATE.DISABLED ? <Col className="pl-0 pt-50">
          <VenueStateGroup selectedSize={28} size={20} active={selectedVenueState}/>
        </Col> : <></>}
      </Row>

      {/* <Select 
        options={options}
        styles={{...reactselectTheme,
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "var(--color--lightblue)" : state.isFocused ? "#5279CE1F" : "white",
            color: 'var(--text-color)'
          })
        }}
        isDisabled={disabled}
        value={options.filter(option => option.value==selectedVenueState)}
        onChange={(option) => {
          if(!!onChange) {
            onChange(option)
            return
          }
          setSelectedVenueState(option.value)
          if ([VENUE_STATE.DISABLED].includes(Number(option.value)) && !!setShowReasonModal) {
            setShowReasonModal(true)
          }
        }}
        components={{Option: IconOption, SingleValue: SingleValue}}
        isSearchable={false}
      /> */}
  </div>
  );
};

VenueStateDropdown.propTypes = {};

VenueStateDropdown.defaultProps = {};

export default VenueStateDropdown;
