/***
 *
 * Controller class for user.
 * @file Heading.js
 * @description Heading component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React from "react";
import PropTypes from 'prop-types';
import "./Heading.scss";

const Heading = (props) => {
  return (
    <div className="d-flex align-items-center">
      <h3 className="Heading" data-testid="Heading">
        {props.text}
      </h3>
    </div>
  );
};

Heading.propTypes = {
  setGraphStatus: PropTypes.any,
  text: PropTypes.string
};

Heading.defaultProps = {
  setGraphStatus: null,
  text: ""
};

export default Heading;
