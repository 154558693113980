/**
 * Service Class
 * @file Service.js
 * @description Definition of an API Service Endpoint
 * @author Utkarsh Gupta
 * @since 26 Jun 2022
 */

/** A service endpoint */
class Service {
  /** Service endpoint constructor
   * @param {function(...*): string} urlBuilder
   * @param {string} method
   */
  constructor(urlBuilder, method) {
    /** @type {function(...*): string} */
    this.urlBuilder = urlBuilder;
    /** @type {string} */
    this.method = method;
  }
}

export default Service;