import "../NewDashboard/NDashboard.scss";
import RefreshIcon from '../../../assets/images/icons/Refresh.svg';

const RefreshButton = ({ clickState, clickHandler, clickFunc, noLeftBtMargin, noMargin }) => (
  <button className={`p-0 refresh-button btn bg-white ${noLeftBtMargin ? '' : 'ml-1'}`} disabled={clickState} onClick={() => {
    clickHandler(true);
    if (!!clickFunc)
      clickFunc();
  }}>
    <div className="d-flex align-items-center text" style={{ fontSize: '0.857rem' }}>
      <img src={RefreshIcon} style={{ width: '14px', margin: noMargin ? '' : '10px' }} />
    </div>
  </button>
);

export default RefreshButton;