import PropTypes from "prop-types";
import "./ZoomWidget.scss";
import { useEffect } from "react";

/**
 * Zoom Widget
 * @param {{
 *  zoom: number
 *  setZoom: (number) => void
 * }} props 
 * @returns 
 */
const ZoomWidget = (props) => {

  useEffect(() => {



  })

  return (
    <>
      <div className="btn btn-sm btn-icon rounded-circle btn-primary mr-50 zoomWidget" onClick={() => {
        if ((props.zoom * 1.1) < 1) {

          props.setZoom(level => level * 1.1)
          props.setCurrentZoomValue(props.currentZoomValue * 1.1)
        }
        else {
          props.setZoom(1)
          props.setCurrentZoomValue(1)
        }
      }}>
        <span className="material-symbols-outlined">add</span>
      </div>
      <div className="btn btn-sm btn-icon rounded-circle btn-primary mr-50 zoomWidget" onClick={() => {
        if ((props.zoom / 1.1) > 0.2) {

          props.setZoom(level => level / 1.1)
          props.setCurrentZoomValue(props.currentZoomValue / 1.1)
        }
        else {
          props.setZoom(0.2)
          props.setCurrentZoomValue(0.2)

        }

      }}>
        <span className="material-symbols-outlined">remove</span>
      </div>
      <div title="Reset to 100%" style={{ minWidth: "80px" }} className="btn btn-sm rounded-pill btn-primary d-flex justify-content-center zoomWidget align-items-center" onClick={() => { props.setZoom(1) }}>
        <strong>{(props.zoom * 100).toFixed(0)}%</strong>
      </div>
    </>
  );
};

ZoomWidget.propTypes = {};
ZoomWidget.defaultProps = {};

export default ZoomWidget;