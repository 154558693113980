import { Alert } from "reactstrap";

import ChartCard from "./ChartCard";

const ChartError = ({ title, value, noCardBorder, noStyle }) => {
  return (
    <ChartCard title={title} error={true} noCardBorder={noCardBorder} noStyle={noStyle}>
        <Alert className="p-1" color="danger">{value}</Alert>
    </ChartCard>
  );
};

export default ChartError;