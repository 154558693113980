// ** React Imports
import React from "react";
import { Suspense, lazy } from "react";

// ** Toast & ThemeColors Context
import { ToastContainer } from "react-toastify";
import { ThemeContext } from "./utility/context/ThemeColors";

// ** Ripple Button
import "./@core/components/ripple-button";

// ** React Toastify
import "@styles/react/libs/toastify/toastify.scss";

// ** Core styles
//import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
//import "./assets/scss/style.scss";

import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import store from "./redux/store";
import { AUTH0 } from "./configs";
import { Auth0Provider } from "./auth0";
import { VeryFancyLoader } from "./components";
// import { AuthProvider } from "./auth";

import { createRoot } from "react-dom/client";

const App = lazy(() => import("./App"));

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Auth0Provider
    domain={AUTH0.DOMAIN}
    clientId={AUTH0.CLIENTID}
    cacheLocation="localstorage"
    useRefreshTokens={true}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: AUTH0.AUDIENCE
    }}
  >
  {/* <AuthProvider> */}
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<VeryFancyLoader />}>
          <ThemeContext>
            <Suspense fallback={<></>}>
              <App />
            </Suspense>
            <ToastContainer
              position="top-right"
              autoClose={false}
              style={{ width: '400px' }}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              enableMultiContainer
              containerId={"A"}
            />
            <ToastContainer
              limit={1}
              position="top-center"
              autoClose={false}
              newestOnTop={false}
              closeOnClick={false}
              rtl={false}
              pauseOnFocusLoss
              draggable={false}
              enableMultiContainer
              containerId={"B"}
            />
          </ThemeContext>
        </Suspense>
      </BrowserRouter>
    </Provider>
  {/* </AuthProvider> */}
  </Auth0Provider>
);
