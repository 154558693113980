import { useSelector } from "react-redux";
import ChartCardWithAreaCharts from "./ChartCardWithAreaCharts";

const PhyRateChart = ({categories, series1, series2, colors, isLoading, height, width, ticks, noStyle, noCardBorder, unit }) => {
  const range = useSelector(store => store.activeOrg.meta.dateRange);
  return (
      <ChartCardWithAreaCharts
          title="Phy Rate"
          categories={categories}
          series={[series1, series2]}
          colors={colors}
          height={height}
          range={range}
          width={width}
          // isTraffic={true}
          ticks={ticks}
          xlabel={`Phy Rate${!!unit?` (${unit})`:''}`}
          ylabel={range>0 ? "Date" : "Time"}
          isLoading={isLoading}
          showBorders={true}
          noStyle={noStyle}
          noCardBorder={noCardBorder}
          chartOptions={(options) => {
            if(!!options?.yaxis && options.yaxis instanceof Object) {
              options.yaxis.min = undefined
              options.yaxis.max = undefined
              options.yaxis.forceNiceScale = true
              options.yaxis.tickAmount = 4
              if(!!options?.yaxis?.labels && options.yaxis.labels instanceof Object) {
                options.yaxis.labels = {
                  ...options.yaxis.labels,
                  formatter: (value) => {
                    let roundedValue = (Math.round(value * 10))/10
                    return isNaN(roundedValue)? 0.0:roundedValue
                  }
                }
              }
            }
            if(!!options?.tooltip || !(options.tooltip instanceof Object)) {
              options.tooltip = {
                y: {}
              }
            }
            options.tooltip.y = {
              ...options.tooltip.y,
              formatter: function (
                value = 0,
                { series, seriesIndex, dataPointIndex, w }
              ) {
                  return (
                    value + " " + unit ?? ""
                  );
              },
            }; 
            return options
          }
          }
      />
  );
}

export default PhyRateChart;