import PropTypes from "prop-types";
import { Line } from "react-konva";
import { LayoutData, convexHull } from "../../utils";

/**
 * Fill up a layout using Vatti Clipping Algorithm (by clipping the polygon from its bounding box)
 * @param {{
 *   scaleFactor: number,
 *   layout: LayoutData
 * }} props 
 */
const LayoutFiller = (props) => {
  return (
    <Line points={convexHull(props.layout).map(x => x*props.scaleFactor)} stroke="transparent" fill="transparent" closed />
  );
};

LayoutFiller.propTypes = {
  scaleFactor: PropTypes.number,
  layout: PropTypes.instanceOf(LayoutData)
};
LayoutFiller.defaultProps = {};

export default LayoutFiller;