import { createSlice } from "@reduxjs/toolkit";

const inviteSubOrgsInitialState = {
  status : {
    loading: false,
    error: null
  },
  data: null
};

const inviteSubOrgsSlice = createSlice({
  name: "inviteSubOrgsSlice",
  initialState: {...inviteSubOrgsInitialState},
  reducers: {
    add: (state, action) => {
      state.data = [...action.payload];
    },
    reset: (state) => {
      return {
        status: {
          loading: false,
          error: null
        },
        data: null
      }
    },
    setLoading: (state, action) => {
      state.status.loading = action.payload;
    },
    setError: (state, action) => {
      state.status.error = action.payload;
    }
  }
});

export const inviteSubOrgsReducer = inviteSubOrgsSlice.reducer;
const inviteSubOrgsActions = inviteSubOrgsSlice.actions;
export default inviteSubOrgsActions;