import { createSlice } from "@reduxjs/toolkit";

const wreInitial = {
    currentInsertedLayout: 0,
    unsavedLayout: false,
    analysisScore: {}
};

const wreSlice = createSlice({
    name: "wre",
    initialState: { ...wreInitial },
    reducers: {
        setCurrentInsertedLayout(state, action) {
            state.currentInsertedLayout = action.payload
        },
        setUnsavedLayout(state, action) {
            state.unsavedLayout = action.payload
        },
        setAnalysisScore(state, action) {
            state.analysisScore = action.payload
        }
    }
});

export const wreReducer = wreSlice.reducer;
const wreAction = wreSlice.actions;
export default wreAction;