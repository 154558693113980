/** This is a standalone Static layout renderer for drawing icons and representations of a layout, 
 * it is self contained (i.e. helper functions are included in this file) 
 * and is ported from a previous version of Layout Editor.
 * In older versions of Layout Editor, this component was known as `FullLayoutMaker`
 */

import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { Layer, Line, Stage, Image, Group, Rect, Text } from "react-konva";
import useImage from "use-image";
import { COLORS } from "../constants";

/**
 * Static Layout Renderer (centered on the viewport)
 * @param {{
 *   "layout": {
 *       "dimensions": {
 *         "length": number,
 *         "width": number,
 *         "height": number
 *         "area": number
 *       }?,
 *       "walls": Array<{
 *         "id": string,
 *         "loc": Array<number>,
 *         "material": string
 *       }>,
 *       "isActive": boolean,
 *       "id": number,
 *       "createdAt": string,
 *       "updatedAt": string
 *     },
 *   "width": number | string,
 *   "height": number | string,
 *   "bgColor": string
 * }} props 
 */
const StaticLayoutRenderer = (props) => {
  const divRef = useRef(null);
  const stageRef = useRef(null)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [scaleFactor, setScaleFactor] = useState([1, 1]);
  const { layout } = props;
  const { completeLayout } = props
  const [blank_grid] = useImage(require('../../../../assets/images/icons/blank_grid.webp'));


  // We cant set the h & w on Stage to 100% it only takes px values so we have to
  // find the parent container's w and h and then manually set those 
  useEffect(() => {
    if (divRef.current?.offsetHeight && divRef.current?.offsetWidth) {
      setDimensions({
        width: divRef.current.offsetWidth,
        height: divRef.current.offsetHeight
      })
    }
  }, [divRef]);

  useEffect(() => {
    let y = calcScaleFactor(layout, dimensions.width, dimensions.height);
    setScaleFactor(y);
  }, [dimensions, layout]);

  return (
    <div ref={divRef} style={{ width: props.width, height: props.height }}>
      <Stage ref={stageRef} style={{ backgroundColor: props.bgColor, borderRadius: "5px" }} width={dimensions.width} height={dimensions.height}>
        {
          !props.hideBackGrid &&
          <Layer>
            <Image image={blank_grid} />
          </Layer>
        }
        <Layer>
          {scalePoints(scaleFactor, layout, 10, dimensions.width, dimensions.height).map((line, index) => {
            return (
              <Line key={index} points={line} stroke="black" strokeWidth={3} lineCap="round" lineJoin="round" />
            );
          })}
        </Layer>
        {
          props.showAP &&
          <Layer>
            {/* <Group
              x={614}
              y={160}
              width={40}
              height={40}
            // offsetX={20}
            // offsetY={20}
            >
               */}

            {
              scaleAP(scaleFactor, completeLayout, 10, dimensions.width, dimensions.height).map((newPoints, index) =>
                <Rect
                  key={index}
                  height={10}
                  width={10}
                  x={newPoints[0]}
                  y={newPoints[1]}
                  strokeWidth={2}
                  stroke={COLORS.AP_COLOR}
                  cornerRadius={8}
                  fill={COLORS.AP_COLOR}
                />)

            }
            {/* </Group > */}
          </Layer>
        }

        {
          props.showAP && completeLayout?.infraPositions?.length > 0 && props.showLegend &&
          <Layer>
            <Group x={0} y={0} height={40} width={40}>
              <Text x={10} y={10} fontSize={16} fill={"black"} text={"Planned AP:"} />
              <Rect
                height={15}
                width={15}
                x={110}
                y={10}
                strokeWidth={2}
                stroke={COLORS.AP_COLOR}
                cornerRadius={8}
                fill={COLORS.AP_COLOR}
              />
            </Group>
          </Layer>
        }
      </Stage>
    </div >
  )
};

StaticLayoutRenderer.propTypes = {
  layout: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  bgColor: PropTypes.string
};
StaticLayoutRenderer.defaultProps = {
  bgColor: "white"
};


/// HELPER FUNCTIONS -- DO NOT USE THESE ELSEWHERE -- THESE ARE PORTED AND EXIST FOR LEGACY SUPPORT -- AND ARE MOSTLY UNDOCUMENTED

function extractPoints(layout) {
  let pointsList = [];
  for (const component of layout["components"])
    for (const wall of component["walls"]) {
      pointsList.push([wall["loc"][0], wall["loc"][1]]);
      pointsList.push([wall["loc"][2], wall["loc"][3]]);
    }
  return pointsList;
}
function extractApPoints(layout) {
  let pointsList = [];
  for (const infraPoint of layout["infraPositions"]) {
    pointsList.push([infraPoint.location.x, infraPoint.location.y]);
  }
  return pointsList;
}

function boundingBox(layout) {
  let points = extractPoints(layout);
  let minX = points[0][0], minY = points[0][1], maxX = points[0][0], maxY = points[0][1];
  for (let i = 1; i < points.length; i++) {
    minX = Math.min(points[i][0], minX);
    minY = Math.min(points[i][1], minY);
    maxX = Math.max(points[i][0], maxX);
    maxY = Math.max(points[i][1], maxY);
  }
  const x = [[minX, minY], [maxX, maxY]];
  return x;
}

export function calcScaleFactor(layout, width, height, optionalTag = "") {
  const [[minX, minY], [maxX, maxY]] = boundingBox(layout);
  const diffMax = Math.max(maxX - minX, maxY - minY);
  return [Math.min(width, height) / diffMax, Math.min(height, width) / diffMax];
}

function scalePoints(scaleFactor, layout, offset = 0, dimWidth, dimHeight) {
  const bbox = boundingBox(layout);
  const center = { x: (bbox[0][0] + bbox[1][0]) / 2, y: (bbox[0][1] + bbox[1][1]) / 2 };
  const nPointCenter = {
    x: (center.x - bbox[0][0]) * scaleFactor[0] * ((100 - offset * 2) / 100) + offset,
    y: (center.y - bbox[0][1]) * scaleFactor[1] * ((100 - offset * 2) / 100) + offset
  };
  const transformPoint = { x: dimWidth / 2 - nPointCenter.x, y: dimHeight / 2 - nPointCenter.y };
  let newLines = [];
  const points = extractPoints(layout);
  for (let i = 0; i < points.length; i += 2) {
    newLines.push([
      (points[i][0] - bbox[0][0]) * scaleFactor[0] * ((100 - offset * 2) / 100) + offset + transformPoint.x,
      (points[i][1] - bbox[0][1]) * scaleFactor[1] * ((100 - offset * 2) / 100) + offset + transformPoint.y,
      (points[i + 1][0] - bbox[0][0]) * scaleFactor[0] * ((100 - offset * 2) / 100) + offset + transformPoint.x,
      (points[i + 1][1] - bbox[0][1]) * scaleFactor[1] * ((100 - offset * 2) / 100) + offset + transformPoint.y
    ])
  }

  return newLines;
}


function scaleAP(scaleFactor, layout, offset = 0, dimWidth, dimHeight) {
  const bbox = boundingBox(layout.layoutJson);  // boundary of the box from min points to max points
  const center = { x: (bbox[0][0] + bbox[1][0]) / 2, y: (bbox[0][1] + bbox[1][1]) / 2 }; //center of that box
  const nPointCenter = {
    x: (center.x - bbox[0][0]) * scaleFactor[0] * ((100 - offset * 2) / 100) + offset,
    y: (center.y - bbox[0][1]) * scaleFactor[1] * ((100 - offset * 2) / 100) + offset
  };
  const transformPoint = { x: dimWidth / 2 - nPointCenter.x, y: dimHeight / 2 - nPointCenter.y };
  let newPoints = [];
  const points = extractApPoints(layout);

  for (let i = 0; i < points.length; i++) {
    newPoints.push([
      (points[i][0] - bbox[0][0]) * scaleFactor[0] * ((100 - offset * 2) / 100) + offset + transformPoint.x,
      (points[i][1] - bbox[0][1]) * scaleFactor[1] * ((100 - offset * 2) / 100) + offset + transformPoint.y,
    ])
  }

  return newPoints;
}

export default StaticLayoutRenderer;