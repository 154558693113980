import React, { useState } from "react";
import "./ReportAlert.scss";
import { Alert, Button } from "reactstrap";
import SupportModal from "../SupportModal";
import { TICKET_CATEGORY } from "../../pages/Tickets/TicketConstants";
import { useSelector } from "react-redux";

const ReportAlert = (props) => {
  const { error, setError, context, toggleAction } = props
  const permissions = useSelector(store => store?.rbac?.permissions);
  const [showModal, setShowModal] = useState(false)
  const [ticketDetails, setTicketDetails] = useState({})


  const reportIssue = () => {
    setTicketDetails({
      subject: `Error occured while performing: ${context?.action || 'UNKNOWN ACTION'}`,
      category: context?.category || TICKET_CATEGORY.WEBUI
    })
    setShowModal(true)
  }

  return (
    <>
      <Alert
        className="mt-1 w-100 d-flex justify-content-between  align-items-center"
        color="danger"
        isOpen={!!error}
        toggle={() => {
          if (toggleAction) {
            toggleAction()
          }
          setError(null)
        }
        }>
        <div className="alert-body">{error}</div>
        {
          permissions?.manageTicket?.create && context?.showReportButton &&
          <Button className="mr-4" size='sm' outline color='danger' onClick={reportIssue}>Report</Button>
        }
      </Alert>
      {
        showModal && <SupportModal
          isOpen={showModal}
          setIsOpen={setShowModal}
          ticketDetails={ticketDetails}
          context={context}
        />
      }

    </>
  );
};

ReportAlert.propTypes = {};

ReportAlert.defaultProps = {};

export default ReportAlert;
