import { activeVenueActions } from "../slices";
import store from "../store";
import createRequest, { services } from "../../services";
import { CatchedWebError } from "../../configs";

export const getActiveVenue = (venueId, orgId) => {
  store.dispatch(activeVenueActions.setLoading(true));
  store.dispatch(activeVenueActions.setError(null))
  const { controller, run } = createRequest(services.venue.GET, [venueId, orgId]);
  run()
    .then(response => {
      store.dispatch(activeVenueActions.setActiveVenue(response.data));
    })
    .catch(err => {
      let x = new CatchedWebError(err);
      store.dispatch(activeVenueActions.setError(x.message));
    })
    .finally(() => {
      store.dispatch(activeVenueActions.setLoading(false));
    });
  return controller;
};

export const getInfraByVenue = (venueId, offset = 0, limit = 20, search = "", setHasMore = () => { }, sort, overwrite = false, filterData = {}) => {
  const { controller, run } = createRequest(services.infra.GET_BY_VENUE, [venueId, offset, limit, search, sort, filterData]);
  store.dispatch(activeVenueActions.setInfraLoading(true));
  run()
    .then(response => {
      if (response.data.length < limit) {
        setHasMore(false);
      }
      if (overwrite)
        store.dispatch(activeVenueActions.setInfra(response.data))
      else
        store.dispatch(activeVenueActions.addInfra(response.data));
    })
    .catch(err => {
      store.dispatch(activeVenueActions.setError((new CatchedWebError(err)).message));
      setHasMore(false);
    })
    .finally(() => {
      store.dispatch(activeVenueActions.setInfraLoading(false));
    });
  return controller;
};
