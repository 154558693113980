import { useEffect, useMemo, useState } from "react";
import { EditableLayout } from '../utils';

/**
 * @param {{width: number, height: number}} dimensions
 * @param {EditableLayout} editableLayout 
 */
function useDisplayScalingProvider(editableLayout, dimensions, blank = true) {
  const layoutCount = useMemo(() => Object.keys(editableLayout.layouts).length, [editableLayout]);
  const [isBlank, setIsBlank] = useState(blank || false);
  const [scaleFactor, setScaleFactor] = useState(1);

  useEffect(() => {
    if (layoutCount > 0 && isBlank) {
      setIsBlank(false);
    }
  }, [layoutCount, isBlank]);

  useEffect(() => {
    if (layoutCount > 0 && !isBlank) {
      const firstLayout = editableLayout.layouts[Object.keys(editableLayout.layouts)[0]];
      let minX = firstLayout.walls[0].from.x, maxX = firstLayout.walls[0].from.x, minY = firstLayout.walls[0].from.y, maxY = firstLayout.walls[0].from.y;
      for (const wall of firstLayout.walls) {
        minX = Math.min(minX, wall.from.x, wall.to.x);
        maxX = Math.max(maxX, wall.from.x, wall.to.x);
        minY = Math.min(minY, wall.from.y, wall.to.y);
        maxY = Math.max(maxY, wall.from.y, wall.to.y);
      }
      const width = maxX - minX;
      const height = maxY - minY;

      setScaleFactor(Math.min(dimensions.width / width, dimensions.height / height)*0.9);
    }
  }, [dimensions, isBlank]);

  return { scaleFactor };
}

export default useDisplayScalingProvider;
