import lodash from "lodash-es";
const filterActiveSetter = (newState,filterInitial,setFilterActive) => {
    if(lodash.isEqual(newState,filterInitial))
      setFilterActive(false)
    else
      setFilterActive(true);
  }

const clearFilter = ({setFilterData,property,filterInitial,setFilterActive,onApply}) => {
    setFilterData((prevState) => {
        let newState = {...prevState};
        newState[property] = filterInitial[property];
        filterActiveSetter(newState,filterInitial,setFilterActive);
        onApply(newState);
        return newState;
    });
}

const applyFilter = ({onApply,filterData,filterInitial,setFilterActive}) => {
    onApply(filterData);
    filterActiveSetter(filterData,filterInitial,setFilterActive);
  }

export { clearFilter, applyFilter };