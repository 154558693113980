import { createSlice } from "@reduxjs/toolkit";

const infraTypesInitialState = {
  status: {
    loading: false,
    error: null
  },
  data: []
};

const infraTypesSlice = createSlice({
  name: "infraTypes",
  initialState: {...infraTypesInitialState},
  reducers: {
    setInfraTypes: (state, action) => {
      state.data = [...action.payload];
    },
    resetInfraTypes: (state) => {
      return {...infraTypesInitialState};
    },
    setLoading: (state, action) => {
      state.status.loading = action.payload;
    },
    setError: (state, action) => {
      state.status.error = action.payload;
    }
  }
});

export const infraTypesReducer = infraTypesSlice.reducer;
const infraTypesActions = infraTypesSlice.actions;
export default infraTypesActions;