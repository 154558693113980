import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Col, Container, Input, InputGroup, Nav, NavItem, NavLink, Row } from "reactstrap";
import { useEffect, useMemo, useState } from "react";
import { INFRA_TYPE_GROUPS } from "../../../constants";
import "./SwitchTypeList.scss";
import { SearchIconAddon } from "../../../../../../components";
import SwitchTypeItem from "../SwitchTypeItem";

/**
 * Access Point Types List
 * @param {{
 *  draggedItem: any
 * }} props 
 */

const SWITCH_TYPE_GROUPS = ["48 Port", "24 Port", "8 Port"];
const SWITCH_TYPE_GROUPS_MAPPING = {
  "48 Port": "48 Port Switch",
  "24 Port": "24 Port Switch",
  "8 Port": "8 Port Switch"
}
const SUPPORTED_SWITCHS = ["48 Port Switch", "24 Port Switch", "8 Port Switch"]

const SwitchTypeList = (props) => {
  const infraTypes = useSelector(store => store.infraTypes.data);
  const infraTypesLoading = useSelector(store => store.infraTypes.status.loading);
  const infraTypesError = useSelector(store => store.infraTypes.status.error);
  const [activeTab, setActiveTab] = useState(SWITCH_TYPE_GROUPS_MAPPING[SWITCH_TYPE_GROUPS[0]]);
  const [search, setSearch] = useState('')

  const filteredInfraTypes = useMemo(() => {
    if (search.length > 0) {
      return (infraTypes?.filter(infraType => infraType.infraCategory === 2 && infraType.type === activeTab && infraType.infraType.includes(search)) ?? [])

    }
    return (infraTypes?.filter(infraType => infraType.infraCategory === 2 && infraType.type === activeTab) ?? [])
  }, [activeTab, infraTypes, search]);


  useEffect(() => {
  }, [])


  return (
    <div className="SwitchTypeList">
      <h4 className="p-1 m-0 border-bottom">Switch ({(infraTypes?.filter(infraType => (infraType.infraCategory === 2 && (SUPPORTED_SWITCHS.includes(infraType.type)))) ?? []).length})</h4>

      {infraTypesError ?
        <div className="text-center text-danger py-2">{infraTypesError}</div>
        :
        (infraTypesLoading ?
          <div>Loading...</div>
          :
          <div>
            <Row className="infra-types-tabs-container p-0 m-0">
              <Col >
                <Nav tabs className="infra-types-tabs">
                  {SWITCH_TYPE_GROUPS.map(infraTypeGroup => {
                    return (
                      <NavItem key={infraTypeGroup}>
                        <NavLink className={activeTab === SWITCH_TYPE_GROUPS_MAPPING[infraTypeGroup] ? "active" : ""} onClick={() => { setActiveTab(SWITCH_TYPE_GROUPS_MAPPING[infraTypeGroup]) }}>
                          {infraTypeGroup}
                        </NavLink>
                      </NavItem>
                    );
                  })}
                </Nav>
              </Col>
            </Row>
            <Row className="p-0 m-0">
              <InputGroup className="input-group-merge mx-2 ">
                <Input
                  style={{ height: '2.4rem' }}
                  type="text"
                  value={search}
                  placeholder="Search"
                  onChange={(e) => {
                    setSearch(e.target.value)
                  }}
                />
                <SearchIconAddon />
              </InputGroup>
              {filteredInfraTypes.length === 0 ?
                <Col xs={12} className="text-center text-secondary">No Switch of this type</Col>
                :
                filteredInfraTypes
                  .map(infraType => {
                    return (
                      <Col xs={6} key={infraType.infraTypeId}>
                        <SwitchTypeItem draggedItem={props.draggedItem} data={infraType} />
                      </Col>
                    );
                  })}
            </Row>
          </div>
        )
      }
    </div>
  );
};

SwitchTypeList.propTypes = {
  draggedItem: PropTypes.any
};
SwitchTypeList.defaultProps = {};

export default SwitchTypeList;