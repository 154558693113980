/***
 *
 * Controller class for user.
 * @file PendingTaskBreakdown.js
 * @description PendingTaskBreakdown component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React, { useEffect, useRef, useState } from "react";
// import PropTypes from 'prop-types';
import "./PendingTaskBreakdown.scss";
import { safeNumber } from "../InfraBreakdown";
import createRequest, { services } from "../../services";
import { Progress, Spinner } from "reactstrap";
import ToolTip from "react-portal-tooltip";
import { ReactComponent as InstallIcon } from "../../assets/images/icons/install_cloud_blue.svg";
import { ReactComponent as PhotoIcon } from "../../assets/images/icons/camera-icon.svg";
import { ReactComponent as PlacementIcon } from "../../assets/images/icons/placement_blue.svg";
import { ReactComponent as ValidateIcon } from "../../assets/images/icons/validate-icon_blue.svg";
import { SITE } from "../../utility/constants";
import { bottom } from "@popperjs/core";

const Breakdown = (props) => {
  const { counts } = props;
  return (
    <>
      <div className="mt-50 d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center font-weight--500">
          <InstallIcon width={22} height={22} className="mr-50" />
          Initialize
        </div>
        <div>
          <LeftBracket />
          <span className={`${(counts[0][0]??0) > 0 ? "" : (counts[0][1] != null && (counts[0][1]??0) > 0) ? "text-success" : 'text-color--shasta-black'} text-large`}>
            {safeNumber(counts[0][0])}
          </span>
          &nbsp;/&nbsp;{safeNumber(counts[0][1])}
          <RightBracket />
        </div>
      </div>
      <div className="mt-25 d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center font-weight--500">
          <PhotoIcon width={22} height={22} className="mr-50" />
          Photo
        </div>
        <div>
          <LeftBracket />
          <span className={`${(counts[1][0]??0) > 0 ? "" : (counts[1][1] != null && (counts[1][1]??0) > 0) ? "text-success" : 'text-color--shasta-black'} text-large`}>
            {safeNumber(counts[1][0])}
          </span>
          &nbsp;/&nbsp;{safeNumber(counts[1][1])}
          <RightBracket />
        </div>
      </div>
      <div className="mt-25 d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center font-weight--500">
          <PlacementIcon width={22} height={22} className="mr-50" />
          Install
        </div>
        <div>
          <LeftBracket />
          <span className={`${(counts[2][0]??0) > 0 ? "" : (counts[2][1] != null && (counts[2][1]??0) > 0) ? "text-success" : 'text-color--shasta-black'} text-large`}>
            {safeNumber(counts[2][0])}
          </span>
          &nbsp;/&nbsp;{safeNumber(counts[2][1])}
          <RightBracket />
        </div>
      </div>
      <div className="mt-25 d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center font-weight--500">
          <ValidateIcon width={22} height={22} className="mr-50" />
          Validate
        </div>
        <div>
          <LeftBracket />
          <span className={`${(counts[3][0]??0) > 0 ? "" : (counts[3][1] != null && (counts[3][1]??0) > 0) ? "text-success" : 'text-color--shasta-black'} text-large`}>
            {safeNumber(counts[3][0])}
          </span>
          &nbsp;/&nbsp;{safeNumber(counts[3][1])}
          <RightBracket />
        </div>
      </div>
    </>
  );
};

export const LeftBracket = () => {
  return <span className="text-large">(</span>;
};
export const RightBracket = () => {
  return <span className="text-large">)</span>;
};

const PendingTaskBreakdown = (props) => {
  const { id, pendingTask, totalTask, venueId, venueName, venueType } = props;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [active, setActive] = useState(false);
  const pendingTasksCountRef = useRef(null)
  const [position, setPosition] = useState(null)

  const getTaskBreakdown = () => {
    const { run } = createRequest(services.telemetry.GET_VENUE_BREAKDOWN, [
      venueId,
      "task",
    ]);
    setLoading(true);
    run()
      .then((response) => {
        let taskBreakdown = response?.data?.taskBreakDown
        let siteBreakdown = taskBreakdown?.site
        let subBreakdown = taskBreakdown?.sub
        setData(
          {
            "total": taskBreakdown?.total,
            "pending": taskBreakdown?.pending,
            "site":{
              "total": siteBreakdown?.total,
              "pending":siteBreakdown?.pending,
              "installPending": siteBreakdown?.installPending, 
              "initializePending": siteBreakdown?.initializePending, 
              "photosPending": siteBreakdown?.photosPending, 
              "validatePending": siteBreakdown?.validatePending, 
              "install": siteBreakdown?.install, 
              "initialize": siteBreakdown?.initialize, 
              "photos": siteBreakdown?.photos, 
              "validate": siteBreakdown?.validate 
            },
            "sub":{
              "total": subBreakdown?.total,
              "pending":subBreakdown?.pending,
              "installPending": subBreakdown?.installPending, 
              "initializePending": subBreakdown?.initializePending, 
              "photosPending": subBreakdown?.photosPending, 
              "validatePending": subBreakdown?.validatePending, 
              "install": subBreakdown?.install, 
              "initialize": subBreakdown?.initialize, 
              "photos": subBreakdown?.photos, 
              "validate": subBreakdown?.validate 
            }
          }
        );
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => setLoading(false));
  };

  const handleMouseOver = () => {
    if((totalTask??0)>0) {
      const rect = pendingTasksCountRef.current.getBoundingClientRect()
      //rect:
        // bottom, top, left, right, height, width
      //window:
        //innerWidth, innerHeight, outerWidth, outerHeight
      let diff = window.innerHeight - rect.top
      let halfWindowHeight = window.innerHeight / 2
      let pos = diff > halfWindowHeight? "bottom" : "top"
      setPosition(pos)
      setActive(true);
    }
  }

  useEffect(() => {
    if (active && loading) {
      getTaskBreakdown();
    }
  }, [active, loading]);

  return (
    <div
      className="PendingTaskBreakdown"
      data-testid="PendingTaskBreakdown"
      id={`pendingtask${id}`}
      ref={pendingTasksCountRef}
      onMouseOver={handleMouseOver}
      onMouseLeave={() => {
        setActive(false);
      }}
    >
      <Progress
        className={`progress-pend-task ${(totalTask??0) > 0? 'bg-success':''}`}
        value={(pendingTask / totalTask) * 100}
        // color="warning"
      />
      {/* <span>{safeNumber(pendingTask)}</span>
      <span>/{safeNumber(totalTask)}</span> */}
      <div>
        <span
          className={`${
            (pendingTask??0) > 0 ? "" : (totalTask != null && (totalTask??0) > 0) ? "text-success" : 'text-color--shasta-black'
          } bold-pend-task`}
        >
          {safeNumber(pendingTask)}
        </span>
        &nbsp;/&nbsp;{safeNumber(totalTask)}
      </div>
      {!loading && !error && !!position && (totalTask??0) > 0 ? (
        <ToolTip
          parent={`#pendingtask${id}`}
          active={active}
          position={position}
          arrow="center"
          style={{
            style: {
              border: "0.1rem solid #D9D9D9",
              boxShadow: "none",
            },
            arrowStyle: {
              borderColor: "#D9D9D9",
            },
          }}
        >
          {loading ? (
            <Spinner className="p-1 text-primary" />
          ) : (
            <div className="TasksBreakdownTooltip p-1">
              <div className="pr-5 font-weight--500 mb-1">
                {venueName}&nbsp;Breakdown
              </div>
              <div className="mb-50">
                {venueName}&nbsp;
                <LeftBracket />
                <span className={`${(data?.site?.pending??0) > 0 ? "" : (data?.site?.total != null && (data?.site?.total??0) > 0) ? "text-success" : 'text-color--shasta-black'} text-large`}>
                  {safeNumber(data?.site?.pending)}
                </span>
                &nbsp;/&nbsp;{safeNumber(data?.site?.total)}
                <RightBracket />
              </div>
              <Breakdown counts={[[safeNumber(data?.site?.initializePending),safeNumber(data?.site?.initialize)], [safeNumber(data?.site?.photosPending), safeNumber(data?.site?.photos)], [safeNumber(data?.site?.installPending), safeNumber(data?.site?.install)], [safeNumber(data?.site?.validatePending), safeNumber(data?.site?.validate)]]} />
              {(data?.sub?.total>0)?<>
              <div className="my-50">
                Sub Venues&nbsp;
                <LeftBracket />
                <span className={`${(data?.sub?.pending??0) > 0 ? "" : (data?.sub?.total != null && (data?.sub?.total??0) > 0) ? "text-success" : 'text-color--shasta-black'} text-large`}>
                  {safeNumber(data?.sub?.pending)}
                </span>
                &nbsp;/&nbsp;{safeNumber(data?.sub?.total)}
                <RightBracket />
              </div>
              <Breakdown counts={[[safeNumber(data?.sub?.initializePending),safeNumber(data?.sub?.initialize)], [safeNumber(data?.sub?.photosPending), safeNumber(data?.sub?.photos)], [safeNumber(data?.sub?.installPending), safeNumber(data?.sub?.install)], [safeNumber(data?.sub?.validatePending), safeNumber(data?.sub?.validate)]]} />
              </>:null}
            </div>
          )}
        </ToolTip>
      ) : null}
    </div>
  );
};

PendingTaskBreakdown.propTypes = {};

PendingTaskBreakdown.defaultProps = {};

export default PendingTaskBreakdown;
