/***
 *
 * Controller class for user.
 * @file DummySwitch.js
 * @description DummySwitch component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import React, { useCallback, useMemo, useState } from "react";
import { Tooltip } from "react-tooltip";
import { Button, Col, Container, FormFeedback, Input, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { AlertBox, Toggle } from "../../../../components";
import { CatchedWebError } from "../../../../configs";
import createRequest, { services } from "../../../../services";
import { make_toast } from "../../../../helpers";
import "./DummySwitch.scss"
import Select from "react-select";
import Creatable from "react-select/creatable";
import { UncontrolledTooltip } from "reactstrap/lib";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { isRange } from "../../OneL2Vlan";
import { DEFAULT_VLAN, commaSeparatedList } from "../../L2Vlan";
import { TRUNK_LABEL } from "../../../../utility/tooltip-config-global";
import { portSpeed } from "../../../Profiles/ModelSetting";
import { ReactComponent as CableTest } from "../../../../assets/images/icons/CableTest.svg";
import { ReactComponent as EthernetCable } from "../../../../assets/images/icons/EthernetCable.svg";
import { ReactComponent as SfpCable } from "../../../../assets/images/icons/SfpCable.svg";
import { ReactComponent as Monitor } from "../../../../assets/images/icons/Monitor.svg";
import APSVG from "../../InfraList/APSVG";
import { ReactComponent as PowerCycle } from "../../../../assets/images/icons/PowerCycle.svg";
import { ReactComponent as ResultWire } from "../../../../assets/images/icons/ResultWire.svg";
import { make_custom_toast } from "../../../../helpers/toasts";
import { ReactComponent as EmergencySirenIcon } from "../../../../assets/images/icons/emergency-siren.svg";
import { ReactComponent as SuccessIcon } from "../../../../assets/images/icons/Success.svg";
import { meterToFeet } from "../../../../utility/Utils";
import { ReactComponent as LAN } from "../../../../assets/images/icons/LANPort.svg";

export const TRUNK = 'Trunk'
export const ACCESS = 'Access'

export const GREEN_COLOR = "#289A71";
export const GREY_COLOR = "#808080";
export const BLACK_COLOR = "#000";
const BLUE_COLOR = "#0E4D92";
const YELLOW_COLOR = "#FFBE40"
const TRUNK_COLOR = "#7A0303"
const ACCESS_COLOR = "#7367F0"
const PURPLE_COLOR = "#E218CE"

export const vl = (str) => ({ value: str, label: str == "inherit" ? "Inherit" : str });

export function isInteger(value) {
    return /^[0-9]+$/.test(value);
}

function parseVlanSyntax(portsString) {
    let ports = new Set();
    let fragments = portsString.split(",");
    for (let uncleanfragment of fragments) {
        let fragment = uncleanfragment.trim();
        if (fragment === "") {
            continue
        } else if (isInteger(fragment)) {
            const num = Number(fragment);
            if (num >= 1 && num <= 4094) {
                ports.add(num);
            } else {
                return [[], "Enter value[1-4094]"]
            }
        } else if (isRange((fragment))) {
            const [start, end] = fragment.split("-").map(item => Number(item));
            if (start <= end) {
                for (let i = start; i <= end; i++) {
                    if (i >= 1 && i <= 4094) {
                        ports.add(i);
                    } else {
                        return [[...ports.keys()], "Enter value[1-4094]"]
                    }
                }
            } else {
                return [[], "Invalid"]
            }
        } else {
            return [[], "Invalid"]
        }
    }
    return [[...ports.keys()], null];
}

function portBgColor(status) {
    if (status === 'Blocked') {
        return BLACK_COLOR;
    }
    else if (status === 'Warning') {
        return YELLOW_COLOR
    }
    else if (status === 'Connected') {
        return GREEN_COLOR
    }
    else return GREY_COLOR
    // if (!enabled) {
    //     return BLACK_COLOR;
    // } 
    // else if((!!maxSpeed && !!speed && Number(speed)<Number(maxSpeed)) || duplex !== 'full') {
    //     return YELLOW_COLOR
    // }
    // else if (!online || !connected) {
    //     return GREY_COLOR;
    // } 
    // else {
    //     return GREEN_COLOR;
    // }
}

export const portBorderColor = (borderType) => {
    return borderType === TRUNK
        ? TRUNK_COLOR
        : borderType === ACCESS
            ? ACCESS_COLOR
            : borderType === '8021x'
                ? PURPLE_COLOR
                : 'none'
}

let timeout;

const useAPName = (props) => {
    const [apName, setApName] = useState('')
    const { lldp } = props;
    useEffect(() => {
        const apNameMatch = lldp?.match(/Shasta AP - Name: (.*?),/);
        setApName(apNameMatch ? apNameMatch[1] : '')
    }, [lldp])

    return apName
}
const statusMapping = {
    OK: "Cable is a correctly terminated, good cable, good pair.",
    ON: "Cable is a Open pair, and has no link partner. This means that one (or more) pair has “no pin contact”. If length is returned, it could indicate the position of the opening.",
    ST: "A short is detected on the cable. Cable length is shorter than expected. If length is returned, it could indicate the position of the short.",
    IE: "The terminating impedance is not in the reference range.",
    NC: "No cable detected.",
    NT: "Not Tested",
    NS: "Gigabit Ethernet ports linked up at a speed lower than 1000 Mbps.",
    UN: "Unknown status/error"
}
const statusTitle = {
    ON: "Open Pair",
    ST: "Shorted Pair",
    IE: "Impedance Error",
    NS: "Not Supported",
}
const ResultPill = (props) => {
    const { length, status, id, small, index } = props;
    return (
        <div className={`ResultPill position-absolute bg-white rounded border ${small ? 'px-50' : 'p-50'}`}
            style={{ zIndex: 4 - index }}
        >
            {/* {!['OK'].includes(status) ? ( */}
            {/* <> */}
            <EmergencySirenIcon className='ResultIcon' id={id} />
            {statusMapping[status] != null ?
                <Tooltip anchorSelect={`#${id}`} place={'bottom'}
                    variant="light" border="2px solid #EAEAEA" opacity={1}
                >
                    <div className="wire-tooltip-status">
                        {statusTitle[status] ? <span className="wire-tooltip-status-title">{statusTitle[status]}:&nbsp;</span> : null}{statusMapping[status]}
                    </div>
                </Tooltip> : null}
            {/* </> */}
            {/* ) : (
                <SuccessIcon className='ResultIcon'/>
            )} */}
            {length ?? 0}
            &nbsp;
            ft
            {/* <div className="d-flex align-items-center justify-content-between">
            </div> */}
        </div>
    )
}

const Wire = (props) => {
    const { wireNo, color, slash } = props;
    return (
        <div className="d-flex WireDiv">
            <div className="WireNo">{wireNo}</div>
            <ResultWire
                className="ResultWire d-block"
                data-color={color}
                data-slash={slash}
            // data-no-top-bottom='true'
            />
            <div className="WireNo">{wireNo}</div>
        </div>
    )
}

const PortModal = (props) => {
    const features = useSelector(store => store?.oneInfra?.features)

    const {
        editModal,
        setEditModal,
        modalTab,
        setModalTab,
        actualToShow,
        index,
        isPutting,
        portData,
        warning,
        setWarning,
        portStats,
        trunkVlans,
        cableTesting,
        cableTestResult,
        lldp,
        infraId,
        setCableTesting,
        setCableTestResult,
        borderType,
        sfp,
        children
    } = props;

    const apName = useAPName({ lldp: lldp })

    const handleCableTest = () => {
        const { run } = createRequest(services.infra.RUN, [infraId], {
            "cmd": "cable-diagnostics",
            "cablePorts": [`Ethernet${index}`]
        }
        )
        setCableTesting(true)
        run()
            .then(response => {
                // setCableTestResult(response.data)
                // console.log(response.data)
                make_custom_toast('success', sfp ? 'SFP Info Collection' : 'Cable Test', sfp ? 'SFP Info Collection' : 'Cable Test completed')
                let result = response?.data?.result?.text[`Ethernet${index}`] ?? {}
                // let result = {
                //     "type": "RJ45",
                //     "link-status": "UP",
                //     "pair-A": {
                //         "status": "ON",
                //         "meters": 8
                //     },
                //     "pair-B": {
                //         "status": "ST",
                //         "meters": 8
                //     },
                //     "pair-C": {
                //         "status": "IE",
                //         "meters": 8
                //     },
                //     "pair-D": {
                //         "status": "NC",
                //         "meters": 8
                //     }
                // }

                // {
                //     "Ethernet24": {
                //         "form-factor": "",
                //         "part-number": "",
                //         "revision": "",
                //         "rx-optical-power": 0,
                //         "serial-number": "",
                //         "temperature": 0,
                //         "tx-optical-power": 0,
                //         "type": "SFP",
                //         "vendor-name": ""
                //     }
                // }
                if (sfp) {
                    // console.log(result)
                    setCableTestResult(result)
                    return
                }
                const totalObj = {}
                if (result['pair-A'] != null && result['pair-B'] != null && result['pair-C'] != null && result['pair-D'] != null) {
                    if (
                        ['OK'].includes(result['pair-A'].status) &&
                        ['OK'].includes(result['pair-B'].status) &&
                        ['OK'].includes(result['pair-C'].status) &&
                        ['OK'].includes(result['pair-D'].status)
                    )
                        totalObj.status = 'OK'
                    else totalObj.status = 'ERROR'
                    let lengthA = !isNaN(Number(result['pair-A'].meters)) ? result['pair-A'].meters : 0
                    let lengthB = !isNaN(Number(result['pair-B'].meters)) ? result['pair-B'].meters : 0
                    let lengthC = !isNaN(Number(result['pair-C'].meters)) ? result['pair-C'].meters : 0
                    let lengthD = !isNaN(Number(result['pair-D'].meters)) ? result['pair-D'].meters : 0
                    totalObj.length = Math.max(lengthA, lengthB, lengthC, lengthD)
                    // totalObj.length = !isNaN(Number(result['pair-A'].meters)) ? result['pair-A'].meters : 0
                    // totalObj.length = !isNaN(Number(result['pair-B'].meters)) ? totalObj.length + result['pair-B'].meters : totalObj.length
                    // totalObj.length = !isNaN(Number(result['pair-C'].meters)) ? totalObj.length + result['pair-C'].meters : totalObj.length
                    // totalObj.length = !isNaN(Number(result['pair-D'].meters)) ? totalObj.length + result['pair-D'].meters : totalObj.length
                    totalObj.lengthFloored = !isNaN(Math.floor(totalObj.length)) ? Math.floor(totalObj.length) : totalObj.length
                }
                else totalObj.status = 'ERROR'

                setCableTestResult(
                    {
                        green: resultObj(result, 'pair-A'),
                        orange: resultObj(result, 'pair-B'),
                        blue: resultObj(result, 'pair-C'),
                        brown: resultObj(result, 'pair-D'),
                        total: totalObj
                    }
                )
            })
            .catch(err => {
                make_custom_toast('error', 'Cable Test', (new CatchedWebError(err)).message)
            })
            .finally(() => {
                setCableTesting(false);
            })
    }

    const handlePowerCycle = () => {
        const { run } = createRequest(services.infra.RUN, [infraId], {
            "cmd": "powercycle",
            "powerCyclePorts": [{ name: `Ethernet${index}`, cycle: 1000 }]
        })
        run()
            .then(response => {
                make_custom_toast('success', 'Power Cycle', 'Power Cycling the port.')
            })
            .catch(err => {
                make_custom_toast('error', 'Power Cycle', (new CatchedWebError(err)).message)
            })
        // .finally(() => {
        //     setCableTesting(false);
        // })
    }

    const resultObj = (result, property) => {
        let obj = result[property] ? {
            length: meterToFeet(result[property] != null && result[property].meters != null
                ? result[property].meters
                : '-'),
            status: result[property] != null ? result[property].status : null
        } : {
            length: '-',
            status: ''
        }
        return obj

    }

    useEffect(() => {
        // console.log("cableTestResult: ",cableTestResult)
        if (cableTestResult != null) {
            setWarning(false)
        }
    }, [cableTestResult])

    return (
        <Modal size="lg" centered isOpen={editModal} toggle={() => { if (!isPutting) setEditModal(false); }}
            className="PortModal"
        >
            <ModalHeader className="bg-white p-0" toggle={() => { if (!isPutting) setEditModal(false); }}>
            </ModalHeader>
            <ModalBody>
                <div className={`d-flex tabs-div ${modalTab === 'overview' ? "mb-3" : "mb-2"}`}>
                    <div data-active={modalTab === 'overview'} onClick={() => setModalTab('overview')} className="cursor-pointer">Overview</div>
                    <div data-active={modalTab === 'config'} onClick={() => setModalTab('config')} className="cursor-pointer">Config</div>
                </div>
                {modalTab === 'config' ? children
                    : <div className="PortOverviewDiv d-flex justify-content-between flex-column">
                        <div>
                            <div className="TopDiv">
                                <div>
                                    <div className="SimplePortDiv" data-sfp={sfp}
                                        style={{
                                            backgroundColor: portBgColor(portStats?.status ?? ""),
                                            border: borderType ? `0.4px solid ${portBorderColor(borderType)}` : null
                                        }}
                                    >
                                        {actualToShow[index]}
                                    </div>
                                </div>
                                <div className="CableDiv">
                                    {sfp ? <SfpCable className="w-100" /> : <EthernetCable className="w-100" />}
                                </div>
                                <div className="d-flex align-items-center">
                                    {apName?.length > 0 ? <APSVG height='50' width='50' /> : <Monitor height='50' width='50' />}
                                </div>
                                <div className="d-flex align-items-center font-weight--600 color--shasta-black">Ethernet&nbsp;{actualToShow[index]}</div>
                                <div>
                                    {!features?.cableDiagnosticSupport ? null :
                                        <Button.Ripple
                                            className="CableTestDiv"
                                            color='primary'
                                            disabled={cableTesting}
                                            onClick={() => {
                                                if (sfp) {
                                                    handleCableTest()
                                                }
                                                else {
                                                    setWarning(true)
                                                }
                                            }}
                                        >
                                            {sfp ? (
                                                !cableTesting ? (
                                                    'Collect SFP Info'
                                                ) : <Spinner size='sm' />
                                            ) : (
                                                <><CableTest />Cable Test</>
                                            )
                                            }
                                        </Button.Ripple>}
                                </div>
                                <div className="d-flex align-items-center font-weight--600 color--shasta-black">{apName}</div>
                            </div>
                            <div className="BottomDiv">
                                <div className="d-block">
                                    <div className="font-weight--500 color--shasta-black">Operational Info:</div>
                                    <ul className="OperationalInfoList">
                                        <li>
                                            <span className="OpInfoKey">Status :&nbsp;</span>
                                            &nbsp;
                                            <span>{portStats?.status ?? 'Unknown'}</span>
                                        </li>
                                        <li>
                                            <span className="OpInfoKey">Speed :&nbsp;</span>
                                            &nbsp;
                                            <span>{portStats?.speed ?? 'Unknown'}</span>
                                        </li>
                                        <li>
                                            <span className="OpInfoKey">Duplex :&nbsp;</span>
                                            &nbsp;
                                            <span>{portStats?.duplex ?? 'Unknown'}</span>
                                        </li>
                                        <li>
                                            <span className="OpInfoKey">PoE power draw :&nbsp;</span>
                                            &nbsp;
                                            <span>
                                                {
                                                    portStats?.poe != null ? (
                                                        portStats?.poe['output-power'] != null ? (
                                                            portStats?.poe['output-power']/1000 + ' W'
                                                        ) : 'Unknown'
                                                    ) : "Unknown"
                                                }
                                            </span>
                                        </li>
                                        <li>
                                            <span className="OpInfoKey">Native VLAN :&nbsp;</span>
                                            &nbsp;
                                            <span>{portData?.vlan?.untaggedVlan ?? "-"}</span>
                                        </li>
                                        <li>
                                            <span className="OpInfoKey">Trunk VLANs :&nbsp;</span>
                                            &nbsp;
                                            <span>{trunkVlans ?? '-'}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="ResultContainer">
                                    {!features?.cableDiagnosticSupport ? null :
                                        sfp ? cableTesting || cableTestResult == null ? null : (
                                            <div className="ResultsDiv">
                                                <div className="d-block color--shasta-black">SFP Operational Info:</div>
                                                <ul className="SfpOpList">
                                                    <li>
                                                        <span className="SfpInfoKey">Vendor :&nbsp;</span>
                                                        &nbsp;
                                                        <span>{cableTestResult["vendor-name"] ?? 'Unknown'}</span>
                                                    </li>
                                                    <li>
                                                        <span className="SfpInfoKey">Form Factor :&nbsp;</span>
                                                        &nbsp;
                                                        <span>{cableTestResult["form-factor"] ?? 'Unknown'}</span>
                                                    </li>
                                                    <li>
                                                        <span className="SfpInfoKey">Part Number :&nbsp;</span>
                                                        &nbsp;
                                                        <span>{cableTestResult["part-number"] ?? 'Unknown'}</span>
                                                    </li>
                                                    <li>
                                                        <span className="SfpInfoKey">Serial number :&nbsp;</span>
                                                        &nbsp;
                                                        <span>{cableTestResult["serial-number"] ?? 'Unknown'}</span>
                                                    </li>
                                                    <li>
                                                        <span className="SfpInfoKey">Revision :&nbsp;</span>
                                                        &nbsp;
                                                        <span>{cableTestResult["revision"] ?? 'Unknown'}</span>
                                                    </li>
                                                    <li>
                                                        <span className="SfpInfoKey">Temperature :&nbsp;</span>
                                                        &nbsp;
                                                        <span>{cableTestResult["temperature"] != null ? <>{cableTestResult["temperature"]}&nbsp;&#176;C</> : 'Unknown'}</span>
                                                    </li>
                                                    <li>
                                                        <span className="SfpInfoKey">Vcc :&nbsp;</span>
                                                        &nbsp;
                                                        <span>{cableTestResult["vcc"] ?? 'Unknown'}</span>
                                                    </li>
                                                    <li>
                                                        <span className="SfpInfoKey">Current :&nbsp;</span>
                                                        &nbsp;
                                                        <span>{cableTestResult["tx-bias-current"] ?? 'Unknown'}</span>
                                                    </li>
                                                    <li>
                                                        <span className="SfpInfoKey">Tx Power :&nbsp;</span>
                                                        &nbsp;
                                                        <span>{cableTestResult["tx-optical-power"] ?? 'Unknown'}</span>
                                                    </li>
                                                    <li>
                                                        <span className="SfpInfoKey">Rx Power :&nbsp;</span>
                                                        &nbsp;
                                                        <span>{cableTestResult["rx-optical-power"] ?? 'Unknown'}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        ) : warning || cableTesting || cableTestResult ? <div className="ResultsDiv" data-warning={!!warning}>
                                            {warning ? (
                                                <div className="CTWarningDiv">
                                                    <div className="font-weight--500">
                                                        Do you want to Cable test?
                                                    </div>
                                                    <div>This is a disruptive process</div>
                                                    <div className="gap--10 d-flex justify-content-end">
                                                        <Button.Ripple color='primary' outline size='sm'
                                                            disabled={cableTesting}
                                                            onClick={() => setWarning(false)}
                                                        >Cancel</Button.Ripple>
                                                        <Button.Ripple color='primary' size='sm'
                                                            disabled={cableTesting}
                                                            onClick={handleCableTest}
                                                        >{cableTesting ? <Spinner size='sm' /> : 'Confirm'}</Button.Ripple>
                                                    </div>
                                                </div>
                                            ) : cableTestResult != null ? (
                                                <>
                                                    <div className="text-center mb-1">{['OK'].includes(cableTestResult.total.status) ? <SuccessIcon /> : null}{meterToFeet(cableTestResult.total.length)}&nbsp;ft&nbsp;({cableTestResult.total.lengthFloored}&nbsp;{cableTestResult.total.lengthFloored === 1 ? 'meter' : 'meters'})</div>
                                                    <div className="position-relative">
                                                        <div>
                                                            <div className="d-flex WireDiv">
                                                                <Wire wireNo={1} color='green' />
                                                            </div>
                                                            <div className="d-flex WireDiv">
                                                                <Wire wireNo={2} color='green' slash={false} />
                                                            </div>
                                                        </div>
                                                        {['OK'].includes(cableTestResult['green'].status) ? null : (
                                                            <ResultPill
                                                                length={cableTestResult['green'].length}
                                                                status={cableTestResult['green'].status}
                                                                id='wire-green'
                                                                index={0}
                                                            />
                                                        )
                                                        }
                                                    </div>
                                                    <div className="d-flex WireDiv position-relative">
                                                        <Wire wireNo={3} color='orange' />
                                                        {['OK'].includes(cableTestResult['orange'].status) ? null : (
                                                            <ResultPill
                                                                length={cableTestResult['orange'].length}
                                                                status={cableTestResult['orange'].status}
                                                                id='wire-orange'
                                                                small={true}
                                                                index={1}
                                                            />
                                                        )
                                                        }
                                                    </div>
                                                    <div className="position-relative">
                                                        <div className="d-flex WireDiv">
                                                            <Wire wireNo={4} color='blue' slash={false} />
                                                        </div>
                                                        <div className="d-flex WireDiv">
                                                            <Wire wireNo={5} color='blue' />
                                                        </div>
                                                        {['OK'].includes(cableTestResult['blue'].status) ? null : (
                                                            <ResultPill
                                                                length={cableTestResult['blue'].length}
                                                                status={cableTestResult['blue'].status}
                                                                id='wire-blue'
                                                                index={2}
                                                            />
                                                        )
                                                        }
                                                    </div>
                                                    <div className="d-flex WireDiv position-relative">
                                                        <Wire wireNo={6} color='orange' slash={false} />
                                                        {['OK'].includes(cableTestResult['orange'].status) ? null : (
                                                            <ResultPill
                                                                length={cableTestResult['orange'].length}
                                                                status={cableTestResult['orange'].status}
                                                                id='wire-orange-second'
                                                                small={true}
                                                                index={3}
                                                            />
                                                        )
                                                        }
                                                    </div>
                                                    <div className="position-relative">
                                                        <div>
                                                            <div className="d-flex WireDiv">
                                                                <Wire wireNo={7} color='brown' />
                                                            </div>
                                                            <div className="d-flex WireDiv">
                                                                <Wire wireNo={8} color='brown' slash={false} />
                                                            </div>
                                                        </div>
                                                        {['OK'].includes(cableTestResult['brown'].status) ? null : (
                                                            <ResultPill
                                                                length={cableTestResult['brown'].length}
                                                                status={cableTestResult['brown'].status}
                                                                id='wire-brown'
                                                                index={4}
                                                            />
                                                        )}
                                                    </div>
                                                </>
                                            ) : null}
                                        </div>
                                            : null}
                                </div>
                                <div className="d-block">
                                    <div className="font-weight--500 color--shasta-black">
                                        LLDP Info:
                                    </div>
                                    <div className="mt-50">
                                        {lldp}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {sfp || !features?.poeSupport?.powerCycle ? null :
                            <div>
                                <Button.Ripple
                                    color='primary'
                                    className='d-flex align-items-center mb-1'
                                    onClick={handlePowerCycle}
                                >
                                    <PowerCycle className="mr-50" />Power Cycle
                                </Button.Ripple>
                            </div>}
                    </div>}
            </ModalBody>
        </Modal>
    )
}

const Port = ({ index, portData, fullConfig, portStats, infraId, infraTypeId, connected, onSubmit, x, y, dim, maxSpeed, orgId, entireConfig, lldp, onClick, vlanId, mode, selectedPorts = new Set(), disabled = false, setTooltip }) => {

    const features = useSelector(store => store?.oneInfra?.features)
    const actualToShow = useSelector(store => store?.oneInfra?.actualToShow)
    const [editModal, setEditModal] = useState(false);
    const [isPutting, setIsPutting] = useState(false);
    const [isError, setIsError] = useState(false);
    const [borderType, setBorderType] = useState(null)
    const [trunkVlans, setTrunkVlans] = useState("")
    const [modalTab, setModalTab] = useState('overview')
    const [warning, setWarning] = useState(false)
    const [cableTesting, setCableTesting] = useState(false)
    const [cableTestResult, setCableTestResult] = useState(null)

    // useEffect(() => {
    //   setCableTestResult({
    //     "green": {
    //         "length": 26,
    //         "status": "OK"
    //     },
    //     "orange": {
    //         "length": 26,
    //         "status": "ON"
    //     },
    //     "blue": {
    //         "length": 26,
    //         "status": "ST"
    //     },
    //     "brown": {
    //         "length": 26,
    //         "status": "IE"
    //     },
    //     "total": {
    //         length: 26*4,
    //         status: "NC"
    //     }
    // })
    // }, [])

    useEffect(() => {
        let trunkVlansString = commaSeparatedList(portData?.vlan?.taggedVlans ?? [])
        setTrunkVlans(trunkVlansString !== "" ? trunkVlansString : "-")
    }, [portData?.vlan?.taggedVlans])


    useEffect(() => {
        const vlanConfig = portData?.vlan
        if (!!vlanConfig) {
            if (mode == 'vlan') {
                if (!!vlanId && vlanConfig?.taggedVlans?.includes(vlanId)) {
                    setBorderType(TRUNK)
                }
                else if (!!vlanId && vlanConfig?.untaggedVlan == vlanId) {
                    setBorderType(ACCESS)
                }
                else setBorderType(null)
            }
            else if (mode == '8021x') {
                if (selectedPorts.has(index))
                    setBorderType('8021x')
                else setBorderType(null)
            }
        }
    }, [portData, index, mode, selectedPorts, vlanId])


    const setPortData = useCallback((data) => {
        setIsError(false);
        if (infraId) {
            setIsPutting(true);
            let newConfig = JSON.parse(JSON.stringify(entireConfig))
            let vlans = {}
            newConfig.configuration.portsConfiguration = data.configuration
            Object.keys(newConfig.configuration.portsConfiguration).map(port => {
                delete newConfig.configuration.portsConfiguration[port].vlan
            })
            Object.keys(newConfig.configuration.vlans ?? {}).map(vlan => {
                if (!!newConfig.configuration.vlans[vlan]?.ipv4?.subnet && !!newConfig.configuration.vlans[vlan]?.ipv4?.subnet[0]?.prefix) {
                    vlans[vlan] = {
                        "subnet": newConfig.configuration.vlans[vlan]?.ipv4?.subnet
                    }
                }
            })

            const { run } = createRequest(services.networks.SWITCHCONFIG_SET, [infraId, orgId], {
                orgId: orgId,
                configuration: {
                    portsConfiguration: newConfig.configuration.portsConfiguration,
                    vlans: vlans
                }
            });
            run()
                .then(response => {
                    setEditModal(false);
                    make_toast("success", "Port settings updated", true);
                    onSubmit();
                    // REDUX_WORKER.getInfra(infraId);
                })
                .catch((err) => {
                    const x = new CatchedWebError(err);
                    setIsError(x.message);
                })
                .finally(() => {
                    setIsPutting(false);
                })
        }
    }, [infraId, orgId]);

    return (<g className="cursor-pointer">
        <rect x={x} y={y} rx={1} width={dim} height={dim} id={`Port${index}`} fill={portBgColor(portStats?.status ?? "")}
            stroke-width={!!borderType ? 0.4 : 0}
            stroke={portBorderColor(borderType)}
        />
        <foreignObject x={x} y={y} width={dim} height={dim} onMouseOver={() => {
            const div = document.getElementById(`Port${index}`);
            const rect = div.getBoundingClientRect();
            clearTimeout(timeout)
            timeout = setTimeout(() => setTooltip({
                show: true, x: (rect.left + (rect.width / 2)), y: rect.bottom, data: {
                    index: index,
                    connected: connected,
                    portData: portData,
                    trunkVlans: trunkVlans,
                    portStats: portStats,
                    lldp: lldp
                }
            }), 500);
        }}
            onMouseLeave={() => {
                clearTimeout(timeout)
                setTooltip(prevState => { return { ...prevState, show: false } })
            }}
            onClick={() => {
                if (!disabled) {
                    if (!onClick) {
                        if (portData)
                            setEditModal(true)
                    }
                    else {
                        // setIsTooltipActive(false)
                        onClick(index)
                    }
                }
            }}>
        </foreignObject>
        {/* <text x= y=fill="white" class="font-small">{i+1}</text> */}
        <text x={x+(dim/2)} y={y+(dim/2)} className="font-small" fill="white"  dominant-baseline="middle" text-anchor="middle">{actualToShow[index]}</text> 
        {/* <Port index={item} key={item} connected={stats.EthernetStatus[`Ethernet${item}`]} portData={config[`Ethernet${item}`]} fullConfig={config} infraId={infraId} onSubmit={onSubmit}/> */}
        {/* <div style={{backgroundColor:"red",height:"100%", width:"100%"}}></div> */}
        {/* <ToolTip active={isTooltipActive} position="bottom" arrow="center" parent={`#Port${index}`}>
        </ToolTip> */}
        <PortModal
            editModal={editModal}
            setEditModal={setEditModal}
            modalTab={modalTab}
            setModalTab={setModalTab}
            actualToShow={actualToShow}
            index={index}
            isPutting={isPutting}
            portData={portData}
            setPortData={setPortData}
            fullConfig={fullConfig}
            warning={warning}
            setWarning={setWarning}
            portStats={portStats}
            trunkVlans={trunkVlans}
            cableTesting={cableTesting}
            cableTestResult={cableTestResult}
            lldp={lldp}
            infraId={infraId}
            setCableTesting={setCableTesting}
            setCableTestResult={setCableTestResult}
            borderType={borderType}
        >
            <Container className="PortConfigTabContainer d-flex flex-column">
                <div className="ConfigHeading mb-50">Ethernet&nbsp;{actualToShow[index]}</div>
                <p className="text-center text-primary"><strong>{isPutting && "Saving..."}</strong></p>
                <AlertBox color="danger" isOpen={!!isError} toggle={() => { setIsError(false); }}>
                    {isError}
                </AlertBox>
                <PortForm hidePOE={features?.poe === false} infraTypeId={infraTypeId} isPutting={isPutting} setPortData={setPortData} index={index} portData={portData} fullConfig={fullConfig} infraId={infraId} connected={connected} x={x} y={y} dim={dim} onCancel={() => { if (!isPutting) setEditModal(false); }} maxSpeed={maxSpeed} />
            </Container>
        </PortModal>
    </g>)
};

/// PORT FORM ===============================>
export const PortForm = (props) => {
    const { isPutting, index, portData, fullConfig, infraTypeId, connected, onSubmit, x, y, dim, onCancel, setPortData, hidePOE = false, maxSpeed = "1000", minSpeed = "0", profile = false, disable802 } = props;
    const [duplex, setDuplex] = useState(portData?.duplex || "full");
    const infraTypes = useSelector(store => store.infraTypes.data);
    const features = useSelector(store => store.infraTypes.data.find(it => it.infraTypeId == infraTypeId).ports.capabilities)
    const [enabled, setEnabled] = useState(portData?.enabled);
    const [speed, setSpeed] = useState(portData?.speed || 100);
    const [mode, setMode] = useState(portData?.vlan?.mode || "Access");
    const [untaggedVlan, setUntaggedVlan] = useState(portData?.vlan?.untaggedVlan || null);
    const [taggedVlans, setTaggedVlans] = useState(commaSeparatedList(portData?.vlan?.taggedVlans ?? []));
    const [adminMode, setAdminMode] = useState(portData?.poe?.hasOwnProperty("admin-mode") ? portData?.poe["admin-mode"] : true); // fix this
    const [detection, setDetection] = useState(portData?.poe?.detection || "");
    const [doReset, setDoReset] = useState(portData?.poe?.hasOwnProperty("do-reset") ? portData?.poe["do-reset"] : false);          // fix this
    const [powerLimit, setPowerLimit] = useState(portData?.poe?.hasOwnProperty("power-limit") ? portData?.poe["power-limit"] : 99900);  // fix this
    const [priority, setPriority] = useState(portData?.poe?.priority || "high");
    const [ieee, setIeee] = useState(Object.keys(portData?.ieee8021x ?? {}).length > 0);

    const [error, setError] = useState({
        taggedVlans: "",
        untaggedVlans: "",
        powerLimit: ""
    })

    const switchDuplex = [{ label: "Half", value: "half" }, { label: "Full", value: "full" }];
    const switchEnable = [{ label: "Yes", value: true }, { label: "No", value: false }];
    const switchPriority = [{ label: "Critical", value: "critical" }, { label: "High", value: "high" },
    { label: "Medium", value: "medium" }, { label: "Low", value: "low" }];
    const switchPoePower = [{ label: "On", value: true }, { label: "Off", value: false }]

    const form = useMemo(() => {
        return {
            duplex: duplex,
            enabled: enabled,
            speed: speed,
            vlan: {
                mode: mode,
                taggedVlans: parseVlanSyntax(taggedVlans)[0],
                untaggedVlan: untaggedVlan
            },
            poe: {
                "admin-mode": adminMode,
                "detection": detection,
                "do-reset": doReset,
                "power-limit": powerLimit,
                "priority": priority
            }
        };
    }, [duplex, enabled, speed, mode, untaggedVlan, taggedVlans, adminMode, detection, doReset, powerLimit, priority]);

    return (
        <form
            className={`${profile ? '' : 'switch-config-form d-flex flex-column justify-content-between'}`}
            onSubmit={(e) => {
                e.preventDefault()
                let formFinalised = {
                    duplex: duplex,
                    enabled: enabled,
                    speed: speed,
                    vlan: {
                        mode: mode
                    },
                };
                if (!hidePOE) {
                    formFinalised.poe = {
                        "admin-mode": adminMode,
                        detection: detection,
                        "power-limit": powerLimit,
                        priority: priority
                    }
                    if (doReset)
                        formFinalised.poe["do-reset"] = doReset;
                }

                if (form.vlan.untaggedVlan) {
                    formFinalised.vlan.untaggedVlan = form.vlan.untaggedVlan
                }
                else {
                    formFinalised.vlan.untaggedVlan = DEFAULT_VLAN
                }

                if (formFinalised.vlan.mode === "Trunk") {
                    formFinalised.vlan.taggedVlans = form.vlan.taggedVlans;
                }

                if (profile) {
                    if (ieee) {
                        formFinalised.ieee8021x = {
                            "is-authenticator": true,
                            "authentication-mode": "auto",
                            "host-mode": "multi-auth"
                        }
                    }
                }

                let key = `Ethernet${index}`;
                let newFullConfig = {};
                newFullConfig.configuration = { ...fullConfig, [key]: formFinalised }

                setPortData(newFullConfig);
                if (onSubmit)
                    onSubmit();
            }}>
            <div>
                {profile ? <h5 className="headings">Link Settings</h5>
                    : <div className="ConfigSubHeading mb-1">Link Settings</div>}
                <Row>
                    <Col xs={4}>
                        <div className="cInput">
                            <label>Duplex</label>
                            <Select
                                defaultValue={[...switchDuplex, { value: "inherit", label: "Inherit" }].find(it => it.value == duplex)}
                                isDisabled={props.disabled}
                                onChange={(e) => { setDuplex(e.value); }}
                                options={!profile ? [{ value: "inherit", label: "Inherit" }, ...switchDuplex] : switchDuplex}
                            />
                        </div>
                    </Col>
                    <Col xs={4}>
                        <div className="cInput">
                            <label>Enabled</label>
                            <Select
                                defaultValue={[...switchEnable, { value: "inherit", label: "Inherit" }].find(it => it.value == enabled)}
                                isDisabled={props.disabled}
                                onChange={(e) => { setEnabled(e.value) }}
                                options={!profile ? [{ value: "inherit", label: "Inherit" }, ...switchEnable] : switchEnable}
                            />
                        </div>
                    </Col>
                    <Col xs={4}>
                        <div className="cInput">
                            <label>Speed</label>
                            <Select
                                defaultValue={[...portSpeed, { value: "inherit", label: "Inherit" }].find(it => it.value == speed)}
                                isDisabled={props.disabled}
                                onChange={(e) => { setSpeed(e.value == "inherit" ? e.value : Number.parseInt(e.value)) }}
                                options={!profile ? [{ value: "inherit", label: "Inherit" }, ...portSpeed.filter(it => ((Number(it.value) <= Number(maxSpeed)) && (Number(it.value) >= Number(minSpeed))))] : portSpeed.filter(it => ((Number(it.value) <= Number(maxSpeed)) && (Number(it.value) >= Number(minSpeed))))}
                            />
                        </div>
                    </Col>
                </Row>

                {profile ? <div>
                    <h5 className="mt-2 headings">VLAN Settings</h5>
                    <Row>
                        <Col xs={4}>
                            <div className="cInput">
                                <label>Port Mode</label>
                                <Select
                                    defaultValue={vl(mode)}
                                    isDisabled={props.disabled}
                                    onChange={(e) => { if (e.value === "Access") { setTaggedVlans(commaSeparatedList(portData?.vlan?.taggedVlans ?? [])); setError(er => ({ ...er, taggedVlans: "" })) } setMode(e.value) }}
                                    options={[vl("Access"), vl("Trunk")]}
                                />
                            </div>
                        </Col>
                        {mode === "Trunk" &&
                            <Col xs={4}>
                                <div className="cInput">
                                    <label className="d-inline-flex align-items-center">Trunk VLANs (1-4094) &nbsp;<span className="material-symbols-outlined cursor-pointer" style={{ fontSize: "15px", fontWeight: "bold" }} id="TRUNK_LABEL">info</span></label>
                                    <UncontrolledTooltip target="TRUNK_LABEL">{TRUNK_LABEL}</UncontrolledTooltip>
                                    <Input type="text" value={taggedVlans} invalid={error.taggedVlans.length > 0} disabled={props.disabled} onChange={(e) => {
                                        const [vlans, err] = parseVlanSyntax(e.target.value);
                                        if (!!err) {
                                            setError(er => ({ ...er, taggedVlans: err }))
                                            setTaggedVlans(e.target.value)
                                        }
                                        else if (err == null || e.target.value === "") {
                                            if (!err && untaggedVlan && vlans?.find(it => it === Number(untaggedVlan))) {
                                                setError(er => ({ ...er, taggedVlans: "Can't have native VLAN" }))
                                            }
                                            else
                                                setError(er => ({ ...er, taggedVlans: "" }))
                                            setTaggedVlans(e.target.value);
                                        } else {
                                            if (err !== "error")
                                                setError(er => ({ ...er, taggedVlans: err }))
                                            else {
                                                if (!err && untaggedVlan && vlans?.find(it => it === Number(untaggedVlan))) {
                                                    setError(er => ({ ...er, taggedVlans: "Can't have native VLAN" }))
                                                }
                                                else
                                                    setError(er => ({ ...er, taggedVlans: "" }))
                                            }
                                        }
                                    }} />
                                    <FormFeedback>{error.taggedVlans}</FormFeedback>
                                </div>
                            </Col>
                        }
                        <Col xs={4}>
                            <div className="cInput">
                                <label>{mode === "Trunk" && <>Access</>} VLAN</label>
                                <Input type="text" value={untaggedVlan} invalid={!!error.untaggedVlans} disabled={props.disabled} onChange={(e) => {
                                    if (e.target.value === "") {
                                        setUntaggedVlan(null);
                                    }
                                    if (isInteger(e.target.value)) {
                                        if (e.target.value < 1 || e.target.value > 4094)
                                            setError(er => ({ ...er, untaggedVlans: "Enter value [1-4094]" }));
                                        else
                                            setError(er => ({ ...er, untaggedVlans: "" }));

                                        if (mode === "Trunk") {
                                            if (e.target.value < 1 || e.target.value > 4094)
                                                setError(er => ({ ...er, untaggedVlans: "Enter value [1-4094]" }));
                                            else if (parseVlanSyntax(taggedVlans)[0].find(it => it === Number(e.target.value))) {
                                                setError(er => ({ ...er, untaggedVlans: "Native VLAN can't be among Trunk VLANs" }));
                                            } else {
                                                setError(er => ({ ...er, untaggedVlans: "" }))
                                            }
                                        }
                                        setUntaggedVlan(Number(e.target.value));
                                    } else {
                                        if (e.target.value.length === 0)
                                            setError(er => ({ ...er, untaggedVlans: "" }))
                                    }
                                }} />
                                <FormFeedback>{error.untaggedVlans}</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                </div> : <></>}

                {profile ?
                    <h5 className={"headings mt-2 " + (hidePOE ? "d-none" : "")}>POE Settings</h5>
                    : <div className={"ConfigSubHeading mt-2 mb-1 " + (hidePOE ? "d-none" : "")}>POE Settings</div>}
                <Row className={"" + (hidePOE ? "d-none" : "")}>
                    <Col className="mb-1" xs={4}>
                        <div>
                            <label>Power</label>
                            <Select
                                defaultValue={[...switchPoePower, { value: "inherit", label: "Inherit" }].find(it => it.value == adminMode)}
                                options={profile ? switchPoePower : [{ value: "inherit", label: "Inherit" }, ...switchPoePower]}
                                isDisabled={props.disabled}
                                onChange={(e) => { setAdminMode(e.value) }} />
                        </div>
                    </Col>
                    {/* <Col xs={4}>
                    {(!profile && features?.poeSupport?.powerCycle) ?
                        <div className="d-flex align-items-center mt-2">
                            <Toggle
                                value={doReset}
                                displayText={false}
                                disabled={props.disabled}
                                onClick={(e) => {
                                    setDoReset(prevState => !prevState)
                                }} />
                            <span>Power Cycle</span>
                        </div> : null}
                </Col> */}
                </Row>
                <Row className={(hidePOE ? "d-none" : "")}>
                    {(Array.isArray(features?.poeSupport?.supportedProtocols) && features?.poeSupport?.supportedProtocols?.length > 0) ?
                        <Col xs={4}>
                            <div className="cInput">
                                <label>Detection</label>
                                <Select
                                    defaultValue={vl(detection)}
                                    isDisabled={props.disabled}
                                    onChange={e => { setDetection(e.value) }}
                                    // options={[vl("2pt-dot3af"), vl("2pt-dot3af+legacy"), vl("4pt-dot3af"), vl("4pt-dot3af+legacy"), vl("legacy")]}
                                    options={!profile ? [{ value: "inherit", label: "Inherit" }, ...features?.poeSupport?.supportedProtocols?.map(item => vl(item))] : [...features?.poeSupport?.supportedProtocols?.map(item => vl(item))]}
                                />
                            </div>
                        </Col> : null}
                    <Col xs={4}>
                        <div className="cInput">
                            <label>Power Limit (mW)</label>
                            {profile ?
                                <Input type="text" value={powerLimit} disabled={props.disabled} invalid={error.powerLimit.length > 0} onChange={(e) => {
                                    if (e.target.value === "") {
                                        setPowerLimit("");
                                        setError(prevState => { return { ...prevState, powerLimit: "Required" } })
                                    }
                                    else if (e.target.value < 0 || e.target.value > (infraTypes.find(it => it.infraTypeId == infraTypeId)?.ports?.capabilities?.poeSupport?.POELimit ?? 65000)) {
                                        setPowerLimit(Number(e.target.value));
                                        setError(prevState => { return { ...prevState, powerLimit: `Enter [0-${infraTypes.find(it => it.infraTypeId == infraTypeId)?.ports?.capabilities?.poeSupport?.POELimit ?? 65000}]` } })
                                    }
                                    else {
                                        setError(prevState => { return { ...prevState, powerLimit: "" } })
                                        setPowerLimit(e.target.value);
                                        if (isInteger(e.target.value)) {
                                            setPowerLimit(Number(e.target.value));
                                        }
                                    }
                                }} /> :
                                <Creatable
                                    defaultValue={vl(powerLimit)}
                                    isDisabled={props.disabled}
                                    onChange={(e) => {
                                        if (e.value === "") {
                                            setPowerLimit("");
                                            setError(prevState => { return { ...prevState, powerLimit: "Required" } })
                                        }
                                        else if (e.value >= (infraTypes.find(it => it.infraTypeId == infraTypeId)?.ports?.POELimit ?? 65000)) {
                                            setPowerLimit(Number(e.value));
                                            setError(prevState => { return { ...prevState, powerLimit: `Enter [0-${infraTypes.find(it => it.infraTypeId == infraTypeId)?.ports?.POELimit ?? 65000}]` } })
                                        }
                                        else {
                                            setError(prevState => { return { ...prevState, powerLimit: "" } })
                                            if (isInteger(e.value)) {
                                                setPowerLimit(Number(e.value));
                                            }
                                            else if (e.value == "inherit")
                                                setPowerLimit(e.value);
                                        }
                                    }}
                                    options={[{ value: "inherit", label: "Inherit" }, { value: 60000, label: "60000" }]}
                                />
                            }
                            {!!error.powerLimit && <span className="text-danger">{error.powerLimit}</span>}
                        </div>
                    </Col>
                    <Col xs={4}>
                        <div className="cInput">
                            <label>Priority</label>
                            <Select
                                defaultValue={[...switchPriority, { value: "inherit", label: "Inherit" }].find(it => it.value == priority)}
                                isDisabled={props.disabled}
                                onChange={e => { setPriority(e.value) }}
                                options={profile ? switchPriority : [{ value: "inherit", label: "Inherit" }, ...switchPriority]}
                            />
                        </div>
                    </Col>
                </Row>
                {(profile && features?.ieee8021xSupport) ? <span className="d-inline-flex align-items-center mt-50">
                    <Toggle
                        value={ieee}
                        disabled={disable802 || props.disabled}
                        displayText={false}
                        onClick={(e) => {
                            setIeee(prevState => !prevState)
                        }} /> <span>Port 802.1X</span>
                </span> : null}
            </div>
            {!props.disabled &&
                <div>
                    <Row className="mb-1 mt-3">
                        <Col xs={12} className="d-flex justify-content-end">
                            <Button color="outline-primary" type="reset" className="mr-1" onClick={onCancel}>Cancel</Button>
                            <input type="submit" className="btn btn-primary" value="Save" disabled={error.powerLimit.length > 0 || error.taggedVlans.length > 0 || error.untaggedVlans.length > 0 || isPutting} />
                        </Col>
                    </Row>
                </div>}
        </form>
    );
};

const MngPorts = ({ con, mngt, x, y, dim }) => {
    return (
        <g className="cursor-pointer"
            onClick={() => { }}
        >
            <foreignObject x={x} y={y - 2} width={dim} height={2}>
                <div className="port-text">MGMT</div>
            </foreignObject>
            <rect x={x} y={y} rx={1} width={dim} height={dim} fill={"#808080"} />
            <rect x={x} y={y + dim + 1} rx={1} width={dim} height={dim} fill={"#808080"} />
            <foreignObject x={x} y={y + (2 * dim) + 1} width={dim} height={2}>
                <div className="port-text">CNSL</div>
            </foreignObject>
            <line x1={x + dim + 1} y1={4} x2={x + dim + 1} y2={17} stroke="#BABABA" strokeWidth={0.2} />
        </g>
    );
}

const SFPPorts = ({ index, x, y, dim, onSubmit, portData, infraTypeId, fullConfig, portStats, infraId, connected, maxSpeed, orgId, lldp, mode, vlanId, selectedPorts = new Set(), onClick, entireConfig, disabled = false, setTooltip }) => {

    const actualToShow = useSelector(store => store?.oneInfra?.actualToShow)
    const [editModal, setEditModal] = useState(false);
    const [isPutting, setIsPutting] = useState(false);
    const [isError, setIsError] = useState(false);
    const [borderType, setBorderType] = useState(null)
    const [trunkVlans, setTrunkVlans] = useState("")
    const [modalTab, setModalTab] = useState('overview')
    const [warning, setWarning] = useState(false)
    const [cableTesting, setCableTesting] = useState(false)
    const [cableTestResult, setCableTestResult] = useState(null)

    useEffect(() => {
        let trunkVlansString = commaSeparatedList(portData?.vlan?.taggedVlans ?? [])
        setTrunkVlans(trunkVlansString !== "" ? trunkVlansString : "-")
    }, [portData?.vlan?.taggedVlans])

    useEffect(() => {
        const vlanConfig = portData?.vlan
        if (!!vlanConfig) {
            if (mode == 'vlan') {
                if (!!vlanId && vlanConfig?.taggedVlans?.includes(vlanId)) {
                    setBorderType(TRUNK)
                }
                else if (!!vlanId && vlanConfig?.untaggedVlan == vlanId) {
                    setBorderType(ACCESS)
                }
                else setBorderType(null)
            }
            else if (mode == '8021x') {
                if (selectedPorts.has(index))
                    setBorderType('8021x')
                else setBorderType(null)
            }
        }
    }, [portData, index, mode, selectedPorts, vlanId])

    const setSFPData = useCallback((data) => {
        setIsError(false);
        if (infraId) {
            setIsPutting(true);
            let newConfig = JSON.parse(JSON.stringify(entireConfig))
            let vlans = {}
            newConfig.configuration.portsConfiguration = data.configuration
            Object.keys(newConfig.configuration.portsConfiguration).map(port => {
                delete newConfig.configuration.portsConfiguration[port].vlan
            })
            Object.keys(newConfig.configuration.vlans ?? {}).map(vlan => {
                if (!!newConfig.configuration.vlans[vlan]?.ipv4?.subnet && !!newConfig.configuration.vlans[vlan]?.ipv4?.subnet[0]?.prefix) {
                    vlans[vlan] = {
                        "subnet": newConfig.configuration.vlans[vlan]?.ipv4?.subnet
                    }
                }
            })

            const { run } = createRequest(services.networks.SWITCHCONFIG_SET, [infraId, orgId], {
                orgId: orgId,
                configuration: {
                    portsConfiguration: newConfig.configuration.portsConfiguration,
                    vlans: vlans
                }
            });
            run()
                .then(response => {
                    setEditModal(false);
                    make_toast("success", "Port settings updated", true);
                    onSubmit();
                    // REDUX_WORKER.getInfra(infraId);
                })
                .catch((err) => {
                    const x = new CatchedWebError(err);
                    setIsError(x.message);
                })
                .finally(() => {
                    setIsPutting(false);
                })
        }
    }, [infraId]);

    return (<g className="cursor-pointer">
        <rect x={x} y={y} rx={1} width={dim + 2} height={dim} id={`Port${index}`} fill={portBgColor(portStats?.status ?? "")}
            stroke-width={!!borderType ? 0.4 : 0}
            stroke={portBorderColor(borderType)}
        />
        <foreignObject x={x} y={y} width={dim + 2} height={dim} onMouseOver={() => {
            const div = document.getElementById(`Port${index}`);
            const rect = div.getBoundingClientRect();
            clearTimeout(timeout)
            timeout = setTimeout(() => setTooltip({
                show: true, x: (rect.left + (rect.width / 2)), y: rect.bottom, data: {
                    index: index,
                    connected: connected,
                    portData: portData,
                    trunkVlans: trunkVlans,
                    portStats: portStats,
                    lldp: lldp
                }
            }), 500);
        }}
            onMouseLeave={() => {
                clearTimeout(timeout)
                setTooltip(prevState => { return { ...prevState, show: false } })
            }}
            onClick={() => {
                if (!disabled) {
                    if (!onClick) {
                        if (portData)
                            setEditModal(true)
                    }
                    else {
                        // setIsTooltipActive(false)
                        onClick(index)
                    }
                }
            }}>
        </foreignObject>
        <text x={x+((dim+2)/2)} y={y+((dim)/2)} className="font-small" fill="white"  dominant-baseline="middle" text-anchor="middle">{actualToShow[index]}</text> 
        <PortModal
            editModal={editModal}
            setEditModal={setEditModal}
            modalTab={modalTab}
            setModalTab={setModalTab}
            actualToShow={actualToShow}
            index={index}
            isPutting={isPutting}
            portData={portData}
            fullConfig={fullConfig}
            warning={warning}
            setWarning={setWarning}
            portStats={portStats}
            trunkVlans={trunkVlans}
            cableTesting={cableTesting}
            cableTestResult={cableTestResult}
            lldp={lldp}
            infraId={infraId}
            setCableTesting={setCableTesting}
            setCableTestResult={setCableTestResult}
            borderType={borderType}
            sfp={true}
        >
            <Container className="PortConfigTabContainer d-flex flex-column">
                <div className="ConfigHeading mb-50">Ethernet&nbsp;{actualToShow[index] ?? ''}</div>
                <p className="text-center text-primary"><strong>{isPutting && "Saving..."}</strong></p>
                <AlertBox color="danger" isOpen={!!isError} toggle={() => { setIsError(false); }}>
                    {isError}
                </AlertBox>
                <PortForm isPutting={isPutting} infraTypeId={infraTypeId} hidePOE setPortData={setSFPData} index={index} portData={portData} fullConfig={fullConfig} infraId={infraId} connected={connected} x={x} y={y} dim={dim} onCancel={() => { if (!isPutting) setEditModal(false); }} maxSpeed={maxSpeed} minSpeed="10000" />
            </Container>
        </PortModal>
        {/* <text x={x+(dim/2)-1} y={11+(dim/2)} fill="white" class="font-small">{i+1}</text> */}
        {/* <Port index={item} key={item} connected={stats.EthernetStatus[`Ethernet${item}`]} portData={config[`Ethernet${item}`]} fullConfig={config} infraId={infraId} onSubmit={onSubmit}/> */}
        {/* <div style={{backgroundColor:"red",height:"100%", width:"100%"}}></div> */}
    </g>)
}

export const LegendItem = ({ name = '', bgColor = null, borderColor = null }) => {
    return (
        <span className="switch-label">
            <div className="switch-colour-label"
                style={{
                    backgroundColor: bgColor ?? '#fff',
                    border: (!!borderColor ? ('2px solid ' + borderColor) : '')
                }}></div>
            {name}
        </span>
    )
}

const Legend = ({ mode }) => {
    const legends = [
        <LegendItem name='Connected' bgColor='#1FC78F' />,
        <LegendItem name='Disconnected' bgColor='#808080' />,
        <LegendItem name='Disabled' bgColor='#000' />,
        <LegendItem name='Warning' bgColor='#FFBE40' />
    ]
    if (mode == 'default') {
        return legends;
    }
    else if (mode == 'vlan') {
        legends.push(
            <LegendItem borderColor="#7A0303" name="Trunk" />,
            <LegendItem borderColor="#7367F0" name="Access" />
        )
        return legends;
    }
    else if (mode == '8021x') {
        legends.push(
            <LegendItem borderColor="#E218CE" name="802.1x" />
        )
        return legends
    }
    else return null;
}

const DummySwitch = ({ mode = 'default', config, infraId, infraTypeId, onSubmit, dummy = false, lanPorts, sfpPorts, online, entireConfig, vlanId = null, onClick, selectedPorts, disabled }) => {
    const stats = useSelector(store => store?.oneInfra?.stats);
    const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
    const actualToShow = useSelector(store => store?.oneInfra?.actualToShow)
    const [tooltip, setTooltip] = useState({ x: 0, y: 0 });
    let portsWidth = ((lanPorts.length / 2) * 6 * 1.05)
    let end = 160 - (160 - portsWidth) / 2
    let dim = 6

    const lldpFormatter = (connectedDevices, portString) => {
        if (!!connectedDevices && !!connectedDevices[portString] && Array.isArray(connectedDevices[portString])) {
            let lldp = []
            connectedDevices[portString].forEach(device => {
                if (!!device?.lldp) {
                    lldp.push(device?.lldp)
                }
            })
            return lldp.join("\n")
        }
        return "-"
    }

    return (
        <div className="" >
            <svg viewBox="0 0 200 20">
                {/* <MngPorts con mngt x={end - portsWidth - 2} dim={dim} y={4} /> */}
                {Array.from({ length: lanPorts.length }, (v, i) => lanPorts[i].portNumber).map((item, index) => {

                    let x = end - (((lanPorts.length / 2) - (Math.floor(index / 2) + 1)) * dim * 1.1) + (Math.floor(index / 12) * 1.75);
                    let y = (index % 2) ? 5 + dim : 4
                    // console.log(
                    //   "index--->", index,
                    //   "item--->", item,
                    //   "x--->", x,
                    //   "y---->", y,
                    //   "fixFactor--->", ((index/8) * 0.01),
                    // )  
                    return (
                        <g>
                            <Port entireConfig={entireConfig} index={item - 1} key={item}
                                connected={(!!stats?.EthernetStatus && (stats?.EthernetStatus[`Ethernet${item - 1}`]?.status == 'Connected' || stats?.EthernetStatus[`Ethernet${item - 1}`]?.status == "Warning"))}
                                portData={dummy ? null : config[`Ethernet${item - 1}`]} fullConfig={config}
                                portStats={!!stats?.EthernetStatus ? stats?.EthernetStatus[`Ethernet${item - 1}`] ?? {} : {}} infraId={infraId}
                                lldp={lldpFormatter(stats?.devices, `Ethernet${item - 1}`)}
                                onSubmit={onSubmit} x={x} y={y} dim={dim} maxSpeed={lanPorts[index].Speed} orgId={activeOrgId}
                                mode={mode}
                                vlanId={vlanId}
                                setTooltip={setTooltip}
                                infraTypeId={infraTypeId}
                                onClick={onClick}
                                selectedPorts={selectedPorts}
                                disabled={disabled}
                            />
                            {/*Lines Every 12 Ports */}
                            {
                                (item + 1) % 12 == 0 ?
                                    <line x1={x + dim + 1} y1={4} x2={x + dim + 1} y2={17} stroke="#BABABA" strokeWidth={0.2} />
                                    : null
                            }
                        </g>
                    );
                })}
                {
                    (lanPorts.length < 12) ?
                        <line x1={end + dim + 4} y1={4} x2={end + dim + 4} y2={17} stroke="#BABABA" strokeWidth={0.2} /> : null
                }
                {
                    Array.from({ length: sfpPorts.length ?? 0 }, (v, i) => sfpPorts[i].portNumber).map((item, index) => {
                        let x = end + ((Math.floor(index / 2) + 2) * (dim + 2) * 1.05) - 3.25;
                        let y = (index % 2) ? 5 + dim : 4;
                        // console.log(
                        //     "index--->", index,
                        //     "x--->", x,
                        //     "y---->", y,
                        // )
                        return (
                            <SFPPorts x={x} y={y} dim={dim} offset={lanPorts.length} fullConfig={config}
                                lldp={lldpFormatter(stats?.devices, `Ethernet${item - 1}`)}
                                index={item - 1} connected={(!!stats?.EthernetStatus && (stats?.EthernetStatus[`Ethernet${item - 1}`]?.status == "Connected" || stats?.EthernetStatus[`Ethernet${item - 1}`]?.status == "Warning"))}
                                portData={dummy ? null : config ? config[`Ethernet${item - 1}`] : null} infraId={infraId}
                                portStats={!!stats?.EthernetStatus ? stats?.EthernetStatus[`Ethernet${item - 1}`] ?? {} : {}}
                                onSubmit={onSubmit} maxSpeed={sfpPorts[index].Speed} orgId={activeOrgId}
                                mode={mode}
                                vlanId={vlanId}
                                onClick={onClick}
                                setTooltip={setTooltip}
                                infraTypeId={infraTypeId}
                                selectedPorts={selectedPorts}
                                entireConfig={entireConfig}
                                disabled={disabled}
                            />
                        );
                    })
                }
            </svg>

            <Tooltip isOpen={tooltip.show} place="bottom" opacity={1} variant="light" border="2px solid #EAEAEA" style={{ zIndex: 1 }} position={{ x: tooltip?.x, y: tooltip?.y }}>
                <div style={{ width: "275px" }}>
                    <h5 className="font-weight-bolder mb-2">Ethernet{actualToShow[tooltip?.data?.index]}</h5>
                    <Row>
                        <Col xs={12} md={6}>
                            <p className="d-flex justify-content-between mb-0">
                                <span className="firstRow">Connected</span>
                                <strong className="secondRow">{tooltip?.data?.connected === true ? "Yes" : (tooltip?.data?.connected === false ? "No" : "Unknown")}</strong>
                            </p>
                            <p className="d-flex justify-content-between">
                                <span className="firstRow">Native VLAN</span>
                                <strong className="secondRow">
                                    {tooltip?.data?.portData?.vlan?.untaggedVlan ?? "-"}
                                </strong>
                            </p>
                            <p className="">
                                <div className="firstRow">Trunk VLANS</div>
                                <strong className="secondRow">{tooltip?.data?.trunkVlans}</strong>
                            </p>
                        </Col>
                        <Col xs={12} md={6}>
                            <p className="d-flex justify-content-between mb-0">
                                <span className="firstRow">Speed</span>
                                <strong className="secondRow">{tooltip?.data?.portStats?.speed ?? "Unknown"}</strong>
                            </p>
                            <p className="d-flex justify-content-between">
                                <span className="firstRow">Duplex</span>
                                <strong className="secondRow">{tooltip?.data?.portStats?.duplex ?? "Unknown"}</strong>
                            </p>
                        </Col>
                        <Col xs={12}>
                            <div className="firstRow">LLDP</div>
                            <strong className="secondRow" style={{ whiteSpace: "pre-wrap" }}>{tooltip?.data?.lldp ?? "-"}</strong>
                        </Col>
                    </Row>
                </div>
            </Tooltip>

            {mode == 'default'
                ? <div className="d-flex justify-content-center mt-2">
                    <Legend mode={mode} />
                </div>
                : <div className="d-flex justify-content-center mt-2">
                    <Legend mode={mode} />
                </div>
            }
        </div>
    );
};

DummySwitch.propTypes = {};

DummySwitch.defaultProps = {};

export default DummySwitch;
