import { createSlice } from "@reduxjs/toolkit";

const orgInitialState = {
  status: {
    loading: false,
    error: null
  },
  data: {
    orgId: null,
    parentOrgId: null,
    orgDisplayName: null,
    orgTypeId: null,
    createdAt: null,
    updatedAt: null,
    OLSEnable: null,
    lidarScan: null,
    orgAddress: {
      addressLine: null,
      state: null,
      city: null,
      zipCode: null
    }
  },
  meta: {
    roles: []
  }
};

const orgSlice = createSlice({
  name: "org",
  initialState: orgInitialState,
  reducers: {
    setLoading: (state, action) => {
      state.status.loading = action.payload;
    },
    setError: (state, action) => {
      state.status.error = action.payload;
    },
    setOrg: (state, action) => {
      state.data = action.payload;
    },
    setOLSEnabled: (state, action) => {
      state.data.OLSEnable = action.payload;
    },
    setRoles: (state, action) => {
      state.meta.roles = [...(action.payload)];
    },
    resetOrg: (state) => {
      state = {...orgInitialState};
    },
    setLidarScan: (state, action) => {
      state.data.lidarScan = action.payload;
    }
  }
});

export const orgReducer = orgSlice.reducer;
const orgActions = orgSlice.actions;
export default orgActions;

