import { orgActions } from "../slices";
import store from "../store";
import createRequest, { services } from "../../services";
import { CatchedWebError } from "../../configs";

export const getOrg = () => {
  store.dispatch(orgActions.setLoading(true));
  const { controller, run } = createRequest(services.organization.GET_SELF);
  run()
    .then(response => {
      store.dispatch(orgActions.setOrg(response.data));
    })
    .catch(err => {
      let x = new CatchedWebError(err);
      store.dispatch(orgActions.setError(x.message));
    })
    .finally(() => {
      store.dispatch(orgActions.setLoading(false));
    });
  return controller;
};

export const getOrgRoles = () => {
  store.dispatch(orgActions.setLoading(true));
  const orgId = store.getState().identity.data.orgId;
  const { controller, run } = createRequest(services.organization.GET_ROLES, [orgId]);
  run()
    .then(response => {
      store.dispatch(orgActions.setRoles(response.data));
    })
    .catch(err => {
      let x = new CatchedWebError(err);
      store.dispatch(orgActions.setError(x.message));
    })
    .finally(() => {
      store.dispatch(orgActions.setLoading(false));
    });
  return controller;
};