import { createSlice } from "@reduxjs/toolkit";

const breadcrumbInitialstate = {
    status: {
      loading: false,
      error: null
    },
    data:[],
    venuecrumb:{
      venueId:null,
      parentPath:[]
    },
    orgcrumb:{
      orgId:null,
      parentPath:[]
    }

  };

  const breadcrumbSlice = createSlice({
    name: "breadcrumb",
    initialState: {...breadcrumbInitialstate},
    reducers: {
      setLoading: (state, action) => {
        state.status.loading = action.payload;
      },
      setError: (state, action) => {
        state.status.error = action.payload;
      },
      setData: (state, action) => {
        state.data = action.payload;
      },
      setVenuecrumb: (state, action) => {
        state.venuecrumb = action.payload;
      },
      setOrgcrumb: (state, action) => {
        state.orgcrumb = action.payload;
      },
      reset: (state) => {
        return {
          status: {
            loading: false,
            error: null
          },
          data:[],
          venuecrumb:{
            venueId:null,
            parentPath:[]
          },
          orgcrumb:{
          orgId:null,
          parentPath:[]
          }
        };
      }
    }
  });
  
  export const breadcrumbReducer = breadcrumbSlice.reducer;
  const breadcrumbActions = breadcrumbSlice.actions;
  export default breadcrumbActions;