/***
 *
 * Controller class for user.
 * @file MaterialSymbol.js
 * @description MaterialSymbol component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React from "react";
import PropTypes from 'prop-types';

/**
 * Material Symbols Outlined with optional customisable classes
 * @returns Icon
 */
const MaterialSymbol = (props) => {
  return (
    <span className={"material-symbols-outlined " + props.className}>{props.value}</span>
  );
};

MaterialSymbol.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string
};

MaterialSymbol.defaultProps = {
  className: ""
};

export default MaterialSymbol;
