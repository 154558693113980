import { createSlice } from "@reduxjs/toolkit";

const oneNetworkInitialState = {
  status: {
    loading: false,
    error: null
  },
  data: {
    networkId: null,
    networkName: null,
    updatedAt: null,
    createdAt: null,
    networkTypeId: null,
    orgId: null
  }
};

const oneNetworkSlice = createSlice({
  name: "oneNetwork",
  initialState: {...oneNetworkInitialState},
  reducers: {
    setNetwork: (state, action) => {
      state.data = {...action.payload}
    },
    resetNetwork: (state) => {
      return {...oneNetworkInitialState};
    },
    setLoading: (state, action) => {
      state.status.loading = action.payload;
    },
    setError: (state, action) => {
      state.status.error = action.payload;
    }
  }
});

export const oneNetworkReducer = oneNetworkSlice.reducer;
const oneNetworkActions = oneNetworkSlice.actions;
export default oneNetworkActions;
