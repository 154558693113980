/**
 * URL related constants
 * @file urls.js
 * @description Paths used by services to create complete URLs
 * @author Utkarsh Gupta
 * @since 26 Jun 2022
 */

const BASE_URL = process.env.REACT_APP_BASE_URL;
const VENUE_URL = process.env.REACT_APP_VENUE_URL;
const INFRASTRUCTURE_URL = process.env.REACT_APP_INFRASTRUCTURE_URL;
const NETWORKS_URL = process.env.REACT_APP_NETWORKS_URL;
const TELEMETRY_URL = process.env.REACT_APP_TELEMETRY_URL;
const DEVICE_URL = TELEMETRY_URL;
const FWUPGRADE_URL = process.env.REACT_APP_FWUPGRADE_URL;
const WRE_URL = process.env.REACT_APP_WRE_URL;
const TICKET_URL = process.env.REACT_APP_DIGITAL_BUSINESS_URL;
const MARKETPLACE_URL = process.env.REACT_APP_BILLING_SERVICE;
const UPLOAD_URL = process.env.REACT_APP_UPLOAD_ORG_URL;
export { BASE_URL, VENUE_URL, WRE_URL, INFRASTRUCTURE_URL, NETWORKS_URL, TELEMETRY_URL, DEVICE_URL, FWUPGRADE_URL, TICKET_URL, UPLOAD_URL, MARKETPLACE_URL };
