import PropTypes from "prop-types";
import React from "react";
import "./EditorSidebarItem.scss";
import { useSelector } from "react-redux";
import { EDITORS, LAYOUT_TYPES } from "../constants";
import { UncontrolledTooltip } from "reactstrap";

/**
 * Sidebar Menu Item for the Editor
 * @param {{
 *   Icon: React.Component,
 *   label: string,
 *   value: (1 | 2 | 3 | 4),
 *   mode: (1 | 2 | 3 | 4),
 *   setMode: (_: (1 | 2 | 3 | 4)) => void
 * }} props  
 */

const SVG_DIMENSION = { width: "30px", height: "30px" }
const EditorSidebarItem = (props) => {
  const currentInsertedLayout = useSelector(store => store.wre.currentInsertedLayout)
  const isImageLayout = currentInsertedLayout == LAYOUT_TYPES.IMAGE
  const disable = ((props.value == EDITORS.WallAnnotationsEditor || props.value == EDITORS.AnalysisEditor) && isImageLayout)
  return (
    <>
      <div
        id={`mode${props.label.replaceAll(' ', '')}`}
        className={"EditorSidebarItem d-flex flex-column align-items-center justify-content-center p-25  " + (disable ? "pointer-not-allowed" : '') + (props.mode === props.value ? " editor-sidebar-item-selected" : "")}
        onClick={() => {
          if (!disable) {
            props.setMode(props.value)
          }
        }}
      >
        <props.Icon svgProps={props.value == EDITORS.CableDrop ? { width: '24px', height: '24px' } : SVG_DIMENSION} disabled={props.disabled} color={props.mode === props.value ? "#fff" : "#323232"} />
        {/* <div className={"sidebar-item-label text-center " + (props.mode === props.value ? "text-dark" : "text-dark")}>{props.label}</div> */}
      </div>
      <UncontrolledTooltip placement="bottom" target={`mode${props.label.replaceAll(' ', '')}`} >{props.label}</UncontrolledTooltip>
    </>
  );
};

EditorSidebarItem.propTypes = {
  Icon: PropTypes.instanceOf(React.Component),
  label: PropTypes.string,
  value: PropTypes.oneOf([1, 2, 3, 4])
};
EditorSidebarItem.defaultProps = {};

export default EditorSidebarItem;