/***
 *
 * Controller class for user.
 * @file OrgList.js
 * @description OrgList component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import React, { useEffect, useState } from "react";
import { Row, Col, UncontrolledTooltip, Modal, ModalHeader, ModalFooter, Button, ModalBody, Input } from "reactstrap";
import { ReactComponent as Ticket } from "../../../assets/images/icons/ticket-icon.svg";
import { ReactComponent as Firmware } from "../../../assets/images/icons/Firmware.svg";
import { ReactComponent as MSPIcon } from "../../../assets/images/icons/MSP.svg"
import { ReactComponent as BusIcon } from "../../../assets/images/icons/BUS.svg"
import {EmergencySirenIcon } from "../../../assets/images/icons/Icons";
import { ALARM_OPERATION, FIRMWARE_OPERATION, TICKET_OPERATION } from "../../../utility/tooltip-config-global";
import { LIST_TYPES } from "../../../components/OrgWidget/utils";
import OrgWidget from "../../../components/OrgWidget";
import "./OrgList.scss"
import { MaterialSymbol, VeryFancyLoader } from "../../../components";
import { TICKET_CATEGORY } from "../../Tickets/TicketConstants";
import { useDispatch, useSelector } from "react-redux";
import createRequest, { services } from "../../../services";
import { make_custom_toast } from "../../../helpers/toasts";
import { createErrorContext } from "../../../configs/ErrorContextMaker";
import { CatchedWebError } from "../../../configs";
import { useNavigate } from "react-router-dom";
import { breadcrumbActions } from "../../../redux/slices";

/// Icons for organization types
export const orgtypesIcons = {
  1: <MSPIcon width="100%" height="100%" style={{ height: "22px", width: "22px" }} />,
  2: <MSPIcon width="100%" height="100%" style={{ height: "22px", width: "22px" }} />,
  3: <BusIcon width="100%" height="100%" style={{ height: "22px", width: "22px" }} />,
};

const Operations = (ticket, alarm, firmware) => {

  return <Row className="m-auto">
    {alarm ? <Col sm={3}>
      <EmergencySirenIcon id="ALARM_OPERATION" width="100%" height="100%" style={{ height: "20px", width: "20px" }} />
      <UncontrolledTooltip target="ALARM_OPERATION">{ALARM_OPERATION}</UncontrolledTooltip>
    </Col> : ` `}
    {ticket ? <Col sm={3}>
      <Ticket className="stroke-black" id="TICKET_OPERATION" width="100%" height="100%" style={{ height: "20px", width: "20px" }} />
      <UncontrolledTooltip target="TICKET_OPERATION">{TICKET_OPERATION}</UncontrolledTooltip>
    </Col> : ` `}
    {firmware ? <Col sm={3}>
      <Firmware className="fill-black" id="FIRMWARE_OPERATION" width="100%" height="100%" style={{ height: "20px", width: "20px" }} />
      <UncontrolledTooltip target="FIRMWARE_OPERATION">{FIRMWARE_OPERATION}</UncontrolledTooltip>
    </Col> : ` `}
  </Row>
}

export const OrgDeleteModal = (props) => {

  const {deleteItem, setDeleteItem, onSubmit} = props;

  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const [deleting, setDeleting] = useState(false);
  const [deleteText, setDeleteText] = useState("");

  const navigate = useNavigate();

  const deleteIt = (item) => {
    const { context, run } = createRequest(services.organization.DELETE, [item.orgId]);

    setDeleting(true);
    run().then(() => {
      if(onSubmit)
        onSubmit();
      setDeleteItem(null);
    })
    .catch((err) => {
      let x = new CatchedWebError(err);
      const apiContext = createErrorContext(context, item.inviteId ? 'DELETE INVITE' : 'DELETE ORGANIZATION', TICKET_CATEGORY.ORGANIZATION, err)
      make_custom_toast('error', 'Organization', x.message, true, 'Create Ticket', () => {
        navigate(
          `/organization/${activeOrgId}/support/ticket/createticket/${apiContext.action}/${apiContext.category}`,
          {
            state: {
              ticketContext: apiContext,
            },
          }
        );
      })
    }).finally(() => {
      setDeleting(false);
    })

  }

  return( <Modal isOpen={!!deleteItem} centered toggle={() => { if (!deleting) { setDeleteText(""); setDeleteItem(null); } }} >
          <ModalHeader toggle={() => { if (!deleting) { setDeleteText(""); setDeleteItem(null); } }}>Organization Deletion</ModalHeader>
          {deleteItem &&
            <React.Fragment>
              <ModalBody className="px-4">
                <div className="d-flex flex-column align-items-center">
                  {deleting && <div className="text-center"><VeryFancyLoader /></div>}
                  <div className="text-center">
                    <MaterialSymbol className="text-warning display-1" value="info" />
                  </div>
                  <div className="text-center">You won't be able to revert this. <br /> If you are sure, please enter the organization name <strong>{deleteItem.orgName}</strong> to continue.</div>
                  <div className="w-100 pt-1">
                    <Input className="w-100" valid={deleteText === deleteItem.name || deleteText === deleteItem.orgName} color="danger" value={deleteText} onChange={(e) => { setDeleteText(e.target.value); }} />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="border-0 d-flex justify-content-center pb-3">
                <Button className="mr-2 small-add-button" color="primary" disabled={deleting} onClick={() => { setDeleteText(""); setDeleteItem(null); }}>Cancel</Button>
                <Button color="danger" className="small-add-button" onClick={() => { deleteIt(deleteItem); }} disabled={!(deleteText === deleteItem.orgName) || deleting}>Delete</Button>
              </ModalFooter>
            </React.Fragment>
          }
        </Modal>)

}

const OrgList = () => {
  const SCREEN_ID = "org-dashboard";

  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(breadcrumbActions.setData([
      {
        path: `/organization/${activeOrgId}/sub`,
        text: "Organizations",
        active: true
      }]))
  }, []);

  return (
    <div className="OrgList" data-testid="OrgList">
      <div className="mt-1">  
        <OrgWidget
          SCREEN_ID={SCREEN_ID}
          listType={LIST_TYPES.ORG_LIST}
          filterReq={{
            OrgStateFilter: true,
            LocationFilter: true
          }}
          showOrgOptions
        />
      </div>
    </div>
  );

  // return (
  //   <div className="OrgList" data-testid="OrgList">
  //     {showFilter
  //       ? <FilterSetter
  //         // disabled={!Object.keys(filterSelection).length}
  //         showFilter={showFilter}
  //         elements={() => [
  //           <OperationsFilter filter={filterSelection} setFilter={setFilterSelection} />,
  //           <LocationFilter filter={filterSelection} setFilter={setFilterSelection} />,
  //           <OrgStateFilter filter={filterSelection} setFilter={setFilterSelection} orgTypeId={activeOrgTypeId} />
  //         ]}
  //         handleApplyClick={() => {
  //           setShowFilter(false)
  //           setMainFilter('All')
  //           fetchOrgs(0, "All", filterSelection);
  //           setFilterData(filterSelection)
  //           if (lodash.isEqual(filterSelection, filterInitial))
  //             setFilterActive(false)
  //           else
  //             setFilterActive(true);
  //         }}
  //         handleClearAll={() => {
  //           setShowFilter(false)
  //           setMainFilter('All')
  //           setFilterActive(false);
  //           setFilterSelection(filterInitial)
  //           setFilterData(filterInitial);
  //           fetchOrgs(0, "All", filterInitial);
  //         }}
  //         setShowFilter={setShowFilter} />
  //       : null}
  //       <SidePanel
  //         isOpen = {isColumnSelectorOpen}
  //         setIsOpen = {setIsColumnSelectorOpen}>
  //         <ColSelector cols={cols} 
  //           setCols={setCols} 
  //           setIsOpen = {setIsColumnSelectorOpen}
  //           onApply = {(newColumns) => {
  //             setColumns(SCREEN_ID, newColumns, view);
  //           } }
  //           />
  //       </SidePanel>
  //     {/* <Heading text="Managed Organizations" /> */}

  //     {/* {showFilter && <FilterSetter
  //       elements={() => [
  //         <OrgsStatusFilter filter={filterData} setFilter={setFilterData} />,
  //         <OrgTypeFilter filter={filterData} setFilter={setFilterData} />
  //       ]}
  //       disabled={!Object.keys(filterData).length}
  //       showFilter={showFilter}
  //       setFilter={setFilterData}
  //       filter={filterData}
  //       handleApplyClick={() => {
  //         fetchOrgs(0,filterData);
  //         setFilterActive(true);
  //       }}
  //       handleClearAll={() => {
  //         setFilterData({});
  //         setFilterActive(false);
  //         fetchOrgs(0,{});
  //       }}
  //       setShowFilter={setShowFilter} />} */}


  //     {/** Delete Modal */}
  //     <Modal isOpen={modal} centered toggle={() => { if (!deleting) { setDeleteText(""); setModal(false); } }} >
  //       <ModalHeader toggle={() => { if (!deleting) { setDeleteText(""); setModal(false); } }}>Organization Deletion</ModalHeader>
  //       {deleteItem &&
  //         <React.Fragment>
  //           <ModalBody className="px-4">
  //             <div className="d-flex flex-column align-items-center">
  //               {deleting && <div className="text-center"><VeryFancyLoader /></div>}
  //               <div className="text-center">
  //                 <MaterialSymbol className="text-warning display-1" value="info" />
  //               </div>
  //               <div className="text-center">You won't be able to revert this. <br /> If you are sure, please enter the organization name <strong>{deleteItem.orgName}</strong> to continue.</div>
  //               <div className="w-100 pt-1">
  //                 <Input className="w-100" valid={deleteText === deleteItem.name || deleteText === deleteItem.orgName} color="danger" value={deleteText} onChange={(e) => { setDeleteText(e.target.value); }} />
  //               </div>
  //             </div>
  //           </ModalBody>
  //           <ModalFooter className="border-0 d-flex justify-content-center pb-3">
  //             <Button className="mr-2 small-add-button" color="primary" disabled={deleting} onClick={() => { setDeleteText(""); setModal(false); }}>Cancel</Button>
  //             <Button color="danger" className="small-add-button" onClick={() => { deleteIt(deleteItem); }} disabled={!(deleteText === deleteItem.orgName)}>Delete</Button>
  //           </ModalFooter>
  //         </React.Fragment>
  //       }
  //     </Modal>

  //     {/** Main Body */}
  //     <div className="mt-1">

  //       <Row>
  //         {/** Search */}
  //         <Col xs={12} md={8} className="d-flex">
  //           <div className="searchBox d-flex align-items-center" style={{ width: "350px" }}>
  //             <InputGroup className="input-group-merge">
  //               <Input type="text" placeholder="Search" className="searchInput" style={{ height: '2.4rem' }} onChange={
  //                 (event) => {
  //                   const searchText = event.target.value;
  //                   if (searchText.length === 0 || searchText.length > 1) {
  //                     debounce(() => {
  //                       setSearch(searchText);
  //                       dispatch(suborgsActions.resetSubOrgs());
  //                     });
  //                   }
  //                 }
  //               } />
  //               <SearchIconAddon />
  //             </InputGroup>
  //           </div>
  //           {/* <div className="ml-1">
  //           <FilterButton active={filterActive} 
  //           onClick={() => {
  //             setFilterData(() => {
  //               applyFilterWrapper(filterInitial);
  //               return filterInitial;
  //             })
  //           }} 
  //           />
  //         </div> */}
  //           <div className="ml-1">
  //             <GroupButton>
  //               {<div className={"grp-btn-custom " + (mainFilter == "All" && !filterActive ? "active" : "")} onClick={() => { handleMainFilterClick("All") }} >All ({filterCount?.all ?? 0})</div>}
  //               {<div className={"grp-btn-custom " + (mainFilter == "Issues" && !filterActive ? "active" : "")} onClick={() => { handleMainFilterClick("Issues") }} >Issues ({filterCount?.issue ?? 0})</div>}
  //               {<div className={"grp-btn-custom " + (mainFilter == "Offline" && !filterActive ? "active" : "")} onClick={() => { handleMainFilterClick("Offline") }} >Offline ({filterCount?.offline ?? 0})</div>}
  //             </GroupButton>
  //           </div>
  //           <FilterButton className='ml-50' size={22} active={filterActive} onClick={() => setShowFilter(true)} style={{ padding: '0.36rem' }} />
  //         </Col>

  //         {/** Add/Invite Organization Buttons */}
  //         <Col md={4} xs={12}>
  //           {permissions?.manageOrganization?.create &&
  //             <div className="d-flex justify-content-end text-right">
  //               <React.Fragment>
  //                 <Link to={`/organization/${activeOrgId}/sub/add`} className="rounded btn-primary bg-white small-add-button">Add</Link>
  //                 {/* <Button color="primary" onClick={() => { inviteModalState[1](true); }}>Invite</Button> */}
  //               </React.Fragment>
  //             </div>
  //           }
  //         </Col>
  //       </Row>
  //     </div>

  //     {/** Sub Organizations List */}
  //     {suborgs === null ?
  //       <div className="d-flex justify-content-center"><VeryFancyLoader /></div> :
  //       <div>

  //         {(loading && suborgs.length === 0) ?
  //           <div className="text-center p-3" style={{ height: "80vh" }}>
  //             <VeryFancyLoader />
  //           </div>
  //           :
  //           <div>

  //             {suborgs.length > 0 ? (
  //               <InfiniteScroll
  //                 className="mt-1"
  //                 dataLength={suborgs.length}
  //                 next={() => { fetchOrgs(suborgs.length, mainFilter, filterData) }}
  //                 hasMore={hasMore}
  //                 loader={<div>{SCROLL_MORE_TEXT}</div>}
  //                 endMessage={<div>Showing {suborgs.length} result(s).</div>}
  //                 scrollableTarget="org-dash-bod"
  //                 scrollThreshold={0.7}
  //               >
  //                 <div id="org-dash-bod">
  //                   <Table className="table-view bg-white fixed-header">
  //                     <thead>
  //                       <tr>
  //                         <th style={{ width: "500px" }}><div className="pl-3"><ColumnHeader header="Name" attribute={"orgName"} setter={setSort} sort={sort} /></div></th>
  //                         {cols.Operations && <th style={{ width: "150px" }}><div style={{ marginTop: '1.5px' }}>Operations</div></th>}
  //                         {cols.Location && <th style={{ width: "100px" }}><ColumnHeader header="Location" attribute={"location"} setter={setSort} sort={sort} /></th>}
  //                         {cols.Infrastructure && <th className='pr-0' style={{ width: "100px" }}><ColumnHeader right header="Infrastructure" attribute={"totalInfra"} setter={setSort} sort={sort} /></th>}
  //                         {cols.Venue && <th className="pr-0" style={{ width: "100px" }}><ColumnHeader right header="Venue" attribute={"totalVenue"} setter={setSort} sort={sort} /></th>}
  //                         {cols.State && <th style={{ width: "100px" }}><ColumnHeader header="State" attribute={"state"} setter={setSort} sort={sort} /></th>}
  //                         {cols["Live Sites"] && <th className="text-right" style={{ width: "100px" }}><div style={{ marginTop: '1.5px' }}>Live Sites</div></th>}
  //                         {cols["Proposed Amt"] && <th className="text-right" style={{ width: "100px" }}><div style={{ marginTop: '1.5px' }}>Proposed Amt</div></th>}
  //                         {cols["Proposed Subscription"] && <th className="text-right" style={{ width: "100px" }}><div style={{ marginTop: '1.5px' }}>Proposed Subs</div></th>}
  //                         {<th style={{ width: "90px" }} className="text-right">
  //                           <span 
  //                             className="material-symbols-outlined cursor-pointer" 
  //                             onClick={() => 
  //                               setIsColumnSelectorOpen(prevState => !prevState)}>
  //                               settings
  //                           </span>
  //                         </th>}
  //                       </tr>
  //                     </thead>
  //                     {suborgs.map((org, index) => {
  //                       return (
  //                         <ExpOrgRow width="500px" key={org.orgId} expand={!org.leafNode} orgId={org.orgId} cols={cols} sort={sort} permissions={permissions} filter={mainFilter}
  //                           filterData={filterData} search={search} setDeleteItem={setDeleteItem} setModal={setModal} parentLast={[true]}>
  //                           <ExpCell width="500px" parentLast={[true]}>
  //                             <div className="d-flex align-items-center py-50" onClick={() => {
  //                               // navigate(`/organization/${venue.orgId}/venues/${venue.venueId}`)
  //                             }}>
  //                               {(view == NETWORK_VIEW || view == DEPLOYMENT_VIEW) && <div className="d-flex align-items-start">
  //                                 <img src={statusIcons(org.onlineInfra, org.totalInfra)} />
  //                               </div>}
  //                               <div className={"d-flex align-items-center " + ((view == NETWORK_VIEW || view == DEPLOYMENT_VIEW) ? "ml-1" : "")}>
  //                                 <div>
  //                                   <div className="d-flex align-items-center">
  //                                     {orgtypesIcons[org.orgTypeId]}
  //                                     <span className={"ml-1 " + ((permissions?.manageFirmware?.create || switchOrgs.includes(org.orgId) || hierarchy) ? "table-link" : "")} onClick={() => {
  //                                       if (permissions?.manageFirmware?.create || switchOrgs.includes(org.orgId) || hierarchy)
  //                                         navigate(`/organization/${org.orgId}`);
  //                                     }}>{org.orgName}</span>
  //                                   </div>
  //                                   {(view == NETWORK_VIEW || view == DEPLOYMENT_VIEW) && <div className="font-weight-bold" style={{ marginTop: "0.5rem", minHeight: "0.5rem", fontSize: "0.75rem" }}>
  //                                     {org?.ticketCount > 0 ? `${org?.ticketCount} Ticket${org?.ticketCount > 1 ? "s" : ""}` : ""}
  //                                     {(org?.ticketCount > 0 && (org?.offlineAP > 0 || org?.offlineSwitch > 0)) ? ", " : ""}
  //                                     {org?.offlineAP > 0 ? `${org?.offlineAP} AP${org?.offlineAP > 1 ? "s" : ""} Offline` : ""}
  //                                     {(org?.offlineAP > 0 && org?.offlineSwitch > 0) ? ", " : ""}
  //                                     {org?.offlineSwitch > 0 ? `${org?.offlineSwitch} Switch${org?.offlineSwitch > 1 ? "es" : ""} Offline` : ""}
  //                                   </div>}
  //                                   {view == BUSINESS_VIEW && <div className="font-weight-bold" style={{ marginTop: "0.5rem", minHeight: "0.5rem", fontSize: "0.75rem" }}>
  //                                     {org.notes}
  //                                   </div>}
  //                                 </div>
  //                               </div>
  //                             </div>
  //                           </ExpCell>
  //                           {cols.Operations && <ExpCell width="150px">{Operations(org.ticketStatus, org.alarmStatus, org.firmwareStatus)}</ExpCell>}
  //                           {cols.Location && <ExpCell width="100px"><div className="d-flex align-items-center"><div className="flag-icon mr-50" style={{ backgroundImage: `url( https://flagcdn.com/${org?.location?.toLowerCase()}.svg )` }}>&nbsp;</div>{org?.location}</div></ExpCell>}
  //                           {cols.Infrastructure && <ExpCell className="d-flex justify-content-end" width="100px">
  //                             <div className="d-flex">
  //                               <span>
  //                                 <span className={`${((org?.onlineInfra??0)>0 && (org?.totalInfra??0)>0)?'text-success font-weight--600 cursor-pointer':''} font-size--larger`}
  //                                   onClick={() => {
  //                                     if(((org?.onlineInfra??0)>0 && (org?.totalInfra??0)>0)) {
  //                                       navigate(
  //                                         `/organization/${org.orgId}/infra`,
  //                                         {
  //                                           state: {
  //                                             filter: {
  //                                               status: ["ONLINE"]
  //                                             },
  //                                           },
  //                                         }
  //                                       )
  //                                     }
  //                                   }}
  //                                 >
  //                                   {org?.onlineInfra != null?org?.onlineInfra:'-'}
  //                                 </span>
  //                                 &nbsp;/&nbsp;
  //                                 <span 
  //                                   className={`${(org?.totalInfra??0)>0?'table-link':''}`}
  //                                   onClick={() => {
  //                                     if((org?.totalInfra??0)<=0) return;
  //                                     // navigate(`/organization/${activeOrgId}/infra?filter=All`, {state: {filter: {orgs: [org]}}})
  //                                     navigate(`/organization/${org.orgId}/infra?filter=All`)
  //                                   }}
  //                                 >
  //                                   {org?.totalInfra!=null?org?.totalInfra:'-'}
  //                                 </span>
  //                                 </span>
  //                               <span style={{ width: '1.1rem' }} />
  //                             </div>
  //                           </ExpCell>}
  //                           {cols.Venue && <ExpCell className="justify-content-end" width="100px">
  //                             <div className="d-flex" >
  //                             <span> 
  //                               <span className={`${((org?.onlineVenue??0)>0 && (org?.totalVenue??0)>0)?'text-success font-weight--600':''} font-size--larger`}>
  //                                 {org.onlineVenue!=null?org.onlineVenue:"-"}
  //                               </span>
  //                               &nbsp;/&nbsp;
  //                               <span
  //                                 className={`${(org?.totalVenue??0)>0?'table-link':''}`}
  //                                 onClick={() => {
  //                                   if((org.totalVenue??0)<=0) return;
  //                                   // navigate(`/organization/${activeOrgId}/venues`, {state: {filter: {orgs: [org]}}})
  //                                   navigate(`/organization/${org.orgId}/venues`)
  //                                 }}
  //                               >
  //                                 {org.totalVenue!=null?org.totalVenue:"-"}
  //                               </span>
  //                             </span>
  //                               <span style={{ width: '1.1rem' }} />
  //                             </div></ExpCell>}
  //                           {cols.State && <ExpCell width="100px"><span style={{ paddingLeft: '0.35rem' }}>{activeOrgTypeId == BUSINESS ? BUS_STATE_MAPPING[org.state] : MSP_STATE_MAPPING[org.state]}</span></ExpCell>}
  //                           {
  //                             cols["Live Sites"] && <ExpCell className="justify-content-end" width="100px"><span className={org.liveSites>0?"table-link cursor-pointer":""} onClick={() => {
  //                               if(org.liveSites>0)
  //                                 navigate(`/organization/${org.orgId}/venues`, { state: { filter: { state: [VENUE_STATE.LIVE] } } });
  //                             }}
  //                               style={{ paddingRight: '0.6rem' }}>{org.liveSites}</span></ExpCell>
  //                           }
  //                           {cols["Proposed Amt"] && <ExpCell className="justify-content-end" width="150px"><span >${org.quotedAmt}</span></ExpCell>}
  //                           {cols["Proposed Subscription"] && <ExpCell className="justify-content-end" width="170px"><span >{org.quotedSubscription}</span></ExpCell>}
  //                           < ExpCell width="90px" className="d-flex justify-content-end" >
  //                             {(permissions?.manageFirmware?.create || switchOrgs.includes(org.orgId) || hierarchy) &&
  //                               <SwitcherIcon className="switch-icon" onClick={() => {
  //                                 dispatch(activeOrgActions.resetActiveOrg());
  //                                 dispatch(identitiesActions.resetIdentities());
  //                                 dispatch(rbacActions.reset());
  //                                 dispatch(infraListActions.reset());
  //                                 dispatch(deviceActions.reset());
  //                                 dispatch(suborgsActions.resetSubOrgs());
  //                                 REDUX_WORKER.getActiveOrg(org.orgId);
  //                                 navigate(`/organization/${org.orgId}`);
  //                               }} />
  //                             }
  //                             <UncontrolledButtonDropdown className="me-2 "
  //                               disabled={!(switchOrgs.includes(org.orgId) ||
  //                                 hierarchy ||
  //                                 permissions?.manageFirmware?.create)}
  //                             >
  //                               <DropdownToggle color="white" className="p-0">
  //                                 <span className={"material-symbols-outlined " + ((switchOrgs.includes(org.orgId) ||
  //                                   hierarchy ||
  //                                   permissions?.manageFirmware?.create) ? "text-primary" : "text-secondary")}>
  //                                   more_vert
  //                                 </span>
  //                               </DropdownToggle>
  //                               <DropdownMenu className="dropdown-menu-right">
  //                                 {
  //                                   (permissions?.manageFirmware?.create || permissions?.manageOrganization?.delete || hierarchy) &&
  //                                   <DropdownItem tag="span" onClick={() => { setDeleteItem(org); setModal(true); }}>
  //                                     Delete
  //                                   </DropdownItem>
  //                                 }
  //                               </DropdownMenu >
  //                             </UncontrolledButtonDropdown >
  //                           </ExpCell >
  //                         </ExpOrgRow >
  //                       );
  //                     })}
  //                   </Table >
  //                 </div>
  //               </InfiniteScroll >
  //             ) : (
  //               (mainFilter == "All" && search == "") ?
  //                 <Container className='mt-1' style={{ height: '78vh', backgroundColor: 'white' }}>
  //                   <h4 className="text-center pt-4">{`No Business under ${activeOrgName} `}. {(permissions?.manageOrganization?.create ? <span className="text-primary cursor-pointer" onClick={() => navigate(`add`)}>Click to Add</span> : <></>)}</h4>
  //                 </Container> :
  //                 <Container className='mt-1' style={{ height: '78vh', backgroundColor: 'white' }}>
  //                   <h4 className="text-secondary text-center pt-4">Organizations do not exist</h4>
  //                 </Container>
  //             )}
  //           </div >
  //         }
  //       </div >
  //     }
  //     <InviteOrganization search={search} activeOrgId={activeOrgId} setHasMore={setHasMore} modalState={inviteModalState} setInviteSuccess={setInviteSuccess} setDeleteSuccess={setDeleteSuccess} />
  //   </div >
  // );
};

OrgList.propTypes = {};

OrgList.defaultProps = {};

export default OrgList;
