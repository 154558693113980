/***
 *
 * Controller class for user.
 * @file ColumnHeader.js
 * @description ColumnHeader component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import { React, useEffect, useRef, useState } from "react";
import { ReactComponent as Sort } from "../../assets/images/icons/SortIcon.svg";
import { ReactComponent as ArrowUp } from "../../assets/images/icons/ArrowUp.svg";
import { ReactComponent as ArrowDown } from "../../assets/images/icons/ArrowDown.svg";
// import { Button, Popover, PopoverBody } from "reactstrap";
import "./columnheader.scss";

const ColumnHeader = (props) => {
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterButtonShow, setFilterButtonShow] = useState(false);
  const [sortShow, setSortShow] = useState(false);
  const [hovering, setHovering] = useState(false);
  const [filterId, setFilterId] = useState(props.header.replaceAll(' ', '') + 'filter-dropdown');
  const popoverRef = useRef(null);

  useEffect(() => {
    setFilterId(props.header.replaceAll(' ', '') + 'filter-dropdown');
  }, [props.header])

  useEffect(() => {
    const outClick = (e) => {
      if (!document?.getElementsByClassName('popover')[0]?.contains(e.target)) {
        setFilterOpen(false);
      }
    }
    if (!!props?.filter)
      window.addEventListener('click', outClick);
    return () => {
      if (!!props?.filter)
        window.removeEventListener('click', outClick);
    }
  }, [])

  useEffect(() => {
    setFilterButtonShow((props?.filter?.isFilterActive || hovering || filterOpen))
  }, [props?.filter?.isFilterActive, hovering, filterOpen])

  useEffect(() => {
    setSortShow(props?.sort?.orderBy === props?.attribute || hovering || filterOpen);
  }, [props?.sort?.orderBy, props?.attribute, hovering, filterOpen])



  return (
    <div className="ColumnHeader" data-testid="ColumnHeader" style={{ width: props.width, paddingTop: !props.attribute ? "0.16rem" : "" }}
      onMouseEnter={() => {
        setHovering(true);
      }}
      onMouseLeave={() => {
        setHovering(false);
      }}
    >
      <div className={"d-flex align-items-center " + (props.right ? "justify-content-end" : "") + (props.centerText ? "justify-content-center left-correct-padding" : "")}>
        <div className={"position-relative mr-50 " + ((!props.filter && !props.attribute) ? '' : '')}>
          {props.header}
        </div>
        {
          !!props.attribute &&
          <div className={'cursor-pointer ' + (sortShow ? '' : 'invisible')} onClick={() => {
            if (!(props.disabled))
              props.setter((prevState) => {
                return {
                  order: (props.sort.orderBy !== props.attribute) ? "ASC" : (prevState.order === "ASC" ? "DESC" : "ASC"),
                  orderBy: props.attribute
                }
              })
          }}>
            {(props.sort.orderBy !== props.attribute)
              ? <Sort />
              : props.sort.order == null
                ? <Sort />
                : props.sort.order === "ASC"
                  ? <ArrowUp />
                  : <ArrowDown />
            }
          </div>
        }
        {/* {!!props.filter &&
        <div className={`${props.header.replaceAll(' ','')} ${filterButtonShow?'':'invisible'}`} id={filterId} ref={popoverRef}>
          <span className={`material-symbols-outlined filter-icon ${props?.filter?.isFilterActive?' text-primary':'bg-white'} cursor-pointer d-flex align-items-center text-center`}
            onClick={() => setFilterOpen((prevState)  => !prevState)}
          >
            arrow_drop_down
          </span>
          <Popover boundariesElement='window' placement="bottom" 
            isOpen={filterOpen} target={filterId}
            toggle={() => setFilterOpen((prevState) => !prevState)}
          >
            <PopoverBody className="p-0">
              <div className="px-1 pt-1">
                {props.filter.component}
              </div>
              <hr />
              <div className='filter-buttons mt-1 px-1 pb-1'>
                <Button.Ripple outline color='primary' size='sm' 
                  onClick={() => {
                    setFilterOpen(false);
                    setHovering(false);
                  }}
                >
                  Cancel
                </Button.Ripple>
                <Button.Ripple outline color='primary' size='sm' 
                  onClick={() => {
                    props.filter.onFilterClear();
                    setFilterOpen(false);
                    setHovering(false);
                    }}
                >
                  Clear
                </Button.Ripple>
                <Button.Ripple color='primary' size='sm' 
                  onClick={() => {
                    props.filter.onFilterApply();
                    setFilterOpen(false);
                    setHovering(false);
                  }}
                >
                  <span className="d-flex align-items-center">
                    <span class="material-symbols-outlined done-icon">
                      done
                    </span>
                    Apply
                  </span>
                </Button.Ripple>
              </div>
            </PopoverBody>
          </Popover>
        </div>} */}
      </div>
    </div>
  );
};

ColumnHeader.propTypes = {};

ColumnHeader.defaultProps = {};

export default ColumnHeader;
