/***
 *
 * Controller class for user.
 * @file SortingHeader.js
 * @description SortingHeader component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import { React, useEffect, useState } from "react";
import { ReactComponent as Sort } from "../../assets/images/icons/SortIcon.svg";
import { Button, Popover, PopoverBody } from "reactstrap";
import "./sortingheader.scss";

const SortingHeader = (props) => {
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterButtonShow, setFilterButtonShow] = useState(false);
  const [sortShow, setSortShow] = useState(false);
  const [filterId, setFilterId] = useState(props.header.replaceAll(' ', '') + 'filter-dropdown');
  useEffect(() => {
    setFilterId(props.header.replaceAll(' ', '') + 'filter-dropdown');
  }, [props.header])

  useEffect(() => {
    const outClick = (e) => {
      let elements = document?.getElementsByClassName(props.header.replaceAll(' ', ''));
      if (elements && Array.isArray(elements) && elements.length &&
        !elements[0]?.contains(e.target)) {
        setFilterOpen(false);
      }
    }
    window.addEventListener('click', outClick);
    return () => {
      window.removeEventListener('click', outClick);
    }
  }, [])
  return (
    <div className="SortingHeader" data-testid="SortingHeader"
      onMouseEnter={() => {
        setSortShow(true);
        if (props.filter) setFilterButtonShow(true)
      }}
      onMouseLeave={() => {
        setSortShow(false);
        if (props.filter && !filterOpen)
          setFilterButtonShow(false)
      }}
    >
      <div className="d-flex align-items-center">
        <div className="position-relative mx-50">
          {props.header}
          <span className={"ml-50 " + (sortShow?'':'invisible')}>
            <Sort className="cursor-pointer" onClick={()=>{
                if(!(props.disabled))
                  props.setter((prevState)=>{
                    return {
                      order : (props.sort.orderBy !== props.attribute)?"ASC":(prevState.order==="ASC"?"DESC":"ASC"),
                      orderBy : props.attribute
                    }
                  })
            }}/>
          </span>
          {props?.filter?.isFilterActive && <div className="filter-active"></div>}
        </div>
        {props.filter &&
          <div className={`${props.header.replaceAll(' ', '')} ${filterButtonShow ? '' : 'invisible'}`} id={filterId}

          >
            <span className="material-symbols-outlined filter-icon cursor-pointer d-flex align-items-center text-center"
              // onMouseEnter={() => setFilterOpen(true)}
              // onMouseLeave={() => setFilterOpen(false)}
              onClick={() => setFilterOpen((prevState) => !prevState)}
            >
              arrow_drop_down
            </span>
            <Popover placement="down" isOpen={filterOpen}
              target={filterId} className="poppy"
            // toggle={this.toggle}
            >
              {/* <PopoverHeader>Popover Title</PopoverHeader> */}
              <PopoverBody>
                <div>
                  {props.filter.component}
                </div>
                <div className='filter-buttons mt-1'>
                  <Button.Ripple outline color='primary' size='sm'
                    onClick={() => {
                      setFilterOpen(false);
                      setFilterButtonShow(false);
                      setSortShow(false);
                    }}
                  >
                    Cancel
                  </Button.Ripple>
                  <Button.Ripple outline color='primary' size='sm'
                    onClick={() => {
                      props.filter.onFilterClear();
                      setFilterOpen(false);
                      setFilterButtonShow(false);
                      setSortShow(false);
                    }}
                  >
                    Clear
                  </Button.Ripple>
                  <Button.Ripple color='primary' size='sm'
                    onClick={() => {
                      props.filter.onFilterApply();
                      setFilterOpen(false);
                      setFilterButtonShow(false);
                      setSortShow(false);
                    }}
                  >
                    <span className="d-flex align-items-center">
                      <span class="material-symbols-outlined done-icon">
                        done
                      </span>
                      Apply
                    </span>
                  </Button.Ripple>
                </div>
              </PopoverBody>
            </Popover>
          </div>}
      </div>
    </div>
  );
};

SortingHeader.propTypes = {};

SortingHeader.defaultProps = {};

export default SortingHeader;
