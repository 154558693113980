/***
 *
 * Controller class for user.
 * @file FirmwareProgressGraph.js
 * @description FirmwareProgressGraph component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React, { useState } from "react";
// import PropTypes from 'prop-types';
import "./FirmwareProgressGraph.scss";
import Skeleton from "../../pages/Dashboard/Graphs/Skeleton";
import { Col, Container, Progress, Row } from "reactstrap";
import { useEffect } from "react";
import createRequest, { services } from "../../services";
import { CatchedWebError } from "../../configs";
import { firmwareChartColors, firmwareChartKeys, firmwareChartLabels } from "../../utility/constants";
import { useSelector } from "react-redux";
let FWProgressBarInterval;
const FwPROGRESS_UPDATE_INTERVAL = 180000; // ms = 3 mins

const FirmwareProgressGraph = (props) => {

  const socketMessage = useSelector(store => store.identity.meta.updateDashboardMessage);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const getData = (run) => {
    setLoading(true);
    run()
    .then((response) => {
      setData(response.data[0]);
    })
    .catch((err) => {
      setError((new CatchedWebError(err)).message);
    })
    .finally(() => {
      setLoading(false);
    })
  }

  const percentageCalc = (index) => {
    let key = firmwareChartKeys[index]
    return ((data[key] / data.totalInfras) * 100).toFixed(0) > 0 ? (
      `${((data[key] / data.totalInfras) * 100).toFixed(0)}%`
    ) : '0%'
  }

  useEffect(() => {
  
    return () => {
      clearInterval(FWProgressBarInterval)
    }
  }, [])
  
  

  useEffect(() => {
    const { run } = createRequest(services.fwupgrade.GET_FWUPGRADE_COUNT_BY_ORG, [props.orgId, true]);
    if (!!socketMessage) {
      clearInterval(FWProgressBarInterval)
      getData(run)
      FWProgressBarInterval = setInterval(() => getData(run),FwPROGRESS_UPDATE_INTERVAL);
    }
  }, [socketMessage])

  useEffect(() => {
    const { run } = createRequest(services.fwupgrade.GET_FWUPGRADE_COUNT_BY_ORG, [props.orgId, true]);
    clearInterval(FWProgressBarInterval);
    getData(run);
    FWProgressBarInterval = setInterval(() => getData(run),FwPROGRESS_UPDATE_INTERVAL);
  }, [props.orgId])

  return (
    <div className="FirmwareProgressGraph h-100" data-testid="FirmwareProgressGraph">
      <div className="rounded border  bg-white p-50" style={{ height: "97%" }}>
        <div style={{
          fontWeight: "500",
          fontSize: "0.875rem",
          marginRight: "7px",
        }}>Firmware Software Status</div>
        {loading ?
          <div className="d-flex align-items-center h-100">
            <Container className="text-center">
              <Skeleton width="90%" height="30px" />
              <div>
                <Skeleton width="30%" height="10px" />
                <Skeleton width="30%" height="10px" />
              </div>
            </Container>
          </div>
          : <div className="d-flex flex-column justify-content-between mt-1">
              <div>
                <div className="d-flex" style={{ width: "100%", height: "20px" }}>
                  {/* {firmwareChartKeys.map((it, index) => {
                    return <div style={{ width: `${(data[it] / data.totalInfras) * 100}%`, textAlign: "center", fontWeight: "600" }}>
                      {((data[it] / data.totalInfras) * 100).toFixed(0) > 0
                        && `${((data[it] / data.totalInfras) * 100).toFixed(0)}%`}
                    </div>
                  })} */}
                </div>
                <Progress className="mt-1" style={{ height: "12px" }} multi>
                  {firmwareChartKeys.map((it, index) => {
                    return (<Progress
                      style={{
                        backgroundColor: firmwareChartColors[it],
                        height: "12px"
                      }}
                      key={index} bar value={(data[it] / data.totalInfras) * 100}>
                    </Progress>)
                  }
                  )}
                </Progress>
              </div>
              <div className="legends mt-2 mb-50 text-center">
                {firmwareChartLabels.map((it, index) => {
                  return (
                    <div key={index} xs={4}>
                      <div className="d-flex align-items-center">
                        <div style={{ height: "5px", width: "18px", marginRight: "0.5rem", backgroundColor: firmwareChartColors[firmwareChartKeys[index]] }}>&nbsp;</div>
                        <div style={{ fontSize: "0.75rem", whiteSpace: 'nowrap' }}>
                          {`${it} (${percentageCalc(index)})`}
                        </div>
                      </div>
                    </div>)
                })}
              </div>
          </div>}
      </div>
    </div>
  );
};

FirmwareProgressGraph.propTypes = {};

FirmwareProgressGraph.defaultProps = {};

export default FirmwareProgressGraph;
