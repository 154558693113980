/***
 *
 * Controller class for user.
 * @file FilterList.js
 * @description FilterList component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React from "react";
// import PropTypes from 'prop-types';
import "./FilterList.scss";

const FilterList = (props) => {
  const elements = props.elements();
  
  
  return (
    <div className="FilterList" data-testid="FilterList">
      {props.showFilter && elements.map((ele,key)=> {
        if(!ele.props.hide)
          return <div key={key}>{ele}</div>
      })}
    </div>
  );
};

FilterList.propTypes = {};

FilterList.defaultProps = {};

export default FilterList;
