/***
 *
 * Controller class for user.
 * @file OrganizationsList.js
 * @description OrganizationsList component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React, {useRef,useState,useEffect} from "react";
import "./OrganizationsList.scss";
import { useDispatch, useSelector } from 'react-redux';
import { Input, Row, Col, Spinner, InputGroup } from 'reactstrap';
import { SearchIconAddon } from '..';
import createRequest, { services } from '../../services';
import { orgtypesIcons } from '../../pages/Organization/OrgList';
import { useNavigate } from 'react-router-dom';
import { activeOrgActions, deviceActions, identitiesActions, infraListActions, rbacActions, suborgsActions } from '../../redux/slices';
import { VeryFancyLoader } from '..';
import InfiniteScroll from "react-infinite-scroll-component";
import SelectionDisplayPills from "../SelectionDisplayPills";
import { CatchedWebError } from "../../configs";
import REDUX_WORKER from "../../redux/workers";

let timer;

const debounce = (callback)=>{
  clearTimeout(timer);
  timer = setTimeout(callback, 800);
}

const ExpandableItem = (props) => {
  const activeOrgId = useSelector(state => state.activeOrg.data.orgId);
  const [expanded, setExpanded] = useState(false);
  const [children, setChildren] = useState(null);
  const [error, setError] = useState(null);
  const [areChildrenLoading, setAreChildrenLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (expanded) {
      setAreChildrenLoading(true);
      const { controller, run } = createRequest(services.organization.GET_CHILDREN, [props.item.orgId,,,,,,,{status: 'Accepted'}]);

      run()
        .then(response => {
          setError(null);
          setChildren(response.data);
        })
        .catch(err => {
          setError((new CatchedWebError(err)).message);
        })
        .finally(() => {
          setAreChildrenLoading(false);
        })

      return () => {
        controller.abort();
      }
    }
  }, [expanded, props.item.orgId]);

  return (
    <li className="childOrg">
      <div>
        <span className="alignVert">
          {!props.item.leafNode && <span className="d-inline-block cursor-pointer" onClick={() => { setExpanded(state => !state); }} title="See children">
            <span className="material-symbols-outlined">{expanded && !areChildrenLoading && children !== null ? "expand_more" : "chevron_right" }</span>
          </span>
          }
          <span className="orgName d-inline-block" title="Switch to this organization" onClick={() => {
            dispatch(activeOrgActions.resetActiveOrg());
            dispatch(activeOrgActions.resetActiveOrgLogo());
            dispatch(identitiesActions.resetIdentities());
            dispatch(infraListActions.reset());
            dispatch(deviceActions.reset());
            dispatch(suborgsActions.resetSubOrgs());
            dispatch(rbacActions.reset());
            REDUX_WORKER.getActiveOrg(props.item.orgId);
            navigate(`/organization/${props.item.orgId}`);
            props.setOpen(false);
            props.setSearch("");
          }}>
            <span className="d-flex align-items-center">
              <span>{orgtypesIcons[props.item.orgTypeId]}</span> &nbsp;
              <span>{props.item.orgDisplayName} </span> &nbsp; 
              {activeOrgId === props.item.orgId && <span className="material-symbols-outlined text-success">check_circle</span>}
              {expanded && areChildrenLoading && <small className="text-secondary"><Spinner size="sm" /></small>}
            </span>
          </span>
        </span>
        {expanded && 
          <React.Fragment>
            {error && <span className="pl-1">ERROR: {error}</span>}
            {(
              !props.item.leafNode ? 
                <ExpandableList items={children} setOpen={props.setOpen} setSearch={props.setSearch}/> :
                <span className="pl-3">No Sub Organization</span>
            )}
          </React.Fragment>
        }      
      </div>
    </li>
  );
}

const ExpandableItemMultiSelect = (props) => {
  const [expanded, setExpanded] = useState(false);
  const [children, setChildren] = useState(null);
  const [error, setError] = useState(null);
  const [areChildrenLoading, setAreChildrenLoading] = useState(false);

  

  useEffect(() => {
    if (expanded) {
      setAreChildrenLoading(true);
      const { controller, run } = createRequest(services.organization.GET_CHILDREN, [props.item.orgId,,,,,,,{status: 'Accepted'}]);

      run()
        .then(response => {
          setError(null);
          setChildren(response.data);
        })
        .catch(err => {
          setError((new CatchedWebError(err)).message);
        })
        .finally(() => {
          setAreChildrenLoading(false);
        })

      return () => {
        controller.abort();
      }
    }
  }, [expanded, props.item.orgId]);

  const orgInArray = (org) => {
    if(Array.isArray(props.selectedOrgs)) {
      let i;
      for(i=0;i<props.selectedOrgs.length;i++) {
        if(parseInt(props.selectedOrgs[i].orgId)===parseInt(org.orgId)) {
          return true;
        }
      }
      return false;
    }
  }
  const [isChecked,setIsChecked] = useState(orgInArray(props?.item)?true:null);
  useEffect(() => {
    if(props?.item) {
      if(orgInArray(props?.item)) setIsChecked(true);
      else setIsChecked(false);
    }
  }, [props])

  useEffect(() => {
    //console.log("ischecked: ",isChecked);
    if(!!isChecked) {
      let tempSelectedOrgs = [...props.selectedOrgs];
      tempSelectedOrgs.push({orgId:props.item?.orgId,orgDisplayName:props.item?.orgDisplayName});
      props.setSelectedOrgs(tempSelectedOrgs);
    }
    else 
      props.setSelectedOrgs(props.selectedOrgs.filter(org => parseInt(org.orgId) !== parseInt(props.item?.orgId)));
  }, [isChecked])
  
  
  const handleChange = (event) => {
    setIsChecked(!isChecked);
  }

  return (
    <li className="childOrg">
      <div>
        <span className="alignVert">
          {!props.item.leafNode && <span className="d-inline-block cursor-pointer" onClick={() => { setExpanded(state => !state); }} title="See children">
            <span className="material-symbols-outlined">{expanded && !areChildrenLoading && children !== null ? "expand_more" : "chevron_right" }</span>
          </span>
          }
          <span className="w-100 orgName d-inline-block">
            <span className="d-flex align-items-center">
              <span>{orgtypesIcons[props.item.orgTypeId]}</span> &nbsp;
              <div className='w-100 d-flex justify-content-between pr-1'>
                <span>{props.item.orgDisplayName} </span>
                  <input
                    className="cursor-pointer"
                    type="checkbox"
                    checked={isChecked}
                    onChange={(event)=>{
                      handleChange(event);
                    }}
                  />
              </div>
              {expanded && areChildrenLoading && <small className="text-secondary"><Spinner size="sm" /></small>}
            </span>
          </span>
        </span>
        {expanded && 
          <React.Fragment>
            {error && <span className="pl-1">ERROR: {error}</span>}
            {(
              !props.item.leafNode ? 
                <ExpandableList 
                multiSelect={true} 
                items={children} 
                setOpen={props.setOpen} 
                setSearch={props.setSearch}
                selectedOrgs={props.selectedOrgs}
                setSelectedOrgs={props.setSelectedOrgs}/>:
                <span className="pl-3">No Sub Organization</span>
            )}
          </React.Fragment>
        }      
      </div>
    </li>
  );
}

const ExpandableList = (props) => {
  if(props.multiSelect) {
    return (
      <ul className="expandableList" style={{ paddingLeft: props.isRoot ? "0em" : "1em" }}>
        {props.items?.map(item => 
          <ExpandableItemMultiSelect 
            key={item.orgId} 
            item={item} 
            setOpen={props.setOpen} 
            setSearch={props.setSearch}
            selectedOrgs={props.selectedOrgs}
            setSelectedOrgs={props.setSelectedOrgs}/>)}
      </ul>
    );
  }
  else {
    return (
      <ul className="expandableList" style={{ paddingLeft: props.isRoot ? "0em" : "1em" }}>
        {props.items?.map(item => <ExpandableItem key={item.orgId} item={item} setOpen={props.setOpen} setSearch={props.setSearch}/>)}
      </ul>
    );
  }
};

ExpandableList.propTypes = {};
ExpandableList.defaultProps = {};

const OrganizationsList = ({search,setSearch,...props}) => {
  const orgId = useSelector(state => state.org.data.orgId);
  const [directChildren, setDirectChildren] = useState(null);
  const [directChildrenLoading, setDirectChildrenLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  

  useEffect(() => {
    if (orgId && props.isOpen) {
      setDirectChildrenLoading(true);
      const { controller, run } = createRequest(services.organization.GET_SWITCH, [0,20,encodeURIComponent(search)]);
      run()
        .then(response => {
          setError(null);
          if(response.data.length < 20)
            setHasMore(false);
          setDirectChildren(response.data);
        })
        .catch(err => {
          setError((new CatchedWebError(err)).message);
        })
        .finally(() => {
          setDirectChildrenLoading(false);
        })
      
      return () => {
        controller.abort();
        setHasMore(true)
      }
    }
  }, [orgId,props.isOpen,search]);

  const inputElement = useRef(null);

  useEffect(() => {
    if (inputElement && inputElement.current) {
      inputElement.current.focus();
    } 
  }, [inputElement]);

  return (
    <div className="OrganizationsList pt-1" data-testid="OrganizationsList">
      <InputGroup>
        <Input type="text" placeholder="Search" autoFocus={true} ref={inputElement} onChange={(e)=>{
              if(e.target.value.length>1 || e.target.value.length === 0 )
                debounce(()=>setSearch(e.target.value));
            }} />
        <SearchIconAddon />
      </InputGroup>
      {props.multiSelect && 
      <div className='mt-1'>
        <SelectionDisplayPills
        selected={props.selectedOrgs} 
        selectedSetter={props.setSelectedOrgs}/>
      </div>}
      <div id="scrollableDivTree" className="mt-2 manageOverflow">
        {directChildrenLoading && <div className="d-flex justify-content-center"><VeryFancyLoader /></div>}
        {!directChildrenLoading && directChildren !== null && (error ? "ERROR" : 
          (directChildren.length > 0 ?
            <div>
              <InfiniteScroll 
                dataLength={directChildren.length} 
                next={() => {
                const { run } = createRequest(services.organization.GET_SWITCH, [directChildren.length,20,encodeURIComponent(search)]);
                run()
                  .then(response => {
                    setError(null);
                    if(response.data.length < 20)
                      setHasMore(false);
                    setDirectChildren(curState => [...curState,...response.data]);
                  })
                  .catch(err => {
                    setError((new CatchedWebError(err)).message);
                  })
                }}
                hasMore={hasMore}
                scrollableTarget="scrollableDivTree"
                loader={<div className="text-center" style={{minHeight: "40px"}}><Spinner size="sm" /></div>}
                endMessage={""}
              >
            <ExpandableList 
              isRoot 
              items={directChildren}
              setOpen={props.setOpen} 
              setSearch={setSearch} 
              multiSelect={props.multiSelect}
              selectedOrgs={props.selectedOrgs}
              setSelectedOrgs={props.setSelectedOrgs}/>
              </InfiniteScroll>
            </div> : <div className='text-center'>{search === "" ? "Managed Organization does not exist" : <span>No matching results found for "<strong>{search}</strong>"</span>}</div>
          ))
        }
      </div>
      <div>&nbsp;</div>
      <span>{directChildren?.length > 0 ? `Showing ${directChildren?.length} result(s).`: " "}</span>
      {!props.multiSelect && <Row>
          <Col className="my-1" sm="12" md="6">
          </Col>
          <Col sm="12" md="6">
          
          </Col>
        </Row>}
    </div>
  );
};

OrganizationsList.propTypes = {};

OrganizationsList.defaultProps = {};

export default OrganizationsList;
