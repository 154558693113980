import { Col, Container, Row } from "reactstrap";
import Skeleton from "./Skeleton";

const DonutChartSkeleton = ({ legendCount }) => {
  return (
      <Container>
          <Row>
              <Col xs={7}>
                    <Skeleton circle height="130px" width="130px" />
              </Col>
              <Col xs={5} className="pt-2">
                  {[...Array(legendCount).keys()].map(i => (
                    <Skeleton key={i} />
                  ))}
              </Col>
          </Row>
      </Container>
  );
};

export default DonutChartSkeleton;