import { orgtypesActions } from "../slices";
import store from "../store";
import createRequest, { services } from "../../services";
import { CatchedWebError } from "../../configs";

const getOrgTypes = () => {
  store.dispatch(orgtypesActions.setLoading(true));
  const { controller, run } = createRequest(services.organization.GET_TYPES);
  run()
    .then(response => {
      store.dispatch(orgtypesActions.setOrgTypes(response.data))
    })
    .catch(err => {
      let x = new CatchedWebError(err);
      store.dispatch(orgtypesActions.setError(x.message));
    })
    .finally(() => {
      store.dispatch(orgtypesActions.setLoading(false));
    });
  return controller;
}

export default getOrgTypes;