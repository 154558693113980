/***
 *
 * Controller class for user.
 * @file GroupButton.js
 * @description GroupButton component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React from "react";
// import PropTypes from 'prop-types';
import "./GroupButton.scss";

const GroupButton = ({children, ...props}) => {
  return (
    <div {...props} className={"GroupButton " + props.className??''} data-testid="GroupButton">
      {children}
    </div>
  );
};

GroupButton.propTypes = {};

GroupButton.defaultProps = {};

export default GroupButton;
