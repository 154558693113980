import { Col, Row } from "reactstrap";
import Chart from "react-apexcharts";
import "../NewDashboard/NDashboard.scss";

import Bullet from "./Bullet";


const DonutApexChartWrapper = ({ labels, series, colors, labelStyle, forceFullWidth }) => {
  return (
    <div>

      <Row className={forceFullWidth ? " " : "w-100"}>
          <Col className={"bg-white " + forceFullWidth ? "d-block" : "d-lg-none"} lg={forceFullWidth ? 1 : 2} md={1}></Col>
          <Col lg={forceFullWidth ? 10 : 6} md={10} className="px-0 text-center">
              <Chart options={{
                  dataLabels: {
                      enabled: false
                    },
                    chart: {
                        offsetY: 0,
                        offsetX: -5,
                    },
                    plotOptions: {
                        pie: {
                            size: 500,
                          donut: {
                              size: "75%",
                              labels: {
                                  show: true,
                                  value: {
                                      show: true,
                                      fontSize: "22px",
                                      fontWeight: "800"
                                    },
                                    total: {
                                        show: true,
                                      fontSize: "15px",
                                      fontWeight: "300px"
                                    },
                                    name: {
                                        show: true,
                                        fontSize: "15px",
                                        fontWeight: "300px"
                                    }
                                }
                            }
                        }
                    },
                    colors: colors,
                    labels: labels,
                  legend: {
                      show: false,
                      fontSize: "12rem",
                      offsetY: 20,
                      markers: {
                          width: 8,
                          height: 8
                        },
                        width: 150
                    }
              }} series={series} type="donut" height={"185px"}/>
          </Col>
          <Col className={forceFullWidth ? "d-block" : "d-lg-none"} lg={forceFullWidth ? 1 : 1} md={1}></Col>
          <Col lg={forceFullWidth ? 12 : 3} md={12} className="d-flex align-items-center px-0" style={{fontSize: "0.8rem"}}>
            <div className={"p-0 w-100 " + (labelStyle ? labelStyle : (
                forceFullWidth ?
                "d-flex justify-content-around":
                "d-md-flex justify-content-around d-lg-block"
                ))}>
              <Row className="ml-1">
                {labels.map((label, i) => <Col xs={forceFullWidth ? 6 : 12} style={{whiteSpace: "nowrap"}} className={forceFullWidth ? "horizontal" : "flexible"}>
                  <Bullet color={colors[i]} />
                &nbsp;&nbsp;{label}</Col>)}
              </Row>
            </div>
          </Col>
      </Row>
    </div>
  );
};

export default DonutApexChartWrapper;