import PropTypes from "prop-types";
import "./WallAnnotationsEditor.scss";
import { AnimatePresence, motion } from "framer-motion";
import WallsListItem from "./WallsListItem";
import WallAnnotationsCanvas from "./WallAnnotationsCanvas";
import { useEffect, useState } from "react";

/**
 * Wall Annotations Editor
 * @param {{
 *   layoutManager: object,
 *   layout: {
*     "venueId": number,
*     "name": string,
*     "layoutType": 1 | 2 | 3,
*     "layoutFile": string,
*     "infraPositions": Array<{
*       "infra_type_id": number,
*       "x": number,
*       "y": number
*     }>?,
*     "layoutJson": {
*       "dimensions": {
*         "length": number,
*         "width": number,
*         "height": number
*         "area": number
*       }?,
*       "walls": Array<{
*         "id": string,
*         "loc": Array<number>,
*         "material": string
*       }>,
*       "isActive": boolean,
*       "id": number,
*       "createdAt": string,
*       "updatedAt": string
*     }
*   }
 * }} props 
 * @returns 
 */
const WallAnnotationsEditor = (props) => {
  const [selectedWallMaterial, setSelectedWallMaterial] = useState("");

  const { layoutManager } = props;

  useEffect(() => {
    const layoutUuid = layoutManager.selectedWall.layoutUuid;
    const wallId = layoutManager.selectedWall.wallId;
    if (layoutUuid && wallId) {
      setSelectedWallMaterial(
        layoutManager
          .editableLayout
          .layouts[layoutUuid]
          .walls
          .find(wall => wall.id === wallId)
          ?.material
        ?? ""
      )
    }
  }, [layoutManager]);

  return (
    <motion.div
      className="WallAnnotationsEditor"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.15 }}
    >
      <motion.div
        className="map-area"
        initial={{ top: 10, opacity: 0 }}
        animate={{ top: 0, opacity: 1 }}
        transition={{ duration: 0.3, delay: 0.3 }}
      >
        <WallAnnotationsCanvas blank={props.blank} layoutManager={props.layoutManager} layouts={[props.layout]} />
      </motion.div>

      {/* <motion.div
        className="selector-area"
        initial={{ right: -10, opacity: 0 }}
        animate={{ right: 0, opacity: 1 }}
        transition={{ duration: 0.3, delay: 0.3 }}
      >
        <h4 className="p-1 m-0 border-bottom">Annotate Walls</h4>
        <AnimatePresence>
          {props.layoutManager.selectedWall.wallId !== null && selectedWallMaterial !== "" &&
            <motion.div
              key={props.layoutManager.selectedWall.wallId}
              initial={{ bottom: -10, opacity: 0 }}
              animate={{ bottom: 0, opacity: 1 }}
              transition={{ duration: 0.2 }}
              className="p-1 walls-list"
            >
              <WallsListItem selectedWall={props.layoutManager.selectedWall} setWallMaterial={props.layoutManager.setWallMaterial} highlighted={selectedWallMaterial} label="Brick Wall" value="brick wall" shorthand="B" color="#CB553D" />
              <WallsListItem selectedWall={props.layoutManager.selectedWall} setWallMaterial={props.layoutManager.setWallMaterial} highlighted={selectedWallMaterial} label="Dry Wall" value="dry wall" shorthand="D" color="#918179" />
              <WallsListItem selectedWall={props.layoutManager.selectedWall} setWallMaterial={props.layoutManager.setWallMaterial} highlighted={selectedWallMaterial} label="Steel Wall" value="steel wall" shorthand="M" color="#9FA4A9" />
              <WallsListItem selectedWall={props.layoutManager.selectedWall} setWallMaterial={props.layoutManager.setWallMaterial} highlighted={selectedWallMaterial} label="Wooden Wall" value="wooden wall" shorthand="W" color="#E39645" />
            </motion.div>
          }
        </AnimatePresence>
        <AnimatePresence>
          {(props.layoutManager.selectedWall.wallId === null || selectedWallMaterial === "") &&
            <motion.div
              key={props.layoutManager.selectedWall.wallId}
              initial={{ bottom: -10, opacity: 0 }}
              animate={{ bottom: 0, opacity: 1 }}
              transition={{ duration: 0.2 }}
              className="px-3 pb-4 walls-list d-flex justify-content-center align-items-center text-center no-wall-selected-message"
            >
              Select a wall to edit its properties.
            </motion.div>
          }
        </AnimatePresence>
      </motion.div> */}

    </motion.div>
  );
};

WallAnnotationsEditor.propTypes = {
  layout: PropTypes.object
};
WallAnnotationsEditor.defaultProps = {};

export default WallAnnotationsEditor;