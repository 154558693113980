import PropTypes from "prop-types";
import "./FloorplanSlideView.scss";
import { UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from "reactstrap";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import FloorplanStaticRenderer from "../FloorplanStaticRenderer";
import { wre } from "../../../../../services/wre.service";
import Skeleton from "../../../../Dashboard/Graphs/Skeleton";
import createRequest, { services } from "../../../../../services";
import { getTimeZone } from "../../../../../utility/Localization";

/**
 * Layout List Item
 * @param {{
 *   layout: {
 *     "venueId": number,
 *     "name": string,
 *     "layoutType": 1 | 2 | 3,
 *     "layoutFile": string,
 *     "infraPositions": Array<{
 *       "infra_type_id": number,
 *       "x": number,
 *       "y": number
 *     }>?,
 *     "layoutJson": {
 *       "dimensions": {
 *         "length": number,
 *         "width": number,
 *         "height": number
 *         "area": number
 *       }?,
 *       "walls": Array<{
 *         "id": string,
 *         "loc": Array<number>,
 *         "material": string
 *       }>,
 *       "isActive": boolean,
 *       "id": number,
 *       "createdAt": string,
 *       "updatedAt": string
 *     }
 *   },
 *   onDelete: () => void,
 *   onHeaderClick: () => void
 * }} props - Passed down props for layout list item
 * @returns 
 */
const FloorplanSlideView = (props) => {
    const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
    const venueId = props.venueId
    const [layout, setLayout] = useState(props.layout)
    const layoutId = layout?.id;
    const layoutType = layout.layoutType;
    const isDesignLayout = props.isDesignLayout
    const [img, setImg] = useState(null);
    const [loading, setLoading] = useState(false)
    const [imgLoading, setImgLoading] = useState(true);
    const [apCount, setApCount] = useState({
        planned: 0,
        online: 0,
        offline: 0,
        cableDrop: 0
    })


    const updateApCount = async () => {
        setLayout(props.layout)

        let convertedTrueList = []
        let realInfraIdList = []
        let realInfraPlanList = []
        let onlineInfraList = []
        let offlineInfraList = []

        let tempLayout = { ...props.layout }
        let tempRealInfraPosition = props.layout.realInfraPositions
        let tempInfraPosition = props.layout.infraPositions
        // let tempInfraPosition = []

        for (const i in props.layout.infraPositions) {
            let infra = props.layout.infraPositions[i]
            if (infra.isConverted)
                convertedTrueList.push(infra.id)
        }
        for (const i in props.layout.realInfraPositions) {
            let realInfra = props.layout.realInfraPositions[i]
            realInfraIdList.push(realInfra.infraItemId)
            realInfraPlanList.push(realInfra.planId)

        }


        setApCount({
            planned: (props.layout.infraPositions.length - convertedTrueList.length),
            online: onlineInfraList.length,
            offline: offlineInfraList.length,
            cableDrop: props.layout.cableDrop.length
        })
        const { run, controller } = createRequest(services.telemetry.GET_INFRA_STATUS, [activeOrgId, venueId, new Date().getTimezoneOffset(), realInfraIdList])
        if (realInfraIdList.length > 0) {
            run()
                .then((res) => {

                    for (let i in tempRealInfraPosition) {
                        let tempInfra = tempRealInfraPosition[i]
                        for (let j in res.data) {
                            let infraStatus = res.data[j]
                            if (tempInfra.infraItemId == infraStatus.infraItemId) {
                                tempRealInfraPosition[i].status = infraStatus.status

                                if (infraStatus.status == 'connected') {
                                    onlineInfraList.push(infraStatus.infraItemId)
                                }
                                else {
                                    offlineInfraList.push(infraStatus.status)
                                }
                            }
                        }
                    }

                    let newList = props.layout.infraPositions.filter((infra) => !realInfraPlanList.includes(infra.id))
                    tempLayout["infraPositions"] = props.layout.infraPositions.filter((infra) => !infra.isConverted)
                    setLayout(tempLayout)
                    // tempLayout.infraPositions = tempInfraPosition
                    // setLayout(tempLayout)

                    setApCount({
                        planned: (props.layout.infraPositions.length - convertedTrueList.length),
                        online: onlineInfraList.length,
                        offline: offlineInfraList.length,
                        cableDrop: props.layout.cableDrop.length
                    })
                    setLoading(false)
                })
                .catch((err) => {
                    setLoading(false)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
        else {
            setLoading(false)
        }
    }
    useEffect(() => {
        setLoading(true)
        updateApCount()
    }, [props.layout])

    useEffect(() => {

        if ((activeOrgId && layoutType === 1)) {
            // const { run, controller } = createRequest(wre.GET_IMAGE, [activeOrgId, isDesignLayout ? layout.layoutFile.slice(7) : layoutId]);
            const { run, controller } = createRequest(wre.GET_FLOORPLAN_IMAGE, [activeOrgId, layout.id]);
            run()
                .then(response => {
                    setImg(response.data);

                })
                .catch(err => {
                    setImg("");
                })
                .finally(() => { setImgLoading(false) })
            return () => controller && controller.abort();
        }
    }, [activeOrgId, layoutId, layoutType, isDesignLayout, layout]);

    return (
        <div className="FloorplanSlideView   p-1  ">
            <div className="center-div mb-1">
                <h5 className="fw-600 text-center layout-name m-0 ">
                    {layout.name || "Untitled"}
                </h5>
            </div>

            <div >
                {(layout.layoutType === 1) ?
                    (imgLoading ?
                        <Skeleton height="220px" /> :
                        ((img === "" || img === null) ?
                            <Skeleton height="220px" enableAnimation={false} /> :

                            <div className="min-height-check ">
                                <FloorplanStaticRenderer
                                    layout={layout.layoutJson}
                                    completeLayout={layout}
                                    //  width="100%" //hinthere removed byrd
                                    height="220px"
                                    imageSrc={img}
                                    hideBackGrid={props.hideBackGrid}
                                    showAP={props.showAP}
                                    showRealAp={props.showRealAp}
                                    showCableDrop={props.showCableDrop}
                                />
                            </div>


                            // <div className="d-flex justify-content-center">
                            //     <img height="171px" style={{ paddingTop: "5px", borderRadius: "5px", maxWidth: "100%", objectFit: "contain" }} src={img} alt={layout.name} />
                            // </div>
                        )
                    )
                    :
                    (!(layout?.layoutJson?.components?.length > 0) ?
                        <img src={require('../../../../../assets/images/icons/blank_grid.webp')} style={{ width: '100%', height: '100%' }} />
                        :
                        loading ?
                            <div className="center-div min-height-check" style={{ height: '100%' }}>
                                <Spinner color="primary" />
                            </div>
                            :
                            <div className="min-height-check ">
                                <FloorplanStaticRenderer
                                    layout={layout.layoutJson}
                                    completeLayout={layout}
                                    //  width="100%" //hinthere removed byrd
                                    height="220px"
                                    hideBackGrid={props.hideBackGrid}
                                    showAP={props.showAP}
                                    showRealAp={props.showRealAp}
                                    showCableDrop={props.showCableDrop}
                                />
                            </div>

                        // : null
                    )
                }
            </div>
            <div className="d-flex w-100 center-div mt-2">
                <div className="green-circle   " />
                <span>Online ({apCount.online})</span>
                <div className="blue-circle ml-4" />
                <span>Installed ({apCount.offline})</span>
                <div className="yellow-circle ml-4" />
                <span>Planned ({apCount.planned})</span>
                <div className="cable-drop-legend ml-4" />
                <span>Cable Drop ({apCount.cableDrop})</span>
            </div>
        </div>
    );
};

FloorplanSlideView.propTypes = {
    layout: PropTypes.object,
    onDelete: PropTypes.func,
    promoteToDesign: PropTypes.func,
    onHeaderClick: PropTypes.func
};
FloorplanSlideView.defaultProps = {};

export default FloorplanSlideView;