/**
 * Routes Maker Component
 * @file RoutesMaker.js
 * @description Component to create routes from lists
 * @author Utkarsh Gupta
 * @since 26 June 2022
 */

import React from "react";
import PropTypes from "prop-types";
import "./RoutesMaker.scss";

import { Routes, Route, Navigate } from "react-router-dom";
// import { useAuth } from "../../auth";
import PermissionDenied from "../PermissionDenied";
import { useAuth0 } from "../../auth0";

/**
 * Creates a React Router 6 compatible route selection from a list of routes
 * @param {{id: string, path: string, isProtected: boolean, config: object, Component: JSX.Element}[]} routes 
 * @returns A React-Router routes selector
 */
const RoutesMaker = ({ routes }) => {
  const { isAuthenticated } = useAuth0();
  if (routes.length === 0) {
    return <React.Fragment />;
  }

  return (
    <Routes>
      {routes.map(Item => {
        return (
          <Route
            key={Item.id} 
            path={Item.path} 
            element={
              ((Item.isProtected && isAuthenticated) || (!Item.isProtected))
              ? Item.isAuthorized
                ? Item.Component
                : <PermissionDenied />
              : <Navigate to={`/`} />
            } 
          />
        );
      })}
    </Routes>
  );
};

RoutesMaker.propTypes = {
  routes: PropTypes.array,
};

RoutesMaker.defaultProps = {
  routes: [],
};
// Item.isPermitted?
                // Item.Component : <PermissionDenied /> :
                // <Navigate to={`/login/`} />

export default RoutesMaker;
