export const mac_regex = /^([0-9A-Fa-f]{2}[\s:-]){5}([0-9A-Fa-f]{2})$|^([a-fA-F0-9]{12})$|^([0-9A-Fa-f]{4}[\s:-]){2}([0-9A-Fa-f]{4})$/;

export const colon_mac = (mac) => {
    let isValid = mac_regex.test(mac);
    if(isValid) {
        if(mac?.indexOf(':') !== -1) {
            return mac;
        }
        else {
            let colonMac="";
            for (let i = 0; i < mac?.length; i++) {
                colonMac += mac[i];
                if (i % 2 === 1 && i !== mac?.length - 1) {
                    colonMac += ":";
                }
            }
            return colonMac;
        }
    }
    else {
        return mac;
    }
}

export const serial_mac = (mac) => {
    let isValidMac = mac_regex.test(mac)
    if(isValidMac && mac?.indexOf(':') !== -1)
        return mac?.replaceAll(':','')
    else
        return mac
}