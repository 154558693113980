/***
 *
 * Controller class for user.
 * @file Notification.js
 * @description Notification component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import React, { useRef } from "react";
// import PropTypes from 'prop-types';
import "./Notification.scss";
import { Button, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, Modal, ModalBody, Spinner, Table, UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody, Popover } from "reactstrap";
import { SearchIconAddon, FilterSetter, ColumnHeader } from "../../../components";
import FilterButton from "../../../components/FilterButton";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { breadcrumbActions } from "../../../redux/slices";
import { useLocation, useNavigate } from "react-router";
import createRequest from "../../../services";
import blazer from "../../../services/blazer.service";
import { ALERT_LOW, ALERT_MED, ALERT_HIG, SCROLL_MORE_TEXT, BUSINESS } from "../../../utility/constants";
import { AggregateVenueFilter, CumulativeInfrasFilter, NotificationImpactFilter, NotificationTypeFilter, OrgFilter } from "../../Filters/filters";
import { dateTimeFormatter, getTimeZone } from "../../../utility/Localization";
import InfiniteScroll from "react-infinite-scroll-component";
import { make_toast } from "../../../helpers";
import { CatchedWebError } from "../../../configs";
import { timeDiff } from "../../Infrastructure/SwitchOverview";
import lodash from "lodash-es"
import APSVG from "../../Infrastructure/InfraList/APSVG";
import SwitchSVG from "../../Infrastructure/InfraList/SwitchSVG";
import { ReactComponent as Venue } from "../../../assets/images/icons/Venue.svg";
import GroupButton from "../../../components/GroupButton";
import { cmpCol, getColumns, setColumns } from "../../../utility/colSaver";
import SidePanel from "../../../components/SidePanel";
import ColSelector from "../../../components/ColSelector";
import {ReactComponent as TicketIcon} from "../../../assets/images/icons/ticket-icon.svg";
// import {ReactComponent as NotificationIcon} from "../../assets/images/icons/notification.svg";
import notificationSvg from "../../../assets/images/icons/notification.svg";
import { EmergencySirenIcon } from "../../../assets/images/icons/Icons";
import { ReactComponent as WarningIcon } from "../../../assets/images/icons/WarningWhiteExclamation.svg";
import { ReactComponent as OrangeWarningIcon } from "../../../assets/images/icons/OrangeWarning.svg";
import AlarmDesc from "../../../components/AlarmDesc";
import { Tooltip } from "react-tooltip";

const alertColor = {
  [ALERT_LOW]: "success",
  [ALERT_MED]: "warning",
  [ALERT_HIG]: "danger"
}

const alertText = {
  [ALERT_LOW]: "Minor",
  [ALERT_MED]: "Major",
  [ALERT_HIG]: "Critical"
}

export const summaryPopoverStyle = `
.noti-summary-popover > .popover.bs-popover-auto[x-placement^=bottom] .arrow:after {
  border-bottom-color: white
}
.noti-summary-popover > .popover {
  border-radius: 0.358rem
}
.noti-summary-popover > .popover .popover-body {
  border-top-color: #ebe9f1;
  border-radius: 0.358rem
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 0;
  border-width: 0.1rem 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
`
const filterInitial = {
  alertImpact: [],
  alertType: [],
  infras: [],
  venues: [],
  // venueChildren: false
}

const Notification = () => {
  const SCREEN_ID = "notifi-dashboard";
  const initColumns = {
    Type: true,
    Affects: true,
    Summary: true,
    Venues: true,
    Created: true,
    Severity: true,
  }
  const initMount = useRef(true);

  const view = useSelector(store => store.identity.meta.view);
  const permissions = useSelector(store => store?.rbac?.permissions);
  const location = useLocation();
  const [filterActive, setFilterActive] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filterData, setFilterData] = useState(filterInitial);
  const [historical, setHistorical] = useState(location?.state?.historical ?? false);
  const [search, setSearch] = useState("");
  const [popover, setPopover] = useState(null);
  const [ackModal, setAckModal] = useState(null);
  const [ackLoading, setAckLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState({})
  const [notification, setNotification] = useState([]);
  const [sort, setSort] = useState({
    order: "DESC",
    orderBy: "created_at"
  });
  const [cols, setCols] = useState(cmpCol(initColumns, getColumns(SCREEN_ID, view)??{}));
  const [isColumnSelectorOpen, setIsColumnSelectorOpen] = useState(false);
  const [notiController, setNotiController] = useState(null)
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const activeOrg = useSelector(store => store.activeOrg.data);
  const [locationApiCalled, setLocationApiCalled] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  let timer;

  const debounce = (callback) => {
    clearTimeout(timer);
    timer = setTimeout(callback, 1000);
  }

  const getNotification = (filter = filterData, from = notification.length, orgChildren=true) => {
    const { controller, run } = createRequest(blazer.ORG_ALERT, [activeOrgId, from, 20, search, historical ? "acknowledge" : "open", sort, {...filter, orgs: filter?.orgs?.map(it => it.orgId)}, true, orgChildren])
    setShowFilter(false);
    notiController?.abort()
    setNotiController(controller)
    run().then((response) => {
      if (from == 0)
        setNotification(response.data.response)
      else
        setNotification(prevState => [...prevState, ...response.data.response]);
      if (response.data.response.length < 20)
        setHasMore(false);
      setCount({ historical: response?.data?.historicalCount, actionable: (response?.data?.totalCount) })
    })
      .finally(() => {
        setLoading(false)
        setNotiController(null)
      });
  }

  const ackNotification = (id) => {
    const { run } = createRequest(blazer.ACK_ALERT, [id])
    setAckLoading(true);
    run()
      .then(() => {
        setNotification(prevState => prevState.filter(it => it.id != id));
      })
      .catch((err) => {
        make_toast("error", (new CatchedWebError(err)).message);
      })
      .finally(() => {
        setAckLoading(false);
        setAckModal(null);
      })
  }

  // useEffect(() => {
  //   console.log("location: ",location.state)
  //   if(!!location?.state?.filter && !locationApiCalled) {
  //     setFilterData(prevState => {
  //       let newData = {
  //         ...prevState,
  //         ...location?.state?.filter
  //       }
  //       setFilterActive(!lodash.isEqual(newData,filterInitial))
  //       setHasMore(true)
  //       getNotification(newData, 0, false)
  //       return newData
  //     })
  //     setLocationApiCalled(true)
  //   }
  // }, [location])
  

  useEffect(() => {
    setNotification([]);
    setHasMore(true);
    setLoading(true);
  if(!!location?.state?.filter  && !locationApiCalled) {
    setFilterData(prevState => {
      let newData = {
        ...prevState,
        ...location?.state?.filter
      }
      setFilterActive(!lodash.isEqual(newData,filterInitial))
      setHasMore(true)
      getNotification(newData, 0, true)
      setLocationApiCalled(true)
      return newData
    })
  }
  else
    getNotification(filterData, 0);
  }, [search, historical, sort, location])

  useEffect(() => {
    if(initMount.current)
      initMount.current = false
    else
      setCols({...initColumns, ...getColumns(SCREEN_ID, view)??{}})
  }, [view])

  useEffect(() => {
    dispatch(breadcrumbActions.setData([{
      text: "Notifications",
      active: true
    }]))

  }, [])

  return (
    <div className="Notification mt-1" data-testid="Notification">
      <div className="rounded border shadow bg-white p-1">
        <SidePanel
          isOpen = {isColumnSelectorOpen}
          setIsOpen = {setIsColumnSelectorOpen}>
          <ColSelector cols={cols} setCols={setCols} setIsOpen={setIsColumnSelectorOpen} onApply={(newCol) => {
            setColumns(SCREEN_ID, newCol, view);
          }}/>
        </SidePanel>

        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <div className="search-box mr-1" style={{ width: "310px" }}>
              <InputGroup className="input-group-merge">
                <Input autoFocus type="text" placeholder="Search" style={{ height: '2.4rem' }} onChange={
                  (e) => {
                    debounce(() => setSearch(e.target.value));
                  }
                } />
                <SearchIconAddon />
              </InputGroup>
            </div>
            <GroupButton>
              <div className={"grp-btn-custom " + (!historical ? "active" : "")} onClick={() => { if (!loading) setHistorical(false) }}>Actionable ({count?.actionable??0})</div>
              <div className={"grp-btn-custom " + (historical ? "active" : "")} onClick={() => { if (!loading) setHistorical(true) }}>Historical ({count?.historical??0})</div>
            </GroupButton>
            <FilterButton className="ml-50" active={filterActive} size={22} onClick={() => setShowFilter(true)} style={{ padding: '0.36rem' }} />
            <FilterSetter setShowFilter={setShowFilter}
              showFilter={showFilter}
              filter={filterData}
              setFilter={setFilterData}
              elements={() => {
                return [
                  <NotificationTypeFilter filter={filterData} setFilter={setFilterData} />,
                  <NotificationImpactFilter filter={filterData} setFilter={setFilterData} />,
                  <OrgFilter hide={activeOrg.orgTypeId == BUSINESS} filter={filterData} setFilter={setFilterData} />,
                  <AggregateVenueFilter filter={filterData} setFilter={setFilterData} />,
                  <CumulativeInfrasFilter filter={filterData} setFilter={setFilterData}/>
                ]
              }}
              disabled={!Object.keys(filterData).length}
              handleApplyClick={() => {
                setHasMore(true);
                const newFilterData = {...filterData, 
                  // venueChildren: false
                }
                setFilterData(newFilterData)
                if (lodash.isEqual(newFilterData, filterInitial))
                  setFilterActive(false)
                else
                  setFilterActive(true);
                getNotification(newFilterData, 0);
              }}
              handleClearAll={() => {
                setHasMore(true);
                setFilterActive(false);
                setFilterData(filterInitial);
                getNotification(filterInitial, 0);
              }}
            />
          </div>

          <div className="d-flex">
            <button className="p-0 refresh-button btn ml-1 bg-white" onClick={() => { setNotification([]); setLoading(true); getNotification(filterData, 0) }}>
              <div className="d-flex align-items-center justify-content-center">
                <div className="material-symbols-outlined padded-icon">Cached</div>
              </div>
            </button>
          </div>
        </div>

        <Modal centered isOpen={!!ackModal} toggle={() => { if (!ackLoading) setAckModal(null) }}>
          <ModalBody className="text-center p-1">
            <div className="text-center">
              <span className="material-symbols-outlined text-warning display-2">error</span>
            </div>
            <h3 className="font-weight-bolder mt-1 mb-2">Are you sure?</h3>
            Acknowledging this notification, will move it to historical view.
            <div className="d-flex justify-content-center mt-2">
              <Button color="danger" outline onClick={() => { if (!ackLoading) setAckModal(null) }}>Cancel</Button>
              <Button color="primary" className="ml-1" onClick={() => { ackNotification(ackModal) }}>Yes, Acknowledge</Button>
            </div>
          </ModalBody>
        </Modal>

        <div>
              <InfiniteScroll
              dataLength={notification.length}
              next={() => { getNotification(); }}
              hasMore={hasMore}
              loader={<div className="mt-50">{SCROLL_MORE_TEXT}</div>}
              endMessage={<div className="mt-50">Showing {notification.length} result(s)</div>}
              scrollableTarget="table-bod"
            >
              <div className="mt-2" id="table-bod">
                <Table className="bg-white table-view fixed-header">
                  <thead>
                    <tr>
                      {cols.Type && <th style={{ width: "7%" }} className="rounded-left-top">
                        <ColumnHeader attribute={"alert_type"} header="Type" setter={setSort} sort={sort} />
                      </th>}
                      <th style={{ width: "18%" }}><ColumnHeader header="Id" attribute={"alert_id"} setter={setSort} sort={sort} /></th>
                      {cols.Affects && <th style={{ width: "19%" }}><ColumnHeader header="Affects" /></th>}
                      {cols.Summary && <th style={{ width: "20%" }}><ColumnHeader header="Summary" attribute={"display_name"} setter={setSort} sort={sort}/></th>}
                      {cols.Venues && <th style={{ width: "18%" }}><ColumnHeader header="Venue" /></th>}
                      {cols.Created && <th style={{ width: "12%" }}><ColumnHeader header="Created" attribute={"created_at"} setter={setSort} sort={sort} /></th>}
                      {cols.Severity && <th style={{ width: "12%" }}>
                        <ColumnHeader header="Severity" attribute={"alert_impact"} setter={setSort} sort={sort} />
                      </th>}
                      <th style={{ width: "2%" }} className="text-right">
                        <span 
                        className="material-symbols-outlined cursor-pointer" 
                        onClick={() => 
                          setIsColumnSelectorOpen(prevState => !prevState)}>
                          settings
                      </span>
                      </th>
                    </tr>
                  </thead>
                  {notification.length>0 ? 
                    <tbody>
                      {notification?.map((noti, index) => {
                        return <tr key={index}>
                          {cols.Type && <td style={{ width: "7%" }}>
                          {(noti?.alert_type === "alarm" || noti?.alert_type === "venue_alarm") ? (
                          noti?.alert_impact == ALERT_HIG ? (
                            <EmergencySirenIcon
                              style={{ width: "22px", height: "22px" }}
                            />
                          ) : noti?.alert_impact == ALERT_MED ? (
                            <OrangeWarningIcon
                              style={{ width: "22px", height: "22px" }}
                            />
                          ) : noti?.alert_impact == ALERT_LOW ? (
                            <WarningIcon
                              style={{ width: "22px", height: "22px" }}
                            />
                          ) : null
                        ) : noti?.alert_type === "ticket" ? (
                          <TicketIcon
                            style={{ width: "22px", height: "22px" }}
                          />
                        ) : noti?.alert_type === "notification" ? (
                          // <NotificationIcon
                          //   style={{ width: "22px", height: "22px" }}
                          // />
                          <img src={notificationSvg} style={{ width: '22px', height: '22px' }} alt="notification"/>
                        ) : null}
                          </td>}
                          <td style={{ width: "18%" }} className={(noti?.alert_type === "ticket") ? "table-link" : ""} onClick={() => {
                            if (noti?.alert_type === "ticket")
                              navigate(`/organization/${noti.org_id}/support/ticket/details/${noti?.alert_id}`)
                          }}><span style={{ textTransform: "capitalize" }}>{noti?.alert_type.replace("_"," ")} </span>{noti?.alert_id != null ? noti?.alert_id : "-"}</td>
                          {cols.Affects && <td style={{ width: "14%" }} className={noti?.affected_entities != null && (noti?.affected_entities[0]?.venue_id != null || (noti?.affected_entities[0]?.infra_id != null && noti?.is_active)) && noti?.org_id != null ? "table-link" : ""}
                            onClick={() => {
                              if(noti?.org_id != null)
                              {
                                if (noti?.affected_entities != null && noti?.affected_entities[0]?.venue_id != null)
                                  navigate(`/organization/${noti.org_id}/venues/${noti?.affected_entities[0]?.venue_id}`)
                                else if (noti?.is_active && noti?.affected_entities != null && noti?.affected_entities[0]?.infra_id != null)
                                  navigate(`/organization/${noti.org_id}/infra/${noti?.affected_entities[0]?.infra_id}`)
                              }
                            }}>
                            {noti?.affected_entities != null &&
                              <span>
                                {noti?.affected_entities[0].venues != null ?
                                  <Venue className="mr-50"/> :
                                  noti?.affected_entities[0].infra_category == "ap" ? (
                                    <APSVG className="mr-50"/>
                                  ) : noti?.affected_entities[0].infra_category == 'switch' ? (
                                    <SwitchSVG className="mr-50"/>
                                  ) : (noti?.affected_entities[0].infra_category == null && 
                                      !!noti?.affected_entities[0].venue_name) ? (
                                    <Venue className="mr-50"/>
                                  ) : (
                                    null
                                  )}
                              </span>}
                            {noti?.affected_entities != null ?
                              noti?.affected_entities[0].venues != null ?
                                <span className="cursor-pointer mt-50" id={`venue${index}`} onMouseEnter={() => setPopover(index)} onMouseLeave={() => setPopover(null)}>
                                  <span>{noti?.affected_entities[0]?.venues.length > 1 ? (noti?.affected_entities[0]?.venues.length || '-') + ' Venues'
                                    : noti?.affected_entities[0]?.venues[0]?.venue_name}</span>
                                  <Popover
                                    placement='right'
                                    target={`venue${index}`}
                                    isOpen={popover === index}
                                  >
                                    <PopoverHeader>Venues</PopoverHeader>
                                    <PopoverBody className="scrollable-popover">
                                      {noti?.affected_entities[0].venues?.map((venue, key) => (
                                        <div className="cursor-pointer text-primary" key={'orgRowPopver' + key} onClick={()=>navigate(`/organization/${noti.org_id}/venues/${venue?.venue_id}`)}>{venue.venue_name}</div>
                                      ))}
                                    </PopoverBody>
                                  </Popover>
                                </span> :
                                (noti?.affected_entities[0].org_name ?? noti?.affected_entities[0].venue_name ?? noti?.affected_entities[0].infra_name) : "-"}</td>}
                          {cols.Summary && 
                            <td>
                              <div 
                                id={`noti-description${index}`} 
                                // onMouseEnter={() => setSummaryPopover(`noti-description${index}`)} 
                                // onMouseLeave={() => setSummaryPopover(null)}
                                className={`${'cursor-pointer'}`}
                              >
                                {noti?.display_name!=null?  <span>{noti?.display_name}<span className="material-symbols-outlined info-icon">info</span></span> : "-"}
                              </div>
                              <Tooltip
                                anchorSelect={`#noti-description${index}`}
                                variant="light"
                                clickable
                                place="bottom"
                                border="solid 2px #EAEAEA"
                                opacity={1}
                                style={{boxShadow:"2px 2px 15px #EAEAEA", zIndex: 40}}
                                delayShow={200}
                              >
                                <div style={{width:"270px", fontSize:"1rem"}}>
                                  <AlarmDesc alarmType={noti?.alert_code} data={noti?.metadata_payload??{}} desc={noti?.description} orgId={noti?.org_id}/>
                                </div>
                              </Tooltip>
                            </td>
                          }
                          {cols.Venues && 
                            <td 
                              style={{ width: "18%" }}
                              className={`${
                                noti.alert_type==='notification'?
                                  (!!noti?.affected_entities && 
                                  noti.affected_entities.length === 1 && 
                                  noti?.affected_entities[0]?.org_id != null && 
                                  noti?.affected_entities[0]?.venues.length === 1 && 
                                  noti?.affected_entities[0]?.venues[0].venue_id != null)?
                                    'table-link'
                                  : ''
                                : !!noti?.venue_name && noti?.org_id != null && noti?.venue_id != null?
                                    'table-link'
                                  : ''}`}
                              onClick={() => {
                                if(noti.alert_type==='notification' &&
                                  (!!noti?.affected_entities && 
                                  noti.affected_entities.length === 1 && 
                                  noti?.affected_entities[0]?.org_id != null && 
                                  noti?.affected_entities[0]?.venues.length === 1 && 
                                  noti?.affected_entities[0]?.venues[0].venue_id != null)) {
                                    navigate(`/organization/${noti?.affected_entities[0]?.org_id}/venues/${noti?.affected_entities[0]?.venues[0].venue_id}`)
                                  }
                                else if(!!noti?.venue_name && noti?.org_id != null && noti?.venue_id != null) {
                                  navigate(`/organization/${noti.org_id}/venues/${noti?.venue_id}`)
                                }
                              }}
                            >
                              {noti.alert_type==='notification'?
                                (!!noti?.affected_entities && 
                                noti.affected_entities.length === 1 && 
                                noti?.affected_entities[0]?.org_id != null && 
                                noti?.affected_entities[0]?.venues.length === 1 && 
                                noti?.affected_entities[0]?.venues[0].venue_id != null)?
                                  noti?.affected_entities[0]?.venues[0]?.venue_name ?? "-"
                                : "-"
                              :(noti.venue_name??"-")}
                            </td>
                          }
                          {cols.Created && <td style={{ width: "12%" }} className="cursor-pointer" id={`noti${index}`}>{timeDiff(noti.created_at) + " ago"}
                            <UncontrolledTooltip target={`noti${index}`}>
                              {dateTimeFormatter(noti.created_at, "long", "medium") + " (" + getTimeZone(false) + ") "}
                            </UncontrolledTooltip>
                          </td>}
                          {cols.Severity && <td style={{ width: "12%" }}><span className={`font-weight-bolder text-${alertColor[noti?.alert_impact]}`} style={{ textTransform: "capitalize" }}>{alertText[noti.alert_impact]}</span></td>}
                          <td className="text-right" style={{ width: "2%" }}>
                            <UncontrolledDropdown className={historical?"d-none":""} direction="down" style={{ position: "inherit" }}>
                              <DropdownToggle color='white' className="w-0 p-0" disabled={historical || !permissions?.manageOrganization?.create}>
                                <span className={"material-symbols-outlined cursor-pointer " + ((historical || !permissions?.manageOrganization?.create) ? "text-secondary" : "text-primary")} title="Actions">more_vert</span>
                              </DropdownToggle>
                              {!historical && <DropdownMenu>
                                <DropdownItem className="w-100" onClick={() => {
                                  setAckModal(noti?.id)
                                }}>
                                  Acknowledge
                                </DropdownItem>
                              </DropdownMenu>}
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      })
                      }
                    </tbody>:loading?
                    <tbody>
                      <tr>
                        <td colSpan={10} className="p-5 text-center"><Spinner color="primary" /></td>
                      </tr>
                    </tbody>:
                    <tbody>
                      <tr>
                        <td colSpan={10} className="p-5 text-center"><h4>No Notification Present.</h4></td>
                      </tr>
                    </tbody>
                    }
                </Table>
              </div>
            </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

Notification.propTypes = {};

Notification.defaultProps = {};

export default Notification;
