import PropTypes from "prop-types";

const WallAnnotationsIcon = ({svgProps, color}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none" {...svgProps}>
    <g clipPath="url(#clip0_24515_7470)">
      <path d="M16 13V7" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10 19V13" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M22 19V13" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16 25V19" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M4 13H28" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M4 19H28" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M28 7H4V25H28V7Z" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_24515_7470">
        <rect width="32" height="32" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

WallAnnotationsIcon.propTypes = {
  svgProps: PropTypes.object,
  color: PropTypes.string
}
WallAnnotationsIcon.defaultProps = {
  svgProps: {
    width: "32",
    height: "32",
  },
  color: "#323232"
}

export default WallAnnotationsIcon;