import { ReactComponent as Add } from './Add.svg';
import { ReactComponent as Expand } from './Expand.svg';
import { ReactComponent as Collapse } from './Collapse.svg';
import { ReactComponent as Grid } from './Grid.svg';
import { ReactComponent as List } from './List.svg';
import { ReactComponent as User } from './User.svg';
import { ReactComponent as Security } from './Security.svg';
import { ReactComponent as Search } from './Search.svg';
import { ReactComponent as Critical } from './Critical.svg';
import { ReactComponent as VenueBlue } from './VenueBlue.svg';
import { ReactComponent as ExpandView } from './ExpandIcon.svg';
import { ReactComponent as Bell } from './Bell.svg';
import { ReactComponent as Firmware } from './Firmware.svg';
import { ReactComponent as EmergencySiren } from './emergency-siren.svg';


export const AddIcon = (props) => {
    return <Add {...props} />
}
export const ExpandIcon = (props) => {
    return <Expand {...props} />
}
export const CollapseIcon = (props) => {
    return <Collapse {...props} />
}
export const GridIcon = (props) => {
    return <Grid {...props} />
}
export const ListIcon = (props) => {
    return <List {...props} />
}


export const UserIcon = (props) => {
    return <User {...props} />
}


export const SecurityIcon = (props) => {
    return <Security {...props} />
}


export const SearchIcon = (props) => {
    return <Search {...props} />
}


export const CriticalIcon = (props) => {
    return <Critical {...props} />
}


export const EmergencySirenIcon = (props) => {
    return <EmergencySiren {...props} />
}



export const VenueBlueIcon = (props) => {
    return <VenueBlue {...props} />
}


export const ExpandViewIcon = (props) => {
    return <ExpandView  {...props} />
}
export const NotificationIcon = (props) => {
    return <Bell className='' {...props} />
}


export const FirmwareIcon = (props) => {
    return <Firmware  {...props} />
}


