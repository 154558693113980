import PropTypes from "prop-types";
import "./SnappingCanvas.scss";
import Canvas from "../../../Canvas";
import { CANVAS_FEATURES } from "../../../constants";
import { useEffect } from "react";

/**
 * Snapping Canvas
 * @param {{
 *   layoutManager: object,
 *   layouts: Array<{
*     "venueId": number,
*     "name": string,
*     "layoutType": 1 | 2 | 3,
*     "layoutFile": string,
*     "infraPositions": Array<{
*       "infra_type_id": number,
*       "x": number,
*       "y": number
*     }>?,
*     "layoutJson": {
*       "dimensions": {
*         "length": number,
*         "width": number,
*         "height": number
*         "area": number
*       }?,
*       "walls": Array<{
*         "id": string,
*         "loc": Array<number>,
*         "material": string
*       }>,
*       "isActive": boolean,
*       "id": number,
*       "createdAt": string,
*       "updatedAt": string
*     }  
*   }>
 * }} props 
 */
const SnappingCanvas = (props) => {
  return (
    <div className="SnappingCanvas">
      <Canvas
        layoutManager={props.layoutManager}
        draggedItem={props.draggedItem}
        features={[CANVAS_FEATURES.ZOOM_WIDGETS, CANVAS_FEATURES.LAYOUT_MANIPULATION_WIDGETS]}
        events={[]}
        layouts={props.layouts || []}
        onSave={() => { }}
      />
    </div>
  );
};

SnappingCanvas.propTypes = {
  layoutManager: PropTypes.object,
  layouts: PropTypes.object
};
SnappingCanvas.defaultProps = {};

export default SnappingCanvas;