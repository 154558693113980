import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Header from "../_builder/Header";
import { useState } from "react";
import { Group, Layer, Line, Stage } from "react-konva";
import { useEffect } from "react";
import PropTypes from "prop-types";

const LayoutEditor = () => {
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const activeVenueId = useSelector(store => store.activeVenue.data.venueId);
  const { layoutId } = useParams();

  return (
    <div >
      <Header heading="Floor Plan" />
      {activeOrgId} - {activeVenueId} - {layoutId}
      <div id="konvaContainer" style={{ height: "50vh", width: "100%", backgroundColor: "lightblue" }}>
        <LayoutEditorView />
      </div>
    </div>
  );
};
LayoutEditor.propTypes = {};
LayoutEditor.defaultProps = {};

let data = { "walls": [{ "id": "0", "loc": [0.10963549253902394, 0.5688056906786214, 2.0731120311971774, 0.5688059784347798], "material": "5" }, { "id": "1", "loc": [0.10963555757017679, 2.396256919774079, 0.10963549253902394, 0.5688056906786214], "material": "11" }, { "id": "2", "loc": [-2.0731121641018797, 2.3962569974487105, 0.10963555757017679, 2.396256919774079], "material": "11" }, { "id": "3", "loc": [-2.0731116134736607, -2.2299682356293147, -2.07311204489259, 2.396256997448706], "material": "8" }, { "id": "4", "loc": [1.1094360750105547, -2.2299676336268024, -2.073111732682942, -2.2299679972107316], "material": "11" }, { "id": "5", "loc": [1.1094360750105547, -2.2299676336268024, 1.1094359498837494, -2.3962567247370274], "material": "5" }, { "id": "6", "loc": [2.0731119256833264, -2.3962562821929647, 1.109435949883741, -2.3962569631556065], "material": "8" }, { "id": "7", "loc": [2.0731122696157547, 0.5688059188301265, 2.0731119256833264, -2.3962562821929647], "material": "8" }, { "id": "8", "loc": [-2.072062507392844, 5.095021844253143, -2.0720617704746735, -2.2296327349094214], "material": "3" }, { "id": "9", "loc": [-7.196903720619181, 5.095021638676419, -2.072062745811518, 5.095022559508848], "material": "3" }, { "id": "10", "loc": [-7.19690352406856, -1.7525277998001783, -7.196904197456339, 5.095021638676355], "material": "11" }, { "id": "11", "loc": [-3.5465972992454677, -1.7525282674068214, -7.196904000905655, -1.7525282766374], "material": "11" }, { "id": "12", "loc": [-3.546597354924365, -2.229633408092868, -3.546597299245404, -1.7525287442439796], "material": "8" }, { "id": "13", "loc": [-2.072061770474483, -2.229634165420896, -3.5465971165057857, -2.2296334080928366], "material": "3" }] }
let data2 = { "walls": [{ "id": "0", "loc": [0.10963549253902394, 0.5688056906786214, 2.0731120311971774, 0.5688059784347798], "material": "5" }, { "id": "1", "loc": [0.10963555757017679, 2.396256919774079, 0.10963549253902394, 0.5688056906786214], "material": "11" }, { "id": "2", "loc": [-2.0731121641018797, 2.3962569974487105, 0.10963555757017679, 2.396256919774079], "material": "11" }, { "id": "3", "loc": [-2.0731116134736607, -2.2299682356293147, -2.07311204489259, 2.396256997448706], "material": "8" }, { "id": "4", "loc": [1.1094360750105547, -2.2299676336268024, -2.073111732682942, -2.2299679972107316], "material": "11" }, { "id": "5", "loc": [1.1094360750105547, -2.2299676336268024, 1.1094359498837494, -2.3962567247370274], "material": "5" }, { "id": "6", "loc": [2.0731119256833264, -2.3962562821929647, 1.109435949883741, -2.3962569631556065], "material": "8" }, { "id": "7", "loc": [2.0731122696157547, 0.5688059188301265, 2.0731119256833264, -2.3962562821929647], "material": "8" }, { "id": "8", "loc": [-2.072062507392844, 5.095021844253143, -2.0720617704746735, -2.2296327349094214], "material": "3" }, { "id": "9", "loc": [-7.196903720619181, 5.095021638676419, -2.072062745811518, 5.095022559508848], "material": "3" }, { "id": "10", "loc": [-7.19690352406856, -1.7525277998001783, -7.196904197456339, 5.095021638676355], "material": "11" }, { "id": "11", "loc": [-3.5465972992454677, -1.7525282674068214, -7.196904000905655, -1.7525282766374], "material": "11" }, { "id": "12", "loc": [-3.546597354924365, -2.229633408092868, -3.546597299245404, -1.7525287442439796], "material": "8" }, { "id": "13", "loc": [-2.072061770474483, -2.229634165420896, -3.5465971165057857, -2.2296334080928366], "material": "3" }] }
let data3 = "{\"walls\": [{\"id\": \"85331094-BA4B-41BE-A80C-DF667B1DCFF0\", \"loc\": [0.8863402, 3.977758, 0.88634, 3.6228466], \"material\": \"steel wall\"}, {\"id\": \"FB4820EC-8785-4989-9439-F111F22FAF35\", \"loc\": [-1.1565526, 3.9777577, 1.6336528, 3.977758], \"material\": \"steel wall\"}, {\"id\": \"8D0D76CC-9924-4093-85B3-2A6B6E3349F2\", \"loc\": [-1.1565536, -2.8694732, -1.1565528, 3.9777577], \"material\": \"dry wall\"}, {\"id\": \"E333F1B9-C4F6-4907-B468-855C35B37903\", \"loc\": [1.6336517, -2.8694732, -1.1565539, -2.869473], \"material\": \"dry wall\"}, {\"id\": \"6AE5431D-6ED5-457F-A8DF-B3E28B122935\", \"loc\": [1.6336526, 3.977758, 1.6336515, -2.8694732], \"material\": \"wooden wall\"}]}"
let data4 = JSON.parse(data3);

function generateLayoutProperties(layout) {
  let maxValue = layout["walls"][0]["loc"][0];
  let minValue = layout["walls"][0]["loc"][0];
  for (const a of layout["walls"]) {
    maxValue = Math.max(maxValue, ...a["loc"]);
    minValue = Math.min(minValue, ...a["loc"]);
  }
  return [minValue, maxValue];
}

function initialScale(layout, arr, vw, vh) {
  let [_, maxValue] = generateLayoutProperties(layout);
  let scaledArr = [];
  let minOf2 = Math.min(vw, vh);

  for (let i = 0; i < arr.length; i++) {
    scaledArr.push((arr[i] * minOf2) / (maxValue));
  }
  return scaledArr;
}

const SingleLayout = ({ layout, conatinerWidth, conatinerHeight }) => {
  return (
    <Group>
      {layout["walls"].map((wall, ind) => {
        return (
          <Line key={ind} width={5} points={initialScale(layout, wall["loc"], conatinerWidth, conatinerHeight)} stroke="black" />
        );
      })}
    </Group>
  );
};
SingleLayout.defaultProps = {};
SingleLayout.propTypes = {
  layout: PropTypes.object,
  conatinerWidth: PropTypes.number,
  conatinerHeight: PropTypes.number
};

const LayoutEditorView = () => {
  const [temp, setTemp] = useState([data4]);
  const [stageInfo, setStageInfo] = useState({
    stageScale: 1,
    stageX: 0,
    stageY: 0
  });
  const [conatinerWidth, setContainerWidth] = useState(0);
  const [conatinerHeight, setContainerHeight] = useState(0);

  const [selectedId, selectShape] = useState(null);

  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
    }
  };

  useEffect(() => {
    const container = document.querySelector("#konvaContainer");
    setContainerWidth(container.offsetWidth);
    setContainerHeight(container.offsetHeight);
  }, []);

  return (
    <Stage onMouseDown={checkDeselect} onTouchStart={checkDeselect} draggable scaleX={stageInfo.stageScale} scaleY={stageInfo.stageScale} x={stageInfo.stageX} y={stageInfo.stageY} width={conatinerWidth} height={conatinerHeight} style={{ border: "1px solid black" }} onWheel={(e) => {
      e.evt.preventDefault();
      const scaleBy = 1.02;
      const stage = e.target.getStage();
      const oldScale = stage.scaleX();
      const mousePointTo = {
        x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
        y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale
      };
      const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

      setStageInfo({
        stageScale: newScale,
        stageX: -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale,
        stageY: -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale
      })
    }}>

      <Layer>
        {temp.map((layout, index) => {
          return (
            <SingleLayout onChange={(newAttrs) => {
              const layouts = temp.slice();
              layouts[index] = newAttrs;
            }} onSelect={() => { selectShape(index) }} isSelected={index === selectedId} key={index} layout={layout} conatinerWidth={conatinerWidth} conatinerHeight={conatinerHeight} />
          );
        })}
      </Layer>
    </Stage>
  );
};
LayoutEditorView.propTypes = {};
LayoutEditorView.defaultProps = {};





export default LayoutEditor;