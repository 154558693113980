import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import REDUX_WORKER from "../../../redux/workers";
import { breadcrumbActions } from "../../../redux/slices";
import Header from "../_builder/Header";
import Select from "react-select";
import "./FloorPlan2.scss";
import { LAYOUT_LIST_OPTIONS } from "./constants";
import { Container, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import LayoutList from "./LayoutList";

const FloorPlan2wrapper = () => {
  const venueId = useSelector(state => state.activeVenue.data.venueId);
  const venuecrumb = useSelector(store => store.breadcrumb.venuecrumb);
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const activeVenue = useSelector(state => state.activeVenue.data);
  const [editorState, setEditorState] = useState({ isOpen: false, layout: null });
  const [uploadImageDialog, setUploadImageDialog] = useState(false);
  const dispatch = useDispatch();

  const [layoutViewFilter, setLayoutViewFilter] = useState(LAYOUT_LIST_OPTIONS[0]);

  useEffect(() => {
    if (venuecrumb.venueId !== activeVenue.venueId)
      REDUX_WORKER.getVenueParent(venueId, activeOrgId);

    dispatch(breadcrumbActions.setData([...venuecrumb.parentPath,
    {
      path: `/organization/${activeOrgId}/venues/${activeVenue?.venueId}`,
      text: activeVenue?.venueName,
      active: false,
    },
    {
      path: `/organization/${activeOrgId}/venues/${activeVenue?.venueId}/floorPlan/`,
      text: "Layouts",
      active: true,
    }
    ]))

  }, [activeVenue, venuecrumb]);

  return (
    <div className="FloorPlan2">
      <Header heading="Floor Plan" />
      <div className="background shadow-sm rounded bg-white py-1 border">
        <Container className="d-flex justify-content-between align-items-center">
          <h4>Layouts</h4>
          <div className="d-flex align-items-center">
            <Select value={layoutViewFilter} onChange={setLayoutViewFilter} className="layoutListOptions" options={LAYOUT_LIST_OPTIONS} />
            <UncontrolledDropdown >
              <DropdownToggle color="primary" className="ml-1" caret>Add</DropdownToggle>
              <DropdownMenu right>
                <DropdownItem className="w-100" onClick={() => { setEditorState({ isOpen: true, layout: null, blank: true }) }}>Create Blank Layout</DropdownItem>
                <DropdownItem className="w-100" onClick={() => { setUploadImageDialog(true); }}>Import 2D Layout</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </Container>
        <Container>
          <LayoutList uploadImageDialog={uploadImageDialog} setUploadImageDialog={setUploadImageDialog} layoutViewFilter={layoutViewFilter.value} editorState={editorState} setEditorState={setEditorState} venueId={venueId} listFilter={layoutViewFilter.value} />
        </Container>
      </div>
    </div>
  );
};
FloorPlan2wrapper.propTypes = {};
FloorPlan2wrapper.defaultProps = {};

export default FloorPlan2wrapper;