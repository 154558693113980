import { useSelector } from "react-redux";
import ChartCardWithAreaCharts from "./ChartCardWithAreaCharts";

const OnlineInfraCharts = ({ categories, series, colors, isLoading, height, width,ticks }) => {
  const range = useSelector(store => store.activeOrg.meta.dateRange);
  
  return (
      <ChartCardWithAreaCharts
          title="Online Infrastructure"
          categories={categories}
          series={[series]}
          height={height}
          ticks={ticks}
          xlabel="Infrastructure Count"
          ylabel={range>0 ? "Date" : "Time"}
          colors={colors}
          width={width}
          isLoading={isLoading}
      />
  );
};

export default OnlineInfraCharts;