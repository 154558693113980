/***
 *
 * Controller class for user.
 * @file Actions.js
 * @description Actions component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Col, CustomInput, Row, Spinner, Table, UncontrolledTooltip } from "reactstrap";
import { make_toast } from "../../helpers";
import Select from "react-select";
import { selectThemeColors } from "@utils";
import createRequest, { services } from "../../services";
// import PropTypes from 'prop-types';
import "./Actions.scss";
import { Check, X } from "react-feather";
import useAutoclear from "../../hooks/useAutoclear";
import { CatchedWebError } from "../../configs";
import { make_custom_toast } from "../../helpers/toasts";
import { CLICK_TO_SCAN, INFORMATION_ELEMENTS_IES, OVERRIDE_DFS } from "../../utility/tooltip-config-global";
import Toggle from "../Toggle";
import { reactselectTheme } from "../../utility/constants";

const CustomLabel = () => {
  return (
    <React.Fragment>
      <span className='switch-icon-left'>
        <Check size={14} />
      </span>
      <span className='switch-icon-right'>
        <X size={14} />
      </span>
    </React.Fragment>
  )
};

const bandwidthOptions = [
  { label: "20 MHz", value: "20" },
  { label: "40 MHz", value: "40" },
  { label: "80 MHz", value: "80" },
  { label: "160 MHz", value: "160" },
];

const IEs = [
  { label: "SSID (0)", value: "0" },
  { label: "SUPP_RATES (1)", value: "1" },
  { label: "FH_PARAMS (2)", value: "2" },
  { label: "DS_PARAMS (3)", value: "3" },
  { label: "CF_PARAMS (4)", value: "4" },
  { label: "TIM (5)", value: "5" },
  { label: "IBSS_PARAMS (6)", value: "6" }
];

const downloadIE = (ssid) => {
  //console.log(ssid);
  const fileBlob = new Blob([JSON.stringify(ssid, null, "\t")], { type: "text/plain" });
  const url = URL.createObjectURL(fileBlob);

  const link = document.createElement("a");
  link.download = ssid.ssid + ".json";
  link.href = url;
  link.click();
}

const Actions = (props) => {

  const infraItemId = useSelector(store => store.oneInfra.data.infraItemId);
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [firmwareListLoading, setFirmwareListLoading] = useState(false);
  const [currFirmwareLoading, setCurrFirmwareLoading] = useState(false);
  const [currFirmware, setCurrFirmware] = useState("");
  const [firmwareList, setFirmwareList] = useState([]);
  const [uriToFlash, setUriToFlash] = useState("");

  const [overrideDFS, setOverrideDFS] = useState(false);
  const [bandwidth, setBandwidth] = useState(20);
  const [ies, setIes] = useState([2]);

  const stopLoading = () => { setIsLoading(false); };
  const startLoading = () => { setIsLoading(true); };

  const setSuccess = (resp) => { setResponse(resp.data) };
  const dispatch = useDispatch();

  const runCommand = () => {
    startLoading();
    props.modalLoading(true);
    let requestbody = { cmd: props.runCommand };
    if (props.runCommand === "upgrade") {
      requestbody.uri = uriToFlash;
    }
    if (props.runCommand === "wifi-scan") {
      requestbody.override_dfs = overrideDFS;
      requestbody.bandwidth = bandwidth;
      requestbody.ies = [...ies];
    }
    const { run } = createRequest(services.infra.RUN, [infraItemId], requestbody);
    run().then(
      (res) => {
        if (props.runCommand === "upgrade") {
          setUriToFlash("");
        }
        else if (["reboot", "blink", "reset"].includes(props.runCommand)) {
          make_custom_toast('success', 'Infrastructure', res.data.message || 'Command Executed');
          if (props.hasOwnProperty('hideModal')) {
            props.hideModal()
          }
        }
        setSuccess(res);
      })
      .catch((err) => {
        let x = new CatchedWebError(err);
        make_toast('error', x.message);
      })
      .finally(() => {
        stopLoading();
        props.modalLoading(false);
      });
  }

  const fetchFirmWareData = useCallback(() => {
    if (infraItemId) {
      setFirmwareListLoading(true);
      setCurrFirmwareLoading(true);

      // FETCH CURRENT FIRMWARE
      const { run: run2 } = createRequest(services.infra.DETAILS, [infraItemId]);
      run2()
        .then(response => {
          setCurrFirmware(response.data.firmware || "[NO FIRMWARE FOUND]");
        })
        .catch(err => {
          let x = new CatchedWebError(err);
          setCurrFirmware(`[NO FIRMWARE FOUND]: ${x.message}`);
        })
        .finally(() => {
          setCurrFirmwareLoading(false);
        })

      // FETCH LIST OF AVAILABLE FIRMWARE
      const { run } = createRequest(services.infra.FIRMWARE, [infraItemId]);
      run()
        .then(response => {
          setFirmwareList([...response.data]);
        })
        .finally(() => {
          setFirmwareListLoading(false);
        })
    }
  }, [infraItemId]);

  useEffect(() => {
    if (props.runCommand === "upgrade")
      fetchFirmWareData();
  }, [props.runCommand])

  useAutoclear((!!response && props.runCommand !== "wifi-scan"), () => setResponse(null));

  if (props.runCommand === "wifi-scan")
    return (<div className="Actions-Component" data-testid="Actions">
      <h2 className="pb-1">Wi-Fi Scan</h2>
      {
        !response ? <>
          <Row className="my-1">
            <Col xs={4} className="d-flex align-items-center">
              <span>Override DFS</span> &nbsp; <span className="material-symbols-outlined cursor-pointer" id="OVERRIDE_DFS">help</span>
              <UncontrolledTooltip target="OVERRIDE_DFS">
                {OVERRIDE_DFS}
              </UncontrolledTooltip>
            </Col>
            <Col xs={8} className="text-left">
              <Toggle value={overrideDFS} onClick={(e) => { setOverrideDFS(prevState => !prevState ); }} displayText={false} />
            </Col>
          </Row>
          <Row className="my-1">
            <Col xs={4} className="d-flex align-items-center">
              Bandwidth
            </Col>
            <Col xs={8}>
              <Select options={bandwidthOptions}
                styles={reactselectTheme}
                value={bandwidthOptions.filter(item => Number(item.value) === bandwidth)}
                onChange={(e) => { setBandwidth(Number(e.value)); }}
              />
            </Col>
          </Row>
          <Row className="my-1">
            <Col xs={4} className="d-flex align-items-center">
              <span>IEs</span> &nbsp; <span className="material-symbols-outlined cursor-pointer" id="INFORMATION_ELEMENTS_IES">help</span>
              <UncontrolledTooltip target="INFORMATION_ELEMENTS_IES">
                {INFORMATION_ELEMENTS_IES}
              </UncontrolledTooltip>
            </Col>
            <Col xs={8}>
              <Select options={IEs}
                styles={reactselectTheme}
                isMulti
                value={IEs.filter(ie => ies.includes(Number(ie.value)))}
                onChange={(e) => { setIes(e.map(eItem => Number(eItem.value))); }}
              />
            </Col>
          </Row>
          <div className="my-1 text-right">
            <Button color="outline-danger" className="mr-1" disabled={isLoading} onClick={() => { props.setRunCommand(null); }}>Cancel</Button>
            <Button id="CLICK_TO_SCAN" color="primary" disabled={isLoading} onClick={() => { runCommand() }}>
              {isLoading ? <Spinner size="sm" /> : "Continue"}
            </Button>
            <UncontrolledTooltip target="CLICK_TO_SCAN">
              {CLICK_TO_SCAN}
            </UncontrolledTooltip>
          </div>
        </> : <div className="wifi-scan">
          {response.data.length ? <Table className="table shadow-sm bg-white">
            <thead>
              <tr>
                <th className="bg-color">SSID</th>
                <th className="bg-color">CHANNEL</th>
                <th className="bg-color">IEs</th>
              </tr>
            </thead>
            <tbody>
              {response?.data?.map((network, i) => {
                return <tr className="hoverable" key={i}>
                  <td>{network.ssid || network.bssid}</td>
                  <td>{network.channel}</td>
                  <td><div className="material-symbols-outlined cursor-pointer"
                    onClick={() => {
                      downloadIE(network);
                    }}
                  >download</div></td>
                </tr>
              })}
            </tbody>
          </Table> :
            <div className="text-center">
              <h4>No SSIDs found</h4>
            </div>}
        </div>}
    </div>)
  else if (props.runCommand === "upgrade")
    return (<div className="Actions-Component" data-testid="Actions">
      <h2 className="pb-1">Firmware Update</h2>
      <Alert className="w-100 text-left" isOpen={response !== null} color={response && response.status === "pending" ? "warning" : "success"} toggle={() => { setResponse(null); }}>
        <div className="alert-body">
          {response && response.status === "pending" ? "PENDING" : "DONE"}: {response && response.message}
        </div>
      </Alert>
      <p style={{ fontSize: "1.1em", fontWeight: "400" }}>Installed Firmware</p>
      <p className="pb-1"><strong>{currFirmwareLoading ? <em>Loading...</em> :
        (currFirmware === "[NO FIRMWARE FOUND]" ? <small className="text-danger">*FIRMWARE DATA UNAVAILABLE</small> : currFirmware)}
      </strong></p>
      {firmwareListLoading ? <Spinner size="sm" /> :
        <React.Fragment>
          <p style={{ fontSize: "1.1em", fontWeight: "100" }}>Available Firmware</p>
          <Select onChange={(e) => { setUriToFlash(e.value); }} options={firmwareList.map(image => {
            return { label: image?.revision, value: image?.uri };
          })} className="react-select" classNamePrefix="select" theme={selectThemeColors} />
        </React.Fragment>
      }
      <div className="my-1 text-right">
        <Button color="outline-primary" className="mr-1" disabled={isLoading} onClick={() => { props.setRunCommand(null); }}>Cancel</Button>
        <Button color="primary" disabled={uriToFlash === "" || isLoading} onClick={() => { runCommand(); }}>{isLoading ? <Spinner size="sm" /> : "Continue"}</Button>
      </div>
    </div>)
  else if (!!props.runCommand)
    return (
      <div className="Actions-Component" data-testid="Actions">
        <Alert className="w-100 text-left" isOpen={response !== null} color={response && response.status === "pending" ? "warning" : "success"} toggle={() => { setResponse(null); }}>
          <div className="alert-body">
            {response && response.status === "pending" ? "PENDING" : "DONE"}: {response && response.message}
          </div>
        </Alert>
        Are you sure you want to perform this action?
        <div className="my-2 text-right">
          <Button color="primary" className="mr-1" outline disabled={isLoading} onClick={() => props.setRunCommand(null)}>Cancel</Button>
          <Button color="warning" disabled={isLoading} onClick={() => {
            runCommand();
          }}>{isLoading ? <Spinner size="sm" /> : "Run"}</Button>
        </div>
      </div>
    );

  return (<></>);
};

Actions.propTypes = {};

Actions.defaultProps = {};

export default Actions;
