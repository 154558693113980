/**
 * Identity Service
 * @file identity.service.js
 * @description API Endpoints related to identity management
 * @author Utkarsh Gupta
 * @since 17 Jul 2022
 */

import Service from "./Service";
import { B } from "../helpers";

/** Services related to identity management */
const identity = {
  GET_SELF: new Service(() => B("identity"), "GET"),
  BLOCK: new Service((id) => B(`identity/${id}/block`), "PUT"),
  INVITE: new Service(() => B("identity"), "POST"),
  USER_LOGIN: new Service(() => B("identity/login"),"POST"),
  USER_LOGOUT: new Service(() => B("identity/logout"),"POST"),
  USER_REFRESH: new Service(() => B("identity/refreshtoken"),"POST"),
  USER_FORGOT: new Service(() => B("identity/forgot"),"POST"),
  USER_VALIDATE: new Service((id) => B(`identity/forgot/validate?forgotId=${id}`),"GET"),
  USER_RESET: new Service((id) => B(`identity/forgot/${id}`),"put"),
  EDIT: new Service((identityId) => B(`identity/${identityId}`), "PUT"),
  GET: new Service((identityId) => B(`identity/${identityId}`), "GET"),
  DELETE: new Service((identityId) => B(`identity/${identityId}`), "DELETE"),
  GET_LOGO: new Service((identityId,purpose="get") => B(`identity/${identityId}/logo?purpose=${purpose}`),"GET"),
  DELETE_LOGO: new Service ((identityId) => B(`identity/${identityId}/logo`),"DELETE"),
};

export default identity;
