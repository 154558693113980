/***
 *
 * Controller class for user.
 * @file Modal.js
 * @description Modal component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React from "react";
import { Modal,ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const DeleteModal = (props) => {
  return (
    <div className="Modal" data-testid="Modal">
      <Modal centered isOpen={props.isOpen} toggle={() => {props.toggle(false);}}>
        <ModalHeader className="bg-white" toggle={() => { props.toggle(false); }}>
        </ModalHeader>
            <h2 className="mx-auto headings">{props.heading}</h2>
            <ModalBody className="d-flex flex-column align-items-center p-3">
              <span class="material-symbols-outlined display-3 text-warning">
                {props.icon}
              </span>
              <div className="text-center font-weight-bolder">{props.info}</div>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-center border-0">
              {props.children}
            </ModalFooter>
          </Modal>
    </div>
  );
};

DeleteModal.propTypes = {};

DeleteModal.defaultProps = {};

export default DeleteModal;
