//  Dashboard
export const NETWORK_VIEW_TOOLTIP = 'Network View';
export const BUSINESS_VIEW_TOOLTIP = 'Business View';
export const DEPLOYMENT_VIEW_TOOLTIP = "Deployment View";


//  Infrastructure 
export const AP_REBOOT = "Reboot access point";
export const AP_RESET = "Revert access point to initial out of box state. You'll be notified after reset is completed";
export const SWITCH_REBOOT = "Reboot switch";
export const SWITCH_RESET = "Revert switch to initial out of box state. You'll be notified after reset is completed";
export const BLINK = "Blink LED's, assist with remote identification";
export const SCAN = "Perform a wireless scan on all channels. You'll be notified after scan is completed (AP out of service for 30 seconds)";
export const CONNECT_RTTY = "Connect to the infra via RTTY";
export const OVERRIDE_DFS = "Scan DFS channels even when DFS is disabled in the RF Profile.";
export const INFORMATION_ELEMENTS_IES = "Information elements are blobs of data of varying size. Each data blob is tagged with a type number and a size, and it is understood that an information element of a certain type has its data field interpreted in a certain way.";
export const WIFI_SCAN = "This action will perform scan for neighboring APs over all channels. The AP will move to each channel for several seconds while it gathers the neighboring information. You'll be notified after scan is completed.";
export const CLICK_TO_SCAN = "Perform a wireless scan on all channels. You'll be notified after scan is completed (AP out of service for 30 seconds)";
export const TRUNK_LABEL = "VLANs to trunk. Single value, comma separated values and ranges (e.g. 4, 55, 63, 722-746)";


// Organization
export const ALARM_OPERATION = "Alarm";
export const TICKET_OPERATION = "Ticket";
export const FIRMWARE_OPERATION = "Firmware";
export const IMAGE_RESOLUTION = "Recommended resolution: 250px X 250px Maximum size: 5MB";
export const OPERATION_FAILED = 'Operation Failed';
export const OPERATION_SUCCESS = 'Operation completed successfully';


// Marketplace
export const OPEN_PROPOSAL = "In progress - waiting for Shasta to approve";
export const EXPENSIVE_OPEN_PROPOSAL = "Following are the expensive Open Proposal"
export const TOTAL_ONE_TIME_AMOUNT_TOOLTIP = "One-time amount paid for the proposal."
export const SPARE_ONE_TIME_AMOUNT_TOOLTIP = "One-time amount paid for the proposal."
export const RECURRING_AMOUNT_TOOLTIP = "Recurring amount, charged every month"


// Firmware Rollout
export const RERUN_BUTTON_REPORT = "Retry update for all 'Failed' or 'Offline' infrastructure "
export const RSSI_INFO = "Information updates once every 2 minutes"
export const REFERENCE_FIRMWARE_UPDATE_DATE = "Reference date time for this venue has already passed. Schedule date time has been adjusted accordingly."


// Network
export const SSID = "This is the SSID for your network. It will be visible to the users and allows them to connect to the network.";
export const NET_DESCRIPTION = "Brief description about the network"
export const NET_TYPE_OPEN = "Network type is Public (Open), anyone can access this network and traffic is NOT encrypted over the air.";
export const NET_TYPE_PASS = "Network type is Passphrase (PSK), single passphrase used to connect, traffic is encrypted.";
export const NET_TYPE_MPSK = "Network type is Multi-PSK (MPSK with external AAA), per client passphrase based on RADIUS integration, traffic is encrypted";
export const NET_TYPE_ENT = "Network type is Enterprise (802.1x with external AAA), username & password are required to connect, traffic is encrypted";
export const NET_TYPE_SAE = "This network will use a more secure PSK-based authentication mechanism and will mitigate security issues even if you choose a weak pre-shared key.";
export const TARGET_HIDE_NETWORK = "When enabled, the SSID would not be broadcasted and will not be visible to the clients, in order to connect the user would need to know the network name.";
export const RADIUS_SETTINGS = {
    ENABLE_MAC_AUTH: "Enabling MAC authentication will send an authentication request per client MAC address that connects to the AAA server",
    AAA_SERVER_IP: "AAA server IP address (IPv4)",
    AAA_SERVER_AUTH_PORT: "AAA server Authentication port (e.g. 1812)",
    AAA_SERVER_ACC_PORT: "AAA server Accounting port (e.g. 1813)",
    AAA_SERVER_SECRET: "AAA server shared secret",
    AAA_SERVER_INT_UPDATE: "Accounting Interim update interval in seconds",
    AAA_SERVER_NAS_ID_STRING: "Custom string, will be sent to the AAA server as the NAS-ID attribute. If empty, the Venue RADIUS Location value will be sent.",
    AAA_SERVER_NAS_ID: "NAS-Identifier string for RADIUS messages. When used, this should be unique to the NAS within the scope of the RADIUS server. Leaving this field blank will use the venue address for NAS-ID",
    ENABLE_COA: "Enable Change of Authorization (CoA), allows to dynamically modify an already authenticated client",
    COA_IP: "IP address allowed to send CoA messages",
    COA_PORT: "UDP Port CoA requests will be sent to",
    COA_SECRET: "Shared secret used for CoA messages"
}
export const VLAN = "All traffic for this network would be tagged with VLAN (1-4086), in case value is empty, traffic will be untagged";
export const DOWNSTREAM = "Download rate limit, applied separately to each client that connects to this network (per Client limit)";
export const UPSTREAM = "Upload rate limit, applied separately to each client that connects to this network (per Client limit)";
export const CLIENT_ISOLATION = "When Off, clients can communicate with each other locally. AP mode stops clients connected to the same AP from communicating with each other and Network mode uses layer 2 ACLs to stop clients across the network (different AP’s within the venue) from communicating with each other";
export const OPEN_IMG = "https://www.pngkey.com/png/full/276-2762850_04-novwifi-symbol-white-wifi-icon-transparent-white.png";
export const NET_PASSPHRASE = "A passphrase clients can use for connecting to the network.";
export const NET_SECURITY = "WiFi secruity standard for your network.";
export const VLAN_IMG = "https://activereach.net/wp-content/uploads/2019/07/vlan.png";


//  RRM Management
export const RRM_SCAN_PERIOD = "Time between off channel scans (milliseconds)";
export const RRM_SELECTION_PERIOD = "Time between channel selection re-evalution (seconds)";
export const RRM_BETTER_PERC = "Threshold in percentages for avoiding un-necessary channel changes. Channel change event will occur only if the alternative channel airtime usage ratio is smaller than current channel airtime usage ration by RRM_BETTER_PERC % (for example, by 20%). Higher values (i.e., 50% and above) will avoid channel changes at the risk of being less optimal (in terms of channel airtime availability). Alternatively, smaller values (i.e., 10% and below) will better cope with changes on the channels airtime usage at the cost of frequent channel changes.";
export const RRM_DIS_FACTOR_IDLE = "Discount factor that sets the accounted history on channel usage for idle channels. Typical channel usage can be characterized by idle and busy periods. To avoid channel switch to a channel that is on Idle period (with possibly high channel usage when on busy periods), it is recommended to take a longer history (i.e., RRM_DIS_FACTOR_IDLE=0.9 or above), at the cost of postponing switching to free channels.";
export const RRM_DIS_FACTOR_BUSY = "Discount factor that sets the accounted history on channel usage for busy channels. Typical channel usage can be characterized by idle and busy periods. To filter spikes or deeps on channel usage an RRM_DIS_FACTOR_BUSY=0.8 is recommended. Low values of this factor (i.e., 0.5 and below) will adapt faster to changes in channel usage but may wrongly cause channel switch based on momentary channel spikes or deeps. Large values (i.e., 0.9 and above) will smoothen channel airtime usage and may result in longer time until switching to a better channel.";
export const RRM_DIS_BUSY_CUR_FACTOR = "Discount factor that sets the accounted history on the current channel. To filter spikes or deeps on the current channel usage an RRM_DIS_BUSY_CUR_FACTOR=0.5 is recommended. Low values of this factor (i.e., 0.2 and below) will adapt faster to changes in current channel usage but may wrongly cause channel switch based on momentary channel spikes or deeps. Large values (i.e., 0.9 and above) will smooth current channel airtime usage and may result in longer time until switching to a better channel. Note that current channel usage is averaged over the selection periods.";
export const NEIGHBOUR_REPORTING = "Switch to enable and disable the reporting of identified neighbors to the cloud controller.";
export const FTM_RESPONDER = "Publish fine timing measurement (FTM) responder functionality on this BSS.";
export const STATIONARY_AP = "Stationary AP config indicates that the AP doesn't move.";
export const RRM_DWELL_TIME = "Sets the time that the AP goes for passive scanning of off channels. Setting the time to 20mSec is recommended. Below 20mSec the driver is not able to capture the airtime usage on scanned channels. Above 50 mSec will add packet delays and may cause service interuptions.";
export const PUBLIC_IP = "Public IP visible by the Cloud (source IP from HTTPS traffic), typically would be the NAT address on the site gateway for the management traffic of the Infrastructure "
export const PRIVATE_IP = "Local management address used by the Infrastructure on the uplink interface"
export const DFS_IDLE_PPS = "Threshold on transmitted and received packet per second, below which an AP is considered idle and can switch to a DFS channel";
export const DFS_DIS_FAC = "Discount factor that sets the accounted history on idle probability in a given hour for DFS switch ";
export const DFS_BETTER_PERC = "Threshold on the busy by others airtime percentage above which the AP decides to move to a non-DFS channel";
export const DFS_COOLOFF = "Time to cool off a channel after a radar event or CAC failure on a DFS channel in sec";

//  Profiles
export const BAND = "Specifies the wireless band to configure the radio for.";
export const BANDWIDTH = "Specifies a narrow channel width in MHz.";
export const WIFI_MODE = "Specify the maximum Wi-Fi generation to support on this radio.   This can be Wi-Fi 4 (802.11n), Wi-Fi 5 (802.11ac), or Wi-Fi 6 (802.11ax).";
export const CH_SELECTION = "Select how the channel is assigned.   Auto will enable automatic channel selection, and Manual will allow you to choose which channel to use"
export const CH = "The channel this radio should operate on";
export const BLOCKED_CH = "These channels will be excluded from the auto channel selection algorithm"
export const CH_WIDTH = "Specify the maximum channel width allowed on this radio";
export const MIMO = "This option allows configuring the antenna pairs that shall be used. This is just a hint for the AP. If the requested value is not supported then the AP will use the highest common denominator.";
export const LEGACY = "Allow legacy 802.11b data rates.";
export const MSR_TIP = "Specify the minimum supported rate on this radio.  Clients that cannot operate at or above this rate will not be allowed to connect."
export const BSSID = "Enabling this option will make the PHY broadcast its BSSs using the multiple BSSID beacon IE.";
export const TX_POWER = "Specify the TX Power mode of this radio.   Auto enables automatic selection of the power.   High will transmit at the maximum power level allowed, medium and low can be used to lower the power levels in high-density environments.";
export const CLIENTS = "Specify the maximum number of clients that may connect to this radio.  The count is accumulative across all Networks enabled on this radio.";
export const DFS = "Enable the ability to use DFS channels which require RADAR detection mechanisms"
export const PROFILE_NAME = "Profile Name would be used to identify your profile settings"
export const DESCRIPTION = "Brief description of Profile";
export const QOS = "This section describes the Quality of upload and download bandwith of the infrastructure.";
export const ATF = "Airtime Fairness works using sliding averages of total packets per second to approximate influence to the scheduler.";
export const STEERING = "Wifi Steering determines the operating parameters of RRM on the Infrasturcture.";


//SWITCH PROFILE
export const PORT802 = "Specify the ports on which 802.1X is enabled";
export const IP802 = "AAA server IP address";
export const AUTH802 = "Authentication port of AAA server";
export const SECRET802 = "AAA server shared secret"

export const UPLINK = "Configure uplink ports. Enter comma separated values and ranges (e.g. 4, 10, 12-42)"
export const DOWNLINK = "Configure downlink ports. Enter comma separated values and ranges (e.g. 4, 10, 12-42)"


// FIRMWARE ROLLOUT
export const SEND_EMAIL = "Avoid sending schedule creation/updation email to user by toggling this switch off"

// FIRMWARE MATRIX
export const RELEASE_NAME = "E.g. formats: 2.12, 2.43.1, 2.2.1.3, 2.2-ca, 2.4-USA"