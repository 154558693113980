/***
 *
 * Controller class for user.
 * @file ExpTable.js
 * @description ExpTable component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React from "react";
// import PropTypes from 'prop-types';
import "./ExpTable.scss";
import { useState } from "react";
import { CollapseIcon, ExpandIcon } from "../../assets/images/icons/Icons";

const ExpCell = (props) => {
  return (
    <div className="ExpCell"
      style={{
        paddingLeft: (props.showLines && props.level >= 1) ? `${2.95 * (props.level-1.45)}rem` : "0",
        minWidth: props.width
      }}
    >
      <div className={`${props.showLines ? (props.last ? "border-left-cell-half " : "border-left-cell ") : (props.level == null || props.level<=1) ? (props.expand ? "parent-cell-expand " : "parent-cell ") : ""} d-flex align-items-center h-100 position-relative ` + props.className}>
        {
          props.showLines && Array.from({ length: props.level - 1 }, (v, i) => i).reverse().map((val, key) => {
            // {console.log(val, "--->",props.parentLast, "--->",props.parentLast[val])}
            if (val>0 && (props.parentLast && (!props.parentLast[val])))
              return <div 
              className="straight-lines-tree-cell"
              style={{
                left: `${(-1 * 2.95 * (props.level - val - 1))}rem`,
              }}
              key={`${key}--exp-cell-key`}
              ></div>

          })
        }
        {props.showLines && <div
          className="horizontal-dash-tree-cell"
        />}
        <div className={props.hide?"d-none":""} style={{ padding: props.showLines ? 0 : "0 0.85rem" }}>
          {props.children}
        </div>
      </div>
    </div>)
}

const ExpRow = (props) => {
  const [expand, setExpand] = useState((props?.expand && props?.level > 1 && props?.level < 4));
  let level = props.level ?? 1;
  const firstCell = React.cloneElement(props.children[0], { expand })
  return (
    <>
      <div className="ExpRow ">
        <div className={((level == 1 && !expand) ? "parent-cell" : "")} style={{ width: "5px", display: "table-cell", verticalAlign: "middle" }}>
          {props.expand ?
            <CollapseIcon width={14} height={14} onClick={() => setExpand(!expand)} />
            : <ExpandIcon width={14} height={14} onClick={() => setExpand(!expand)} />
          }
        </div>
        {firstCell}
        {props.children.slice(1)}
      </div>
      {
        expand &&
        <>
          <ExpRowList level={level + 1} expand={true}>
            <ExpCell showLines>
              <div className="pl-1">
                <h5>{level + 1}</h5>
                <div className="font-weight-bolder">EXPANDED ROW</div>
              </div>
            </ExpCell>
            <ExpCell>2</ExpCell>
            <ExpCell>3</ExpCell>
            <ExpCell>4</ExpCell>
          </ExpRowList>
        </>

      }
    </>)
}

const ExpRowList = (props) => {
  const newChildren = props?.children?.map(element => React.cloneElement(element, { level: props.level }))
  // console.log("Old Children--->", props.children);
  // console.log("New Children--->", newChildren);
  return (
    <ExpRow level={props.level} expand={props.expand}>
      {newChildren}
    </ExpRow>)
}

const TableHead = (props) => {
  return (
    <div className="table-head" expand={false}>
      {/* <TableCol></TableCol> */}
      {props.children}
    </div>)
}
const TableCol = (props) => {
  return (
    <div className={"table-col " + props.className} expand={false} style={{
      minWidth: props.width,
      padding: "0 0.5rem"
    }}>
      {props.children}
    </div>)
}

const ExpTable = (props) => {
  return (
    <div style={{ overflow: "auto", minHeight: "150px" }}>
      <div className="ExpTable" data-testid="ExpTable">
        {props.children}
      </div>
    </div>
  );
};

ExpTable.propTypes = {};

ExpTable.defaultProps = {};

export { ExpCell, TableHead, TableCol }
export default ExpTable;
