/***
 *
 * Controller class for user.
 * @file LightBadge.js
 * @description LightBadge component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React from "react";
// import PropTypes from 'prop-types';
import "./LightBadge.scss";

const LightBadge = (props) => {
  const preColor = {
    "success": "#13A877",
    "danger": "#ea5455",
    "secondary": "#5e5873",
    "warning": "#ff9f43",
    "primary": "#3E5ECF",
    "info": "#00cfe8",
    "dark": "#4b4b4b"
  }
  return (
    <div {...props} className={"LightBadge " + props.className} data-testid="LightBadge">
      <div className={"rounded light-badge-custom " + props.innerClassName}
        style={{
          backgroundColor: `${preColor[props.color] ?? props.color}1F`,
          border: `1px solid ${preColor[props.color] ?? props.color}`,
          color: preColor[props.color] ?? props.color,
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

LightBadge.propTypes = {};

LightBadge.defaultProps = {};

export default LightBadge;
