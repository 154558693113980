import { useCallback, useEffect, useRef, useState } from "react";
import { EditableLayout, LayoutData } from "../utils";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import wreAction from "../../../../redux/slices/wre.slice";
import { LAYOUT_TYPE, LAYOUT_TYPES } from "../constants";
import { make_custom_toast } from "../../../../helpers/toasts";
import { checkInfraType } from "../helper";

function useLayoutsManager(layouts) {
  const dispatch = useDispatch()
  const currentInsertedLayout = useSelector(store => store.wre.currentInsertedLayout)
  const [saveType, setSaveType] = useState(layouts.length === 0 ? "create" : "edit");

  const [selectedWall, setSelectedWall] = useState({ layoutUuid: null, wallId: null });
  const [selectedLayout, setSelectedLayout] = useState({ layoutUuid: null });
  const [selectedAp, setSelectedAp] = useState(null);
  const [selectedSwitch, setSelectedSwitch] = useState(null);
  const [selectedCableDrop, setSelectedCableDrop] = useState(null);

  const [editableLayout, setEditableLayout] = useState(new EditableLayout(layouts));
  const [lineStartPoint, setLineStartPoint] = useState(null)
  const [lineEndPoint, setLineEndPoint] = useState(null)
  const [currentZoomValue, setCurrentZoomValue] = useState((layouts.length > 0 && layouts[0].layoutType == LAYOUT_TYPES.IMAGE) ? 0.8 : 0.40
  ); // this is used to make zoom level equal in all  canvas types i.e wall annotation , ap placement and others.
  const [currentOffset, setCurrentOffset] = useState({})
  const firstRender = useRef(true)



  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }
    dispatch(wreAction.setUnsavedLayout(true))
  }, [editableLayout])

  const resetSelectedEntities = () => {
    setSelectedLayout({ layoutUuid: null });
    setSelectedAp(null);
    setSelectedSwitch(null)
    setSelectedCableDrop(null);

  }

  const updateEditableLayout = (updatedLayout) => {
    // console.log('rdebug ap newData layout:', updatedLayout.infraPositions)
    let ifpos = {};
    let cableDrop = {}
    if (updatedLayout && Object.keys(updatedLayout).length > 0 && updatedLayout.infraPositions) {
      for (const infraPos of updatedLayout.infraPositions) {
        ifpos[uuidv4()] = infraPos;
      }
    }
    if (updatedLayout && Object.keys(updatedLayout).length > 0 && updatedLayout.cableDrop) {
      for (const cable of updatedLayout.cableDrop) {
        cableDrop[uuidv4()] = cable;
      }
    }
    setEditableLayout(eLayout => {
      const cLayout = eLayout.copy();
      cLayout.infraPositions = ifpos;
      cLayout.cableDropList = cableDrop
      return cLayout;
    })
    firstRender.current = true
    dispatch(wreAction.setUnsavedLayout(false))
  }

  const insertLayout = useCallback((layout, point) => {
    dispatch(wreAction.setCurrentInsertedLayout(layout.layoutType == LAYOUT_TYPES.IMAGE ? LAYOUT_TYPES.IMAGE : LAYOUT_TYPES.SINGLE_LIDAR_SCAN))
    // console.log('rdebug trigger 10...valuePassed:', layout.layoutType == LAYOUT_TYPES.IMAGE ? LAYOUT_TYPES.IMAGE : LAYOUT_TYPES.SINGLE_LIDAR_SCAN)
    setEditableLayout(eLayout => {
      const cLayout = saveType === "create" ? eLayout.copyWithoutInfra() : eLayout.copy();
      // hinthere, here layout.layoutJson.component list should have one element, adding 0th position
      cLayout.layouts[uuidv4()] = new LayoutData(layout, point, layout.imageLayoutLink, layout.layoutType, layout.layoutJson.components[0]);
      return cLayout;
    })
  }, [saveType]);

  const removeLayout = useCallback((layoutUuid) => {
    // console.log('rdebug trigger 11 inside remove currentInserted:', currentInsertedLayout)
    if (currentInsertedLayout == LAYOUT_TYPES.IMAGE) {
      dispatch(wreAction.setCurrentInsertedLayout(LAYOUT_TYPES.NONE))
      // console.log('rdebug trigger 4...')

    }

    // console.log('rdebug trigger 12...')
    setEditableLayout(eLayout => {
      // console.log('rdebug trigger 13...')

      const cLayout = saveType === "create" ? eLayout.copyWithoutInfra() : eLayout.copy();
      const layoutLength = Object.keys(cLayout?.layouts).length
      if (currentInsertedLayout == LAYOUT_TYPES.SINGLE_LIDAR_SCAN && layoutLength > 1) { // have more than 1 scan layout on display
        dispatch(wreAction.setCurrentInsertedLayout(LAYOUT_TYPES.SINGLE_LIDAR_SCAN))
        // console.log('rdebug trigger 5...')
      }
      else if (currentInsertedLayout == LAYOUT_TYPES.SINGLE_LIDAR_SCAN && layoutLength == 1) {
        dispatch(wreAction.setCurrentInsertedLayout(LAYOUT_TYPES.NONE))
        // console.log('rdebug trigger 6...')
      }

      delete cLayout.layouts[layoutUuid];
      setSelectedWall({ layoutUuid: null, wallId: null });
      setSelectedLayout({ layoutUuid: null });
      return cLayout;
    })
  }, [saveType, currentInsertedLayout]);

  const translateLayout = (layoutUuid, toPoint) => {
    setEditableLayout(eLayout => {
      const cLayout = eLayout.copy();
      cLayout.layouts[layoutUuid].transform.position = toPoint;
      return cLayout;
    })
  };


  const rotateLayout = (layoutUuid, toAngle) => {
    setEditableLayout(eLayout => {
      const cLayout = eLayout.copy();
      cLayout.layouts[layoutUuid].transform.rotation = toAngle;
      return cLayout;
    })
  };

  const setWallMaterial = (layoutUuid, wallId, toMaterial) => {
    setEditableLayout(eLayout => {
      const cLayout = eLayout.copy();
      let ind = cLayout.layouts[layoutUuid].walls.findIndex(wall => wall.id === wallId);
      if (ind !== -1) {
        cLayout.layouts[layoutUuid].walls[ind].material = toMaterial;
      }
      return cLayout;
    })
  }

  const insertAp = (type, point) => {
    setEditableLayout(eLayout => {
      const cLayout = eLayout.copy();
      const uuidAp = uuidv4()
      cLayout.infraPositions[uuidAp] = {
        infraTypeId: type,
        infraCategory: 1,
        location: {
          x: point.x,
          y: point.y
        }
      };
      setSelectedAp(uuidAp)
      return cLayout;
    })
  };
  const insertSwitch = (type, point) => {
    setEditableLayout(eLayout => {
      const cLayout = eLayout.copy();
      const uuidAp = uuidv4()
      cLayout.infraPositions[uuidAp] = {
        infraTypeId: type,
        infraCategory: 2,
        location: {
          x: point.x,
          y: point.y
        }
      };
      setSelectedAp(uuidAp)
      return cLayout;
    })
  };

  const insertCableDrop = (name, point) => {
    setEditableLayout(eLayout => {
      const cLayout = eLayout.copy();
      const uuidCableDrop = uuidv4()
      cLayout.cableDropList[uuidCableDrop] = {
        location: {
          x: point.x,
          y: point.y
        }
      };
      setSelectedCableDrop(uuidCableDrop)
      return cLayout;
    })
  };



  const removeAp = (uuid) => {
    const tempLayout = editableLayout.copy();
    let tempRemoveApList = []
    if (!!tempLayout?.infraPositions[uuid]?.id)
      tempRemoveApList.push(tempLayout.infraPositions[uuid].id)

    if (tempLayout.infraPositions[uuid].isConverted) {
      make_custom_toast('error', 'Floorplan', "Placed APs can't be deleted")
      return
    }
    else {
      setEditableLayout(eLayout => {
        const cLayout = eLayout.copy();
        cLayout.removedApList = [...cLayout.removedApList, ...tempRemoveApList]
        delete cLayout.infraPositions[uuid];
        setSelectedAp(null);
        return cLayout;
      })
    }
  };

  const removeSwitch = (uuid) => {
    const tempLayout = editableLayout.copy();
    let tempRemoveApList = []
    if (!!tempLayout?.infraPositions[uuid]?.id)
      tempRemoveApList.push(tempLayout.infraPositions[uuid].id)

    if (tempLayout.infraPositions[uuid].isConverted) {
      make_custom_toast('error', 'Floorplan', "Placed APs can't be deleted")
      return
    }
    else {
      setEditableLayout(eLayout => {
        const cLayout = eLayout.copy();
        cLayout.removedApList = [...cLayout.removedApList, ...tempRemoveApList]
        delete cLayout.infraPositions[uuid];
        setSelectedSwitch(null);
        return cLayout;
      })
    }
  };

  const removeCableDrop = (uuid) => {
    const tempLayout = editableLayout.copy();
    let tempRemoveCableDropList = []
    if (!!tempLayout?.cableDropList[uuid]?.id)
      tempRemoveCableDropList.push(tempLayout.cableDropList[uuid].id)

    setEditableLayout(eLayout => {
      const cLayout = eLayout.copy();
      cLayout.removedCableDropList = [...cLayout.removedCableDropList, ...tempRemoveCableDropList]
      delete cLayout.cableDropList[uuid];
      setSelectedCableDrop(null);
      return cLayout;
    })

  };



  const translateAp = (uuid, toPoint) => {
    setEditableLayout(eLayout => {
      const cLayout = eLayout.copy();
      cLayout.infraPositions[uuid].location.x = toPoint.x;
      cLayout.infraPositions[uuid].location.y = toPoint.y;
      return cLayout;
    })
  };

  const translateSwitch = (uuid, toPoint) => {
    setEditableLayout(eLayout => {
      const cLayout = eLayout.copy();
      cLayout.infraPositions[uuid].location.x = toPoint.x;
      cLayout.infraPositions[uuid].location.y = toPoint.y;
      return cLayout;
    })
  };
  const translateCableDrop = (uuid, toPoint) => {
    setEditableLayout(eLayout => {
      const cLayout = eLayout.copy();
      cLayout.cableDropList[uuid].location.x = toPoint.x;
      cLayout.cableDropList[uuid].location.y = toPoint.y;
      return cLayout;
    })
  };
  const updateCableName = (uuid, name) => {
    if (name.trim().length) {
      setEditableLayout(eLayout => {
        const cLayout = eLayout.copy();
        cLayout.cableDropList[uuid].name = name;
        return cLayout;
      })
    }
  };




  return {
    saveType,
    setSaveType,
    selectedWall,
    setSelectedWall,
    selectedLayout,
    setSelectedLayout,
    editableLayout,
    setEditableLayout,
    insertLayout,
    removeLayout,
    translateLayout,
    rotateLayout,
    setWallMaterial,
    insertCableDrop,
    removeCableDrop,
    selectedCableDrop,
    setSelectedCableDrop,
    insertAp,
    removeAp,
    selectedAp,
    setSelectedAp,
    translateAp,
    translateSwitch,
    translateCableDrop,
    insertSwitch,
    removeSwitch,
    selectedSwitch,
    setSelectedSwitch,
    updateCableName,
    resetSelectedEntities,
    updateEditableLayout,
    currentZoomValue,
    setCurrentZoomValue,
    currentOffset,
    setCurrentOffset,
    lineStartPoint,
    setLineStartPoint,
    lineEndPoint,
    setLineEndPoint
  };
}

export default useLayoutsManager;