/***
 *
 * Controller class for user.
 * @file VeryFancyLoader.js
 * @description VeryFancyLoader component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React from "react";
import PropTypes from 'prop-types';
import { Spinner } from "reactstrap";

const VeryFancyLoader = (props) => {
  return (
    <Spinner className="text-primary" size={props.size}/>
  );
};

VeryFancyLoader.propTypes = {
  size: PropTypes.string
};

VeryFancyLoader.defaultProps = {
  size: "5"
};

export default VeryFancyLoader;
