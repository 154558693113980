import { infraTypesActions } from "../slices";
import store from "../store";
import createRequest, { services } from "../../services";
import { CatchedWebError } from "../../configs";

const getInfraTypes = () => {
  store.dispatch(infraTypesActions.setLoading(true));
  const activeOrgId = store.getState().activeOrg.data.orgId;

  const { controller, run } = createRequest(services.infra.GET_TYPES, [activeOrgId]);

  if (activeOrgId) {

  run()
    .then(response => {
      store.dispatch(infraTypesActions.setError(null));
      store.dispatch(infraTypesActions.setInfraTypes(response.data));
    })
    .catch(err => {
      store.dispatch(infraTypesActions.setError((new CatchedWebError(err)).message));
    })
    .finally(() => {
      store.dispatch(infraTypesActions.setLoading(false));
    });
  }


  return controller;
};

export default getInfraTypes;