import { CatchedWebError } from "../../configs";
import createRequest, { services } from "../../services";
import { activeOrgActions, identityActions, infraTypesActions, rbacActions } from "../slices";
import store from "../store";
import { Compat_IdentityData, Compat_Organization } from "./critical3.worker";

function RbacActiveOrgSwitch(newOrgId) {
  store.dispatch(identityActions.setLoading(true));
  store.dispatch(activeOrgActions.setLoading(true));
  store.dispatch(rbacActions.setLoading(true));
  store.dispatch(infraTypesActions.setLoading(true));

  const { run, controller } = createRequest(services.identity.GET_SELF, [], {}, true, newOrgId);
  run()
    .then(response => {
      store.dispatch(rbacActions.set(response.data));
      const compat_identity = Compat_IdentityData(response.data);
      store.dispatch(identityActions.setIdentity(compat_identity));
  
      const compat_organization = Compat_Organization(response.data);
      store.dispatch(activeOrgActions.setActiveOrg(compat_organization));
      
      const { run:runPictureFetch } = createRequest(services.identity.GET_LOGO, [response?.data?.identityId, "get"]);
      
      runPictureFetch()
        .then((response) => {
          store.dispatch(identityActions.setPicture(response.data));
        })
        .catch((error) => {
          //do nothing
        })
      store.dispatch(infraTypesActions.setLoading(true));
      const {run: run2} = createRequest(services.infra.GET_TYPES, [newOrgId], {}, true, newOrgId);
      run2()
        .then(response2 => {
          store.dispatch(infraTypesActions.setInfraTypes(response2.data));
        })
        .catch(err => {
          store.dispatch(infraTypesActions.setError((new CatchedWebError(err)).message));
        })
        .finally(() => {
          store.dispatch(infraTypesActions.setLoading(false));
        })
    })
    .catch(err => {
      const x = new CatchedWebError(err);
      store.dispatch(identityActions.setError(x.message));
      store.dispatch(activeOrgActions.setError(x.message));
      store.dispatch(rbacActions.setError(x.message));
      store.dispatch(infraTypesActions.setError(x.message));
    })
    .finally(() => {
      store.dispatch(identityActions.setLoading(false));
      store.dispatch(activeOrgActions.setLoading(false));
      store.dispatch(rbacActions.setLoading(false));
      store.dispatch(infraTypesActions.setLoading(false));
    })
  
  return controller;  
}

export default RbacActiveOrgSwitch;