import { useSelector } from "react-redux";
import ChartCardWithAreaCharts from "./ChartCardWithAreaCharts";

const RssiChart = ({categories, series, max, colors, isLoading, typeSeriesTrafficUplink, typeSeriesTrafficDownlink, height, width, ticks, noStyle, noCardBorder, unit }) => {
  const range = useSelector(store => store.activeOrg.meta.dateRange);
  return (
      <ChartCardWithAreaCharts
          title="RSSI"
          categories={categories}
          series={[series]}
          height={height}
          xlabel={`RSSI${!!unit?` (${unit})`:''}`}
          ylabel={range>0 ? "Date" : "Time"}
          ticks={ticks}
          colors={colors}
          width={width}
          range={range}
          isLoading={isLoading}
          noStyle={noStyle}
          noCardBorder={noCardBorder}
          chartOptions={(options) => {
            if(!!options) {
              options.fill = {
                type: "solid",
                opacity: 0
              }
            }
            if(!!options?.yaxis && options.yaxis instanceof Object) {
                // options.yaxis.stepSize = 10
                options.yaxis.min = -100
                // options.yaxis.min = undefined
                options.yaxis.max = max != null?max:undefined
                options.yaxis.forceNiceScale = false
                options.yaxis.tickAmount = 4
                options.yaxis.decimalsInFloat = 0
                // if(!!options?.yaxis?.labels  && options.yaxis.labels instanceof Object)
                //   options.yaxis.labels.offsetX = -5
              // if(!!options?.yaxis?.labels && options.yaxis.labels instanceof Object) {
              //   options.yaxis.labels = {
              //     ...options.yaxis.labels,
              //     formatter: (value) => {
              //       let roundedValue = Number(value.toFixed(1))
              //       return isNaN(roundedValue)? 0:roundedValue
              //     }
              //   }
              // }
              if(!!options?.tooltip || !(options.tooltip instanceof Object)) {
                options.tooltip = {
                  y: {}
                }
              }
              options.tooltip.y = {
                ...options.tooltip.y,
                formatter: function (
                  value = 0,
                  { series, seriesIndex, dataPointIndex, w }
                ) {
                    return (
                      value != null? value + " dBm" : '-'
                    );
                },
              }; 
            }
              return options
          }
          }
      />
  );
}

export default RssiChart;