import { infraListActions } from "../slices";
import store from "../store";
import createRequest, { services } from "../../services";
import { CatchedWebError } from "../../configs";

const getInfraList = (orgId, offset, limit, search = "", order, orderBy, setHasMore = () => { }, overwrite = false, filter = {}) => {
  store.dispatch(infraListActions.setLoading(true));
  const { controller, run } = createRequest(services.infra.GET_BY_ORGANIZATION, [orgId, offset, limit, search, order, orderBy, filter]);

  run()
    .then(response => {
      if (overwrite)
        store.dispatch(infraListActions.setData(response.data));
      else
        store.dispatch(infraListActions.addItems(response.data));
      setHasMore(response.data.length === limit);
    })
    .catch(err => {
      store.dispatch(infraListActions.setError((new CatchedWebError(err)).message));
      setHasMore(false);
    })
    .finally(() => {
      store.dispatch(infraListActions.setLoading(false));
    });
  return controller;
};

export default getInfraList;