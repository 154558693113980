import { act } from "@react-three/fiber";
import { createSlice } from "@reduxjs/toolkit";

const ticketInitial = {
    id: 0,
    freshdeskTicketid: 0,
    ticketDetails: {},
    cachedTicketDetails: {},
    ticketCacheTime: '',
    openDraft: false,
    retainData: false,
    ticketCategory: null,
    solutionCategoryList: [],
    ticketSuggestionsList: []
};

const ticketSlice = createSlice({
    name: "ticket",
    initialState: { ...ticketInitial },
    reducers: {
        setTicketId(state, action) {
            state.id = action.payload;
        },
        setFreshdeskTicketId(state, action) {
            state.freshdeskTicketid = action.payload;
        },
        setRetainData(state, action) {
            state.retainData = action.payload
        },
        setOpenDraft(state, action) {
            state.openDraft = action.payload
        },
        setTicketSuggestionsList(state, action) {
            state.ticketSuggestionsList = action.payload
        },
        setSolutionCategoryList(state, action) {
            state.solutionCategoryList = action.payload
        },
        setTicketDetails(state, action) {


        },
        setCachedTicketDetails(state, action) {
            state.cachedTicketDetails = action.payload


        },

        setTicketCacheTime(state, action) {
            state.ticketCacheTime = action.payload
        },
        setTicketCategory(state, action) {
            state.ticketCategory = action.payload
        }
    }
});

export const ticketReducer = ticketSlice.reducer;
const ticketAction = ticketSlice.actions;
export default ticketAction;