/***
 *
 * Controller class for user.
 * @file InfraBreakdown.js
 * @description InfraBreakdown component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React, { useEffect, useRef, useState } from "react";
// import PropTypes from 'prop-types';
import "./InfraBreakdown.scss";
import { useNavigate } from "react-router-dom";
import ToolTip from "react-portal-tooltip";
import createRequest, { services } from "../../services";
import { Spinner } from "reactstrap";
import { SITE } from "../../utility/constants";

export const safeNumber = (num) => {
  let safeNum = Number(num)
  return isNaN(safeNum)?"-":safeNum
}

const InfraBreakdown = (props) => {
  const { id, totalOnline, totalCount, venueId, venueName, venueType, orgId } = props;
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [active, setActive] = useState(false);
  const [position, setPosition] = useState(null)
  const infraBreakdownDivRef = useRef(null)

  const getInfraBreakdown = () => {
    const { run } = createRequest(services.telemetry.GET_VENUE_BREAKDOWN, [
      venueId,
      "infra",
    ]);
    setLoading(true);
    run()
      .then((response) => {
        setData(response.data?.infraBreakDown);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (active && loading) {
      getInfraBreakdown();
    }
  }, [active, loading]);

  const handleMouseOver = () => {
    if((totalCount??0)>0) {
      const rect = infraBreakdownDivRef.current.getBoundingClientRect()
      //rect:
        // bottom, top, left, right, height, width
      //window:
        //innerWidth, innerHeight, outerWidth, outerHeight
      let diff = window.innerHeight - rect.top
      let quarterWindowHeight = window.innerHeight / 4
      let pos = diff > quarterWindowHeight? "bottom" : "top"
      setPosition(pos)
      setActive(true);
    }
  }

  return (
    <div
      className="InfraBreakdown"
      data-testid="InfraBreakdown"
      id={`infrabreakdown${id}`}
      onMouseOver={handleMouseOver}
      onMouseLeave={() => {
        setActive(false);
      }}
      ref={infraBreakdownDivRef}
    >
      <span
        className={`${(totalOnline??0) > 0 ? "text-success font-weight--600 cursor-pointer" : ""} bigger-text`}
        onClick={() => {
          if((totalOnline??0) > 0)
            navigate(`/organization/${orgId}/venues/${venueId}/infrastructure`, {
              state: {
                filter: {
                  status: ["ONLINE"]
                },
              },
            })
        }}
      >
        {safeNumber(totalOnline)}
      </span>
      &nbsp;/&nbsp;
      <span
        className={`${(totalCount??0) > 0 ?"table-link":''}`}
        onClick={() => {
          if((totalCount??0) > 0)
            navigate(`/organization/${orgId}/venues/${venueId}/infrastructure/`);
        }}
      >
        {safeNumber(totalCount)}
      </span>
      {!loading && !error && !!position && ((totalCount??0) > 0) ? (
        <ToolTip
          parent={`#infrabreakdown${id}`}
          active={active}
          position={position}
          arrow="center"
          style={{
            style: {
              border: "0.1rem solid #D9D9D9",
              boxShadow: "none",
            },
            arrowStyle: {
              borderColor: "#D9D9D9",
            },
          }}
        >
          {loading ? (
            <div className="p-1 text-primary"><Spinner /></div>
          ) : (
            <div className="infraBreakdownTooltip p-1">
              <div className="pr-5 font-weight--500">
                {venueName}&nbsp;Breakdown
              </div>
              <div className="breakdown-item d-flex justify-content-between align-items-center mt-1">
                <div className="font-weight--500">{venueName}</div>
                <div>
                  <span
                    className={`${
                      (data?.siteOnlineCount ?? 0) > 0 ? "text-success font-weight--600" : ""
                    } bigger-text`}
                  >
                    {safeNumber(data?.siteOnlineCount)}
                  </span>
                  <span>&nbsp;/&nbsp;{safeNumber(data?.siteTotalCount)}</span>
                </div>
              </div>
              {(data?.subvenueTotalCount>0) ? <div className="breakdown-item d-flex justify-content-between align-items-center mt-1">
                <div>Sub Venues</div>
                <div>
                  <span
                    className={`${
                      (data?.subvenueOnlineCount ?? 0) > 0
                        ? "text-success font-weight--600"
                        : ""
                    } bigger-text`}
                  >
                    {safeNumber(data?.subvenueOnlineCount)}
                  </span>
                  <span>&nbsp;/&nbsp;{safeNumber(data?.subvenueTotalCount)}</span>
                </div>
              </div>:null}
            </div>
          )}
        </ToolTip>
      ) : null}
    </div>
  );
};

InfraBreakdown.propTypes = {};

InfraBreakdown.defaultProps = {};

export default InfraBreakdown;
