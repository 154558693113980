import PropTypes from "prop-types";
import "./AccessPointsEditor.scss";
import { motion } from "framer-motion";
import AccessPointCanvas from "./AccessPointCanvas";

/**
 * Access Points Editor
 * @param {{
 *   layoutManager: object,
 *   layout: {
*     "venueId": number,
*     "name": string,
*     "layoutType": 1 | 2 | 3,
*     "layoutFile": string,
*     "infraPositions": Array<{
*       "infra_type_id": number,
*       "x": number,
*       "y": number
*     }>?,
*     "layoutJson": {
*       "dimensions": {
*         "length": number,
*         "width": number,
*         "height": number
*         "area": number
*       }?,
*       "walls": Array<{
*         "id": string,
*         "loc": Array<number>,
*         "material": string
*       }>,
*       "isActive": boolean,
*       "id": number,
*       "createdAt": string,
*       "updatedAt": string
*     }
*   }
 * }} props 
 * @returns 
 */
const AccessPointsEditor = (props) => {

  return (
    <motion.div
      className="AccessPointsEditor"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.15 }}
    >
      <motion.div
        initial={{ top: 10, opacity: 0 }}
        animate={{ top: 0, opacity: 1 }}
        transition={{ duration: 0.3, delay: 0.3 }}
        className="map-area"
      >
        <AccessPointCanvas
          blank={props.blank}
          draggedItem={props.draggedItemRef}
          layoutManager={props.layoutManager}
          layouts={[props.layout]} />
      </motion.div>
      {/* <motion.div
        className="selector-area"
        initial={{ right: -10, opacity: 0 }}
        animate={{ right: 0, opacity: 1 }}
        transition={{ duration: 0.3, delay: 0.3 }}
      >
        <h4 className="p-1 m-0 border-bottom">Available Access Points</h4>
        <div className="p-1 ap-list">
          <AccessPointTypesList draggedItem={draggedItem} />
        </div>
      </motion.div> */}
    </motion.div>
  );
};

AccessPointsEditor.propTypes = {
  layout: PropTypes.object,
  layoutManager: PropTypes.object
};
AccessPointsEditor.defaultProps = {};

export default AccessPointsEditor;