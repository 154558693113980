import { useEffect, useState } from "react";
import { Button, Input, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import createRequest, { services } from "../../../services";
import REDUX_WORKER from "../../../redux/workers";

const RoomLocationModal = (props) => {
    const { visible, setVisible, infraData } = props
    const [room, setRoom] = useState(infraData?.room || '')
    const [location, setLocation] = useState(infraData?.location || '')
    const [loading, setLoading] = useState(false)

    const [invalidRoom, setInvalidRoom] = useState(null)
    const [invalidLocation, setInvalidLocation] = useState(null)

    useEffect(() => {
        if (room.length > 32) {
            setInvalidRoom("Maximum 32 characters are allowed")
        }
        else {
            setInvalidRoom(null)
        }
        if (location.length > 32) {
            setInvalidLocation("Maximum 32 characters are allowed")
        }
        else {
            setInvalidLocation(null)
        }
    }, [room, location])

    const updateTask = () => {
        const updatePayload = {
            room: room,
            location: location,
        }
        if ((room == '' || room.length < 1) && (location == '' || location.length < 1)) {
            updatePayload.installed = false
        }
        else {
            updatePayload.installed = true
        }
        setLoading(true)
        const { run } = createRequest(services.telemetry.UPDATE_TASK, [infraData?.macAddress], updatePayload)
        run()
            .then(res => {
                REDUX_WORKER.getInfra(infraData?.infraItemId, false)
                setLoading(false)
                setVisible(false)
            })
            .catch(err => {
                setLoading(false)
                setVisible(false)
            })
    }

    return (
        <Modal centered isOpen={visible} toggle={() => { setVisible(null) }}>
            <ModalHeader className="bg-white" toggle={() => { setVisible(null) }} />
            <ModalBody>
                <h4>Add Room and Location</h4>
                <div className="mb-2">
                    <span>Room</span>
                    <Input invalid={!!invalidRoom} className="w-100" value={room} rows={7} onChange={(e) => {
                        setRoom(e.target.value)
                    }} />
                    {
                        invalidRoom && <span style={{ fontSize: '12px' }} className="text-danger">{invalidRoom}</span>
                    }
                </div>
                <div>
                    <span>Location</span>
                    <Input invalid={!!invalidLocation} className="w-100" value={location} rows={7} onChange={(e) => {
                        setLocation(e.target.value)
                    }} />
                    {
                        invalidLocation && <span style={{ fontSize: '12px' }} className="text-danger">{invalidLocation}</span>
                    }
                </div>
                <div className="my-2 text-right">
                    <Button.Ripple className="mr-1" disabled={loading} color="primary" outline onClick={() => { setVisible(false) }}>Discard</Button.Ripple>
                    <Button.Ripple color="primary" disabled={loading || invalidRoom || invalidLocation} onClick={() => updateTask()}>{loading ? <Spinner size="sm" /> : "Submit"}</Button.Ripple>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default RoomLocationModal;