import { createSlice } from "@reduxjs/toolkit";

const initialIdentities = {
  status: {
    loading: true,
    error: null
  },
  data: []
};

const identitiesSlice = createSlice({
  name: "identities",
  initialState: {...initialIdentities},
  reducers: {
    addIdentities: (state, action) => {
      const { data, offset } = action.payload;
      for (let identityPos = 0; identityPos < data.length; identityPos++) {
        state.data[offset + identityPos] = data[identityPos];
      }
    },
    setFreshIdentities: (state, action) => {
      const { data } = action.payload;
        state.data = [...data];
    },
    resetIdentities: (state) => {
      return {...initialIdentities};
    },
    setLoading: (state, action) => {
      state.status.loading = action.payload;
    },
    setError: (state, action) => {
      state.status.error = action.payload;
    },
    modifyIdentity: (state, action) => {
      const { position, modifiedIdentity } = action.payload;
      state.data[position] = modifiedIdentity;
    }
  }
});

export const identitiesReducer = identitiesSlice.reducer;
const identitiesActions = identitiesSlice.actions;
export default identitiesActions;