/***
 *
 * Controller class for user.
 * @file HeightAnimator.js
 * @description HeightAnimator component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React from "react";
// import PropTypes from 'prop-types';
import "./HeightAnimator.scss";

const HeightAnimator = (props) => {
  const { children, heightAuto, transitionTime } = props;

  return (
    <div
      className="HeightAnimator"
      data-height-auto={heightAuto}
      data-testid="HeightAnimator"
      style={{ transition: `grid-template-rows ${transitionTime} ease-out` }}
    >
      <div className="height-animator-inner-div">{children}</div>
    </div>
  );
};

HeightAnimator.propTypes = {};

HeightAnimator.defaultProps = {};

export default HeightAnimator;
