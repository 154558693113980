const ERROR = {
    INVALID_ERROR_BODY: 99999,
    UNKNOWN_ERROR: 99998,
    IMAGE_UPLOAD_ERROR: 99997,
    CACHE_BUSTER_DISABLED: 99996,
    GRAPH_LOADING_ERROR: 99995,
    UNAUTHORISED_ACCESS: 401,
    NETWORK_ERROR: 99994
};

const INTERNAL_ERRORS = {
    99999: "Unable to process your request at this time. Please try again after sometime",
    99998: "Unknown Error",
    99997: "Could not upload the image, try again.",
    99996: "Cache buster is disabled. You might not get updates.",
    99995: "Graphs could not be loaded. Try again.",
    99994: "Something went wrong. Please check your internet connection.",
    401: "You do not have sufficient permissions to access"
};

/** A Raw Web Error */
class RawWebError {
    /**
     * Web Error Constructor
     * @param {String} errorCode - Internal Error Code
     * @param {Number} browserCode - Browser's Error Code
     * @param {String} message - Message String
     */
    constructor(errorCode, browserCode, message) {
        const eCode = errorCode ? Number(errorCode) : undefined;
        const bCode = browserCode ? Number(browserCode) : undefined;
        this.errorCode = eCode;
        this.browserCode = bCode;
        if (message) {
            this.message = message;
        } else {
            if (eCode) {
                if (INTERNAL_ERRORS[eCode]) {
                    this.message = INTERNAL_ERRORS[eCode]
                } else if (browserCode && INTERNAL_ERRORS[bCode]) {
                    this.message = INTERNAL_ERRORS[bCode]
                } else {
                    this.message = INTERNAL_ERRORS[ERROR.UNKNOWN_ERROR]
                }
            } else if (bCode) {
                if (INTERNAL_ERRORS[bCode]) {
                    this.message = INTERNAL_ERRORS[bCode]
                } else {
                    this.message = INTERNAL_ERRORS[ERROR.UNKNOWN_ERROR]
                }
            } else {
                this.message = INTERNAL_ERRORS[ERROR.INVALID_ERROR_BODY]
            }
        }
    }
}

/** A Try-Catched Web Error */
class CatchedWebError extends RawWebError {
    /**
     * Try-Catched Web Error Constructor
     * @param {Error} err 
     */
    constructor(err) {
        // Try to parse the backend's error, or else set undefined
        if (err?.name === "NetworkError") {
            super(ERROR.NETWORK_ERROR);
        } else {
            super(
                err?.response?.data?.error?.code || undefined,
                err?.response?.data?.error?.statusCode || err?.status_code || undefined,
                err?.response?.data?.error?.message || (
                    (err?.status_code === 403 || err?.status_code === 401) ? "Permission Denied": (
                        err?.status_code === 500 ? "Service unavailable. Please try again later." : (
                            err?.status_code === 504 ? "Gateway unavailable. Please try again later." : (
                                undefined
                            )
                        )
                    )
                )
            );
        }
    }
}


export {ERROR, INTERNAL_ERRORS, RawWebError, CatchedWebError };