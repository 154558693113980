import { createSlice } from "@reduxjs/toolkit";

const marketplaceInitial = {
    quoteShippingInfo: {
        quoteName: '',
        endCustomer: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        projectName: '',
        address: '',
        deliverTo: 'Venue',
        selectedVenue: '',
        otherproperty: ''
    }
};

const marketplaceSlice = createSlice({
    name: "marketplace",
    initialState: { ...marketplaceInitial },
    reducers: {
        setQuoteShippingInfo(state, action) {
            const payload = action.payload
            const temp = {
                quoteName: payload.quoteName || '',
                endCustomer: payload.endCustomer || '',
                firstName: payload.firstName || '',
                lastName: payload.lastName || '',
                email: payload.email || '',
                phoneNumber: payload.phone || '',
                projectName: payload.projectName || '',
                address: payload.billingAddress.addressLine1 || '',
                selectedVenue: payload.selectedVenue || '',
                deliverTo: payload.deliverTo || '',
                otherproperty: payload.otherproperty || ''
            }
            state.quoteShippingInfo = temp
        },
        resetQuoteShippingInfo(state, action) {
            state.quoteShippingInfo = marketplaceInitial.quoteShippingInfo
        }
    }
});

export const marketplaceReducer = marketplaceSlice.reducer;
const marketplaceAction = marketplaceSlice.actions;
export default marketplaceAction;