/**
 * Invite Service
 * @file invite.service.js
 * @description API Endpoints related to organization invitation
 * @author Utkarsh Gupta
 * @since 19 Jul 2022
 */

import Service from "./Service";
import { B } from "../helpers";

/** Services related to Organization Invitation */
const invite = {
  GET: new Service((id, offset = 0, limit = 10, search = "") => B(`organization/${id}/invite?offset=${offset}&limit=${limit}&search=${search}`), "GET"),
  GET_INVITE: new Service((inviteId) => B(`invite/${inviteId}`), "GET"),
  DELETE: new Service((inviteId) => B(`invite/${inviteId}`), "DELETE"),
  CREATE: new Service(() => B("invite"), "POST"),
  ACCEPT: new Service((id) => B(`invite/${id}/accept`), "POST"),
  RESEND_IDENTITY: new Service((id) => B(`identity/${id}/resend`), "PUT"),
  RESEND: new Service((inviteId) => B(`invite/${inviteId}/resend`), "PUT"),
  GET_LOGO: new Service((identityId, purpose) => B(`identity/${identityId}/logo?purpose=${purpose}`), "GET")
};

export default invite;