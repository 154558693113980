import PropTypes from "prop-types";
import "./LayoutWidget.scss";
import { AnimatePresence, motion } from "framer-motion";


const layoutWidgetOptionsVariants = {
  open: { width: 150 },
  close: { width: 65 }
};

/**
 * Layout Manipulation Widget
 * @param {{
 *   selectedLayout: { layoutUuid: string? },
 *   removeLayout: (layoutUuid: string) => void
 * }} props 
 */
const LayoutWidget = (props) => {
  return (
    <AnimatePresence>
      <motion.div 
        className="LayoutWidget d-flex align-items-center rounded-pill mx-50"
        animate={props.selectedLayout.layoutUuid === null ? "close" : "open"}
        variants={layoutWidgetOptionsVariants}
        transition={{ duration: 0.2 }}
      >
        <div 
          title="Select a layout to see options"
          className="layout-widget-label rounded-pill px-75 h-100 d-flex align-items-center"
        >
          Layout
        </div>
          {props.selectedLayout.layoutUuid !== null && 
            <motion.div 
              className="layout-widget-options d-flex align-items-center mr-25 px-50"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.1, delay: 0.2 }}
            >
              <div 
                title="Remove the selected layout from view"
                className="layout-widget-action rounded-pill"
                onClick={() => { props.removeLayout(props.selectedLayout.layoutUuid); }}
              >Remove</div>
            </motion.div>
          }
      </motion.div>
    </AnimatePresence>
  );
};

LayoutWidget.defaultProps = {};
LayoutWidget.propTypes = {};

export default LayoutWidget;