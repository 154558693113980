import { createSlice } from "@reduxjs/toolkit";

const acceptedSubOrgsInitialState = {
  status : {
    loading: false,
    error: null
  },
  data: null
};

const acceptedSubOrgsSlice = createSlice({
  name: "acceptedSubOrgsSlice",
  initialState: {...acceptedSubOrgsInitialState},
  reducers: {
    add: (state, action) => {
      state.data = [...action.payload];
    },
    reset: (state) => {
      return {
        status: {
          loading: false,
          error: null
        },
        data: null
      }
    },
    setLoading: (state, action) => {
      state.status.loading = action.payload;
    },
    setError: (state, action) => {
      state.status.error = action.payload;
    }
  }
});

export const acceptedSubOrgsReducer = acceptedSubOrgsSlice.reducer;
const acceptedSubOrgsActions = acceptedSubOrgsSlice.actions;
export default acceptedSubOrgsActions;