/***
 *
 * Controller class for user.
 * @file CreateTicket.js
 * @description CreateTicket component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import Select from "react-select";
import {
    Alert,
    Button,
    Col,
    Form,
    Input,
    InputGroup,
    InputGroupAddon,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Spinner,
} from "reactstrap";
import { TextInput } from "../../../components";
import { breadcrumbActions, ticketAction } from "../../../redux/slices";
import store from "../../../redux/store";
import createRequest, { services } from "../../../services";
import VenueSelector from "../../../components/VenueSelector";
import * as Yup from "yup";
import "./CreateTicket.scss";
import useAutoclear from "../../../hooks/useAutoclear";
import {
    CACHED_TICKET_KEY,
    CATEGORY_LIST,
    FILE_UPLOAD_ERROR_TEXT,
    PRIORITY_LIST,
    editorConfig,
} from "../TicketConstants";
// import { extractAttachmentName, extractFileExtension, extractFileName } from "../utility/StringFunction";
import { uploadFile } from "../utility/ticketUtility";
import {
    extractFileExtension,
    extractFileName,
} from "../utility/StringFunction";
import { CatchedWebError } from "../../../configs";
import TextEditor from "../../../components/TextEditor";
import { make_custom_toast } from "../../../helpers/toasts";

export const BlobToBase64New = (blobUrl) => {
    let img = new Image();
    img.src = blobUrl;
    URL.revokeObjectURL(blobUrl);
    let [w, h] = [img.width, img.height];
    let canvas = document.createElement("canvas");
    canvas.width = w;
    canvas.height = h;
    let ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    return canvas.toDataURL();
};

export const isBlobPresent = (text) => {
    const SEARCH_FOR = "src=\"blob:"
    return text.search(SEARCH_FOR) > 0;
}

export const extractBlobFromDescription = (data) => {
    let blobLinkArray = []
    let tempData = data;
    let firstIndex = 0
    let lastIndex = 0
    let value = ''
    while (tempData.length > 0) {
        firstIndex = tempData.indexOf("blob:")
        if (firstIndex < 0) break;
        lastIndex = tempData.indexOf('\"')
        value = tempData.substring(firstIndex, lastIndex)
        if (value.includes('blob:'))
            blobLinkArray.push(value)
        tempData = tempData.substring(lastIndex + 1)
    }
    return blobLinkArray
}


const CreateTicket = () => {
    const identityData = useSelector((state) => state.identity.data);
    const identityId = identityData.identityId;
    const requesterEmail = identityData.email;
    const requesterName = identityData.userName;
    const activeOrgId = useSelector((store) => store.activeOrg.data.orgId);
    const localTicketDetails = localStorage.getItem(CACHED_TICKET_KEY)
    const cachedTicketDetails = localTicketDetails ? JSON.parse(localTicketDetails) : {}
    const activeOrgName = useSelector(store => store.activeOrg.data.orgDisplayName);
    const navigate = useNavigate();
    const location = useLocation();
    const urlParams = useParams();
    const formikRef = useRef(null)

    const ticketContext = location?.state?.ticketContext;

    const ticketSubjectPrev = location.state?.ticketSubject
        ? location.state.ticketSubject
        : cachedTicketDetails.subject ? cachedTicketDetails.subject
            : "";

    const ticketPriorityPrev = location.state?.ticketPriority
        ? location.state.ticketPriority
        : cachedTicketDetails.priority ? cachedTicketDetails.priority
            : 1;

    const ticketDescriptionPrev = location.state?.ticketDescription
        ? location.state.ticketDescription
        : cachedTicketDetails.description ? cachedTicketDetails.description
            : "";

    const ticketMacAddressPrev = location.state?.macAddress
        ? location.state.macAddress
        : cachedTicketDetails?.infra?.macAddress ? cachedTicketDetails?.infra?.macAddress
            : "";

    const ticketVenueIdPrev = location.state?.venueId
        ? location.state.venueId
        : cachedTicketDetails?.venue?.venueId ? cachedTicketDetails?.venue?.venueId
            : 0;

    const ticketInfraIdPrev = location.state?.infraId
        ? location.state.infraId
        : cachedTicketDetails?.infra?.infraItemId ? cachedTicketDetails?.infra?.infraItemId
            : 0;

    const ticketNetworkIdPrev = location.state?.networkId
        ? location.state.networkId
        : 0;

    const ticketVenueNamePrev = location.state?.venueName
        ? location.state.venueName
        : cachedTicketDetails?.venue?.venueName ? cachedTicketDetails?.venue?.venueName
            : "";

    const ticketInfraNamePrev = location.state?.infraName
        ? location.state.infraName
        : cachedTicketDetails?.infra?.infraName ? cachedTicketDetails?.infra?.infraName
            : "";
    const ticketNetworkNamePrev = location.state?.networkName
        ? location.state.networkName
        : "";
    const showVenuePrev = location.state?.openVenue
        ? location.state.openVenue
        : false;
    const ticketCategoryPrev = location.state?.selectedCategory
        ? location.state.selectedCategory
        : ticketContext?.category
            ? ticketContext?.category
            : cachedTicketDetails?.category ? cachedTicketDetails?.category
                : 1;

    // const categoryList = location.state?.categoryList
    //     ? location.state.categoryList.length > 0
    //         ? location.state.categoryList
    //         : CATEGORY_LIST
    //     : CATEGORY_LIST;

    const categoryList = CATEGORY_LIST;

    const [error, setError] = useState(null);
    const [showSelectVenue, setShowSelectVenue] = useState(
        showVenuePrev || true
    );
    const [selectedInfrastructure, setSelectedInfrastructure] = useState({
        infraItemId: ticketInfraIdPrev,
        infraName: ticketInfraNamePrev,
        macAddress: ticketMacAddressPrev,
    });
    const [selectedVenue, setSelectedVenue] = useState({
        venueId: ticketVenueIdPrev,
        venueName: ticketVenueNamePrev,
    });
    const [venueSelectorModal, setVenueSelectorModal] = useState(false);
    const [infrastructureList, setInfrastructureList] = useState([]);
    const [subject, setSubject] = useState(
        location.state?.ticketSubject
            ? location.state.ticketSubject
            : cachedTicketDetails.subject ? cachedTicketDetails.subject
                : ""
    );
    const [description, setDescription] = useState(
        cachedTicketDetails?.description ? cachedTicketDetails?.description
            : ticketContext?.errorMessage || ""
    );
    const [uploadedFiles, setUploadedFiles] = useState(cachedTicketDetails?.uploadedFiles ? cachedTicketDetails?.uploadedFiles : []);
    const [ticketPriority, setTicketPriority] = useState(ticketPriorityPrev);
    const [ticketApiLoading, setTicketApiLoading] = useState(false);
    const [ticketCategory, setTicketCategory] = useState(categoryList[0]?.label);
    const [uploadUrl, setUploadUrl] = useState(cachedTicketDetails?.fileUrl ? cachedTicketDetails?.fileUrl : []);
    // const [openTicketCreatedModal, setOpenTicketCreatedModal] = useState(false) replaced by toast
    const [fileUploadLoading, setFileUploadLoading] = useState(false);
    const [emptyDescription, setEmptyDescription] = useState(false);
    const [uploadError, setUploadError] = useState(null);

    // useAutoclear(ticketNotCreatedAlert, () => { setTicketNotCreatedAlert(false); }); // error message should not clear
    const editor = useRef(null);
    const cacheData = useRef(null)

    const customStyles = {
        menu: (base) => ({
            ...base,
            zIndex: 100,
        }),
        dropdownIndicator: (base) => ({
            ...base,
            svg: {
                height: "20px",
                width: "20px",
            },
        }),
    };
    const [params] = useSearchParams();

    const breadCrumbs = [
        {
            path: `/organization/${activeOrgId}/support/ticket/`,
            text: "Support",
            active: false,
        },
        {
            path: `/organization/${activeOrgId}/support/ticket/createticket`,
            text: "Create Ticket",
            active: true,
        },
    ];


    /* Making cache data for Ticket, when any of these values are changed 
    1. Subject
    2. Description
    3. Priority
    4. Category
    5. VenueDetails
    6. Infra details
    */
    useEffect(() => {
        let categoryValue = categoryList.filter(
            (category) => category?.label == ticketCategory
        )[0]?.value

        cacheData.current = {
            subject: subject,
            description: description,
            priority: ticketPriority,
            category: categoryValue,
            venue: selectedVenue,
            infra: selectedInfrastructure,
            uploadedFiles: uploadedFiles,
            fileUrl: uploadUrl,
            cacheTime: new Date()

        }
    }, [subject, description, ticketPriority, ticketCategory, selectedInfrastructure, selectedVenue, uploadUrl, uploadedFiles])

    //saving the cache data of Ticket when component is unmounted and storing that in Redux 
    useEffect(() => {
        return () => {
            const cachedConvertedData = JSON.stringify(cacheData.current)
            try {
                localStorage.setItem(CACHED_TICKET_KEY, cachedConvertedData)
            }
            catch (e) {

            }
        }
    }, [])

    useEffect(() => {
        setEmptyDescription(false);
    }, [description]);

    useEffect(() => {
        if (selectedVenue == null) {
            setInfrastructureList([]);
            setSelectedInfrastructure({
                infraItemId: ticketInfraIdPrev,
                infraName: ticketInfraNamePrev,
                macAddress: ticketMacAddressPrev,
            });
        }
        else {
            setSelectedInfrastructure({
                infraItemId: 0,
                infraName: '',
                macAddress: ''
            })
        }
    }, [selectedVenue]);

    useEffect(() => {
        if (ticketCategoryPrev) {
            setTicketCategory(
                categoryList.filter(
                    (category) => category.value == ticketCategoryPrev
                )[0].label
            );
        }
    }, []);

    // ---Params Use Effect
    useEffect(() => {
        store.dispatch(breadcrumbActions.setData(breadCrumbs));
    }, [params]);

    // --- SelectedVenue Use Effect
    useEffect(() => {
        if (selectedVenue && selectedVenue.venueId != 0) {
            const { run } = createRequest(services.infra.GET_BY_VENUE, [
                selectedVenue.venueId,
                0,
                1000,
                "",
            ]);
            run()
                .then((res) => {
                    let tempInfraList = [];
                    res.data.map((item) => {
                        tempInfraList.push({
                            value: item.infraItemId ? item.infraItemId : 0,
                            label: item.infraDisplayName
                                ? item.infraDisplayName
                                : "-",
                            macAddress: item.macAddress
                                ? item.macAddress
                                : "",
                        });
                    });
                    setInfrastructureList(tempInfraList);
                    if (
                        tempInfraList.length > 0 &&
                        selectedInfrastructure.infraItemId == 0
                    ) {
                        setSelectedInfrastructure({
                            infraItemId: tempInfraList[0].value,
                            infraName: tempInfraList[0]?.label,
                            macAddress: tempInfraList[0].macAddress,
                        });
                    }
                })
                .catch((err) => {
                    setError(new CatchedWebError(err).message);
                });
        }
    }, [selectedVenue]);





    const createTicket = async (values, { setSubmitting, resetForm }) => {
        if (description.length < 10) {
            setEmptyDescription(true);
            return;
        }
        const createTicketPayload = {
            subject: values.ticketsubject,
            description: description,
            status: 2, // 'open' status by default
            severity: ticketPriority,
            category: ticketCategory,
            source: 2,
            attachments: uploadUrl,
            venueId: selectedVenue ? selectedVenue.venueId : 0,
            venueName: selectedVenue ? selectedVenue.venueName : "",
            networkId: ticketNetworkIdPrev,
            networkName: ticketNetworkNamePrev,
            infraId: selectedInfrastructure
                ? selectedInfrastructure.infraItemId
                : 0,
            infraName: selectedInfrastructure
                ? selectedInfrastructure.infraName
                : "",
            identityId: identityId,
            requesterEmail: requesterEmail,
            requesterName: requesterName,
            orgId: activeOrgId,
            orgName: activeOrgName,
            macAddress: selectedInfrastructure.macAddress
                ? selectedInfrastructure.macAddress
                : "",
        };


        const blobArray = extractBlobFromDescription(description);

        let newDescription = description

        if (isBlobPresent(description) && blobArray.length > 0) {
            for (let i = 0; i < blobArray.length; i++) {
                let tempBlobLink = blobArray[i]
                let convertedLink = BlobToBase64New(tempBlobLink)
                newDescription = newDescription.replace(tempBlobLink, convertedLink)

            }
            setDescription(newDescription)

        }
        createTicketPayload.description = newDescription

        const { run } = createRequest(services.ticket.CREATE_TICKET, [], createTicketPayload);
        setTicketApiLoading(true);
        run()
            .then((res) => {
                window.scrollTo(0, 0);
                const newTicket = res.data;
                make_custom_toast(
                    "success",
                    "Ticket Successfully created",
                    `Your Ticket #${newTicket.freshworksTicketId} is in review`,
                    true,
                    "View Ticket",
                    () => {
                        navigate(
                            `/organization/${activeOrgId}/support/ticket/details/${newTicket.freshworksTicketId}`
                        );
                    }
                );
                cacheData.current = {} //resetting the cacheData for ticket
                // store.dispatch(ticketAction.setOpenDraft(false))
                navigate(
                    `/organization/${activeOrgId}/support/ticket/`,
                    { replace: true }
                );
            })
            .catch((err) => {
                window.scrollTo(0, 0);
                setTicketApiLoading(false);
                const x = new CatchedWebError(err)
                make_custom_toast('error', 'Ticket', x.message)
            });



    };

    const uploadFiles = (files) => {
        if (files == null || files == undefined || files.length < 1) {
            return;
        }
        let tempUploadedFiles = [...uploadedFiles];
        let tempUploadUrl = [...uploadUrl];
        setFileUploadLoading(true);
        const promiseList = files.map((file) => uploadFile(file, activeOrgId));
        Promise.all(promiseList)
            .then((resArray) => {
                setFileUploadLoading(false);
                resArray.map((res) => {
                    tempUploadUrl.push({
                        filename: res.fileName,
                    });
                    tempUploadedFiles.push({
                        filename: res.displayName,
                    });
                });
                setUploadUrl(tempUploadUrl);
                setUploadedFiles(tempUploadedFiles);
            })
            .catch((errArray) => {
                setFileUploadLoading(false);
                setUploadError(FILE_UPLOAD_ERROR_TEXT);
            });
    };

    const deleteFile = (index) => {
        const fileName = encodeURIComponent(
            extractFileName(uploadUrl[index].filename)
        );
        const extension = extractFileExtension(uploadedFiles[index].filename);

        const { run } = createRequest(services.ticket.REMOVE_ATTACHMENT, [
            activeOrgId,
            fileName,
            extension,
        ]);
        run()
            .then((res) => {
                let tempUploadedFiles = [...uploadedFiles];
                let tempUploadUrl = [...uploadUrl];
                tempUploadedFiles.splice(index, 1);
                tempUploadUrl.splice(index, 1);
                setUploadedFiles(tempUploadedFiles);
                setUploadUrl(tempUploadUrl);
            })
            .catch((err) => {
                setError(new CatchedWebError(err).message);
            });
    };

    return (
        <>
            {/* <Heading text={'Create Ticket'} /> */}
            <Formik
                innerRef={formikRef}
                initialValues={{
                    ticketsubject: ticketSubjectPrev
                        ? ticketSubjectPrev
                        : ticketContext?.action || "",
                    ticketpriority: ticketPriorityPrev,
                }}

                validationSchema={Yup.object({
                    ticketsubject: Yup.string()
                        .min(3, "Minimum 3 characters are required")
                        .max(128, "Maximum 128 characters are allowed")
                        .required("Required"),
                    // ticketdescription: Yup.string()
                    //   .min(3, "Minimum 3 characters are required")
                    //   .max(1000, "Maximum 1000 characters are allowed")
                    //   .required("Required")
                })}

                onSubmit={createTicket}
            >
                {({ isSubmitting, values, setFieldValue, handleSubmit }) => (
                    <Form
                        onSubmit={(event) => {
                            event.preventDefault();
                            handleSubmit();
                        }}
                        onKeyUp={() => setSubject(values.ticketsubject)}

                    >
                        <div className="CreateTicket bg-white rounded shadow p-2 pb-5 mt-2">
                            <div>
                                <Row>
                                    <Col xs="6">
                                        <TextInput
                                            label="Subject"
                                            placeholder="Add subject for ticket"
                                            type="text"
                                            name="ticketsubject"
                                            isrequired
                                        />
                                    </Col>
                                    <Col xs="3" className="d-flex ">
                                        <div className="d-flex flex-column w-100">
                                            <Label className={"labelfont"}>
                                                Select Category
                                            </Label>
                                            <Select
                                                classNamePrefix="select"
                                                defaultValue={
                                                    ticketCategoryPrev
                                                        ? categoryList.filter(
                                                            (category) =>
                                                                category.value ==
                                                                ticketCategoryPrev
                                                        )[0]
                                                        : categoryList[0]
                                                }
                                                options={categoryList}
                                                isClearable={false}
                                                isSearchable={false}
                                                styles={customStyles}
                                                onChange={(option) => {
                                                    setTicketCategory(
                                                        option?.label
                                                    );
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs="3" className="d-flex ">
                                        <div className="d-flex flex-column w-100">
                                            <Label className={"labelfont"}>
                                                Select Severity
                                            </Label>
                                            <Select
                                                name={"ticketpriority"}
                                                classNamePrefix="select"
                                                defaultValue={PRIORITY_LIST.find(
                                                    (it) =>
                                                        it.value ===
                                                        ticketPriorityPrev
                                                )}
                                                options={PRIORITY_LIST}
                                                isClearable={false}
                                                isSearchable={false}
                                                styles={customStyles}
                                                onChange={(option) => {
                                                    setTicketPriority(
                                                        option.value
                                                    );
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                {/* <div onClick={() => {
                  setShowSelectVenue(!showSelectVenue)
                }} className="assign-btn-container my-1 d-flex align-items-center text-primary cursor-pointer">
                  <span class="assign-venue-btn material-symbols-outlined">
                    {!showSelectVenue ? 'add' : 'remove'}
                  </span>
                  Affected Venue/Infrastructure
                </div> */}

                                {showSelectVenue && (
                                    <Row className="fadable">
                                        <Col xs="8" md="6">
                                            <Label className="labelfont">
                                                Select Venue (optional)
                                            </Label>
                                            <InputGroup>
                                                <Input
                                                    type="text"
                                                    disabled
                                                    value={
                                                        selectedVenue &&
                                                            selectedVenue.venueId !==
                                                            0
                                                            ? selectedVenue.venueName
                                                            : "No Venue Selected"
                                                    }
                                                    isrequired
                                                />
                                                <Button
                                                    color="outline-primary rounded-custom"
                                                    onClick={() => {
                                                        setVenueSelectorModal(
                                                            true
                                                        );
                                                    }}
                                                >
                                                    Select Venue
                                                </Button>
                                            </InputGroup>
                                        </Col>
                                        <Col xs="8" md="6" className="d-flex ">
                                            <div className="d-flex flex-column w-100">
                                                <Label className={"labelfont"}>
                                                    Select Infrastructure
                                                    (optional)
                                                </Label>
                                                <Select
                                                    key={`SELECT_INFRA_KEY_${infrastructureList[0]?.label}`}
                                                    isDisabled={
                                                        selectedVenue &&
                                                            selectedVenue.venueId ==
                                                            0
                                                            ? true
                                                            : false
                                                    }
                                                    className={"selectBox"}
                                                    classNamePrefix="select"
                                                    // value={selectedInfrastructure}
                                                    defaultValue={
                                                        selectedInfrastructure.infraName
                                                            ? {
                                                                label: selectedInfrastructure.infraName,
                                                                value: selectedInfrastructure.infraItemId,
                                                            }
                                                            : infrastructureList[0] ||
                                                            "Select"
                                                    }
                                                    options={infrastructureList}
                                                    isClearable={false}
                                                    isSearchable
                                                    styles={customStyles}
                                                    onChange={(option) => {
                                                        setSelectedInfrastructure(
                                                            {
                                                                infraItemId:
                                                                    option.value,
                                                                infraName:
                                                                    option?.label,
                                                                macAddress:
                                                                    option.macAddress,
                                                            }
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                            </div>

                            {/* --- Decription and Attachment View --- */}

                            <div className="my-2">
                                <Row>
                                    <Col>
                                        {/* <TextInput label="Description (Not more than 1000 characters)" placeholder='Add description' rows='5' type="textarea" name="ticketdescription" isrequired /> */}
                                        <Label style={{ fontSize: "1em" }}>
                                            Description
                                            <span className="text-danger">
                                                *
                                            </span>
                                        </Label>
                                        <TextEditor
                                            ref={editor}
                                            value={description}
                                            config={editorConfig}
                                            tabIndex={1} // tabIndex of textarea
                                            onBlur={(newDescription) =>
                                                setDescription(newDescription)
                                            } // preferred to use only this option to update the content for performance reasons
                                            onChange={(newDescription) => { setDescription(newDescription) }}
                                        />
                                        {emptyDescription && (
                                            <span className="empty-description">{`Minimum 10 characters required.`}</span>
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="d-flex flex-column">
                                            <div className="d-flex  align-items-center">
                                                <h5 className="my-2 mr-1 mb-0 cursor-default">
                                                    Upload Attachment
                                                </h5>
                                                <span className="text-muted cursor-default">
                                                    (Maximum upload file size:
                                                    20MB)
                                                </span>
                                            </div>
                                            <Alert
                                                className="w-100"
                                                color="danger"
                                                isOpen={!!uploadError}
                                                toggle={() =>
                                                    setUploadError(null)
                                                }
                                            >
                                                <div className="alert-body">
                                                    {uploadError}
                                                </div>
                                            </Alert>
                                        </div>

                                        <div className="fileupload">
                                            {fileUploadLoading ? (
                                                <Spinner color={`primary`} />
                                            ) : uploadedFiles.length > 0 ? (
                                                <div className="d-flex align-center flex-wrap flex-1">
                                                    {uploadedFiles.map(
                                                        (file, index) => {
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    className="d-flex align-items-center justify-content-between rounded p-1 m-1 shadow "
                                                                >
                                                                    <span>
                                                                        {
                                                                            file.filename
                                                                        }
                                                                    </span>
                                                                    <span
                                                                        className="bin-icon ml-1 cursor-pointer d-inline material-symbols-outlined"
                                                                        onClick={() => {
                                                                            deleteFile(
                                                                                index
                                                                            );
                                                                        }}
                                                                    >
                                                                        delete
                                                                    </span>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            ) : (
                                                <Dropzone onDrop={uploadFiles}>
                                                    {({
                                                        getRootProps,
                                                        getInputProps,
                                                    }) => (
                                                        <section className="d-flex w-100">
                                                            <div
                                                                {...getRootProps()}
                                                                className="d-flex justify-content-center align-items-center  w-100 text-center"
                                                            >
                                                                <input
                                                                    {...getInputProps()}
                                                                />
                                                                <div className="test-center">
                                                                    <p className="material-symbols-outlined large cursor-default">
                                                                        file_upload
                                                                    </p>
                                                                    <p className="cursor-default">
                                                                        <span className="cursor-pointer text-primary">
                                                                            Upload
                                                                            a
                                                                            file
                                                                        </span>{" "}
                                                                        or drag
                                                                        and drop
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            {/* --- Create Ticket Button --- */}
                            <div className="d-flex justify-content-between">
                                {uploadedFiles.length > 0 ? (
                                    <Dropzone onDrop={uploadFiles}>
                                        {({ getRootProps, getInputProps }) => (
                                            <section>
                                                <div {...getRootProps()}>
                                                    <input
                                                        {...getInputProps()}
                                                    />
                                                    <div>
                                                        <Button
                                                            outline
                                                            className="mr-1"
                                                            disabled={
                                                                ticketApiLoading
                                                            }
                                                            color="primary"
                                                            onClick={() => { }}
                                                        >
                                                            Add Files
                                                        </Button>
                                                    </div>
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                ) : (
                                    <div />
                                )}
                                <div>
                                    <Button
                                        outline
                                        className="mr-1"
                                        disabled={ticketApiLoading}
                                        color="primary"
                                        onClick={() => {
                                            cacheData.current = {} //resetting the cacheData for ticket
                                            navigate(-1);

                                        }}
                                    >
                                        Discard
                                    </Button>
                                    <Button
                                        disabled={ticketApiLoading}
                                        type={"submit"}
                                        color="primary"
                                    >
                                        {ticketApiLoading ? (
                                            <Spinner size={"sm"} />
                                        ) : (
                                            `Create Ticket`
                                        )}
                                    </Button>
                                </div>
                            </div>

                            {/* --- replaced by toast --- */}
                            {/* <Modal centered isOpen={openTicketCreatedModal}>
                <ModalBody>
                  <div className="d-flex flex-column align-items-center p-3">
                    <span className="material-symbols-outlined display-1 text-success mb-2">task_alt</span>
                    <div>New Ticket Created</div>
                    <Button color="gradient-success" className="mt-2" onClick={() => {
                      setOpenTicketCreatedModal(false)
                      navigate(`/organization/${activeOrgId}/support/ticket/`)
                    }}>OK</Button>
                  </div>
                </ModalBody>
              </Modal> */}

                            <Modal
                                centered
                                isOpen={venueSelectorModal}
                                toggle={() => {
                                    setVenueSelectorModal(false);
                                }}
                            >
                                <ModalHeader
                                    toggle={() => {
                                        setVenueSelectorModal(false);
                                    }}
                                >
                                    Assign to Venue
                                </ModalHeader>
                                <ModalBody>
                                    <VenueSelector
                                        showCount
                                        selectedVenue={selectedVenue}
                                        setSelectedVenue={setSelectedVenue}
                                        setVenueSelectorModal={
                                            setVenueSelectorModal
                                        }
                                        showChild
                                    />
                                </ModalBody>
                            </Modal>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

CreateTicket.propTypes = {};

CreateTicket.defaultProps = {};

export default CreateTicket;