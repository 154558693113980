import { Button } from "reactstrap";
import "./DrawLineWidget.scss"
import { Toggle } from "../../../../components";

const DrawLineWidget = (props) => {
    return (
        <div className="d-flex align-items-center" >
            Draw
            <Toggle
                displayText={false}
                value={props.tool == 'draw'}
                onClick={() => {
                    props.setTool(prev => prev == 'draw' ? 'move' : 'draw')
                }} />
            <Button className="ml-1" size="sm" color={props.selectedLineIndex ? "primary" : 'secondary'} onClick={() => {
                props.removeLine()
            }}>Delete</Button>
        </div>
    )
}

export default DrawLineWidget;

