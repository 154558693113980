import { createSlice } from "@reduxjs/toolkit";

/*
    Structure of an item in "data" submember
    {
        id: "string",   /// orgId, venueId etc.
        type: "string",   /// DeviceCount, HealthData etc
        category: "string"    /// ORG, VENUE etc.
        data: object?,        /// actual chart data
        rangeStart: dateRange[0],   /// rangeStart
        rangeEnd: dateRange[1],     /// rangeEnd
        lastFetched: number?        /// timestamp of last fetched
    }
*/

const recurringGraphsState = {
    orgId: null,
    venueId: null,
    infraId: null,
    networkId: null,
    timeOption: null,
    graphs: {
        dashboard: {
            orgHealth: { data: null, timestamp: null },
            orgTraffic: { data: null, timestamp: null },
            orgUsers: { data: null, timestamp: null },
            orgInfraCount: { data: null, timestamp: null }
        },
        dashboardCumulative: {
            orgHealth: { data: null, timestamp: null },
            orgTraffic: { data: null, timestamp: null },
            orgUsers: { data: null, timestamp: null },
            orgInfraCount: { data: null, timestamp: null }
        },
        venue: {
            venueHealth: { data: null, timestamp: null },
            venueTraffic: { data: null, timestamp: null },
            venueUsers: { data: null, timestamp: null },
            venueInfraCount: { data: null, timestamp: null }
        },
        venueCumulative: {
            venueHealth: { data: null, timestamp: null },
            venueTraffic: { data: null, timestamp: null },
            venueUsers: { data: null, timestamp: null },
            venueInfraCount: { data: null, timestamp: null }
        },
        infra: {
            infraHealth: { data: null, timestamp: null },
            infraTraffic: { data: null, timestamp: null },
            infraDevices: { data: null, timestamp: null }
        },
        network: {
            networkHealth: { data: null, timestamp: null },
            networkTraffic: { data: null, timestamp: null }
        }
    }
};

const recurringGraphsSlice = createSlice({
    name: "recurringGraphs",
    initialState: {...recurringGraphsState},
    reducers: {
        setOrgId: (state, action) => {
            state = {...recurringGraphsState};
            state.orgId = action.payload;
            return state;
        },
        setVenueId: (state, action) => {
            state.venueId = action.payload;
            state.graphs.venue = {...recurringGraphsState.graphs.venue};
            return state;
        },
        setInfraId: (state, action) => {
            state.infraId = action.payload;
            state.graphs.infra = { ...recurringGraphsState.graphs.infra };
        },
        setNetworkId: (state, action) => {
            state.networkId = action.payload;
            state.graphs.network = { ...recurringGraphsState.graphs.network };
        },
        setTimeOption: (state, action) => {
            state = {...recurringGraphsState, orgId: state.orgId, venueId: state.venueId, infraId: state.infraId, networkId: state.networkId};
            state.timeOption = action.payload;
            return state;
        },
        setReduxNetwork: (state, action) => {
            switch (action.payload.graphType) {
                case "HEALTH":
                    state.graphs.network.networkHealth.data = JSON.stringify(action.payload.graphData);
                    state.graphs.network.networkHealth.timestamp = action.payload.graphTimestamp;
                    break;
                case "TRAFFIC":
                    state.graphs.network.networkTraffic.data = JSON.stringify(action.payload.graphData);
                    state.graphs.network.networkTraffic.timestamp = action.payload.graphTimestamp;
                    break;
                default: 
                    break;
            }
        },
        setReduxInfra: (state, action) => {
            switch (action.payload.graphType) {
                case "HEALTH":
                    state.graphs.infra.infraHealth.data = JSON.stringify(action.payload.graphData);
                    state.graphs.infra.infraHealth.timestamp = action.payload.graphTimestamp;
                    break;
                case "TRAFFIC":
                    state.graphs.infra.infraTraffic.data = JSON.stringify(action.payload.graphData);
                    state.graphs.infra.infraTraffic.timestamp = action.payload.graphTimestamp;
                    break;
                case "DEVICES":
                    state.graphs.infra.infraDevices.data = JSON.stringify(action.payload.graphData);
                    state.graphs.infra.infraDevices.timestamp = action.payload.graphTimestamp;
                    break;
                default:
                    break;
            }
        },
        setReduxVenue: (state, action) => {
            switch (action.payload.graphType) {
                case "HEALTH":
                    state.graphs.venue.venueHealth.data = JSON.stringify(action.payload.graphData);
                    state.graphs.venue.venueHealth.timestamp = action.payload.graphTimestamp;
                    break;
                case "TRAFFIC":
                    state.graphs.venue.venueTraffic.data = JSON.stringify(action.payload.graphData);
                    state.graphs.venue.venueTraffic.timestamp = action.payload.graphTimestamp;
                    break;
                case "USERS":
                    state.graphs.venue.venueUsers.data = JSON.stringify(action.payload.graphData);
                    state.graphs.venue.venueUsers.timestamp = action.payload.graphTimestamp;
                    break;
                case "INFRACOUNT":
                    state.graphs.venue.venueInfraCount.data = JSON.stringify(action.payload.graphData);
                    state.graphs.venue.venueInfraCount.timestamp = action.payload.graphTimestamp;
                    break;
                default:
                    break;
            }
        },
        setReduxVenueCumulative: (state, action) => {
            switch (action.payload.graphType) {
                case "HEALTH":
                    state.graphs.venueCumulative.venueHealth.data = JSON.stringify(action.payload.graphData);
                    state.graphs.venueCumulative.venueHealth.timestamp = action.payload.graphTimestamp;
                    break;
                case "TRAFFIC":
                    state.graphs.venueCumulative.venueTraffic.data = JSON.stringify(action.payload.graphData);
                    state.graphs.venueCumulative.venueTraffic.timestamp = action.payload.graphTimestamp;
                    break;
                case "USERS":
                    state.graphs.venueCumulative.venueUsers.data = JSON.stringify(action.payload.graphData);
                    state.graphs.venueCumulative.venueUsers.timestamp = action.payload.graphTimestamp;
                    break;
                case "INFRACOUNT":
                    state.graphs.venueCumulative.venueInfraCount.data = JSON.stringify(action.payload.graphData);
                    state.graphs.venueCumulative.venueInfraCount.timestamp = action.payload.graphTimestamp;
                    break;
                default:
                    break;
            }
        },
        setReduxDashboard: (state, action) => {
            switch (action.payload.graphType) {
                case "HEALTH":
                    state.graphs.dashboard.orgHealth.data = JSON.stringify(action.payload.graphData);
                    state.graphs.dashboard.orgHealth.timestamp = action.payload.graphTimestamp;
                    break;
                case "TRAFFIC":
                    state.graphs.dashboard.orgTraffic.data = JSON.stringify(action.payload.graphData);
                    state.graphs.dashboard.orgTraffic.timestamp = action.payload.graphTimestamp;
                    break;
                case "USERS":
                    state.graphs.dashboard.orgUsers.data = JSON.stringify(action.payload.graphData);
                    state.graphs.dashboard.orgUsers.timestamp = action.payload.graphTimestamp;
                    break;
                case "INFRACOUNT":
                    state.graphs.dashboard.orgInfraCount.data = JSON.stringify(action.payload.graphData);
                    state.graphs.dashboard.orgInfraCount.timestamp = action.payload.graphTimestamp;
                    break;
                default:
                    break;
            }
        },
        setReduxDashboardCumulative: (state, action) => {
            switch (action.payload.graphType) {
                case "HEALTH":
                    state.graphs.dashboardCumulative.orgHealth.data = JSON.stringify(action.payload.graphData);
                    state.graphs.dashboardCumulative.orgHealth.timestamp = action.payload.graphTimestamp;
                    break;
                case "TRAFFIC":
                    state.graphs.dashboardCumulative.orgTraffic.data = JSON.stringify(action.payload.graphData);
                    state.graphs.dashboardCumulative.orgTraffic.timestamp = action.payload.graphTimestamp;
                    break;
                case "USERS":
                    state.graphs.dashboardCumulative.orgUsers.data = JSON.stringify(action.payload.graphData);
                    state.graphs.dashboardCumulative.orgUsers.timestamp = action.payload.graphTimestamp;
                    break;
                case "INFRACOUNT":
                    state.graphs.dashboardCumulative.orgInfraCount.data = JSON.stringify(action.payload.graphData);
                    state.graphs.dashboardCumulative.orgInfraCount.timestamp = action.payload.graphTimestamp;
                    break;
                default:
                    break;
            }
        }
    }
})

export const recurringGraphsReducer = recurringGraphsSlice.reducer;
const recurringGraphsActions = recurringGraphsSlice.actions;
export default recurringGraphsActions;