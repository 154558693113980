/***
 *
 * Controller class for user.
 * @file ModelSetting.js
 * @description ModelSetting component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import React, { useEffect, useState } from "react";
import { Toggle, SelectInput, TextInput } from "../../../components";
import { Row, Col, Button, UncontrolledTooltip, Badge, Spinner, Input, TabContent, TabPane, Table, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle, Modal, ModalHeader, ModalBody } from "reactstrap";
import Select from "react-select";
import Ap101 from "../../../assets/images/icons/EAP101.svg";
import Ap102 from "../../../assets/images/icons/EAP102.png";
import Ap104 from "../../../assets/images/icons/EAP104.png";
import Ap196 from "../../../assets/images/icons/CIG196.png";
import { ReactComponent as LAN } from "../../../assets/images/icons/LANPort.svg";
import { ReactComponent as Edit } from "../../../assets/images/icons/Edit.svg";
import lodash from "lodash-es"

// import PropTypes from 'prop-types';
import "./ModelSetting.scss";
import { useSelector } from "react-redux";
import LightBadge from "../../../components/LightBadge";
import createRequest, { services } from "../../../services";
import Port802 from "../Port802";

const Img = {
  "CIG_WF196": Ap196,
  "Edgecore_EAP101": Ap101,
  "Edgecore_EAP102": Ap102,
  "Edgecore_EAP104": Ap104
}
export const portSpeed = [{ value: "auto", label: "Auto" }, { value: "10", label: "10 Mbps" }, { value: "100", label: "100 Mbps" }, { value: "1000", label: "1 Gbps" },
{ value: "2500", label: "2.5 Gbps" }, { value: "5000", label: "5 Gbps" }, { value: "10000", label: "10 Gbps" }, {value: "25000", label: "25 Gbps" }, {value: "100000", label: "100Gbps" }];

export const portDuplex = [{ value: "auto", label: "Auto" }, { value: "half", label: "Half" }, { value: "full", label: "Full" },];

const Port = ({ port, onClick, selectedPort }) => {
  return (<div className="d-flex flex-column align-items-center mx-1">
    <div className="font-weight-bolder small-text">{port?.name}</div>
    <div className="border-big" style={{ minHeight: "20px" }}></div>
    <LAN height={64} width={64} className={"cursor-pointer " + (port?.status ? "fill-grey" : "fill-black")} onClick={() => {
      onClick();
    }} />
  </div>
  )
}

const PortForm = (props) => {

  const [internalState, setInternalState] = useState(props.selectedPort);
  const infraModels = useSelector(store => store.infraTypes.data);

  return (
    <div>
      <Row>
        <Col md={6} xs={12}>
          <div className="port-form-label">Port Status</div>
          <Select
            value={{ value: internalState?.status, label: internalState?.status ? "On" : "Off" }}
            isDisabled={props.disabled}
            options={[{ value: true, label: "On" }, { value: false, label: "Off" }]}
            onChange={(e) => {
              setInternalState((prevState) => { return { ...prevState, status: e.value } })
            }
            } />

          <div className="port-form-label">Duplex</div>
          <Select
            isDisabled={props.disabled}
            options={portDuplex}
            value={portDuplex.find(it => it.value == internalState?.duplex)}
            onChange={(e) => {
              setInternalState((prevState) => { return { ...prevState, duplex: e.value } });
            }} />

          <div className="port-form-label">VLAN Tag</div>
          <Input type="number" autoComplete="off" invalid={props.error} disabled={props.disabled} name="vlan" value={internalState?.vlan || ""} onChange={(e) => {
            if (e.target.value == "" || (Number(e.target.value) >= 0 && Number(e.target.value) < 4051)) {
              setInternalState((prevState) => { return { ...prevState, vlan: Number(e.target.value) } });
              if (props.valid == "model") {
                props.setValid("");
              }
              props.setError(false)
            }
            else {
              props.setValid("model");
              props.setError(true);
            }
          }} />
          {props.error && <div className="small-font text-danger">Enter value[1-4050]</div>}

        </Col>

        <Col md={6} xs={12}>
          <div className="port-form-label">Port 802.1X</div>
          <Select
            value={{ value: internalState?.ieee8021x, label: internalState?.ieee8021x ? "On" : "Off" }}
            isDisabled={props.disabled || props.disable802}
            options={[{ value: true, label: "On" }, { value: false, label: "Off" }]}
            onChange={(e) => {
              setInternalState((prevState) => { return { ...prevState, ieee8021x: e.value } })
            }
            } />

          <div className="port-form-label">Speed</div>
          <Select
            isDisabled={props.disabled}
            options={portSpeed.filter(it => it.value == "auto" || it.value <= infraModels.find(model => model.infraTypeId == props.selectedModel)?.ports?.LAN?.find(port => port.InterfaceName == internalState.name)?.Speed)}
            value={portSpeed.find(it => it.value == internalState?.speed)}
            onChange={(e) => {
              setInternalState((prevState) => { return { ...prevState, speed: !!Number(e.value) ? Number(e.value) : e.value } })
            }} />

        </Col>
      </Row>
      <div className={"text-right mt-1 " + (props.disabled?"d-none":"")}>
        <Button.Ripple className="mr-1" color="danger" outline onClick={() => {
          props.resetPort(internalState);
          setTimeout(() => props.setSelectedPort(null), 100);
        }}>
          Reset
        </Button.Ripple>
        <Button.Ripple color="primary" onClick={() => {
          props.handleChange(internalState);
          setTimeout(() => props.setSelectedPort(null), 100);
        }}>Save</Button.Ripple>
      </div>


    </div>)
}

const TableView = (props) => {


  return (
    <Table className="table-view mt-1" hover>
      <thead>
        <tr>
          <th className="rounded-left-top" style={{ width: "19%" }}>Name</th>
          <th style={{ width: "19%" }}>Admin State</th>
          <th style={{ width: "19%" }}>Speed</th>
          <th style={{ width: "19%" }}>Duplex</th>
          <th style={{ width: "19%" }}>VLAN Tag</th>
          <th className="rounded-right-top" style={{ width: "5%" }}></th>
        </tr>
      </thead>
      <tbody>
        {props.data[props.selectedModel].Ports.map((port, index) => {
          return (<tr key={index}>
            <td className="table-link" onClick={() => {
              props.setSelectedPort(port)
            }}>{port.name}</td>
            <td className={`text-${port.status ? "success" : "secondary"} `}>{port.status ? "Enabled" : "Disabled"}</td>
            <td>{portSpeed.find(it => it.value == port.speed)?.label ?? "Auto"}</td>
            <td>{portDuplex.find(it => it.value == port.duplex)?.label ?? "Auto"}</td>
            <td>{port.vlan > 0 ? port.vlan : "-"}</td>
            <td><Edit onClick={() => {
              props.setSelectedPort(port)
            }} /></td>
          </tr>)
        })}
      </tbody>
    </Table>
  )
}


const ModelSetting = (props) => {
  const model = useSelector(store => store.infraTypes.data);
  const [selectedModel, setSelectedModel] = useState(Number(Object.keys(props.data)[0]));
  const [defaultAP, setDefaultAP] = useState(null);
  const [error, setError] = useState(null);
  const [selectedPort, setSelectedPort] = useState(null)

  const handleChange = (port) => {
    props.setter((prevState => {

      let ports = [...props.data[selectedModel].Ports];
      ports[ports.map(it => it.name).indexOf(port.name)] = { ...port };
      return {
        ...prevState,
        [selectedModel]: {
          ...prevState[selectedModel],
          Ports: [...ports]
        }
      }
    }))
  }

  const resetPort = (port) => {
    if (!!defaultAP)
      props.setter((prevState => {
        let ports = [...props.data[selectedModel].Ports];
        ports[ports.map(it => it.name).indexOf(port.name)] = { ...defaultAP[selectedModel].Ports[defaultAP[selectedModel].Ports.map(it => it.name).indexOf(port.name)] };
        return {
          ...prevState,
          [selectedModel]: {
            ...prevState[selectedModel],
            Ports: [...ports]
          }
        }
      }))
  }

  const resetModel = (modelId) => {
    if (!!defaultAP)
      props.setter((prevState => {
        return {
          ...prevState,
          [modelId]: JSON.parse(JSON.stringify(defaultAP[modelId]))
        }
      }))
    setCustomize(modelId, true);
  }

  const setCustomize = (modelId, override = false) => {
    if(!!defaultAP){
      if(override){
        props.setCustomizedModel(prevState => {
          return {
            ...prevState,
            [modelId] : false
          }
        })
        return
      }
      let currData = JSON.parse(JSON.stringify(props.data[modelId]));
      let defaultData = JSON.parse(JSON.stringify(defaultAP[modelId]));
      delete currData.customize;
      delete defaultData.customize;
      delete currData["random-password"];
      delete defaultData["random-password"];
      if(lodash.isEqual(currData, defaultData))
        props.setCustomizedModel(prevState => {
          return {
            ...prevState,
            [modelId] : false
          }
        })
      else
        props.setCustomizedModel(prevState => {
          return {
            ...prevState,
            [modelId] : true
          }
        })
    }
  }

  useEffect(() => {
    if(!!props.data && !!selectedModel)
      setCustomize(selectedModel)
  },[props.data, selectedModel])

  useEffect(() => {
    const { run } = createRequest(services.networks.AP_DEFAULT, [])
    run().then(response => setDefaultAP(response.data.AP));
  }, []);

  return (
    <div className="ModelSettingNew" data-testid="ModelSettingNew">
      <div className="bg-white rounded shadow-sm p-1">
        <Row className="pt-1">
          <Col md={3} xs={6}>
            <div style={{ fontSize: "1rem", marginBottom: "0.5rem" }}>Model</div>
            <UncontrolledDropdown className="w-100">
              <DropdownToggle className="w-100 " color="primary" outline>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    {model.find(it => it.infraTypeId == selectedModel)?.infraType?.replace(/_/g, " ")}
                    {props.customizedModel[selectedModel] ? <LightBadge className="ml-1" color={"primary"} pill>Customized</LightBadge> : null}
                  </div>
                  <span className="material-symbols-outlined">expand_more</span>
                </div>
              </DropdownToggle>
              <DropdownMenu className="w-100" style={{ maxHeight: "200px", overflow: "auto" }}>
                {model.map((it, index) => {
                  if (props.data[it.infraTypeId])
                    return <DropdownItem className="w-100" key={it.infraTypeId} onClick={() => {
                      if (!error) {
                        setSelectedModel(it.infraTypeId);
                        // setSelectedPort(props.data[it.infraTypeId].Ports[0]);
                      }
                    }}>
                      <div className="d-flex align-items-center justify-content-between mt-50">
                        <div className="font-weight-bolder mr-1">{it.infraType?.replace(/_/g, " ")}</div>
                        {props.customizedModel[it.infraTypeId] ? <LightBadge color={"primary"} pill>Customized</LightBadge> : null}
                      </div>
                    </DropdownItem>
                })
                }
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          <Col>
            <div style={{ fontSize: "1rem", marginBottom: "0.5rem" }}>Customized Models</div>
            <div className="d-flex align-items-center ">
              {Object.values(props.data).map(mod => {
                if (props.customizedModel[mod.infraTypeId])
                  return <LightBadge color="primary"><div className="d-flex align-items-center justify-content-between" style={{ padding: "0.4rem 0.5rem" }}>{model.find(it => it.infraTypeId == mod.infraTypeId)?.infraType?.replace(/_/g, " ")} <span className="cursor-pointer ml-50" onClick={() => resetModel(mod.infraTypeId)}>X</span></div></LightBadge>
              })}
            </div>
          </Col>
        </Row>
        <Row>

          {selectedModel && props.data[selectedModel]?.Ports?.length == 0 && <Col xs={12}>
            <div className="d-flex align-items-center justify-content-center h-100">
              <h4>No LAN ports for this AP</h4>
            </div>
          </Col>}
          {selectedModel && props.data[selectedModel]?.Ports?.length > 0 &&
            <Col xs={12}>
              <div className="d-flex flex-column align-items-center justify-content-center p-2 mt-1 border" style={{ backgroundColor: "#F9F9F9" }}>
                <div className="d-flex">
                  {selectedModel && props.data[selectedModel].Ports.map(port => {
                    return (<div><Port port={port} selectedPort={selectedPort} onClick={() => {
                      setSelectedPort(port)
                    }} /></div>)
                  })}
                </div>
                <div className="d-flex justify-content-center m-0 mt-1">
                  <span className="switch-label">
                    <div className="switch-colour-label" style={{ backgroundColor: "#808080" }}></div>
                    Enabled
                  </span>
                  <span className="switch-label">
                    <div className="switch-colour-label" style={{ backgroundColor: "#000" }}></div>
                    Disabled
                  </span>
                </div>
              </div>
              <TableView data={props.data} selectedModel={selectedModel} setSelectedPort={setSelectedPort} />
              <div>
                <div className="port-form-label">LEDs</div>
                <Toggle value={props.data[selectedModel]?.leds} disabled={props.disabled} onClick={() => {
                  props.setter(prevState => {
                    return {
                      ...prevState,
                      [selectedModel]: {
                        ...prevState[selectedModel],
                        leds: !prevState[selectedModel]?.leds
                      }
                    }
                  })
                }} />
              </div>

              {/* <div>
                <Port802 disabled={props.disabled} data={props?.data802 ?? {}} setter={props.setter} setPortData={props.setPortData} setDisable={props.setDisable}
                  valid={props.valid} setValid={props.setValid} />
              </div> */}

              <Modal className="modal-lg" isOpen={!!selectedPort} centered toggle={() => { if (props.valid != "model") setSelectedPort(null) }}>
                <ModalHeader className="bg-white p-0 m-0" toggle={() => { if (props.valid != "model") setSelectedPort(null) }}></ModalHeader>
                <ModalBody>
                  <div className="p-1 pb-2">
                    <h3 className="font-weigt-bolder mb-1">{selectedPort?.name} Settings</h3>
                    <PortForm data={props.data} error={error} setError={setError} valid={props.valid} setValid={props.setValid}
                      setter={props.setter} selectedModel={selectedModel} selectedPort={selectedPort} disableRandomPassword={props.disableRandomPassword}
                      setSelectedPort={setSelectedPort} resetPort={resetPort} handleChange={handleChange} disable802={props.disable802} disabled={props.disabled} />
                  </div>
                </ModalBody>
              </Modal>
            </Col>}
        </Row>
      </div>
    </div>
  );
};

ModelSetting.propTypes = {};

ModelSetting.defaultProps = {};

export default ModelSetting;
