import store from "../../../redux/store"

export const checkInfraType = (infraTypeId) => {
    const state = store.getState()
    const infraTypeList = state.infraTypes.data
    const filteredInfra = infraTypeList.filter(infra => infra.infraTypeId == infraTypeId)[0]
    if (!!filteredInfra?.infraCategory)
        return (filteredInfra?.infraCategory)
    else
        return 0

}