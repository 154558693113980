import PropTypes from "prop-types";

const CableDropIcon = ({ svgProps, color }) => (
    // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none" {...svgProps}>
    //     <path d="M23.9432 19.9807C23.9734 19.9807 23.9983 20.0056 23.9983 20.0358V25.2589C23.9983 25.2891 23.9734 25.314 23.9432 25.314H8.05338C8.02315 25.314 7.99826 25.2891 7.99826 25.2589V20.0358C7.99826 20.0056 8.02315 19.9807 8.05338 19.9807H23.9432ZM23.9432 19.0918H8.05338C7.53249 19.0918 7.10938 19.514 7.10938 20.0358V25.2589C7.10938 25.7798 7.5316 26.2029 8.05338 26.2029H23.9432C24.464 26.2029 24.8872 25.7807 24.8872 25.2589V20.0358C24.8872 19.5149 24.4649 19.0918 23.9432 19.0918Z" fill={color} />
    //     <path d="M12.3103 17.2979C12.2019 17.2979 12.0926 17.2588 12.0072 17.1788C11.8277 17.0117 11.8179 16.7308 11.985 16.5504C12.5023 15.9948 13.1174 15.5593 13.8134 15.2553C14.5094 14.9513 15.2472 14.7957 16.0072 14.793H16.0286C16.7797 14.793 17.5112 14.9433 18.2028 15.2393C18.9014 15.5388 19.5192 15.9699 20.0401 16.521C20.2081 16.6997 20.201 16.9806 20.0223 17.1495C19.8437 17.3184 19.5619 17.3104 19.3939 17.1317C18.9574 16.6695 18.4383 16.3077 17.8517 16.0561C17.2668 15.8055 16.6588 15.6766 16.0099 15.6819C15.3717 15.6837 14.753 15.8144 14.169 16.0695C13.585 16.3246 13.0686 16.6899 12.6348 17.1566C12.5477 17.2499 12.4286 17.2979 12.3095 17.2979H12.3103ZM10.0819 14.2028C9.97345 14.2028 9.86412 14.1637 9.77878 14.0837C9.59923 13.9166 9.58945 13.6348 9.75656 13.4553C10.5592 12.5939 11.5148 11.9175 12.5939 11.4455C13.673 10.9735 14.8179 10.7326 15.9957 10.7281H16.0268C17.193 10.7281 18.329 10.961 19.4037 11.4215C20.4863 11.8855 21.4454 12.5548 22.2552 13.4108C22.4232 13.5895 22.4161 13.8704 22.2374 14.0393C22.0588 14.2081 21.777 14.2001 21.609 14.0215C20.8837 13.2544 20.0232 12.6544 19.0526 12.2384C18.0899 11.8259 17.0721 11.617 16.0268 11.617H15.9992C14.9432 11.6206 13.9174 11.8375 12.9495 12.2597C11.9815 12.6828 11.1255 13.289 10.4063 14.0606C10.3183 14.1548 10.2001 14.2019 10.081 14.2019L10.0819 14.2028ZM7.10856 11.4313C7.00012 11.4313 6.89078 11.3921 6.80545 11.3122C6.62589 11.145 6.61612 10.8633 6.78323 10.6837C7.96812 9.41348 9.37523 8.41704 10.9663 7.72193C12.5583 7.02593 14.2446 6.67037 15.9815 6.66504C17.721 6.70326 19.4081 7.00282 21.0055 7.68726C22.6001 8.37082 24.0152 9.35659 25.209 10.6197C25.377 10.7984 25.3699 11.0793 25.1912 11.2481C25.0126 11.417 24.7317 11.409 24.5628 11.2304C23.4526 10.0562 22.137 9.13882 20.6543 8.50326C19.1699 7.86682 17.6037 7.55215 15.9841 7.55304C14.3708 7.55837 12.8019 7.88904 11.3219 8.53526C9.84189 9.18148 8.53434 10.1086 7.43301 11.289C7.34501 11.3833 7.22678 11.4304 7.10767 11.4304L7.10856 11.4313Z" fill={color} />
    //     <path d="M7.1059 19.9797V25.313H6.21701V19.9797H7.1059ZM7.1059 19.0908H6.21701C5.72635 19.0908 5.32812 19.489 5.32812 19.9797V25.313C5.32812 25.8037 5.72635 26.2019 6.21701 26.2019H7.1059C7.59657 26.2019 7.99479 25.8037 7.99479 25.313V19.9797C7.99479 19.489 7.59657 19.0908 7.1059 19.0908Z" fill={color} />
    //     <path d="M25.77 19.9797V25.313H24.8811V19.9797H25.77ZM25.77 19.0908H24.8811C24.3904 19.0908 23.9922 19.489 23.9922 19.9797V25.313C23.9922 25.8037 24.3904 26.2019 24.8811 26.2019H25.77C26.2606 26.2019 26.6589 25.8037 26.6589 25.313V19.9797C26.6589 19.489 26.2606 19.0908 25.77 19.0908Z" fill={color} />
    //     <path d="M11.5495 21.759V23.5368H9.7717V21.759H11.5495ZM11.5495 20.8701H9.7717C9.28103 20.8701 8.88281 21.2683 8.88281 21.759V23.5368C8.88281 24.0275 9.28103 24.4257 9.7717 24.4257H11.5495C12.0401 24.4257 12.4384 24.0275 12.4384 23.5368V21.759C12.4384 21.2683 12.0401 20.8701 11.5495 20.8701Z" fill={color} />
    //     <path d="M22.2205 21.759V23.5368H15.9983V21.759H22.2205ZM22.2205 20.8701H15.9983C15.5076 20.8701 15.1094 21.2683 15.1094 21.759V23.5368C15.1094 24.0275 15.5076 24.4257 15.9983 24.4257H22.2205C22.7112 24.4257 23.1094 24.0275 23.1094 23.5368V21.759C23.1094 21.2683 22.7112 20.8701 22.2205 20.8701Z" fill={color} />
    //     <path d="M17.3273 24.4257C17.0819 24.4257 16.8828 24.2266 16.8828 23.9812V21.3146C16.8828 21.0692 17.0819 20.8701 17.3273 20.8701C17.5726 20.8701 17.7717 21.0692 17.7717 21.3146V23.9812C17.7717 24.2266 17.5726 24.4257 17.3273 24.4257Z" fill={color} />
    //     <path d="M19.1085 24.4257C18.8632 24.4257 18.6641 24.2266 18.6641 23.9812V21.3146C18.6641 21.0692 18.8632 20.8701 19.1085 20.8701C19.3538 20.8701 19.553 21.0692 19.553 21.3146V23.9812C19.553 24.2266 19.3538 24.4257 19.1085 24.4257Z" fill={color} />
    //     <path d="M20.8898 24.4257C20.6444 24.4257 20.4453 24.2266 20.4453 23.9812V21.3146C20.4453 21.0692 20.6444 20.8701 20.8898 20.8701C21.1351 20.8701 21.3342 21.0692 21.3342 21.3146V23.9812C21.3342 24.2266 21.1351 24.4257 20.8898 24.4257Z" fill={color} />
    // </svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" fill="none" {...svgProps}>
        < path d="M0.851562 21.275H20.5016C20.9266 21.275 21.2516 20.95 21.2516 20.525V0.875C21.2516 0.45 20.9266 0.125 20.5016 0.125H0.851562C0.426562 0.125 0.101562 0.45 0.101562 0.875V20.525C0.101562 20.95 0.451562 21.275 0.851562 21.275ZM1.60156 1.625H19.7516V19.775H1.60156V1.625Z" fill={color} />
        <path d="M18 2.97461H3.375C3.175 2.97461 3 3.14961 3 3.34961V13.5746C3 13.7746 3.175 13.9496 3.375 13.9496H5.475V16.1246C5.475 16.3246 5.65 16.4996 5.85 16.4996H8.175V18.2246C8.175 18.4246 8.35 18.5996 8.55 18.5996H12.85C13.05 18.5996 13.225 18.4246 13.225 18.2246V16.4996H15.55C15.75 16.4996 15.925 16.3246 15.925 16.1246V13.9496H18C18.2 13.9496 18.375 13.7746 18.375 13.5746V3.34961C18.375 3.14961 18.2 2.97461 18 2.97461ZM17.625 13.1996H15.525C15.325 13.1996 15.15 13.3746 15.15 13.5746V15.7496H12.825C12.625 15.7496 12.45 15.9246 12.45 16.1246V17.8496H8.9V16.1246C8.9 15.9246 8.725 15.7496 8.525 15.7496H6.2V13.5746C6.2 13.3746 6.025 13.1996 5.825 13.1996H3.75V3.72461H17.65V13.1996H17.625Z" fill={color} />
        <path d="M5.875 9.37578C6.025 9.37578 6.125 9.27578 6.125 9.12578V5.17578C6.125 5.02578 6.025 4.92578 5.875 4.92578C5.725 4.92578 5.625 5.02578 5.625 5.17578V9.12578C5.625 9.25078 5.725 9.37578 5.875 9.37578ZM8.2 9.37578C8.35 9.37578 8.45 9.27578 8.45 9.12578V5.17578C8.45 5.02578 8.35 4.92578 8.2 4.92578C8.05 4.92578 7.95 5.02578 7.95 5.17578V9.12578C7.95 9.25078 8.075 9.37578 8.2 9.37578ZM10.8 9.37578C10.95 9.37578 11.05 9.27578 11.05 9.12578V5.17578C11.05 5.02578 10.95 4.92578 10.8 4.92578C10.65 4.92578 10.55 5.02578 10.55 5.17578V9.12578C10.55 9.25078 10.675 9.37578 10.8 9.37578ZM13.325 9.37578C13.475 9.37578 13.575 9.27578 13.575 9.12578V5.17578C13.575 5.02578 13.475 4.92578 13.325 4.92578C13.175 4.92578 13.075 5.02578 13.075 5.17578V9.12578C13.075 9.25078 13.175 9.37578 13.325 9.37578ZM15.65 9.37578C15.8 9.37578 15.9 9.27578 15.9 9.12578V5.17578C15.9 5.02578 15.8 4.92578 15.65 4.92578C15.5 4.92578 15.4 5.02578 15.4 5.17578V9.12578C15.4 9.25078 15.525 9.37578 15.65 9.37578Z" fill={color} />
    </svg >

);

CableDropIcon.propTypes = {
    svgProps: PropTypes.object,
    color: PropTypes.string
}
CableDropIcon.defaultProps = {
    svgProps: {
        width: "32",
        height: "32",
    },
    color: "#323232"
}

export default CableDropIcon;