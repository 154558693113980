/**
 * AUTH0 related constants
 * @file auth0.js
 * @description Public configuration options for Auth0
 * @author Utkarsh Gupta
 * @since 26 Jun 2022
 */

const AUTH0 = {
  DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN || "login.dev.shastacloud.com",
  CLIENTID: process.env.REACT_APP_AUTH0_CLIENTID || "vSHv9HVBHgTTXgGYsCgbGz8EXP4W1Z12",
  AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE || "http://shastadev.com"
}



export default AUTH0;