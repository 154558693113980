import { Group, Image, Rect, Text } from "react-konva";
import useImage from "use-image";
// import Infra from "./infraIcon.svg";
import Infra from "../../../../../../assets/images/icons/cable_drop_color.svg";
// import InfraSelected from "./infraIconSelected.svg";
import InfraSelected from "../../../../../../assets/images/icons/cable_drop.svg";
import PENCIL_ICON from "../../../../../../assets/images/icons/EditPen.svg";
import { useSelector } from "react-redux";
import { Point } from "../../../utils";
import { COLORS } from "../../../constants";
import { Html } from "react-konva-utils";
import { useEffect, useRef, useState } from "react";
import { usePreventZoom } from "../../../../../../utility/Utils";
function truncate(val, n) {
  if (val.length > n) {
    return val.slice(0, n);
  }
  return val;
}

/**
 * Access Point
 * @param {{
 *  data: { infra_type_id: number, x: number, y: number },
 *  isSelected: boolean,
 *  scaleFactor: number,
 *  onClick: () => void
 * }} props 
 */
const DropCable = (props) => {

  const infraTypes = useSelector(store => store.infraTypes.data);
  const [imgr] = useImage(Infra);
  const [imgrS] = useImage(InfraSelected);
  const [name, setName] = useState(props.data?.name ? props.data?.name : '')
  const [showInput, setShowInput] = useState(false)
  const firstRef = useRef(true)

  usePreventZoom()

  useEffect(() => {
    if (!firstRef.current) {
      props.updateCableName(props.uuid, name)
    }
    firstRef.current = false
  }, [name])


  return (

    <Group

      x={props.data.location.x * props.scaleFactor}
      y={props.data.location.y * props.scaleFactor}
      width={15}
      height={15}

      draggable={props.moveCableDrop && (props.uuid == props.selectedCableDrop)}
      onDragEnd={
        (e) => {
          const newPosition = new Point(
            e.target.x() / props.scaleFactor,
            e.target.y() / props.scaleFactor
          );
          props.translateCableDrop(props.uuid, newPosition);
        }
      }
      onClick={props.onClick}
      onMouseEnter={(e) => {
        const container = e.target.getStage().container();
        if (props.moveCableDrop)
          container.style.cursor = "pointer";
      }}
      onMouseLeave={(e) => {
        const container = e.target.getStage().container();
        container.style.cursor = "default";
      }}
    >
      <Rect
        height={15}
        width={15}
        x={0}
        y={0}
        strokeWidth={1}
        // offsetX={20}
        // offsetY={20}
        stroke={props.isSelected ? COLORS.PRIMARY : COLORS.CABLE_DROP_COLOR}
        fill={props.isSelected ? COLORS.CABLE_DROP_COLOR : COLORS.CABLE_DROP_COLOR}
        scaleX={1 / props.stageReference.current.scaleX()}
        scaleY={1 / props.stageReference.current.scaleY()}
      />

      {
        props.isSelected &&
        <Html
          divProps={{ style: {} }}
          transformFunc={(attrs) => {
            const newAttrs = { ...attrs }
            newAttrs.scaleX = 2
            newAttrs.scaleY = 2
            return newAttrs
          }}

        >
          <div
            onWheel={e => {

            }}


            style={{
              zoom: 'reset',
              width: '120px',
              minHeight: '30px',
              top: '0.8rem',
              position: 'absolute',
              padding: '0.2em 0.5em',
              backgroundColor: 'white',
              border: '1px solid #d4d4d4',
              borderRadius: '3px'
            }}
          >

            <div className={`d-flex justify-content-between user-select-none`} style={{ fontSize: '0.5em' }}>
              <span style={{ color: 'black', fontWeight: '600' }}>
                {
                  !showInput ?
                    "Outlet Label" : "Enter Outlet label"
                }
              </span>
              {
                !showInput ?
                  <img
                    className="cursor-pointer"
                    onClick={(e) => {
                      setShowInput(true)
                    }} src={PENCIL_ICON} height={'8px'} />
                  : <span
                    onClick={(e) => {
                      setShowInput(false)
                    }}
                    style={{ fontSize: '1.6em' }}
                    class="material-symbols-outlined text-primary cursor-pointer">
                    done
                  </span>
              }
            </div>

            {
              showInput ?
                <input
                  className="cable-name-input"
                  style={{ width: '100%', height: '2em' }}
                  value={name}
                  onKeyDown={(e) => {
                    if (e.code == "Enter") {
                      setShowInput(prev => !prev)
                    }
                  }}
                  onChange={(e) => {
                    setName(e.target.value)
                  }} />
                : <span className="user-select-none" style={{ fontSize: '0.5em', color: '#636363' }}>{name ? name : 'No Name'}</span>
            }
          </div>
        </Html>

      }
      {/* <Rect height={40} width={40} x={0} y={0} strokeWidth={1} stroke={props.isSelected ? "white" : "#5279CE"} cornerRadius={2} fill={props.isSelected ? "#5279CE" : "white"} /> */}
      {/* <Image image={props.isSelected ? imgrS : imgr} height={26} width={26} offsetX={-7} offsetY={-7} /> */}
      {/* <Text offsetX={-2} offsetY={-26} fontSize={9} fill={props.isSelected ? "white" : "#5279CE"} text={truncate(infraTypes.find(item => item.infraTypeId === props.data.infraTypeId)?.infraType ?? "Unknown", 7)} /> */}
    </Group >



  );
};

DropCable.propTypes = {};
DropCable.defaultProps = {};

export default DropCable;