import { Group, Image, Rect, Text } from "react-konva";
import useImage from "use-image";
// import Infra from "./infraIcon.svg";
import Infra from "../../../../../assets/images/icons/APColored.svg";
// import InfraSelected from "./infraIconSelected.svg";
import InfraSelected from "../../../../../assets/images/icons/APColoredSelected.svg";
import { useSelector } from "react-redux";
import { Point } from "../../utils";
import { COLORS } from "../../constants";
import { Html } from "react-konva-utils";

function truncate(val, n) {
  if (val.length > n) {
    return val.slice(0, n);
  }
  return val;
}

/**
 * Access Point
 * @param {{
 *  data: { infra_type_id: number, x: number, y: number },
 *  isSelected: boolean,
 *  scaleFactor: number,
 *  onClick: () => void
 * }} props 
 */
const AccessPoint = (props) => {
  const infraTypes = useSelector(store => store.infraTypes.data);
  const [imgr] = useImage(Infra);
  const [imgrS] = useImage(InfraSelected);
  return (
    <Group
      x={props.data.location.x * props.scaleFactor}
      y={props.data.location.y * props.scaleFactor}
      draggable={props.moveAp && props.uuid == props.selectedAp}
      onDragEnd={
        (e) => {
          const newPosition = new Point(
            e.target.x() / props.scaleFactor,
            e.target.y() / props.scaleFactor
          );
          props.translateAp(props.uuid, newPosition);
        }
      }
      onClick={props.onClick}
      onMouseEnter={(e) => {
        const container = e.target.getStage().container();
        if (props.moveAp)
          container.style.cursor = "pointer";
      }}
      onMouseLeave={(e) => {
        const container = e.target.getStage().container();
        container.style.cursor = "default";
      }}

    >

      <Rect
        x={0}
        y={0}
        width={15}
        height={15}
        offsetX={4}
        offsetY={0}
        strokeWidth={1}
        cornerRadius={10}
        stroke={props.isSelected ? COLORS.PRIMARY : COLORS.AP_COLOR}
        fill={props.isSelected ? COLORS.AP_COLOR : COLORS.AP_COLOR}

        scaleX={1 / props.stageReference.current.scaleX()}
        scaleY={1 / props.stageReference.current.scaleY()}
      />
      {
        props.isSelected &&
        <Html
          divProps={{ style: {} }}
          transformFunc={(attrs) => {
            const newAttrs = { ...attrs }
            newAttrs.scaleX = 2
            newAttrs.scaleY = 2
            return newAttrs
          }}

        >
          <div


            style={{
              zoom: 'reset',
              width: '90px',
              minHeight: '20px',
              top: '-1.5rem',
              left: '0.5rem',
              position: 'absolute',
              padding: '0.2em 0.5em',
              backgroundColor: 'white',
              border: '1px solid #d4d4d4',
              borderRadius: '3px'
            }}
          >

            <div className="" style={{ fontSize: '0.5em' }}>

              <div className="d-flex align-items-center">
                <div style={{ fontSize: '5px' }}>Model : &nbsp;</div>
                <span>
                  {truncate(infraTypes.find(item => item.infraTypeId === props.data.infraTypeId)?.infraType ?? "Unknown", 14)}
                </span>
              </div>
              <div className="d-flex align-items-center">
                <div style={{ fontSize: '5px' }}>Type : &nbsp;</div>
                <span>
                  {infraTypes.find(item => item.infraTypeId === props.data.infraTypeId)?.type}
                </span>
              </div>

            </div>
          </div>

        </Html>
        // <Text
        //   offsetX={-10}
        //   offsetY={5}
        //   fontSize={12}
        //   fill={"#444"}
        //   text={truncate(infraTypes.find(item => item.infraTypeId === props.data.infraTypeId)?.infraType ?? "Unknown", 7)}
        //   scaleX={1 / props.stageReference.current.scaleX()}
        //   scaleY={1 / props.stageReference.current.scaleY()}
        // />
      }
    </Group>
    // {/* <Rect height={40} width={40} x={0} y={0} strokeWidth={1} dash={[2, 2]} stroke={props.isSelected ? "white" : "#5279CE"} cornerRadius={2} fill={props.isSelected ? "#5279CE" : "white"} /> */ }
    // {/* <Image image={props.isSelected ? imgrS : imgr} height={26} width={26} offsetX={-7} offsetY={0} /> */ }
  );
};

AccessPoint.propTypes = {};
AccessPoint.defaultProps = {};

export default AccessPoint;