/***
 *
 * Controller class for user.
 * @file FirmwarePopup.js
 * @description FirmwarePopup component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React from "react";
// import PropTypes from 'prop-types';
import "./FirmwarePopup.scss";
import ToolTip from "react-portal-tooltip";
import { useState } from "react";
import {  Progress, Row } from "reactstrap";
import { firmwareChartColors, firmwareChartKeys, firmwareChartLabels } from "../../utility/constants";

const FirmwarePopup = (props) => {
  const {data} = {...props};
  const [active, setActive] = useState(false);

  return (
    <div id={`tooltip-${props.tooltipId}`} onMouseOver={() => setActive(true)} onMouseLeave={() => setActive(false)}>
      {props.children}
      <ToolTip active={active && (data?.totalInfras>0)} position="left" arrow="bottom" parent={`#tooltip-${props.tooltipId}`}>
      <div style={{width:"350px", padding:"0.5rem 1rem"}}>
        <Progress className="mt-50" style={{height:"12px"}} multi>
          {firmwareChartKeys.map((it, index) => {          
            return (<Progress
              style={{
                backgroundColor: firmwareChartColors[it],
                height: "12px"
              }}
            key={index} bar value={(data[it]/data?.totalInfras) * 100}>
            </Progress>)
          } 
          )}
        </Progress>

        <div className="text-center pl-50">
          <Row className="my-1">
              {firmwareChartKeys.map((it, index) => {          
                  return ((((data[it]/data?.totalInfras) * 100) > 0) && 
                    <div className="d-flex align-items-center">
                      <div className="" style={{height:"5px", width:"20px",marginRight: "0.5rem", backgroundColor: firmwareChartColors[it]}}>&nbsp;</div>
                      <div className="font-weight-bolder mr-50" style={{fontSize:"0.75rem"}}>{((data[it]/data?.totalInfras) * 100).toFixed(0)}% </div>
                      <div className="mr-1" style={{fontSize:"0.75rem"}}>{firmwareChartLabels[index]}</div>
                    </div>
                  )
                })} 
          </Row>
        </div>
      </div>
      </ToolTip>
    </div>
  )
}

FirmwarePopup.propTypes = {};

FirmwarePopup.defaultProps = {};

export default FirmwarePopup;
