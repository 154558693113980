/***
 *
 * Controller class for user.
 * @file Header.js
 * @description Header component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import React from "react";
import PropTypes from "prop-types";
import "./Header.scss";
import { Tabs } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';

import {
  Row,
  Col,
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu
} from "reactstrap";
import { activeOrgActions } from "../../../../redux/slices";

const dateOption = [{ value: 0, label: "Today" }, { value: 6, label: "Last 7 Days" }, { value: 29, label: "Last 30 Days" }]

const Header = (props) => {

  const infraId = useSelector((store) => store.oneInfra.data.infraItemId);
  const venueId = useSelector((store) => store.oneInfra.data.venueId);
  const activeOrg = useSelector((store) => store.activeOrg.data);
  const activeOrgId = useSelector((store) => store.activeOrg.data.orgId);
  const orgDateRange = useSelector(store => store.activeOrg.meta.dateRange);
  const permissions = useSelector(store => store?.rbac?.permissions);
  const venuePermissions = useSelector(store => store?.rbac?.venuePermissions);
  const dispatch = useDispatch();

  return (
    <div className="Header" data-testid="Header">
      <Row>
        <Col className="d-flex justify-content-between align-items-center m-auto">
          <div>
            <Tabs
              data={[
                {
                  path: `/organization/${activeOrgId}/infra/${infraId}/`,
                  text: "Overview",
                },
                ...permissions?.manageInfrastats?.view ?
                  [
                    //   {
                    //   path: `/organization/${activeOrgId}/infra/${infraId}/insight/`,
                    //   text: "Insight",
                    // },
                    {
                      path: `/organization/${activeOrgId}/infra/${infraId}/events/`,
                      text: "Timeline",
                    }] : [],
                // {
                //   path: `/organization/${activeOrgId}/infra/${infraId}/details/`,
                //   text: "Details",
                // },
                ...(venuePermissions[venueId]?.network?.view == null ? permissions?.manageNetwork?.view : venuePermissions[venueId]?.network?.view) ?
                  [{
                    path: `/organization/${activeOrgId}/infra/${infraId}/networks/`,
                    text: "Network"
                  }] : [],
                ...(venuePermissions[venueId]?.device?.view == null ? permissions?.manageDevice?.view : venuePermissions[venueId]?.device?.view) ?
                  [{
                    path: `/organization/${activeOrgId}/infra/${infraId}/clients/`,
                    text: "Clients"
                  }] : [],
                ...(activeOrg.vxLAN && venuePermissions[venueId]?.profile?.create == null ? permissions?.manageProfile?.create : venuePermissions[venueId]?.profile?.create) ?
                  [{
                    path: `/organization/${activeOrgId}/infra/${infraId}/vxlan/`,
                    text: "VxLAN"
                  }] : [],
                ...(venuePermissions[venueId]?.infra?.create == null ? permissions?.manageInfra?.create : venuePermissions[venueId]?.infra?.create) ?
                  [{
                    path: `/organization/${activeOrgId}/infra/${infraId}/utilities/`,
                    text: "Utilities",
                  }] : [],
                {
                  path: `/organization/${activeOrgId}/infra/${infraId}/settings/`,
                  text: "Settings",
                },
              ]}
            />
          </div>
          <div>
            {
              props.showDateRange &&
              <Select className="daterange-select" isClearable={false} isSearchable={false} defaultValue={dateOption.find(it => it.value == orgDateRange)} options={dateOption} onChange={(event) => { dispatch(activeOrgActions.setDateRange(event.value)); }} />
            }
          </div>
          <span>
            {
              ((venuePermissions[venueId]?.infra?.delete == null ? permissions?.manageInfra?.delete : venuePermissions[venueId]?.infra?.delete)
                || permissions?.manageTicket?.create) &&
              props.showDropdown &&
              <UncontrolledButtonDropdown className="p-0" direction="left">
                <DropdownToggle color='white' className="smallWidth text-right">
                  <span className="material-symbols-outlined text-primary">more_vert</span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-right">
                  {permissions?.manageTicket?.create && <DropdownItem tag='span' onClick={
                    props.onSupport
                  }>
                    Support
                  </DropdownItem>}
                  {(venuePermissions[venueId]?.infra?.delete == null ? permissions?.manageInfra?.delete : venuePermissions[venueId]?.infra?.delete) &&
                    <DropdownItem tag='span' onClick={
                      props.onDeleteClick
                    }>
                      Delete
                    </DropdownItem>}
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            }
          </span>
        </Col>
      </Row>
    </div>
  );
};

Header.propTypes = {
  heading: PropTypes.string,
};

Header.defaultProps = {
  heading: "Infrastructure",
};

export default Header;
