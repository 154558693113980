/***
 *
 * Controller class for user.
 * @file IdentityPermissions.js
 * @description IdentityPermissions component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import React, { useState } from "react";
// import PropTypes from 'prop-types';
import "./IdentityPermissions.scss";
import { Button, Col, Container, Label, Row } from "reactstrap";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import createRequest, { services } from "../../../services";
import { useDispatch, useSelector } from "react-redux";
import { CatchedWebError } from "../../../configs";
import { Toggle, VeryFancyLoader } from "../../../components";
import { ReactComponent as Warning } from "../../../assets/images/icons/Warning.svg";
import moment from "moment";
import { MANAGE, NONE, VIEW, isAdmin } from "../../../utility/constants";
import { breadcrumbActions } from "../../../redux/slices";
import { make_custom_toast } from "../../../helpers/toasts";
import { TICKET_CATEGORY } from "../../Tickets/TicketConstants";
import { createErrorContext } from "../../../configs/ErrorContextMaker";

const permissionsData = [
  { label: "Scan", apiProperty: "scan" },
  { label: "Infrastructure", apiProperty: "infra" },
  { label: "Network", apiProperty: "network" },
  { label: "Device", apiProperty: "device" },
  { label: "Profiles", apiProperty: "profile" },
]

let permissionLevels = {};
permissionLevels[NONE] = { value: NONE, label: 'None' };
permissionLevels[VIEW] = { value: VIEW, label: 'View' };
permissionLevels[MANAGE] = { value: MANAGE, label: 'Manage' };

const IdentityPermissions = () => {
  const { identityId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const activeVenueId = useSelector(store => store.activeVenue.data.venueId);
  const activeVenue = useSelector(store => store.activeVenue.data);
  const roles = useSelector(store => store.rbac.data.systemRoles);
  const venuecrumb = useSelector(store => store.breadcrumb.venuecrumb);
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);

  const [identityData, setIdentityData] = useState([]);
  const [admin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [posting, setPosting] = useState(false);
  const [roleNames, setRoleNames] = useState([]);
  const [permissions, setPermissions] = useState({});

  useEffect(() => {
    dispatch(breadcrumbActions.setData([...venuecrumb.parentPath,
    {
      text: activeVenue?.venueName,
      active: false,
    },
    {
      path: `/organization/${activeOrgId}/venues/${activeVenue?.venueId}/admin/`,
      text: "Admin",
      active: false,
    },
    {
      path: `/organization/${activeOrgId}/venues/${activeVenue?.venueId}/admin/${identityId}`,
      text: identityData.userName ?? '',
      active: true,
    }
    ]));
  }, [activeVenue.venueId, venuecrumb, identityData.userName])

  useEffect(() => {
    if (!!identityData?.permissions) {
      setPermissions(identityData.permissions)
    }
  }, [identityData])

  const findRoleNames = (roleIds, roles) => {
    const roleNames = roleIds.map(roleId => {
      return roles?.find(role => role.roleId === roleId)?.role ?? ''
    })
    return roleNames;
  }

  useEffect(() => {
    if (!!identityData?.roleIds && !!roles) {
      setRoleNames(findRoleNames(identityData?.roleIds, roles));
    }
  }, [identityData, roles])

  useEffect(() => {
    const { run, controller } = createRequest(services.organization.GET_IDENTITY_PERMISSIONS, [activeVenueId, identityId]);
    setLoading(true);
    run()
      .then(response => {
        setIdentityData(response.data);
        setIsAdmin(isAdmin(response.data.roleIds[0]));
        setLoading(false);
      })
      .catch(err => {
        let errorMessage = (new CatchedWebError(err)).message
        make_custom_toast('error', 'User Permissions', errorMessage)
        navigate(-1)
      })
    return () => controller.abort();
  }, [identityId])

  const PostPermissions = () => {
    let postPermissions = { ...permissions };
    if (postPermissions.infra == null) postPermissions.infra = NONE;
    if (postPermissions.scan == null) postPermissions.scan = NONE;
    if (postPermissions.network == null) postPermissions.network = NONE;
    if (postPermissions.profile == null) postPermissions.profile = NONE;
    if (postPermissions.device == null) postPermissions.device = NONE;
    const { context, run } = createRequest(services.organization.POST_IDENTITY_PERMISSIONS, [activeVenueId, identityData.identityId], { permissions: identityData.venueLevelPermission ? postPermissions : {} })
    setPosting(true);
    run()
      .then(response => {
        setIdentityData((prevState) => {
          return { ...prevState, permissions: postPermissions }
        });
        make_custom_toast('success', 'User Venue Level Permissions', 'Permissions updated.')
      })
      .catch(err => {
        let errorMessage = new CatchedWebError(err).message
        const apiContext = createErrorContext(context, 'Update Firmware Schedule', TICKET_CATEGORY.VENUE, err)
        make_custom_toast('error', 'Updating User Venue Permissions', errorMessage, true, 'Create Ticket', () => {
          navigate(
            `/organization/${activeOrgId}/support/ticket/createticket`,
            {
              state: {
                ticketContext: apiContext
              },
            }
          );
        })
      })
      .finally(() => {
        setPosting(false);
      })
  }

  const Permission = (props) => {
    const [value, setValue] = useState({});

    useEffect(() => {
      setValue(
        (permissions[props.apiProperty] !== null &&
          permissions[props.apiProperty] !== undefined)
          ? permissionLevels[permissions[props.apiProperty]]
          : permissionLevels[NONE]
      )
    }, [props.apiProperty])

    return (
      <div className="col-lg-3 my-50">
        <Label>{props.label}</Label>
        <Select
          className="select-dropdown"
          options={Object.values(permissionLevels)}
          value={value}
          isDisabled={props.disable}
          onChange={(event) => {
            setPermissions((prevState) => {
              let newState = { ...prevState };
              newState[props.apiProperty] = event.value;
              return newState;
            })
          }}
        />
      </div>
    );
  }

  const DataCell = ({ children, className, maxWidth, title }) => {
    return (
      <Row
        className={"data-cell mt-50 fw-medium font-size--16 text-overflow-ellipsis " + (className ? className : '')}
        style={{ maxWidth: maxWidth ?? null }}
        title={title}>
        <span className="heading-bold-style-text">{children}</span>
      </Row>
    );
  }

  const DataHeader = ({ children }) => {
    return (
      <Row className="mb-50 fw-medium">
        {children}
      </Row>
    );
  }

  if (loading) {
    return <div className="pt-5 d-flex justify-content-center align-items-center"><VeryFancyLoader /></div>
  }

  return (
    <div className="IdentityPermissions bg-white mt-1 p-1" data-testid="IdentityPermissions">
      <Container>
        <Row className="d-flex justify-content-between">
          <Col>
            <Container >
              <DataHeader>Name</DataHeader>
              <DataCell>{identityData.userName}</DataCell>
            </Container>
          </Col>
          <Col>
            <Container>
              <DataHeader>Role</DataHeader>
              <DataCell maxWidth={'250px'} title={roleNames} className='d-block'>
                {roleNames.join(',')}
              </DataCell>
            </Container>
          </Col>
          {/* <Col>
            <Container>
              <DataHeader>Venue</DataHeader>
              <DataCell className="text-primary">Infosynth Data...</DataCell>
            </Container>
          </Col> */}
          <Col>
            <Container>
              <DataHeader>Status</DataHeader>
              <DataCell>{identityData?.blocked ? 'Disabled' : 'Enabled'}</DataCell>
            </Container>
          </Col>
          {/* <Col>
            <Container>
              <DataHeader>Permission Level</DataHeader>
              <DataCell>Single venue only</DataCell>
            </Container>
          </Col> */}
          {identityData.lastLogin
            ? <Col>
              <Container>
                <DataHeader>Last Login</DataHeader>
                <DataCell>{moment(identityData.lastLogin).fromNow()}</DataCell>
              </Container>
            </Col>
            : null}
        </Row>
      </Container>
      <h3 className="pl-1 pt-1 fw-medium headings">Permission</h3>
      <div className="ml-1">
        <Toggle label="Venue Level Permission" disabled={admin} value={identityData.venueLevelPermission} onClick={() => {
          if (identityData.venueLevelPermission) {
            setPermissions({});
          }
          else {
            setPermissions({ ...identityData.permissions });
          }
          setIdentityData(prevState => {
            return {
              ...prevState,
              venueLevelPermission: !prevState.venueLevelPermission
            }
          })
        }} displayText={false} />
      </div>
      {(identityData.venueLevelPermission || admin) ? <></> : <span><Warning className="mr-50 ml-1" />Warning: Organization level permissions are defined for this venue.</span>}
      {admin ? <span ><Warning className="mr-50 ml-1" />Warning: Permissions cannot be set for Admin users.</span> : <></>}
      {
        permissionsData?.map((item, key) => {
          return <Permission key={key} disable={admin || !identityData.venueLevelPermission} label={item.label} apiProperty={item.apiProperty} />
        })
      }
      <div className="footer d-flex justify-content-end">
        <Button.Ripple className="small-add-button" color='primary'
          onClick={() => { navigate(-1) }}>
          Discard
        </Button.Ripple>
        <Button.Ripple color='primary' className="small-add-button" onClick={() => PostPermissions()}>
          Save
        </Button.Ripple>
      </div>
    </div>
  );
};

IdentityPermissions.propTypes = {};

IdentityPermissions.defaultProps = {};

export default IdentityPermissions;
