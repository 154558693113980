/**
 * Text-Input
 * @file TextInputWithIcon.js
 * @description Formik compatible text-input styled with reactstrap components.
 * @author Utkarsh Gupta
 * @since 29 Jun 2022
 */

import { useField } from "formik";
import React from "react";
import { FormFeedback, Input, Label } from "reactstrap";
import PropTypes from 'prop-types';
import "./TextInputWithIcon.scss";

const TextInputWithIcon = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const elementProps = { ...props, isrequired: "sampled" };
  delete elementProps.noLabel
  delete elementProps.labelfontauth
  delete elementProps.overallClasses
  return (
    <div className={"TextInputWithIcon " + (props.noBottomMargin ? "" : " mb-2 ") + (!!props.overallClasses ? props.overallClasses : '')} data-testid="TextInputWithIcon">
      {!props.noLabel &&
        <Label
          className={"labelfont" +
            (props?.labelfontauth === true ? "auth" : '')}
          for={props.id || props.name}>{label}{props.isrequired ?
            <span className="text-danger">*</span> : <></>}</Label>}
      <div style={{ position: 'relative' }}>
        <div className="textinput-icon" >{props.icon}</div>
        <Input className="textinput-with-icon pl-1 w-100" invalid={!!((props.ignoreTouch || meta.touched) && meta.error)} autoComplete="off" {...field} {...elementProps} />
        {((props.ignoreTouch || meta.touched) && meta.error) ? (
          <FormFeedback>{meta.error}</FormFeedback>
        ) : null}
      </div>
    </div>
  );
};

TextInputWithIcon.propTypes = {
  label: PropTypes.string,
  props: PropTypes.any,
  isrequired: PropTypes.bool,
  labelfontauth: PropTypes.bool
};

TextInputWithIcon.defaultProps = {};

export default TextInputWithIcon;
