/***
 *
 * Controller class for user.
 * @file VenueSelector.js
 * @description VenueSelector component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import React, { createContext, useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import { Alert, Input, InputGroup } from "reactstrap";
import createRequest, { services } from "../../services";
import { SearchIconAddon } from ".."
import PropTypes from 'prop-types';
import "./VenueSelector.scss";
import { VeryFancyLoader } from "..";
import { CatchedWebError } from "../../configs";
import { BUSINESS_VIEW, MANAGE, SCROLL_MORE_TEXT } from "../../utility/constants";
import { ReactComponent as Infrastructure } from "../../assets/images/icons/Infrastructure-SM.svg";
import { VenueIcon } from "../../pages/Venue/VenueTree";
import { Highlight } from "../VenueInfraWidget";
import { orgtypesIcons } from "../OrgWidget";


const ExpandableVenueItem = (props) => {
  const [subList, setSubList] = useState(props?.item?.children ?? []);
  // const [subListLoading, setSubListLoading] = useState(false);
  // const [subListError, setSubListError] = useState(null);
  const [subListOpened, setSubListOpened] = useState(props?.item?.children?.length > 0 ? true : false);
  const [hasMore, setHasMore] = useState(true);

  const { showCount } = useContext(VenueSelectorContext);

  const venuePer = useSelector(store => store?.rbac?.data?.venues?.find(it => it.venueId == props.item.venueId)?.permissions);

  useEffect(() => {
    if (props?.item?.children?.length > 0) {
      setSubListOpened(true)
      setSubList(props?.item?.children)
    }
    else {
      setSubListOpened(false)
      setSubList([])
    }
  }, [props?.item?.children])


  let subCount = 0;
  const getSubList = (offset = 0) => {
    const limit = 20;
    const { controller, run } = props?.item?.venueId == null ?
      createRequest(
        services.telemetry.GET_ORG_VENUE_LIST,
        [
          props?.item?.orgId,
          offset,
          limit,
          props?.search ?? '',
          'All',
          undefined,
          undefined,
          {},
          ["Infrastructure", ...(props?.getAddress ? ['VenueAddress'] : [])],
          BUSINESS_VIEW
        ]
      )
      : createRequest(
        services.telemetry.GET_VENUE_CHILD,
        [
          props?.item?.venueId,
          offset,
          limit,
          props?.search ?? '',
          BUSINESS_VIEW,
          ['Infrastructure', ...(props?.getAddress ? ['VenueAddress'] : []), ...(props?.showChild ? ['Organization'] : [])],
          "All",
          undefined,
          {},
          false,
          false,
          false,
        ]
      );
    // setSubListLoading(true);
    run()
      .then(response => {
        // setSubListError(false);
        subCount += response?.data?.length
        if (offset === 0)
          setSubList([...response.data]);
        else
          setSubList(prevState => [...prevState, ...response.data])
        if (response.data.length == limit) getSubList(subCount);
        else setHasMore(false);
      })
      .catch(err => {
        // setSubListError((new CatchedWebError(err)).message);
      })
      .finally(() => {
        // setSubListLoading(false);
      });
    return controller;
  }
  useEffect(() => {
    if (!props?.item?.children || props?.item?.children?.length == 0) {
      if (subListOpened) {
        let controller = getSubList(0);
        return () => {
          controller?.abort();
        }
      }
      else {
        setSubList([]);
        setHasMore(true);
        subCount = 0;
      }
    }
  }, [subListOpened, props.item.orgId, props.item.venueId]);

  useEffect(() => {

    if (props.clearable && !props.selectedVenue && props.item.defaultVenue)
      props.setSelectedVenue(props.item)

  }, [props.selectedVenue])
  return (
    <li>
      <div className={`list-top-div d-flex align-items-center hoverable ${props.isRoot ? 'bg-color--light-blue top-border' : ''}`}>
        {
          Array(props?.level ?? 0)?.fill()?.map((levelItem, index) => {
            if (props?.isParentLast[index]) {
              return <div style={{ marginLeft: '1.8rem' }} key={index}></div>
            }
            if (props?.isLastElement && props?.level - index === 1) {
              return (
                <div className="d-flex align-items-start h-100" style={{ marginLeft: index === 0 ? '0.8rem' : '1.8rem' }} key={index}>
                  <div className="h-50 border-left-dark" style={{ width: '0rem' }}></div>
                  <div className='h-50 border-bottom-dark' style={{ width: '1rem' }}></div>
                </div>
              )
            }
            return <div className="d-flex align-items-center h-100" style={{ marginLeft: index === 0 ? '0.8rem' : '1.8rem' }} key={index}>
              <div className="h-100 border-left-dark" style={{ width: '0rem' }}></div>
              {props?.level - index === 1 ?
                <div className='border-bottom-dark' style={{ width: '1rem' }}></div>
                : null
              }
            </div>
          })
        }
        <div className={`list-item pr-1 py-custom `}>
          {!props.item.leafNode && <div className="material-symbols-outlined cursor-pointer text-primary" onClick={() => { setSubListOpened(opened => !opened); }}>
            {subListOpened ? "expand_more" : "chevron_right"}
          </div>}
          <span className={"hover-bold iconSpan d-flex align-items-center w-100 " + (props.item.leafNode ? "padding-left-chevron " : '')
            + (props?.item?.venueId != null ? 'cursor-pointer' : '')
          } onClick={() => {
            if (props?.item?.venueId == null)
              return
            if ((venuePer == null || venuePer?.infra == null || venuePer.infra == MANAGE))
              props.selectedVenue?.venueId === props.item.venueId ? props.setSelectedVenue(null) : props.setSelectedVenue(props.item);
            props.setSelectModal && setTimeout(
              () => props.setSelectModal(false), 500)
          }}>
            {
              props?.item?.venueId != null ?
                (venuePer == null || venuePer?.infra == null || venuePer.infra == MANAGE) && <input type='radio' className='radio-input cursor-pointer mr-50'
                  checked={!!props.selectedVenue && props.selectedVenue.venueId == props.item.venueId}
                  onChange={() => { }}
                />
                : null
            }
            {
              props?.item?.venueId == null ?
                orgtypesIcons[props?.item?.orgTypeId] :
                VenueIcon[props.item.venueType]
            }
            &nbsp;&nbsp;
            <div className="d-flex justify-content-between w-100">
              <div>
                <Highlight search={(props?.search ?? '')}>
                  {props?.item?.venueName ?? props?.item?.orgName}
                </Highlight>
                {/* {
                props.showChild &&
                <span> ({props?.item?.orgName})</span>
              } */}
                {/* <span className="small">{subListLoading && <Spinner size="sm" />}</span> */}
              </div>
              {props?.item?.venueId != null && showCount ?
                <div className="d-flex align-items-center">
                  <span>
                    <span
                      className={`online-infra-count ${props?.item?.onlineInfras > 0
                        ? `text-success font-weight--600`
                        : ''}`}>
                      {props?.item?.onlineInfras}
                    </span>
                    &nbsp;/&nbsp;
                    {props?.item?.totalInfras}
                  </span>
                  <Infrastructure className="make-black-icon ml-50" style={{ width: '14px' }} />
                </div>
                : null}
            </div>
          </span>
        </div>
      </div>
      {subListOpened &&
        // !subListLoading &&
        (
          // subListError ?
          // "Can't load sub-venues: " + subListError.toString() :
          <div>
            <ExpandableVenueList
              list={subList}
              showChild={props.showChild ? true : false}
              selectedVenue={props.selectedVenue}
              setSelectedVenue={props.setSelectedVenue}
              setSelectModal={props.setSelectModal}
              clearable={props.clearable}
              search={props?.search ?? ''}
              level={(props?.level ?? 0) + 1}
              getAddress={props?.getAddress ? true : false}
              isParentLast={
                (props?.level ?? 0) == 0 ?
                  []
                  : props?.isParentLast != null ?
                    [...props?.isParentLast, props?.isLastElement]
                    : [props?.isLastElement]
              }
            />
          </div>
        )
      }
    </li>
  );
}

const ExpandableVenueList = (props) => {
  // if (props.list && props.list.length === 0) {
  //   return <div className="pl-2 small text-secondary noVenueDiv">
  //     {/* No Children */}
  //     No Venue Found
  //   </div>
  // }
  return (
    <ul className={"venueList mb-0 pl-0"}>
      {props.list && props.list.map((venue, index) => {
        return (
          <ExpandableVenueItem
            key={venue.venueId}
            item={venue}
            isRoot={props.isRoot}
            selectedVenue={props.selectedVenue}
            setSelectedVenue={props.setSelectedVenue}
            setSelectModal={props.setSelectModal}
            clearable={props.clearable}
            showChild={props.showChild ? true : false}
            search={props?.search ? props.search : ''}
            getAddress={props?.getAddress ? true : false}
            level={props.level ?? 0}
            isLastElement={index === props.list.length - 1}
            isParentLast={props?.isParentLast}
          />
        );
      })}
    </ul>
  );
}

ExpandableVenueItem.propTypes = {
  item: PropTypes.any,
  selectedVenue: PropTypes.any,
  setSelectedVenue: PropTypes.any
};

ExpandableVenueList.propTypes = {
  isRoot: PropTypes.any,
  list: PropTypes.any,
  listSetter: PropTypes.any,
  selectedVenue: PropTypes.any,
  setSelectedVenue: PropTypes.any
}

const VenueSelectorContext = createContext(null)

const VenueSelector = (props) => {
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const [venues, setVenues] = useState(null);
  const [venueError, setVenueError] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("")
  const [orgId, setOrgId] = useState((props.orgId ?? activeOrgId))
  let timer;

  const debounce = (callback) => {
    clearTimeout(timer);
    timer = setTimeout(callback, 800);
  }

  useEffect(() => {
    if (props.orgId != null) {
      setOrgId(props.orgId)
    }
    else {
      setOrgId(activeOrgId)
    }
  }, [props.orgId, activeOrgId])

  const getList = (offset = 0) => {
    const { controller, run } = createRequest(services.telemetry.GET_ORG_VENUE_LIST, [
      orgId,
      offset,
      20,
      encodeURIComponent(search),
      "All",
      undefined,
      undefined,
      props?.showChild ? {} : { orgId: [orgId] },
      ['Infrastructure', ...(props?.getAddress ? ['VenueAddress'] : []), ...(props?.showChild ? ['Organization'] : [])],
      BUSINESS_VIEW,
    ]);
    setLoading(true);
    run()
      .then(response => {
        if (response.data.length < 20) {
          setHasMore(false);
        }
        else {
          setHasMore(true)
        }
        setVenueError(null);
        setVenues(prevState => {
          let newData = response?.data || []
          if (offset == 0 || prevState == null || prevState?.length == 0)
            return response.data
          else
            return [...prevState, ...newData]
        });
      })
      .catch(err => {
        setVenueError((new CatchedWebError(err)).message);
      })
      .finally(() => {
        setLoading(false);
      })
      ;
    return controller;
  }

  useEffect(() => {
    const controller = getList(0);
    return () => {
      controller?.abort();
    }
  }, [orgId, search]);

  // useEffect(() => {
  //   if(!props.selectedVenue && venues)
  //     props.setSelectedVenue(venues.find(it => it.defaultVenue));
  // },[props.selectedVenue, venues])

  // useAutoclear(venueError, () => { setVenueError(null); });

  return (
    <VenueSelectorContext.Provider
      value={{
        showCount: props.showCount
      }}
    >
      <React.Fragment>
        <InputGroup className="input-group-merge mb-1">
          <Input type="text" placeholder="Search" onChange={
            (e) => {
              if (e.target.value.length > 1 || e.target.value.length === 0) {
                debounce(() => {
                  setSearch(e.target.value);
                });
              }
            }
          } />
          <SearchIconAddon />
        </InputGroup>
        <div className="VenueSelector" data-testid="VenueSelector">
          {/* Autoclear */}
          <Alert color="danger" isOpen={venueError !== null} toggle={() => { setVenueError(null); }}>
            <div className="alert-body">{venueError}</div>
          </Alert>
          {!venueError && <>
            <InfiniteScroll
              dataLength={(venues?.length ?? 0)}
              next={() => {
                getList(venues?.length)
              }}
              hasMore={hasMore}
              loader={props.noEndText ? '' : SCROLL_MORE_TEXT}
              endMessage={props.noEndText ? '' : <span className="d-flex mt-50">Showing {venues?.length} result(s)</span>}
              scrollableTarget="venue-selector-list"
            >
              <div
                id="venue-selector-list"
              >
                {venues?.length > 0 ?
                  <ExpandableVenueList
                    isRoot
                    list={venues}
                    listSetter={setVenues}
                    selectedVenue={props.selectedVenue}
                    setSelectedVenue={props.setSelectedVenue}
                    setSelectModal={props.setVenueSelectorModal}
                    clearable={props.clearable}
                    showChild={props.showChild ? true : false}
                    getAddress={props?.getAddress ? true : false}
                    search={search}
                    level={0}
                    isParentLast={[]}
                  />
                  : loading ? <div className="d-flex justify-content-center align-items-center h-100">
                    <VeryFancyLoader />
                  </div> : <div className="text-center p-1"><h5>Venue does not exist</h5></div>
                }
              </div>
            </InfiniteScroll>
          </>
          }
        </div>
      </React.Fragment>
    </VenueSelectorContext.Provider>
  );
};

VenueSelector.propTypes = {
  selectedVenue: PropTypes.object,
  setSelectedVenue: PropTypes.func
};

VenueSelector.defaultProps = {};

export default VenueSelector;
