import { Col, Container, Row } from "reactstrap";

import Skeleton from "./Skeleton";

const AreaChartSkeleton = ({ legendCount }) => {
  return (
      <Container>
          <Row className="pb-1">
              <Col xs={12}>
                <Skeleton 
                height={"120px"} 
                /> 
              </Col>
              {/* <Col xs={2} className="pt-1">
                  {[...Array(legendCount).keys()].map(i => (
                    <Skeleton key={i} />
                  ))}
              </Col> */}
          </Row>
      </Container>
  );
};

export default AreaChartSkeleton;