export const CATEGORY_LIST = [
    { value: 1, label: 'Web UI' },
    { value: 2, label: 'Organization' },
    { value: 3, label: 'Venue' },
    { value: 4, label: 'Network' },
    { value: 5, label: 'Clients' },
    { value: 6, label: 'Infrastructure' },
    { value: 7, label: 'Billing' },
    { value: 8, label: 'Deployment' },
    // { value: 7, label: 'Orders' },
]

export const TICKET_CATEGORY = {
    WEBUI: 1,
    ORGANIZATION: 2,
    VENUE: 3,
    NETWORK: 4,
    CLIENTS: 5,
    INFRASTRUCTURE: 6,
    BILLING: 7,
    DEPLOYMENT: 8
    // ORDERS: 7,
}


export const PRIORITY_LIST = [
    { value: 1, label: 'Minor' },
    { value: 2, label: 'Major' },
    { value: 3, label: 'Critical' }
]


export const ISSUE_LIST = [
    { value: 1, label: 'Bug' },
    { value: 2, label: 'Improvement' },
    { value: 3, label: 'New Feature' },
    { value: 4, label: 'Others' },
]


export const TICKET_STATUS_MAPPING = {
    2: 'Open',
    3: 'Pending',
    4: 'Resolved',
    5: 'Closed',
    6: 'In Progress',
    7: 'Waiting on Customer'
}

export const TICKET_PRIORITY_MAPPING = {
    1: 'Minor',
    2: 'Major',
    3: 'Critical',
}

export const TICKET_SEVERITY = {
    MINOR: 1,
    MAJOR: 2,
    CRITICAL: 3

}

export const DAY_SMALL_MAPPING = [
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat"
]

export const SHORT_MONTH = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    "Jun",
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
]

export const TICKET_API_LIMIT = 30

export const TICKET_TABLE_HEADINGS = [
    {
        name: 'TICKET',
        attribute: '',
        width: '8%'
    },
    {
        name: 'SUBJECT',
        attribute: 'subject',
        width: '24%'

    },
    {
        name: 'STATUS',
        attribute: 'status',
        width: '12%'

    },

    {
        name: 'SEVERITY',
        attribute: 'severity',
        width: '9%'
    },

    {
        name: 'CATEGORY',
        attribute: 'category',
        width: '10%'

    },
    {
        name: 'REPORTED BY',
        attribute: 'requesterName',
        width: '12%'

    },

    // {
    //     name: 'CREATED AT',
    //     attribute: 'createdAt',
    //     width: '14%'

    // },
    {
        name: 'LAST UPDATE',
        attribute: 'updatedAt',
        width: '10%'
    },
]
export const editorConfig = {
    readonly: false, // all options from https://xdsoft.net/jodit/docs/,
    placeholder: 'Add Description or paste Screenshot(s)',
    // height: '202px',
    toolbarAdaptive: false,
    disablePlugins: [
        'table',
        'iframe',
        'select',
        'spellcheck',
        'select',
        'redo-undo',
        'search',
        'powered-by-jodit',
        'paste',
        'print',
        'about',
        'preview',
        'fullsize',
        'clipboard',
        'color',
        'indent',
        'justify',
        'symbols',
        'link',
        'hr',
        'wrap-nodes',
        'source',
        'class-span',
        'clean-html',
        'format-block',
        'copyformat',
        'xpath',
        'stat',
        'video',
        'image',
        'file',
        'subscript',
        'speech-recognize',
        'ai-assistant',
        'add-new-line'
    ]
}

export const TD_LINK_TEXT = "Shasta Cloud’s Distribution Partner is TDSynnex for order fulfillment and credit. If you don’t already have an account with TD Synnex you can sign - up here"
export const TD_LINK = "https://www.tdsynnex.com/na/us/become-a-customer/"
export const FILE_UPLOAD_ERROR_TEXT = 'Error occurred while uploading file, check file or try again.'
export const TIME_IN_SECONDS = {
    "1_MIN": 60,
    "24_HOURS": 86400
}

export const CACHED_TICKET_KEY = "RU89NFJKR89NCISCIURESC7843HFFB748"
