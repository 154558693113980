import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ChartCardWithProgress from "./ChartCardWithProgress";
import ChartCardWithDonut from "./ChartCardWithDonut";
import Infra from "../../../assets/images/icons/DashInfra.svg"
import { useEffect, useState } from "react";
import { THIRD_PARTY_TITLE } from "../../../utility/constants";


const InfraChart = ({ series, isLoading, trend, labelStyle, forceFullWidth, dountChart, main, setMain, height, className, onMetricClick, onTotalClick, singleLine = false }) => {
  const activeOrgId = useSelector(state => state.activeOrg.data.orgId);
  const permissions = useSelector(store => store?.rbac?.permissions);
  const navigate = useNavigate();
  const [mainColor, setMainColor] = useState('')
  const [invertTrend, setInvertTrend] = useState(false);

  useEffect(() => {
    //  Series = [Connected, Disconnected,Pending, Demo]
    // main = [0,1,2,3]
    let mainIndex = main || 0 // if main==null then use 0
    if (mainIndex == 0 && series[mainIndex] > 0) { //for Online
      setMainColor('text-success')
    }
    else if (mainIndex == 1 && series[mainIndex] > 0) { // for offline
      setMainColor('text-danger')
    }
    else {
      setMainColor('')
    }

    if(mainIndex == 1)
      setInvertTrend(true);
    else
      setInvertTrend(false);

  }, [series, main])

  if (dountChart) //default value if false
    return <ChartCardWithDonut disableExpand={true} forceFullWidth={forceFullWidth} labelStyle={labelStyle} colors={["rgba(50, 201, 151, 1)", "rgba(206, 82, 156, 1)", "rgba(136, 136, 136, 1)", "rgba(136, 0, 136, 1)"]} isLoading={isLoading} title="Infrastructure" labels={["Online", "Offline", "Never Connected", THIRD_PARTY_TITLE]} main={0} series={series}
      AddAction={
        permissions?.manageInfra?.create
          ? () => {
            navigate(`/organization/${activeOrgId}/infra/add`);
          }
          : null
      }
      SearchAction={
        permissions?.manageInfra?.view
          ? () => {
            navigate(`/organization/${activeOrgId}/infra`);
          }
          : null
      }
    />

  return <ChartCardWithProgress className={className} icon={Infra} mainColor={mainColor} invertTrend={invertTrend} forceFullWidth={forceFullWidth} trend={trend} labelStyle={labelStyle}
    colors={["rgba(50, 201, 151, 1)", "rgba(234,84,85,1)", "rgba(136, 136, 136, 1)", "rgba(136, 0, 136, 1)"]}
    isLoading={isLoading} title="Infrastructure" labels={["Online", "Offline", "Never Connected", THIRD_PARTY_TITLE]}
    main={main ?? 0} setMain={setMain} series={series} height={height}  singleLine={singleLine}
    onMetricClick={onMetricClick} onTotalClick={onTotalClick}
    AddAction={
      permissions?.manageInfra?.create
        ? () => {
          navigate(`/organization/${activeOrgId}/infra/add`);
        }
        : null
    }
    SearchAction={
      permissions?.manageInfra?.view
        ? () => {
          navigate(`/organization/${activeOrgId}/infra`);
        }
        : null
    }
  />
};

InfraChart.defaultProps = { dountChart: false }

export default InfraChart;