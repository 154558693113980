/***
 *
 * Controller class for user.
 * @file TimeProgress.js
 * @description TimeProgress component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import React, { useCallback } from "react";
// import PropTypes from 'prop-types';
import "./TimeProgress.scss";
import { useState } from "react";
import { useEffect } from "react";
import { Alert, Progress } from "reactstrap";
import { useDispatch } from "react-redux";
import { rebootingAction } from "../../redux/slices";

const TimeProgress = (props) => {
  const [progress, setProgress] = useState(0);
  let interval;

  const dispatch = useDispatch();
  useEffect(() => {
    setProgress(0);
  },[props.start])

  useEffect(() =>{
    interval = setTimeout(() => {
      let prog = ((Date.now() - props.start) / (props.end - props.start)) * 100;
      setProgress(prog);
      if(props.end < Date.now())
      { 
        dispatch(rebootingAction.clearRebooting({
          infraId:props.infraId
        }))
      }
    },100)

    return ( () => {
      clearTimeout(interval);
    })
  },[progress])


  return (
    <div className={props.onList? "ListProgress":"TimeProgress"} data-testid="TimeProgress">
      <div className="text-padding">
        Rebooting
      </div>
      <Progress value={progress} />
    </div>
  );
};

TimeProgress.propTypes = {};

TimeProgress.defaultProps = {};

export default TimeProgress;
