import "./Editor.scss";
import PropTypes from "prop-types";
import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";
import { EDITORS, FAKE_WALL_DATA, LAYOUT_TYPE, LAYOUT_TYPES } from "../constants";
import EditorSidebarItem from "../EditorSidebarItem";

import { SidebarIcons } from "../Icons";
import SnappingEditor from "../Editors/SnappingEditor";
import WallAnnotationsEditor from "../Editors/WallAnnotationsEditor";
import AccessPointsEditor from "../Editors/AccessPointsEditor";
import useLayoutsManager from "../Canvas/useLayoutsManager";
import { Alert, Button, DropdownItem, DropdownMenu, DropdownToggle, Input, Modal, ModalBody, ModalHeader, Progress, Spinner, UncontrolledDropdown, UncontrolledTooltip } from "reactstrap";
import { make_toast } from "../../../../helpers";
import createRequest from "../../../../services";
import { wre } from "../../../../services/wre.service";
import { useDispatch, useSelector } from "react-redux";
import { CatchedWebError } from "../../../../configs";
import AnalysisEditor from "../Editors/AnalysisEditor";
import { make_custom_toast } from "../../../../helpers/toasts";
import { Navigate } from "react-router-dom";
import { createErrorContext } from "../../../../configs/ErrorContextMaker";
import { TICKET_CATEGORY } from "../../../Tickets/TicketConstants";
import AvailableLayouts from "../Editors/SnappingEditor/AvailableLayouts";
import AccessPointTypesList from "../Editors/AccessPointsEditor/AccessPointTypesList";
import WallsListItem from "../Editors/WallAnnotationsEditor/WallsListItem";
import wreAction from "../../../../redux/slices/wre.slice";
import { VeryFancyLoader } from "../../../../components";
import { WRE_URL } from "../../../../configs/urls";
import axios from "axios";
import CableDropList from "../../../../components/CableDropList";
import SwitchTypeList from "../Editors/SwitchEditor/SwitchTypeList";

/**
 * Floor Plan Editor
 * @param {{
 *   editorState: {
 *     isOpen: boolean,
 *     layout: object?,
 *     blank: boolean
 *   },
 *   setEditorState: (_: { isOpen: boolean, layout: object? }) => void,
 *   openInMode: 1 | 2 | 3 | 4,
 *   afterSaveSuccess: () => {}
 * }} props 
 */
const Editor = (props) => {
  const venueId = useSelector(store => store.activeVenue.data.venueId);
  const dispatch = useDispatch()
  const orgId = useSelector(store => store.activeOrg.data.orgId);
  const identity = useSelector(store => store.identity);
  const currentInsertedLayout = useSelector(store => store.wre.currentInsertedLayout)
  const analysisScore = useSelector(store => store.wre.analysisScore)
  const unsavedLayout = useSelector(store => store.wre.unsavedLayout)
  const permissions = useSelector(store => store?.rbac?.permissions);
  const { editorState } = props;
  const [layout, setLayout] = useState(editorState?.layout)
  const [mode, setMode] = useState(EDITORS.SnappingEditor);
  const layoutManager = useLayoutsManager(props.editorState.layout ? [props.editorState.layout] : []);
  const { saveType, editableLayout, setSaveType, resetSelectedEntities, updateEditableLayout } = layoutManager;
  const [saving, setSaving] = useState(false);
  const [name, setName] = useState("");

  const draggedLayoutItem = useRef()
  const draggedApItem = useRef()
  const draggedSwitchItem = useRef()
  const draggedCableDrop = useRef()

  const [selectedWallMaterial, setSelectedWallMaterial] = useState("");
  const layoutId = layout?.id;
  const [result, setResult] = useState(null);
  const [resultLoading, setResultLoading] = useState(false);
  const [resultError, setResultError] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showUnsaveModal, setShowUnsaveModal] = useState(false)
  const [scoreListening, setScoreListening] = useState(false)
  const [currentScore, setCurrentScore] = useState(0)
  const [currentPercentage, setCurrentPercentage] = useState(0)
  const [floorplanNameError, setFloorplanNameError] = useState(null)
  const [deleting, setDeleting] = useState(false)
  const WRE = (url) => WRE_URL + url;



  // newData coming in useEffect here

  useEffect(() => {
    dispatch(wreAction.setUnsavedLayout(false))
  }, [])

  // useEffect(()=>{
  //   resetScoreCalculator()
  // },[mode])

  useEffect(() => {
    if (analysisScore?.score && layoutId == analysisScore?.floorPlanId) {
      setCurrentPercentage(0)
      setScoreListening(false)
      setCurrentScore(analysisScore.score)
    }
    if (analysisScore?.percentage && layoutId == analysisScore?.floorPlanId) {
      setCurrentPercentage(analysisScore?.percentage)
    }
  }, [analysisScore])

  useEffect(() => {
    if (editorState && editorState.layout)
      setLayout(editorState.layout)

  }, [editorState])

  useEffect(() => {
    const layoutUuid = layoutManager.selectedWall.layoutUuid;
    const wallId = layoutManager.selectedWall.wallId;
    if (layoutUuid && wallId) {
      setSelectedWallMaterial(
        layoutManager
          .editableLayout
          .layouts[layoutUuid]
          .walls
          .find(wall => wall.id === wallId)
          ?.material
        ?? ""
      )
    }
    else {
      setSelectedWallMaterial("")
    }
  }, [layoutManager]);

  const resetScoreCalculator = () => {
    setCurrentPercentage(0)
    setScoreListening(false)
    setCurrentPercentage(0)
    setResultError(null)
  }
  const runApPlacement = useCallback(() => {
    const { run } = createRequest(wre.ANALYSE, [layoutId]);
    // setResult(null);
    // setResultError(null);
    // setResultLoading(true);

    run()
      .then(response => {
        setResultError(null)
        setScoreListening(true)
        setCurrentPercentage(0)
        setCurrentScore(0)
        // setResult(response.data);
      })
      .catch(err => {
        const x = new CatchedWebError(err);
        setResultError(x.message);
        setScoreListening(false)
        setCurrentScore(0)
        setCurrentPercentage(0)
        console.log(err);
      })
      .finally(() => {
        setResultLoading(false);
      })
  }, [layoutId]);

  const exportPNG = async () => {
    const { run, controller } = createRequest(wre.EXPORT_FLOORPLAN, [editorState.layout.id]);
    const imgSrc = await run()
    const imgBlob = await fetch(imgSrc.data)
      .then(res => res.arrayBuffer())
      .then((buffer) => new Blob([buffer], { type: "image/png" }))

    const link = document.createElement('a')
    link.href = URL.createObjectURL(imgBlob)
    link.download = `FLoorplan${layout.id}Image`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)



    // axios.get(WRE(`floorplan/${layout.id}/export`),
    //   {
    //     responseType: 'arraybuffer',
    //     headers: {
    //       authorization: "Bearer " + identity.meta.token
    //     }
    //   })
    //   .then(res => {
    //     const blob = new Blob([res.data], { type: 'image/png' });
    //     const link = document.createElement('a');
    //     link.href = URL.createObjectURL(blob);
    //     link.setAttribute('download', `Floorplan${layout.id}.png`);
    //     document.body.appendChild(link);
    //     link.click();
    //   })
    //   .catch(err => {
    //     // setError((new CatchedWebError(err)).message)
    //     make_custom_toast('error', 'Floorplan', (new CatchedWebError(err)).message)

    //   })










    return () => controller && controller.abort();
  }

  const deleteFloorplan = async () => {
    setDeleting(true)
    const { run, controller } = createRequest(wre.DELETE_FLOORPLAN, [editorState.layout.id]);
    run()
      .then(res => {
        setDeleting(false)
        props.setEditorState({ isOpen: false, layout: null })
        props.afterSaveSuccess()
      })
      .catch((err) => {
        setDeleting(false)
        make_custom_toast('error', 'Floorplan', (new CatchedWebError(err)).message)
      })
    return () => controller && controller.abort();
  }



  useEffect(() => {

    if (editorState?.layout?.layoutType != LAYOUT_TYPES.IMAGE && editorState?.layout?.layoutType) {
      dispatch(wreAction.setCurrentInsertedLayout(LAYOUT_TYPES.SINGLE_LIDAR_SCAN))
      // console.log('rdebug trigger 7...:', editorState?.layout)

    }
    else if (editorState?.layout?.layoutType == LAYOUT_TYPES.IMAGE) {
      dispatch(wreAction.setCurrentInsertedLayout(LAYOUT_TYPES.IMAGE))
      // console.log('rdebug trigger 8...:', editorState?.layout)

    }
    else {
      dispatch(wreAction.setCurrentInsertedLayout(LAYOUT_TYPES.NONE))
      // console.log('rdebug trigger 9...:', editorState?.layout)

    }

    if (saveType === "edit") {
      setName(editorState.layout.name);
    } else {
      setName("Untitled Floorplan");
    }
  }, [saveType, editorState]);

  useEffect(() => {
    if (name.length > 0) {
      verifyFloorplanName(name)
    }
  }, [name])


  const closeEditor = () => {
    props.setEditorState({ isOpen: false, layout: null })
  }



  const verifyFloorplanName = (value) => {
    // let regex = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]{3,}$/g
    let regex = new RegExp("^(?!\\s)(?!.*\\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9_#@.\\s-]+$")
    if (value.length > 30) {
      setFloorplanNameError('Maximum length should be 30 characters.')
    }
    else if (!regex.test(value)) {
      setFloorplanNameError("Enter alphanumeric characters only.")
    }
    else {
      setFloorplanNameError(null)
    }
    return
  }
  return (
    <motion.div
      className="Editor"
      initial={{ opacity: 0.9, scale: 0.9, borderWidth: 3, borderRadius: 20 }}
      animate={{ opacity: 1, scale: 1, borderWidth: 0, borderRadius: 0 }}
      exit={{ opacity: 0, scale: 0.9, borderWidth: 3, borderRadius: 20 }}
      transition={{ duration: 0.2 }}
    >

      {/* --- MODALS --- */}

      <Modal centered isOpen={!!showDeleteModal} toggle={() => { setShowDeleteModal(false) }}>
        <ModalHeader className="bg-white" toggle={() => { setShowDeleteModal(false) }}></ModalHeader>
        <ModalBody className="p-3">
          <div className="d-flex flex-column align-items-center">
            <span className="material-symbols-outlined display-1 text-danger">warning</span>
            <strong className="py-1">Are you sure you want to delete this floorplan?</strong>
            <span>This process cannot be reverted.</span>
            {deleting && <div className="d-flex align-items-center"><span className="mr-1 py-1 text-danger">Deleting</span> <VeryFancyLoader size={8} /></div>}
            <div className="d-flex justify-content-center pt-2">
              <Button color="primary" className="small-add-button" onClick={() => { setShowDeleteModal(false) }}>Cancel</Button>
              <Button className="ml-2 small-add-button" color="danger" onClick={() => { deleteFloorplan() }}>Delete</Button>
            </div>
          </div>
        </ModalBody>
      </Modal>


      <Modal centered isOpen={!!showUnsaveModal} toggle={() => { setShowUnsaveModal(false) }}>
        <ModalHeader className="bg-white" toggle={() => { setShowUnsaveModal(false) }}></ModalHeader>
        <ModalBody className="p-3">
          <div className="d-flex flex-column align-items-center">
            <span className="material-symbols-outlined display-1 text-danger">warning</span>
            <strong className="py-1">You have unsaved changes.</strong>
            <span>There are unsaved changes. You can save your changes, cancel to continue editing or close and discard the changes.</span>
            <div className="d-flex justify-content-center pt-2">
              <Button color="primary" className="small-add-button" onClick={() => { setShowUnsaveModal(false) }}>Cancel</Button>
              <Button className="ml-2 small-add-button" color="danger" onClick={() => { closeEditor() }}>Close</Button>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {/** HEADER */}
      <div className="editor-header border-bottom d-flex justify-content-between align-items-center p-1">
        <h2 className="m-0 editor-heading pl-1">Floor Plan Editor</h2>
        <div className=" ">
          {/* <div id="saveType" className="edit-marker cursor-pointer shadow" style={{ backgroundColor: layoutManager.saveType === "edit" ? "orange" : "dodgerblue" }}></div> */}
          {/* <UncontrolledTooltip target="saveType" placement="bottom">
            {layoutManager.saveType === "edit" ? "You are editing an existing layout" : "You are composing a new layout"}
          </UncontrolledTooltip> */}
          <Input
            invalid={!!floorplanNameError}
            value={name}
            onChange={(e) => {
              dispatch(wreAction.setUnsavedLayout(true))
              setName(e.target.value)
            }}
            size="sm"
            type="text"
            className="editor-name  shadow-sm"
            placeholder="Floorplan Name"
          />
          {floorplanNameError &&
            <span className="text-danger" style={{ fontSize: '12px' }}>{floorplanNameError}</span>
          }
        </div>
        <div
          className="border-left d-flex align-items-center "

        >

          <Button color="primary" className="ml-50 save-button" disabled={!unsavedLayout || name.trim().length === 0 || Object.keys(layoutManager.editableLayout.layouts).length === 0 || floorplanNameError} size="sm"
            onClick={() => {
              // save floorplan
              resetSelectedEntities()
              if (layoutManager.saveType === "edit") {
                const layoutId = props.editorState.layout?.id ?? null;
                const bakedInfo = layoutManager.editableLayout.getMergedLayouts();
                if (layoutId) {
                  setSaving(true);
                  let imageAddedId = null
                  if (currentInsertedLayout == LAYOUT_TYPES.IMAGE) {
                    imageAddedId = bakedInfo.addedLayoutId[0]
                  }
                  const { run, context } = createRequest(wre.UPDATE_FLOORPLAN, [layoutId], {
                    venueId: props.editorState.layout?.venueId ?? 0,
                    name: name.trim(),
                    infraPositions: bakedInfo.infraPositions,
                    cableDrop: bakedInfo.cableDrop,
                    removed: {
                      planned_infra: bakedInfo.removedApList,
                      cable_drop: bakedInfo.removedCableDropList
                    },
                    layoutJson: currentInsertedLayout == LAYOUT_TYPES.IMAGE ? null : bakedInfo.layoutJson
                  })
                  run()
                    .then(response => {
                      props.afterSaveSuccess();
                      if (bakedInfo.removedApList.length > 0 || bakedInfo.removedCableDropList.length > 0) {
                        layoutManager.editableLayout.clearRemovedList()
                      }
                      if (currentInsertedLayout == LAYOUT_TYPES.IMAGE) {
                        const { run, controller } = createRequest(wre.GET_IMAGE, [orgId, layout?.id]);
                        run()
                          .then(imgResponse => {
                            let tempLayout = response.data
                            tempLayout.layoutImageUrl = imgResponse.data
                            tempLayout.layoutJson = FAKE_WALL_DATA

                            if (!response.data.layoutJson || response.data?.layoutJson?.components[0]?.id == "FAKE_COMPONENT") {
                              tempLayout.layoutType = LAYOUT_TYPES.IMAGE
                              tempLayout.layoutJson = null
                            }
                            props.setEditorState(
                              {
                                isOpen: true,
                                layout: tempLayout,
                                blank: false
                              })
                            updateEditableLayout(tempLayout)

                          })
                          .catch(err => {

                          })

                      }
                      else {
                        props.setEditorState({ isOpen: true, layout: response.data, blank: false });
                        updateEditableLayout(response.data)

                      }
                      dispatch(wreAction.setUnsavedLayout(false))

                      // make_custom_toast("success", "Floor Plan Editor", "Layout Saved Successfully");
                    })
                    .catch(err => {
                      const x = new CatchedWebError(err);
                      let apiContext = createErrorContext(context, 'Unable to Create Layout', TICKET_CATEGORY.VENUE, err)
                      make_custom_toast("error", "Floor Plan Editor", x.message, true, 'Create Ticket', () => {
                        Navigate(
                          `/organization/${orgId}/support/ticket/createticket`,
                          {
                            state: {
                              ticketContext: apiContext,
                            },
                          }
                        );
                      })
                    })
                    .finally(() => {
                      setSaving(false);
                    })
                }
              } else {
                setSaving(true);
                const bakedInfo = layoutManager.editableLayout.getMergedLayouts();

                let imageLayoutId = null
                let newLayoutJson = bakedInfo.layoutJson

                if (currentInsertedLayout == LAYOUT_TYPES.IMAGE) {
                  imageLayoutId = bakedInfo.addedLayoutId[0]
                  newLayoutJson = null
                }
                const { run, context } = createRequest(wre.CREATE_FLOORPLAN, [imageLayoutId], {
                  venueId: venueId,
                  name: name.trim(),
                  layoutJson: newLayoutJson,
                  infraPositions: bakedInfo.infraPositions
                });
                run()
                  .then(response => {
                    dispatch(wreAction.setUnsavedLayout(false))

                    props.afterSaveSuccess();


                    let updatedData = {
                      ...response.data
                    }
                    if (!response.data.layoutJson) {
                      updatedData.layoutType = LAYOUT_TYPES.IMAGE
                    }
                    // console.log('rdebug ap updatedData:', updatedData)
                    props.setEditorState({ isOpen: true, layout: updatedData, blank: false });
                    updateEditableLayout(updatedData)
                    setName(response.data.name);
                    setSaveType("edit");
                    // make_custom_toast("success", "Floor Plan Editor", "Layout Created Successfully");
                  })
                  .catch(err => {
                    const x = new CatchedWebError(err);
                    let apiContext = createErrorContext(context, 'Unable to Create Layout', TICKET_CATEGORY.VENUE, err)
                    make_custom_toast("error", "Floor Plan Editor", x.message, true, 'Create Ticket', () => {
                      Navigate(
                        `/organization/${orgId}/support/ticket/createticket`,
                        {
                          state: {
                            ticketContext: apiContext,
                          },
                        }
                      );
                    })
                  })
                  .finally(() => {
                    setSaving(false);
                  })
              }
            }}>{saving ? <Spinner size="sm" /> : <span>Save</span>}</Button>


          <span style={{ marginLeft: '8px', fontSize: '2em' }} onClick={() => {
            if (unsavedLayout) {
              setShowUnsaveModal(true)
            }
            else {
              closeEditor()
            }
          }} class="material-symbols-outlined cursor-pointer">
            close
          </span>

          {
            layoutManager.saveType == 'edit' &&
            <UncontrolledDropdown  >
              <DropdownToggle
                color="white"
                style={{ paddingTop: '3px', color: '#444' }}
                className="material-symbols-outlined  cursor-pointer layout-options-icon"
                tag={'span'}>more_vert</DropdownToggle>
              <DropdownMenu right className="p-0" style={{ zIndex: '41' }} >
                <DropdownItem className="w-100 text-primary" onClick={exportPNG}>Export PNG</DropdownItem>
                <DropdownItem className="w-100 text-danger" onClick={() => { setShowDeleteModal(true) }}>Delete</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          }
        </div>
      </div>


      {/* --- top  bar --- */}
      <div className="d-flex top-mode-bar">

        {
          <EditorSidebarItem
            Icon={SidebarIcons.SnappingIcon}
            label="Layouts"
            value={EDITORS.SnappingEditor}
            mode={mode}
            setMode={setMode}
          />
        }
        <EditorSidebarItem
          Icon={SidebarIcons.AccessPointIcon}
          label="Access Points"
          value={EDITORS.AccessPointsEditor}
          mode={mode}
          setMode={setMode}
        />
        <EditorSidebarItem
          Icon={SidebarIcons.SwitchIcon}
          label="Switch"
          value={EDITORS.SwitchEditor}
          mode={mode}
          setMode={setMode}
        />

        <EditorSidebarItem
          Icon={SidebarIcons.CableDropIcon}
          label="Cable Drop"
          value={EDITORS.CableDrop}
          mode={mode}
          setMode={setMode}
        />

        {/* <EditorSidebarItem
          Icon={SidebarIcons.DrawLinesIcon}
          label="Draw Lines"
          value={EDITORS.DrawLines}
          mode={mode}
          setMode={setMode}
        /> */}

        <EditorSidebarItem
          Icon={SidebarIcons.WallAnnotationsIcon}
          label="Walls"
          value={EDITORS.WallAnnotationsEditor}
          mode={mode}
          setMode={setMode}
        />



        {
          permissions?.manageFirmware?.create && //P-Admin Check, only P-Admin can see this
          <EditorSidebarItem
            Icon={SidebarIcons.AnalysisIcon}
            label="Analysis"
            value={EDITORS.AnalysisEditor}
            mode={mode}
            setMode={setMode}
          />
        }

      </div>

      {/** EDITOR */}
      <div className="editor-area">
        <div className="sidebar">

          {/* --- bottom view --- */}
          <div>
            {
              mode == EDITORS.SnappingEditor &&
              <>
                <div className=" pt-0 available-layouts-list" id="available-layouts-list">
                  <AvailableLayouts draggedItem={draggedLayoutItem} layoutManager={layoutManager} venueId={layout?.venueId ?? venueId} />
                </div>
              </>
            }
            {
              mode == EDITORS.AccessPointsEditor &&
              <>
                <div className="ap-list">
                  <AccessPointTypesList draggedItem={draggedApItem} />
                </div>
              </>
            }
            {
              mode == EDITORS.SwitchEditor &&
              <>
                <div className="ap-list">
                  <SwitchTypeList draggedItem={draggedSwitchItem} />
                </div>
              </>
            }
            {
              mode == EDITORS.CableDrop &&
              <>
                <div className="ap-list">
                  <CableDropList draggedItem={draggedCableDrop} />
                </div>
              </>
            }
            {

              mode == EDITORS.WallAnnotationsEditor &&
              <>
                <h4 className="p-1 m-0 border-bottom">Annotate Walls</h4>
                {/* <AnimatePresence > */}
                {/* {layoutManager.selectedWall.wallId !== null && selectedWallMaterial !== "" && */}
                <div
                  key={layoutManager.selectedWall.wallId}
                  className="p-1 walls-list"
                >
                  <WallsListItem selectedWall={layoutManager.selectedWall} setWallMaterial={layoutManager.setWallMaterial} highlighted={selectedWallMaterial} label="Brick Wall" value="brick wall" shorthand="B" color="#CB553D" />
                  {/* <WallsListItem selectedWall={props.layoutManager.selectedWall} setWallMaterial={props.layoutManager.setWallMaterial} highlighted={selectedWallMaterial} label="Concrete Wall" value="concrete wall" shorthand="C" color="#95D77F" /> */}
                  <WallsListItem selectedWall={layoutManager.selectedWall} setWallMaterial={layoutManager.setWallMaterial} highlighted={selectedWallMaterial} label="Dry Wall" value="dry wall" shorthand="D" color="#918179" />
                  <WallsListItem selectedWall={layoutManager.selectedWall} setWallMaterial={layoutManager.setWallMaterial} highlighted={selectedWallMaterial} label="Steel Wall" value="steel wall" shorthand="M" color="#9FA4A9" />
                  <WallsListItem selectedWall={layoutManager.selectedWall} setWallMaterial={layoutManager.setWallMaterial} highlighted={selectedWallMaterial} label="Wooden Wall" value="wooden wall" shorthand="W" color="#E39645" />
                </div>
                {/* } */}
                {/* </AnimatePresence> */}

                {/* <AnimatePresence>
                  {(layoutManager.selectedWall.wallId === null || selectedWallMaterial === "") &&
                    <div
                      key={layoutManager.selectedWall.wallId}
                      className="px-3 pb-4 walls-list d-flex justify-content-center align-items-center text-center no-wall-selected-message"
                    >
                      Select a wall to edit its properties.
                    </div>
                  }
                </AnimatePresence> */}
              </>
            }
            {
              mode == EDITORS.AnalysisEditor &&
              <>
                <div
                  className="selector-area p-2"
                >
                  <h1 className="text-center">Placement</h1>
                  <h1 className="text-center">Analysis</h1>

                  <div className="d-flex flex-column align-items-center">
                    {editorState?.layout?.score &&
                      <span className="mb-1">Previously calculated score: {editorState?.layout?.score.toFixed(2)}%</span>
                    }
                    {resultError && <div className="text-danger text-center mt-3 mb-1">{resultError}</div>}
                    {

                      scoreListening ?
                        <div className="w-100">
                          <span>Calculating <Spinner color="primary" size={'sm'} /></span>
                          <Progress className="w-100 mt-50" value={currentPercentage} color="success" style={{ fontSize: "0.75rem" }}>
                            {`${Math.floor(currentPercentage)}%`}
                          </Progress>
                        </div>
                        : currentScore ?
                          <h4>Score: {currentScore.toFixed(2)}%</h4>
                          : null
                    }
                    <Button className="mt-3" size="md" color="primary" disabled={scoreListening} onClick={() => { runApPlacement() }}>Analyse Placements</Button>
                  </div>
                </div>
              </>
            }
          </div>
        </div>
        <div className="playground shadow-lg">
          {mode === EDITORS.AnalysisEditor ?
            <AnalysisEditor
              blank={props.editorState.blank}
              layoutManager={layoutManager}
              layout={layout}
            />
            : <SnappingEditor
              blank={props.editorState.blank}
              layoutManager={layoutManager}
              layout={layout}
              draggedLayoutRef={draggedLayoutItem}
              draggedApRef={draggedApItem}
              draggedSwitchRef={draggedSwitchItem}
              draggedCableDropRef={draggedCableDrop}
              mode={mode}
            />}


        </div>

      </div>
    </motion.div>
  );
};

Editor.propTypes = {
  editorState: PropTypes.object,
  setEditorState: PropTypes.func,
  openInMode: PropTypes.oneOf([1, 2, 3, 4])
};
Editor.defaultProps = {
  openInMode: 1
};

export default Editor;