import PropTypes from "prop-types";

const SwitchIcon = ({ svgProps, color }) => (

    <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="switch 2">
            <g id="Group 484900">
                <path id="Vector" d="M17.8753 9.66667C17.944 9.66667 18 9.72267 18 9.79133V14.8753C18 14.944 17.944 15 17.8753 15H6.12468C6.05601 15 6.00001 14.944 6.00001 14.8753V9.79133C6.00001 9.72267 6.05601 9.66667 6.12468 9.66667H17.8753ZM17.8753 9H6.12468C5.68734 9 5.33334 9.354 5.33334 9.79133V14.8753C5.33334 15.3127 5.68734 15.6667 6.12468 15.6667H17.8753C18.3127 15.6667 18.6667 15.3127 18.6667 14.8753V9.79133C18.6667 9.354 18.3127 9 17.8753 9Z" fill={color} />
                <path id="Vector_2" d="M5.282 9.66667C5.31 9.66667 5.33267 9.68933 5.33267 9.71733V14.9493C5.33267 14.9773 5.31 15 5.282 15H4.71733C4.68933 15 4.66667 14.9773 4.66667 14.9493V9.71733C4.66667 9.68933 4.68933 9.66667 4.71733 9.66667H5.282ZM5.282 9H4.71733C4.32133 9 4 9.32133 4 9.71733V14.9493C4 15.3453 4.32133 15.6667 4.71733 15.6667H5.282C5.678 15.6667 5.99933 15.3453 5.99933 14.9493V9.71733C5.99933 9.32133 5.678 9 5.282 9Z" fill={color} />
                <path id="Vector_3" d="M19.1247 9.66667C19.2393 9.66667 19.3333 9.76 19.3333 9.87533V14.792C19.3333 14.9067 19.24 15.0007 19.1247 15.0007H18.8753C18.7607 15.0007 18.6667 14.9073 18.6667 14.792V9.87533C18.6667 9.76067 18.76 9.66667 18.8753 9.66667H19.1247ZM19.1247 9H18.8753C18.392 9 18 9.392 18 9.87533V14.792C18 15.2753 18.392 15.6673 18.8753 15.6673H19.1247C19.608 15.6673 20 15.2753 20 14.792V9.87533C20 9.392 19.608 9 19.1247 9Z" fill={color} />
                <path id="Vector_4" d="M8.66666 11.6667V13H7.33332V11.6667H8.66666ZM8.66666 11H7.33332C6.96532 11 6.66666 11.2987 6.66666 11.6667V13C6.66666 13.368 6.96532 13.6667 7.33332 13.6667H8.66666C9.03466 13.6667 9.33332 13.368 9.33332 13V11.6667C9.33332 11.2987 9.03466 11 8.66666 11Z" fill={color} />
                <path id="Vector_5" d="M16.6667 11.6667V13H12V11.6667H16.6667ZM16.6667 11H12C11.632 11 11.3333 11.2987 11.3333 11.6667V13C11.3333 13.368 11.632 13.6667 12 13.6667H16.6667C17.0347 13.6667 17.3333 13.368 17.3333 13V11.6667C17.3333 11.2987 17.0347 11 16.6667 11Z" fill={color} />
                <path id="Vector_6" d="M13 13.6667C12.816 13.6667 12.6667 13.5173 12.6667 13.3333V11.3333C12.6667 11.1493 12.816 11 13 11C13.184 11 13.3333 11.1493 13.3333 11.3333V13.3333C13.3333 13.5173 13.184 13.6667 13 13.6667Z" fill={color} />
                <path id="Vector_7" d="M14.3333 13.6667C14.1493 13.6667 14 13.5173 14 13.3333V11.3333C14 11.1493 14.1493 11 14.3333 11C14.5173 11 14.6667 11.1493 14.6667 11.3333V13.3333C14.6667 13.5173 14.5173 13.6667 14.3333 13.6667Z" fill={color} />
                <path id="Vector_8" d="M15.6667 13.6667C15.4827 13.6667 15.3333 13.5173 15.3333 13.3333V11.3333C15.3333 11.1493 15.4827 11 15.6667 11C15.8507 11 16 11.1493 16 11.3333V13.3333C16 13.5173 15.8507 13.6667 15.6667 13.6667Z" fill={color} />
            </g>
        </g>
    </svg>



    // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none" {...svgProps}>
    //   <path d="M23.9432 19.9807C23.9734 19.9807 23.9983 20.0056 23.9983 20.0358V25.2589C23.9983 25.2891 23.9734 25.314 23.9432 25.314H8.05338C8.02315 25.314 7.99826 25.2891 7.99826 25.2589V20.0358C7.99826 20.0056 8.02315 19.9807 8.05338 19.9807H23.9432ZM23.9432 19.0918H8.05338C7.53249 19.0918 7.10938 19.514 7.10938 20.0358V25.2589C7.10938 25.7798 7.5316 26.2029 8.05338 26.2029H23.9432C24.464 26.2029 24.8872 25.7807 24.8872 25.2589V20.0358C24.8872 19.5149 24.4649 19.0918 23.9432 19.0918Z" fill={color}/>
    //   <path d="M12.3103 17.2979C12.2019 17.2979 12.0926 17.2588 12.0072 17.1788C11.8277 17.0117 11.8179 16.7308 11.985 16.5504C12.5023 15.9948 13.1174 15.5593 13.8134 15.2553C14.5094 14.9513 15.2472 14.7957 16.0072 14.793H16.0286C16.7797 14.793 17.5112 14.9433 18.2028 15.2393C18.9014 15.5388 19.5192 15.9699 20.0401 16.521C20.2081 16.6997 20.201 16.9806 20.0223 17.1495C19.8437 17.3184 19.5619 17.3104 19.3939 17.1317C18.9574 16.6695 18.4383 16.3077 17.8517 16.0561C17.2668 15.8055 16.6588 15.6766 16.0099 15.6819C15.3717 15.6837 14.753 15.8144 14.169 16.0695C13.585 16.3246 13.0686 16.6899 12.6348 17.1566C12.5477 17.2499 12.4286 17.2979 12.3095 17.2979H12.3103ZM10.0819 14.2028C9.97345 14.2028 9.86412 14.1637 9.77878 14.0837C9.59923 13.9166 9.58945 13.6348 9.75656 13.4553C10.5592 12.5939 11.5148 11.9175 12.5939 11.4455C13.673 10.9735 14.8179 10.7326 15.9957 10.7281H16.0268C17.193 10.7281 18.329 10.961 19.4037 11.4215C20.4863 11.8855 21.4454 12.5548 22.2552 13.4108C22.4232 13.5895 22.4161 13.8704 22.2374 14.0393C22.0588 14.2081 21.777 14.2001 21.609 14.0215C20.8837 13.2544 20.0232 12.6544 19.0526 12.2384C18.0899 11.8259 17.0721 11.617 16.0268 11.617H15.9992C14.9432 11.6206 13.9174 11.8375 12.9495 12.2597C11.9815 12.6828 11.1255 13.289 10.4063 14.0606C10.3183 14.1548 10.2001 14.2019 10.081 14.2019L10.0819 14.2028ZM7.10856 11.4313C7.00012 11.4313 6.89078 11.3921 6.80545 11.3122C6.62589 11.145 6.61612 10.8633 6.78323 10.6837C7.96812 9.41348 9.37523 8.41704 10.9663 7.72193C12.5583 7.02593 14.2446 6.67037 15.9815 6.66504C17.721 6.70326 19.4081 7.00282 21.0055 7.68726C22.6001 8.37082 24.0152 9.35659 25.209 10.6197C25.377 10.7984 25.3699 11.0793 25.1912 11.2481C25.0126 11.417 24.7317 11.409 24.5628 11.2304C23.4526 10.0562 22.137 9.13882 20.6543 8.50326C19.1699 7.86682 17.6037 7.55215 15.9841 7.55304C14.3708 7.55837 12.8019 7.88904 11.3219 8.53526C9.84189 9.18148 8.53434 10.1086 7.43301 11.289C7.34501 11.3833 7.22678 11.4304 7.10767 11.4304L7.10856 11.4313Z" fill={color}/>
    //   <path d="M7.1059 19.9797V25.313H6.21701V19.9797H7.1059ZM7.1059 19.0908H6.21701C5.72635 19.0908 5.32812 19.489 5.32812 19.9797V25.313C5.32812 25.8037 5.72635 26.2019 6.21701 26.2019H7.1059C7.59657 26.2019 7.99479 25.8037 7.99479 25.313V19.9797C7.99479 19.489 7.59657 19.0908 7.1059 19.0908Z" fill={color}/>
    //   <path d="M25.77 19.9797V25.313H24.8811V19.9797H25.77ZM25.77 19.0908H24.8811C24.3904 19.0908 23.9922 19.489 23.9922 19.9797V25.313C23.9922 25.8037 24.3904 26.2019 24.8811 26.2019H25.77C26.2606 26.2019 26.6589 25.8037 26.6589 25.313V19.9797C26.6589 19.489 26.2606 19.0908 25.77 19.0908Z" fill={color}/>
    //   <path d="M11.5495 21.759V23.5368H9.7717V21.759H11.5495ZM11.5495 20.8701H9.7717C9.28103 20.8701 8.88281 21.2683 8.88281 21.759V23.5368C8.88281 24.0275 9.28103 24.4257 9.7717 24.4257H11.5495C12.0401 24.4257 12.4384 24.0275 12.4384 23.5368V21.759C12.4384 21.2683 12.0401 20.8701 11.5495 20.8701Z" fill={color}/>
    //   <path d="M22.2205 21.759V23.5368H15.9983V21.759H22.2205ZM22.2205 20.8701H15.9983C15.5076 20.8701 15.1094 21.2683 15.1094 21.759V23.5368C15.1094 24.0275 15.5076 24.4257 15.9983 24.4257H22.2205C22.7112 24.4257 23.1094 24.0275 23.1094 23.5368V21.759C23.1094 21.2683 22.7112 20.8701 22.2205 20.8701Z" fill={color}/>
    //   <path d="M17.3273 24.4257C17.0819 24.4257 16.8828 24.2266 16.8828 23.9812V21.3146C16.8828 21.0692 17.0819 20.8701 17.3273 20.8701C17.5726 20.8701 17.7717 21.0692 17.7717 21.3146V23.9812C17.7717 24.2266 17.5726 24.4257 17.3273 24.4257Z" fill={color}/>
    //   <path d="M19.1085 24.4257C18.8632 24.4257 18.6641 24.2266 18.6641 23.9812V21.3146C18.6641 21.0692 18.8632 20.8701 19.1085 20.8701C19.3538 20.8701 19.553 21.0692 19.553 21.3146V23.9812C19.553 24.2266 19.3538 24.4257 19.1085 24.4257Z" fill={color}/>
    //   <path d="M20.8898 24.4257C20.6444 24.4257 20.4453 24.2266 20.4453 23.9812V21.3146C20.4453 21.0692 20.6444 20.8701 20.8898 20.8701C21.1351 20.8701 21.3342 21.0692 21.3342 21.3146V23.9812C21.3342 24.2266 21.1351 24.4257 20.8898 24.4257Z" fill={color}/>
    // </svg>
);

SwitchIcon.propTypes = {
    svgProps: PropTypes.object,
    color: PropTypes.string
}
SwitchIcon.defaultProps = {
    svgProps: {
        width: "32",
        height: "32",
    },
    color: "#323232"
}

export default SwitchIcon;