import { createSlice } from "@reduxjs/toolkit";

const initialPermissions = {
  status: {
    loading: null,
    error: null
  },
  data: {
    viewVenue: null,
    manageVenue: null,
    viewOrganization: null,
    manageOrganization: null
  }
}

const permissionsSlice = createSlice({
  name: "permissions",
  initialState: initialPermissions,
  reducers: {
    setPermissions: (state, action) => {
      state.data = {...(action.payload)};
    },
    resetPermissions: (state) => {
      state.data = {...initialPermissions};
    },
    setLoading: (state, action) => {
      state.status.loading = action.payload;
    },
    setError: (state, action) => {
      state.status.error = action.payload;
    }
  }
});

export const permissionsReducer = permissionsSlice.reducer;
const permissionsActions = permissionsSlice.actions;
export default permissionsActions;
