/***
 *
 * Controller class for user.
 * @file OperationCounters.js
 * @description OperationCounters component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React, { memo, useEffect, useRef, useState } from "react";
// import PropTypes from 'prop-types';
import "./OperationCounters.scss";
import { Spinner } from "reactstrap";
import { ReactComponent as Warning } from "../../assets/images/icons/WarningWhiteExclamation.svg";
import { ReactComponent as OrangeWarning } from "../../assets/images/icons/OrangeWarning.svg";
import { ReactComponent as Ticket } from "../../assets/images/icons/ticket-icon.svg";
import { ReactComponent as Firmware } from "../../assets/images/icons/Firmware.svg";
import {ReactComponent as EmergencySirenIcon} from "../../assets/images/icons/emergency-siren.svg"
import ToolTip from "react-portal-tooltip";
import createRequest, { services } from "../../services";
import { CatchedWebError } from "../../configs";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ALERT_HIG, ALERT_LOW, ALERT_MED } from "../../utility/constants";

const utcToTimezone = (utcDateTime, timezone) => {
  // console.log("utcDateTime: ", utcDateTime)
  // console.log("timezone: ", timezone)
  const momentUtcObj = moment.utc(utcDateTime)
  // console.log("momentUtcObj: ",momentUtcObj)
  // console.log("final: ",momentUtcObj.tz(timezone).format("Do MMMM h:mm a"))
  if(momentUtcObj != null)
    return momentUtcObj?.tz(timezone)?.format("MMMM Do h:mm a");
  
  return "";
}

const WithCounter = (props) => {
  const { children, count, small } = props;

  return (
    <div className="position-relative">
      {children}
      {!!count?
      <div className={`count-bubble d-flex align-items-center justify-content-center ${small?'small-bubble':''}`}>
        {count > 9 ? "9+" : count}
      </div>:null}
    </div>
  );
};

const abbreviatedTimezone = (timezone) => {
  try {
    return moment().tz(timezone).format("z")
  }
  catch {
    return timezone
  }
}

const StatusIcons  = ({zone, size}) => {
  switch (zone){
    case "RED_ZONE":
      return (<EmergencySirenIcon
        width="100%"
        height="100%"
        style={{ height: `${size}px`, width: `${size}px` }}
      />)
    case "ORANGE_ZONE":
      return (<OrangeWarning
        width="100%"
        height="100%"
        style={{ height: `${size}px`, width: `${size}px` }}
      />)
    
    case "YELLOW_ZONE":
      return (<Warning
        width="100%"
        height="100%"
        style={{ height: `${size}px`, width: `${size}px` }}
      />)
    default:
      return <EmergencySirenIcon
      width="100%"
      height="100%"
      style={{ height: `${size}px`, width: `${size}px` }}
    />
  }
}

const OperationCounters = memo((props) => {
  const { id, alarmsData, ticketsData, fwScheduleData, venueId, venueName, enableFwClick, fwClick, small, setVenues, orgId, isOrg, isInfra, infraName, infraItemId, setInfras, zone } = props;
  const [alarms, setAlarms] = useState({loading: true, error: null, data: null, show: false, active: false, position: null})
  const [tickets, setTickets] = useState({loading: true, error: null, data: null, show: false, active: false, position: null})
  const [fwSchedule, setFwSchedule] = useState({loading: true, error: null, fetched: false, data: null, show: false, active: false, position: null})
  const [size, setSize] = useState(20)
  const navigate = useNavigate()
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId)
  const permissions = useSelector(store => store?.rbac?.permissions);
  const alarmsDivRef = useRef(null)
  const ticketsDivRef = useRef(null)
  const firmwareDivRef = useRef(null)

  const handleMouseOver = (stateSetter, ref) => {
    const rect = ref.current.getBoundingClientRect()
    //rect:
      // bottom, top, left, right, height, width
    //window:
      //innerWidth, innerHeight, outerWidth, outerHeight
    let diff = window.innerHeight - rect.top
    let quarterWindowHeight = window.innerHeight / 4
    let pos = diff > quarterWindowHeight? "bottom" : "top"
    stateSetter(prevState => {
      return {
        ...prevState,
        position: pos,
        active: true
      }
    })
  }

  useEffect(() => {
    setSize((small?16:20))
  }, [small])

  useEffect(() => {
    if(!!alarmsData?.show) {
      setAlarms(prevState => {
        let newAlarms = {
          ...prevState,
          show: true
        }
        let breakdownData = {
          total: prevState.data?.total != null ? prevState.data?.total : alarmsData.total,
          critical: prevState.data?.critical != null ? prevState.data?.critical : alarmsData.critical,
          major: prevState.data?.major != null ? prevState.data?.major : alarmsData.major,
          minor: prevState.data?.minor != null ? prevState.data?.minor : alarmsData.minor,
        }
        if(breakdownData.total != null && breakdownData.critical != null && breakdownData.major != null && breakdownData.minor != null) {
          newAlarms.loading = false
          newAlarms.error = null
        }
        newAlarms.data = {...(newAlarms.data ?? {}), ...breakdownData}
        return newAlarms
      })
    }
  }, [alarmsData])
  
  useEffect(() => {
    if(!!ticketsData?.show) {
      setTickets(prevState => {
        let newTickets = {
          ...prevState,
          show: true
        }
        let breakdownData = {
          total: prevState.data?.total != null? prevState.data?.total : ticketsData.total,
          critical: prevState.data?.critical != null? prevState.data?.critical : ticketsData.critical,
          major: prevState.data?.major != null? prevState.data?.major : ticketsData.major,
          minor: prevState.data?.minor != null? prevState.data?.minor : ticketsData.minor,
        }
        if(breakdownData.total != null && breakdownData.critical != null && breakdownData.major != null && breakdownData.minor != null) {
          newTickets.loading = false
          newTickets.error = null
        }
        newTickets.data = {...(newTickets.data ?? {}), ...breakdownData}
        return newTickets
      })
    }
  }, [ticketsData])

  useEffect(() => {
    if(!!fwScheduleData?.show) {
      if(isInfra) {
        setFwSchedule(prevState => {
          let newData = {
            ...prevState,
            show: true
          }
          let breakDownData = {
            infraSnoozeDate: fwScheduleData.infraScheduleSnoozed,
            venueTimezone: fwScheduleData.venueTimezone
          }
          newData.data = breakDownData
          if(breakDownData.infraSnoozeDate != null && breakDownData.venueTimezone != null) {
            newData.loading = false
            newData.error = null
          }
          return newData
        })
        return
      }
      setFwSchedule(prevState => {
        let newData = {
          ...prevState,
          show: (isOrg && prevState.fetched) ? (prevState?.response?.venues?.count>0 ? true : false) : true
        }
        //new exists: new
        //new doesn't exist but old exists: old
        let breakdownData = {
          venueScheduleId: fwScheduleData.venueScheduleId,
          venueSnoozedDate: fwScheduleData.venueSnoozedDate,
          venueScheduleDate: fwScheduleData.venueScheduleDate,
          venueTimezone: fwScheduleData.venueTimezone,
          subVenueSchedulesCount: fwScheduleData.subVenueSchedulesCount,
          subVenueSnoozedDate: fwScheduleData.subVenueSnoozedDate,
          subVenueScheduleTimezone: fwScheduleData.subVenueScheduleTimezone,
        }
        if((breakdownData.venueSnoozedDate != null && 
          breakdownData.venueTimezone != null) ||
          (breakdownData.subVenueSchedulesCount != null && 
          breakdownData.subVenueSnoozedDate != null && 
          breakdownData.subVenueScheduleTimezone != null) || prevState.fetched) {
          newData.loading = false
          newData.error = null
        }
        newData.data = breakdownData
        return newData
      })
    }
  }, [fwScheduleData])

  const getAlarmData = () => {
    let runFunc;
    if(isInfra) {
      const {run} = createRequest(services.telemetry.GET_INFRA_BREAKDOWN,[infraItemId,'alarm'])
      runFunc = run;
    }
    else if(!isOrg){
      const {run} = createRequest(services.telemetry.GET_VENUE_BREAKDOWN,[venueId,'alarm'])
      runFunc = run;
    }
    else{
      const {run} = createRequest(services.telemetry.GET_ORG_BREAKDOWN,[orgId,'alarm'])
      runFunc = run;
    }
    runFunc()
    .then(response => {
      setAlarms(prevState => {
        let breakDown = response.data?.alarmBreakDown
        let newData = breakDown?{
          total: breakDown.totalAlarms,
          critical: breakDown.criticalAlarms,
          major: breakDown.majorAlarms,
          minor: breakDown.minorAlarms,
          totalVenues: breakDown.totalAlarmVenues,
          criticalVenues: breakDown.criticalAlarmVenues,
          majorVenues: breakDown.majorAlarmVenues,
          minorVenues: breakDown.minorAlarmVenues,
          totalOrgs: breakDown?.totalAlarmOrgs,
          criticalOrgs: breakDown?.criticalAlarmOrgs,
          majorOrgs: breakDown?.majorAlarmOrgs,
          minorOrgs: breakDown?.minorAlarmOrgs
        }:null
        return {
          ...prevState,
          data: newData,
          loading: false,
          error: null
        }
      })
    })
    .catch(error => {
      setAlarms(prevState => {
        return {
          ...prevState,
          loading: false,
          error: (new CatchedWebError(error)).message
        }
      })
    })
  }

  const getTicketData = () => {
    let runFunc;
    if(isInfra) {
      const {run} = createRequest(services.telemetry.GET_INFRA_BREAKDOWN,[infraItemId,'ticket'])
      runFunc = run;
    }
    else if(!isOrg){
      const {run} = createRequest(services.telemetry.GET_VENUE_BREAKDOWN,[venueId,'ticket'])
      runFunc = run;
    }
    else{
      const {run} = createRequest(services.telemetry.GET_ORG_BREAKDOWN,[orgId,'ticket'])
      runFunc = run;
    }

    runFunc()
    .then(response => {
      setTickets(prevState => {
        let breakDown = response.data?.ticketBreakDown
        let newData = !!breakDown?{
          total: breakDown.totalTickets,
          critical: breakDown.criticalTickets,
          major: breakDown.majorTickets,
          minor: breakDown.minorTickets,
          totalVenues: breakDown.totalTicketVenues,
          criticalVenues: breakDown.criticalTicketVenues,
          majorVenues: breakDown.majorTicketVenues,
          minorVenues: breakDown.minorTicketVenues,
          totalOrgs: breakDown?.totalTicketOrgs,
          criticalOrgs: breakDown?.criticalTicketOrgs,
          majorOrgs: breakDown?.majorTicketOrgs,
          minorOrgs: breakDown?.minorTicketOrgs
        }:null
        return {
          ...prevState,
          data: newData,
          loading: false,
          error: null
        }
      })
    })
    .catch(error => {
      setTickets(prevState => {
        return {
          ...prevState,
          loading: false,
          error: (new CatchedWebError(error)).message
        }
      })
    })
  }

  const getFwScheduleData = () => {
    let runFunc;
    if(isInfra){
      const {run} = createRequest(services.telemetry.GET_INFRA_BREAKDOWN,[infraItemId,'firmware'])
      runFunc = run;
    }
    else if(!isOrg){
      const {run} = createRequest(services.telemetry.GET_VENUE_BREAKDOWN,[venueId,'firmware'])
      runFunc = run;
    }
    else{
      const {run} = createRequest(services.telemetry.GET_ORG_BREAKDOWN,[orgId,'firmware'])
      runFunc = run;
    }

    runFunc()
    .then(response => {
      if(isInfra) {
        let breakDown = response.data?.firmwareBreakDown;
        setInfras(prevState => {
          return prevState.map(item => {
            if(item.infraItemId == infraItemId) {
              let newItem = {
                ...item,
                scheduleId: breakDown?.scheduleId,
                scheduleDate: breakDown?.scheduleDate,
                scheduleSnoozed: breakDown?.scheduleSnoozed,
                venueTimezone: breakDown?.timezone,
              }
              return newItem;
            }
            else return item;
          })
        })
        return;
        // scheduleId: item?.scheduleId,
        //                   venueId: item?.venueId,
        //                   infraItemId: item?.infraItemId,
        //                   scheduleDate: item?.scheduleDate,
        //                   scheduleSnoozed: item?.scheduleSnoozed,
        //                   timezone: item?.venueTimezone,
        //                   rescheduleInfra: true,
      }
      if(isOrg){
        setFwSchedule(prevState => {
          return {
            ...prevState,
            response: response?.data?.firmwareBreakDown
          }
        })
      }

      let breakDown = response.data?.firmwareBreakDown
      let siteBreakDown = breakDown?.siteVenue
      let subVenueBreakdown = breakDown?.subVenues
      let newVenueData = {
        scheduleId: siteBreakDown?.scheduleId,
        scheduleSnoozed: siteBreakDown?.scheduleSnoozed,
        scheduleDate: siteBreakDown?.scheduleDate,
        venueTimezone: siteBreakDown?.timezone,
        subVenueSchedulesCount: subVenueBreakdown?.count,
        subVenueSnoozedDate: subVenueBreakdown?.scheduleSnoozed,
        subVenueScheduleTimezone: subVenueBreakdown?.timezone,
      }
      setVenues(prevState => {
        let newState = prevState.map(v => {
          if(v.venueId == venueId) return {
            ...v,
            ...newVenueData
          }
          else return v
        })
        // console.log("new state: ",newState)
        return newState
      })
    })
    .catch(error => {
      
    }).finally(() => setFwSchedule(prevState => {
      return {
        ...prevState,
        fetched: true
      }
    }))
  }
  
  useEffect(() => {
    if(alarms.active && alarms.loading) {
      getAlarmData()
    }
  }, [alarms.active, alarms.loading])

  useEffect(() => {
    if(tickets.active && tickets.loading) {
      getTicketData()
    }
  }, [tickets.active, tickets.loading])

  useEffect(() => {
    if(fwSchedule.active && !fwSchedule.fetched) {
      getFwScheduleData()
    }
  }, [fwSchedule.active, fwSchedule.fetched])

  useEffect(() => {
    //clears use location state
    return () => {
      window.history.replaceState({}, '')
    }
  }, [])

  return (
    <div className="OperationCounters" data-testid="OperationCounters">
      {/* <div className="m-auto"> */}
      <div className="grid-wrapper">
          <div className="grid-inner-div">
          {alarms.show ? (
            <div
              id={`alarm${id}`}
              onMouseOver={() => {
                if((alarms.data?.total??0) > 0)
                  handleMouseOver(setAlarms, alarmsDivRef)
              }}
              onMouseLeave={() => {
                setAlarms(prevState => {
                  return {
                    ...prevState,
                    active: false
                  }
                })
              }}
              ref={alarmsDivRef}
            >
              <WithCounter count={alarms.data?.total ?? 0} small={small}>
                {/* <EmergencySirenIcon
                  width="100%"
                  height="100%"
                  style={{ height: `${size}px`, width: `${size}px` }}
                /> */}
                <StatusIcons zone={zone} size={size} />
              </WithCounter>
              {!alarms.loading && !!alarms.position ? 
              <ToolTip
                parent={`#alarm${id}`}
                active={alarms.active}
                position={alarms.position}
                arrow="center"
                style={{
                  style: {
                    border: "0.1rem solid #D9D9D9",
                    boxShadow: "none",
                  },
                  arrowStyle: {
                    borderColor: "#D9D9D9",
                  },
                }}
              >
                  {alarms.loading?<Spinner className="m-1 text-primary"/>:
                  <div className="pl-50 py-50 mr-4">
                  <div className={`${!!alarms.data?.total && permissions?.manageNotificationstats?.view?'table-link':''}`}
                    onClick={() => {
                      if(permissions?.manageNotificationstats?.view)
                      navigate(`/organization/${activeOrgId}/notification`,{state: {filter: {venues: (alarms?.data?.totalVenues??[]).map(v => {return {venueId: v, venueName: ''}}), 
                      orgs: alarms?.data?.totalOrgs,
                      infras: isInfra ? [{ infraItemId: infraItemId, infraName: infraName }] : [],
                      orgs: alarms?.data?.totalOrgs?.map(it => {return{orgId:it}}),
                      // venueChildren: false, 
                      alertType: ['alarm']
                    }}})
                    }}>{alarms.data?.total ?? '-'}&nbsp;{(alarms.data?.total??0)==1?'Alarm':'Alarms'}</div>
                  {!!alarms.data?.critical ? (
                    <div className={`d-flex align-items-center pt-50 ${permissions?.manageNotificationstats?.view?'table-link':''}`}
                      onClick={() => {
                        if(permissions?.manageNotificationstats?.view)
                        navigate(`/organization/${activeOrgId}/notification`,{state: {filter: {venues: (alarms?.data?.criticalVenues??[]).map(v => {return {venueId: v, venueName: ''}}),
                          orgs: alarms?.data?.criticalOrgs?.map(it => {return{orgId:it}}),
                          // venueChildren: false, 
                          infras: isInfra ? [{ infraItemId: infraItemId, infraName: infraName }] : [],
                          alertImpact: [ALERT_HIG], 
                          alertType: ['alarm']
                        }}})
                      }}>
                      <EmergencySirenIcon
                        className='mr-50'
                        width="100%"
                        height="100%"
                        style={{ height: "20px", width: "20px" }}
                      />
                      {alarms.data.critical}&nbsp;Critical
                    </div>
                  ) : null}
                  {!!alarms.data?.major ? (
                    <div className={`d-flex align-items-center pt-50 ${permissions?.manageNotificationstats?.view?'table-link':''}`}
                    onClick={() => {
                      if(permissions?.manageNotificationstats?.view)
                      navigate(`/organization/${activeOrgId}/notification`,{state: {filter: {venues: (alarms?.data?.majorVenues??[]).map(v => {return {venueId: v, venueName: ''}}), 
                        orgs: alarms?.data?.majorOrgs?.map(it => {return{orgId:it}}),
                        // venueChildren: false, 
                        infras: isInfra ? [{ infraItemId: infraItemId, infraName: infraName }] : [],
                        alertImpact: [ALERT_MED], 
                        alertType: ['alarm']
                      }}})
                    }}>
                      <OrangeWarning className='mr-50' width="100%"
                        height="100%"
                        style={{ height: "20px", width: "20px" }}/>
                      {alarms.data.major}&nbsp;Major
                    </div>
                  ) : null}
                  {!!alarms.data?.minor ? (
                    <div className={`d-flex align-items-center pt-50 ${permissions?.manageNotificationstats?.view?'table-link':''}`}
                    onClick={() => {
                      if(permissions?.manageNotificationstats?.view)
                      navigate(`/organization/${activeOrgId}/notification`,{state: {filter: {venues: (alarms?.data?.minorVenues??[]).map(v => {return {venueId: v, venueName: ''}}), 
                        orgs: alarms?.data?.minorOrgs?.map(it => {return{orgId:it}}),
                        // venueChildren: false, 
                        infras: isInfra ? [{ infraItemId: infraItemId, infraName: infraName }] : [],
                        alertImpact: [ALERT_LOW], alertType: ['alarm']
                      }}})
                    }}>
                      <Warning className='mr-50'/>
                      {alarms.data.minor}&nbsp;Minor
                    </div>
                  ) : null}
                </div>}
              </ToolTip>
              : null}
            </div>
        ) : null}
        </div>
          <div className="grid-inner-div">
        {tickets.show ? (
            <div
              id={`ticket${id}`}
              onMouseOver={() => {
                handleMouseOver(setTickets, ticketsDivRef)
              }}
              onMouseLeave={() => {
                setTickets(prevState => {
                  return {
                    ...prevState,
                    active: false
                  }
                })
              }}
              ref={ticketsDivRef}
            >
              <WithCounter count={tickets.data?.total ?? 0} small={small}>
              <Ticket className="stroke-black" id="TICKET_OPERATION" width="100%" height="100%" style={{ height: `${size}px`, width: `${size}px` }} />
              </WithCounter>
              {!tickets.loading && !!tickets.position ? 
              <ToolTip
                parent={`#ticket${id}`}
                active={tickets.active}
                position={tickets.position}
                arrow="center"
                style={{
                  style: {
                    border: "0.1rem solid #D9D9D9",
                    boxShadow: "none",
                  },
                  arrowStyle: {
                    borderColor: "#D9D9D9",
                  },
                }}
              >
                {tickets.loading?<Spinner className="m-1 text-primary"/>:
                <div className="pl-50 py-50 mr-4">
                  <div className={`${!!tickets.data?.total && permissions?.manageNotificationstats?.view?'table-link':''}`}
                    onClick={() => {
                      if(permissions?.manageNotificationstats?.view)
                      navigate(`/organization/${activeOrgId}/notification`,{state: {filter: {venues: (tickets?.data?.totalVenues??[]).map(v => {return {venueId: v, venueName: ''}}), 
                        orgs: tickets?.data?.totalOrgs?.map(it => {return{orgId:it}}),
                        // venueChildren: false,
                        infras: isInfra ? [{ infraItemId: infraItemId, infraName: infraName }] : [], 
                        alertType: ['ticket']
                      }}})
                    }}>{tickets.data?.total ?? '-'}&nbsp;{(tickets.data?.total??0)==1?'Ticket':'Tickets'}</div>
                  {!!tickets.data?.critical ? (
                    <div className={`d-flex align-items-center pt-50 ${permissions?.manageNotificationstats?.view?'table-link':''}`}
                      onClick={() => {
                        if(permissions?.manageNotificationstats?.view)
                        navigate(`/organization/${activeOrgId}/notification`,{state: {filter: {venues: (tickets?.data?.criticalVenues??[]).map(v => {return {venueId: v, venueName: ''}}), 
                          orgs: alarms?.data?.criticalOrgs?.map(it => {return{orgId:it}}),
                          // venueChildren: false, 
                          infras: isInfra ? [{ infraItemId: infraItemId, infraName: infraName }] : [],
                          alertImpact: [ALERT_HIG], alertType: ['ticket']
                        }}})
                      }}
                    >
                      <EmergencySirenIcon
                        className='mr-50'
                        width="100%"
                        height="100%"
                        style={{ height: "20px", width: "20px" }}
                      />
                      {tickets.data.critical}&nbsp;Critical
                    </div>
                  ) : null}
                  {!!tickets.data?.major ? (
                    <div className={`d-flex align-items-center pt-50 ${permissions?.manageNotificationstats?.view?'table-link':''}`}
                    onClick={() => {
                      if(permissions?.manageNotificationstats?.view)
                      navigate(`/organization/${activeOrgId}/notification`,{state: {filter: {venues: (tickets?.data?.majorVenues??[]).map(v => {return {venueId: v, venueName: ''}}), 
                        orgs: tickets?.data?.majorOrgs?.map(it => {return{orgId:it}}),
                        // venueChildren: false, 
                        infras: isInfra ? [{ infraItemId: infraItemId, infraName: infraName }] : [],
                        alertImpact: [ALERT_MED], alertType: ['ticket']
                      }}})
                    }}>
                      <OrangeWarning className='mr-50' width="100%"
                        height="100%"
                        style={{ height: "20px", width: "20px" }}/>
                      {tickets.data.major}&nbsp;Major
                    </div>
                  ) : null}
                  {!!tickets.data?.minor ? (
                    <div className={`d-flex align-items-center pt-50 ${permissions?.manageNotificationstats?.view?'table-link':''}`}
                      onClick={() => {
                        if(permissions?.manageNotificationstats?.view)
                        navigate(`/organization/${activeOrgId}/notification`,{state: {filter: {venues: (tickets?.data?.minorVenues??[]).map(v => {return {venueId: v, venueName: ''}}), 
                          // venueChildren: false, 
                          infras: isInfra ? [{ infraItemId: infraItemId, infraName: infraName }] : [],
                          orgs: tickets?.data?.minorOrgs?.map(it => {return{orgId:it}}),
                          alertImpact: [ALERT_LOW], alertType: ['ticket']
                        }}})
                      }}>
                      <Warning className='mr-50'
                      width="100%"
                      height="100%"
                      style={{ height: "20px", width: "20px" }}/>
                      {tickets.data.minor}&nbsp;Minor
                    </div>
                  ) : null}
                </div>}
              </ToolTip>
              :null}
            </div>
        ) : null}
          </div>
          <div className="grid-inner-div">
          {(fwSchedule.show) ? (
              <div
                id={`fwschedule${id}`}
                onMouseOver={() => {
                  handleMouseOver(setFwSchedule, firmwareDivRef)
                }}
                onMouseLeave={() => {
                  setFwSchedule(prevState => {
                    return {
                      ...prevState,
                      active: false
                    }
                  })
                }}
                ref={firmwareDivRef}
              >
                <Firmware className="fill-black" id="FIRMWARE_OPERATION" width="100%" height="100%" style={{ height: `${size}px`, width: `${size}px` }} />
                {!fwSchedule.loading && !!fwSchedule.position ? 
                <ToolTip
                  parent={`#fwschedule${id}`}
                  active={fwSchedule.active}
                  position={fwSchedule.position}
                  arrow="center"
                  style={{
                    style: {
                      border: "0.1rem solid #D9D9D9",
                      boxShadow: "none",
                    },
                    arrowStyle: {
                      borderColor: "#D9D9D9",
                    },
                  }}
                >
                  {/* {console.log(fwSchedule)} */}
                  {fwSchedule.loading
                  ? <Spinner className="m-1 text-primary"/>
                  :<div className={`FwScheduleTooltip pl-50 py-50 ${isInfra ? 'pr-50' : 'mr-4'}`}>
                    <div className="font-weight--500 mb-1">Firmware Schedule</div>
                    {
                    (isInfra) ? 
                    <div className="mb-1">
                      Update scheduled on
                      &nbsp;
                      {fwSchedule?.data?.infraSnoozeDate != null && fwSchedule?.data?.venueTimezone != null ?
                      <span
                        className={`${enableFwClick ? 'table-link':''}`}
                        onClick={() => {
                          if(enableFwClick) {
                            fwClick()
                          }
                        }}
                      >
                        {utcToTimezone(fwSchedule?.data?.infraSnoozeDate,fwSchedule?.data?.venueTimezone)}
                        &nbsp;
                        ({abbreviatedTimezone(fwSchedule?.data?.venueTimezone)})
                      </span> : null}
                    </div> : 
                    ((!isOrg) 
                    ? <div>
                      <div>
                        <span className="font-weight--500">{venueName}:</span>
                        &nbsp;
                        {!!fwSchedule.data?.venueSnoozedDate && !!fwSchedule.data?.venueTimezone?
                        <span className={`${
                          enableFwClick
                          ? 'table-link'
                          : ''}`} 
                          onClick={() => {
                            if(enableFwClick) {
                              fwClick()
                            }
                          }}
                          >
                          {utcToTimezone(fwSchedule.data?.venueSnoozedDate,fwSchedule.data?.venueTimezone)}&nbsp;({abbreviatedTimezone(fwSchedule.data?.venueTimezone)})
                        </span>:"None"}
                      </div>
                      {(!isOrg && (fwSchedule.data?.subVenueSchedulesCount??0 > 0)) ?
                      <div className="mt-1">
                        <span className="font-weight--500">
                          {`${fwSchedule.data?.subVenueSchedulesCount} Sub Venues Scheduled`}
                        </span>
                        <br />
                        {!!fwSchedule.data?.subVenueSnoozedDate && !!fwSchedule.data?.subVenueScheduleTimezone?
                        <span className="sub-venue-date-span">
                          {`Next on ${utcToTimezone(fwSchedule.data?.subVenueSnoozedDate,fwSchedule.data?.subVenueScheduleTimezone)} (${abbreviatedTimezone(fwSchedule.data?.subVenueScheduleTimezone)})`}
                        </span>:null}
                      </div>:null}
                    </div> : (
                    <div className="mb-1">
                      <span className="table-link" onClick={() => {
                          navigate(`/organization/${activeOrgId}/notification`,{state: {filter: {orgs: fwSchedule?.response?.orgs?.map(it => {return {orgId: it}})??[], 
                            // venueChildren: false, 
                            alertType: ['notification']
                          }}})
                        }}>{fwSchedule.response?.venues?.count} Venues</span> Scheduled 
                      <div className="sub-venue-date-span">
                        Next on {utcToTimezone(fwSchedule.response.venues?.scheduleSnoozed,fwSchedule.response?.venues?.timezone)}&nbsp;({abbreviatedTimezone(fwSchedule.response?.venues?.timezone)})
                      </div>
                    </div>)
                    )}
                    </div>}
                </ToolTip>:null}
              </div>
          ) : null}
          </div>
        {/* {ticket ? <Col sm={3}>
          <Ticket className="stroke-black" id="TICKET_OPERATION" width="100%" height="100%" style={{ height: "20px", width: "20px" }} />
          <UncontrolledTooltip target="TICKET_OPERATION">{TICKET_OPERATION}</UncontrolledTooltip>
        </Col> : ` `}
        {firmware ? <Col sm={3}>
          <Firmware className="fill-black" id="FIRMWARE_OPERATION" width="100%" height="100%" style={{ height: "20px", width: "20px" }} />
          <UncontrolledTooltip target="FIRMWARE_OPERATION">{FIRMWARE_OPERATION}</UncontrolledTooltip>
        </Col> : ` `} */}
      </div>
    </div>
  );
});

OperationCounters.propTypes = {};

OperationCounters.defaultProps = {};

export default OperationCounters;
