/***
 * A Simple Alert Box
 * @file AlertBox.js
 * @description AlertBox component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React from "react";
import PropTypes from 'prop-types';
import "./AlertBox.scss";
import { Alert } from "reactstrap";

const AlertBox = (props) => {
  return (
    <Alert className={"" + props.className} color={props.color} isOpen={props.isOpen} toggle={props.toggle}>
      <div className="alert-body">{props.children}</div>
    </Alert>
  );
};

AlertBox.propTypes = {
  color: PropTypes.oneOf(["danger", "success", "info", "primary", "secondary", "dark", "light", "warning"]), //added warning as there was a warning for alert box in identities page.
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  children: PropTypes.any,
  className: PropTypes.string
};

AlertBox.defaultProps = {
  color: "primary",
  className: ""
};

export default AlertBox;
