/***
 *
 * Controller class for user.
 * @file FirmwareReschedule.js
 * @description FirmwareReschedule component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React, { useState } from "react";
// import PropTypes from 'prop-types';
import "./FirmwareReschedule.scss";
import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Flatpickr from 'react-flatpickr';
import moment from "moment";
import { Fragment } from "react";
import { useEffect } from "react";
import createRequest, { services } from "../../services";
import { make_toast } from "../../helpers";
import { CatchedWebError } from "../../configs";
import { make_custom_toast } from "../../helpers/toasts";

const FirmwareReschedule = (props) => {
  const { options, setModal, updateSnoozeDate } = { ...props };
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [disableChange, setDisableChange] = useState(false);
  const [dateOpen, setDateOpen] = useState(false);
  const [timeOpen, setTimeOpen] = useState(false);
  const [rescheduling, setRescheduling] = useState(false);
  const [scheduleDate, setScheduleDate] = useState(() => {
    if (options.scheduleDate && !!moment.tz.zone(options.timezone)) {
      const scheduleDateObj = (moment.utc(options?.scheduleDate));
      return (scheduleDateObj.tz(options?.timezone)).format('YYYY-MM-DDTHH:mm:ss.000');
    }
    else {
      return null
    }
  });
  const [snoozeDate, setSnoozeDate] = useState(() => {
    // console.log("options?.timezone: ",options?.timezone)
    // try {
    //   moment.tz.load(options?.timezone)
    // }
    // catch(err) {
    //   console.log("error in loading abbr tz", err)
    // }
    // console.log("!!moment.tz.zone(options?.timezone): ",!!moment.tz.zone(options?.timezone))
    if (options?.scheduleSnoozed && !!moment.tz.zone(options?.timezone)) {
      const snoozedDateObj = (moment.utc(options?.scheduleSnoozed));
      return (snoozedDateObj.tz(options?.timezone)).format('YYYY-MM-DDTHH:mm:ss.000');
    }
    else {
      console.log("snooze date null")
      return null;
    }
  });
  const [error, setError] = useState(null)

  useEffect(() => {
    if (options == null)
      setError(null)
  }, [options])


  useEffect(() => {
    if (snoozeDate) {
      setDate(new Date(snoozeDate));
      setTime(new Date(snoozeDate));
    }
  }, [snoozeDate])

  useEffect(() => {
    setDisableChange(rescheduling || dateOpen || timeOpen)
  }, [rescheduling, dateOpen, timeOpen])

  const handleChange = () => {
    setRescheduling(true);
    let newDate = new Date(date);
    let newTime = new Date(time);
    newDate.setHours(newTime.getHours());
    newDate.setMinutes(newTime.getMinutes());
    const newDateObj = moment.tz(moment(newDate).format("YYYY-MM-DDTHH:mm:ss"), options?.timezone);
    newDate = (newDateObj.utc()).format("YYYY-MM-DDTHH:mm:ssZ");
    newDate = new Date(newDate);

    const { run } = options?.rescheduleInfra ?
      createRequest(services.fwupgrade.RESCHEDULE_INFRA, [options?.infraItemId], {
        "scheduleId": options?.scheduleId,
        "scheduleDate": newDate.toISOString().slice(0, -5) + "Z"
      })
      : createRequest(services.fwupgrade.RESCHEDULE_VENUE, [options?.venueId], {
        "scheduleId": options?.scheduleId,
        "scheduleDate": newDate.toISOString().slice(0, -5) + "Z"
      })
    run()
      .then((response) => {
        make_custom_toast("success", "Firmware", "Rescheduled Successfully!")
        // make_toast("success","Rescheduled Successfully!", true);
        const newDateUTC = newDate.toISOString().slice(0, -5) + "Z";
        const newSnoozedDateObj = (moment.utc(newDateUTC));
        if (options?.rescheduleInfra)
          updateSnoozeDate(newSnoozedDateObj, options.infraItemId, options?.rescheduleInfra)
        else
          updateSnoozeDate(newSnoozedDateObj, options.venueId);
        setModal(null);
      })
      .catch((err) => {
        // make_toast("error",(new CatchedWebError(err)).message);
        setError((new CatchedWebError(err)).message)
      })
      .finally(() => {
        setRescheduling(false)
      })
  }

  const minDateCalc = () => {
    const minDate = new Date(moment.tz((moment.utc().format("YYYY-MM-DDTHH:mm:ss")), options?.timezone).format("YYYY-MM-DDTHH:mm"))
    if (!!minDate) {
      minDate.setHours(0);
      minDate.setMinutes(0);
      return minDate
    }
    else return 'today'
  }
  const maxDateCalc = (scheduleDate) => {
    const schDate = new Date(scheduleDate);
    return schDate.setDate(schDate.getDate() + 7);
  }
  const minTimeCalc = (date) => {
    //if date is same as today's date then min time as per current venue local time else min time is 00:00
    const currVenueTzDate = new Date(((moment.utc()).tz(options?.timezone)).format('YYYY-MM-DDTHH:mm:ss.000'))
    if (currVenueTzDate && date &&
      currVenueTzDate.getFullYear() === date.getFullYear()
      && currVenueTzDate.getMonth() === date.getMonth()
      && currVenueTzDate.getDate() === date.getDate())
      return (currVenueTzDate.getHours() + ':' + currVenueTzDate.getMinutes())
    else return '00:00'
  }
  const maxTimeCalc = (scheduleDate, date) => {
    const schDate = scheduleDate ? new Date(scheduleDate) : null;
    if (schDate) {
      schDate.setDate(schDate.getDate() + 7);
      if (date &&
        schDate.getFullYear() === date.getFullYear()
        && schDate.getMonth() === date.getMonth()
        && schDate.getDate() === date.getDate()) {
        return (schDate.getHours() + ':' + schDate.getMinutes())
      }
      else return '23:59'
    }
    else return '23:59'
  }
  return (
    <Modal centered isOpen={!!options} toggle={() => { setModal(null); }}>
      <ModalHeader toggle={() => { setModal(null); }} className='bg-white'>
        Change Scheduled Update
      </ModalHeader>
      <ModalBody>
        Select when you want to start the update.<br />
        <Fragment>
          <Label for='default-picker' className='mt-1'>Select Date</Label>
          <Flatpickr
            className='form-control'
            value={date}
            options={
              {
                minDate: minDateCalc(),
                maxDate: maxDateCalc(scheduleDate)
              }}
            onChange={date => {
              setDate(date[0])
              //change time if less than min time
              const currentTimeHours = time.getHours()
              const currentTimeMinutes = time.getMinutes()
              const minTimeString = minTimeCalc(date[0])
              const timeElementsArray = minTimeString.split(':')
              if (timeElementsArray.length === 2) {
                const minTimeHours = Number(timeElementsArray[0])
                const minTimeMinutes = Number(timeElementsArray[1])
                if (currentTimeHours < minTimeHours ||
                  (currentTimeHours === minTimeHours && currentTimeMinutes < minTimeMinutes)) {
                  setTime(new Date(((moment.utc()).tz(options?.timezone)).format('YYYY-MM-DDTHH:mm:ss.000')))
                }
              }
            }}
            onOpen={() => { setDateOpen(true) }}
            onClose={() => { setDateOpen(false) }}
            id='default-picker'
          />
        </Fragment>
        <Fragment>
          <Label className='mt-1' id='timepicker'>Select Time</Label>
          <Flatpickr
            className='form-control'
            value={time}
            id='timepicker'
            options={{
              enableTime: true,
              noCalendar: true,
              dateFormat: 'h:i K',
              time_24hr: false,
              // minTime: minTimeCalc(date),
              // maxTime: maxTimeCalc(scheduleDate, date)
            }}
            onChange={date => setTime(date[0])}
            onOpen={() => { setTimeOpen(true) }}
            onClose={() => { setTimeOpen(false) }}
          />
          <div className="text-right font-weight-bold">{options?.timezone?.replace("_", " ")}</div>
          {!!error ?
            <div className="text-danger" style={{ maxHeight: '2rem' }}>
              {error}
            </div> : null}
        </Fragment>
      </ModalBody>
      <ModalFooter>
        <Button.Ripple disabled={disableChange} outline color='primary' onClick={() => { setModal(null); }}>Cancel</Button.Ripple>
        <Button.Ripple disabled={disableChange} color='primary' onClick={() => { handleChange() }}>Change</Button.Ripple>
      </ModalFooter>
    </Modal>
  );
};

FirmwareReschedule.propTypes = {};

FirmwareReschedule.defaultProps = {};

export default FirmwareReschedule;
