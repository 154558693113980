import { Button, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import "./LayoutDeletionConfirmation.scss";
import { make_toast } from "../../../../helpers";
import { make_custom_toast } from "../../../../helpers/toasts";

/**
 * Layout Deletion Confirmation Modal
 * @param {{
 *   isOpen: {id: number, name: string},
 *   toggle: (_: number | ((_: number) => number)) => void,
 *   deleteLayout: (id: number, resolve: Function, reject: Function) => void
 * }} props 
 * @returns 
 */
const LayoutDeletionConfirmation = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const { deleteLayout, isOpen, toggle } = props;

  const deleteWithLoader = useCallback(() => {
    setIsDeleting(true);
    deleteLayout(
      isOpen.id,
      () => {
        setIsDeleting(false);
        make_custom_toast('success', 'Layout', 'Layout deleted successfully.')
        toggle();
      },
      () => { setIsDeleting(false); }
    );
  }, [deleteLayout, isOpen, toggle]);

  return (
    <Modal className="LayoutDeletionConfirmation" centered isOpen={props.isOpen.id !== 0} toggle={() => { if (!isDeleting) props.toggle(); }}>
      <ModalHeader className="bg-white" toggle={() => { if (!isDeleting) props.toggle() }}></ModalHeader>
      <ModalBody className="pb-2">
        <div className="text-center">
          <span className="text-warning material-symbols-outlined delete-warning-icon">info</span>
        </div>
        {isDeleting ?
          <div className="d-flex justify-content-center">
            <Spinner size="lg" />
          </div>
          :
          <div className="text-center mt-2 mb-50">
            <h5>Are you sure you want to delete</h5>
            <h5><strong>"{props.isOpen.name}"?</strong></h5>
          </div>
        }
        <div className="d-flex justify-content-center mt-2">
          <Button color="primary" onClick={props.toggle} disabled={isDeleting}>Cancel</Button>
          <Button color="outline-danger" className="ml-1" onClick={deleteWithLoader} disabled={isDeleting}>Yes</Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

LayoutDeletionConfirmation.propTypes = {
  isOpen: PropTypes.object,
  toggle: PropTypes.func,
  deleteLayout: PropTypes.func,
};
LayoutDeletionConfirmation.defaultProps = {};

export default LayoutDeletionConfirmation;