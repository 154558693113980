import { useCallback, useEffect, useRef, useState } from "react";
import { $g } from "../utils";
import { LAYOUT_TYPES } from "../constants";

function useDynamicScaling(setCurrentOffset = null, layouts, currentZoomValue) {
  const containerReference = useRef(null);
  const stageReference = useRef();
  const [dimensions, setDimensions] = useState({ width: 100, height: 100 });
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  let OFFSET_CONSTANT = 1
  if (layouts && (layouts.length > 0)) {
    if (layouts[0]?.layoutType == LAYOUT_TYPES.IMAGE) {
      OFFSET_CONSTANT = 3 //hinthere this is only for image and can be moved for both support layouts
    }
  }
  const setCurrentSize = useCallback(() => {
    if (containerReference.current?.offsetHeight && containerReference.current?.offsetWidth) {
      setDimensions({
        width: containerReference.current.offsetWidth,
        height: containerReference.current.offsetHeight
      });
    }
  }, []);

  /**
   * Center Layout View At this real point
   * @param {Point} point 
   */
  const centerLayoutViewAt = (point) => {
    point.x = -point.x;
    point.y = -point.y;
    stageReference && stageReference.current.position($g(point));
  };

  // run it once
  useEffect(() => {
    setCurrentSize();
  }, [setCurrentSize]);

  useEffect(() => {
    // let newOffset = { x: -(dimensions.width), y: -(dimensions.height) }
    let newOffset = { x: -(dimensions.width * (100 / (200 * currentZoomValue))), y: -(dimensions.height * (100 / (200 * currentZoomValue))) }
    if (layouts && (layouts.length > 0)) {
      if (layouts[0]?.layoutType == LAYOUT_TYPES.IMAGE) {
        //hinthere this is only for image and can be moved for both support layouts
        newOffset = { x: -(dimensions.width / 3), y: -(dimensions.height / 4) }
      }
    }
    setOffset(newOffset);
    if (setCurrentOffset) {
      setCurrentOffset(newOffset)
    }
  }, [dimensions]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setCurrentSize();
    })
  }, [setCurrentSize]);

  return { dimensions, setDimensions, offset, setOffset, containerReference, stageReference, centerLayoutViewAt };
}

export default useDynamicScaling;