import PropTypes from "prop-types";
import "./WallsListItem.scss";

/**
 * Wall Type List Item
 * @param {{
 *   label: string
 *   value: string
 *   shorthand: string
 *   color: string
 *   highlighted: string
 *   selectedWall: { layoutUuid: string?, wallId: string? }
 *   setWallMaterial: (layoutUuid: string, wallId: string, toMaterial: string) => void
 * }} props 
 */
const WallsListItem = (props) => {
  return (
    <div
      onClick={() => {
        if (props.selectedWall.layoutUuid && props.selectedWall.wallId && props.value) {
          props.setWallMaterial(props?.selectedWall.layoutUuid, props?.selectedWall?.wallId, props?.value);
        }
      }}
      className="WallsListItem py-1 px-1 d-flex justify-content-between border-bottom align-items-center"
      style={{ backgroundColor: (props?.highlighted) === props?.value ? props?.color + "88" : "white" }}
    >
      <div>{props.label}</div>
      <div className="wall-color-code d-flex align-items-center border-secondary rounded-pill p-25 justify-content-around bg-white">
        <span className="material-symbols-outlined" style={{ color: props?.color }}>circle</span>
        <span className="wall-shorthand mx-25">{props?.shorthand}</span>
      </div>
    </div>
  );
};

WallsListItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  shorthand: PropTypes.string,
  color: PropTypes.string,
  highlighted: PropTypes.string,
  onClick: PropTypes.func,
  selectedWall: PropTypes.object,
  setWallMaterial: PropTypes.func
};
WallsListItem.defaultProps = {
  highlighted: ""
};

export default WallsListItem;