/**
 * URL Builder Function
 * @file urlBuilder.js
 * @description Contains functions related to creating complete URLs
 * @author Utkarsh Gupta
 * @since 26 Jun 2022
 */


import { BASE_URL, VENUE_URL, INFRASTRUCTURE_URL, NETWORKS_URL, TELEMETRY_URL, DEVICE_URL, FWUPGRADE_URL, TICKET_URL } from "../configs";
import { MARKETPLACE_URL, UPLOAD_URL } from "../configs/urls";

/**
 * Prepend BASE_URL to the relative URL
 * @param {string} url 
 * @returns Absolute URL (`BASE_URL + url`)
 */
function B(url) {
  return BASE_URL.concat(url);
}

/**
 * Prepend VENUE_URL to the relative URL
 * @param {string} url
 * @returns Absolute URL (`VENUE_URL + url`)
 */
function V(url) {
  return VENUE_URL.concat(url);
}

/**
 * Prepend INFRASTRUCTURE_URL to the relative URL
 * @param {string} url
 * @returns Absolute URL (`INFRASTRUCTURE + url`)
 */
function I(url) {
  return INFRASTRUCTURE_URL.concat(url);
}

function N(url) {
  /**
 * Prepend NETWORKS_URL to the relative URL
 * @param {string} url
 * @returns Absolute URL (`NETWORKS + url`)
 */
  return NETWORKS_URL.concat(url);
}

function T(url) {
  /**
 * Prepend TELEMETRY_URL to the relative URL
 * @param {string} url
 * @returns Absolute URL (`TELEMETRY + url`)
 */
  return TELEMETRY_URL.concat(url);
}

function D(url) {
  /**
* Prepend DEVICE_URL to the relative URL
* @param {string} url
* @returns Absolute URL (`DEVICE + url`)
*/
  return DEVICE_URL.concat(url);
}

function U(url) {
  /**
* Prepend FWUPGRADE_URL to the relative URL
* @param {string} url
* @returns Absolute URL (`FWUPGRADE + url`)
*/
  return FWUPGRADE_URL.concat(url);
}

function TICKET(url) {
  /**
* Prepend TICKET_URL to the relative URL
* @param {string} url
* @returns Absolute URL (`TICKET + url`)
*/
  return TICKET_URL.concat(url);
}

function UPLOAD(url) {
  /**
* Prepend TICKET_URL to the relative URL
* @param {string} url
* @returns Absolute URL (`TICKET + url`)
*/
  return UPLOAD_URL.concat(url);
}

function MARKETPLACE(url) {
  /**
* Prepend MARKETPLACE_URL to the relative URL
* @param {string} url
* @returns Absolute URL (`MARKETPLACE + url`)
*/
  return MARKETPLACE_URL.concat(url);
}

export { B, V, I, N, T, D, U, TICKET, UPLOAD, MARKETPLACE };
