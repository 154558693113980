import { Col, Container, Row } from "reactstrap";
import Skeleton from "./Skeleton";
import "./ProgressSkeleton.scss"

const ProgressSkeleton = ({ right=true }) => {
  return (
      <div className="ProgressSkeleton d-flex justify-content-between w-100">
        <div className="d-flex align-items-end">
          <Skeleton height="25px" width="25px" className='bottom-margin--skeleton'/>
          
          {right?<><span className="text-lg forward-slash" style={{color: '#444'}}>/</span>
          <Skeleton height="15px" width="25px" className='bottom-margin--skeleton'/></>:null}
        </div>
        <div className="d-flex align-items-end">
          <Skeleton height="15px" width="50px" className='bottom-margin-left--skeleton'/>
        </div>
      </div>
  );
};

export default ProgressSkeleton;