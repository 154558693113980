import { oneNetworkActions } from "../slices";
import store from "../store";
import createRequest, {services} from "../../services";
import { CatchedWebError } from "../../configs";

const getNetwork = (networkId) => {
  store.dispatch(oneNetworkActions.setLoading(true));

  const { controller, run } = createRequest(services.networks.GET_NETWORK, [networkId]);

  run()
    .then(response => {
      store.dispatch(oneNetworkActions.setError(null));
      store.dispatch(oneNetworkActions.setNetwork(response.data));
    })
    .catch(err => {
      let x = new CatchedWebError(err);
      store.dispatch(oneNetworkActions.setError(x.message));
    })
    .finally(() => {
      store.dispatch(oneNetworkActions.setLoading(false));
    });
  return controller;
};

export default getNetwork;