import PropTypes from "prop-types";
import { Layer } from "react-konva";
import DropCable from "../DropCable";
import { EditableLayout } from "../../../utils";

/**
 * Access Points Layer
 * @param {{
 *  scaleFactor: number,
 *  editableLayout: EditableLayout
 *  selectedAp: string,
 *  setSelectedAp: (uuid: string) => void
 * }} props 
 * @returns 
 */
const DropCables = (props) => {
  return (
    <Layer>
      {Object.keys(props.editableLayout.cableDropList).map(uuid => {
        return (
          <DropCable
            key={uuid}
            scaleFactor={props.scaleFactor}
            selectedCableDrop={props.selectedCableDrop}
            setSelectedCableDrop={props.setSelectedCableDrop}
            data={props.editableLayout.cableDropList[uuid]}
            updateCableName={props.updateCableName}
            isSelected={props.selectedCableDrop === uuid}
            uuid={uuid}
            moveCableDrop={props.moveCableDrop}
            translateCableDrop={props.translateCableDrop}
            stageReference={props.stageReference}
            handleWheel={props.handleWheel}
            onClick={() => {
              if (props.moveCableDrop && props.selectedCableDrop !== uuid) {
                props.setSelectedCableDrop(uuid)
              }
              else {
                props.setSelectedCableDrop(null)
              }
            }}
          />
        );
      })}
    </Layer>
  );
};

DropCables.propTypes = {};
DropCables.defaultProps = {};

export default DropCables;