import PropTypes from "prop-types";
import { Layer } from "react-konva";
import { EditableLayout } from "../../utils";
import AccessPoint from "./AccessPoint";
import { useEffect } from "react";

/**
 * Access Points Layer
 * @param {{
 *  scaleFactor: number,
 *  editableLayout: EditableLayout
 *  selectedAp: string,
 *  setSelectedAp: (uuid: string) => void
 * }} props 
 * @returns 
 */
const AccessPoints = (props) => {

  return (
    <Layer>
      {Object.keys(props.editableLayout.infraPositions).map(uuid => {
        if (props.editableLayout.infraPositions[uuid].infraCategory == 1)
          return (
            <AccessPoint
              key={uuid}
              scaleFactor={props.scaleFactor}
              selectedAp={props.selectedAp}
              setSelectedAp={props.setSelectedAp}
              data={props.editableLayout.infraPositions[uuid]}
              isSelected={props.selectedAp === uuid}
              uuid={uuid}
              moveAp={props.moveAp}
              translateAp={props.translateAp}
              stageReference={props.stageReference}
              onClick={() => {
                if (props.moveAp && props.selectedAp !== uuid) {
                  props.setSelectedAp(uuid)
                }
              }}
            />
          );
      })}
    </Layer>
  );
};

AccessPoints.propTypes = {};
AccessPoints.defaultProps = {};

export default AccessPoints;