/***
 *
 * Controller class for user.
 * @file SwitchOverview.js
 * @description SwitchOverview component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import React, { Suspense, lazy, useCallback, useEffect, useState } from "react";
import SwitchHeader from "../_builder/SwitchHeader";
import "./SwitchOverview.scss";
import { useDispatch, useSelector } from "react-redux";
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown, Button, Modal, ModalHeader, ModalBody, Alert, UncontrolledTooltip, Spinner, Input, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import DummySwitch from "../_switches/DummySwitch";
import createRequest, { services } from "../../../services";
import { Actions, AlertBox, SupportModal, VeryFancyLoader } from "../../../components";
import useAutoclear from "../../../hooks/useAutoclear";
import { CatchedWebError } from "../../../configs";
import { breadcrumbActions, infraListActions, oneInfraActions } from "../../../redux/slices";
import { useNavigate, useParams } from "react-router-dom";
import REDUX_WORKER from "../../../redux/workers";
import imageCompression from "browser-image-compression";
import UpdateLink from "../../../components/UpdateLink";
import { FloorplanSlide, ImagePage, handleCopy, hideCopyIcon, showCopyIcon } from "../APOverview";
import { IMGS } from "../_builder/const";
import { ReactComponent as Ticket } from "../../../assets/images/icons/Ticket.svg";
import { ReactComponent as Reboot } from "../../../assets/images/icons/Reboot.svg";
import { ReactComponent as Reset } from "../../../assets/images/icons/FactoryReset.svg";
import { ReactComponent as Connect } from "../../../assets/images/icons/Connect.svg";
import { ReactComponent as Floor } from "../../../assets/images/icons/Floor.svg";
import { ReactComponent as OrgGlobe } from "../../../assets/images/icons/OrgGlobe.svg";
import { ReactComponent as Validate } from "../../../assets/images/icons/validate-icon-black.svg";
import { ReactComponent as InfraNotesIcon } from "../../../assets/images/icons/Infra_notes_icon.svg";
import { ReactComponent as Install } from "../../../assets/images/icons/install_cloud_color.svg"
import { ReactComponent as InstallIconBlack } from "../../../assets/images/icons/install_cloud.svg"
import { ReactComponent as LayoutIcon } from "../../../assets/images/icons/placement-icon.svg"
import { ReactComponent as LayoutIconBlack } from "../../../assets/images/icons/placement-icon-black.svg"
import { ReactComponent as ValidateIcon } from '../../../assets/images/icons/validate-icon.svg'
import { ReactComponent as ValidateIconBlack } from '../../../assets/images/icons/validate-icon-black.svg'
import { ReactComponent as CameraImageBlack } from "../../../assets/images/icons/camera-icon-black.svg";
import { ReactComponent as CameraImage } from "../../../assets/images/icons/camera-icon.svg";
import { ReactComponent as Success } from "../../../assets/images/icons/Success.svg"

import { ReactComponent as TicketIcon } from '../../../assets/images/icons/ticket-icon-col.svg'
import { ReactComponent as UpdateIcon } from "../../../assets/images/icons/UpdateIcon.svg";

import { useRef } from "react";
import PencilIcon from '../../../assets/images/icons/Pencil.svg';
import axios from "axios";
import { make_toast } from "../../../helpers";
import Empty from "../APOverview/Empty";
import TimeProgress from "../../../components/TimeProgress";
import moment from 'moment-timezone';
import DeleteIcon from '../../../assets/images/icons/Delete.svg';
import { make_custom_toast } from "../../../helpers/toasts.js";
import { CONNECT_RTTY, SWITCH_REBOOT, SWITCH_RESET } from "../../../utility/tooltip-config-global.js";
import { AddIcon } from "../../../assets/images/icons/Icons.js";
import { DEPLOYMENT_VIEW, THIRD_PARTY_TITLE } from "../../../utility/constants.js";
import { ipFormater } from "../InfraList/index.js";
import { UPTIME } from "../Details/index.js";
import LightBadge from "../../../components/LightBadge/index.js";
import { statusIcons, versionColors } from "../../Venue/VenueTree/index.js";
import { dateTimeFormatter } from "../../../utility/Localization/index.js";
import SwitchSVG from "../InfraList/SwitchSVG.js";
import AlertInfraTable from "../../../components/AlertInfraTable/index.js";
import InfraInsightCharts from "../../../components/InfraInsightCharts/index.js";
import InfraImageModal from "../Components/InfraImageModal.js";
import InstalledImageModal from "../Components/InstalledImageModal.js";
import { FAKE_WALL_DATA, LAYOUT_TYPES } from "../../Venue/FloorPlan2/constants.js";
import { dateLeftFinder } from "../../../utility/Utils.js";
import RoomLocationModal from "../Components/RoomLocationModal.js";
import FirmwareReschedule from "../../../components/FirmwareRescheduleVenue/index.js";
const Copy = lazy(() => import("../APOverview/Copy.js"));
const Device = lazy(() => import("../APOverview/Device.js"));

const COPYABLE_ID = {
  PUBLIC_IP: 'switch-public-ip-copy-icon',
  PRIVATE_IP: 'switch-private-ip-copy-icon',
  MAC_ADDRESS: 'switch-mac-add-copy-icon',

}

export function timeDiff(date) {
  if (!date)
    return "-";
  const fixedDate = new Date(date);
  const now = new Date();
  const diff = now - fixedDate;
  if (Math.floor(diff / (24 * 60 * 60 * 1000)) > 0)
    return (Math.floor(diff / (24 * 60 * 60 * 1000))) + " Day(s)"
  if (Math.floor(diff / (60 * 60 * 1000)) > 0)
    return (Math.floor(diff / (60 * 60 * 1000))) + " Hr(s)"
  if (Math.floor(diff / (60 * 1000)) > 0)
    return (Math.floor(diff / (60 * 1000))) + " Min(s)"

  return "few sec(s)"
}


const SwitchOverview = () => {
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const issues = useSelector(store => store.oneInfra.issues) ?? [];
  const alarms = useSelector(store => store.oneInfra.alarms) ?? [];
  const infra = useSelector(store => store.oneInfra.data);
  const infraId = useSelector(store => store.oneInfra.data.infraItemId);
  const venueId = useSelector(store => store.oneInfra.data.venueId);
  const infraDetails = useSelector(store => store.oneInfra.details.data);
  const rebooting = useSelector(store => store.rebooting.data[infraId]);
  const infraTypeId = useSelector(store => store.oneInfra.data.infraTypeId);
  const infraMacAddress = useSelector(store => store.oneInfra.data.macAddress);
  const description = useSelector(store => store.oneInfra.data.description);
  const infraTypes = useSelector(store => store.infraTypes.data);
  const infraDisplayName = useSelector(store => store.oneInfra.data.infraDisplayName);
  const infraType = useSelector(store => store.infraTypes.data.find(it => it.infraTypeId == infraTypeId))
  const permissions = useSelector(store => store?.rbac?.permissions);
  const venuePermissions = useSelector(store => store?.rbac?.venuePermissions);
  const features = useSelector(store => store?.oneInfra?.features)
  const stats = useSelector(store => store?.oneInfra?.stats)
  const view = useSelector(store => store.identity.meta.view);
  const [connectURL, setConnectURL] = useState(null);
  const [consoleLoading, setConsoleLoading] = useState(true);
  const [error, setError] = useState(null);
  const [support, setSupport] = useState(false)
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [unassignConfirmModal, setUnassignConfirmModal] = useState(false);
  const [enableDropdown, setEnableDropdown] = useState(false)
  const [enableCommands, setEnableCommands] = useState(false)

  const [deleting, setDeleting] = useState(false);
  const [unassigning, setUnassigning] = useState(false);

  const [unassignError, setUnassignError] = useState(null);
  const [deleteError, setDeleteError] = useState(null);
  const [config, setConfig] = useState(null);

  const hiddenFileInput = useRef(null);
  const noteRef = useRef(null);

  const [imgLoading, setImgLoading] = useState(true);
  const [instImg, setInstImg] = useState({});
  const [instCount, setInstCount] = useState([]);
  const [instActive, setInstActive] = useState(0);

  const [notesError, setNotesError] = useState(null);
  const [noteModal, setNoteModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { infraId: infraUrlId } = useParams();

  const [unassignSuccess, setUnassignSuccess] = useState(false);
  const [runCommand, setRunCommand] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);

  const [statsLoading, setStatsLoading] = useState(false);
  const [scheduleDate, setScheduleDate] = useState(null);
  const [timezone, setTimezone] = useState(null);

  const [infraImageModal, setInfraImageModal] = useState(false)
  const [installedImageModal, setInstalledImageModal] = useState(false)

  const [floorplanList, setFloorplanList] = useState([])
  const [floorplanLoading, setFloorplanLoading] = useState(false)
  const [roomLocationModal, setRoomLocationModal] = useState(false);
  const [options, setOptions] = useState(null)


  let interval;

  useEffect(() => {
    REDUX_WORKER.getDetails(infra.macAddress);
  }, [infra.connected])


  // old implementation
  // const InstallationImages = () => {
  //   const [deleting, setDeleting] = useState(false);
  //   const [first, setFirst] = useState(true);

  //   const removeImg = () => {
  //     const { run } = createRequest(services.infra.INSTALL_IMG_DELETE, [infra?.infraItemId, instCount[instActive]])

  //     if (!deleting && instCount[instActive]) {
  //       setDeleting(true)
  //       run()
  //         .then(() => {
  //           setInstImg(prevState => {
  //             let newObj = { ...prevState }
  //             delete newObj[instCount[instActive]];
  //             return { ...newObj }
  //           });
  //           setInstCount(prevState => {
  //             let newArray = [...prevState];
  //             newArray.splice(instActive, 1);
  //             return [...newArray];
  //           })
  //           setInstActive(0);
  //         })
  //         .catch((err) => {
  //           setError((new CatchedWebError(err)).message)
  //         })
  //         .finally(() => setDeleting(false))
  //     }
  //   }

  //   async function uploadImageToCloud(img) {
  //     if (!!img) {
  //       const options = { maxSizeMB: 5, maxWidthOrHeight: 250, useWebWorker: true }
  //       const selectedImageCom = await imageCompression(img, options);
  //       const { run } = createRequest(services.infra.INSTALL_IMG, [infra?.infraItemId, 'put', Number(instCount.slice(-1)) + 1]);
  //       try {
  //         let response = await run();
  //         await axios.put(response.data, selectedImageCom)
  //           .then(() => {
  //             const { run } = createRequest(services.telemetry.UPDATE_TASK, [infra.macAddress], { photos: true })
  //             if (first) {
  //               run()
  //               setFirst(false);
  //             }
  //           });
  //       } catch (err) {
  //         setError("Failed to upload Installation Image. Please try again later");
  //         window.scroll(0, 0)
  //       }
  //     }
  //   }

  //   const uploadImage = (event) => {
  //     if (["image/png", "image/jpg", "image/jpeg"].includes(event.target.files[0]?.type)) {
  //       const ImgURL = URL.createObjectURL(event.target.files[0]);
  //       setInstImg(prevState => { return { ...prevState, [Number(instCount.slice(-1)) + 1]: ImgURL } })
  //       setInstCount(prevState => [...prevState, Number(instCount.slice(-1)) + 1])
  //       setInstActive(instCount.length);
  //       uploadImageToCloud(event.target.files[0]);
  //       hiddenFileInput.current.value = null;
  //     }
  //     else
  //       make_toast("error", "Invalid Format, png/jpg Accepted Only", true);
  //   }

  //   return (
  //     <div className="border rounded bg-grey p-1">
  //       <div className="d-flex align-items-center justify-content-between">
  //         <h6 className="font-weight-bolder">Installation Image</h6>
  //         {(venuePermissions[venueId]?.infra?.update == null ? permissions?.manageInfra?.update : venuePermissions[venueId]?.infra?.update) ?
  //           <div className="d-flex">
  //             <div className="cursor-pointer d-flex align-items-center" onClick={() => hiddenFileInput.current.click()}><AddIcon width={22} height={22} /></div>
  //             <div className="cursor-pointer d-flex align-items-center ml-1" onClick={removeImg}><img src={DeleteIcon} /></div>
  //           </div> : null}
  //       </div>
  //       <div className="p-1">
  //         <Row className="bg-white border rounded">
  //           <Col xs={7}>
  //             <div className="d-flex align-items-center justify-content-center" style={{ minHeight: "299px", maxHeight: "300px" }}>
  //               <input label="Logo" type="file" name="file" id="file" ref={hiddenFileInput} style={{ display: 'none' }} onChange={uploadImage} />
  //               {
  //                 imgLoading ?
  //                   <div className="text-center"><Spinner color="primary" /></div> :
  //                   instCount?.length > 0 ?
  //                     <div className="d-flex align-items-center justify-content-center p-1">
  //                       <img className="inst-img w-100" src={instImg[instCount[instActive]]} onError={() => {
  //                         setInstImg({})
  //                       }} />
  //                     </div> :
  //                     <div className="d-flex flex-column align-items-center justify-content-center">
  //                       <Suspense fallback={<></>}><Empty /></Suspense>
  //                       <small>You don't have any uploaded images</small>
  //                       {(venuePermissions[venueId]?.infra?.update == null ? permissions?.manageInfra?.update : venuePermissions[venueId]?.infra?.update) ?
  //                         <div className="text-primary font-weight-bolder cursor-pointer" onClick={() => hiddenFileInput.current.click()}>
  //                           Upload New Image
  //                         </div> : null}
  //                     </div>
  //               }
  //             </div>
  //           </Col>
  //           <Col xs={5}>
  //             <div className="my-1">
  //               <div className="d-flex justify-content-between align-items-center">
  //                 <h5 className="font-weight-bolder">Notes</h5>
  //                 {(venuePermissions[venueId]?.infra?.update == null ? permissions?.manageInfra?.update : venuePermissions[venueId]?.infra?.update) ?
  //                   <img className="cursor-pointer" src={PencilIcon} onClick={() => setNoteModal(true)} /> : null}
  //               </div>
  //               <div style={{ whiteSpace: "pre-line", maxHeight: "250px", overflow: "auto" }}>
  //                 {infra?.description || ((venuePermissions[venueId]?.infra?.update == null ? permissions?.manageInfra?.update : venuePermissions[venueId]?.infra?.update) ? <em className="text-secondary">Add a note</em> : null)}
  //               </div>
  //             </div>
  //           </Col>
  //         </Row>
  //       </div>
  //       <Pagination className={instCount?.length > 0 ? "" : "d-none"} size="sm">
  //         <PaginationItem key={"prev"}>
  //           <PaginationLink onClick={() => { if (instActive > 0) { setInstActive(prevState => prevState - 1); } }}>
  //             <span className="material-symbols-outlined" style={{ fontSize: "1.1rem" }}>chevron_left</span>
  //           </PaginationLink>
  //         </PaginationItem>
  //         {instCount?.map((img, index) => {
  //           return <PaginationItem active={instActive == index} key={index}>
  //             <PaginationLink onClick={() => { if (instActive != index); setInstActive(index); }}>
  //               {index + 1}
  //             </PaginationLink>
  //           </PaginationItem>
  //         })}
  //         <PaginationItem key={"next"}>
  //           <PaginationLink onClick={() => { if (instActive < (instCount?.length - 1)) { setInstActive(prevState => prevState + 1); } }}>
  //             <span className="material-symbols-outlined" style={{ fontSize: "1.1rem" }}>chevron_right</span>
  //           </PaginationLink>
  //         </PaginationItem>
  //       </Pagination>
  //       <div className={instCount?.length > 0 ? "d-none" : ""} style={{ minHeight: "40px" }}>&nbsp;</div>
  //     </div>
  //   )
  // }
  const InstallationImages = () => {
    const [deleting, setDeleting] = useState(false);
    const [first, setFirst] = useState(true);

    const removeImg = () => {
      const { run } = createRequest(services.infra.INSTALL_IMG_DELETE, [infra?.infraItemId, instCount[instActive]])

      if (!deleting && instCount[instActive]) {
        setDeleting(true)
        run()
          .then(() => {
            setInstImg(prevState => {
              let newObj = { ...prevState }
              delete newObj[instCount[instActive]];
              return { ...newObj }
            });
            setInstCount(prevState => {
              let newArray = [...prevState];
              newArray.splice(instActive, 1);
              return [...newArray];
            })
            setInstActive(0);
          })
          .catch((err) => {
            setError((new CatchedWebError(err)).message)
          })
          .finally(() => setDeleting(false))
      }
    }

    async function uploadImageToCloud(img) {
      if (!!img) {
        const options = { maxSizeMB: 5, maxWidthOrHeight: 250, useWebWorker: true }
        const selectedImageCom = await imageCompression(img, options);
        const { run } = createRequest(services.infra.INSTALL_IMG, [infra?.infraItemId, 'put', Number(instCount.slice(-1)) + 1]);
        try {
          let response = await run();
          await axios.put(response.data, selectedImageCom)
            .then(() => {
              const { run } = createRequest(services.telemetry.UPDATE_TASK, [infra.macAddress], { photos: true })
              if (first) {
                run()
                  .then(() => {
                    REDUX_WORKER.getInfra(infra?.infraItemId, false) //this will make photos boolean true in infra details response
                  })
                setFirst(false);
              }
            });
        } catch (err) {
          setError("Failed to upload Installation Image. Please try again later");
          window.scroll(0, 0)
        }
      }
    }

    const uploadImage = (event) => {
      if (["image/png", "image/jpg", "image/jpeg"].includes(event.target.files[0]?.type)) {
        const ImgURL = URL.createObjectURL(event.target.files[0]);
        setInstImg(prevState => { return { ...prevState, [Number(instCount.slice(-1)) + 1]: ImgURL } })
        setInstCount(prevState => [...prevState, Number(instCount.slice(-1)) + 1])
        setInstActive(instCount.length);
        uploadImageToCloud(event.target.files[0]);
        hiddenFileInput.current.value = null;
      }
      else
        make_toast("error", "Invalid Format, png/jpg Accepted Only", true);
    }



    return (
      <div className="">
        <div className="d-flex align-items-center justify-content-between mb-50">
          <div className="d-flex align-items-center">
            <div style={{ position: 'relative' }}>

              {
                infra?.photos ?
                  <CameraImage className="mr-50" width={22} height={22} /> :
                  <CameraImageBlack className="mr-50" width={22} height={22} />
              }
              {infra?.photos
                ? <div className="success-top-icon-small">
                  <Success width={14} height={14} />
                </div>
                : <></>
              }
            </div>
            <h6 className="font-weight-500 p-0 m-0">Installation Images</h6>
          </div>
          {(venuePermissions[venueId]?.infra?.update == null ? permissions?.manageInfra?.update : venuePermissions[venueId]?.infra?.update) ?
            <div className="d-flex">
              <div className="cursor-pointer d-flex align-items-center" onClick={() => hiddenFileInput.current.click()}><AddIcon width={22} height={22} /></div>
              {/* <div className="cursor-pointer d-flex align-items-center ml-1" onClick={removeImg}><img src={DeleteIcon} /></div> */}
            </div> : null}
        </div>
        <div className="">
          {/* <Row className="bg-white border rounded"> */}
          {/* <Col> */}
          <div
            className="d-flex align-items-center justify-content-center"
          // style={{ minHeight: "299px", maxHeight: "300px" }}
          >
            <input label="Logo" type="file" name="file" id="file" ref={hiddenFileInput} style={{ display: 'none' }} onChange={uploadImage} />
            {
              imgLoading ?
                <div className="text-center"><Spinner color="primary" /></div> :
                instCount?.length > 0 ?
                  <div className="d-flex w-100 flex-column align-items-center justify-content-center ">
                    <img
                      className="inst-img w-100 cursor-pointer"
                      src={instImg[instCount[instActive]]}
                      onClick={() => setInstalledImageModal(true)}
                      onError={() => {
                        setInstImg({})
                      }} />
                    <div className="d-flex align-items-center justify-content-between mt-50 w-100 ">
                      <span
                        className="material-symbols-outlined text-primary cursor-pointer"
                        style={{ fontSize: "1.8rem" }}
                        onClick={() => { if (instActive > 0) { setInstActive(prevState => prevState - 1); } }}
                      >chevron_left</span>

                      <div className="d-flex align-items-center">
                        {instCount?.length > 0 && instCount.map((img, index) => {
                          return (
                            <div className={`${index == instActive ? 'selected-img-dot' : 'grey-img-dot'}`} />
                          )
                        })}
                      </div>

                      <span
                        className="material-symbols-outlined text-primary cursor-pointer"
                        style={{ fontSize: "1.8rem" }}
                        onClick={() => { if (instActive < (instCount?.length - 1)) { setInstActive(prevState => prevState + 1); } }}
                      >chevron_right</span>
                    </div>
                  </div> :
                  <div className="d-flex flex-column align-items-center justify-content-center my-1">
                    <Suspense fallback={<></>}><Empty height={'70px'} /></Suspense>
                    <small>No Image Uploaded</small>

                    {/* --- removed as per figma --- */}
                    {/* {(venuePermissions[venueId]?.infra?.update == null ? permissions?.manageInfra?.update : venuePermissions[venueId]?.infra?.update) ?
                          <div className="text-primary font-weight-bolder cursor-pointer" onClick={() => hiddenFileInput.current.click()}>
                            Upload New Image
                          </div> : null} */}
                  </div>
            }
          </div>
          {/* </Col> */}
          {/* --- removed as per figma --- */}
          {/* <Col xs={5}>
              <div className="my-1">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="font-weight-bolder">Notes</h5>
                  {(venuePermissions[venueId]?.infra?.update == null ? permissions?.manageInfra?.update : venuePermissions[venueId]?.infra?.update) ?
                    <img className="cursor-pointer" src={PencilIcon} onClick={() => setNoteModal(true)} /> : null}
                </div>
                <div style={{ whiteSpace: "pre-line", maxHeight: "250px", overflow: "auto" }}>
                  {infra?.description || ((venuePermissions[venueId]?.infra?.update == null ? permissions?.manageInfra?.update : venuePermissions[venueId]?.infra?.update) ? <em className="text-secondary">Add a note</em> : null)}
                </div>
              </div>
            </Col> */}
          {/* </Row> */}
        </div>

        {/* --- Removed as per figma */}

        {/* <Pagination className={instCount?.length > 0 ? "" : "d-none"} size="sm">
          <PaginationItem key={"prev"}>
            <PaginationLink onClick={() => { if (instActive > 0) { setInstActive(prevState => prevState - 1); } }}>
              <span className="material-symbols-outlined" style={{ fontSize: "1.1rem" }}>chevron_left</span>
            </PaginationLink>
          </PaginationItem>
          {instCount?.map((img, index) => {
            return <PaginationItem active={instActive == index} key={index}>
              <PaginationLink onClick={() => { if (instActive != index); setInstActive(index); }}>
                {index + 1}
              </PaginationLink>
            </PaginationItem>
          })}
          <PaginationItem key={"next"}>
            <PaginationLink onClick={() => { if (instActive < (instCount?.length - 1)) { setInstActive(prevState => prevState + 1); } }}>
              <span className="material-symbols-outlined" style={{ fontSize: "1.1rem" }}>chevron_right</span>
            </PaginationLink>
          </PaginationItem>
        </Pagination> */}
        {/* --- empty space below --- */}
        {/* <div className={instCount?.length > 0 ? "d-none" : ""} style={{ minHeight: "40px" }}>&nbsp;</div> */}

        <div className=" d-flex justify-content-between pt-50">
          <div className="d-flex" style={{ overflow: 'hidden' }}>
            <div className="w-50 mr-50">
              <span className="bold-text">Room:</span>
              <span className="mr-1 room-text-box"  >{infra?.room || ''}</span>
            </div>
            <div className="w-50">
              <span className="bold-text">Location:</span>
              <span>{infra?.location || ''}</span>
            </div>
          </div>
          <img className="cursor-pointer" src={PencilIcon} onClick={() => setRoomLocationModal(true)} />

        </div>
      </div>
    )
  }

  useEffect(() => {
    if (!!venueId) {
      let allowCommands = ((venuePermissions[venueId]?.infra?.create == null ?
        permissions?.manageInfra?.create : venuePermissions[venueId]?.infra?.create) &&
        (features?.commandSupport?.rebootSupport ||
          features?.commandSupport?.factoryResetSupport ||
          (features?.commandSupport?.rttySupport && permissions?.manageFirmware?.create)))
      setEnableCommands(allowCommands)
      setEnableDropdown(
        (venuePermissions[venueId]?.infra?.delete == null ? permissions?.manageInfra?.delete : venuePermissions[venueId]?.infra?.delete) ||
        permissions?.manageTicket?.create ||
        allowCommands
      )
    }
  }, [features, permissions, venuePermissions, venueId])



  const fetchConfig = () => {
    const { run } = createRequest(services.networks.SWITCHCONFIG_GET, [infraId, activeOrgId]);
    run()
      .then((response) => {
        setConfig(response.data)
      })
      .catch((err) => { make_custom_toast("error", "Switch Configuration", (new CatchedWebError(err)).message) });
  }


  useEffect(() => {
    if (infraId) {
      const { run: getImg } = createRequest(services.infra.INSTALL_IMG, [infraId, "get"])

      getImg().then((response) => {
        setInstImg(response.data);
        setInstCount(Object.keys(response.data));
      })
        .finally(() => setImgLoading(false));

      fetchConfig();
    }

    return () => {
      clearInterval(interval);
    }
  }, [infraId]);


  const fetchStats = useCallback((preventLoad = false) => {
    if (!preventLoad)
      setStatsLoading(true);
    const { run } = createRequest(services.networks.SWITCH_STATS, [infraId]);
    if (infra?.realInfra) {
      run()
        .then(response => {
          dispatch(oneInfraActions.setStats(response.data))
        })
        .catch(err => {
          const x = new CatchedWebError(err);
          make_custom_toast('error', 'Switch Statistics', x.message)
        })
        .finally(() => {
          setStatsLoading(false);
        });
    }
    else {
      setStatsLoading(false);
    }
  }, []);

  const getFloorPlanList = () => {
    setFloorplanLoading(true)

    let apiCaller
    if (infra?.installed) {
      apiCaller = createRequest(services.wre.GET_PLACED_FLOORPLAN, [infra?.infraItemId, infra?.venueId])
    }
    else {
      apiCaller = createRequest(services.wre.GET_FLOORPLAN_LIST, [infra?.venueId])
    }


    apiCaller.run()
      .then(res => {
        const tempData = res.data.sort((a, b) => {
          if (a.layoutJson)
            return -1
          else
            return 1
          return 0
        }

        )
        for (let i in tempData) {
          if (tempData[i].layoutJson == null) {
            tempData[i].layoutJson = FAKE_WALL_DATA
            tempData[i].layoutType = LAYOUT_TYPES.IMAGE
          }
        }
        // setFloorplanList(tempData.filter((floorplan) => floorplan.layoutJson != null))

        setFloorplanList(tempData)
        setFloorplanLoading(false)
      })
      .catch(err => {
        setFloorplanLoading(false)
        // make_custom_toast('error', 'Floorplan', (new CatchedWebError(err)).message)
      })
    return apiCaller.controller.abort()
  }


  useEffect(() => {

    getFloorPlanList()

    if (Number(infraUrlId) === infra.infraItemId) {
      dispatch(breadcrumbActions.setData([
        {
          path: `/organization/${activeOrgId}/infra`,
          text: "Infrastructure",
          active: false
        }, {
          text: infra.infraDisplayName,
          active: false
        },
        {
          path: `/organization/${activeOrgId}/infra/${infra.infraItemId}`,
          text: "Overview",
          active: true
        }
      ]))
    }
  }, []);

  const fetchURL = () => {
    if (!connectURL && infraId) {
      const { run } = createRequest(services.infra.CONNECT, [infraId]);
      setConsoleLoading(true);
      run()
        .then((response) => {
          const url = new URL(`https://${response?.data?.server}:${response?.data?.viewport}/rtty/${response?.data?.connectionId}`);
          setConnectURL(url);
        })
        .catch((err) => {
          setError((new CatchedWebError(err)).message);
          setRunCommand(null);
        })
        .finally(() => {
          setConsoleLoading(false);
        })
    }
  }

  const updateSwitch = (des = description) => {
    const submitData = {
      infraTypeId: infraTypeId,
      macAddress: infraMacAddress,
      infraDisplayName: infraDisplayName,
      description: des,
      venueId: infra?.venueId,
      orgId: infra?.orgId
    };

    const { run } = createRequest(services.infra.MODIFY, [infraId], submitData);
    setModalLoading(true);
    run()
      .then(response => {
        dispatch(oneInfraActions.setInfra({ ...infra, description: response?.data?.description, orgId: activeOrgId, connected: infra?.connected }))
      })
      .catch(err => {
        setError((new CatchedWebError(err)).message);
      })
      .finally(() => {
        setModalLoading(false);
        setNoteModal(false);
      })

  }

  const removeImg = () => {
    const { run } = createRequest(services.infra.INSTALL_IMG_DELETE, [infraId])

    run()
      .then(() => {
        setInstImg(null);
      })
      .catch((err) => {
        setError((new CatchedWebError(err)).message)
      })
  }

  async function uploadImageToCloud(img) {
    if (!!img) {
      const options = { maxSizeMB: 5, maxWidthOrHeight: 250, useWebWorker: true }
      const selectedImageCom = await imageCompression(img, options);
      const { run } = createRequest(services.infra.INSTALL_IMG, [infraId, 'put']);
      try {
        let response = await run();
        await axios.put(response.data, selectedImageCom);
      } catch (err) {
        setError("Failed to upload Installation Image. Please try again later");
        window.scroll(0, 0)
        setInstImg(null)
      }
    }
  }

  const uploadImage = (event) => {
    if (["image/png", "image/jpg", "image/jpeg"].includes(event.target.files[0]?.type)) {
      setInstImg(URL.createObjectURL(event.target.files[0]))
      uploadImageToCloud(event.target.files[0]);
      hiddenFileInput.current.value = null;
    }
    else
      make_toast("error", "Invalid Format, png/jpg Accepted Only", true);
  }

  useEffect(() => {
    if (runCommand === 'connect') {
      fetchURL();
    }
  }, [runCommand])

  useAutoclear(unassignSuccess, () => { setUnassignSuccess(false) });

  useEffect(() => {
    if (infraId) {
      const { run } = createRequest(services.fwupgrade.GET_INFRA_SCHEDULE, [infraId]);
      run()
        .then(response => {
          if (response.data?.status === 'Scheduled' && !(response.data?.completedDate)) {
            // setScheduleDate(response.data?.scheduleDate);
            if (response.data?.scheduleDate && !!moment.tz.zone(response.data?.timezone)) {
              const scheduleDateObj = (moment.utc(response?.data?.scheduleDate));
              setScheduleDate(((scheduleDateObj.tz(response?.data?.timezone)).format('YYYY-MM-DDTHH:mm:ss.000')));
              setTimezone(response.data?.timezone);
            }
          }
        })
        .catch(err => {
          //not showing this error on UI as it is not a major one
          // setError((new CatchedWebError(err)).message);
        })
    }
  }, [infraId])

  const SwitchOverviewDropDown = () => {
    return (<UncontrolledDropdown className="text-primary" disabled={!enableDropdown}>
      <DropdownToggle tag="span" className="material-symbols-outlined dropdownToggle">
        more_vert
      </DropdownToggle>
      <DropdownMenu right>
        {(venuePermissions[venueId]?.infra?.delete == null ? permissions?.manageInfra?.delete : venuePermissions[venueId]?.infra?.delete) &&
          <DropdownItem className="w-100" onClick={() => { setDeleteConfirmModal(true); }}>Delete</DropdownItem>
        }
        {permissions?.manageTicket?.create && <DropdownItem className="w-100" onClick={() => { setSupport(true) }}>Support</DropdownItem>}
        {/* {infra.venueId !== 0 && <DropdownItem className="w-100" onClick={() => { setUnassignConfirmModal(true); }}>Unassign</DropdownItem>} */}
        {enableCommands ?
          <>
            <DropdownItem divider />
            {features?.commandSupport?.rebootSupport ?
              <DropdownItem className="w-100" disabled={!infra.connected} onClick={() => { setRunCommand("reboot"); }}>
                <div className='d-flex justify-content-between align-items-center'>
                  Reboot
                  <div className="cursor-pointer tooltip-icon" style={{ fontWeight: "500" }} id="SWITCH_REBOOT">
                    <Reboot width={20} height={20} />
                  </div>
                  <UncontrolledTooltip target="SWITCH_REBOOT">
                    {SWITCH_REBOOT}
                  </UncontrolledTooltip>
                </div>
              </DropdownItem>
              : null}
            {features?.commandSupport?.factoryResetSupport ?
              <DropdownItem className="w-100" disabled={!infra.connected} onClick={() => { setRunCommand("reset"); }}>
                <div className='d-flex justify-content-between align-items-center'>
                  Reset
                  <div className="cursor-pointer tooltip-icon" style={{ fontWeight: "500" }} id="SWITCH_RESET"><Reset width={20} height={20} /></div>
                  <UncontrolledTooltip target="SWITCH_RESET">
                    {SWITCH_RESET}
                  </UncontrolledTooltip>
                </div>
              </DropdownItem>
              : null}

            {/* <DropdownItem disabled={!infra.connected} className="w-100" onClick={() => { infra.connected && setRunCommand("upgrade") }}>
              <div className='d-flex justify-content-between align-items-center'>
                Update
                <div className="material-symbols-outlined cursor-pointer tooltip-icon" style={{ fontWeight: "500" }} id="upgrade">info</div>
                <UncontrolledTooltip target="upgrade">
                  This action will update the firmware of access point. You'll be notified after firmware is updated.
                </UncontrolledTooltip>
              </div>
            </DropdownItem> */}
            {/* Only PA can connect */}
            {features?.commandSupport?.rttySupport && permissions?.manageFirmware?.create &&
              <DropdownItem className="w-100" disabled={!infra.connected} onClick={() => { setRunCommand("connect"); }}>
                <div className='d-flex justify-content-between align-items-center'>
                  Connect
                  <div className="cursor-pointer tooltip-icon" style={{ fontWeight: "500" }} id="CONNECT_RTTY"><Connect width={20} height={20} /></div>
                  <UncontrolledTooltip target="CONNECT_RTTY">
                    {CONNECT_RTTY}
                  </UncontrolledTooltip>
                </div>
              </DropdownItem>
            }
          </> : null}
      </DropdownMenu>
    </UncontrolledDropdown>)
  }


  const ApStatInfo = () => {
    return (
      <div className="bg-white rounded p-1" style={{ width: '39%', position: 'relative' }}>
        {/* {
          (venuePermissions[venueId]?.infra == null ?
            (permissions?.manageInfra?.delete || permissions?.manageInfra?.create)
            : (venuePermissions[venueId]?.infra?.delete || venuePermissions[venueId]?.infra?.create)
            || permissions?.manageTicket?.create) &&
          <ApOptionDropdown />
        } */}

        <div className="d-flex justify-content-between align-items-center mb-1">
          <span className="w-50">
            <Device height={20} width={20} className="fill-client-svg mr-1" />
            <span
              className={((venuePermissions[venueId]?.device?.view == null ? permissions?.manageDevice?.view : venuePermissions[venueId]?.device?.view) && infra?.connectedClients > 0 ? "cursor-pointer text-primary" : "")}
              onClick={() => {
                if ((venuePermissions[venueId]?.device?.view == null ? permissions?.manageDevice?.view : venuePermissions[venueId]?.device?.view) && infra?.connectedClients > 0)
                  navigate(`/organization/${activeOrgId}/infra/${infraId}/clients/`)
              }} >
              {infra?.connectedClients} Connected Clients
            </span>
          </span>

          <span className="w-50">
            <span className="bold-text">
              Uptime :
            </span>
            <span id="switch-uptime-time-id">
              {infraDetails?.upTime > 0 ? UPTIME(infraDetails?.upTime, true) : ''}
            </span>
            {
              infraDetails?.upTime > 0 &&
              <UncontrolledTooltip target={"switch-uptime-time-id"}>
                {UPTIME(infraDetails?.upTime)}
              </UncontrolledTooltip>
            }
          </span>
        </div>

        <div className="d-flex justify-content-between align -items-center mb-1" >


          <div className="w-50 d-flex">
            <span className="bold-text ">
              Release :
            </span>
            {
              infra?.firmwareVersion != "Unknown" ?
                <LightBadge className=" badge-custom" color={versionColors[infra?.firmwareStatus] ?? "secondary"}>
                  {infra?.firmwareVersion?.replace("Unknown", ``) ?? "-"}
                </LightBadge> : "Unknown"
            }
          </div>



        </div>

        <div className="d-flex justify-content-between align-items-center mb-1">

          <span className="w-50">
            <span className="bold-text">
              Public IP :
            </span>
            <span
              className="cursor-pointer "
              onClick={() => handleCopy((infra?.connected && !!infra?.publicIP) ? infra?.publicIP : "Not Available")}
              onMouseEnter={() => {
                showCopyIcon(COPYABLE_ID.PUBLIC_IP)
              }}
              onMouseLeave={() => {
                hideCopyIcon(COPYABLE_ID.PUBLIC_IP)
              }}
            >
              {(infra?.connected && !!infra?.publicIP) ? infra?.publicIP : "Unavailable"}
              <span class="material-symbols-outlined ml-50 copy-icon" id={COPYABLE_ID.PUBLIC_IP} style={{ fontSize: '1em' }}>
                content_copy
              </span>
            </span>
          </span>

        </div>

        <div className="d-flex justify-content-between align-items-center  mb-1">

          <span className="w-50">
            <span className="bold-text">
              Private IP :
            </span>
            <span
              className="cursor-pointer"
              onClick={() => handleCopy(infra?.privateIP)}
              onMouseEnter={() => {
                showCopyIcon(COPYABLE_ID.PRIVATE_IP)
              }}
              onMouseLeave={() => {
                hideCopyIcon(COPYABLE_ID.PRIVATE_IP)
              }}>
              {((infra?.connected && infra?.privateIP) && infra?.privateIP) || "Unavailable"}
              <span class="material-symbols-outlined ml-50 copy-icon" id={COPYABLE_ID.PRIVATE_IP} style={{ fontSize: '1em' }}>
                content_copy
              </span>
            </span>
          </span>

        </div>

        <div className="d-flex justify-content-between align-items-center  mb-1">
          <span>
            <span className="bold-text">
              Switch Profile :
            </span>
            <span className={`cursor-pointer ${infra?.switchProfileId ? 'text-primary' : ''} `} onClick={() => {
              if (infra?.switchProfileId)
                navigate(`/organization/${infra?.orgId}/profiles/${infra?.switchProfileId}`)
            }}>
              {infra?.switchProfile ?? "Unavailable"}
            </span>
          </span>

        </div>

      </div>)
  }
  const ApDeploymentInfo = () => {
    return (<div className="bg-white rounded p-1" style={{ width: '39%' }}>
      <div className="d-flex justify-content-between align-items-center mb-1">
        <span className="w-50">
          <Validate height={20} width={20} className=" mr-1" />
          <span>
            Network Validation
          </span>
        </span>
      </div>

      <div className="d-flex justify-content-between align -items-center mb-1" >
        <span className="d-flex align-items-center w-50" >
          <span class="material-symbols-outlined mr-1">
            compare_arrows
          </span>
          <span className="bold-text">
            Ping :
          </span>
          <span>
            {infra?.speedTestResult?.ping | '-'}
          </span>
        </span>


        <span className="w-50">
          <span className="bold-text ">
            Captured :
          </span>
          <span id={'execution-time-id'}>
            {infra?.speedTestResult?.executionTime ? timeDiff(infra?.speedTestResult?.executionTime) + 'ago' : '-'}
          </span>
          {
            infra?.speedTestResult?.executionTime &&
            <UncontrolledTooltip target={"execution-time-id"}>
              {dateTimeFormatter(infra?.speedTestResult?.executionTime, 'long', 'medium')}
            </UncontrolledTooltip>
          }
        </span>
      </div>

      <div className="d-flex justify-content-between align-items-center mb-1">
        <span className="d-flex align -items-center w-50">
          <span class="material-symbols-outlined mr-1">
            download
          </span>
          <span className="bold-text">
            Download:
          </span>
          <span>{infra?.speedTestResult?.downlink ? infra?.speedTestResult?.downlink.toFixed(2) + 'Mb/s' : '-'}</span>
        </span>
        <span className="w-50">
          <span className="">
            <span className="bold-text">
              Captured by : &nbsp;
            </span>
            {infra?.speedTestResult?.email ? infra?.speedTestResult?.email :
              infra?.speedTestResult?.userName ? infra?.speedTestResult?.userName :
                '-'}
          </span>
        </span>
      </div>

      <div className="d-flex justify-content-between align-items-center  mb-1">
        <span className="d-flex align -items-center w-50">
          <span class="material-symbols-outlined mr-1">
            publish
          </span>
          <span className="bold-text">
            Upload :
          </span>
          <span>{infra?.speedTestResult?.uplink ? infra?.speedTestResult?.uplink.toFixed(2) + 'Mb/s' : '-'}</span>
        </span>
      </div>



    </div>)
  }


  return (
    <div className="SwitchOverview" data-testid="SwitchOverview">
      <Modal centered isOpen={!!runCommand && runCommand !== 'connect'} toggle={() => !modalLoading && setRunCommand(null)}>
        <ModalHeader toggle={() => !modalLoading && setRunCommand(null)}>
        </ModalHeader>
        <ModalBody>
          <Actions runCommand={runCommand} setRunCommand={setRunCommand} modalLoading={setModalLoading} hideModal={() => setRunCommand(null)} />
        </ModalBody>
      </Modal>

      <div className="d-flex align-items-center justify-content-between w-100">
        <SwitchHeader heading={infraDisplayName} activeOrgId={activeOrgId} infraId={infraId} />
        <SwitchOverviewDropDown />
      </div>
      <Alert color="danger" isOpen={!!error} toggle={() => setError(null)}><div className="alert-body">{error}</div></Alert>
      <AlertBox color="success" isOpen={unassignSuccess} toggle={() => { setUnassignSuccess(false); }}>Switch Unassigned</AlertBox>
      <AlertBox color="danger" isOpen={!!unassignError} toggle={() => { setUnassignError(false); }}>{unassignError}</AlertBox>
      <AlertBox color="danger" isOpen={!!deleteError} toggle={() => { setDeleteError(false); }}>{deleteError}</AlertBox>

      {
        options != null &&

        <FirmwareReschedule
          options={options}
          setModal={setOptions}
          updateSnoozeDate={(snzDate, itemId, rescheduleInfra) => {
            REDUX_WORKER.getInfra(infra?.infraItemId, false)
            // if (rescheduleInfra) {
            //   if (options.setSubList) {
            //     options.setSubList((prevState) => {
            //       let index = prevState.findIndex((it) => it.infraItemId == itemId);
            //       let newList = [...prevState];
            //       if (index >= 0) newList[index].scheduleSnoozed = snzDate;
            //       return [...newList];
            //     });
            //   }
            //   else {
            //     setList((prevState) => {
            //       let index = prevState.findIndex((it) => it.infraItemId == itemId);
            //       let newList = [...prevState];
            //       if (index >= 0) newList[index].scheduleSnoozed = snzDate;
            //       return [...newList];
            //     });
            //   }
            //   return
            // }
            // if (options.setSubList) {
            //   options.setSubList((prevState) => {
            //     let index = prevState.findIndex((it) => it.venueId == itemId);
            //     let newList = [...prevState];
            //     if (index >= 0) newList[index].scheduleSnoozed = snzDate;
            //     return [...newList];
            //   });
            // } else {
            //   setList((prevState) => {
            //     let index = prevState.findIndex((it) => it.venueId == itemId);
            //     let newList = [...prevState];
            //     if (index >= 0) newList[index].scheduleSnoozed = snzDate;
            //     return [...newList];
            //   });
            // }
          }}
        />
      }


      {/* --- New View --- */}

      <span className="d-flex">
        {
          infra?.alarmCount > 0 &&
          <span className="px-1 py-50 yellow-info-badge shadow-sm rounded mr-1 mb-1">
            {
              statusIcons(infra?.zone)
            }

            <span className="text-primary cursor-pointer mr-50" onClick={() => {
              navigate(`/organization/${activeOrgId}/notification`, {
                state: {
                  filter: {
                    infras: [{ infraItemId: infra?.infraItemId, infraName: infra?.infraDisplayName }],
                    alertType: ['alarm']
                  }
                }
              })
            }}>
              {infra?.alarmCount}
            </span>
            Active Alarms
          </span>
        }
        {
          infra?.scheduleSnoozed &&
          <span className="d-flex align-items-center px-1 py-50 yellow-info-badge shadow-sm rounded mr-1 mb-1">
            <UpdateIcon width={24} height={24} className="mr-50" />
            Firmware upgrade schedule in &nbsp;
            <span id="schedule-tag-info" className="text-primary cursor-pointer"
              onClick={() =>

                setOptions({
                  scheduleId: infra?.scheduleId,
                  venueId: infra?.venueId,
                  scheduleDate: infra?.scheduleDate,
                  scheduleSnoozed: infra?.scheduleSnoozed,
                  timezone: infra?.venueTimezone,
                  infraItemId: infra?.infraItemId,
                  rescheduleInfra: true

                })

              }>{dateLeftFinder(infra?.scheduleSnoozed)}</span>
            <UncontrolledTooltip target="schedule-tag-info">
              {dateTimeFormatter(infra?.scheduleSnoozed, 'long', 'short')}
            </UncontrolledTooltip>
          </span>
        }
        {
          infra?.awaitingTickets > 0 &&
          <span className="d-flex align-items-center px-1 py-50 yellow-info-badge shadow-sm rounded mb-1">
            <TicketIcon width={22} height={24} />
            <span className="ml-50 text-primary cursor-pointer" onClick={() => navigate(`/organization/${activeOrgId}/support/ticket/opentickets`, {
              state: {
                filter: { "infraId": infra?.infraItemId, "statuses": [7] }
              }
            })}>1 Ticket</span> &nbsp; awaiting customer feedback
          </span>
        }
      </span>
      <div className="d-flex justify-content-between mb-1">

        {/* --- Left card --- */}
        <div className="bg-white rounded p-1" style={{ width: '34%' }}>


          <div className="d-flex justify-content-between mb-1">
            <span>

              {infra?.status != "demo" && statusIcons(infra?.zone)}
              <SwitchSVG className="mr-50" />
              <strong>{infra?.infraDisplayName || '-'}</strong>
            </span>
            <div className="mr-1">
              {
                !!rebooting ?
                  <TimeProgress start={rebooting?.start} end={rebooting?.end} infraId={infraId} />
                  : infra?.status === "demo" ?
                    <>
                      <LightBadge color="secondary">
                        <span className="text-dark">

                          {THIRD_PARTY_TITLE}
                        </span>
                      </LightBadge>
                    </> :
                    infra?.status === "pending" ?
                      <>
                        <LightBadge color="warning">
                          <span className="text-dark">

                            Pending
                          </span>
                        </LightBadge>
                      </> :
                      infra?.connected ?
                        <>
                          <LightBadge
                            color="success"
                            size="sm"
                            id='infra-connection-status-success-badge'>
                            <span className="text-dark">
                              Online
                            </span>
                          </LightBadge>
                          {infraDetails?.lastStatusAt != null ?
                            <UncontrolledTooltip target='#infra-connection-status-success-badge' placement="bottom">
                              Connected&nbsp;{timeDiff(infraDetails?.lastStatusAt)}&nbsp;ago
                            </UncontrolledTooltip> : null}
                        </>
                        :
                        <>
                          <LightBadge color="danger">
                            <span className="text-dark">
                              Offline
                            </span>
                          </LightBadge>
                        </>
              }
            </div>
          </div>

          <div className="d-flex justify-content-between w-100 " >
            <div className="d-flex flex-column justify-content-between" style={{ width: '70%' }}>
              <div className="d-flex align-items-center w-100">
                <span className="d-flex align-items-center mr-1" style={{ fontSize: '12px', maxWidth: "50%" }}>
                  <OrgGlobe width={'18px'} height={'18px'} className="mr-50" />
                  <span className="cursor-pointer text-primary ellipse-text-box text-nowrap" onClick={() => {
                    navigate(`/organization/${infra?.orgId}`)
                  }}>
                    {infra?.orgName}
                  </span>
                </span>
                <span className="d-flex align-items-center" style={{ fontSize: '12px', width: '50%' }}>
                  <Floor width={'18px'} height={'18px'} className="mr-50" />
                  <span className="cursor-pointer text-primary  ellipse-text-box text-nowrap" onClick={() => {
                    navigate(`/organization/${infra?.orgId}/venues/${infra?.venueId}`)
                  }} >
                    {infra?.venueName}
                  </span>
                </span>
              </div>
              <div>
                <span className="bold-text" >MAC : </span>
                <span
                  className="cursor-pointer"
                  onClick={() => handleCopy(infra?.macAddress)}
                  onMouseEnter={() => {
                    showCopyIcon(COPYABLE_ID.MAC_ADDRESS)
                  }}
                  onMouseLeave={() => {
                    hideCopyIcon(COPYABLE_ID.MAC_ADDRESS)
                  }}
                >
                  <span>{infra?.macAddress?.substring(0, 8)}</span>
                  <span className="font-weight-bolder">{infra?.macAddress?.substring(8, 12)}</span>
                  <span class="material-symbols-outlined ml-50 copy-icon" id={COPYABLE_ID.MAC_ADDRESS} style={{ fontSize: '1em' }}>
                    content_copy
                  </span>
                </span>

              </div>

            </div>

            <div className="center-div" style={{ height: '70px', width: '90px' }}>
              <img className="ap-img cursor-pointer" src={infraTypes.find(it => it.infraTypeId == infra?.infraTypeId)?.images['1']} onClick={() => setInfraImageModal(true)} />
            </div>
          </div>

          <div className="d-flex justify-content-between mb-1" >
            <div className=" ellipse-text-box text-nowrap">
              <span className="bold-text" >Serial number : </span>
              <span>{infra?.serialNumber ?? ''}</span>
            </div>
            <div className="d-flex justify-content-end ellipse-text-box text-nowrap">
              <span className="bold-text" >Model : </span>
              <span>{infraTypes?.find(it => it.infraTypeId == infra?.infraTypeId)?.infraType?.replace(/_/g, " ")}</span>
            </div>
          </div>

          <div className="d-flex justify-content-between mb-1" >
            <div className="ellipse-text-box text-nowrap">
              <span className="bold-text" >Asset ID : </span>
              <span >{infra?.assetTag || ''}</span>
            </div>
            <span className="">
              {
                view == DEPLOYMENT_VIEW &&
                <div className="d-flex" style={{ position: 'relative' }}>
                  <div className="mr-1" style={{ position: 'relative' }}>
                    {
                      infra?.initialized ?
                        <Install width={24} height={24} /> :
                        <InstallIconBlack width={24} height={24} />
                    }
                    {infra?.initialized
                      ? <div className="success-top-icon">
                        <Success width={16} height={16} />
                      </div>
                      : <></>
                    }
                  </div>
                  <div className="mr-1" style={{ position: 'relative' }}>
                    {
                      infra?.photos ?
                        <CameraImage width={24} height={24} /> :
                        <CameraImageBlack width={24} height={24} />
                    }
                    {infra?.photos
                      ? <div className="success-top-icon">
                        <Success width={16} height={16} />
                      </div>
                      : <></>
                    }
                  </div>
                  <div className="mr-1" style={{ position: 'relative' }}>
                    {
                      infra?.installed ?
                        <LayoutIcon width={24} height={24} /> :
                        <LayoutIconBlack width={24} height={24} />
                    }
                    {infra?.installed
                      ? <div className="success-top-icon">
                        <Success width={16} height={16} />
                      </div>
                      : <></>
                    }
                  </div>
                  <div style={{ position: 'relative' }}>
                    {
                      infra?.validated ?
                        <ValidateIcon width={24} height={24} /> :
                        <ValidateIconBlack width={24} height={24} />
                    }
                    {infra?.validated
                      ? <div className="success-top-icon">
                        <Success width={16} height={16} />
                      </div>
                      : <></>
                    }
                  </div>
                </div>
              }
            </span>
          </div>

        </div>

        {/*--- Middle Card --- */}
        {
          view != DEPLOYMENT_VIEW ?
            <ApStatInfo /> :
            <ApDeploymentInfo />
        }


        {/* --- Right Card --- */}
        <div className="bg-white rounded p-1" style={{ width: '25%' }}>
          <InstallationImages />
        </div>

      </div>




      {
        view != DEPLOYMENT_VIEW &&
        <div className="d-flex justify-content-between">
          <div className=" bg-white p-1 rounded   pb-2  " style={{ width: '74%' }}>
            {/* Status Bar */}
            {/* <div className='d-flex justify-content-between flex-wrap'>
          <div className="d-flex">
            <div className="d-flex">
              <div>
                  {
                    !!rebooting ?
                      <TimeProgress start={rebooting?.start} end={rebooting?.end} infraId={infraId} /> :
                      infra?.status === "DEMO" ?
                        <>
                          <div className="dark-badge text-center">
                            <span className="mx-1 status-text">{THIRD_PARTY_TITLE}</span></div>
                        </> :
                        infra?.status === "PENDING" ?
                          <>
                            <div className="warning-badge text-center">
                              <span className="mx-1 status-text">Pending</span></div>
                          </> :
                          infra?.connected ?
                            <>
                              <div className="success-badge text-center cursor-pointer"
                                id='infra-connection-status-success-badge'
                              >
                                <span className="mx-1 status-text">Online</span></div>
                              {infraDetails?.lastStatusAt != null ?
                                <UncontrolledTooltip target='#infra-connection-status-success-badge' placement="bottom">
                                  Connected&nbsp;{timeDiff(infraDetails?.lastStatusAt)}&nbsp;ago
                                </UncontrolledTooltip> : null}
                            </>
                            :
                            <>
                              <div className="fail-badge text-center">
                                <span className="mx-1 status-text">Offline</span></div>
                            </>
                  }
                </div>
              {infra?.connected && 
            <div className="client-badge d-flex align-items-center justify-content-arround">
              <Clients className="mr-1" width={32} />
              12 Unique MAC's
            </div>}
              {(!infra.connected && infraDetails?.lastStatusAt) && <div className="ml-50 pt-50">
                  Last Connected {timeDiff(infraDetails?.lastStatusAt)} ago
                </div>}
            </div>
          </div>
          <div className="d-flex align-items-start flex-wrap">
            {permissions?.manageFirmware?.view &&
              <UpdateLink scheduleDate={scheduleDate} venueId={infra.venueId} timezone={timezone} />
            }
            <div>
              {permissions?.manageTicket?.view && issues.length > 0 &&
                <div className="rounded quicklink-button-ticket cursor-pointer" onClick={() => {
                  navigate(`/organization/${activeOrgId}/support/ticket/`, {
                    state: {
                      filter: { "infraId": infraId }
                    }
                  })
                }}>
                  <div className="d-flex align-items-center support-button" id="ticket-tag">
                    <div className="main-text d-flex align-items-center">
                      <Ticket width={28} />
                      &nbsp;{issues.length} Open Issues
                    </div>
                    <UncontrolledTooltip target="ticket-tag">
                      Last updated {timeDiff(issues[0].updatedAt)} ago
                    </UncontrolledTooltip>
                  </div>
                </div>}
            </div>
            {/* ALARM BUTTON 
            {permissions?.manageNotificationstats?.view && alarms.count > 0 && <div className="rounded quicklink-button-ticket cursor-pointer" onClick={() => {
              navigate(`/organization/${activeOrgId}/notification/`, {
                state: {
                  filter: { infras: [{ infraItemId: infraId, infraName: infra?.infraDisplayName }], alertType: ['alarm'] }
                }
              })
            }}>
              <div className="d-flex align-items-center support-button" id="alarm-tag">
                <div className="main-text d-flex align-items-center">
                  <div className="material-symbols-outlined text-danger">warning</div>
                  {/* correcting to active alarm/ active alarms instead of the current 'Alarms(s)' text as part of fixing TASK-3822 
                  &nbsp;{`${alarms?.count} Active ${alarms?.count == 1 ? 'Alarm' : 'Alarms'}`}
                </div>
                <UncontrolledTooltip target="alarm-tag">
                  Last updated {timeDiff(alarms.updatedAt)} ago
                </UncontrolledTooltip>
              </div>
            </div>}
          </div>
        </div> */}

            {runCommand === 'connect' ?
              <div>
                {consoleLoading ?
                  <div className="d-flex justify-content-center align-items-center mb-1 console-frame">
                    <VeryFancyLoader />
                  </div>
                  : <>
                    <p>username: <i><b>admin</b></i>, password: <i><b>{config?.randomPassword ?? '-'}</b></i></p>
                    <iframe className='w-100 rounded console-frame mt-1' src={connectURL} title="Console"></iframe>
                  </>}
                <div className='d-flex justify-content-center'><Button.Ripple color='outline-primary' onClick={() => { setRunCommand(null); setConnectURL(null); }}>Go Back</Button.Ripple></div>
              </div>
              : <>
                {/* <Row className="my-1">
          <Col lg="3">
            <div className="d-inline-block text-center">
              <img className="previewer-image" src={Previewer} alt="Show Preview" />
              <h5 style={{paddingTop: "3px"}}>&nbsp;Preview</h5>
            </div>
          </Col>
        </Row> */}

                {/* SVG */}
                <Row className="mt-1">
                  {
                    (statsLoading) ?
                      <Col lg="12" className="m-1 " style={
                        {
                          height: "10rem",
                          // backgroundColor: "rgb(249, 249, 249)",
                          // border: "1px solid #EAEAEA"
                        }
                      }>
                        <div className="d-flex align-items-center justify-content-center h-100">
                          <Spinner color="primary" />
                        </div>
                      </Col>
                      : <Col lg="12">
                        {(config?.configuration)
                          ? <DummySwitch
                            mode='default'
                            online={infra?.connected}
                            lanPorts={infraType.ports.LAN}
                            entireConfig={config}
                            config={config?.configuration?.portsConfiguration}
                            sfpPorts={infraType?.ports?.Uplink?.filter(it => it.InterfaceType != "RJ-45")}
                            infraId={infraId}
                            infraTypeId={infra.infraTypeId}
                            onSubmit={() => { fetchConfig(); fetchStats(); }}
                          />
                          : <DummySwitch dummy={true} lanPorts={infraType?.ports.LAN} sfpPorts={infraType?.ports?.Uplink?.filter(it => it.InterfaceType != "RJ-45")} />}
                      </Col>
                  }
                </Row>

                {/* <Row className="mt-2 px-50">
                <Col xs={6} md={4} lg={2} className="px-50">
                  <div className="mb-lg-0 mb-1 border rounded bg-grey p-1">
                    <div className="info-heading">Switch Model<Suspense fallback={<></>}><Copy className="cursor-pointer" height={18} width={18} onClick={() => handleCopy((infraTypes.find(itype => itype.infraTypeId === infraTypeId)?.infraType?.replaceAll("_", " ") ?? "Unknown"))} /></Suspense></div>
                    <div className="info">{infraTypes ? (infraTypes.find(itype => itype.infraTypeId === infraTypeId)?.infraType?.replaceAll("_", " ") ?? "Unknown") : "-"}</div>
                  </div>
                </Col>
                <Col xs={6} md={4} lg={2} className="px-50">
                  <div className="mb-lg-0 mb-1 border rounded bg-grey p-1">
                    <div className="info-heading">Public IP<Suspense fallback={<></>}><Copy className="cursor-pointer" height={18} width={18} onClick={() => handleCopy(stats?.publicIp ?? "Unavailable")} /></Suspense></div>
                    <div className="info">{stats?.publicIp ?? "Unavailable"}</div>
                  </div>
                </Col>
                <Col xs={6} md={4} lg={2} className="px-50">
                  <div className="mb-lg-0 mb-1 border rounded bg-grey p-1">
                    <div className="info-heading">Private IP<Suspense fallback={<></>}><Copy className="cursor-pointer" height={18} width={18} onClick={() => handleCopy(stats?.localIp ?? "Unavailable")} /></Suspense></div>
                    <div className={(infraDetails?.ipv4 != null && infraDetails?.ipv4?.length > 0) ? "" : "info"}>{(infraDetails?.ipv4 != null && infraDetails?.ipv4?.length > 0) ? ipFormater(infraDetails?.ipv4.split('/')[0]) : "Unavailable"}</div>
                  </div>
                </Col>
                <Col xs={6} md={4} lg={2} className="px-50">
                  <div className="mb-lg-0 mb-1 border rounded bg-grey p-1">
                    <div className="info-heading">MAC<Suspense fallback={<></>}><Copy className="cursor-pointer" height={18} width={18} onClick={() => handleCopy(infraMacAddress)} /></Suspense></div>
                    <div className="">
                      <span>{infraMacAddress?.substring(0, 8)}</span>
                      <span className="font-weight-bolder">{infraMacAddress?.substring(8, 12)}</span>
                    </div>
                  </div>
                </Col>
                <Col xs={6} md={4} lg={2} className="px-50">
                  <div className="mb-lg-0 mb-1 border rounded bg-grey p-1">
                    <div className="info-heading">Switch Profile</div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="info">{config?.profileName ?? "-"}</div>
                      {config?.switchProfileId &&
                        <img className="cursor-pointer" src={PencilIcon} style={{
                          width: '1.4rem',
                          paddingRight: '0.3rem'
                        }} onClick={() => {
                          navigate(`/organization/${activeOrgId}/profiles/${config?.switchProfileId}`)
                        }} />
                      }
                    </div>
                  </div>
                </Col>
              </Row> */}
                {/* <Row className="mt-2">
                <Col md={12} lg={5}>
                  <div className="border rounded bg-grey p-1">
                    <h6 className="font-weight-bolder">Device Images</h6>
                    <div>
                      <ImagePage imgs={infraTypes.find(it => it.infraTypeId == infraTypeId)?.images} />
                    </div>
                  </div>
                </Col>
                <Col md={12} lg={7}>
                  <InstallationImages />
                </Col>
              </Row> */}



              </>}
          </div>

          <div className="rounded bg-white p-1" style={{ width: '25%' }}>
            <span className="d-flex justify-content-between mb-1">
              <span>
                <InfraNotesIcon className="mr-1" />
                <span>
                  Notes
                </span>
              </span>
              {(venuePermissions[venueId]?.infra?.update == null ? permissions?.manageInfra?.update : venuePermissions[venueId]?.infra?.update) ?
                <img className="cursor-pointer" src={PencilIcon} onClick={() => setNoteModal(true)} /> : <span />}
            </span>
            <div style={{ whiteSpace: "pre-line", maxHeight: "150px", overflow: "auto" }}>
              {infra?.description || ((venuePermissions[venueId]?.infra?.update == null ? permissions?.manageInfra?.update : venuePermissions[venueId]?.infra?.update) ? <div className="center-div" style={{ minHeight: '100px' }}><em className="text-secondary ">No Notes</em></div> : null)}
            </div>
          </div>
        </div>
      }
      {
        view != DEPLOYMENT_VIEW &&
        <InfraInsightCharts />
      }
      {
        view != DEPLOYMENT_VIEW &&
        <AlertInfraTable infraItemId={infra?.infraItemId} />
      }

      {
        view == DEPLOYMENT_VIEW &&
        <div>
          {/* --- deployment view --- */}
          {
            floorplanLoading ?
              <div className="center-div bg-white p-3 rounded mt-2 " style={{ height: '200px' }}>
                <Spinner color="primary" />
              </div>
              : floorplanList.length > 0 ?
                <FloorplanSlide floorplanList={floorplanList} venueId={infra?.venueId} infraData={infra} />
                :
                <div className="center-div bg-white p-3 rounded mt-2 " style={{ height: '200px' }}>
                  No Floorplan Available
                </div>
          }
        </div>
      }


      <SupportModal isOpen={support} setIsOpen={setSupport}
        onSuccess={() => dispatch(oneInfraActions.setIssues(issues + 1))}
        ticketDetails={
          {
            category: 3,
            infraName: infra?.infraDisplayName,
            venueName: infra?.venueName,
            venueId: infra?.venueId,
            infraId: infra?.infraItemId,
            macAddress: infra?.macAddress
          }
        } />
      <Modal centered isOpen={noteModal} toggle={() => { setNoteModal(false); setNotesError(null) }}>
        <ModalHeader className="bg-white" toggle={() => { setNoteModal(false); setNotesError(null) }} />
        <ModalBody>
          <h3>Add Notes to Switch</h3>
          <Alert color="danger" isOpen={!!notesError} toggle={() => setNotesError(null)}><div className="alert-body">{notesError}</div></Alert>
          <div>
            <small>Notes</small>
            <Input type="textarea" className="w-100" defaultValue={description} rows={7} innerRef={noteRef} onChange={(e) => {
              if (e.target.value.length > 512)
                setNotesError("Notes can be atmost 512 characters long.");
              else
                setNotesError(null);
            }} />
          </div>
          <div className="my-2 text-right">
            <Button.Ripple className="mr-1" disabled={modalLoading} color="primary" outline onClick={() => { setNoteModal(false); setNotesError(null) }}>Discard</Button.Ripple>
            <Button.Ripple color="primary" disabled={modalLoading || !!notesError} onClick={() => updateSwitch(noteRef.current.value)}>{modalLoading ? <Spinner size="sm" /> : "Submit"}</Button.Ripple>
          </div>
        </ModalBody>
      </Modal>
      {/* Delete Modal */}
      <Modal centered isOpen={deleteConfirmModal} toggle={() => { setDeleteConfirmModal(false); }}>
        <ModalHeader toggle={() => { setDeleteConfirmModal(false); }}></ModalHeader>
        <ModalBody className="m-2">
          <h3 className="text-center mb-2">Switch Deletion</h3>
          <p className="text-center">
            <span style={{ fontSize: "6em" }} className="material-symbols-outlined text-warning">Report</span>
          </p>
          <p className="text-center">Are you sure you want to delete this switch?</p>
          {deleting && <p className="text-center text-danger">Deleting...</p>}
          <div className="d-flex justify-content-center mt-3">
            <Button color="gradient-primary" className="mr-1" onClick={() => { setDeleteConfirmModal(false); }}>Cancel</Button>
            <Button color="outline-danger" onClick={() => {
              setDeleting(true);
              setDeleteError(false);
              const { run } = createRequest(services.infra.DELETE, [infraId]);
              run()
                .then((response) => {
                  dispatch(infraListActions.reset());
                  navigate(`/organization/${activeOrgId}/infra?deleted=true`);
                })
                .catch((err) => {
                  let x = new CatchedWebError(err);
                  setDeleteError(x.message);
                })
                .finally(() => {
                  setDeleting(false);
                  setDeleteConfirmModal(false);
                })
            }}>Delete</Button>
          </div>
        </ModalBody>
      </Modal>

      {/* Unassign Modal */}
      <Modal centered isOpen={unassignConfirmModal} toggle={() => { setUnassignConfirmModal(false); }}>
        <ModalHeader toggle={() => { setUnassignConfirmModal(false); }}></ModalHeader>
        <ModalBody className="m-2">
          <h3 className="text-center mb-2">Unassign Switch</h3>
          <p className="text-center">
            <span style={{ fontSize: "6em" }} className="material-symbols-outlined text-warning">Report</span>
          </p>
          <p className="text-center">Are you sure you want to unassign this switch?</p>
          {unassigning && <p className="text-center text-danger">Unassigning...</p>}
          <div className="d-flex justify-content-center mt-3">
            <Button color="gradient-primary" className="mr-1" onClick={() => { setUnassignConfirmModal(false); }}>Cancel</Button>
            <Button color="outline-warning" onClick={() => {
              setUnassigning(true);
              setUnassignError(false);
              const { run } = createRequest(services.infra.MODIFY, [infraId], {
                venueId: 0,
                infraTypeId: infra.infraTypeId,
                macAddress: infra.macAddress,
                infraDisplayName: infraDisplayName,
                orgId: activeOrgId
              })
              run()
                .then((response) => { setUnassignSuccess(true); setUnassignConfirmModal(false); REDUX_WORKER.getInfra(infraId); })
                .catch((err) => { let x = new CatchedWebError(err); setUnassignError(x.message); setUnassignConfirmModal(false); })
                .finally(() => { setUnassigning(false); })
            }}>Unassign</Button>
          </div>
        </ModalBody>
      </Modal>

      {
        !!infraImageModal &&
        <InfraImageModal
          visible={infraImageModal}
          setVisible={setInfraImageModal}
          imgs={infraTypes.find(it => it.infraTypeId == infraTypeId)?.images} />
      }
      {
        !!installedImageModal &&
        <InstalledImageModal
          visible={installedImageModal}
          setVisible={setInstalledImageModal}
          instImg={instImg}
          setInstImg={setInstImg}
          instCount={instCount}
          setInstCount={setInstCount}
          infraData={infra}
          instActive={instActive}
          setInstActive={setInstActive}
        />
      }

      {
        !!roomLocationModal &&
        <RoomLocationModal
          visible={roomLocationModal}
          setVisible={setRoomLocationModal}
          infraData={infra}
        />
      }


    </div >
  );
};

SwitchOverview.propTypes = {};

SwitchOverview.defaultProps = {};

export default SwitchOverview;
