import Service from "./Service";
import { T } from "../helpers";
import { onedaytimestamps } from "./infra.service";
import { VIEW_API, VIEW_MAPPING } from "../utility/constants";

/** Services related to telemetry */
const telemetry = {
  GET_INFRA_HEALTH: new Service((macAddress, startDate, endDate) => T(`/health/device/${macAddress}/${startDate}/${endDate}`), "GET"),
  Get_INFRA_TRAFFIC: new Service((macAddress, startDate, endDate) => T(`/traffic/device/${macAddress}/${startDate}/${endDate}`), "GET"),
  Get_INFRA_USERS: new Service((macAddress, startDate, endDate) => T(`/status/device/${macAddress}/${startDate}/${endDate}/count`), "GET"),
  GET_NETWORK_HEALTH: new Service((orgId, venueId, startDate, endDate) => T(`/health/organization/${orgId}/network/${startDate}/${endDate}?venueList=${venueId}`), "GET"),
  GET_NETWORK_TRAFFIC: new Service((orgId, venueId, startDate, endDate) => T(`/traffic/organization/${orgId}/network/${startDate}/${endDate}?venueList=${venueId}`), "GET"),
  GET_VENUE_HEALTH: new Service((venueId, startDate, endDate, children) => T(`/health/venue/${venueId}/${startDate}/${endDate}?children=${children}`), "GET"),
  Get_VENUE_TRAFFIC: new Service((venueId, startDate, endDate, children) => T(`/traffic/venue/${venueId}/${startDate}/${endDate}?children=${children}`), "GET"),
  Get_VENUE_USERS: new Service((macAddress, startDate, endDate, children) => T(`/status/userdevice/venue/${macAddress}/${startDate}/${endDate}/count?children=${children}`), "GET"),
  GET_VENUE_USERS_COUNT: new Service((venueId, child = false) => T(`/userdevice/venue/${venueId}/ue-count?children=${child}`), "GET"),
  GET_VENUE_TASKS: new Service((venueId, infraCategory) => T(`/venues/${venueId}/tasks` + (infraCategory ? `?infraCategory=${infraCategory}` : '')), "GET"),
  GET_VENUE_INFO_COUNT: new Service((venueId) => T(`/venues/${venueId}/count`), "GET"),
  GET_VENUE_EVENT_COUNT: new Service((venueId) => T(`/events/venue/${venueId}/count`), "GET"),
  Get_VENUE_INFRA_COUNT: new Service((venueId, startDate, endDate, children) => T(`/status/device/venue/${venueId}/${startDate}/${endDate}/count?children=${children}`), "GET"),
  GET_ORG_HEALTH: new Service((orgId, startDate, endDate, children) => T(`/health/organization/${orgId}/${startDate}/${endDate}?children=${children}`), "GET"),
  Get_ORG_TRAFFIC: new Service((orgId, startDate, endDate, children) => T(`/traffic/organization/${orgId}/${startDate}/${endDate}?children=${children}`), "GET"),
  Get_ORG_USERS: new Service((macAddress, startDate, endDate, children) => T(`/status/userdevice/organization/${macAddress}/${startDate}/${endDate}/count?children=${children}`), "GET"),
  Get_ORG_INFRA_COUNT: new Service((orgId, startDate, endDate, children) => T(`/status/device/organization/${orgId}/${startDate}/${endDate}/count?children=${children}`), "GET"),
  Get_DEVICE_TRAFFIC: new Service((macAddress, startDate, endDate) => T(`/traffic/userdevice/${macAddress}/${startDate}/${endDate}`), "GET"),
  GET_STATISTICS: new Service((macAddress) => {
    return T(`/infrastructure/${macAddress}/statistics`);
  }, "GET"),
  GET_INFRA_DEPLOYMENT_INFO: new Service((macAddress) => {
    return T(`/infrastructure/${macAddress}`);
  }, "GET"),
  GET_ORG_DASH: new Service((orgId, offset, limit, search = "", sort = null, view, cols = [], filter = "All", filterData = {}) => T(`/organization/${orgId}/dashboard?offset=${offset}&limit=${limit}&search=${search}&viewType=${VIEW_API[view]}&columns=${cols}&filter=${filter}`
    + (!!sort?.orderBy ? `&orderBy=${sort.orderBy}` : ``) + (!!sort?.order ? `&order=${sort.order}` : ``)
    + (filterData?.operations?.length > 0 ? `&operations=${filterData.operations}` : "")
    + (filterData?.location?.length > 0 ? `&location=${filterData.location}` : "")
    + (filterData?.state?.length > 0 ? `&state=${filterData.state}` : "")
  )),
  // GET_INFRA_DASH: new Service((orgId, offset, limit, search = "", sort = null, filter = "All", cols = [], filterData = {}, orderInfras) => T(`/organization/${orgId}/dashboard/infras?offset=${offset}&limit=${limit}&search=${search}&filter=${filter}&timezoneOffset=${(new Date()).getTimezoneOffset()}&columns=${cols}`
  //   + (!!sort?.orderBy ? `&orderkey=${sort.orderBy}` : ``) + (!!sort?.order ? `&order=${sort.order}` : ``)
  //   + (filterData?.category ? `&infraCategory=${filterData.category}` : "")
  //   + (filterData?.infraType?.length ? `&infraType=${filterData.infraType}` : "")
  //   + (filterData?.status?.length > 0 ? `&status=${filterData.status}` : "")
  //   + (filterData?.venueId?.length ? `&venueId=${filterData.venueId}` : "")
  //   // + (filterData?.status ? `&status=${filterData.status.map(type => type.toLowerCase())}` : "")
  //   + (filterData?.orgId?.length > 0 ? `&orgId=${filterData.orgId}` : "")
  //   + (filterData?.firmwareVersion?.length > 0 ? `&firmwareVersion=${filterData.firmwareVersion}` : "")
  //   + (!!orderInfras ? `&orderInfras=${orderInfras}` : ``)
  // ), "GET"),
  UE_DEBUG: new Service((ueMac, flag) => T(`/userdevice/ue-debug/${ueMac}?flag=${flag}`), "POST"),
  // INFRA_FILTER_COUNT: new Service((id, search = "", cols = [], venueId = null) => T(`/organization/${id}/dashboard/infras/count?search=${search}&columns=${cols}` + `${(!!venueId ? `&venueId=${venueId}` : ``)}`), "GET"),
  VENUE_INFRA_COUNT: new Service((id, search = "", cols = [], view, filterData = {}) => T(`/venues/${id}/children/count?viewType=${VIEW_API[view]}&search=${search}&columns=${cols}`
    + (filterData?.orgId?.length > 0 ? `&orgIds=${filterData.orgId}` : "")
    + (filterData?.status?.length > 0 ? `&status=${filterData.status}` : "")
    + (filterData?.firmwareVersion?.length > 0 ? `&firmwareVersion=${filterData.firmwareVersion}` : "")
  ), "GET"),

  // GET_INFRA_STATUS: new Service((orgId, venueId, timezoneOffset, infraIdList) => T(`/organization/${orgId}/dashboard/infras?offset=0&limit=1000&filter=All&timezoneOffset=${timezoneOffset}&columns=MAC%20Address&search=venues:${venueId}infras:${infraIdList}`), "GET"),
  GET_INFRA_STATUS: new Service((orgId, venueId, timezoneOffset, infraIdList) => T(`/organization/${orgId}/infrastructure?offset=0&limit=99999&filter=All&timezoneOffset=${timezoneOffset}&columns=MAC%20Address,Status&search=venues:${venueId}infras:${infraIdList}`), "GET"),
  GET_INFRA_DETAILS: new Service((infraItemId) => T(`/infra/${infraItemId}/details`), "GET"),


  GET_EVENT_MAP: new Service(() => T(`/events-mapping`), "GET"),


  ORG_FILTER_COUNT: new Service((id, search = "", view, cols = []) => T(`/organization/${id}/dashboard/count?viewType=${VIEW_API[view]}&search=${search}&columns=${cols}`), "GET"),
  
  UPDATE_TASK: new Service((macAddress) => T(`/infrastructure/${macAddress}/tasks`), "PUT"),
  GET_INFRA_BREAKDOWN: new Service((infraItemId, breakdownType) => T(`/infra/${infraItemId}/breakdown?breakDown=${breakdownType}`), "GET"),
  GET_VENUE_BREAKDOWN: new Service((venueId, breakdownType) => T(`/venues/${venueId}/breakdown?breakDown=${breakdownType}`), "GET"),
  GET_ORG_BREAKDOWN: new Service((orgId, breakdownType) => T(`/organization/${orgId}/breakdown?breakDown=${breakdownType}`), "GET"),
  GET_WEBHOOK: new Service((orgId) => T(`/webhook?orgId=${orgId}`), "GET"),
  POST_WEBHOOK: new Service(() => T(`/webhook`), "POST"),
  PUT_WEBHOOK: new Service((id) => T(`/webhook/${id}`), "PUT"),
  TEST_WEBHOOK: new Service(() => T(`/webhook/test`), "PUT"),
  GET_WEBHOOK_DOCS: new Service((orgId) => T(`/webhook/docs?orgId=${orgId}`), "GET"),

  GET_VENUE_CHILD: new Service((id, offset, limit, search, view, cols, mainFilter, sort, filterData = {}, includeInfras, includeCurrentVenue = false, forAssociation = false, stateSort = false, alarms = false) => T(`/venues/${id}/children?offset=${offset}&limit=${limit}&search=${search}&viewType=${VIEW_API[view]}&stateSorting=${stateSort}&getAlarms=${alarms}&columns=${cols}&filter=${mainFilter}&timezoneOffset=${(new Date()).getTimezoneOffset()}&includeInfras=${includeInfras}&includeCurrentVenue=${includeCurrentVenue}&forAssociation=${forAssociation}`
    + (!!sort?.orderBy ? `&orderBy=${sort.orderBy}` : ``) + (!!sort?.order ? `&order=${sort.order}` : ``)
    // + (filterData?.infraCategory ? `&infraCategory=${filterData.infraCategory}` : "")
    // + (filterData?.infraType?.length ? `&infraType=${filterData.infraType}` : "")
    // + (filterData?.status?.length > 0 ? `&status=${filterData.status}` : "")
    // + (filterData?.firmwareVersion?.length > 0 ? `&firmwareVersion=${filterData.firmwareVersion}` : "")
  ), "GET"),
  GET_VENUE_CHILDREN: new Service((id, offset, limit, search, view, cols, mainFilter, sort, filterData = {}, includeInfras, includeCurrentVenue = false, forAssociation = false, response = 'Tree') => T(`/venues/${id}/infrastructure?offset=${offset}&limit=${limit}&search=${search}&viewType=${VIEW_API[view]}&columns=${cols}&filter=${mainFilter}&timezoneOffset=${(new Date()).getTimezoneOffset()}&includeInfras=${includeInfras}&includeCurrentVenue=${includeCurrentVenue}&forAssociation=${forAssociation}`
    + (!!sort?.orderBy ? `&orderBy=${sort.orderBy}` : ``) + (!!sort?.order ? `&order=${sort.order}` : ``)
    + (response != null ? `&response=${response}` : "")
    + (filterData?.infraCategory ? `&infraCategory=${filterData.infraCategory}` : "")
    + (filterData?.infraType?.length ? `&infraType=${filterData.infraType}` : "")
    + (filterData?.status?.length > 0 ? `&status=${filterData.status}` : "")
    + (filterData?.firmwareVersion?.length > 0 ? `&firmwareVersion=${filterData.firmwareVersion}` : "")
  ), "GET"),
  GET_VENUE_CHILDREN_COUNT: new Service((id, cols, search, view, filterData = {}) => T(`/venues/${id}/infrastructure/count?search=${search}&viewType=${VIEW_API[view]}&columns=${cols}&timezoneOffset=${(new Date()).getTimezoneOffset()}`
    + (filterData?.infraCategory ? `&infraCategory=${filterData.infraCategory}` : "")
    + (filterData?.infraType?.length ? `&infraType=${filterData.infraType}` : "")
    + (filterData?.status?.length > 0 ? `&status=${filterData.status}` : "")
    + (filterData?.firmwareVersion?.length > 0 ? `&firmwareVersion=${filterData.firmwareVersion}` : "")
  ), "GET"),

  GET_MQTT: new Service((orgId) => T(`/mqtt?orgId=${orgId}`), "GET"),
  CREATE_MQTT: new Service(() => T(`/mqtt`), "POST"),
  UPDATE_MQTT: new Service((mqttId) => T(`/mqtt/${mqttId}`), "PUT"),
  TEST_MQTT: new Service(() => T(`/mqtt/test`), "PUT"),
  GET_MQTT_TRENDS: new Service((orgId, startDate, endDate, offset, trendType) => T(`/organization/${orgId}/mqtt/trend?startDate=${startDate}&endDate=${endDate}&timezoneOffset=${offset}&children=true&trendType=${trendType}`), "GET"),

  GET_INFRA_HIERARCHY_LIST: new Service(
    (orgId, offset, limit, columns, viewType, mainFilter, sort, search, filterData, response, forAssociation, orderInfras) => T(
      `/organization/${orgId}/infrastructure?offset=${offset}&limit=${limit}&columns=${columns}&timezoneOffset=${(new Date()).getTimezoneOffset()}`
      + (mainFilter != null ? `&filter=${mainFilter}` : '')
      + (viewType != null ? `&viewType=${VIEW_MAPPING[viewType]}` : '')
      + (response != null ? `&response=${response}` : '')
      + (search != null ? `&search=${search}` : '')
      + (forAssociation != null ? `&forAssociation=${forAssociation}` : '')
      + (orderInfras != null ? `&orderInfras=${orderInfras}` : '')
      + (sort?.order != null ? `&order=${sort?.order}` : '')
      + (sort?.orderBy != null ? `&orderBy=${sort?.orderBy}` : '')
      + (filterData?.firmwareVersion?.length > 0 ? `&firmwareVersion=${filterData.firmwareVersion}` : "")
      + (filterData?.infraCategory != null ? `&infraCategory=${filterData.infraCategory}` : '')
      + (filterData?.status?.length > 0 ? `&status=${filterData.status}` : '')
      + (filterData?.state != null ? `&state=${filterData.state}` : '')
      + (filterData?.operations != null ? `&operations=${filterData.operations}` : '')
      + (filterData?.location != null ? `&location=${filterData.location}` : '')
      + (filterData?.orgId?.length > 0 ? `&orgIds=${filterData.orgId}` : '')
      + (filterData?.infraType?.length > 0 ? `&infraType=${filterData.infraType}` : "")
    ),
    "GET"
  ),

  GET_INFRA_HIERARCHY_LIST_COUNT: new Service(
    (orgId, columns, viewType, search, filterData, forAssociation) => T(
      `/organization/${orgId}/infrastructure/count?columns=${columns}&timezoneOffset=${(new Date()).getTimezoneOffset()}`
      + (forAssociation != null ? `&forAssociation=${forAssociation}` : '')
      + (viewType != null ? `&viewType=${VIEW_MAPPING[viewType]}` : '')
      + (search != null ? `&search=${search}` : '')
      + (filterData?.infraIds?.length > 0 ? `&infraIds=${filterData.infraIds}` : "")
      // + (filterData?.firmwareVersion?.length > 0 ? `&firmwareVersion=${filterData.firmwareVersion}` : "")
      + (filterData?.infraCategory != null ? `&infraCategory=${filterData.infraCategory}` : '')
      // + (filterData?.status?.length > 0 ? `&status=${filterData.status.map(type => type.toLowerCase())}` : "")
      // + (filterData?.state != null ? `&state=${filterData.state}` : '')
      // + (filterData?.operations != null ? `&operations=${filterData.operations}` : '')
      // + (filterData?.location != null ? `&location=${filterData.location}` : '')
      // + (filterData?.infraType?.length > 0 ? `&infraType=${filterData.infraType}` : "")
      // + (filterData?.orgId?.length > 0 ? `&orgId=${filterData.orgId}` : '')
    ),
    "GET"
  ),

  GET_ORG_LIST: new Service((orgId, offset, limit, search = "", mainFilter, sort, filterData, columns, view, alarm = true, response = "Tree", orgOrder = []) => T(`/organization/${orgId}/orglist?search=${search}&getAlarms=${alarm}&response=${response}&columns=${columns}&orderOrgs=${orgOrder}&timezoneOffset=${(new Date()).getTimezoneOffset()}`
    + (offset != null ? `&offset=${offset}` : '')
    + (limit != null ? `&limit=${limit}` : '')
    + (mainFilter != null ? `&filter=${mainFilter}` : '')
    + (sort?.order != null ? `&order=${sort?.order}` : '')
    + (sort?.orderBy != null ? `&orderBy=${sort?.orderBy}` : '')
    + (filterData?.orgId?.length > 0 ? `&orgIds=${filterData.orgId}` : "")
    + (filterData?.operations?.length > 0 ? `&operations=${filterData.operations}` : "")
    + (filterData?.location?.length > 0 ? `&location=${filterData.location}` : "")
    + (filterData?.state?.length > 0 ? `&state=${filterData.state}` : "")
    + (filterData?.firmwareVersion?.length > 0 ? `&firmwareVersion=${filterData.firmwareVersion}` : "")
    + (view != null ? `&viewType=${VIEW_MAPPING[view]}` : '')), "GET"),

  GET_ORG_LIST_COUNT: new Service((orgId, search, getAlarms = true, columns) => T(`/organization/${orgId}/orglist/count?search=${search}&columns=${columns}&getAlarms=${getAlarms}`
  ), "GET"),

  GET_ORG_VENUE_LIST: new Service((orgId, offset, limit, search = "", mainFilter, sort, response = "Tree", filterData, columns, view, alarms = false, orderVenues, getSiteOnly = false) => T(`/organization/${orgId}/venues?search=${search}&offset=${offset}&limit=${limit}&columns=${columns}&getSiteOnly=${getSiteOnly}&response=${response}&getAlarms=${alarms}&timezoneOffset=${(new Date()).getTimezoneOffset()}`
    + (mainFilter != null ? `&filter=${mainFilter}` : '')
    + (sort?.order != null ? `&order=${sort?.order}` : '')
    + (sort?.orderBy != null ? `&orderBy=${sort?.orderBy}` : '')
    + (filterData?.orgId?.length > 0 ? `&orgIds=${filterData.orgId}` : "")
    + (filterData?.operations?.length > 0 ? `&operations=${filterData.operations}` : "")
    + (filterData?.location?.length > 0 ? `&location=${filterData.location}` : "")
    + (filterData?.state?.length > 0 ? `&state=${filterData.state}` : "")
    + (filterData?.firmwareVersion?.length > 0 ? `&firmwareVersion=${filterData.firmwareVersion}` : "")
    + (view != null ? `&viewType=${VIEW_MAPPING[view]}` : '')
    + (orderVenues != null ? `&orderVenues=${orderVenues}` : ``)
  ), "GET"),

  GET_ORG_VENUE_LIST_COUNT: new Service((orgId, search, viewType, filterData, columns, getAlarms = false, getSiteOnly = false) => T(`/organization/${orgId}/venues/count?search=${search}&columns=${columns}&getSiteOnly=${getSiteOnly}&getAlarms=${getAlarms}`
    // + (filterData?.orgId?.length > 0 ? `&orgId=${filterData.orgId}` : "")
    // + (filterData?.operations?.length > 0 ? `&operations=${filterData.operations}` : "")
    // + (filterData?.location?.length > 0 ? `&location=${filterData.location}` : "")
    // + (filterData?.state?.length > 0 ? `&state=${filterData.state}` : "")
    // + (filterData?.firmwareVersion?.length > 0 ? `&firmwareVersion=${filterData.firmwareVersion}` : "")
    + (viewType != null ? `&viewType=${VIEW_MAPPING[viewType]}` : '')
  ), "GET"),

  DOWNLOAD_VENUE_INFRA_LIST: new Service((venueId, response = 'Tree', view, columns, mainFilter, sort, search = '', filterData, forAssociation = false, exportData = false, orderInfras) => T(`/venues/${venueId}/infrastructure/export?response=${response}&viewType=${VIEW_MAPPING[view]}&timezoneOffset=${(new Date()).getTimezoneOffset()}&columns=${columns ?? []}&filter=${mainFilter}&forAssociation=${forAssociation}&exportData=${exportData}&includeCurrentVenue=${true}`
    + (sort?.order != null ? `&order=${sort?.order}` : '')
    + (sort?.orderBy != null ? `&orderBy=${sort?.orderBy}` : '')
    + (!!search ? `&search=${search}` : '')
    + (filterData?.venueId?.length ? `&venueId=${filterData.venueId}` : "")
    + (filterData?.firmwareVersion?.length > 0 ? `&firmwareVersion=${filterData.firmwareVersion}` : "")
    + (filterData?.infraCategory != null ? `&infraCategory=${filterData.infraCategory}` : "")
    + (filterData?.status?.length > 0 ? `&status=${filterData?.status}` : "")
    + (filterData?.infraType?.length > 0 ? `&infraType=${filterData.infraType}` : "")
    + (filterData?.operations?.length > 0 ? `&operations=${filterData.operations}` : ``)
    + (filterData?.tasks?.length > 0 ? `&tasks=${filterData.tasks}` : ``)
    + (orderInfras != null ? `&orderInfras=${orderInfras}` : '')
  ), "GET"),

  DOWNLOAD_INFRA_LIST: new Service((orgId, response = 'Tree', view, columns, mainFilter, sort, search = '', filterData, forAssociation = false, exportData = false) => T(`/organization/${orgId}/infrastructure/export?response=${response}&viewType=${VIEW_MAPPING[view]}&timezoneOffset=${(new Date()).getTimezoneOffset()}&columns=${columns ?? []}&filter=${mainFilter}&forAssociation=${forAssociation}&exportData=${exportData}`
    + (sort?.order != null ? `&order=${sort?.order}` : '')
    + (sort?.orderBy != null ? `&orderBy=${sort?.orderBy}` : '')
    + (!!search ? `&search=${search}` : '')
    + (filterData?.orgId?.length > 0 ? `&orgIds=${filterData.orgId}` : "")
    + (filterData?.venueId?.length ? `&venueId=${filterData.venueId}` : "")
    + (filterData?.infraIds?.length ? `&infraIds=${filterData.infraIds}` : ``)
    + (filterData?.firmwareVersion?.length > 0 ? `&firmwareVersion=${filterData.firmwareVersion}` : "")
    + (filterData?.infraCategory != null ? `&infraCategory=${filterData.infraCategory}` : "")
    + (filterData?.status?.length > 0 ? `&status=${filterData?.status}` : "")
    + (filterData?.infraType?.length > 0 ? `&infraType=${filterData.infraType}` : "")
    + (filterData?.operations?.length > 0 ? `&operations=${filterData.operations}` : ``)
    + (filterData?.tasks?.length > 0 ? `&tasks=${filterData.tasks}` : ``)
  ), "GET"),

  DOWNLOAD_INFRA_EVENTS: new Service((mac, startDate, endDate, timezone, sort) => T(`/events/device/${mac}/export?startDate=${startDate}&endDate=${endDate}&timezoneOffset=${timezone}&order=${sort.order}&orderBy=${sort.orderBy}`), "GET"),

  DOWNLOAD_ORG_LIST: new Service((orgId, view, columns, mainFilter, sort, search = '', filterData, getAlarms = false, response = 'Tree', exportData = false) => T(`/organization/${orgId}/orglist/export?viewType=${VIEW_MAPPING[view]}&timezoneOffset=${(new Date()).getTimezoneOffset()}&columns=${columns ?? []}&filter=${mainFilter}&getAlarms=${getAlarms}&response=${response}&exportData=${exportData}`
    + (sort?.order != null ? `&order=${sort?.order}` : '')
    + (sort?.orderBy != null ? `&orderBy=${sort?.orderBy}` : '')
    + (!!search ? `&search=${search}` : '')
    //not in api currently
    // + (filterData?.operations?.length > 0 ? `&operations=${filterData.operations}` : "")
    + (filterData?.location?.length > 0 ? `&location=${filterData.location}` : "")
    + (filterData?.state?.length > 0 ? `&state=${filterData.state}` : "")
  ), "GET"),

  DOWNLOAD_VENUE_LIST: new Service((orgId, view, columns, mainFilter, sort, search = '', response = 'Tree', filterData) => T(`/organization/${orgId}/venues/export?viewType=${VIEW_MAPPING[view]}&timezoneOffset=${(new Date()).getTimezoneOffset()}&columns=${columns}&filter=${mainFilter}&response=${response}`
    + (sort?.order != null ? `&order=${sort?.order}` : '')
    + (sort?.orderBy != null ? `&orderBy=${sort?.orderBy}` : '')
    + (!!search ? `&search=${search}` : '')
    + (filterData?.orgId?.length > 0 ? `&orgIds=${filterData.orgId}` : "")
    + (filterData?.operations?.length > 0 ? `&operations=${filterData.operations}` : "")
    + (filterData?.location?.length > 0 ? `&location=${filterData.location}` : "")
    + (filterData?.state?.length > 0 ? `&state=${filterData.state}` : "")
    + (filterData?.firmwareVersion?.length > 0 ? `&firmwareVersion=${filterData.firmwareVersion}` : "")
  ), "GET"),

  DOWNLOAD_VENUE_EVENTS: new Service((venueId, search = '', mainFilter, filterData, startDate, endDate, timezoneOffset, sort, children = false) => T(`/events/venue/${venueId}/export?impact=${mainFilter}&search=${search}&startDate=${startDate}&endDate=${endDate}&timezoneOffset=${timezoneOffset}&children=${children}&order=${sort.order}&orderBy=${sort.orderBy}`
    + (filterData.events != null && filterData.events?.length > 0 ? `&eventFilter=${filterData.events}` : '')
  ), "GET"),

  GET_VENUE_DETAILS: new Service((venueId) => T(`/venues/${venueId}/details`), "GET"),
  GET_VENUE_ALERTS: new Service((venueId, offset, limit, search, status, severity = 'all', sort, filter, count = false) => T(`/venues/${venueId}/alerts?offset=${offset}&limit=${limit}&getCount=${count}&search=${search}&status=${status}&severity=${severity}&order=${sort.order}&orderBy=${sort.orderBy}`
    + (filter?.alertType?.length > 0 ? `&alertType=${filter.alertType}` : ``)
    + (filter?.alertImpact?.length > 0 ? `&alertImpact=${filter.alertImpact}` : ``)
    + (filter?.venues?.length > 0 ? `&venueId=${filter.venues.map(venue => venue.venueId)}` : ``)
    + (filter?.infras?.length > 0 ? `&infraId=${filter.infras.map(infra => infra.infraItemId)}` : ``)), "GET"),



};

export default telemetry;