import { createSlice } from "@reduxjs/toolkit";

const activeVenueInitialState = {
  status: {
    loading: false,
    error: null,
    infraLoading: false
  },
  data: {
    venueId: null,
    orgId: null,
    parentVenueId: null,
    venueName: null,
    floorPlan: null,
    createdAt: null,
    updatedAt: null,
    venueAddress: {
      addressLine: null,
      state: null,
      city: null,
      zipCode: null
    },
    shippingAddress: {
      addressLine: null,
      state: null,
      city: null,
      zipCode: null
    }
  },
  meta: {
    infra: null
  }
};

const activeVenueSlice = createSlice({
  name: "activeVenue",
  initialState: {...activeVenueInitialState},
  reducers: {
    setLoading: (state, action) => {
      state.status.loading = action.payload;
    },
    setInfraLoading: (state, action) => {
      state.status.infraLoading = action.payload;
    },
    setError: (state, action) => {
      state.status.error = action.payload;
    },
    setActiveVenue: (state, action) => {
      state.data = {...action.payload};
    },
    resetActiveVenue: (state) => {
      state.data = {...activeVenueInitialState};
    },
    setInfra:(state, action) => {
      state.meta.infra = [...action.payload];
    },
    addInfra: (state, action) => {
      if (state.meta.infra === null)
        state.meta.infra = [...action.payload];
      else
        state.meta.infra = state.meta.infra.concat(action.payload);
    },
    resetInfra: (state) => {
      state.meta.infra = null
    }
  }
});

export const activeVenueReducer = activeVenueSlice.reducer;
const activeVenueActions = activeVenueSlice.actions;
export default activeVenueActions;

