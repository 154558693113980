/***
 *
 * Controller class for user.
 * @file ReleaseArticle.js
 * @description ReleaseArticle component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React, { useEffect, useState } from "react";
import { Alert, Button } from "reactstrap";
import { CatchedWebError } from "../../configs";
import createRequest, { services } from "../../services";
import VeryFancyLoader from "../VeryFancyLoader";
// import PropTypes from 'prop-types';
import "./ReleaseArticle.scss";

const ReleaseArticle = (props) => {
  const [loading,setLoading] = useState(true);
  const [article,setArticle] = useState(null);
  const [error,setError] = useState(null);
  useEffect(() => {
    const {run} = createRequest(services.ticket.GET_ARTICLE_BY_ID,[props.selectedRN]);
    if(props.selectedRN) {
      setLoading(true);
      run()
      .then((response)=> {
        setArticle(response.data.description);
      })
      .catch((err)=> {
        setError((new CatchedWebError(err)).message);
      })
      .finally(()=> {
        setLoading(false);
      })
    }
  }, [props.selectedRN])
  
  return (
    <div className="ReleaseArticle mt-1 bg-white rounded" data-testid="ReleaseArticle">
      <Alert className="w-100" color="danger" isOpen={!!error} toggle={() => setError(null)}>
        <div className="alert-body">{error}</div>
      </Alert>
      <div className="d-flex">
        <div className="d-inline-block text-center padding-arrow">
          <span className='material-symbols-outlined cursor-pointer text-primary hover-bold' onClick={()=>props.setSelectedRN(null)}>
            arrow_back
          </span>
        </div>
        <div className='w-100'>
          {loading?
          <div className="d-flex justify-content-center align-items-center h-100">
            <VeryFancyLoader />
          </div>
          :
          article &&
          <div className='d-inline-block py-2 w-100'>
            <div className="release-article" dangerouslySetInnerHTML={{ __html: article??<></> }} />
            <div className = 'text-center w-100'>
              <Button outline color='primary' onClick={() => {
                props.setSelectedRN(null);
              }}>
                Go Back
              </Button>
            </div>
          </div>
          }
        </div>
      </div>
      <div className="pt-1 pl-2">
      </div>
    </div>
  );
};

ReleaseArticle.propTypes = {};

ReleaseArticle.defaultProps = {};

export default ReleaseArticle;
