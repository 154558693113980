import { BUSINESS_VIEW, DEPLOYMENT_VIEW, NETWORK_VIEW } from "../../utility/constants"

export const LIST_TYPES = {
    INFRA_LIST: 1,
    MSP_LIST: 2,
    VENUE_LIST: 3,
    ORG_LIST: 4
  }

export const filterObj = {
    "infra-list": {
        [NETWORK_VIEW]: {
            'All': {
            name: "All",
            countName: 'all',
            },
            'Online': {
            name: "Online",
            countName: 'online',
            },
            'Issues': {
            name: "Issues",
            countName: 'issues',
            },
            'Offline': {
            name: "Offline",
            countName: 'offline',
            },
        },
        [BUSINESS_VIEW]: {
            'All': {
            name: "All",
            apiValue: 'All',
            countName: 'all',
            },
            'Online': {
            name: "Online",
            countName: 'online',
            },
            'Issues': {
            name: "Issues",
            countName: 'issues',
            },
            'Offline': {
            name: "Offline",
            countName: 'offline',
            },
        },
        [DEPLOYMENT_VIEW]: {
            'All': {
            name: "All",
            countName: 'all',
            },
            'Not Initialized' : {
            name: "Not Initialized",
            countName: 'notInitialized',
            },
            'No Photos' : {
            name: "No Photos",
            countName: 'noPhotos',
            },
            'Not Installed' : {
            name: "Not Installed",
            countName: 'notInstalled',
            },
            'Not Validated' : {
            name: "Not Validated",
            countName: 'notValidated',
            },
        },
    },
    "venue-dashboard": {
        [NETWORK_VIEW]: {
          "All":{
            name: "All",
            countName: "all",
          },
          "Issues":{
            name: "Issues",
            countName: "issues",
          },
          "Offline":{
            name: "Offline",
            countName: "offline",
          },
        },
    
        [DEPLOYMENT_VIEW]: {
          "All": {
            name: "All",
            countName: "all",
          },
          "Pending Tasks": {
            name: "Pending Tasks",
            countName: "pending",
          },
          "Completed":{
            name: "Completed",
            countName: "completed",
          },
        },
    
        [BUSINESS_VIEW]: {
          "All":{
            name: "All",
            countName: "all",
          },
          "Design":{
            name: "Design",
            countName: "design",
          },
          "Deployment":{
            name: "Deployment",
            countName: "deployment",
          },
          "Live": {
            name: "Live",
            countName: "live",
          },
          "Disabled": {
            name: "Disabled",
            countName: "disabled",
          },
        },
      },
    "dashboard":{
      [NETWORK_VIEW]: {
        "All":{
          name: "All",
          countName: "All",
        },
        "Critical":{
          name: "Critical",
          countName: "Critical",
        },
        "Major":{
          name: "Major",
          countName: "Major",
        },
      },
  
      [DEPLOYMENT_VIEW]: {
        "All":{
          name: "All",
          countName: "All",
        },
        "Critical":{
          name: "Critical",
          countName: "Critical",
        },
        "Major":{
          name: "Major",
          countName: "Major",
        },
      },
  
      [BUSINESS_VIEW]: {
        "All":{
          name: "All",
          countName: "All",
        },
        "Critical":{
          name: "Critical",
          countName: "Critical",
        },
        "Major":{
          name: "Major",
          countName: "Major",
        },
      },
    },
    "org-dashboard":{
      [NETWORK_VIEW]: {
        "All":{
          name: "All",
          countName: "All",
        },
        "Issues":{
          name: "Issues",
          countName: "Issues",
        },
        "Offline":{
          name: "Offline",
          countName: "Offline",
        },
      },
  
      [DEPLOYMENT_VIEW]: {
        "All":{
          name: "All",
          countName: "All",
        },
        "Issues":{
          name: "Issues",
          countName: "Issues",
        },
        "Offline":{
          name: "Offline",
          countName: "Offline",
        },
      },
  
      [BUSINESS_VIEW]: {
        "All":{
          name: "All",
          countName: "All",
        },
        "Issues":{
          name: "Issues",
          countName: "Issues",
        },
        "Offline":{
          name: "Offline",
          countName: "Offline",
        },
      },
    }
}

export const columnObj = {
    "dashboard": {
        [BUSINESS_VIEW]:{
            "Alarms": true,
            "State": true,
            "Infrastructure": true,
            "Traffic": true,
            "Clients": true
        },
        [DEPLOYMENT_VIEW]:{
            "Alarms": true,
            "State": true,
            "Infrastructure": true,
            "Traffic": true,
            "Clients": true
        },
        [NETWORK_VIEW]:{
            "Alarms": true,
            "State": true,
            "Infrastructure": true,
            "Traffic": true,
            "Clients": true
        }
    },
    "infra-list": {
        // Operations, Infrastructure, Traffic, Clients, Firmware, Location, State, In-Service, Cost, 
    // Proposed, VenueAddress, Proposal Count, 
    // Tasks,MAC Address, Operations, UpTime, Statistics, Room, Speedtest, Private IP, Public IP, 
    // AP Profile, Switch Profile, RF Profile, Radio 2G, Radio 5G, Radio 6G
        [NETWORK_VIEW]: {
          "Status": true,
          "Organization-Venue": true,
          "Private IP": true,
          "Public IP": false,
          "AP Profile": false,
          "Switch Profile": false,
          "RF Profile": false,
          "Radio 2G": false,
          "Radio 5G": false,
          "Radio 6G": false,
          "Tasks": false,
          "Room": false,
          "Location": false,
          "Traffic": true,
          "Clients": true,
          "UpTime": false,
          "Release": true,
          "Operations": true,
          "MAC Address": true,
          "Serial Number": false,
          "Asset Tag": false
        },
        [BUSINESS_VIEW]: {
          "Status": true,
          "Organization-Venue": true,
          "Private IP": true,
          "Public IP": false,
          "AP Profile": false,
          "Switch Profile": false,
          "RF Profile": false,
          "Radio 2G": false,
          "Radio 5G": false,
          "Radio 6G": false,
          "Tasks": false,
          "Room": false,
          "Location": false,
          "Traffic": true,
          "Clients": true,
          "UpTime": true,
          "Release": true,
          "Operations": true,
          "MAC Address": true,
          "Serial Number": false,
          "Asset Tag": false
        },
        [DEPLOYMENT_VIEW]: {
          "Status": true,
          "Organization-Venue": true,
          "Private IP": false,
          "Public IP": false,
          "AP Profile": false,
          "Switch Profile": false,
          "RF Profile": false,
          "Radio 2G": false,
          "Radio 5G": false,
          "Radio 6G": false,
          "Tasks": true,
          "Room": true,
          "Location": true,
          "Traffic": false,
          "Clients": false,
          "UpTime": false,
          "Release": false,
          "Operations": false,
          "MAC Address": true,
          "Serial Number": false,
          "Asset Tag": false
        },
    },
    "venue-dashboard": {
    [NETWORK_VIEW]: {
      Operations: true,
      Infrastructure: true,
      Traffic: true,
      Clients: true,
      Release: true,
      State: true,
      Location: false,
      "In-Service": false,
      Cost: false,
      "Pending Tasks": false,
    },

    [DEPLOYMENT_VIEW]: {
      Operations: false,
      Infrastructure: true,
      Traffic: false,
      Clients: false,
      Release: false,
      State: true,
      Location: false,
      "In-Service": false,
      Cost: false,
      "Pending Tasks": true,
    },

    [BUSINESS_VIEW]: {
      Operations: false,
      Infrastructure: false,
      Traffic: false,
      Clients: false,
      Release: false,
      State: true,
      Location: true,
      "In-Service": true,
      Cost: true,
      "Pending Tasks": false,
    },
    },
    "org-dashboard":{
      [NETWORK_VIEW]:{
        Infrastructure: true,
        Venues: true,
        Operations: true,
        Location: true,
        State: false,
        "Live Sites": false,
        "Proposed Amt": false,
        "Proposed Subs": false,
      },
      [DEPLOYMENT_VIEW]:{
        Infrastructure: true,
        Venues: true,
        Operations: false,
        Location: false,
        State: true,
        "Live Sites": true,
        "Proposed Amt": false,
        "Proposed Subs": false,
      },
      [BUSINESS_VIEW]:{
        Infrastructure: true,
        Venues: true,
        Operations: false,
        Location: false,
        State: false,
        "Live Sites": true,
        "Proposed Amt": true,
        "Proposed Subs": true,
      }
    }
}

export const apiColumns = {
  'In Service': 'In-Service',
  'Venue Address': 'VenueAddress',
  'Up Time': 'UpTime',
  "Pending Tasks": "Tasks",
  "Release": "Firmware",
  "Proposed Subs": "Proposed Subscription"
}

export const allowedColumnsObj = {
  [LIST_TYPES.MSP_LIST] : [
    "Alarms",
    "State",
    "Operations",
    "Location",
    "Venues",
    "Infrastructure",
    "Live Sites",
    "Proposed Amt",
    "Proposed Subscription"
  ],
  [LIST_TYPES.VENUE_LIST] : [
    "State", 
    "Operations", 
    "Location", 
    "Infrastructure", 
    "Tasks",
    "In-Service", 
    "Cost", 
    "Traffic", 
    "Clients", 
    "Firmware", 
    //there in api definition but there are no columns for these
    // "Organization", 
    // "Proposed", 
    // "VenueAddress", 
    // "Proposal Count", 
    // "Tasks", 
    // "Alarms"
  ],
  [LIST_TYPES.INFRA_LIST] : [
    "Status", 
    "Organization-Venue", 
    "Private IP", 
    "Public IP", 
    "AP Profile", 
    "Switch Profile", 
    "RF Profile", 
    "Radio 2G", 
    "Radio 5G", 
    "Radio 6G", 
    "Tasks", 
    "Room", 
    "Location", 
    "Traffic", 
    "Clients",
    "UpTime", 
    "Firmware", 
    "Operations", 
    "MAC Address", 
    "Serial Number", 
    "Asset Tag",
    // "Speedtest", 
  ],
}

export const mapColumns = [
  "Name",
  "Traffic",
  "Clients",
  "Firmware",
  "State",
  "Infrastructure",
  "Alarms",
  "VenueAddress",
];