import Service from "./Service";
import { T, TICKET, U } from "../helpers";

const blazer = {
  /**
   * @example
   * params = [orgId: number, children: boolean = false]
   * response = { connected: number, disconnected: number, pending: number }
   */
  ORG_INFRA: new Service((orgId, children = false, currDate, timezone) => T(`/organization/${orgId}/infra-stats?children=${children}&currentDate=${currDate}&timezoneOffset=${timezone}`), "GET"),

  /**
   * @example
   * params = [orgId: number, children: boolean = false]
   * response = { totalVenues: number, offlineVenues: number, onlineVenues: number, inactiveVenues: number }
   */
  ORG_VENUE: new Service((orgId, children = false, currDate, timezone) => T(`/organization/${orgId}/venue-stats?id=${orgId}&children=${children}&currentDate=${currDate}&timezoneOffset=${timezone}`), "GET"),
  ORG_VENUE_TASKS: new Service((orgId, children = false) => T(`/organization/${orgId}/tasks?children=${children}`), "GET"),

  /** TICKETING URL */
  ORG_SUPPORT: new Service((orgId, children = false) => TICKET(`/ticket/count?orgId=${orgId}&children=${children}`), "GET"),

  /**
   * @example
   * params = [orgId: number, startDate: string, endDate: string, children: boolean]
   * response = { userdeviceTrend: [ { label: string, device_count: 0 } ], connected: 0 }
   */
  ORG_DEVICES: new Service((orgId, startDate, endDate, children = false, timezone) => T(`/userdevice/organization/${orgId}?startDate=${startDate}&endDate=${endDate}&children=${children}&timezoneOffset=${timezone}`), "GET"),

  /**
   * @example
   * params = [orgId: number, children: boolean]
   * response = { Up to date:0 ,Others:0 }
   */
  FIRMWARE_VERSION: new Service((orgId, children) => U(`firmware/version/organization/${orgId}?children=${children}`), "GET"),


  /**
   * @example
   * params = [orgId: number, children: boolean]
   * response = { userdeviceTrend: [ { label: string, device_count: 0 } ], connected: 0 }
   */
  AP_HEALTH: new Service((orgId, startDate, endDate, children = false, timezone) => T(`/uptime/organization/${orgId}?startDate=${startDate}&endDate=${endDate}&children=${children}&timezoneOffset=${timezone}`), "GET"),
  AP_HEALTH_VENUE: new Service((venueId, startDate, endDate, children = false, timezone) => T(`/uptime/venue/${venueId}?startDate=${startDate}&endDate=${endDate}&children=${children}&timezoneOffset=${timezone}`), "GET"),


  /**
   * @example
   * params = [orgId: number, children: boolean]
   * response = { userdeviceTrend: [ { label: string, device_count: 0 } ], connected: 0 }
   */
  AP_REBOOT: new Service((orgId, startDate, endDate, children = false, timezone) => T(`/reboot/organization/${orgId}?startDate=${startDate}&endDate=${endDate}&children=${children}&timezoneOffset=${timezone}`), "GET"),
  AP_REBOOT_VENUE: new Service((venueId, startDate, endDate, children = false, timezone) => T(`/reboot/venue/${venueId}?startDate=${startDate}&endDate=${endDate}&children=${children}&timezoneOffset=${timezone}`), "GET"),


  ORG_AP_ONLINE: new Service((orgId, startDate, endDate, children, timezone) => T(`/infra/organization/${orgId}?startDate=${startDate}&endDate=${endDate}&children=${children}&timezoneOffset=${timezone}`), "GET"),
  ORG_VENUE_ALARMS: new Service((orgId, startDate, endDate, children, timezone) => T(`/alert/organization/${orgId}?startDate=${startDate}&endDate=${endDate}&children=${children}&timezoneOffset=${timezone}`), "GET"),
  ORG_CLENTS_COUNT: new Service((orgId, children) => T(`/organization/${orgId}/userdevice-stats?children=${children}`), "GET"),

  ORG_ALERT: new Service((orgId, offset, limit, search, status, sort, filter, count = false, children = false, severity) => T(`/alert/organization/${orgId}/list?children=${children}&offset=${offset}&limit=${limit}&getCount=${count}&search=${search}&status=${status}&order=${sort.order}&orderBy=${sort.orderBy}`
    + (filter?.alertType?.length > 0 ? `&alertType=${filter.alertType}` : ``)
    + (filter?.alertImpact?.length > 0 ? `&alertImpact=${filter.alertImpact}` : ``)
    + (filter?.venues?.length > 0 ? `&venueId=${filter.venues.map(venue => venue.venueId)}` : ``)
    + (filter?.orgs?.length > 0 ? `&orgIds=${filter.orgs}` : ``)
    + (severity ? `&severity=${severity}` : ``)
    + (filter?.infras?.length > 0 ? `&infraId=${filter.infras.map(infra => infra.infraItemId)}` : ``)), "GET"),

  INFRA_ALERT_LIST: new Service((infraItemId, offset, limit, search, status, sort, filter, count = false, severity) => T(`/infra/${infraItemId}/alerts?offset=${offset}&limit=${limit}&getCount=${count}&search=${search}&status=${status}&order=${sort.order}&orderBy=${sort.orderBy}`
    + (filter?.alertType?.length > 0 ? `&alertType=${filter.alertType}` : ``)
    + (filter?.alertImpact?.length > 0 ? `&alertImpact=${filter.alertImpact}` : ``)
    + (filter?.venues?.length > 0 ? `&venueId=${filter.venues.map(venue => venue.venueId)}` : ``)
    + (filter?.orgs?.length > 0 ? `&orgIds=${filter.orgs}` : ``)
    + (severity ? `&severity=${severity}` : ``)
  ), "GET"),

  ACK_ALERT: new Service((id) => T(`/alert/${id}`), "PUT"),
  INFRA_ALERT: new Service((id) => T(`/infrastructure/${id}/alarms/count`), "GET"),

  /**
   * @example
   * params = [orgId: number, startDate: string, endDate: string, children: boolean]
   * response = {
   *    uplink: [{label: string, rx_bytes: string, tx_bytes: string}],
   *    downlink: [{label: string, rx_bytes: string, tx_bytes: string}],
   *    avg_tx_bytes: number,
   *    avg_rx_bytes: number
   * }
   */
  ORG_NETWORK: new Service((orgId, startDate, endDate, children = false, timezone) => T(`/traffic/organization/${orgId}?startDate=${startDate}&endDate=${endDate}&children=${children}&timezoneOffset=${timezone}`), "GET"),


  VENUE_INFRA: new Service((venueId, children = false) => T(`/infra/venue/${venueId}?children=${children}`), "GET"),
  VENUE_DEVICES: new Service((venueId, startDate, endDate, children = false, timezone) => T(`/userdevice/venue/${venueId}?startDate=${startDate}&endDate=${endDate}&children=${children}&timezoneOffset=${timezone}`), "GET"),
  VENUE_NETWORK: new Service((venueId, startDate, endDate, children = false, timezone) => T(`/traffic/venue/${venueId}?startDate=${startDate}&endDate=${endDate}&children=${children}&timezoneOffset=${timezone}`), "GET"),
  VENUE_EVENTS: new Service((venueId, startDate, endDate, children = false, timezone, impact, search, sort, event) => T(`/events/venue/${venueId}?startDate=${startDate}&endDate=${endDate}&children=${children}&timezoneOffset=${timezone}&impact=${impact}&search=${search}&order=${sort.order}&orderBy=${sort.orderBy}` + (event.events ? `&eventFilter=${event.events}` : '')), "GET"),
  VENUE_EVENT_COUNT: new Service((venueId, startDate, endDate, children = false, timezone, search="") => T(`/events/venue/${venueId}/count?search=${search}&startDate=${startDate}&endDate=${endDate}&children=${children}&timezoneOffset=${timezone}`), "GET"),
  VENUE_HEALTH: new Service((venueId, startDate, endDate, children = false, timezone, sort) => T(`/timeline/venue/${venueId}?startDate=${startDate}&endDate=${endDate}&children=${children}&timezoneOffset=${timezone}&order=${sort.order}&orderBy=${sort.orderBy}`), "GET"),
  INFRA_DEVICES: new Service((infraId, startDate, endDate, timezone, realtime = false) => T(`/userdevice/device/${infraId}?timezoneOffset=${timezone}` + (startDate === '' ? '' : `&startDate=${startDate}`) + (endDate === '' ? '' : `&endDate=${endDate}`) + (realtime ? `&realTime=true` : '')), "GET"),
  INFRA_DEVICES_COUNT: new Service((infraId, historical = false) => T(`/userdevice/device/${infraId}?historical_trend=${historical}`), "GET"),
  INFRA_NETWORK: new Service((infraId, startDate, endDate, timezone, realtime = false) => T(`/traffic/device/${infraId}?timezoneOffset=${timezone}` + (startDate === '' ? '' : `&startDate=${startDate}`) + (endDate === '' ? '' : `&endDate=${endDate}`) + (realtime ? `&realTime=true` : '')), "GET"),
  INFRA_LOAD: new Service((infraId, startDate, endDate, timezone, realtime = false) => T(`/infrastructure/${infraId}/load?timezoneOffset=${timezone}` + (startDate === '' ? '' : `&startDate=${startDate}`) + (endDate === '' ? '' : `&endDate=${endDate}`) + (realtime ? `&realTime=true` : '')), "GET"),
  INFRA_MEMORY: new Service((infraId, startDate, endDate, timezone, realtime = false) => T(`/infrastructure/${infraId}/memory?timezoneOffset=${timezone}` + (startDate === '' ? '' : `&startDate=${startDate}`) + (endDate === '' ? '' : `&endDate=${endDate}`) + (realtime ? `&realTime=true` : '')), "GET"),
  INFRA_EVENTS: new Service((mac, startDate, endDate, timezone, sort) => T(`/events/device/${mac}?startDate=${startDate}&endDate=${endDate}&timezoneOffset=${timezone}&order=${sort.order}&orderBy=${sort.orderBy}`), "GET"),
  INFRA_HEALTH: new Service((mac, startDate, endDate, timezone) => T(`/timeline/device/${mac}?startDate=${startDate}&endDate=${endDate}&timezoneOffset=${timezone}`), "GET"),
  NETWORK_NETWORK: new Service((orgId, startDate, endDate, timezone, ssid, networkId) => T(`/traffic/organization/${orgId}/network?startDate=${startDate}&endDate=${endDate}&timezoneOffset=${timezone}&ssid=${ssid}&network_id=${networkId}`), "GET"),
  DEVICE_NETWORK: new Service((mac, startDate, endDate, timezone) => T(`/traffic/userdevice/${mac}?startDate=${startDate}&endDate=${endDate}&timezoneOffset=${timezone}`), "GET"),
  DEVICE_PHYRATE: new Service((mac, startDate, endDate, timezone) => T(`/userdevice/${mac}/phy?startDate=${startDate}&endDate=${endDate}&timezoneOffset=${timezone}`), "GET"),
  DEVICE_RSSI: new Service((mac, startDate, endDate, timezone) => T(`/userdevice/${mac}/rssi?startDate=${startDate}&endDate=${endDate}&timezoneOffset=${timezone}`), "GET"),

  WEBHOOK: new Service((orgId, children = false, startDate, endDate, timezone) => T(`/organization/${orgId}/webhook/stats?id=${orgId}&children=${children}&startDate=${startDate}&endDate=${endDate}&timezoneOffset=${timezone}`), "GET"),
  WEBHOOK_TREND: new Service((orgId, children = true, startDate, endDate, timezone, trendType = 'success') => T(`/organization/${orgId}/webhook/trend?id=${orgId}&children=${children}&startDate=${startDate}&endDate=${endDate}&timezoneOffset=${timezone}&trendType=${trendType}`), "GET"),
};

export default blazer;