/***
 *
 * Controller class for user.
 * @file PendingTasksSubHeading.js
 * @description PendingTasksSubHeading component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React from "react";
// import PropTypes from 'prop-types';
import "./PendingTasksSubHeading.scss";
import { Progress } from "reactstrap";

const PendingTasksSubHeading = (props) => {
  const {pendingTasks, totalTasks} = props;
  return (
    <div className="PendingTasksSubHeading" data-testid="PendingTasksSubHeading">
      <div>
        <span className={`text--normal-size ${pendingTasks == 0 ? 'text-success' : 'text-color--shasta-black'}`}>
          {pendingTasks == 0 ? totalTasks : pendingTasks}
        </span>&nbsp;/&nbsp;{totalTasks}&nbsp;{pendingTasks == 0 ? 'Tasks Completed' : 'Pending Tasks'}
      </div>
      <div className="padding-top--point-65">
          <Progress className="progress-pend-task bg-success" value={(pendingTasks / (totalTasks)) * 100} />
      </div>
    </div>
  );
};

PendingTasksSubHeading.propTypes = {};

PendingTasksSubHeading.defaultProps = {};

export default PendingTasksSubHeading;
