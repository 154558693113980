/***
 *
 * Controller class for user.
 * @file UpdateLink.js
 * @description UpdateLink component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React from "react";
import { ReactComponent as UpdateIcon } from "../../assets/images/icons/UpdateIcon.svg";
import { ReactComponent as EditPen } from "../../assets/images/icons/EditPen.svg";
// import PropTypes from 'prop-types';
import "./UpdateLink.scss";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { dateTimeFormatter } from "../../utility/Localization";
import { UncontrolledTooltip } from "reactstrap";

const UpdateLink = ({ scheduleDate, venueId, venueOverview, timezone }) => {
  const navigate = useNavigate();
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const permissions = useSelector(store => store.rbac.permissions);
  if (!scheduleDate || !venueId || !timezone)
    return <></>
  return (
    <div className={'UpdateLink '}>
      <div className={"rounded quicklink-button mr-50" + (!scheduleDate ? " d-none " : "" + (venueOverview ? '' : ''))}>
        <div className="d-flex align-items-center">
          <div>
            <div id="schedule-tag" className='d-flex align-items-center cursor-pointer text-font-small-size'
              onClick={() => {
                navigate(`/organization/${activeOrgId}/fwupdates`, {
                  state: {
                    filter: { "venues": [venueId] }
                  }
                })
              }}>
              <UpdateIcon width={24} height={24} />
              &nbsp;Update Scheduled
            </div>
            <UncontrolledTooltip target="schedule-tag">
              {dateTimeFormatter(scheduleDate, 'long', 'short') + " (" + timezone?.replace("_", " ") + ")"}
            </UncontrolledTooltip>
            {/* <div className='small-font d-flex align-items-center'>
            {dateTimeFormatter(scheduleDate, 'long', 'short') + " (" + timezone?.replace("_"," ") +")"}
            <div className='ml-50'>
              {permissions?.manageFirmware?.update &&
              <EditPen width={28} alt="edit" className='cursor-pointer'
              onClick={() => {
                navigate(`/organization/${activeOrgId}/fwupdates`, {
                  state:{
                    filter:{"venues":[venueId]}
                  }
                })
              }}/>}
            </div>
          </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

UpdateLink.propTypes = {};

UpdateLink.defaultProps = {};

export default UpdateLink;
