import { useState } from "react";
import { Modal, ModalBody, ModalHeader, Pagination, PaginationItem, PaginationLink, Spinner } from "reactstrap";
import Expand from '../../../assets/images/icons/ExpandIcon.svg';
import './InfraImageModal.scss'

const InfraImageModal = (props) => {
    const { visible, setVisible, imgs } = props
    const [active, setActive] = useState(0);
    const [keys, setKeys] = useState(Object.keys(imgs ?? {}));
    const [expandActive, setExpandActive] = useState(0);
    const [selImg, setSelImg] = useState(null);
    const [loading, setLoading] = useState(false);

    return (
        <Modal className="InfraImageModal" centered isOpen={visible} toggle={() => setVisible(false)}>
            <ModalHeader className="bg-white" toggle={() => setVisible(false)}>
            </ModalHeader>
            <ModalBody>
                <div className="d-flex flex-column align-items-center">
                    <div className="bg-white  rounded  mt-2 mb-1" style={{ position: "relative", height: "300px", overflow: "hidden" }}>
                        <div className="d-flex justify-content-center align-items-center" style={{ height: "250px", width: "100%" }}>

                            {(imgs) ?
                                <img src={imgs[keys[active]]} onLoad={() => setLoading(false)} className={"ap-img " + (loading ? "d-none" : "")} />
                                : "No Images Available"}
                        </div>
                        <div className="cursor-pointer" style={{ position: "absolute", right: 10, bottom: 10 }}>

                        </div>
                    </div>


                    <div
                        className="d-flex align-items-center justify-content-between mt-50 "
                        style={{ width: "50%", minWidth: '50%' }}>
                        <span
                            className="material-symbols-outlined text-primary cursor-pointer"
                            style={{ fontSize: "1.8rem" }}
                            onClick={() => { if (active > 0) { setLoading(true); setActive(prevState => prevState - 1); } }}
                        >chevron_left</span>

                        <div className="d-flex align-items-center">
                            {keys.map((img, index) => {
                                return (
                                    <div className={`${index == active ? 'selected-img-dot' : 'grey-img-dot'}`} />
                                )
                            })}
                        </div>

                        <span
                            className="material-symbols-outlined text-primary cursor-pointer"
                            style={{ fontSize: "1.8rem" }}
                            onClick={() => { if (active < (keys?.length - 1)) { setLoading(true); setActive(prevState => prevState + 1); } }}
                        >chevron_right</span>
                    </div>
                </div>
            </ModalBody>
        </Modal >
    )
}

export default InfraImageModal;