import { suborgsActions } from "../slices";
import store from "../store";
import createRequest, { services } from "../../services";
import { CatchedWebError } from "../../configs";

const getSubOrgs = (orgId, offset, limit, search="",overwrite=false, setHasMore = () => {}, switchableOrgIds = new Set(), isPA = false, sort={order:"ASC",orderBy:"orgId"}, filter) => {
  store.dispatch(suborgsActions.setLoading(true));
  const { controller, run } = createRequest(services.organization.GET_CHILDREN, [orgId, 1, offset, limit, search, false, sort, filter]);
  run()
    .then(response => {
      response.data = response?.data?.map(subOrg => {
        return {...subOrg, 
          switchAllowed: (subOrg?.status =="Accepted" && (switchableOrgIds.has(subOrg?.orgId) || isPA))}
      })
      if(overwrite)
        store.dispatch(suborgsActions.setSubOrgs(response.data));
      else
        store.dispatch(suborgsActions.addSubOrgs({ data: response.data, offset: offset }));
      setHasMore(response.data.length === limit);
    })
    .catch(err => {
      let x = new CatchedWebError(err);
      store.dispatch(suborgsActions.setError(x.message));
      setHasMore(false);
    })
    .finally(() => {
      store.dispatch(suborgsActions.setLoading(false));
      store.dispatch(suborgsActions.setIsOutdated(false));
    });
  return controller;
}

export default getSubOrgs;