/***
 *
 * Controller class for user.
 * @file SelectionDisplayPills.js
 * @description SelectionDisplayPills component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React from "react";
// import PropTypes from 'prop-types';
import "./SelectionDisplayPills.scss";
import { Badge } from "reactstrap";
import { X } from "react-feather";

const SelectionDisplayPills = (props) => {
  return (
    <div className="SelectionDisplayPills" data-testid="SelectionDisplayPills">
      {props.selectAll?<Badge className='badgeSize' color="primary" pill>
                        <div className="d-flex align-items-center badgeContentDiv">
                          Selected All&nbsp;
                          <X className='cursor-pointer' onClick={ () => {
                            props.selectedSetter([]);
                            props.setSelectAll(false);
                          }}/>
                        </div>
                      </Badge>:
      props.selected?.map((instance,i)=>{
            return <span key={i}>
                      <Badge className='badgeSize' color="primary" pill>
                        <div className="d-flex align-items-center badgeContentDiv">
                          {instance?.orgDisplayName}&nbsp;
                          <X className='cursor-pointer' onClick={ () => {
                            props.selectedSetter(props.selected.filter(org => org.orgId !== instance.orgId))
                          }}/>
                        </div>
                      </Badge>
                      &nbsp;
                    </span>
          })}
    </div>
  );
};

SelectionDisplayPills.propTypes = {};

SelectionDisplayPills.defaultProps = {};

export default SelectionDisplayPills;