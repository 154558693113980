import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ChartCardWithProgress from "./ChartCardWithProgress";
import Venue from "../../../assets/images/icons/DashVenue.svg"
import { useEffect, useState } from "react";

const VenueChart = ({ series, trend, isLoading, labelStyle, total, main, setMain, height, className, onMetricClick, onTotalClick, singleLine = false }) => {
  const activeOrgId = useSelector(state => state.activeOrg.data.orgId);
  const permissions = useSelector(store => store?.rbac?.permissions);
  const navigate = useNavigate();
  const [mainColor, setMainColor] = useState('');
  const [invertTrend, setInvertTrend] = useState(false);
  useEffect(() => {
    //  Series = [ActiveAlarms, Online, Offline,Pending]
    // main = [0,1,2,3]
    let mainIndex = main || 0 // if main==null use 0

    if ((mainIndex == 0 || mainIndex == 2) && series[mainIndex] > 0) { // for Active Alarms and offline color
      setMainColor('text-danger')
    }
    else if (mainIndex == 1 && series[mainIndex] > 0) { // for online color
      setMainColor('text-success')
    }
    else {
      setMainColor('')
    }

    if(mainIndex == 0 || mainIndex == 2 || mainIndex == 3)
      setInvertTrend(true);
    else
      setInvertTrend(false)

  }, [series, main])
  return <ChartCardWithProgress className={className ?? ""} mainColor={mainColor} invertTrend={invertTrend} icon={Venue} labelStyle={labelStyle} height={height}
    trend={trend} main={main ?? 0} setMain={setMain} isLoading={isLoading} title="Venue" totalVal={total}
    colors={["#CE529C", "rgba(50, 201, 151, 1)", "rgba(206, 82, 156, 1)", "rgba(136, 136, 136, 1)"]}
    labels={["Active Alarms", "Online", "Offline", "Pending"]} series={series} singleLine={singleLine}
    onMetricClick={onMetricClick} onTotalClick={onTotalClick}
    AddAction={
      permissions?.manageVenue?.create
        ? () => {
          navigate(`/organization/${activeOrgId}/venues/add`);
        }
        : null
    }
    SearchAction={
      permissions?.manageVenue?.view
        ? () => {
          navigate(`/organization/${activeOrgId}/venues`);
        }
        : null
    }
  />
}

export default VenueChart;