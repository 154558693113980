import { createSlice } from "@reduxjs/toolkit"


const rebootingSlice = createSlice({
    name: "rebooting",
    initialState: {
        data:{
            "0":{}
        },
        status:{}
    },
    reducers: {
        setRebooting(state, action) {
            state.data[action.payload.infraId] = action.payload;
        },
        setStatus(state, action){
            state.status = action.payload
        },
        clearRebooting(state, action) {
            if(!!state.data[action.payload.infraId])
                delete state.data[action.payload.infraId]
        },
    }
})


export const rebootingReducer = rebootingSlice.reducer;
const rebootingAction = rebootingSlice.actions;
export default rebootingAction;