/**
 * Text-Input
 * @file TextInput.js
 * @description Formik compatible text-input styled with reactstrap components.
 * @author Utkarsh Gupta
 * @since 29 Jun 2022
 */

import { useField } from "formik";
import React from "react";
import { FormFeedback } from "reactstrap";
import PropTypes from 'prop-types';
import "./PasswordInput.scss";
import InputPasswordToggle from '@components/input-password-toggle';

const PasswordInput = ({label,ignoreTouch = false, ...props}) => {
  const [field, meta] = useField({...props, type: 'text'});
  return (
    <div className="PasswordInput" data-testid="PasswordInput">
      
      <InputPasswordToggle invalid={!!((ignoreTouch || meta.touched) && meta.error)} {...field} {...props}  />
      {/* {(meta.touched && meta.error) ? (
        <FormFeedback>{meta.error}</FormFeedback>
      ) : null} */}
      {((ignoreTouch || meta.touched) && meta.error)? <span className="text-danger password-required">{meta.error}</span> : null
      }
    </div>
  );
};

PasswordInput.propTypes = {
  label: PropTypes.string,
  props: PropTypes.any
};

PasswordInput.defaultProps = {};

export default PasswordInput;
