/***
 *
 * Controller class for user.
 * @file SwitchHeader.js
 * @description SwitchHeader component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import React from "react";
import PropTypes from 'prop-types';
import { Tabs } from "../../../../components";
import { useSelector } from "react-redux";

const SwitchHeader = () => {
  const permissions = useSelector(store => store?.rbac?.permissions);
  const venuePermissions = useSelector(store => store?.rbac?.venuePermissions);
  const infraData = useSelector(store => store.oneInfra.data);
  const features = useSelector(store => store.oneInfra.features);
  const infraId = useSelector(store => store.oneInfra.data.infraItemId);
  const venueId = useSelector(store => store.oneInfra.data.venueId);
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId)
  return (
    <div className="SwitchHeader" data-testid="SwitchHeader">
      <div className="d-flex align-items-center">
      </div>
      <Tabs data={[
        {
          path: `/organization/${activeOrgId}/infra/${infraId}/`,
          text: "Overview"
        },
        ...permissions?.manageInfrastats?.view ?
          [
            //   {
            //   path: `/organization/${activeOrgId}/infra/${infraId}/insight/`,
            //   text: "Insight",
            // },
            {
              path: `/organization/${activeOrgId}/infra/${infraId}/events/`,
              text: "Timeline",
            }] : [],
        ...(venuePermissions[venueId]?.device?.view == null ? permissions?.manageDevice?.view : venuePermissions[venueId]?.device?.view) ?
          [{
            path: `/organization/${activeOrgId}/infra/${infraId}/clients/`,
            text: "Clients"
          }] : [],
        ...(venuePermissions[venueId]?.infra?.update == null ? permissions?.manageInfra?.update : venuePermissions[venueId]?.infra?.update) ?
          [{
            path: `/organization/${activeOrgId}/infra/${infraId}/l2vlans`,
            text: "L2-VLANs"
          },
          // ...(features?.routesSupport)?
          // [{
          //   path: `/organization/${activeOrgId}/infra/${infraId}/networking`,
          //   text: "Networking"
          // }]:[],
          // ...features?.ieee8021xSupport?
          // [{
          //   path: `/organization/${activeOrgId}/infra/${infraId}/8021x`,
          //   text: "802.1x"
          // }]:[],
          ...features?.utilitySupport?.enable ?
            [{
              path: `/organization/${activeOrgId}/infra/${infraId}/utilities/`,
              text: "Utilities"
            }] : []
          ] : [],
        ...permissions?.manageInfrastats?.view ?
          [{
            path: `/organization/${activeOrgId}/infra/${infraId}/settings`,
            text: "Settings"
          }] : []
      ]} />
    </div>
  );
};

SwitchHeader.propTypes = {
  heading: PropTypes.string,
  activeOrgId: PropTypes.number,
  infraId: PropTypes.number
};

SwitchHeader.defaultProps = {};

export default SwitchHeader;
