/***
 *
 * Controller class for user.
 * @file Permissions.js
 * @description Permissions component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import React from "react";
// import PropTypes from 'prop-types';
import "./Permissions.scss";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, Modal, ModalBody, Row, Spinner, Table, UncontrolledButtonDropdown } from "reactstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import createRequest, { services } from '../../../services';
import { useEffect } from "react";
import { CatchedWebError } from "../../../configs";
import { FilterSetter, MaterialSymbol, SearchIconAddon, VeryFancyLoader } from "../../../components";
import { Link, useNavigate } from "react-router-dom";
import { breadcrumbActions } from "../../../redux/slices";
import moment from "moment";
import { make_custom_toast } from "../../../helpers/toasts";
import { createErrorContext } from "../../../configs/ErrorContextMaker";
import { TICKET_CATEGORY } from "../../Tickets/TicketConstants";
import { SCROLL_MORE_TEXT } from "../../../utility/constants";
import GroupButton from "../../../components/GroupButton";
import FilterButton from "../../../components/FilterButton";
import { ReactComponent as Permission } from "../../../assets/images/icons/Permissions.svg";
import { ReactComponent as Identity } from "../../../assets/images/icons/Identity.svg";
import { FilterRoles, IdentityRolesFilter, IdentityStatusFilter } from "../../Filters/filters";
import lodash from "lodash-es"


let timer;

const debounce = (callback) => {
  clearTimeout(timer);
  timer = setTimeout(callback, 800);
}

const EllipsedText = ({ list, maxWidth }) => {
  const listString = list.toString();
  return (
    listString.length > maxWidth
      ? <span title={listString} style={{ whiteSpace: 'nowrap' }}>{listString.replaceAll(',', ", ").substring(0, maxWidth)},...</span>
      : <span style={{ whiteSpace: 'nowrap' }}>{listString.replaceAll(',', ", ")}</span>
  );
}

const Roles = ({ roleIds, maxWidth }) => {
  const roles = useSelector(store => store.rbac.data.systemRoles);
  const roleNames = roleIds.map(roleId => {
    return roles?.find(role => role.roleId === roleId)?.role ?? ''
  })

  return <EllipsedText list={roleNames} maxWidth={maxWidth} />
}

const Actions = ({ identity, setDeleteModal }) => {
  return (
    <UncontrolledButtonDropdown direction="left">
      <DropdownToggle color='white' className="smallWidth">
        <MaterialSymbol value="more_vert" className='text-primary' />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-right">
        <DropdownItem tag='span' onClick={() => { setDeleteModal(identity?.identityId) }}>Delete</DropdownItem>
        <DropdownItem tag='span' onClick={() => { }}>{identity?.blocked ? 'Enable' : 'Disable'}</DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
}


const filterInitial = {
  roles: [],
  status: 'all'
}

const Permissions = () => {
  const activeVenue = useSelector(store => store.activeVenue.data);
  const venuecrumb = useSelector(store => store.breadcrumb.venuecrumb);
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const loggedInId = useSelector(state => state.identity.data.identityId);
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState('');
  const [identities, setIdentities] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(null)
  const [filterData, setFilterData] = useState(filterInitial)
  const [mainFilter, setMainFilter] = useState('All')
  const [mainFilterActive, setMainFilterActive] = useState(false)
  const [permissionCount, setPermissionCount] = useState({

    all: 0,
    venuesOnly: 0,
    disabled: 0

  })

  const dispatch = useDispatch();
  const navigate = useNavigate()

  const fetchIdentities = (venueId, limit = 20, offset = 0, search = '', filter = filterData) => {
    const { run } = createRequest(services.organization.GET_VENUE_IDENTITIES, [venueId, limit, offset, search, mainFilter, filter]);
    return run
  }
  const fetchIdentitiesCount = (venueId, search) => {
    const { run } = createRequest(services.organization.GET_VENUE_IDENTITY_COUNT, [venueId, search]);
    run()
      .then(res => {
        setPermissionCount(res.data)
      })
      .catch(err => {

      })
  }



  useEffect(() => {
    if (!!activeVenue.venueId) {
      fetchIdentitiesCount(activeVenue.venueId, search)
      setHasMore(true)
      const run = fetchIdentities(activeVenue.venueId, 20, 0, search);
      run().then(response => {
        setIdentities(response.data);
        if (response?.data?.length < 20)
          setHasMore(false)
        else
          setHasMore(true)
      })
        .catch(err => {
          setError(((new CatchedWebError(err)).message));
        })
        .finally(() => {
          setLoading(false);
        })

    }
  }, [activeVenue.venueId, mainFilter, mainFilterActive])


  useEffect(() => {
    if (search === '') {
      if (!!activeVenue.venueId) {
        fetchIdentitiesCount(activeVenue.venueId, search)
        setHasMore(true)
        const run = fetchIdentities(activeVenue.venueId, 20, 0, search);
        run().then(response => {
          setIdentities(response.data);
          if (response?.data?.length < 20)
            setHasMore(false)
          else
            setHasMore(true)
        })
          .catch(err => {
            setError(((new CatchedWebError(err)).message));
          })
          .finally(() => {
            setLoading(false);
          })

      }

      return
    }
    const delay = setTimeout(() => {
      if (search && (search.length > 0)) {
        if (!!activeVenue.venueId) {
          fetchIdentitiesCount(activeVenue.venueId, search)
          setHasMore(true)
          const run = fetchIdentities(activeVenue.venueId, 20, 0, search);
          run().then(response => {
            setIdentities(response.data);
            if (response?.data?.length < 20)
              setHasMore(false)
            else
              setHasMore(true)
          })
            .catch(err => {
              setError(((new CatchedWebError(err)).message));
            })
            .finally(() => {
              setLoading(false);
            })

        }
      }
    }, 1000)
    return () => clearTimeout(delay)
  }, [search])

  const handleMainFilterClick = (value) => {
    setMainFilter(value)
    setFilterData(filterInitial)
    setMainFilterActive(false)
  }

  const Name = ({ name, id, disabled }) => {
    const [hovering, setHovering] = useState(false);
    if (disabled) return name
    return (
      <Link className={hovering ? 'text-decoration-underline' : ''}
        to={`${id}`} onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        {name}
      </Link>
    );
  }

  const changeStatus = (identityId, blocked) => {
    const { context, run } = createRequest(services.identity.BLOCK, [identityId], { blocked: !blocked });
    run()
      .then(res => {
        setIdentities(ps => {
          return ps.map(identity => {
            if (identity.identityId === identityId)
              return { ...identity, blocked: !blocked }
            else return identity
          })
        })
        const action = blocked ? 'Enabled' : 'Disabled'
        make_custom_toast('success', 'Updating Identity', action + ' successfully')
      })
      .catch(err => {
        let message = (new CatchedWebError(err)).message
        let apiContext = createErrorContext(context, 'Unable to Update Identity', TICKET_CATEGORY.ORGANIZATION, err)
        make_custom_toast('error', 'Updating Identity', message, true, 'Create Ticket', () => {
          navigate(
            `/organization/${activeOrgId}/support/ticket/createticket/${apiContext.action}/${apiContext.category}`,
            {
              state: {
                ticketContext: apiContext,
              },
            }
          );
        })
      })
  }

  const handleDelete = (identityId) => {
    const { context, run } = createRequest(services.identity.DELETE, [identityId])
    run()
      .then(res => {
        setIdentities(ps => ps.filter(identity => identity.identityId !== identityId))
        setDeleteModal(null)
        make_custom_toast('success', 'Deleting Identity', 'Deleted successfully')
      })
      .catch(err => {
        let x = new CatchedWebError(err);
        const apiContext = createErrorContext(context, 'Unable to delete identity', TICKET_CATEGORY.ORGANIZATION, err)
        setDeleteModal(null);
        make_custom_toast('error', 'Identity', x.message, true, 'Create Ticket', () => {
          navigate(
            `/organization/${activeOrgId}/support/ticket/createticket/${apiContext.action}/${apiContext.category}`,
            {
              state: {
                ticketContext: apiContext,
              },
            }
          );
        })
      })
  }

  return (
    <div className="Permissions" data-testid="Permissions">

      {
        showFilter &&
        <FilterSetter
          setShowFilter={setShowFilter}
          showFilter={showFilter}
          filter={filterData}
          setFilter={setFilterData}
          disabled={!Object.keys(filterData).length}
          handleApplyClick={() => {
            const run = fetchIdentities(activeVenue.venueId, 20, 0, search);
            run().then(response => {
              setIdentities(response.data);
              if (response?.data?.length < 20)
                setHasMore(false)
              else
                setHasMore(true)
            })
              .catch(err => {
                setError(((new CatchedWebError(err)).message));
              })
              .finally(() => {
                setLoading(false);
              })
            setShowFilter(false)
            if (lodash.isEqual(filterData, filterInitial))
              setMainFilterActive(false)
            else
              setMainFilterActive(true);
          }}
          handleClearAll={
            () => {
              setFilterData({})
              const run = fetchIdentities(activeVenue.venueId, 20, 0, search, {});
              run().then(response => {
                setIdentities(response.data);
                if (response?.data?.length < 20)
                  setHasMore(false)
              })
                .catch(err => {
                  setError(((new CatchedWebError(err)).message));
                })
                .finally(() => {
                  setLoading(false);
                })
              setShowFilter(false)
              setMainFilterActive(false);
            }
          }
          elements={() => [
            <FilterRoles filterData={filterData} setFilterData={setFilterData} />,
            <IdentityStatusFilter filter={filterData} setFilter={setFilterData} />
          ]}
        />
      }

      <Modal centered isOpen={!!deleteModal} toggle={() => { setDeleteModal(null) }}>
        <ModalBody className="text-center p-1">

          <div className="text-center">
            <span className="material-symbols-outlined text-warning display-2">error</span>
          </div>
          <h3 className="font-weight-bolder mt-1 mb-2">Are you sure?</h3>
          <div className="d-flex justify-content-center mt-2">
            <Button color="danger" outline onClick={() => { setDeleteModal(null) }}>Cancel</Button>
            <Button color="primary" className="ml-1" onClick={() => { handleDelete(deleteModal) }}>Yes</Button>
          </div>
        </ModalBody>
      </Modal>
      {loading
        ? <div className="d-flex justify-content-center align-items-center w-100 h-100"><VeryFancyLoader /></div>
        : <>
          <div className="d-flex align-items-center mb-1">
            <Permission width={24} height={24} />
            <div className="fw-600 ml-1">Permissions</div>
          </div>
          <div className="d-flex w-100">

            <div className="mr-2" style={{ width: '350px' }} >
              {/* 
              <SearchBox style={{ height: '2.4rem' }} onChange={(e) => {
                if (e.target.value.length === 0 || e.target.value.length > 2)
                  debounce(() => setSearch(e.target.value));
              }} /> */}
              <InputGroup className="input-group-merge ">
                <Input
                  style={{ height: '2.4rem' }}
                  type="text"
                  value={search}
                  placeholder="Search"
                  onChange={(e) => {
                    setSearch(e.target.value)
                  }}
                />
                <SearchIconAddon />
              </InputGroup>
            </div>
            <GroupButton>
              <div className={"grp-btn-custom " + (mainFilter == "All" && !mainFilterActive ? "active" : "")} onClick={() => { if (!loading) handleMainFilterClick("All") }} >All ({permissionCount?.all || 0})</div>
              <div className={"grp-btn-custom " + (mainFilter == "VenueOnly" && !mainFilterActive ? "active" : "")} onClick={() => { if (!loading) handleMainFilterClick("VenueOnly") }} >Venue Only ({permissionCount?.venuesOnly || 0})</div>
              <div className={"grp-btn-custom " + (mainFilter == "Disabled" && !mainFilterActive ? "active" : "")} onClick={() => { if (!loading) handleMainFilterClick("Disabled") }} >Disabled ({permissionCount?.disabled || 0})</div>
            </GroupButton>
            <FilterButton className="ml-1" size={22} active={mainFilterActive} onClick={() => setShowFilter(true)}
              style={{ padding: '6.5px' }} />
          </div>

          <InfiniteScroll
            className="mt-2 "
            dataLength={identities.length}
            next={() => {
              const run = fetchIdentities(activeVenue.venueId, 20, identities.length, search);
              run()
                .then(response => {
                  setIdentities((prevState) => {
                    return [...prevState, ...response.data]
                  });
                  if (response.data.length < 20)
                    setHasMore(false);
                })
                .catch(err => {
                  setError(((new CatchedWebError(err)).message));
                })
                .finally(() => {
                  setLoading(false);
                })
            }}
            scrollableTarget="venue-permission-list"
            scrollThreshold={0.8}
            hasMore={hasMore}
            loader={<div>{SCROLL_MORE_TEXT}</div>}
            endMessage={<div className="mb-2">Showing {identities.length} result(s)</div>}
          >
            <div id="venue-permission-list">
              <Table className="table-view fixed-header">
                <thead>
                  <tr>
                    <th style={{ width: '20%' }}>Identity</th>
                    <th style={{ width: '25%' }}>Role</th>
                    <th style={{ width: '16%' }}>Status</th>
                    <th style={{ width: '16%' }}>Permission level</th>
                    <th style={{ width: '16%' }}>Last Login</th>
                    <th style={{ width: '5%' }}></th>
                  </tr>
                </thead>
                <tbody>
                  {identities?.length > 0 ? identities.map((identity, key) => {
                    return (
                      <tr className="" key={key}>
                        <td>
                          <div className="d-flex align-items-center">
                            <Identity width={16} height={16} className="mr-1" />
                            <Name name={identity?.userName ?? ''} id={identity?.identityId} disabled={identity?.blocked} />
                          </div>
                        </td>
                        <td><Roles roleIds={identity.roleIds} maxWidth={25} /></td>
                        {/* <td><EllipsedText list={identity.venues ?? []} maxWidth={15} /></td> */}
                        <td>{identity.blocked ? 'Disabled' : 'Enabled'}</td>
                        <td>{identity.venueLevelPermission ? 'Multiple Venues' : 'Organization'}</td>
                        <td>{!!identity.lastLogin ? moment(identity.lastLogin).fromNow() : ''}</td>
                        <td>
                          <UncontrolledButtonDropdown className="p-0" direction="left" disabled={identity.identityId === loggedInId}>
                            <DropdownToggle color='white' className="p-0">
                              <MaterialSymbol value="more_vert" className='text-primary' />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-right">
                              <DropdownItem tag='span' onClick={() => { setDeleteModal(identity?.identityId) }}>Delete</DropdownItem>
                              <DropdownItem tag='span' onClick={() => { changeStatus(identity?.identityId, identity?.blocked) }}>
                                {identity?.blocked ? 'Enable' : 'Disable'}</DropdownItem>
                            </DropdownMenu>
                          </UncontrolledButtonDropdown>
                        </td>
                      </tr>
                    )
                  })
                    : <tr >
                      <td colSpan={6}>
                        <div className="text-center w-100 mt-2"><h4>No identities</h4></div>
                      </td>
                    </tr>
                  }

                </tbody>
              </Table>
            </div>
          </InfiniteScroll>

        </>}
    </div>
  );
};

Permissions.propTypes = {};

Permissions.defaultProps = {};

export default Permissions;
