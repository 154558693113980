/***
 *
 * Controller class for user.
 * @file CableDropList.js
 * @description CableDropList component
 * @author Rajinder Singh
 * @since 29 Apr 2024
 *
 */

import React from "react";
// import PropTypes from 'prop-types';
import "./CableDropList.scss";
import { Col, Row } from "reactstrap";
import CableDropIcon from "../../pages/Venue/FloorPlan2/Icons/CableDropIcon";
import { COLORS } from "../../pages/Venue/FloorPlan2/constants";
import { CableDropIcon as ReactComponent } from '../../assets/images/icons/cable_drop_color.svg'

const CableDropList = (props) => {
  return (
    <div className="CableDropList" data-testid="CableDropList">
      <h4 className="p-1 m-0 border-bottom">Available Cable Drop</h4>

      <div
        draggable

        className="single-cable d-flex align-items-center rounded shadow-sm bg-white p-1 m-1"
        style={{ width: '170px' }}
        onDragStartCapture={(e) => {
          let flyImage = document.getElementById('cable-fly-image')
          e.dataTransfer.setDragImage(flyImage, 0, 0)
        }}
      >

        <img src={require('../../assets/images/icons/cable-drop.webp')} height={'20px'} width={'20px'} className="mr-50 " />
        <span className="text-primary">Network Outlet</span>
        {/* <CableDropIcon svgProps={{ width: '26px', height: '26px' }} color={COLORS.PRIMARY} /> */}
      </div>

    </div >
  );
};

CableDropList.propTypes = {};

CableDropList.defaultProps = {};

export default CableDropList;
