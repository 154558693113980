/***
 *
 * Controller class for user.
 * @file Details.js
 * @description Details component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Col, Row, Spinner } from "reactstrap";
import { breadcrumbActions, oneInfraActions } from "../../../redux/slices";
import REDUX_WORKER from "../../../redux/workers";
import createRequest, { services } from "../../../services";
import { ReactComponent as Uptime } from "../../../assets/images/icons/DetailsTime.svg";
import { ReactComponent as CPU } from "../../../assets/images/icons/DetailsCPU.svg";
import { ReactComponent as FW } from "../../../assets/images/icons/Firmware.svg";
import { ReactComponent as Memory } from "../../../assets/images/icons/DetailsMem.svg";
import { ReactComponent as IP } from "../../../assets/images/icons/DetailsIP.svg";
import Header from "../_builder/Header";
import "./Details.scss";
import { timeDiff } from "../SwitchOverview";
import LightBadge from "../../../components/LightBadge";
import { ipFormater } from "../InfraList";

export const UPTIME = (sec = 0, upperOnly = false, timeStamp = false, shortHand = false) => {
  if(timeStamp){
    sec = (Date.now() - new Date(sec))/1000; 
  }
  const years = Math.floor(sec / 31540000);
  let secsRemaining = sec % (31540000);

  let string = "";

  if (years > 0) {
    if(!shortHand) {
      if (years === 1) {
        string += "1 Year, ";
      }
      else
        string += years + " Years, ";
    }
    else {
      string += years + " Yr(s), "
    }
    if (upperOnly)
      return string.slice(0, string.length - 2);
  }

  const months = Math.floor(secsRemaining / 2628000);
  secsRemaining = secsRemaining % 2628000;

  if (months > 0) {
    if(!shortHand) {
      if (months === 1) {
        string += "1 Month, ";
      }
      else {
        string += months + " Months, ";
      }
    }
    else {
      string += months + " mo(s), ";
    }
    if (upperOnly)
      return string.slice(0, string.length - 2);
  }

  // const days = Math.floor(secsRemaining / (60 * 60 * 24));
  const days = Math.floor(secsRemaining / 86400);
  secsRemaining = secsRemaining % 86400;

  if (days > 0) {
    if(!shortHand) {
      if (days === 1) {
        string += "1 Day, ";
      }
      else {
        string += days + " Days, ";
      }
    }
    else {
      string += days + " Day(s), ";
    }
    if (upperOnly)
      return string.slice(0, string.length - 2);
  }

  // const hours = Math.floor(secsRemaining / (60 *60));
  const hours = Math.floor(secsRemaining / 3600);
  secsRemaining = secsRemaining % 3600;

  if (hours > 0) {
    // string += (hours.toLocaleString("en-US",{minimumIntegerDigits:2}) || "00") + " Hr(s), "
    string += (hours.toLocaleString("en-US") || "0") + (!shortHand ? 
      hours === 1 ? " Hour, " : " Hours, "
      : " Hr(s), ")
    if (upperOnly)
      return string.slice(0, string.length - 2);
  }

  const minutes = Math.floor(secsRemaining / 60);
  secsRemaining = secsRemaining % 60;

  if (minutes > 0) {
    string += (minutes.toLocaleString("en-US") || "0") + (!shortHand?
      (minutes === 1 ? " Minute, " : " Minutes, ")
    : " Min(s), ")
    if (upperOnly)
      return string.slice(0, string.length - 2);
  }

  string += (Math.floor(secsRemaining).toLocaleString("en-US") || "0") + (!shortHand?
    (minutes === 1 ? " Second" : " Seconds")
    : " Sec(s)")

  return string;
}


const Details = () => {
  //const infraData = useSelector(store => store.oneInfra.data);
  const infraDisplayName = useSelector(store => store.oneInfra.data.infraDisplayName);
  const connected = useSelector(store => store.oneInfra.data.connected);
  const infraItemId = useSelector(store => store.oneInfra.data.infraItemId);
  const realInfra = useSelector(store => store?.oneInfra?.data?.realInfra);
  const macAddress = useSelector(store => store.oneInfra.data.macAddress);
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const details = useSelector(store => store.oneInfra.details.data);
  const detailsLoading = useSelector(store => store.oneInfra.details.loading);
  const [fwVersion, setFwVersion] = useState("");
  const [bannedRadio, setBannedRadio] = useState({});
  const loading = useSelector(store => store.oneInfra.status.loading);
  const error = useSelector(store => store.oneInfra.details.error);
  const permissions = useSelector(store => store?.rbac?.permissions)
  const dispatch = useDispatch();

  // GET DETAILS
  useEffect(() => {
    if (macAddress && realInfra) {
      const x = setInterval(() => REDUX_WORKER.getDetails(macAddress), 30000);
      return () => {
        clearInterval(x);
      }
    }
  }, [macAddress]);

  // BREADCRUMBS
  useEffect(() => {
    dispatch(breadcrumbActions.setData([
      {
        path: `/organization/${activeOrgId}/infra`,
        text: "Infrastructure",
        active: false
      },
      {
        text: infraDisplayName,
        active: false
      }, {
        path: `/organization/${activeOrgId}/infra/${infraItemId}/details/`,
        text: "Details",
        active: true
      }
    ]))
  }, [])

  // GET FIRMWARE VERSION
  useEffect(() => {
    if (macAddress) {
      const { run } = createRequest(services.fwupgrade.GET_VERSION, [macAddress]);

      run().then((response) => {
        if (response.data != "Unknown")
          setFwVersion("Release " + response.data);
      })

      const { run: getRadio } = createRequest(services.networks.BANNED_RADIO, [infraItemId]);

      getRadio().then((response) => {
        setBannedRadio(response.data);
      })
    }

    return (() => {
      dispatch(oneInfraActions.setDetailsError(null));
    })
  }, [macAddress, dispatch]);


  return (
    <div className="Details" data-testid="Details">
      <Header heading={infraDisplayName} />
      <Alert color="danger" isOpen={!!error && connected} toggle={() => dispatch(oneInfraActions.setDetailsError(null))}>
        <div className="alert-body">{error}</div>
      </Alert>
      {!loading ? <div className="bg-white rounded p-1 pb-2 border shadow-sm">
        <div>
          <div className="d-flex align-items-center">
            <h4 className="m-0 p-0 headings">{infraDisplayName}</h4>&nbsp;&nbsp;
            <Button.Ripple disabled={detailsLoading} size="sm" color="flat-primary" className="btn-icon rounded-circle" onClick={() => { if (realInfra) REDUX_WORKER.getDetails(macAddress); }}>
              <span className="material-symbols-outlined color-primary">sync</span>
            </Button.Ripple>
          </div>
          {connected && <small>Last Updated {timeDiff(details.updatedAt)} ago</small>}
          {!connected && <small className="text-secondary">*This infrastructure is not online.</small>}
        </div>
        <Row className="mt-1">
          <Col sm={12} md={6} lg={4} className="d-flex align-items-center py-1">
            <div className="d-flex align-items-center">
              <div className="p-2">
                <Uptime />
              </div>
              <div>
                <h5 className="m-0">Up Time</h5>
                <div className="font-weight-bolder">{connected ? UPTIME(details?.upTime) : "Not Available"}</div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={6} lg={4} className="d-flex align-items-center py-1">
            <div className="d-flex align-items-center">
              <div className="p-2">
                <CPU />
              </div>
              <div>
                <h5 className="m-0">Load</h5>
                <div className="font-weight-bolder">
                  {connected ?
                    details?.load+"%" : "Not Available"
                  }
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={6} lg={4} className="d-flex align-items-center py-1">
            <div className="d-flex align-items-center">
              <div className="p-2">
                <Memory />
              </div>
              <div>
                <h5 className="m-0">Memory</h5>
                <div className="font-weight-bolder">
                  {connected ?
                    details?.totalMemory ?
                      ((details?.usedMemory / details?.totalMemory) * 100).toFixed(2) + "%" :
                      "0.00%" : "Not Available"
                  }</div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={6} lg={4} className="d-flex align-items-center py-1">
            <div className="d-flex align-items-center">
              <div className="p-2">
                <IP />
              </div>
              <div>
                <h5 className="m-0">Private IP Address</h5>
                <div className="font-weight-bold">{(connected && ipFormater(details?.ipv4?.split("/")[0])) || "Not Available"}</div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={6} lg={4} className="d-flex align-items-center py-1">
            <div className="d-flex align-items-center">
              <div className="p-2">
                <FW className="fill-black" width="100%" height="100%" style={{ height: "22px", width: "22px" }} />
              </div>
              <div>
                <h5 className="m-0">FW Release</h5>
                <div className="font-weight-bolder">{(fwVersion.length > 0) ? fwVersion.replace("Unknown",``) : "Not Available"}</div>
              </div>
            </div>
          </Col>
        </Row>
        {
          details?.radios?.length > 0 &&
          <div>
            <h4 className="mt-2">Radios</h4>
            <Row className="mt-1 pl-1">
              {
                Object.keys(bannedRadio)?.map(it => {
                  if(!bannedRadio[it])
                    return <>
                      <Col sm={12} md={3}>
                        <div className="radios">
                          <div className="d-flex justify-content-between mb-1">Band <span className="font-weight-bolder pr-2"><LightBadge color="primary">{it}</LightBadge></span></div>
                          <div className="d-flex justify-content-between mb-1">Status <span className="font-weight-bolder pr-2">Disabled</span></div>
                        </div>
                      </Col>
                    </>
                  else {
                    let radio = details?.radios?.find(rad => rad.band == it);
                    if(radio != null)
                      return <>
                        <Col sm={12} md={3}>
                          <div className="radios">
                            {radio.band && <div className="d-flex justify-content-between mb-1">Band <span className="font-weight-bolder pr-2"><LightBadge color="primary">{radio.band}</LightBadge></span></div>}
                            {connected &&
                              <>
                                <div className="d-flex justify-content-between mb-1">Status <span className="font-weight-bolder pr-2">{bannedRadio[radio.band]?"Enabled": "Disabled"}</span></div>
                                {radio.channel && <div className="d-flex justify-content-between mb-1">Channel <span className="font-weight-bolder pr-2">{radio.channel}</span></div>}
                                {radio.channelWidth && <div className="d-flex justify-content-between mb-1"> Channel Width <span className="font-weight-bolder pr-2">{radio.channelWidth} MHz</span></div>}
                                {radio.txPower && <div className="d-flex justify-content-between mb-1">Tx Power <span className="font-weight-bolder pr-2">{radio.txPower} dBm</span></div>}
                                {radio.noise && <div className="d-flex justify-content-between mb-1">Noise <span className="font-weight-bolder pr-2">{radio.noise} dB</span></div>}
                              </>
                            }
                          </div>
                        </Col>
                      </>  
                  }
                })
              }
            </Row>
          </div>
        }
      </div> :
        <div className="text-center py-5">
          <Spinner color="primary" />
        </div>}
    </div>
  );
};

Details.propTypes = {};

Details.defaultProps = {};

export default Details;
