import { createSlice } from "@reduxjs/toolkit";

const filterInitialState = {
  status: {
    apply: false,
  }
};

const filterSlice = createSlice({
  name: "filter",
  initialState: filterInitialState,
  reducers: {
    setApply: (state, action) => {
      state.status.apply = action.payload;
    }
  }
});

export const filterReducer = filterSlice.reducer;
const filterActions = filterSlice.actions;
export default filterActions;

