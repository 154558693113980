/***
 *
 * Controller class for user.
 * @file FilterButton.js
 * @description FilterButton component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React from "react";
// import { Filter } from "react-feather";
// import PropTypes from 'prop-types';
import "./FilterButton.scss";
import { ReactComponent as FilterIcon } from "../../assets/images/icons/FilterIcon.svg";

const FilterButton = ({
  active,
  onClick,
  className,
  size = 24,
  disabled = false,
  style = {},
}) => {
  return (
    <div
      className={`FilterButton rounded ${
        disabled ? "cursor-not-allowed" : "cursor-pointer"
      } ${active ? "" : disabled? "bg-light-secondary": "bg-white"} ${className ?? ""}`}
      style={style}
      background={active ? "blue" : ""}
      data-testid="FilterButton"
      onClick={() => {
        if(!disabled)
          onClick()
        }
      }
    >
      {/* {
        active && <div className="filter-active"></div>
      } */}
      <FilterIcon height={size} width={size} />
    </div>
  );
};

FilterButton.propTypes = {};

FilterButton.defaultProps = {};

export default FilterButton;
