import PropTypes from "prop-types";
import "./AnalysisEditor.scss";
import { motion } from "framer-motion";
import StaticLayoutRenderer from "../../StaticLayoutRenderer";
import { Alert, Button, Spinner } from "reactstrap";
import { useCallback, useState } from "react";
import createRequest from "../../../../../services";
import { wre } from "../../../../../services/wre.service";
import { CatchedWebError } from "../../../../../configs";

/**
 * Snapping Editor
 * @param {{
 *   layoutManager: object,
 *   layout: {
*     "venueId": number,
*     "name": string,
*     "layoutType": 1 | 2 | 3,
*     "layoutFile": string,
*     "infraPositions": Array<{
*       "infra_type_id": number,
*       "x": number,
*       "y": number
*     }>?,
*     "layoutJson": {
*       "dimensions": {
*         "length": number,
*         "width": number,
*         "height": number
*         "area": number
*       }?,
*       "walls": Array<{
*         "id": string,
*         "loc": Array<number>,
*         "material": string
*       }>,
*       "isActive": boolean,
*       "id": number,
*       "createdAt": string,
*       "updatedAt": string
*     }
*   }
 * }} props 
 * @returns 
 */
const AnalysisEditor = (props) => {
  const layoutId = props.layout?.id;
  const [result, setResult] = useState(null);
  const [resultLoading, setResultLoading] = useState(false);
  const [resultError, setResultError] = useState(null);

  const runApPlacement = useCallback(() => {
    const { run } = createRequest(wre.ANALYSE, [layoutId]);
    setResult(null);
    setResultError(null);
    setResultLoading(true);
    run()
      .then(response => {
        setResult(response.data);
      })
      .catch(err => {
        const x = new CatchedWebError(err);
        setResultError(x.message);
        console.log(err);
      })
      .finally(() => {
        setResultLoading(false);
      })
  }, [layoutId]);

  return (
    <motion.div
      className="AnalysisEditor"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.15 }}
    >
      <motion.div
        className="map-area"
        initial={{ scale: 1.1, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.3, delay: 0.3 }}
      >{
          props.layout &&
          <StaticLayoutRenderer completeLayout={props?.layout} layout={props?.layout?.layoutJson} height="100%" width="100%" hideBackGrid={true} showAP showLegend />
        }
      </motion.div>
      {/* <motion.div
        className="selector-area p-2"
        initial={{ right: -10, opacity: 0 }}
        animate={{ right: 0, opacity: 1 }}
        transition={{ duration: 0.3, delay: 0.3 }}
      >
        <div className="display-3 text-center">Placement</div>
        <div className="display-3 text-center">Analysis</div>

        <Alert color="secondary" className="p-1 mt-1">Layout shown on the left will be used for placement analysis. Make sure you have saved changes to the layout before continuing.</Alert>
        <div className="d-flex flex-column align-items-center">
          {!resultLoading && !resultError && !result &&
            <Button className="mt-3" size="lg" color="primary" onClick={() => { runApPlacement() }}>Analyse Placements</Button>
          }
          {resultLoading && <><Spinner className="mt-3" /><div className="mt-1">Calculating Score...</div></>}
          {resultError && <><div className="text-danger text-center mt-3 mb-1">{resultError}</div><Button color="primary" size="sm" onClick={() => { runApPlacement() }}>Retry?</Button></>}
          {!resultError && !resultLoading && result &&
            <>
              <h2 className="display-2 p-1"><strong>{result?.zone_score?.toFixed(2) ?? 0}%</strong></h2>
              <Button color="primary" size="sm" onClick={() => { runApPlacement() }}>Retry?</Button>
            </>
          }
        </div>
      </motion.div> */}
    </motion.div>
  );
};

AnalysisEditor.propTypes = {
  layoutManager: PropTypes.object,
  layout: PropTypes.object
};
AnalysisEditor.defaultProps = {};

export default AnalysisEditor;