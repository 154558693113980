/***
 *
 * Controller class for user.
 * @file Toggle.js
 * @description Toggle component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React from "react";
// import PropTypes from 'prop-types';
import "./Toggle.scss";

const Toggle = (props) => {
  const {className = ""} = props
  return (
    <div className={"Toggle "+ className} data-testid="Toggle">
    <div className="d-flex flex-column" >
    {props.label
    ? <div>
        {props.label}
        {props.isrequired && <span className="text-danger">*</span>}
      </div>
    : null}
      {props.value ?  
      <div className="d-flex align-items-center user-select-none">
        <div 
          className={`${props.disabled?'cursor-not-allowed':'cursor-pointer'} material-symbols-outlined text-${props.disabled ? "secondary" : props.color} toggle-font ${props.noToggleStyle?'':'mr-1'}`} 
          style={{color:props.color}}
          onClick={ () => {
            if(!props.disabled)
              props.onClick();
          }
        }
        >
          toggle_on
        </div>
        {(props.displayText && !!props.customText)
          ? props.customText[props.value]??null
          : null}
      </div> :
        <div className="d-flex align-items-center user-select-none">
          <div 
            className={`${props.disabled?'cursor-not-allowed':'cursor-pointer'} material-symbols-outlined text-secondary toggle-font ${props.noToggleStyle?'':'mr-1'}`}
            onClick={
              () => {
                if(!props.disabled)
                  props.onClick();
              }
            }
          >
            toggle_off
          </div>
          {props.displayText && props.customText[props.value]}
        </div>
        }
      </div>
    </div>
  );
};

Toggle.propTypes = {};

Toggle.defaultProps = {
  displayText:true, color:"primary", customText:{[true]: "ON", [false]: "OFF"}
};

export default Toggle;
