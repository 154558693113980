/***
 *
 * Controller class for user.
 * @file Port802.js
 * @description Port802 component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React, { useCallback } from "react";
// import PropTypes from 'prop-types';
import "./Port802.scss";
import { useState } from "react";
import * as Yup from 'yup';
import { Formik } from "formik";
import { Button, Col, Form, Input, Label, Row } from "reactstrap";
import { PasswordInput, TextInput, Toggle } from "../../../components";
import { ToolTip } from "../../Networks/Authentication";
import { useEffect } from "react";
import Select from "react-select";
import { useMemo } from "react";
import {ReactComponent as Warning} from "../../../assets/images/icons/Warning.svg";

export const options = [
  { value: 'venueRadius', label: 'Venue Radius Location' },
  { value: 'custom', label: 'Custom' },
  { value: 'none', label: 'None' },
]

export const NasIDCheck = (nas) => {
  if(nas == null)
    return "none";
  if(nas.length>0)
    return "custom";
  if(nas.length == 0)
    return "venueRadius";
  
    return "none";
}

const Port802 = (props) => {
  const [coaEnabled,setCoaEnabled] = useState((props?.data?.radius && !!props?.data?.radius["coa-server-addr"])?true:false);
  const [disable802, setDisable802] = useState(Object.keys(props?.data?.radius??{}).length>0?false:true);
  const [useRadsec, setUseRadsec] = useState(props.data.radSec??false);
  const [nasIdCustom,setNasIdCustom] = useState((props?.data?.radius)?NasIDCheck(props?.data?.radius["nas-identifier"]):"none");
  const handleOnChange = (values) => {
    handleChanges("",null,null,{...values, coa_enabled:!coaEnabled});
    setCoaEnabled(!coaEnabled);
  }

  useMemo(() => {
      props.setDisable(disable802);
  },[disable802]);


  useEffect(() => {
    if(!disable802 && !useRadsec){
      document.getElementsByName("primary_server_ip")[0]?.focus();
    }
    else{
      if(coaEnabled)
        setCoaEnabled(false)
    }
  },[disable802, useRadsec])

  useEffect(() => {
    if(coaEnabled)
      document.getElementsByName("coa_ip")[0]?.focus()
  },[coaEnabled])

  useEffect(() => {
    if(nasIdCustom == "custom")
      document.getElementsByName("nas_id")[0]?.focus()
  },[nasIdCustom])

  const handleChanges = (setField, identifier, value, values, radSec = useRadsec) => {  
    let intValues = {...values};
    if(setField){
      setField(identifier, value);
      intValues[identifier] = value
    }
      props.setter(prevState => {
      let new802 = {
        "use-local-certificates": true,
        "mode": "radius",
        "ports" :[],
        "radSec": radSec,
        radius:{
          "auth-server-addr": intValues.primary_server_ip,
          "auth-server-port": Number(intValues.primary_auth_port),
          "auth-server-secret": intValues.primary_shared_secret,
          "acct-server-addr": intValues.primary_server_ip,
          "acct-server-port": Number(intValues.primary_accounting_port),
          "acct-server-secret": intValues.primary_shared_secret,
        }
      }
      if(nasIdCustom != "none" && !!intValues.nas_id)
        new802.radius["nas-identifier"] = intValues.nas_id;

      if(values.coa_enabled) {
        new802.radius["coa-server-addr"]= intValues.coa_ip
        new802.radius["coa-server-port"]= Number(intValues.coa_port)
        new802.radius["coa-server-secret"]= intValues.coa_secret
      }
    
      return {...prevState,
        ieee8021x:new802,
        services:{
          ...prevState.services,
          ieee8021x:new802
          }
        }
    })
  }

  return (
    <div className="Port802" data-testid="Port802">
    <div>
      <Formik
        initialValues={{
          primary_server_ip: props?.data?.radius?props?.data?.radius["acct-server-addr"]:"",
          primary_auth_port: props?.data?.radius?props?.data?.radius["auth-server-port"]:1812,
          primary_accounting_port: props?.data?.radius?props?.data?.radius["acct-server-port"]:1813,
          primary_shared_secret: props?.data?.radius?props?.data?.radius["acct-server-secret"]:"",
          interim_update: 600,
          nas_id: props?.data?.radius?props?.data?.radius["nas-identifier"]:"",
          coa_ip: props?.data?.radius?props?.data?.radius["coa-server-addr"]:"",
          coa_port: props?.data?.radius?props?.data?.radius["coa-server-port"]:3799,
          coa_secret: props?.data?.radius?props?.data?.radius["coa-server-secret"]:"",
          coa_enabled: (props?.data?.radius && !!props?.data?.radius["coa-server-addr"])?true:false
        }}
        validationSchema={Yup.object({
          primary_server_ip: (!disable802 && !useRadsec)?Yup.string().required("Required")
          .matches(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,"Please enter valid ip address"): Yup.string(),
          primary_auth_port: (!disable802 && !useRadsec)?Yup.number().required("Required")
          .min(1024,"Must atleast be 1024")
          .max(65535,"Can atmost be 65535"): Yup.string(),
          primary_accounting_port:(!disable802 && !useRadsec)?Yup.number().required("Required")
          .min(1024,"Must atleast be 1024")
          .max(65535,"Can atmost be 65535"): Yup.string(),
          primary_shared_secret: (!disable802 && !useRadsec)?Yup.string().required("Required")
          .min(1,"Must have atleast 1 character")
          .max(32,"Secret must be less than 32 characters"): Yup.string(),
          interim_update: !disable802?Yup.number()
          .min(60,"Must atleast be 60")
          .max(6000,"Maximum value is 6000")
          .nullable(true): Yup.string(),
          nas_id: (!disable802 && nasIdCustom=="custom")?Yup.string().required("Required").max(32,"Maximum length is 32 characters"): Yup.string().nullable(true),
          coa_ip: coaEnabled? Yup.string().required("Required")
          .matches(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,"Please enter valid ip address")
          : Yup.string(),
          coa_port: coaEnabled? Yup.number().required("Required")
          .min(1024,"Must atleast be 1024")
          .max(65535,"Can atmost be 65535")
          : Yup.string(),
          coa_secret: coaEnabled? Yup.string().required("Required")
          .min(1,"Must have atleast 1 character.")
          .max(32,"Secret must be less than 32 characters.")
          : Yup.string(),
          coa_enabled: Yup.boolean().required("Required")
                        
                      })}
        >
      {({isSubmitting, handleSubmit, values, setFieldValue, errors, resetForm, validateForm, validateField})=><Form onSubmit={(event)=>{
        event.preventDefault();
        handleSubmit();}}
    >

      <div>
        <div className="rounded shadow-sm bg-white p-2 mt-1 pb-4">
          <h5 className="font-weight-bold mb-0">802.1X Settings</h5>
          <div>
            <Row className="mt-1">
              <Col md={2} xs={3}>
                <div>Enable</div>
                <Toggle value={!disable802} disabled={props.disabled} onClick={() => {
                  if(!disable802){
                      //disabling 802
                      props.setter(prevState => {
                        return {...prevState, 
                          ieee8021x:{},
                          services:{
                          ...prevState.services,
                          ieee8021x:{}
                        }}
                      }) 
                      resetForm();
                    }
                    else{
                      handleChanges(setFieldValue,"","",values);
                    }
                    props.setPortData((prevState) => {
                      let portData = prevState;
                      Object.values(portData).map(port => {
                        port.Ports.map(item => {
                          // console.log(item)
                          item.ieee8021x = disable802 
                        } )
                      })
                      return portData; 
                    })
                    setDisable802(prevState => !prevState); 
                  }} displayText={false}/>
              </Col>
              <Col md={2} xs={3}>
                <div>Use RadSec</div>
                <Toggle disabled={props.disabled || disable802} displayText={false} 
                  value={useRadsec} onClick={() => {
                    handleChanges(null,"","",values,!useRadsec)
                    if(!useRadsec)
                      setTimeout(() => validateForm(), 100);
                    setUseRadsec(prevState => !prevState); 
                  }}/>
              </Col>
            </Row>
            {!useRadsec ? <Row className="mb-2">
              <Col md={2} xs={3}>
                <TextInput labelfontauth={true} tabIndex="0" disabled={disable802} isrequired={true} type="text" name="primary_server_ip" label="AAA Server IP" placeholder="x.x.x.x" onChange={(e)=>handleChanges(setFieldValue, "primary_server_ip", e.target.value, values)}/>
              </Col>
              <Col md={2} xs={3} className=''>
                <TextInput labelfontauth={true} disabled={disable802} isrequired={true} type="number" name="primary_auth_port" label="Authentication Port" placeholder="1812" onChange={(e)=>handleChanges(setFieldValue, "primary_auth_port", e.target.value, values)}/>
              </Col>
              <Col md={2} xs={3}>
                <TextInput labelfontauth={true} disabled={disable802} isrequired={true} type="number" name="primary_accounting_port" label="Accounting Port" placeholder="1813" onChange={(e)=>handleChanges(setFieldValue, "primary_accounting_port", e.target.value, values)}/>
              </Col>
              <Col md={2} xs={3}>
                <label className="labelfontauth" for="primary_shared_secret">Shared Secret<span className="text-danger">*</span></label>
                <PasswordInput autoComplete="one-time-code" name="primary_shared_secret" label="Shared Secret" placeholder="xxxxx" disabled={disable802} onChange={(e)=>handleChanges(setFieldValue, "primary_shared_secret", e.target.value, values)}/>
              </Col>
            </Row>:
            <div className="py-2 d-flex align-items-center">
              <Warning width={22} height={22} />
              <span className="font-weight-bolder pl-50">RadSec must be configured under the RadSec tab to work properly.</span>
            </div>}
          </div>

          <h5 className="font-weight-bold mb-0">Advanced Settings</h5>
          <div className="mt-1">
            <Row className="mb-2">
              <Col md={2} xs={3}>
                <label
                  className="labelfontauth"
                  for="nas-id-string"
                >
                    NAS Type
                </label>
                <Select
                  classNamePrefix='select'  isDisabled={disable802}
                  defaultValue={options.find( it => it.value == nasIdCustom)}
                  options={options}
                  isClearable={false}
                  isSearchable={false}
                  id='nas-id-string'
                  onChange={
                    (option) => {
                        console.log("option", option)
                        setNasIdCustom(option.value);
                        if(option.value == "none")
                          handleChanges(setFieldValue, "nas_id", null, values);
                        else
                          handleChanges(setFieldValue, "nas_id", "", values);
                        setTimeout(() => validateField("nas_id"),0);
                      }
                    }
                />
              </Col>
              <Col md={2} xs={3}>
                <TextInput autoComplete="one-time-code" tabIndex="0" isrequired labelfontauth={true} disabled={disable802 || nasIdCustom != "custom"} type="text" name="nas_id" label="NAS ID" placeholder="nasID" onChange={(e)=>handleChanges(setFieldValue, "nas_id", e.target.value, values)}/>
              </Col>
              <Col>
                {/* <TextInput labelfontauth={true} disabled={disable802} type="number" name="interim_update" label="Interim Update (in seconds)" placeholder="600" onChange={(e)=>handleChanges(setFieldValue, "interim_update", e.target.value, values)}/> */}
              </Col>
            </Row>
            <div className="mt-1">
              <h5 className="font-weight-bold mb-0">Enable CoA</h5>
              <Toggle disabled={disable802} value={coaEnabled} displayText={false}
                onClick={()=>{
                  setFieldValue("coa_enabled",!values.coa_enabled);
                  handleOnChange(values);
                  if(coaEnabled)
                    setTimeout(() => validateForm(), 100);
                  }
                }/>
            </div>
            <Row>
              <Col md={2} xs={3}>
                <TextInput labelfontauth={true} tabIndex="0" disabled={disable802 || !coaEnabled} isrequired={true} type="text" name="coa_ip" label="CoA IP" placeholder="x.x.x.x" onChange={(e)=>handleChanges(setFieldValue, "coa_ip", e.target.value, values)}/>
              </Col>
              <Col md={2} xs={3}>
                <TextInput labelfontauth={true} disabled={disable802 || !coaEnabled} isrequired={true} type="number" name="coa_port" label="CoA Port" placeholder="3379" onChange={(e)=>handleChanges(setFieldValue, "coa_port", e.target.value, values)}/>
              </Col>
              <Col md={2} xs={3}>
                <Label className="label" for="coa_secret">CoA Secret<span className="text-danger">*</span></Label>
                <PasswordInput autoComplete="one-time-code" name="coa_secret" label="Secret" placeholder="xxxxx" disabled={disable802 || !coaEnabled} onChange={(e)=>handleChanges(setFieldValue, "coa_secret", e.target.value, values)}/>
              </Col>
            </Row>
          </div>
        </div>
      </div>
        {/* {console.log("---->", errors)} */}
        {Object.keys(errors).length>0 ? props.setValid("radius") : props.valid === "radius" ? props.setValid("") : null}
        </Form>
        }
      </Formik>
      </div>
    </div>
  );
};

Port802.propTypes = {};

Port802.defaultProps = {};

export default Port802;