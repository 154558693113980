/***
 *
 * Controller class for user.
 * @file Timeline.js
 * @description Timeline component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import React, { Suspense, lazy, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Input, InputGroup, Row, Spinner, Table, UncontrolledTooltip } from "reactstrap";
import { ColumnHeader, FilterSetter, HealthTimeline, SearchIconAddon } from "../../../components";
import { breadcrumbActions } from "../../../redux/slices";
import store from "../../../redux/store";
import REDUX_WORKER from "../../../redux/workers";
import { isoDate } from "../../Dashboard/Graphs/Utils";
// import { Timeline as TimelineSVG } from "../../../components/HealthTimeline";
// import PropTypes from 'prop-types';
import Header from "../_builder/Header"
import "./Timeline.scss";
import blazer from "../../../services/blazer.service";
import Blazer from "../../../redux/slices/blazer.slice";
import useTimedCaller from "../../Dashboard/NewDashboard/useTimedCaller";
import { dateTimeFormatter } from "../../../utility/Localization";
import LightBadge from "../../../components/LightBadge";
import { impactColor } from "../../Infrastructure/Events";
import { TIMELINE_BACKGROUND } from "../../../components/HealthTimeline";
import GroupButton from "../../../components/GroupButton";
import createRequest, { services } from "../../../services";
import { make_custom_toast } from "../../../helpers/toasts";
import { CatchedWebError } from "../../../configs";
import { capitalizeWord, dateGapFinder, downloadSpreadsheet } from "../../../utility/Utils";
import lodash from "lodash-es"
import { UPTIME } from "../../Infrastructure/Details";
import FilterButton from "../../../components/FilterButton";
import { TimelineEventFilter } from "../../Filters/filters";
import { ReactComponent as CsvIcon } from "../../../assets/images/icons/xls_icon.svg";
import { Search } from "react-feather";
import AlarmDesc from "../../../components/AlarmDesc";

const DateRangeSelector = lazy(() => import("../../Dashboard/Graphs/DateRangeSelector"));
const RefreshButton = lazy(() => import("../../Dashboard/Graphs/RefreshButton"));
const Skeleton = lazy(() => import("../../Dashboard/Graphs/Skeleton"));

const initialEventFilter = {
  events: []
}

const Timeline = () => {

  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const range = useSelector(store => store.activeOrg.meta.dateRange);
  const eventRef = useSelector(store => store.venue_events)
  const eventCountRef = useSelector(store => store.venue_event_count)
  const healthRef = useSelector(store => store.venue_health)
  const activeVenue = useSelector(store => store.activeVenue.data);
  const venuecrumb = useSelector(store => store.breadcrumb.venuecrumb);
  const identity = useSelector(store => store.identity);
  const [initialRun, setInitialRun] = useState(true) //to check if this component is rendering first time
  const [events, setEvents] = useState(JSON.parse(eventRef.data)?.eventsTrend);

  const [forceRefresh, setForceRefresh] = useState(false);
  const [cumulative, setCumulative] = useState(false);
  const [active, setActive] = useState(-1);
  const [sort, setSort] = useState({
    order: "DESC",
    orderBy: "timestamp"
  })
  const [timelineFilter, setTimelineFilter] = useState('All')
  const [timelineSearch, setTimelineSearch] = useState('')
  const [timelineCount, setTimelineCount] = useState({
    all: 0,
    critical: 0,
    major: 0,
    minor: 0
  })

  const [showFilter, setShowFilter] = useState(false);
  const [eventFilterActive, setEventFilterActive] = useState(false)
  const [eventFilterData, setEventFilterData] = useState(initialEventFilter)
  const [downloadingList, setDownloadingList] = useState(false)

  useTimedCaller({ service: blazer.VENUE_EVENTS, params: [activeVenue.venueId, isoDate(range), isoDate(), cumulative, new Date().getTimezoneOffset(), timelineFilter, timelineSearch, sort, eventFilterData], data: {} }, range, activeVenue.venueId, state => state.venue_events, Blazer.venue_events.actions, forceRefresh, setForceRefresh);
  useTimedCaller({ service: blazer.VENUE_EVENT_COUNT, params: [activeVenue.venueId, isoDate(range), isoDate(), cumulative, new Date().getTimezoneOffset(), timelineSearch], data: {} }, range, activeVenue.venueId, state => state.venue_event_count, Blazer.venue_event_count.actions, forceRefresh, setForceRefresh);
  useTimedCaller({ service: blazer.VENUE_HEALTH, params: [activeVenue.venueId, isoDate(range), isoDate(), cumulative, new Date().getTimezoneOffset(), sort], data: {} }, range, activeVenue.venueId, state => state.venue_health, Blazer.venue_health.actions, forceRefresh, setForceRefresh);


  const handleDownload = () => {
    const apiURL = services.telemetry.DOWNLOAD_VENUE_EVENTS.urlBuilder(...[
      activeVenue?.venueId,
      timelineSearch,
      timelineFilter,
      eventFilterData,
      isoDate(range),
      isoDate(),
      new Date().getTimezoneOffset(),
      sort
    ])
    const fileName = `Venue Events List${!!timelineFilter && !eventFilterActive? ('-(' + capitalizeWord(timelineFilter) + ')') : ''}${activeVenue?.venueId != null ? ('-(' + activeVenue?.venueId + ')') : ''}.xlsx`;
    setDownloadingList(true)
    downloadSpreadsheet(apiURL, fileName, identity.meta.token)
    .catch(err => {
      make_custom_toast('error',`Export Events List`,(new CatchedWebError(err)).message)
    })
    .finally(() => {
      setDownloadingList(false)
    })
  }


  useEffect(() => {
    setTimelineCount(JSON.parse(eventCountRef.data))
  }, [eventCountRef])

  useEffect(() => {
    setForceRefresh(true);
  }, [sort])

  useEffect(() => {
    setEvents(JSON.parse(eventRef.data)?.eventsTrend)
  },[eventRef])


  useEffect(() => {
    let delayDebounceFn
    if (!initialRun) {
      delayDebounceFn = setTimeout(() => {
        setForceRefresh(true)
      }, 800) //wait for 1.5 seconds
    }
    setInitialRun(false)
    return () => clearTimeout(delayDebounceFn)
  }, [timelineSearch])


  useEffect(() => {
    if (venuecrumb.venueId !== activeVenue.venueId)
      REDUX_WORKER.getVenueParent(activeVenue.venueId, activeOrgId);

    store.dispatch(breadcrumbActions.setData([...venuecrumb.parentPath,
    {
      path: `/organization/${activeOrgId}/venues/${activeVenue?.venueId}/`,
      text: activeVenue?.venueName,
      active: false,
    },
    {
      path: `/organization/${activeOrgId}/venues/${activeVenue?.venueId}/timeline`,
      text: "Timeline",
      active: true,
    }
    ]))

  }, [activeVenue, venuecrumb]);
  const handleMainFilterClick = (value) => {
    setTimelineFilter(value);
    setEventFilterData(initialEventFilter)
    setEventFilterActive(false);
    setShowFilter(false);
    setForceRefresh(true)
  }

  const onClip = (from, to) => {
    setTimelineFilter("")
    setEvents(prevState => {
      let newEvents = [...prevState.filter(it => new Date(it.label).getTime()>=from && new Date(it.label).getTime()<=to)]

      return [...newEvents];
    })
  }

  const revertClip = () => {
    setEvents(JSON.parse(eventRef.data)?.eventsTrend);
    setTimelineFilter("All")
  }

  return (
    <div className="Timeline" data-testid="Timeline">
      {
        showFilter &&
        <FilterSetter
          setShowFilter={setShowFilter}
          showFilter={showFilter}
          filter={eventFilterData}
          setFilter={setEventFilterData}
          disabled={!Object.keys(eventFilterData).length}
          handleApplyClick={() => {
            // handleMainFilterClick("All")
            if (lodash.isEqual(eventFilterData, initialEventFilter)) {
              setEventFilterActive(false);
            }
            else
              setEventFilterActive(true);
            setShowFilter(false)
            setForceRefresh(true)
          }}
          handleClearAll={
            () => {
              setEventFilterData({})
              setEventFilterActive(false);
              setShowFilter(false)
              setForceRefresh(true)

            }
          }
          elements={() => [
            <TimelineEventFilter filter={eventFilterData} setFilter={setEventFilterData} />,
          ]}
        />
      }
      <Row className="p-0 m-0">
        <Col className="p-0 m-0" xs={9}>
          <Header />
        </Col>
        <Col className="p-0 m-0 mt-50" xs={3}>
          <div className="d-flex justify-content-end">
            <Suspense fallback={<></>}>
              <DateRangeSelector />
            </Suspense>
            <Suspense fallback={<></>}>
              <RefreshButton clickState={forceRefresh} clickHandler={setForceRefresh} clickFunc={revertClip}/>
            </Suspense>
          </div>
        </Col>
      </Row>
      <div className="d-flex justify-content-end align-items-center">
        {/* <div className="cumulative">
          <span className={cumulative && "selected"} onClick={() => { setCumulative(true); setForceRefresh(true) }}>Cumulative</span>
          <span className={!cumulative && "selected"} onClick={() => { setCumulative(false); setForceRefresh(true) }}>Non-Cumulative</span>
        </div> */}

      </div>
      <div className="bg-white rounded p-1 ">
        <div style={{minHeight:"180px"}}>
          {(!eventRef.isLoading && !healthRef.isLoading)
            ? <Suspense fallback={<></>}>
                <HealthTimeline
                  bgColor={TIMELINE_BACKGROUND}
                  sectionColor="#1FC78F"
                  events={JSON.parse(eventRef.data)?.eventsTrend}
                  data={JSON.parse(healthRef.data)?.online}
                  minDate={range > 0 ? isoDate(range) : new Date(new Date().setDate(new Date().getDate() - 1))}
                  maxDate={new Date()}
                  active={active}
                  onClip={(from, to) => onClip(from, to)}
                  revertClip={revertClip}
                  setActive={setActive}
                  healthHeading="Venue Timeline"
                  eventHeading="Venue Events"
                  on="Venue" />
            </Suspense>
            : <Suspense fallback={<></>}><Skeleton height="160px" /></Suspense>}
          </div>

        {/* ---- Search Box --- */}
        <div className="d-flex justify-content-between mt-1">
          <div className="d-flex">
          <span className="mr-2" style={{ width: '350px' }}>
            <InputGroup className="input-group-merge ">
              <Input
                style={{ height: '2.4em' }}
                type="text"
                value={timelineSearch}
                placeholder="Search"
                onChange={(e) => {
                  setTimelineSearch(e.target.value)
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                  }
                }}

              />
              <SearchIconAddon />
            </InputGroup>
          </span>
          <GroupButton className="mr-1">
            <div className={"grp-btn-custom " + (timelineFilter == "All" && !eventFilterActive ? "active" : "")} onClick={() => { handleMainFilterClick("All") }} >All ({timelineCount?.all ?? 0})</div>
            <div className={"grp-btn-custom " + (timelineFilter == "Critical" && !eventFilterActive ? "active" : "")} onClick={() => { handleMainFilterClick("Critical") }} >Critical ({timelineCount?.critical ?? 0})</div>
            <div className={"grp-btn-custom " + (timelineFilter == "Major" && !eventFilterActive ? "active" : "")} onClick={() => { handleMainFilterClick("Major") }} >Major({timelineCount?.major ?? 0})</div>
            <div className={"grp-btn-custom " + (timelineFilter == "Minor" && !eventFilterActive ? "active" : "")} onClick={() => { handleMainFilterClick("Minor") }} >Minor ({timelineCount?.minor ?? 0})</div>
          </GroupButton>

          <FilterButton
            size={22}
            active={eventFilterActive}
            onClick={() => setShowFilter(true)}
            style={{ padding: '6.5px' }} />
          </div>
          <div className="d-flex align-items-center">
            <CsvIcon
              height={24}
              width={24}
              className={`${
                downloadingList
                  ? "list-download-icon--disabled"
                  : "cursor-pointer"
              }`}
              onClick={() => {
                if (!downloadingList) {
                  handleDownload()
                }
              }}
              title={`Export List\n(maximum limit: 5000)`}
            />
          </div>
        </div>
        <div>
          <div id="venue-timeline-list" className="mt-2">
            <Table className="fixed-header table-view">
              <thead>
                <tr>
                  <th style={{ width: "15%" }}>
                    <ColumnHeader header="TIME" attribute="timestamp" setter={setSort} sort={sort} />
                  </th>
                  <th style={{ width: "10%" }}>
                    <ColumnHeader header="Severity" attribute="impact" setter={setSort} sort={sort} />
                  </th>
                  <th style={{ width: "25%" }}>
                    <ColumnHeader header="Event" attribute="event" setter={setSort} sort={sort} />
                  </th>
                  <th style={{ width: "50%" }}>DETAILS</th>
                </tr>
              </thead>

              {eventRef.isLoading ?
                <tbody>
                  <tr>
                    <td colSpan={10} className="text-center p-5"><Spinner color="primary" /></td>
                  </tr>
                </tbody> :
                <tbody>
                  {(JSON.parse(eventRef.data)?.eventsTrend?.length > 0) ?
                    events?.map((event, eventIndex) => {
                      return (<tr>
                        {/* <td>{dateTimeFormatter(event.label, "medium", "short")}</td> */}
                        <td>
                          <span id={`timedate${eventIndex}`} className="cursor-pointer">
                            {UPTIME(event.label, true, true)} ago
                          </span>
                          <UncontrolledTooltip target={`#timedate${eventIndex}`} placement="right">
                            {dateTimeFormatter(event.label, "medium", "short")}
                          </UncontrolledTooltip>
                        </td>
                        <td><div className="d-flex"><LightBadge color={impactColor[event.impact] ?? "danger"}>{event.impact ?? "Critical"}</LightBadge></div></td>
                        <td>{event.display_name}</td>
                        <td><AlarmDesc alarmType={event.event_type} data={event.payload} desc={event.description} orgId={activeOrgId}/></td>
                      </tr>)
                    })
                    : <tr><td className="text-center p-5" colSpan={10}><h4>No Event found!</h4></td></tr>
                  }
                </tbody>}
            </Table>
          </div>
          <div className="mt-50">Showing {events?.length} result(s)</div>
        </div>
      </div>
    </div>
  );
};

Timeline.propTypes = {};

Timeline.defaultProps = {};

export default Timeline;
