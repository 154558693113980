import { createSlice } from "@reduxjs/toolkit";

const infraListInitialState = {
  status: {
    loading: false,
    error: null
  },
  data: [],
  ofOrgId: null
};

const infraListSlice = createSlice({
  name: "infraList",
  initialState: { ...infraListInitialState },
  reducers: {
    addItems: (state, action) => {
      if (!!state.data)
        state.data = [...state.data, ...action.payload]
      else
        state.data = [...action.payload];
    },
    setData: (state, action) => {
      state.data = action.payload
    },
    reset: (state) => {
      return { ...infraListInitialState };
    },
    setLoading: (state, action) => {
      state.status.loading = action.payload;
    },
    setError: (state, action) => {
      state.status.error = action.payload;
    },
    setStatus: (state, action) => {
      const ind = state.data.findIndex(pr => pr.infraItemId == action.payload.infraItemId);
      if (ind >= 0) {
        state.data[ind] = { ...state.data[ind], status: action.payload.status };
        state.data[ind] = { ...state.data[ind], alarm: action.payload.status === 'OFFLINE' ? true : false };
      }
    }
  }
});

export const infraListReducer = infraListSlice.reducer;
const infraListActions = infraListSlice.actions;
export default infraListActions;