import AccessPointIcon from "./AccessPointsIcon";
import AnalysisIcon from "./AnalysisIcon";
import CableDropIcon from "./CableDropIcon";
import DrawLinesIcon from "./DrawLinesIcon";
import SnappingIcon from "./SnappingIcon";
import SwitchIcon from "./SwitchIcon";
import WallAnnotationsIcon from "./WallAnnotationsIcon";

export const SidebarIcons = {
  AccessPointIcon,
  AnalysisIcon,
  SnappingIcon,
  WallAnnotationsIcon,
  DrawLinesIcon,
  CableDropIcon,
  SwitchIcon
};