/** This is a standalone Static layout renderer for drawing icons and representations of a layout, 
 * it is self contained (i.e. helper functions are included in this file) 
 * and is ported from a previous version of Layout Editor.
 * In older versions of Layout Editor, this component was known as `FullLayoutMaker`
 */

import PropTypes from "prop-types";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Layer, Line, Stage, Image, Group, Rect } from "react-konva";
// import { Spinner } from 'reactstrap';
import useImage from "use-image";

const CONNECTED_COLOR = "#31AF34";
const DISCONNECTED_COLOR = "#5279CE";


/**
 * Static Layout Renderer (centered on the viewport)
 * @param {{
 *   "layout": {
 *       "dimensions": {
 *         "length": number,
 *         "width": number,
 *         "height": number
 *         "area": number
 *       }?,
 *       "walls": Array<{
 *         "id": string,
 *         "loc": Array<number>,
 *         "material": string
 *       }>,
 *       "isActive": boolean,
 *       "id": number,
 *       "createdAt": string,
 *       "updatedAt": string
 *     },
 *   "width": number | string,
 *   "height": number | string,
 *   "bgColor": string
 * }} props 
 */
const FloorplanStaticRenderer = (props) => {
    const divRef = useRef(null);
    const stageRef = useRef(null)
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const [scaleFactor, setScaleFactor] = useState([1, 1]);
    const { layout } = props;
    const { completeLayout } = props
    const [blank_grid] = useImage(require('../../../../../assets/images/icons/blank_grid.webp'));
    const [zoom, setZoom] = useState(1)
    const [offsetX, setOffSetX] = useState(0)
    const [layoutImage] = useImage(props.imageSrc);
    const [apSize, setApSize] = useState(10)



    useEffect(() => {
        calcScaleFactor(layout, dimensions.width, dimensions.height, layoutImage, setZoom, setOffSetX, setApSize, true);

        window.addEventListener('resize', () => {
            if (divRef?.current?.offsetHeight && divRef?.current?.offsetWidth) {
                setDimensions({
                    width: divRef.current.offsetWidth,
                    height: divRef.current.offsetHeight
                })
            }
        });
        // return () => {
        //     window.removeEventListener('resize', () => { console.log('rdebug resize inside') });
        // };
    }, []);


    // We cant set the h & w on Stage to 100% it only takes px values so we have to
    // find the parent container's w and h and then manually set those 
    useEffect(() => {
        if (divRef?.current?.offsetHeight && divRef?.current?.offsetWidth) {
            setDimensions({
                width: divRef.current.offsetWidth,
                height: divRef.current.offsetHeight
            })
        }
    }, [divRef]);



    useEffect(() => {
        let y = calcScaleFactor(layout, dimensions.width, dimensions.height, layoutImage, setZoom, setOffSetX, setApSize);
        setScaleFactor(y);
    }, [dimensions, layout, layoutImage]);


    return (

        <div ref={divRef} style={{ width: props.width, height: props.height }}>

            <Stage
                scaleY={zoom}
                scaleX={zoom}
                offsetX={offsetX}
                ref={stageRef}
                style={{ backgroundColor: props.bgColor, borderRadius: "5px" }}
                width={dimensions.width}
                height={dimensions.height}>
                {
                    !props.hideBackGrid &&
                    <Layer>
                        <Image image={blank_grid} />
                    </Layer>
                }

                {
                    props.imageSrc &&
                    scaleImage(scaleFactor, layout, 5, dimensions.width, dimensions.height).map((plot, index) =>
                        < Layer >
                            <Image
                                image={layoutImage}
                                x={plot[0]}
                                y={plot[1]}
                                // offsetX={offsetX}
                                width={plot[2] - plot[0]}
                                height={(layoutImage?.height / layoutImage?.width) * (plot[2] - plot[0])}
                            />
                            {/* <Rect
                                key={index}
                                height={apSize}
                                width={apSize}
                                x={0}
                                y={0}
                                strokeWidth={2}
                                stroke={"#000"}
                                cornerRadius={8}
                                fill={"#000"}
                            /> */}

                        </Layer>
                    )
                }

                {
                    !props.imageSrc &&
                    <Layer>
                        {scalePoints(scaleFactor, layout, 5, dimensions.width, dimensions.height).map((line, index) => {
                            return (
                                <Line
                                    //  offsetX={offsetX}
                                    key={index} points={line} stroke="black" strokeWidth={3} lineCap="round" lineJoin="round" />
                            );
                        })}
                    </Layer>
                }
                {
                    props.showAP &&
                    <Layer>
                        {
                            scaleAP(scaleFactor, props.completeLayout, 5, dimensions.width, dimensions.height).map((newPoints, index) =>
                                <Rect
                                    key={index}
                                    height={apSize}
                                    width={apSize}
                                    x={newPoints[0]}
                                    y={newPoints[1]}
                                    // offsetX={offsetX}

                                    strokeWidth={2}
                                    stroke={"#FFCC18"}
                                    cornerRadius={8}
                                    fill={"#FFCC18"}
                                />)
                        }
                    </Layer>
                }

                {
                    props.showRealAp &&
                    <Layer>
                        {
                            scaleRealAP(scaleFactor, props.completeLayout, 5, dimensions.width, dimensions.height, props.infraItemId).map((newPoints, index) =>
                                <Rect
                                    key={index}
                                    height={apSize}
                                    width={apSize}
                                    x={newPoints[0]}
                                    y={newPoints[1]}
                                    // offsetX={offsetX}

                                    strokeWidth={2}
                                    stroke={newPoints[2]}
                                    cornerRadius={8}
                                    fill={newPoints[2]}
                                />)
                        }
                    </Layer>
                }
                {
                    props.showCableDrop &&
                    <Layer>
                        {
                            scaleCableDrop(scaleFactor, props.completeLayout, 5, dimensions.width, dimensions.height, props.infraItemId).map((newPoints, index) =>
                                <Rect
                                    key={index}
                                    height={apSize}
                                    width={apSize}
                                    x={newPoints[0]}
                                    y={newPoints[1]}
                                    // offsetX={offsetX}
                                    strokeWidth={2}
                                    stroke={"#ACACAC"}
                                    fill={"#ACACAC"}
                                />)
                        }
                    </Layer>
                }
            </Stage>

        </div >

    )
};

FloorplanStaticRenderer.propTypes = {
    layout: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    bgColor: PropTypes.string
};
FloorplanStaticRenderer.defaultProps = {
    bgColor: "white"
};


/// HELPER FUNCTIONS -- DO NOT USE THESE ELSEWHERE -- THESE ARE PORTED AND EXIST FOR LEGACY SUPPORT -- AND ARE MOSTLY UNDOCUMENTED

function extractPoints(layout) {
    let pointsList = [];
    for (const component of layout["components"])
        for (const wall of component["walls"]) {
            pointsList.push([wall["loc"][0], wall["loc"][1]]);
            pointsList.push([wall["loc"][2], wall["loc"][3]]);
        }
    return pointsList;
}
function extractApPoints(layout) {
    let pointsList = [];
    for (const infraPoint of layout["infraPositions"]) {
        pointsList.push([infraPoint.location.x, infraPoint.location.y]);
    }
    return pointsList;
}
function extractCableDrop(layout) {
    let pointsList = [];
    for (const infraPoint of layout["cableDrop"]) {
        pointsList.push([infraPoint.location.x, infraPoint.location.y]);
    }
    return pointsList;
}
function extractRealApPoints(layout, infraItemId = null) {
    let pointsList = [];

    if (infraItemId) { //if infraitemId is passed via props, then filter that single ap with this infraItemId. This is only used for Venue Infrastructure page
        let realAp = layout.realInfraPositions.filter((realInfra) => realInfra.infraItemId == infraItemId)
        let color = realAp[0].status == 'connected' ? CONNECTED_COLOR : DISCONNECTED_COLOR
        pointsList.push([realAp[0].location.x, realAp[0].location.y, color])
    }
    else {
        for (const infraPoint of layout["realInfraPositions"]) {
            let color = infraPoint.status == 'connected' ? CONNECTED_COLOR : DISCONNECTED_COLOR
            pointsList.push([infraPoint.location.x, infraPoint.location.y, color]);
        }
    }

    return pointsList;
}

function boundingBox(layout) {
    let points = extractPoints(layout);
    let minX = points[0][0], minY = points[0][1], maxX = points[0][0], maxY = points[0][1];
    for (let i = 1; i < points.length; i++) {
        minX = Math.min(points[i][0], minX);
        minY = Math.min(points[i][1], minY);
        maxX = Math.max(points[i][0], maxX);
        maxY = Math.max(points[i][1], maxY);
    }
    const x = [[minX, minY], [maxX, maxY]];
    return x;
}

export function calcScaleFactor(layout, width, height, layoutImage, setZoom, setOffSetX, setApSize, setApSizeInitially = false) {

    const [[minX, minY], [maxX, maxY]] = boundingBox(layout);
    const diffMax = Math.max(maxX - minX, maxY - minY);
    const scale = [Math.min(width, height) / diffMax, Math.min(height, width) / diffMax];
    let newZoom = (layoutImage?.width / layoutImage?.height).toFixed(1)



    let offset = 5
    const bbox = boundingBox(layout);

    const center = { x: (bbox[0][0] + bbox[1][0]) / 2, y: (bbox[0][1] + bbox[1][1]) / 2 };
    const nPointCenter = {
        x: (center.x - bbox[0][0]) * scale[0] * ((100 - offset * 2) / 100) + offset,
        y: (center.y - bbox[0][1]) * scale[1] * ((100 - offset * 2) / 100) + offset
    };
    const transformPoint = { x: width / 2 - nPointCenter.x, y: height / 2 - nPointCenter.y };
    let newLines = [];
    const points = extractPoints(layout);
    for (let i = 0; i < points.length; i += 2) {
        newLines.push([
            (points[i][0] - bbox[0][0]) * scale[0] * ((100 - offset * 2) / 100) + offset + transformPoint.x,
            (points[i][1] - bbox[0][1]) * scale[1] * ((100 - offset * 2) / 100) + offset + transformPoint.y,
            (points[i + 1][0] - bbox[0][0]) * scale[0] * ((100 - offset * 2) / 100) + offset + transformPoint.x,
            (points[i + 1][1] - bbox[0][1]) * scale[1] * ((100 - offset * 2) / 100) + offset + transformPoint.y
        ])
    }



    let scalledWidth = newLines[0][2] - newLines[0][0]

    //  we can use (scale/ scalled height of Image) for zoom scale of stage

    let x = newLines[0][0] //iniitial original value
    let xzoom = newLines[0][0] * newZoom //value after zoom
    let removedx = xzoom - x //diff in initial and zoomed

    if (layoutImage && setZoom) {
        setZoom(newZoom)
        setOffSetX((removedx / newZoom) + ((layoutImage?.width * (newZoom * 0.9)) / scalledWidth * 2))
        if (setApSizeInitially)
            setApSize(apSize => apSize / newZoom)
    }
    else {
        setOffSetX(0)
        setZoom(1)
        // setApSize(10)

    }

    return scale
}

function scalePoints(scaleFactor, layout, offset = 0, dimWidth, dimHeight) {
    const bbox = boundingBox(layout);
    const center = { x: (bbox[0][0] + bbox[1][0]) / 2, y: (bbox[0][1] + bbox[1][1]) / 2 };
    const nPointCenter = {
        x: (center.x - bbox[0][0]) * scaleFactor[0] * ((100 - offset * 2) / 100) + offset,
        y: (center.y - bbox[0][1]) * scaleFactor[1] * ((100 - offset * 2) / 100) + offset
    };
    const transformPoint = { x: dimWidth / 2 - nPointCenter.x, y: dimHeight / 2 - nPointCenter.y };
    let newLines = [];
    const points = extractPoints(layout);
    for (let i = 0; i < points.length; i += 2) {
        newLines.push([
            (points[i][0] - bbox[0][0]) * scaleFactor[0] * ((100 - offset * 2) / 100) + offset + transformPoint.x,
            (points[i][1] - bbox[0][1]) * scaleFactor[1] * ((100 - offset * 2) / 100) + offset + transformPoint.y,
            (points[i + 1][0] - bbox[0][0]) * scaleFactor[0] * ((100 - offset * 2) / 100) + offset + transformPoint.x,
            (points[i + 1][1] - bbox[0][1]) * scaleFactor[1] * ((100 - offset * 2) / 100) + offset + transformPoint.y
        ])
    }


    return newLines;
}

function scaleImage(scaleFactor, layout, offset = 0, dimWidth, dimHeight) {
    const bbox = boundingBox(layout);

    const center = { x: (bbox[0][0] + bbox[1][0]) / 2, y: (bbox[0][1] + bbox[1][1]) / 2 };
    const nPointCenter = {
        x: (center.x - bbox[0][0]) * scaleFactor[0] * ((100 - offset * 2) / 100) + offset,
        y: (center.y - bbox[0][1]) * scaleFactor[1] * ((100 - offset * 2) / 100) + offset
    };
    const transformPoint = { x: dimWidth / 2 - nPointCenter.x, y: dimHeight / 2 - nPointCenter.y };
    let newLines = [];
    const points = extractPoints(layout);
    for (let i = 0; i < points.length; i += 2) {
        newLines.push([
            (points[i][0] - bbox[0][0]) * scaleFactor[0] * ((100 - offset * 2) / 100) + offset + transformPoint.x,
            (points[i][1] - bbox[0][1]) * scaleFactor[1] * ((100 - offset * 2) / 100) + offset + transformPoint.y,
            (points[i + 1][0] - bbox[0][0]) * scaleFactor[0] * ((100 - offset * 2) / 100) + offset + transformPoint.x,
            (points[i + 1][1] - bbox[0][1]) * scaleFactor[1] * ((100 - offset * 2) / 100) + offset + transformPoint.y
        ])
    }
    return newLines;
}


function scaleAP(scaleFactor, layout, offset = 0, dimWidth, dimHeight) {
    const bbox = boundingBox(layout.layoutJson);  // boundary of the box from min points to max points
    const center = { x: (bbox[0][0] + bbox[1][0]) / 2, y: (bbox[0][1] + bbox[1][1]) / 2 }; //center of that box
    const nPointCenter = {
        x: (center.x - bbox[0][0]) * scaleFactor[0] * ((100 - offset * 2) / 100) + offset,
        y: (center.y - bbox[0][1]) * scaleFactor[1] * ((100 - offset * 2) / 100) + offset
    };
    const transformPoint = { x: dimWidth / 2 - nPointCenter.x, y: dimHeight / 2 - nPointCenter.y };
    let newPoints = [];
    const points = extractApPoints(layout);

    for (let i = 0; i < points.length; i++) {
        newPoints.push([
            (points[i][0] - bbox[0][0]) * scaleFactor[0] * ((100 - offset * 2) / 100) + offset + transformPoint.x,
            (points[i][1] - bbox[0][1]) * scaleFactor[1] * ((100 - offset * 2) / 100) + offset + transformPoint.y,
        ])
    }

    return newPoints;
}
function scaleRealAP(scaleFactor, layout, offset = 0, dimWidth, dimHeight, infraItemId = null) {
    const bbox = boundingBox(layout.layoutJson);  // boundary of the box from min points to max points
    const center = { x: (bbox[0][0] + bbox[1][0]) / 2, y: (bbox[0][1] + bbox[1][1]) / 2 }; //center of that box
    const nPointCenter = {
        x: (center.x - bbox[0][0]) * scaleFactor[0] * ((100 - offset * 2) / 100) + offset,
        y: (center.y - bbox[0][1]) * scaleFactor[1] * ((100 - offset * 2) / 100) + offset
    };
    const transformPoint = { x: dimWidth / 2 - nPointCenter.x, y: dimHeight / 2 - nPointCenter.y };
    let newPoints = [];
    const points = extractRealApPoints(layout, infraItemId);

    for (let i = 0; i < points.length; i++) {
        newPoints.push([
            (points[i][0] - bbox[0][0]) * scaleFactor[0] * ((100 - offset * 2) / 100) + offset + transformPoint.x,
            (points[i][1] - bbox[0][1]) * scaleFactor[1] * ((100 - offset * 2) / 100) + offset + transformPoint.y,
            points[i][2]
        ])
    }
    return newPoints;
}
function scaleCableDrop(scaleFactor, layout, offset = 0, dimWidth, dimHeight) {
    const bbox = boundingBox(layout.layoutJson);  // boundary of the box from min points to max points
    const center = { x: (bbox[0][0] + bbox[1][0]) / 2, y: (bbox[0][1] + bbox[1][1]) / 2 }; //center of that box
    const nPointCenter = {
        x: (center.x - bbox[0][0]) * scaleFactor[0] * ((100 - offset * 2) / 100) + offset,
        y: (center.y - bbox[0][1]) * scaleFactor[1] * ((100 - offset * 2) / 100) + offset
    };
    const transformPoint = { x: dimWidth / 2 - nPointCenter.x, y: dimHeight / 2 - nPointCenter.y };
    let newPoints = [];
    const points = extractCableDrop(layout);

    for (let i = 0; i < points.length; i++) {
        newPoints.push([
            (points[i][0] - bbox[0][0]) * scaleFactor[0] * ((100 - offset * 2) / 100) + offset + transformPoint.x,
            (points[i][1] - bbox[0][1]) * scaleFactor[1] * ((100 - offset * 2) / 100) + offset + transformPoint.y,
        ])
    }
    return newPoints;
}

export default FloorplanStaticRenderer;