/***
 *
 * Controller class for user.
 * @file SelectInput.js
 * @description SelectInput component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import { useField } from "formik";
import React from "react";
import { Label, FormFeedback, FormGroup } from "reactstrap";
import "./SelectInput.scss";

const SelectInput = ({label, ...props}) => {
  const [field, meta] = useField(props);

  return (
    <FormGroup className="SelectInput mb-2" data-testid="SelectInput">
      <Label className="labelfont" htmlFor={props.id || props.name}>{label}{props.isrequired ? <span className="text-danger">*</span> : <></>}</Label>
      <select className="form-control" {...field} {...props} invalid={ (meta.touched && meta.error) ? "invalid": "" } />
      {meta.touched && meta.error ? (
        <FormFeedback className="error">{meta.error}</FormFeedback>
      ) : null}
    </FormGroup>
  );
};

SelectInput.propTypes = {};

SelectInput.defaultProps = {};

export default SelectInput;
