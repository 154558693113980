import { breadcrumbActions } from "../slices";
import store from "../store";
import createRequest, { services } from "../../services";
import { SHASTA } from "../../utility/constants";

export const getVenueParent = (venueId, activeOrgId) => {

  const {run} = createRequest(services.venue.GET_PARENT,[venueId]);

    run().then((response)=>{
        const breadCrumbs = [{
            path:`/organization/${activeOrgId}/venues/`,
            text:"Venues",
            active:false,
          },
          ...(response.data?.parentVenue?.parentVenue?.venueId !== undefined ? [{
            path:`/organization/${activeOrgId}/venues/${response.data?.parentVenue?.parentVenue?.venueId}/`,
            text:response.data?.parentVenue?.parentVenue?.venueName,
            active:false,
          }]:[]),
          ...(response.data?.parentVenue?.venueId !== undefined ? [{
            path:`/organization/${activeOrgId}/venues/${response.data?.parentVenue?.venueId}/`,
            text:response.data?.parentVenue?.venueName,
            active:false,
          }]:[]),
          ...(response.data?.venueId !== undefined ? [{
            path:`/organization/${activeOrgId}/venues/${response.data?.venueId}/`,
            text:response.data?.venueName,
            active:false,
          }]:[])];
          store.dispatch(breadcrumbActions.setVenuecrumb({venueId:venueId,parentPath:breadCrumbs}));
      
    })
    .catch((error)=>{
      store.dispatch(breadcrumbActions.setVenuecrumb({venueId:venueId,parentPath:[{
        path:`/organization/${activeOrgId}/venues/`,
        text:"Venues",
        active:false,
      }]
    }));
    })

    
}

export const getOrgParent = (activeOrgId) => {
  if(activeOrgId===SHASTA) {
    store.dispatch(breadcrumbActions.setOrgcrumb({orgId:activeOrgId,parentPath:[]}));
  }
  else {
    const {run} = createRequest(services.organization.GET_PARENT,[activeOrgId], {}, true, activeOrgId);

    run().then((response)=>{
        const breadCrumbs = [
          ...(response.data?.parentOrg?.parentOrg?.orgId !== undefined ? [{
            path:`/organization/${response.data?.parentOrg?.parentOrg?.orgId}/dashboard/`,
            text:response.data?.parentOrg?.parentOrg?.orgDisplayName,
            active:false,
          }]:[]),
          ...(response.data?.parentOrg?.orgId !== undefined ? [{
            path:`/organization/${response.data?.parentOrg?.orgId}/dashboard/`,
            text:response.data?.parentOrg?.orgDisplayName,
            active:false,
          }]:[]),
          ...(response.data?.orgId !== undefined ? [{
            path:`/organization/${response.data?.orgId}/dashboard/`,
            text:response.data?.orgDisplayName,
            active:false,
          }]:[])];
          store.dispatch(breadcrumbActions.setOrgcrumb({orgId:activeOrgId,parentPath:breadCrumbs}));
    })
    .catch((error)=>{
        store.dispatch(breadcrumbActions.setOrgcrumb({orgId:activeOrgId,parentPath:[]}));
    })
  }
}