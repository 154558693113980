import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import AccessPointTypeItem from "../AccessPointTypeItem";
import { Col, Container, Input, InputGroup, Nav, NavItem, NavLink, Row } from "reactstrap";
import { useEffect, useMemo, useState } from "react";
import { INFRA_TYPE_GROUPS } from "../../../constants";
import "./AccessPointTypesList.scss";
import { SearchIconAddon } from "../../../../../../components";

/**
 * Access Point Types List
 * @param {{
 *  draggedItem: any
 * }} props 
 */
const AccessPointTypesList = (props) => {
  const infraTypes = useSelector(store => store.infraTypes.data);
  const infraTypesLoading = useSelector(store => store.infraTypes.status.loading);
  const infraTypesError = useSelector(store => store.infraTypes.status.error);
  const [activeTab, setActiveTab] = useState("Ceiling");
  const [search, setSearch] = useState('')

  const filteredInfraTypes = useMemo(() => {
    if (search.length > 0) {
      return (infraTypes?.filter(infraType => infraType.infraCategory === 1 && infraType.type === activeTab && infraType.infraType.includes(search)) ?? [])

    }
    return (infraTypes?.filter(infraType => infraType.infraCategory === 1 && infraType.type === activeTab) ?? [])
  }, [activeTab, infraTypes, search]);




  return (
    <div className="AccessPointTypesList">
      <h4 className="p-1 m-0 border-bottom">Available Access Points ({(infraTypes?.filter(infraType => infraType.infraCategory === 1) ?? []).length})</h4>

      {infraTypesError ?
        <div className="text-center text-danger py-2">{infraTypesError}</div>
        :
        (infraTypesLoading ?
          <div>Loading...</div>
          :
          <div>
            <Row className="infra-types-tabs-container p-0 m-0">
              <Col >
                <Nav tabs className="infra-types-tabs">
                  {INFRA_TYPE_GROUPS.map(infraTypeGroup => {
                    return (
                      <NavItem key={infraTypeGroup}>
                        <NavLink className={activeTab === infraTypeGroup ? "active" : ""} onClick={() => { setActiveTab(infraTypeGroup) }}>
                          {infraTypeGroup}
                        </NavLink>
                      </NavItem>
                    );
                  })}
                </Nav>
              </Col>
            </Row>
            <Row className="p-0 m-0">
              <InputGroup className="input-group-merge mx-2 ">
                <Input
                  style={{ height: '2.4rem' }}
                  type="text"
                  value={search}
                  placeholder="Search"
                  onChange={(e) => {
                    setSearch(e.target.value)
                  }}
                />
                <SearchIconAddon />
              </InputGroup>
              {filteredInfraTypes.length === 0 ?
                <Col xs={12} className="text-center text-secondary">No access points of this type</Col>
                :
                filteredInfraTypes
                  .map(infraType => {
                    return (
                      <Col xs={6} key={infraType.infraTypeId}>
                        <AccessPointTypeItem draggedItem={props.draggedItem} data={infraType} />
                      </Col>
                    );
                  })}
            </Row>
          </div>
        )
      }
    </div>
  );
};

AccessPointTypesList.propTypes = {
  draggedItem: PropTypes.any
};
AccessPointTypesList.defaultProps = {};

export default AccessPointTypesList;