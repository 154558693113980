import "../NewDashboard/NDashboard.scss";

import RoundedCard from "./RoundedCard";
import ChartPill from "./ChartPills/ChartPill";
import ChartPill2 from "./ChartPills/ChartPill2";
import ChartPill3 from "./ChartPills/ChartPill3";
import { ReactComponent as Expand } from "../../../assets/images/icons/ExpandIcon.svg";
import { useState } from "react";
import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import DateRangeSelector from "./DateRangeSelector";
import ApexCharts from "apexcharts";
import Chart from "react-apexcharts";
import { dateTimeFormatter } from "../../../utility/Localization";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Minus, Plus } from "react-feather";
import { formatDecimalPoints } from "../../../utility/Utils";
let zoomLeft = 0;
let zoomRight = 6;

const ChartCard = ({ title, Pill, children, Pill2, Pill3, AddAction, SearchAction, series, colors, xlabel, ylabel, showPercentage, categories, typeSeriesTrafficDownlink, typeSeriesTrafficUplink, isTraffic, error, isLoading, disableExpand = false, noStyle, noCardBorder, chartOptions, hideDateRangeAndZoom }) => {
  const [showModal, setShowModal] = useState(false);
  const dateRange = useSelector(store => store.activeOrg.meta.dateRange);
  // const [timeline, setTimeline] = useState({start:0,end:0})
  const beforeZoom = (e, { xaxis }) => {
    for (let i = 0; i < categories?.length - 1; i += 1) {
      if (xaxis.min > (new Date(categories[i])).valueOf() && xaxis.min < (new Date(categories[i + 1])).valueOf()) {
        zoomLeft = i
      }
      if (xaxis.max > (new Date(categories[i])).valueOf() && xaxis.max < (new Date(categories[i + 1])).valueOf()) {
        zoomRight = i + 1
      }
    }
  }

  // setTimeline({start:xaxis.min, end:xaxis.max})
  // let newSeries = series;
  // newSeries[0].data = [0,...newSeries[0].data];
  // let newCat = categories;
  // newCat=["2024-02-07T12:00Z",...categories]

  // useEffect(() => {er
  //   if(showModal){
  //     interval = setInterval(() => {
  //       setTimeline({start:categories[zoomLeft], end:categories[zoomRight]})
  //       console.log(zoomLeft, zoomRight)
  //     }, 100)
  //   }
  //   else{
  //     clearInterval(interval)
  //   }
  // },[showModal])

  let options = {
    xaxis: {
      type: 'datetime',
      // categories: categories,
      categories: categories,
      tickAmount: Number(dateRange) <= 0.25 ? 8 : 6,
      // stepSize:12,
      labels: {
        rotate: 340,
        style: {
          fontSize: '11px'
        },
        formatter: (value) => {
          if (value) {
            if (Number(dateRange) <= 0.25)
              return dateTimeFormatter(new Date(new Date(value).setMinutes(Math.round(new Date(value).getMinutes() / 30) * 30)), "", "short", false, false, false);
            // return dateTimeFormatter(value, "", "short", false, false, false);
            return dateTimeFormatter(value, "medium", "", true);
          }
        },
      }
    },
    yaxis: {
      show: true,
      forceNiceScale: true,
      min: 0,
      max: (max) => {
        if (isTraffic) {
          if (max === 0)
            return 1;
        }
        return Math.max(1, max);
      },
      // tickAmount:7,
      labels: {
        offsetX: -16,
        formatter: (value) => {
          return isTraffic ? formatDecimalPoints(value) : Math.floor(value)
        }
      }

    },
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    colors: colors,
    stroke: {
      width: 3
    },
    // fill: {
    //     colors: colors,
    //     opacity: 0.3,
    //     type: "solid"
    // },
    fill: {
      type: "solid",
      opacity: 0.2
    },
    markers: {
      size: 3,
      colors: ["#fff"],
      strokeColor: colors,
      strokeWidth: 3
    },
    grid: {
      show: false,
      padding: {
        right: 45,
        left: -2,
        top: 2
      }
    },
    chart: {
      id: "expanded",
      parentHeightOffset: 0,
      animations: {
        enabled: false
      },
      toolbar: {
        show: false,
        tools: {
          zoomin: true,
          zoomout: true,
          download: false
        }

      },
      events: {
        beforeZoom: beforeZoom
      }
    },
    tooltip: {
      // x: { show: false },
      y: {
        formatter: isTraffic ?
          function (value = 0, { series, seriesIndex, dataPointIndex, w }) {
            if (seriesIndex === 0) return value.toFixed(2) + ' ' + typeSeriesTrafficUplink ?? "MB";
            else return value.toFixed(2) + ' ' + typeSeriesTrafficDownlink ?? "MB";
          } : function (value, { series, seriesIndex, dataPointIndex, w }) {
            if (showPercentage)
              return value + '%'
            return value
          }
      }
    }
  }
  if (showPercentage) {
    options.yaxis.min = 0
    options.yaxis.max = 100
    options.yaxis.forceNiceScale = false
    options.yaxis.tickAmount = 4
  }
  if (!!chartOptions) {
    options = chartOptions(options)
  }


  useEffect(() => {
    zoomLeft = 0;
    zoomRight = categories?.length - 1;
  }, [categories])

  function zoomIn() {
    if ((zoomLeft + 1) < (zoomRight - 1)) {
      ApexCharts.exec("expanded", "zoomX", (new Date(categories[zoomLeft + 1])).valueOf(), (new Date(categories[zoomRight - 1])).valueOf())
      zoomLeft += 1;
      zoomRight -= 1;
    }
  }
  function zoomOut() {
    if (zoomLeft > 0 && zoomRight < (categories?.length - 1)) {
      ApexCharts.exec("expanded", "zoomX", (new Date(categories[zoomLeft - 1])).valueOf(), (new Date(categories[zoomRight + 1])).valueOf())
      zoomLeft -= 1;
      zoomRight += 1;
    }
    else if (zoomLeft > 0) {
      ApexCharts.exec("expanded", "zoomX", (new Date(categories[zoomLeft - 1])).valueOf(), (new Date(categories[zoomRight])).valueOf())
      zoomLeft -= 1;
    }
    else if (zoomRight < (categories?.length - 1)) {
      ApexCharts.exec("expanded", "zoomX", (new Date(categories[zoomLeft])).valueOf(), (new Date(categories[zoomRight + 1])).valueOf())
      zoomRight += 1;
    }
  }
  return (
    <div className="ChartCard flex-fill" style={noStyle ? {} : { marginBottom: "6px" }}>
      <RoundedCard noCardBorder={noCardBorder}>
        <div >
          <div className="d-flex justify-content-between align-items-center">
            <span>
              <span className="chart-title">{title}</span>
              {Pill && <ChartPill>{Pill}</ChartPill>}
              {Pill2 && <ChartPill2>{Pill2}</ChartPill2>}
              {Pill3 && <ChartPill3>{Pill3}</ChartPill3>}

            </span>
            <div className="d-flex">
              {series?.length > 1 ?
                <div className="d-flex align-items-center justify-content-between mr-50">
                  {series?.map((s, index) => {
                    return (
                      <div className={`d-flex align-items-center justify-content-end ${index == (series.length - 1) ? '' : 'mr-50'}`}>
                        <div className='mr-50 chartcard-legend-circle' style={{
                          backgroundColor: colors[index]
                        }}></div>
                        <span className="chartcard-legend-name">{s.name}</span>
                      </div>
                    )
                  })}
                </div>
                : null}
              {AddAction && <span className="material-symbols-outlined chart-buttons cursor-pointer" onClick={AddAction}>Add</span>}
              {SearchAction && <span className="material-symbols-outlined chart-buttons cursor-pointer" onClick={SearchAction}>Search</span>}
              {!error && !disableExpand && <Expand className={"cursor-pointer"} onClick={() => setShowModal(true)} />}
              {/* <span className="material-symbols-outlined chart-buttons">Drag_Indicator</span> */}
            </div>
          </div>
          {/* {<div className={series?.length>1 ? "d-flex justify-content-end align-items-center": "empty-div"}>{series?.length>1 && series?.map((s, i) => <span className="d-flex align-items-center"><span style={{backgroundColor: colors[i], borderRadius: "50%", width: "0.5rem", height: "0.5rem", display: "inline-block", margin:"0 0.4rem"}}></span><span style={{fontSize:"0.75rem"}}>{s.name}</span></span>)}</div>} */}
        </div>
        <div className="flex-fill d-flex align-items-center">
          <div className="w-100">
            {children}
          </div>
        </div>
      </RoundedCard>


      <Modal className="modal-lg" isOpen={showModal} toggle={() => {
        setShowModal(false);
      }} centered>
        <ModalHeader className="bg-white p-0" toggle={() => {
          setShowModal(false);
        }}></ModalHeader>
        <ModalBody>
          <div className="d-flex justify-content-between">
            <h5 className="font-weight-bold mb-2">{title}</h5>
            <div>
              {
                !hideDateRangeAndZoom &&
                <div className="d-flex justify-content-end">
                  <div className={"zoom-btn left-side "} onClick={() => {
                    zoomOut();
                  }}>
                    <Minus size={14} />
                  </div>
                  <div className={" zoom-btn right-side mr-1 "} onClick={() => {
                    zoomIn();
                  }}><Plus size={14} /></div>
                  <DateRangeSelector />
                </div>
              }
              {/* <div>
                  {timeline.start != 0 && timeline.end != 0  && 
                    <small>showing data from {dateTimeFormatter(timeline.start,"short","short")} to {dateTimeFormatter(timeline.end,"short","short")} </small>}
                </div> */}
            </div>

          </div>
          {isLoading ?
            <div style={{ minHeight: "300px", textAlign: "center", paddingTop: "120px" }}>
              <Spinner color="primary" />
            </div> :
            series ?
              <div>
                <div className="d-flex" style={{ width: "100%" }}>
                  <div className="d-flex align-items-center" style={{ width: "5%" }}>
                    <div className="rotate-label">{xlabel ?? ""}</div>
                  </div>
                  <div style={{ width: "95%" }}>
                    {/* <Chart options={options} series={series} type="area" width={"100%"} height={"300px"} /> */}
                    <Chart options={options} series={series} type="area" width={"102%"} height={"300px"} />
                  </div>
                </div>
                <div style={{ marginLeft: "5%" }}>
                  <div className="text-center my-50">
                    <span>{ylabel ?? ""}</span>
                  </div>
                </div>
              </div> : children}
        </ModalBody>
      </Modal>
    </div>
  );
};
ChartCard.defaultProps = { title: "CHARTCARD", Pill2: null, Pill3: null }

export default ChartCard;