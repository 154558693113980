/***
 *
 * Controller class for user.
 * @file SupportModal.js
 * @description SupportModal component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import { Form, Formik } from "formik";
import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
import Select from "react-select";
import { Alert, Button, Col, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import * as Yup from "yup";
import { CatchedWebError } from "../../configs";
import { make_toast } from "../../helpers";
import { CATEGORY_LIST, FILE_UPLOAD_ERROR_TEXT, PRIORITY_LIST, TICKET_SEVERITY, editorConfig } from "../../pages/Tickets/TicketConstants";
import { extractFileExtension, extractFileName } from "../../pages/Tickets/utility/StringFunction";
import { uploadFile } from "../../pages/Tickets/utility/ticketUtility";
import createRequest, { services } from "../../services";
import TextInput from "../TextInput";
// import PropTypes from 'prop-types';
import "./SupportModal.scss";
import TextEditor from "../TextEditor";
import { useRef } from "react";
import { useEffect } from "react";
import { make_custom_toast } from "../../helpers/toasts";
import { BlobToBase64New, extractBlobFromDescription, isBlobPresent } from "../../pages/Tickets/CreateTicket";
import { useNavigate } from "react-router-dom";

const SupportModal = (props) => {
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const activeOrgName = useSelector(store => store.activeOrg.data.orgDisplayName);

  const identityData = useSelector(state => state.identity.data)
  const identityId = identityData.identityId;
  const requesterEmail = identityData.email;
  const requesterName = identityData.userName
  // const [error, setError] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [uploadUrl, setUploadUrl] = useState([])
  const [fileUploadLoading, setFileUploadLoading] = useState(false)

  const [ticketPriority, setTicketPriority] = useState(TICKET_SEVERITY.MINOR)
  const [ticketApiLoading, setTicketApiLoading] = useState(false);
  const [emptyDescription, setEmptyDescription] = useState(false)
  // const [uploadError, setUploadError] = useState(null)
  const editor = useRef(null);
  const navigate = useNavigate()
  // useEffect(() => {
  //   setEmptyDescription(false)
  // }, [description])

  // useEffect(() => {
  //   setSubject(props?.ticketDetails?.subject || "")
  //   setDescription((props?.ticketDetails?.description || ""))
  // }, [props?.ticketDetails])



  const uploadFiles = (files) => {
    if (files == null || files == undefined || files.length < 1) {
      return
    }
    let tempUploadedFiles = [...uploadedFiles]
    let tempUploadUrl = [...uploadUrl]
    setFileUploadLoading(true)
    const promiseList = files.map(file => uploadFile(file, activeOrgId))
    Promise.all(promiseList)
      .then(resArray => {
        setFileUploadLoading(false)
        resArray.map((res) => {

          tempUploadUrl.push({
            filename: res.fileName
          })
          tempUploadedFiles.push({
            filename: res.displayName
          })
        })
        setUploadUrl(tempUploadUrl)
        setUploadedFiles(tempUploadedFiles)
      })
      .catch(errArray => {
        setFileUploadLoading(false)
        make_custom_toast('error', 'File Upload', FILE_UPLOAD_ERROR_TEXT)
        // setUploadError(FILE_UPLOAD_ERROR_TEXT)
      })
  }

  const deleteFile = (index) => {
    const fileName = encodeURIComponent(extractFileName(uploadUrl[index].filename))
    const extension = extractFileExtension(uploadedFiles[index].filename)

    const { run } = createRequest(services.ticket.REMOVE_ATTACHMENT, [activeOrgId, fileName, extension])
    run()
      .then(res => {
        let tempUploadedFiles = [...uploadedFiles]
        let tempUploadUrl = [...uploadUrl]
        tempUploadedFiles.splice(index, 1)
        tempUploadUrl.splice(index, 1)
        setUploadedFiles(tempUploadedFiles)
        setUploadUrl(tempUploadUrl)
      })
      .catch(err => {
        make_custom_toast('error', 'Remove Ticket Attachment', (new CatchedWebError(err)).message)
        // setError((new CatchedWebError(err)).message)
      })
  }


  const createTicket = (values, { setSubmitting, resetForm }) => {
    if (values.ticketdescription.length < 10) {
      setEmptyDescription(true)
      return
    }
    let createTicketPayload = {
      subject: values.ticketsubject,
      description: values.ticketdescription,
      status: 2, // 'open' status by default 
      severity: ticketPriority,
      category: CATEGORY_LIST[props.ticketDetails.category - 1].label,
      source: 2,
      attachments: uploadUrl,
      venueId: props.ticketDetails.venueId ?? 0,
      infraId: props.ticketDetails.infraId ?? 0,
      networkId: props.ticketDetails.networkId ?? 0,
      venueName: props.ticketDetails.venueName ?? "",
      infraName: props.ticketDetails.infraName ?? "",
      networkName: props.ticketDetails.networkName ?? "",
      identityId: identityId,
      requesterEmail: requesterEmail,
      requesterName: requesterName,
      orgId: activeOrgId,
      orgName: activeOrgName,
      macAddress: props.ticketDetails.macAddress ?? ''

    }
    if (props.context) {
      createTicketPayload.reportError = props.context
    }



    let newDescription = values.ticketdescription
    const blobArray = extractBlobFromDescription(newDescription);

    if (isBlobPresent(newDescription) && blobArray.length > 0) {
      for (let i = 0; i < blobArray.length; i++) {
        let tempBlobLink = blobArray[i]
        let convertedLink = BlobToBase64New(tempBlobLink)
        newDescription = newDescription.replace(tempBlobLink, convertedLink)
      }
    }
    createTicketPayload.description = newDescription

    const { run } = createRequest(services.ticket.CREATE_TICKET, [], createTicketPayload)
    setTicketApiLoading(true)
    run()
      .then((res) => {
        window.scrollTo(0, 0)
        const newTicket = res.data
        make_custom_toast(
          "success",
          "Ticket Successfully created",
          `Your Ticket #${newTicket.freshworksTicketId} is in review`,
          true,
          "View Ticket",
          () => {
            navigate(
              `/organization/${activeOrgId}/support/ticket/details/${newTicket.freshworksTicketId}`
            );
          }
        );
        setTicketApiLoading(false)
        resetForm();
        props.onSuccess && props.onSuccess();
        setUploadedFiles([]);
        setUploadUrl([]);
        props.setIsOpen(false);
      })
      .catch((err) => {
        window.scrollTo(0, 0)
        setTicketApiLoading(false)
        make_custom_toast('error', 'Create Ticket', (new CatchedWebError(err)).message)
        // setError((new CatchedWebError(err)).message)
      })

  }


  const customStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 100
    }),
    dropdownIndicator: (base) => ({
      ...base,
      "svg": {
        height: "20px",
        width: "20px"
      }
    })
  };

  return (
    <div className="SupportModal" data-testid="SupportModal">
      <Modal centered className="modal-lg support-modal-style" isOpen={props.isOpen} toggle={() => {
        setUploadedFiles([]);
        setUploadUrl([]);
        setEmptyDescription(false);
        props.setIsOpen(false);
      }}>
        <ModalHeader className="bg-white" toggle={() => {
          setUploadedFiles([]);
          setUploadUrl([]);
          setEmptyDescription(false);
          props.setIsOpen(false)
        }}></ModalHeader>
        <ModalBody>
          <h3 className="pl-1">Create Ticket</h3>
          {/* <Alert color="danger" isOpen={!!error} toggle={() => setError(null)}>
            <div className="alert-body">{error}</div>
          </Alert> */}
          <Formik
            initialValues={
              {
                ticketsubject: props?.ticketDetails?.subject ?? '',
                ticketdescription: props?.ticketDetails?.description ?? '',
                ticketpriority: TICKET_SEVERITY.MINOR
              }}
            validationSchema={Yup.object({
              ticketsubject: Yup.string()
                .min(3, "Minimum 3 characters are required")
                .max(128, "Maximum 128 characters are allowed")
                .required("Required"),

            })}
            onSubmit={
              createTicket
            }
          >
            {({ values, setFieldValue, handleSubmit }) => (
              <Form onSubmit={(event) => {
                event.preventDefault();
                handleSubmit();
              }}>
                <div className="infra-support-div p-1">
                  <div>
                    <Row>
                      <Col xs="12" md="8">
                        <TextInput label="Subject" placeholder='Add subject for ticket' type="text" name="ticketsubject" isrequired onChange={(e) => {
                          setFieldValue("ticketsubject", e.target.value);
                        }} />
                      </Col>
                      {/* <Col xs="12" md="6" className="d-flex ">
                    <div className="d-flex flex-column w-100">
                      <Label className={"labelfont"}>Select Category</Label>
                      <Select
                        classNamePrefix='select'
                        defaultValue={{label:props.ticketDetails.category}}
                        options={categoryList}
                        isClearable={false}
                        isSearchable={false}
                        styles={customStyles}
                        onChange={
                          (option) => {
                            setTicketCategory(option.label)
                          }
                        }
                      />
                    </div>
                  </Col> 
                 </Row>
                <Row>

                  <Col xs="12" md="6" className="d-flex">
                    <div className="d-flex flex-column w-100">
                      <Label className={"labelfont"}>Issue Type</Label>
                      <Select
                        name={'ticketType'}
                        classNamePrefix='select'
                        defaultValue={ISSUE_LIST[0]}
                        options={ISSUE_LIST}
                        isClearable={false}
                        isSearchable={false}
                        styles={customStyles}
                        onChange={
                          (option) => {
                            setTicketIssueType(option.label)
                          }
                        }
                      />
                    </div>
                  </Col> */}
                      <Col xs="12" md="4" className="d-flex">
                        <div className="d-flex flex-column w-100">
                          <Label className={"labelfont"}>Select Severity</Label>
                          <Select
                            name={'ticketpriority'}
                            classNamePrefix='select'
                            defaultValue={PRIORITY_LIST[0]}
                            options={PRIORITY_LIST}
                            isClearable={false}
                            isSearchable={false}
                            styles={customStyles}
                            onChange={
                              (option) => {
                                setTicketPriority(option.value)
                              }
                            }
                          />
                        </div>
                      </Col>
                    </Row>

                    {/* <Row className='fadable'>
                  {props.ticketDetails.venueName && <Col xs="12" md="6">
                    <TextInput type="text" name="venueName" label="Venue" disabled value={props.ticketDetails.venueName} isrequired />
                  </Col>}
                  {props.ticketDetails.infraName && <Col xs="12" md="6">
                      <TextInput type="text" name="infraName" label="Infrastructure" disabled value={props.ticketDetails.infraName} isrequired />
                  </Col>}
                  {props.ticketDetails.networkName && <Col xs="12" md="6">
                      <TextInput type="text" name="networkName" label="Network" disabled value={props.ticketDetails.networkName} isrequired />
                  </Col>}
                </Row> */}
                  </div>


                  {/* --- Decription and Attachment View --- */}

                  <div className="mb-2">
                    <Row>
                      <Col >
                        {/* <TextInput label="Description (Not more than 1000 characters)" placeholder='Add description' rows='4' type="textarea" value={description} name="ticketdescription" isrequired
                          onChange={(e) => {
                            setDescription(e.target.value);
                            setFieldValue("ticketdescription", e.target.value);
                          }} /> */}
                        <Label style={{ fontSize: '1em' }}>Description<span className="text-danger">*</span></Label>
                        <TextEditor
                          ref={editor}
                          value={values.ticketdescription}
                          config={editorConfig}
                          tabIndex={1} // tabIndex of textarea
                          onBlur={newDescription => { if (newDescription.length < 10) setEmptyDescription(true); else setEmptyDescription(false); }} // preferred to use only this option to update the content for performance reasons
                          onChange={newDescription => {
                            setEmptyDescription(false);
                            setFieldValue("ticketdescription", newDescription)
                          }}
                        />
                        {emptyDescription && <span className="text-danger">{`Minimum 10 characters required.`}</span>}
                      </Col>
                    </Row>
                    <Row>
                      <Col >
                        <div className="d-flex align-items-center">

                          <h5 className="my-2 mr-1 mb-0 cursor-default">Upload Attachment</h5>
                          <span className="text-muted cursor-default">(Maximum upload file size: 20MB)</span>
                        </div>
                        {/* <Alert className="w-100" color="danger" isOpen={!!uploadError} toggle={() => setUploadError(null)}>
                          <div className="alert-body">{uploadError}</div>
                        </Alert> */}
                        <div className='fileupload'>
                          {fileUploadLoading ? <Spinner color={`primary`} /> : uploadedFiles.length > 0 ?
                            <div className='d-flex align-center flex-wrap flex-1'>
                              {uploadedFiles.map((file, index) => {
                                return (
                                  <div key={index} className="d-flex align-items-center justify-content-between rounded p-1 m-1 shadow ">
                                    <span>{file.filename}</span>
                                    <span
                                      className="bin-icon ml-1 cursor-pointer d-inline material-symbols-outlined"
                                      onClick={() => {
                                        deleteFile(index)
                                      }} >
                                      delete
                                    </span>
                                  </div>)
                              })}

                            </div> :
                            <Dropzone
                              onDrop={uploadFiles} >
                              {({ getRootProps, getInputProps }) => (
                                <section className="d-flex w-100">
                                  <div {...getRootProps()} className="d-flex justify-content-center align-items-center  w-100 text-center">
                                    <input {...getInputProps()} />
                                    <div className="test-center">
                                      <p className="material-symbols-outlined large cursor-default">file_upload</p>
                                      <p className="cursor-default"><span className="cursor-pointer text-primary">Upload a file</span> or drag and drop</p>
                                    </div>
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          }
                        </div>
                      </Col>
                    </Row>
                  </div>
                  {/* --- Create Ticket Button --- */}
                  <div className="d-flex justify-content-between">
                    <div>
                      {/* <Button
                    outline
                    className='mr-1'
                    disabled={ticketApiLoading}
                    color="primary"
                    onClick={() => {
                      navigate(`/organization/${activeOrgId}/support/ticket/createticket`,{state:{
                        ticketSubject: subject,
                        ticketDescription: description,
                        ticketPriority: ticketPriority,
                        selectedCategory: props.ticketDetails.category,
                        venueId: props.ticketDetails.venueId,
                        infraId: props.ticketDetails.infraId,
                        networkId: props.ticketDetails.networkId,
                        venueName: props.ticketDetails.venueName,
                        infraName: props.ticketDetails.infraName,
                        networkName: props.ticketDetails.networkName,
                        macAddress: props.ticketDetails.macAddress,
                        openVenue: props.ticketDetails.venueId ? true : false
                      }})
                    }}
                  >
                    Add More Details
                  </Button> */}
                      {
                        uploadedFiles.length > 0 ?
                          <Dropzone
                            onDrop={uploadFiles} >
                            {({ getRootProps, getInputProps }) => (
                              <section >
                                <div {...getRootProps()} >
                                  <input {...getInputProps()} />
                                  <div >
                                    <Button
                                      outline
                                      className='mr-1'
                                      disabled={ticketApiLoading}
                                      color="primary"
                                      onClick={() => { }}
                                    >
                                      Add Files
                                    </Button>
                                  </div>
                                </div>
                              </section>
                            )}
                          </Dropzone>
                          : <div />
                      }
                    </div>
                    <div>
                      <Button
                        outline
                        className='mr-1'
                        disabled={ticketApiLoading}
                        color="primary"
                        onClick={() => {
                          setUploadedFiles([]);
                          setUploadUrl([]);
                          setEmptyDescription(false);
                          props.setIsOpen(false);
                        }}
                      >
                        Discard
                      </Button>
                      <Button
                        disabled={ticketApiLoading}
                        type={'submit'}
                        color="primary"
                      >{ticketApiLoading ? (<Spinner size={'sm'} />) : `Create Ticket`}
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </div>
  );
};

SupportModal.propTypes = {};

SupportModal.defaultProps = {};

export default SupportModal;
