/***
 *
 * Controller class for user.
 * @file Authentication.js
 * @description Authentication component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import React, { useEffect } from "react";
// import PropTypes from 'prop-types';
import "./Authentication.scss";
import {Button, Row, Col, Label, UncontrolledTooltip} from "reactstrap";
import { TextInput, PasswordInput, NetworkImage, Toggle } from "../../../components";
import {RADIUS_SETTINGS} from "../../../utility/tooltip-config-global";
import * as Yup from 'yup';
import { Formik, Form} from 'formik';
import { useState } from "react";
import Select from "react-select";
import { ENTERPRISE, MPSK, PUBLIC } from "../../../utility/constants";
import { NasIDCheck } from "../../Profiles/Port802";
import { ReactComponent as Warning} from "../../../assets/images/icons/Warning.svg";

export const ToolTip = (props) => {
  let field = props.field;
  return (
    <>
      <span className="material-symbols-outlined text-secondary cursor-pointer" id={"tooltip-"+field}>
        help
      </span>
      <UncontrolledTooltip target={"tooltip-"+field} placement="right" >
        <div className="text-center">
          {RADIUS_SETTINGS[field]}
        </div>
      </UncontrolledTooltip>
    </>
  )
}

const options = [
  { value: 'venueRadius', label: 'Venue Radius Location' },
  { value: 'custom', label: 'Custom' },
  { value: 'none', label: 'None' },
]

const Authentication = (props) => {
  const {stepper, handleAuthentication, networkImage, userData, editing, valid, setValid, data, setData, updateNetwork, disabled} = props;
  const [coaEnabled,setCoaEnabled] = useState((data?.configuration?.enable_COA) ? data?.configuration.enable_COA : false);
  const [useRadsec, setUseRadsec] = useState(data?.configuration?.radSec??false);
  const [enableAuth, setEnableAuth] = useState(
    editing
    ? (data?.networkTypeId == MPSK || data?.networkTypeId == ENTERPRISE || (data?.networkTypeId == PUBLIC && data?.configuration?.aaaMacAuth))
    : false);
  const [nasIdCustom,setNasIdCustom] = useState(editing?NasIDCheck(data.configuration.nas_identifier):"venueRadius");

  useEffect(() => {
    if(!useRadsec)
      document.getElementById("primary_ip")?.focus();
  },[useRadsec])
  
  useEffect(() => {
    if(!editing) {
      setEnableAuth((userData.networkType == MPSK || userData.networkType == ENTERPRISE))
    }
  },[userData?.networkType,editing])

  return (
    <div className={`Authentication bg-white rounded ${editing?'p-1':null}`} data-testid="Authentication">
      <Formik
        initialValues={{
          primary_server_ip: data?.configuration ? data?.configuration.radius : "",
          primary_auth_port: (data?.configuration?.AuthPort) ? data?.configuration.AuthPort : 1812,
          primary_accounting_port: (data?.configuration?.AccPort) ? data?.configuration.AccPort : 1813,
          primary_shared_secret: (data?.configuration?.secret) ? data?.configuration.secret : "",
          interim_update: editing? data?.configuration?.interval??null : 600,
          nas_id: (data?.configuration?.nas_identifier) ? data?.configuration.nas_identifier : null,
          coa_ip: (data?.configuration?.COA_host) ? data?.configuration.COA_host : "",
          coa_port: (data?.configuration?.COA_port) ? data?.configuration.COA_port : 3799,
          coa_secret: (data?.configuration?.COA_secret) ? data?.configuration.COA_secret : "",
          coa_enabled: (data?.configuration?.enable_COA) ? data?.configuration.enable_COA : false
        }}
        validationSchema={Yup.object({
          primary_server_ip: (!enableAuth || useRadsec)? Yup.string() : Yup.string().required("Required")
                              .matches(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,"Please enter valid ip address"),
          primary_auth_port: (!enableAuth || useRadsec)? Yup.number().nullable(true) : Yup.number().required("Required")
                            .min(1024,"Must atleast be 1024")
                            .max(65535,"Can atmost be 65535"),
          primary_accounting_port: (!enableAuth || useRadsec)? Yup.number().nullable(true) : Yup.number().required("Required")
                                  .min(1024,"Must atleast be 1024")
                                  .max(65535,"Can atmost be 65535"),
          primary_shared_secret: (!enableAuth || useRadsec)? Yup.string().nullable(true) : Yup.string().required("Required")
                                  .min(1,"Must have atleast 1 character")
                                  .max(32,"Secret must be less than 32 characters"),
          interim_update: !enableAuth? Yup.number().nullable(true) : Yup.number()
                          .min(60,"Must atleast be 60")
                          .max(6000,"Maximum value is 6000")
                          .nullable(true),
          nas_id: (enableAuth && nasIdCustom === 'custom')? Yup.string().required("Required").max(32,"Maximum length is 32 characters") : Yup.string().nullable(true),
          coa_enabled: !enableAuth ? Yup.boolean() : Yup.boolean().required("Required"),
          coa_ip: enableAuth && coaEnabled? Yup.string().required("Required")
            .matches(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,"Please enter valid ip address")
            : Yup.string(),
          coa_port: enableAuth && coaEnabled? Yup.number().required("Required")
            .min(1024,"Must atleast be 1024")
            .max(65535,"Can atmost be 65535")
            : Yup.number().nullable(true),
          coa_secret: enableAuth && coaEnabled? Yup.string().required("Required")
            .min(1,"Must have atleast 1 character.")
            .max(32,"Secret must be less than 32 characters.")
            : Yup.string()
        })}
        onSubmit={(values, { setSubmitting })=>{
        if(editing) {
          updateNetwork()
        }
        else {
          stepper.next();
          handleAuthentication(values, enableAuth, useRadsec);
          setSubmitting(false);
        }
        }}
      >
        {({isSubmitting, handleSubmit, values, setFieldValue, errors, setErrors, validateField, validateForm})=><Form onSubmit={(event)=>{
        event.preventDefault();
        handleSubmit();
        }}
        >
          <div className="main-form">
          <NetworkImage networkImage={networkImage} className=''/>
            { (editing?data?.networkTypeId == PUBLIC:userData.networkType == PUBLIC) && 
            <div  className="my-1">
              Enable MAC Authentication
              <Row>
                <Col xs={2}>
                  <Toggle value={enableAuth} onClick={() => {
                    if(editing) {
                      if (!enableAuth)
                        document.getElementById("primary_ip")?.focus();
                      else
                        setErrors({})
                      setEnableAuth(prevState => !prevState);
                      setData((prevState) => {
                        let data = { ...prevState };
                        data.configuration.aaaMacAuth = prevState?.configuration?.aaaMacAuth 
                          == null
                          ? true 
                          : !prevState.configuration.aaaMacAuth;
                        data.configuration.AccPort = values.primary_accounting_port;
                        data.configuration.AuthPort = values.primary_auth_port;
                        data.configuration.interval = values.interim_update
                        if(values.coa_enabled)
                          data.configuration.COA_port = values.coa_port
                        return { ...data }
                      })
                    }
                    else {
                      setErrors({})
                      setEnableAuth(prevState => !prevState)
                    }
                  }} />
                </Col>
                <Col xs={1} className="d-flex align-items-center pl-0">
                  <ToolTip field='ENABLE_MAC_AUTH'/>
                </Col>
              </Row>
            </div>
            }
          {enableAuth?<>
          <h4 className="mb-1">Settings</h4>
          {/* <hr/> */}
          <Row>
            <Col>
              <div>Use Radsec</div>
              <Toggle disabled={disabled} displayText={false} value={useRadsec}
                onClick={() => {
                  if(!useRadsec)
                    setTimeout(() => validateForm(), 100)
                  setUseRadsec(prevState => !prevState);
                  if(editing){
                    setData((prevState) => {
                      let data = prevState;
                      data.configuration.radSec = !data.configuration.radSec;
                      return { ...data }
                    })
                  }
                }} />
            </Col>
          </Row>
          {(!useRadsec)?<Row>
            <Col className="input--min-height">
              <TextInput id="primary_ip" disabled={disabled || !enableAuth} labelfontauth={true} isrequired={true} 
                type="text" name="primary_server_ip" 
                label="AAA Server IP" placeholder="x.x.x.x" noBottomMargin
                onChange={(e) => {
                  setFieldValue("primary_server_ip", e.target.value);
                  if(editing) {
                    setData((prevState) => {
                      let data = prevState;
                      data.configuration.radius = e.target.value;
                      return { ...data }
                    })
                  }
                }}/>
            </Col>
            <Col xs={1} lg={1} className="d-flex align-items-center pl-0">
              <ToolTip field='AAA_SERVER_IP'/>
            </Col>
            <Col className='input--min-height'>
              <TextInput disabled={disabled || !enableAuth} labelfontauth={true} isrequired={true} type="number" 
                name="primary_auth_port" label="Authentication Port" placeholder="1812" noBottomMargin
                onChange={(e) => {
                const newValue = e.target.value == ''?'':Number(e.target.value)
                setFieldValue("primary_auth_port", newValue);
                if(editing) {
                  setData((prevState) => {
                    let data = prevState;
                    data.configuration.AuthPort = newValue;
                    return { ...data }
                  })
                }
              }}/>
            </Col>
            <Col xs={1} lg={1} className="d-flex align-items-center pl-0">
              <ToolTip field='AAA_SERVER_AUTH_PORT'/>
            </Col>
            <Col className='input--min-height'>
              <TextInput disabled={disabled || !enableAuth} labelfontauth={true} isrequired={true} type="number" 
                name="primary_accounting_port" label="Accounting Port" placeholder="1813" noBottomMargin
                onChange={(e) => {
                  const newValue = e.target.value == ''?'':Number(e.target.value)
                  setFieldValue("primary_accounting_port", newValue);
                  if(editing) {
                    setData((prevState) => {
                      let data = prevState;
                      data.configuration.AccPort = newValue;
                      return { ...data }
                    })
                  }
                }
              }/>
            </Col>
            <Col xs={1} lg={1} className="d-flex align-items-center pl-0">
              <ToolTip field='AAA_SERVER_ACC_PORT'/>
            </Col>
            <Col className="input--min-height">
              <Label className="Label" for="primary_shared_secret">Shared Secret<span className="text-danger">*</span></Label>
              <PasswordInput disabled={disabled || !enableAuth} htmlFor='basic-default-password' name="primary_shared_secret" label="Shared Secret" placeholder="xxxxx" onChange={(e) => {
                      setFieldValue("primary_shared_secret", e.target.value);
                      if(editing) {
                        setData((prevState) => {
                          let data = prevState;
                          data.configuration.secret = e.target.value;
                          return { ...data }
                        })
                      }
                    }}/>
            </Col>
            <Col xs={1} lg={1} className="d-flex align-items-center pl-0">
              <ToolTip field='AAA_SERVER_SECRET'/>
            </Col>
          </Row>:
          <div className="py-2 d-flex align-items-center">
            <Warning width={22} height={22} />
            <span className="font-weight-bolder pl-50">Radsec must be enabled from AP profile to work properly.</span>
          </div>}
          {/* <h4>Extra Settings</h4>
          <hr/> */}
          <Row>
            <Col xs={2} className="input--min-height">
              <TextInput disabled={disabled || !enableAuth} labelfontauth={true} noBottomMargin
                type="number" name="interim_update" label="Interim Update (in seconds)" placeholder="600" 
                onChange={(e) => {
                  const newValue = e.target.value == ''?'':Number(e.target.value)
                  setFieldValue("interim_update", newValue);
                  if(editing) {
                    setData((prevState) => {
                      let data = prevState;
                      data.configuration.interval = newValue;
                      return { ...data }
                    })
                  }
                }
              }/>
            </Col>
            <Col xs={1} className="d-flex align-items-center pl-0">
              <ToolTip field='AAA_SERVER_INT_UPDATE'/>
            </Col>
            <Col xs={2}>
              <Label
                className="labelfontauth"
                for="nas-id-string"
              >
                  NAS-ID String
              </Label>
              <Select
                classNamePrefix='select'
                defaultValue={options.find( it => it.value == nasIdCustom)}
                options={options}
                isClearable={false}
                isSearchable={false}
                isDisabled={disabled || !enableAuth}
                id='nas-id-string'
                menuPortalTarget={document.getElementById("mainBod")}
                onChange={
                  (option) => {
                    setNasIdCustom(option.value)
                    if(option.value == "none") {
                      setFieldValue("nas_id", null);
                      if(editing) {
                        setData((prevState) => {
                          let data = prevState;
                          data.configuration.nas_identifier = null;
                          return { ...data }
                        })
                      }
                    }
                    else {
                      setFieldValue("nas_id","");
                      if(editing) {
                        setData((prevState) => {
                          let data = prevState;
                          data.configuration.nas_identifier = "";
                          return { ...data }
                        })
                      }
                    }
                    setTimeout(() => validateField("nas_id"),0)
                  }
                }
              />
            </Col>
            <Col xs={1} className="d-flex align-items-center pl-0">
              <ToolTip field='AAA_SERVER_NAS_ID_STRING'/>
            </Col>
            <Col xs={2}>
              {nasIdCustom == "custom" &&
              <TextInput disabled={disabled || !enableAuth} labelfontauth={true} type="text" name="nas_id" label="NAS-ID Value" 
                placeholder="nasID" noBottomMargin isrequired={true}
                onChange={(e) => {
                setFieldValue("nas_id", e.target.value);
                if(editing) {
                  setData((prevState) => {
                    let data = prevState;
                    data.configuration.nas_identifier = e.target.value;
                    return { ...data }
                  })
                }
              }}/>
              }
            </Col>
            <Col xs={1} className="d-flex align-items-center pl-0">
              {nasIdCustom == "custom" &&
              <ToolTip field='AAA_SERVER_NAS_ID'/>
              }
            </Col>
          </Row>
          <h4>CoA Settings</h4>
          <Row>
            <Col xs={2} className="d-flex align-items-center">
              <Toggle value={coaEnabled} disabled={disabled || !enableAuth}
                displayText={false}
                onClick={() => {
                  setCoaEnabled(!coaEnabled);
                  setFieldValue("coa_enabled",!values.coa_enabled);
                  if(editing) {
                    setData((prevState) => {
                      let data = prevState;
                      data.configuration.enable_COA = !data.configuration.enable_COA;
                      if(data.configuration.enable_COA)
                        data.configuration.COA_port = values.coa_port??3799
                      return { ...data }
                    })
                  }
                }} />
              <span className='coa-label'>Enable CoA</span>
            </Col>
            <Col xs={1} lg={1} className="d-flex align-items-center pl-0">
              <ToolTip field='ENABLE_COA'/>
            </Col>
          </Row>
          {/* <hr/> */}
          {coaEnabled
          ? <Row className='align-items-top input--min-height'>
            <Col xs={2}>
              <TextInput disabled={disabled || !enableAuth} labelfontauth={true} isrequired={true} type="text" 
                name="coa_ip" label="IP" placeholder="x.x.x.x" noBottomMargin
                onChange={(e) => {
                setFieldValue("coa_ip", e.target.value);
                if(editing) {
                  setData((prevState) => {
                    let data = prevState;
                    data.configuration.COA_host = e.target.value;
                    return { ...data }
                  })
                }
              }}/>
            </Col>
            <Col xs={1} className="d-flex align-items-center pl-0">
              <ToolTip field='COA_IP'/>
            </Col>
            <Col xs={2}>
              <TextInput disabled={disabled || !enableAuth} labelfontauth={true} isrequired={true} type="number" 
                name="coa_port" label="Port" placeholder="3379" noBottomMargin
                onChange={(e) => {
                  const newValue = e.target.value == ''?'':Number(e.target.value)
                  setFieldValue("coa_port", newValue);
                  if(editing) {
                    setData((prevState) => {
                      let data = prevState;
                      data.configuration.COA_port = newValue;
                      return { ...data }
                    })
                  }
                }
              }/>
            </Col>
            <Col xs={1} className="d-flex align-items-center pl-0">
              <ToolTip field='COA_PORT'/>
            </Col>
            <Col xs={2}>
              <Label className="label" for="coa_secret">Secret<span className="text-danger">*</span></Label>
              <PasswordInput disabled={disabled || !enableAuth} htmlFor='basic-default-password' name="coa_secret" label="Secret" placeholder="xxxxx" onChange={(e) => {
                setFieldValue("coa_secret", e.target.value);
                if(editing) {
                  setData((prevState) => {
                    let data = prevState;
                    data.configuration.COA_secret = e.target.value;
                    return { ...data }
                  })
                }
              }}/>
            </Col>
            <Col xs={1} className="d-flex align-items-center pl-0">
              <ToolTip field='COA_SECRET'/>
            </Col>
          </Row>
          : null}
          </>:null}
          </div>
          {/* {console.log("--->", errors)} */}
          {editing && Object.keys(errors).length > 0 ? setValid("auth") : valid === "auth" ? setValid("") : null}
          <div className='d-flex flex-row-reverse mt-50'>
            {editing
            ? !disabled
              ? <Button.Ripple color='primary' className='btn-next d-flex align-items-center' onClick={() => {
                  handleSubmit()
                }}>
                  Update
                </Button.Ripple>
              : null
            : <>
              <Button.Ripple color='primary' className='btn-next d-flex align-items-center' type="submit">
                    Next <span className="material-symbols-outlined">chevron_right</span>
              </Button.Ripple>
              <Button.Ripple color='primary' className='btn-prev mr-1' onClick={()=>stepper.previous()} outline>
                <span className='align-middle d-sm-inline-block d-none'>Back</span>
              </Button.Ripple></>}
          </div>
        </Form>
        }
      </Formik>
    </div>
  );
};

Authentication.propTypes = {};

Authentication.defaultProps = {};

export default Authentication;
