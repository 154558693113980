/***
 *
 * Controller class for user.
 * @file FilterSetter.js
 * @description FilterSetter component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React from "react";
import { X } from "react-feather";
import { Button, Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import FilterList from "../FilterList";
// import PropTypes from 'prop-types';
import "./FilterSetter.scss";

const FilterSetter = (props) => {
  return (
    <div className="FilterSetter" tabIndex="-1" data-testid="FilterSetter">
      <div className={'filterDiv ' + (props.showFilter ? "move-in" : "")} role="dialog" tabIndex="-1">
        <Card className='h-100 w-100 mb-0'>
          <CardHeader className='d-flex justify-content-between'>
            <div>
              <h2>Filters</h2>
            </div>
            <X className='cursor-pointer' onClick={() => { props.setShowFilter(false) }} />
          </CardHeader>
          <CardBody className="mb-1">
            <hr />
            <FilterList elements={props.elements} showFilter={props.showFilter} />
          </CardBody>
          <CardFooter>
            <div className='d-flex justify-content-between' size='sm'>
              <Button outline color='primary'
                onClick={() => {
                  props.handleClearAll();
                }}>
                Clear All
              </Button>
              <Button color='primary' size='sm' disabled={props.disabled} onClick={() => { props.handleApplyClick(); }}>
                Apply
              </Button>
            </div>
          </CardFooter>
        </Card>
      </div>
      {props.showFilter && <div className='modal-backdrop fade show filterBackDrop' onClick={() => { props.setShowFilter(false) }}>
      </div>}
    </div>
  );
};

FilterSetter.propTypes = {};

FilterSetter.defaultProps = {};

export default FilterSetter;
