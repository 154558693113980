import store from "../store";
import { identityActions } from "../slices";
import axios from "axios";
import { B } from "../../helpers";
import { CatchedWebError } from "../../configs";
// import { getToken, clearToken } from "../../authCustom";
import { getTokenSilently, logout } from "../../auth0";

const getIdentity = (after = () => {}) => {
  store.dispatch(identityActions.setLoading(true));
  const controller = new AbortController();
  let afterPromise = null;
  const token = getTokenSilently();

  // get generic token
  store.dispatch(identityActions.setToken(token));
      // get identity details (incl. auth0OrgId)
      return axios.get(B("identity"), {
        signal: controller.signal,
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    .then(response => {
      // get token (with organization details & permissions)
      store.dispatch(identityActions.setIdentity(response.data));
    })
    .then(() => {
      const permToken = store.getState().identity.meta.token;
      // post-arrival-configuration
      
      afterPromise = after(permToken);
    })
    .catch(err => {
      logout({logoutParams:{returnTo: `${window.location.origin}/exit.html`}});
      let x = new CatchedWebError(err);
      store.dispatch(identityActions.setError(x.message));
    })
    .finally(() => {
      store.dispatch(identityActions.setLoading(false));
    });  
  return { controller, afterPromise };
};

export default getIdentity ;