/**
 * Tabs without content
 * @file Tabs.js
 * @description These are only representational tabs, they can be stateful, but they aren't real tabs.
 * @author Utkarsh Gupta
 * @since 27 Jun 2022
 */

import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from 'prop-types';
import "./Tabs.scss";

const Tabs = (props) => {
  const TabStyle = actionData => actionData.isActive ? "Tab ActiveTab" : "Tab";

  return (
    <div className="Tabs d-inline-block pb-2 pt-1" data-testid="Tabs">
      <ul className="list-inline inline">
        {props.data.filter(obj => obj).map((item) => {
          return (
            <li className="list-inline-item" key={item.path}>
              {
              (item.text==="Authentication" && props.authDisabled===true)? <span className="disabledTab">{item.text} </span>:
                <NavLink className={TabStyle} end={item.start?false:true} to={item.path}>
                  {item.text}
                </NavLink>
              }
            </li>
          );
        })}
      </ul>
    </div>
  );
};

Tabs.propTypes = {
  data: PropTypes.array
};

Tabs.defaultProps = {
  data: []
};

export default Tabs;
