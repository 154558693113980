/***
 *
 * Controller class for user.
 * @file NetworkSelector.js
 * @description NetworkSelector component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import createRequest, { services } from "../../../../services";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "reactstrap/lib/Spinner";
import "./NetworkSelector.scss";
import { AlertBox, MaterialSymbol } from "../../../../components";
import { CatchedWebError } from "../../../../configs";

/// Setup state variables and init functions for NetworkSelector Component
const useNetworkSelector = () => {
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const [network, setNetwork] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const { run } = createRequest(services.networks.GET_NET, [activeOrgId, 0, 20]);
    async function execute() {
      try {
        console.log("CALLED FIRST")
        let response = await run();
        setNetwork(response.data);
        if (response.data.length < 20) {
          setHasMore(false);
          console.log("FALSE HASMORE")
        }
      } catch (err) {
        let x = new CatchedWebError(err);
        setError(x.message);
        setHasMore(false);
      }
    }
    execute();
  }, [activeOrgId]);

  function fetchNext20() {
    async function execute() {
      const { run } = createRequest(services.networks.GET_NET, [activeOrgId, network.length, 20]);
      try {
        console.log("CALLED NEXT")
        let response = await run();
        if (response.data.length < 20) {
          setHasMore(false);
          console.log("FALSE HASMORE")
        }
        setNetwork(currNetwork => [...currNetwork, ...response.data]);
      } catch (err) {
        let x = new CatchedWebError(err);
        setError(x.message);
        setHasMore(false);
      }
    }
    execute();
  }

  return { activeOrgId, fetchNext20, network, setNetwork, hasMore, setHasMore, error, setError };
};

const NetworkSelector = (props) => {
  const { fetchNext20, network, hasMore, error, setError } = useNetworkSelector();

  return (
    <div id="NetworkSelector" data-testid="NetworkSelector">
      <AlertBox isOpen={error} color="danger" toggle={() => { setError(null); }}>{error}</AlertBox>
      <InfiniteScroll
        dataLength={network.length}
        next={fetchNext20}
        hasMore={hasMore}
        scrollThreshold={0.6}
        loader={<div style={{ minHeight: "40px" }}><Spinner size="sm" /> </div>}
        endMessage={<span>Showing {Math.max(network.length - props.networkList.length, 0)} result(s)</span>}
        scrollableTarget="NetworkSelector"
      >
        <ul className="networkScrollList">
          {
            network.map((net) => {
              if (!props.networkList.find(it => it.networkId === net.networkId)) {
                return (
                  <li key={net.networkId} className="listItem border-bottom" onClick={() => props.setSelectedNetwork && props.setSelectedNetwork(net)}>
                    <div className="d-flex align-items-center">
                      <MaterialSymbol value={net.networkTypeId === 2 ? "public" : "lock"} /> &nbsp; {net.networkName} &nbsp;
                      {props.selectedNetwork && props.selectedNetwork.networkId === net.networkId && <MaterialSymbol value="done" />}
                    </div>
                  </li>
                );
              }
              return <React.Fragment key={net.networkId} />
            })
          }
        </ul>
      </InfiniteScroll>
    </div>
  );
};

NetworkSelector.propTypes = {};

NetworkSelector.defaultProps = {};

export default NetworkSelector;
