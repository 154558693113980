import { createSlice } from "@reduxjs/toolkit"

const notificationInitial = {
    ticketNotification: {
        count: 0,
        show: false
    },
    infraOfflineCount: {
        count: 0,
        show: false
    }
}

const notificationSlice = createSlice({
    name: "notification",
    initialState: { ...notificationInitial },
    reducers: {
        setTicketNotification(state, action) {
            state.ticketNotification.count = action.payload;
        },
        setInfraOfflineAlarmCount(state, action) {
            state.infraOfflineCount.count = action.payload;
        },



        showTicketUpdateNotification(state, action) {
            state.ticketNotification.show = true
        },
        hideTicketUpdateNotification(state, action) {
            state.ticketNotification.show = false
        },
        showInfraOfflineNotification(state, action) {
            state.infraOfflineCount.show = true
        },
        hideInfraOfflineNotification(state, action) {
            state.infraOfflineCount.show = false
        }
    }
})


export const notificationReducer = notificationSlice.reducer;
const notificationAction = notificationSlice.actions;
export default notificationAction;