import PropTypes from "prop-types";
import "./AccessPointWidget.scss";
import { AnimatePresence, motion } from "framer-motion";
import { useSelector } from "react-redux";

const apWidgetOptionsVariants = {
  open: { width: 200 },
  close: { width: 110 }
};

/**
 * 
 * @param {{
 *  selectedAp: string,
 *  removeAp: (uuid: string) => void
 * }} props 
 * @returns 
 */
const AccessPointWidget = (props) => {
  return (
    <AnimatePresence>
      <motion.div
        className="AccessPointWidget d-flex align-items-center rounded-pill mx-50"
        animate={props.selectedAp === null ? "close" : "open"}
        variants={apWidgetOptionsVariants}
        transition={{ duration: 0.2 }}
      >
        <div
          title="Select an AP to see options"
          className="ap-widget-label rounded-pill px-75 h-100 d-flex align-items-center"
        >
          <span>Access&nbsp;Point</span>
        </div>
        {props.selectedAp !== null &&
          <motion.div
            className="ap-widget-options d-flex align-items-center mr-25 px-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.1, delay: 0.3 }}
          >
            <div
              title="Remove the selected layout from view"
              className="ap-widget-action rounded-pill"
              onClick={() => { props.removeAp(props.selectedAp) }}
            >Remove</div>
          </motion.div>
        }
      </motion.div>
    </AnimatePresence>
  );
};

AccessPointWidget.propTypes = {
  selectedAp: PropTypes.string,
  removeAp: PropTypes.func
};
AccessPointWidget.defaultProps = {};

export default AccessPointWidget;