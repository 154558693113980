import Service from "./Service";
import { D } from "../helpers";

const devices = {
  GET_ORG: new Service(
    (
      orgId,
      offset = 0,
      limit = 100,
      search = "",
      sort = { order: "ASC", orderBy: "mac" },
      filter = {},
      columns,
      status = "connected",
      connectionType,
      startDate,
      timezone
    ) =>
      D(
        `/userdevice/organization/${orgId}/clients?offset=${offset}&limit=${limit}&search=${search}&children=false&clientStatus=${status}&clientType=${connectionType}&order=${sort.order}&orderBy=${sort.orderBy}&startDate=${startDate}&timezoneOffset=${timezone}` +
          (columns != null ? `&columns=${columns}` : ``) +
          (!!filter?.source ? `&source=${filter.source}` : "") +
          (filter?.venues?.length ? `&venueIds=${filter.venues}` : ``) +
          (filter?.networks?.length ? `&ssid=${filter.networks}` : ``) +
          (filter?.bands?.length ? `&band=${filter.bands}` : ``) +
          (filter?.infra?.length ? `&infraIds=${filter.infra}` : ``) +
          (status=='rejected' && filter?.failureReason?.length ? `&failureReason=${filter.failureReason}` : ``)
      ),
    "GET"
  ),
  // DOWNLOAD_ORG_CLIENTS: new Service(
  //   (
  //     orgId,
  //     search = "",
  //     sort = { order: "ASC", orderBy: "mac" },
  //     filter = {},
  //     columns,
  //     status = "connected",
  //     connectionType,
  //     startDate,
  //     timezone
  //   ) =>
  //     D(
  //       `/userdevice/organization/${orgId}/clients/export?search=${search}&children=false&clientStatus=${status}&clientType=${connectionType}&order=${sort.order}&orderBy=${sort.orderBy}&startDate=${startDate}&timezoneOffset=${timezone}` +
  //         (columns != null ? `&columns=${columns}` : ``) +
  //         (!!filter?.source ? `&source=${filter.source}` : "") +
  //         (filter?.venues?.length ? `&venueIds=${filter.venues}` : ``) +
  //         (filter?.networks?.length ? `&ssid=${filter.networks}` : ``) +
  //         (filter?.bands?.length ? `&band=${filter.bands}` : ``) +
  //         (filter?.infra?.length ? `&infraIds=${filter.infra}` : ``)
  //     ),
  //   "GET"
  // ),
  UE_FILTER_COUNT_ORG_LEVEL: new Service(
    (
      orgId,
      search = "",
      columns = [],
      status,
      connectionType,
      timezone,
      startDate,
      filter = {}
    ) =>
      D(
        `/userdevice/organization/${orgId}/clients/count?search=${search}&clientStatus=${status}&clientType=${connectionType}&timezoneOffset=${timezone}&startDate=${startDate}` +
          (columns != null ? `&columns=${columns}` : ``) +
          (!!filter?.source ? `&source=${filter.source}` : "") +
          (filter?.bands?.length ? `&band=${filter.bands}` : ``) +
          (filter?.venues?.length ? `&venueIds=${filter.venues}` : ``) +
          (filter?.infra?.length ? `&infraIds=${filter.infra}` : ``) +
          (filter?.networks?.length ? `&ssid=${filter.networks}` : ``) +
          (filter?.infraIds?.length ? `&infraIds=${filter.infraIds}` : ``)
      ),
    "GET"
  ),
  GET_VENUE: new Service(
    (
      venueId,
      offset = 0,
      limit = 100,
      search = "",
      sort = { order: "ASC", orderBy: "mac" },
      filter = {},
      columns,
      status = "connected",
      connectionType,
      startDate,
      timezone
    ) =>
      D(
        `/userdevice/venue/${venueId}/clients?offset=${offset}&limit=${limit}&search=${search}&clientStatus=${status}&clientType=${connectionType}&startDate=${startDate}&order=${sort.order}&orderBy=${sort.orderBy}&timezoneOffset=${timezone}` +
          (columns != null ? `&columns=${columns}` : ``) +
          (!!filter?.source ? `&source=${filter.source}` : "") +
          (filter?.networks?.length ? `&ssid=${filter.networks}` : ``) +
          (filter?.bands?.length ? `&band=${filter.bands}` : ``) +
          (filter?.infra?.length ? `&infraIds=${filter.infra}` : ``) +
          (status == 'rejected' && filter?.failureReason?.length ? `&failureReason=${filter.failureReason}` : ``)
      ),
    "GET"
  ),
  DOWNLOAD_VENUE: new Service(
    (
      venueId,
      search = "",
      sort = { order: "ASC", orderBy: "mac" },
      filter = {},
      columns,
      status = "connected",
      connectionType,
      startDate,
      timezone
    ) =>
      D(
        `/userdevice/venue/${venueId}/clients/export?search=${search}&clientStatus=${status}&clientType=${connectionType}&startDate=${startDate}&order=${sort.order}&orderBy=${sort.orderBy}&timezoneOffset=${timezone}` +
          (columns != null ? `&columns=${columns}` : ``) +
          (!!filter?.source ? `&source=${filter.source}` : "") +
          (filter?.networks?.length ? `&ssid=${filter.networks}` : ``) +
          (filter?.bands?.length ? `&band=${filter.bands}` : ``) +
          (filter?.infra?.length ? `&infraIds=${filter.infra}` : ``)
      ),
    "GET"
  ),
  UE_FILTER_COUNT_VENUE_LEVEL: new Service(
    (
      venueId,
      search = "",
      columns = [],
      status,
      connectionType,
      timezone,
      startDate,
      filter = {}
    ) =>
      D(
        `/userdevice/venue/${venueId}/clients/count?search=${search}&clientStatus=${status}&clientType=${connectionType}&timezoneOffset=${timezone}&startDate=${startDate}` +
          (columns != null ? `&columns=${columns}` : ``) +
          (!!filter?.source ? `&source=${filter.source}` : "") +
          (filter?.bands?.length ? `&band=${filter.bands}` : ``) +
          (filter?.infra?.length ? `&infraIds=${filter.infra}` : ``) +
          (filter?.networks?.length ? `&ssid=${filter.networks}` : ``)
      ),
    "GET"
  ),
  GET_INFRA: new Service(
    (
      infraId,
      offset = 0,
      limit = 100,
      search = "",
      sort = { order: "ASC", orderBy: "mac" },
      filter = {},
      columns,
      status = "connected",
      connectionType,
      startDate,
      timezone
    ) =>
      D(
        `/userdevice/infrastructure/${infraId}/clients?offset=${offset}&limit=${limit}&search=${search}&clientStatus=${status}&clientType=${connectionType}&startDate=${startDate}&order=${sort.order}&orderBy=${sort.orderBy}&timezoneOffset=${timezone}` +
          (columns != null ? `&columns=${columns}` : ``) +
          (filter?.networks?.length ? `&ssid=${filter.networks}` : ``) +
          (filter?.bands?.length ? `&band=${filter.bands}` : ``) +
          (status == 'rejected' && filter?.failureReason?.length ? `&failureReason=${filter.failureReason}` : ``)
      ),
    "GET"
  ),
  DOWNLOAD_INFRA: new Service(
    (
      infraId,
      search = "",
      sort = { order: "ASC", orderBy: "mac" },
      filter = {},
      columns,
      status = "connected",
      connectionType,
      startDate,
      timezone
    ) =>
      D(
        `/userdevice/infrastructure/${infraId}/clients/export?search=${search}&clientStatus=${status}&clientType=${connectionType}&startDate=${startDate}&order=${sort.order}&orderBy=${sort.orderBy}&timezoneOffset=${timezone}` +
          (columns != null ? `&columns=${columns}` : ``) +
          (filter?.networks?.length ? `&ssid=${filter.networks}` : ``) +
          (filter?.bands?.length ? `&band=${filter.bands}` : ``)
      ),
    "GET"
  ),
  UE_FILTER_COUNT_INFRA_LEVEL: new Service(
    (
      infraId,
      search = "",
      columns = [],
      status,
      connectionType,
      timezone,
      startDate,
      filter = {}
    ) =>
      D(
        `/userdevice/infrastructure/${infraId}/clients/count?search=${search}&clientStatus=${status}&clientType=${connectionType}&timezoneOffset=${timezone}&startDate=${startDate}` +
          (columns != null ? `&columns=${columns}` : ``) +
          (filter?.bands?.length ? `&band=${filter.bands}` : ``) +
          (filter?.networks?.length ? `&ssid=${filter.networks}` : ``)
      ),
    "GET"
  ),
  // GET_VENUE : new Service((venueId,offset=0,limit=100,search="", sort={order:"ASC",orderBy:"mac"}, filter={}, startDate, timezone) =>
  //     D(`/userdevice/venue/${venueId}/ue-list?offset=${offset}&limit=${limit}&search=${search}&children=false&ue_status=connected&startDate=${startDate}&order=${sort.order}&orderBy=${sort.orderBy}&timezoneOffset=${timezone}`
  //         +(filter?.networks?.length ? `&SSIDs=${filter.networks}`:``)
  //         +(filter?.infra?.length ? `&macAddress=${filter.infra}`:``)
  //         +(filter?.bands?.length ? `&bandsType=${filter.bands}`:``)), "GET"),
  // GET_ORG: new Service((orgId,offset=0,limit=100,search="", sort={order:"ASC",orderBy:"mac"}, filter={}, status="connected", startDate, timezone) =>
  //     D(`/userdevice/organization/${orgId}/ue-list?offset=${offset}&limit=${limit}&search=${search}&children=false&ue_status=${status}&order=${sort.order}&orderBy=${sort.orderBy}&startDate=${startDate}&timezoneOffset=${timezone}`
  //         +(filter?.venues?.length ? `&venueIDs=${filter.venues}`:``)
  //         +(filter?.networks?.length ? `&SSIDs=${filter.networks}`:``)
  //         +(filter?.bands?.length ? `&bandsType=${filter.bands}`:``)
  //         +(filter?.infra?.length ? `&macAddress=${filter.infra}`:``)), "GET"),
  GET_DEVICE: new Service(
    (macAddress, orgId) => D(`/userdevice/${macAddress}/details?orgId=${orgId}`),
    "GET"
  ),
  GET_DEVICE_EVENTS: new Service(
    (macAddress, orgId, limit, offset, search, startDate, endDate, timezoneOffset, eventType, filter={}, sort) => D(`/userdevice/${macAddress}/events?mac_address=${macAddress}&orgId=${orgId}&limit=${limit}&offset=${offset}&order=${sort.order}&orderBy=${sort.orderBy}&startDate=${startDate}&endDate=${endDate}&timezoneOffset=${timezoneOffset}&eventType=${eventType}&search=${search}` 
    + (filter.priorities?.length?`&severity=${filter.priorities}` : ``)
    + (filter.event?.length?`&event=${filter.event}` : ``)
    ),
    "GET"
  ),
  GET_DEVICE_EVENTS_COUNT: new Service(
    (macAddress, orgId, search, startDate, endDate, timezoneOffset, eventType, filter={}, sort) => D(`/userdevice/${macAddress}/events/count?mac_address=${macAddress}&orgId=${orgId}&order=${sort.order}&orderBy=${sort.orderBy}&startDate=${startDate}&endDate=${endDate}&timezoneOffset=${timezoneOffset}&eventType=${eventType}&search=${search}` 
    + (filter?.priorities?.length?`&severity=${filter.priorities}` : ``)
    + (filter?.event?.length?`&event=${filter.event}` : ``)
    ),
    "GET"
  ),
  UPDATE_NICKNAME: new Service(
    (mac, org_id, client_name) =>
      D(`/userdevice/${mac}?org_id=${org_id}&client_name=${client_name}`),
    "PUT"
  ),
  DELETE_NICKNAME: new Service(
    (mac, org_id) => D(`/userdevice/${mac}?org_id=${org_id}`),
    "DELETE"
  ),
  DOWNLOAD_LOG_FILE: new Service(
    (macAddress, mac) => D(`/userdevice/ue-debug/${macAddress}?mac=${mac}`), 
    "GET"
  ),
};

export default devices;
