import "../NewDashboard/NDashboard.scss";

import ChartCard from "./ChartCard";
import DonutApexChartWrapper from "./DonutApexChartWrapper";
import DonutChartSkeleton from "./DonutChartSkeleton";

const ChartCardWithDonut = ({ title, Pill, labels, series, isLoading, AddAction, SearchAction, colors, labelStyle, forceFullWidth, disableExpand, hideDateRangeAndZoom }) => {
  return (
    <ChartCard title={title} Pill={Pill} AddAction={AddAction} SearchAction={SearchAction} disableExpand={disableExpand} hideDateRangeAndZoom={hideDateRangeAndZoom}>
      <div className="donut-chart">
        {
          !isLoading ?
            <DonutApexChartWrapper forceFullWidth={forceFullWidth} colors={colors} labels={labels} series={series} labelStyle={labelStyle} />
            : <DonutChartSkeleton legendCount={labels.length} />
        }
      </div>
    </ChartCard>
  );
};

ChartCardWithDonut.defaultProps = { title: "DONUT CARD", Pill: null, isLoading: true }

export default ChartCardWithDonut;