import store from "../store";
import { inviteSubOrgsActions } from "../slices";
import createRequest, { services } from "../../services";
import { CatchedWebError } from "../../configs";

function getInviteSubOrgs(orgId) {
  store.dispatch(inviteSubOrgsActions.setLoading(true));
  const { controller, run } = createRequest(services.invite.GET, [orgId, 0, 1000, ""]);
  run()
    .then(response => {
      store.dispatch(inviteSubOrgsActions.add(response.data));
    })
    .catch(err => {
      let x = new CatchedWebError(err);
      store.dispatch(inviteSubOrgsActions.setError(x.message));
    })
    .finally(() => {
      store.dispatch(inviteSubOrgsActions.setLoading(false));
    });
  return controller;
}

export default getInviteSubOrgs;