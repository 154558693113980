import PropTypes from "prop-types";

const AccessPointsIcon = ({ svgProps, color }) => (

  <svg width="32" height="32" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="wifi_access_point 1">
      <g id="Group 484954">
        <g id="Group">
          <path id="Vector" d="M9.67079 10.9252C9.57693 10.9252 9.48306 10.8906 9.40842 10.8221C9.253 10.6774 9.24454 10.4335 9.38919 10.2781C9.72619 9.91726 10.1263 9.63411 10.5795 9.43561C11.0311 9.23787 11.5112 9.1363 12.006 9.13477C12.503 9.15477 12.9823 9.23094 13.4347 9.42483C13.8895 9.62026 14.2911 9.90033 14.6304 10.2589C14.7758 10.4135 14.7697 10.6574 14.615 10.8028C14.4604 10.9498 14.2172 10.9421 14.0711 10.7875C13.8048 10.5059 13.4886 10.2858 13.1308 10.1319C12.7784 9.98035 12.4037 9.90341 12.0183 9.90341H12.0083C11.6189 9.90495 11.2427 9.98343 10.8872 10.1396C10.531 10.2958 10.2163 10.5182 9.9524 10.8013C9.87623 10.8821 9.77389 10.9236 9.67079 10.9236V10.9252Z" fill={color} />
          <path id="Vector_2" d="M8.2636 8.96928C8.16973 8.96928 8.07586 8.93542 8.00123 8.86617C7.84581 8.72152 7.83734 8.47762 7.98199 8.3222C8.49904 7.76746 9.1138 7.33274 9.80934 7.02805C10.5049 6.72414 11.242 6.56871 11.9991 6.56641H12.0176C12.77 6.56641 13.5017 6.71644 14.1927 7.01266C14.8882 7.3112 15.5068 7.7413 16.0293 8.29296C16.1747 8.44685 16.1685 8.69075 16.0139 8.83694C15.8592 8.98312 15.6153 8.97543 15.4699 8.82155C15.0206 8.34682 14.4889 7.97597 13.8895 7.71975C13.2948 7.46431 12.6639 7.33505 12.016 7.33505H12.0006C11.3489 7.33736 10.7149 7.47123 10.1163 7.73206C9.51774 7.99366 8.98838 8.36837 8.54443 8.8454C8.46826 8.92619 8.36593 8.96774 8.26283 8.96774L8.2636 8.96928Z" fill={color} />
          <path id="Vector_3" d="M6.38464 7.21921C6.29077 7.21921 6.19614 7.18459 6.12227 7.11534C5.96685 6.97069 5.95839 6.72756 6.10381 6.57214C6.86168 5.75887 7.76189 5.12103 8.78059 4.67708C9.79929 4.23159 10.8795 4.00385 11.9898 4H12.0198C13.1216 4 14.1926 4.22005 15.2052 4.654C16.2262 5.09256 17.1318 5.72425 17.895 6.53059C18.0404 6.68524 18.0343 6.92915 17.8796 7.07456C17.725 7.22075 17.4811 7.21306 17.3357 7.05918C16.6447 6.32901 15.8253 5.75733 14.9012 5.36109C13.9864 4.96792 13.0162 4.76864 12.019 4.76864H11.9929C10.9873 4.77249 10.0109 4.97792 9.08912 5.38109C8.16814 5.78272 7.35333 6.35978 6.66702 7.09611C6.59161 7.1769 6.48928 7.21844 6.38618 7.21844L6.38464 7.21921Z" fill={color} />
        </g>
        <path id="Vector_4" d="M13.5386 12.4667C14.8113 12.4667 15.8469 13.5023 15.8469 14.7749V17.8525C15.8469 19.1252 14.8113 20.1608 13.5386 20.1608H10.461C9.1884 20.1608 8.15277 19.1252 8.15277 17.8525V14.7749C8.15277 13.5023 9.1884 12.4667 10.461 12.4667H13.5386ZM13.5386 11.6973H10.461C8.76138 11.6973 7.38336 13.0753 7.38336 14.7749V17.8525C7.38336 19.5522 8.76138 20.9302 10.461 20.9302H13.5386C15.2383 20.9302 16.6163 19.5522 16.6163 17.8525V14.7749C16.6163 13.0753 15.2383 11.6973 13.5386 11.6973Z" fill={color} />
      </g>
    </g>
  </svg>


  // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none" {...svgProps}>
  //   <path d="M23.9432 19.9807C23.9734 19.9807 23.9983 20.0056 23.9983 20.0358V25.2589C23.9983 25.2891 23.9734 25.314 23.9432 25.314H8.05338C8.02315 25.314 7.99826 25.2891 7.99826 25.2589V20.0358C7.99826 20.0056 8.02315 19.9807 8.05338 19.9807H23.9432ZM23.9432 19.0918H8.05338C7.53249 19.0918 7.10938 19.514 7.10938 20.0358V25.2589C7.10938 25.7798 7.5316 26.2029 8.05338 26.2029H23.9432C24.464 26.2029 24.8872 25.7807 24.8872 25.2589V20.0358C24.8872 19.5149 24.4649 19.0918 23.9432 19.0918Z" fill={color}/>
  //   <path d="M12.3103 17.2979C12.2019 17.2979 12.0926 17.2588 12.0072 17.1788C11.8277 17.0117 11.8179 16.7308 11.985 16.5504C12.5023 15.9948 13.1174 15.5593 13.8134 15.2553C14.5094 14.9513 15.2472 14.7957 16.0072 14.793H16.0286C16.7797 14.793 17.5112 14.9433 18.2028 15.2393C18.9014 15.5388 19.5192 15.9699 20.0401 16.521C20.2081 16.6997 20.201 16.9806 20.0223 17.1495C19.8437 17.3184 19.5619 17.3104 19.3939 17.1317C18.9574 16.6695 18.4383 16.3077 17.8517 16.0561C17.2668 15.8055 16.6588 15.6766 16.0099 15.6819C15.3717 15.6837 14.753 15.8144 14.169 16.0695C13.585 16.3246 13.0686 16.6899 12.6348 17.1566C12.5477 17.2499 12.4286 17.2979 12.3095 17.2979H12.3103ZM10.0819 14.2028C9.97345 14.2028 9.86412 14.1637 9.77878 14.0837C9.59923 13.9166 9.58945 13.6348 9.75656 13.4553C10.5592 12.5939 11.5148 11.9175 12.5939 11.4455C13.673 10.9735 14.8179 10.7326 15.9957 10.7281H16.0268C17.193 10.7281 18.329 10.961 19.4037 11.4215C20.4863 11.8855 21.4454 12.5548 22.2552 13.4108C22.4232 13.5895 22.4161 13.8704 22.2374 14.0393C22.0588 14.2081 21.777 14.2001 21.609 14.0215C20.8837 13.2544 20.0232 12.6544 19.0526 12.2384C18.0899 11.8259 17.0721 11.617 16.0268 11.617H15.9992C14.9432 11.6206 13.9174 11.8375 12.9495 12.2597C11.9815 12.6828 11.1255 13.289 10.4063 14.0606C10.3183 14.1548 10.2001 14.2019 10.081 14.2019L10.0819 14.2028ZM7.10856 11.4313C7.00012 11.4313 6.89078 11.3921 6.80545 11.3122C6.62589 11.145 6.61612 10.8633 6.78323 10.6837C7.96812 9.41348 9.37523 8.41704 10.9663 7.72193C12.5583 7.02593 14.2446 6.67037 15.9815 6.66504C17.721 6.70326 19.4081 7.00282 21.0055 7.68726C22.6001 8.37082 24.0152 9.35659 25.209 10.6197C25.377 10.7984 25.3699 11.0793 25.1912 11.2481C25.0126 11.417 24.7317 11.409 24.5628 11.2304C23.4526 10.0562 22.137 9.13882 20.6543 8.50326C19.1699 7.86682 17.6037 7.55215 15.9841 7.55304C14.3708 7.55837 12.8019 7.88904 11.3219 8.53526C9.84189 9.18148 8.53434 10.1086 7.43301 11.289C7.34501 11.3833 7.22678 11.4304 7.10767 11.4304L7.10856 11.4313Z" fill={color}/>
  //   <path d="M7.1059 19.9797V25.313H6.21701V19.9797H7.1059ZM7.1059 19.0908H6.21701C5.72635 19.0908 5.32812 19.489 5.32812 19.9797V25.313C5.32812 25.8037 5.72635 26.2019 6.21701 26.2019H7.1059C7.59657 26.2019 7.99479 25.8037 7.99479 25.313V19.9797C7.99479 19.489 7.59657 19.0908 7.1059 19.0908Z" fill={color}/>
  //   <path d="M25.77 19.9797V25.313H24.8811V19.9797H25.77ZM25.77 19.0908H24.8811C24.3904 19.0908 23.9922 19.489 23.9922 19.9797V25.313C23.9922 25.8037 24.3904 26.2019 24.8811 26.2019H25.77C26.2606 26.2019 26.6589 25.8037 26.6589 25.313V19.9797C26.6589 19.489 26.2606 19.0908 25.77 19.0908Z" fill={color}/>
  //   <path d="M11.5495 21.759V23.5368H9.7717V21.759H11.5495ZM11.5495 20.8701H9.7717C9.28103 20.8701 8.88281 21.2683 8.88281 21.759V23.5368C8.88281 24.0275 9.28103 24.4257 9.7717 24.4257H11.5495C12.0401 24.4257 12.4384 24.0275 12.4384 23.5368V21.759C12.4384 21.2683 12.0401 20.8701 11.5495 20.8701Z" fill={color}/>
  //   <path d="M22.2205 21.759V23.5368H15.9983V21.759H22.2205ZM22.2205 20.8701H15.9983C15.5076 20.8701 15.1094 21.2683 15.1094 21.759V23.5368C15.1094 24.0275 15.5076 24.4257 15.9983 24.4257H22.2205C22.7112 24.4257 23.1094 24.0275 23.1094 23.5368V21.759C23.1094 21.2683 22.7112 20.8701 22.2205 20.8701Z" fill={color}/>
  //   <path d="M17.3273 24.4257C17.0819 24.4257 16.8828 24.2266 16.8828 23.9812V21.3146C16.8828 21.0692 17.0819 20.8701 17.3273 20.8701C17.5726 20.8701 17.7717 21.0692 17.7717 21.3146V23.9812C17.7717 24.2266 17.5726 24.4257 17.3273 24.4257Z" fill={color}/>
  //   <path d="M19.1085 24.4257C18.8632 24.4257 18.6641 24.2266 18.6641 23.9812V21.3146C18.6641 21.0692 18.8632 20.8701 19.1085 20.8701C19.3538 20.8701 19.553 21.0692 19.553 21.3146V23.9812C19.553 24.2266 19.3538 24.4257 19.1085 24.4257Z" fill={color}/>
  //   <path d="M20.8898 24.4257C20.6444 24.4257 20.4453 24.2266 20.4453 23.9812V21.3146C20.4453 21.0692 20.6444 20.8701 20.8898 20.8701C21.1351 20.8701 21.3342 21.0692 21.3342 21.3146V23.9812C21.3342 24.2266 21.1351 24.4257 20.8898 24.4257Z" fill={color}/>
  // </svg>
);

AccessPointsIcon.propTypes = {
  svgProps: PropTypes.object,
  color: PropTypes.string
}
AccessPointsIcon.defaultProps = {
  svgProps: {
    width: "32",
    height: "32",
  },
  color: "#323232"
}

export default AccessPointsIcon;