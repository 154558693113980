/***
 *
 * Controller class for user.
 * @file MoveInfraModal.js
 * @description MoveInfraModal component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React, { useState } from "react";
// import PropTypes from 'prop-types';
import "./MoveInfraModal.scss";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import VenueSelector from "../VenueSelector";
import createRequest, { services } from "../../services";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { identityActions } from "../../redux/slices";
import { make_custom_toast } from "../../helpers/toasts";
import InfraInfo from "../InfraInfo";
import { CatchedWebError } from "../../configs";

const MoveInfraModal = (props) => {
  const { infraIds, selectedVenue, setSelectedVenue, isOpen, setIsOpen, onSuccess, onCancel, orgId } = props;
  const [submitting, setSubmitting] = useState(false)
  const dispatch = useDispatch()
  const onMoveClick = () => {
    if(submitting) return;
    const UUID = uuidv4();
    dispatch(identityActions.setMoveUUID(UUID));
    const {controller , run} = createRequest(services.infra.MOVE, [selectedVenue?.venueId, UUID], Array.from(infraIds ?? {}))
    setSubmitting(true)
    run()
    .then(response => {
      dispatch(identityActions.setMoveUUID(UUID));
      make_custom_toast(
        'success',
        'Move Infrastructure',
        'Moving Infrastructure'
      )
      onSuccess()
      setIsOpen(false)
    })
    .catch(err => {
      make_custom_toast('error','Move Infrastructure', (new CatchedWebError(err)).message)
    })
    .finally(() => {
      setSubmitting(false);
    })
  }

  return (
    <Modal 
      isOpen={isOpen} 
      centered 
      className="MoveInfraModal" 
      data-testid="MoveInfraModal" 
      toggle={() => setIsOpen(prevState => submitting ? prevState : !prevState)}
    >
      <ModalHeader 
        className="bg-white move-infra-modal-header"
        toggle={() => setIsOpen(prevState => submitting ? prevState : !prevState)}
      >
        Move to Venue
        {infraIds?.size > 0 ? 
        ` (${infraIds?.size} Infrastructure Selected)`
        :''}
      </ModalHeader>
      <ModalBody>
        <div className="mb-50">Select Venue</div>
        <VenueSelector 
          selectedVenue={selectedVenue}
          setSelectedVenue={setSelectedVenue}
          // setVenueSelectorModal={(value) => { }}
          showCount
          noEndText
          orgId={orgId}
        />
      </ModalBody>
      {isOpen ? 
      <ModalFooter className={`${selectedVenue == null? 'invisible' : ''}`}>
        <InfraInfo 
          infraIds={infraIds}
          orgId={orgId}
        />
        <div className="d-flex justify-content-end buttons-div mt-1">
          <Button.Ripple color='primary' outline
            disabled={submitting}
            onClick={onCancel}
          >
            Cancel
          </Button.Ripple>
          <Button.Ripple 
            color='primary'
            onClick={onMoveClick}
            disabled={submitting}
          >
            {
              submitting
              ? <Spinner />
              : "Move"
            }
          </Button.Ripple>
        </div>
      </ModalFooter>
      :null}
      {/* {selectedVenue == null ? null :
      } */}
    </Modal>
  );
};

MoveInfraModal.propTypes = {};

MoveInfraModal.defaultProps = {};

export default MoveInfraModal;
