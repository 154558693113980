export const extractFileExtension = (fileString) => {
    if (!fileString) {
        return ''
    }
    const reverseString = fileString.split("").reverse().join("")
    const index = reverseString.indexOf('.')
    const extension = reverseString.substring(0, index).split("").reverse().join("")
    return extension
}
export const extractFileName = (fileString) => {
    if (!fileString) {
        return ''
    }
    return fileString.split('.').slice(0, -1).join('.');

}

export const stringOnlyWithNumbers = (value) => {
    const pattern = /^\d+$/
    return pattern.test(value)
}


export const extractAttachmentName = (url) => {
    const startindex = url.indexOf('ticket/') + 7
    const lastindex = url.indexOf('?')
    return (url.substring(startindex, lastindex));
}