/***
 *
 * Controller class for user.
 * @file DesignLayout.js
 * @description DesignLayout component
 * @author Rajinder Singh
 * @since 22 Jan 2024
 */

import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import "./DesignLayout.scss";
import Header from "../../_builder/Header";
import createRequest, { services } from "../../../../services";
import { useDispatch, useSelector } from "react-redux";
import LayoutListItem from "../LayoutListItem";
import { Button, Col, Row, Spinner } from "reactstrap";
import Editor from "../Editor";
import { FAKE_WALL_DATA, LAYOUT_TYPES } from "../constants";
import { breadcrumbActions } from "../../../../redux/slices";
import REDUX_WORKER from "../../../../redux/workers";
import { wre } from "../../../../services/wre.service";
import wreAction from "../../../../redux/slices/wre.slice";
import InfiniteScroll from "react-infinite-scroll-component";
import { SCROLL_MORE_TEXT } from "../../../../utility/constants";
import { CatchedWebError } from "../../../../configs";
import { ReactComponent as FloorplanIcon } from '../../../../assets/images/icons/floorplan-icon.svg';

const FLOORPLAN_LIMIT = 20;

const DesignLayout = () => {
  const activeVenue = useSelector(state => state.activeVenue.data);
  const venuecrumb = useSelector(store => store.breadcrumb.venuecrumb);
  const venueId = useSelector(state => state.activeVenue.data.venueId);
  const dispatch = useDispatch();
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const [loading, setLoading] = useState(false)
  const [editorState, setEditorState] = useState({ isOpen: false, layout: null });
  const [designLayoutList, setDesignLayoutList] = useState([])
  const [hasMore, setHasMore] = useState(true)


  // const getImageUrl = 


  const getFloorplanList = async (loading = false, offset = 0, limit = FLOORPLAN_LIMIT) => {
    setLoading(loading)
    const { run, context } = createRequest(services.wre.GET_FLOORPLAN_LIST, [activeVenue.venueId, offset, limit])
    run()
      .then(async res => {
        if (res.data) {
          if (res.data.length < FLOORPLAN_LIMIT) {
            setHasMore(false);
          }
          let tempFloorplanList = []
          let list = res.data
          // .sort((a, b) => {
          //   if (a.layoutJson)
          //     return -1
          //   else
          //     return 1
          //   return 0
          // })
          for (let i = 0; i < list.length; i++) {
            let floorplan = list[i]
            const { run, controller } = createRequest(wre.GET_FLOORPLAN_IMAGE, [activeOrgId, floorplan?.id]);
            const response = await run()
            if (floorplan.layoutJson == null) {
              floorplan.layoutType = 1
              floorplan.isImageDesignLayout = true
              floorplan.layoutJson = FAKE_WALL_DATA
              floorplan.imageLayoutLink = response.data
            }
            tempFloorplanList.push(floorplan)
          }
          setDesignLayoutList(tempFloorplanList)
        }
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getFloorplanList(true)
  }, [])

  useEffect(() => {
    if (venuecrumb.venueId !== activeVenue.venueId)
      REDUX_WORKER.getVenueParent(venueId, activeOrgId);

    dispatch(breadcrumbActions.setData([...venuecrumb.parentPath,
    {
      path: `/organization/${activeOrgId}/venues/${activeVenue?.venueId}`,
      text: activeVenue?.venueName,
      active: false,
    },
    {
      path: `/organization/${activeOrgId}/venues/${activeVenue?.venueId}/design/`,
      text: "Design",
      active: true,
    }
    ]))

  }, [activeVenue, venuecrumb]);

  return (
    <div className="DesignLayout" data-testid="DesignLayout">
      <Header heading="Design" />
      <div className="bg-white rounded py-2 px-1 shadow container">
        <div className="d-flex justify-content-between mb-1">
          <span className="ml-1">
            <FloorplanIcon width={24} height={24} className="mr-1" />
            <span className="fw-600">Floor Plans</span>
          </span>
          {
            designLayoutList.length > 0 &&
            <Button color="primary small-add-button" onClick={() => { setEditorState({ isOpen: true, layout: null, blank: true }) }} >Add</Button>
          }
        </div>
        {
          loading ? <div className="center-div"><Spinner color="primary" /></div>
            :
            <InfiniteScroll
              dataLength={designLayoutList ?? 0}
              next={() => {
                const { run } = createRequest(services.wre.GET_FLOORPLAN_LIST, [activeVenue.venueId, designLayoutList.length, FLOORPLAN_LIMIT]);
                run()
                  .then(async response => {
                    if (response.data.length < FLOORPLAN_LIMIT) {
                      setHasMore(false);
                    }
                    let tempFloorplanList = []
                    let list = response.data
                    // .sort((a, b) => {
                    //   if (a.layoutJson)
                    //     return -1
                    //   else
                    //     return 1
                    //   return 0
                    // })


                    for (let i = 0; i < list.length; i++) {
                      let floorplan = list[i]
                      const { run, controller } = createRequest(wre.GET_FLOORPLAN_IMAGE, [activeOrgId, floorplan?.id]);
                      const response = await run()
                      if (floorplan.layoutJson == null) {
                        floorplan.layoutType = 1
                        floorplan.isImageDesignLayout = true
                        floorplan.layoutJson = FAKE_WALL_DATA
                        floorplan.imageLayoutLink = response.data
                      }
                      tempFloorplanList.push(floorplan)
                    }
                    setDesignLayoutList(designList => [...designList, ...tempFloorplanList])
                  })
                  .catch(err => {
                    let x = new CatchedWebError(err);
                    // make_custom_toast()
                    setHasMore(false);
                  })
              }}
              hasMore={hasMore}
              loader={<div>{SCROLL_MORE_TEXT}</div>}
              // endMessage={<span>{`Showing ${designLayoutList.length} results.`}</span>}
              scrollableTarget="mainBod"
              scrollThreshold={0.7}
            >
              <div id="floorplan-list">
                <Row className="p-0 m-0" >
                  {

                    designLayoutList.length > 0 ? designLayoutList.map((designLayout, index) =>
                      <Col key={index + 'designlayout'} md={3}>
                        <LayoutListItem
                          layout={designLayout}
                          hideDropdown={true}
                          onDelete={() => { }}
                          isDesignLayout={true}
                          promoteToDesign={() => {

                          }}
                          onHeaderClick={() => {
                            let tempLayout = { ...designLayout }
                            dispatch(wreAction.setAnalysisScore({})) //resetting analysis score redux value
                            if (designLayout.layoutJson.components[0].walls[0].id == 'FAKE_WALL') {
                              dispatch(wreAction.setCurrentInsertedLayout(LAYOUT_TYPES.IMAGE))
                              // console.log('rdebug trigger 1...') //cancomefromapi
                              tempLayout.layoutType = LAYOUT_TYPES.IMAGE
                            }
                            else if (designLayout.layoutJson) {
                              dispatch(wreAction.setCurrentInsertedLayout(LAYOUT_TYPES.SINGLE_LIDAR_SCAN))
                              // console.log('rdebug trigger 2...') //cancomefromapi
                              tempLayout.layoutType = LAYOUT_TYPES.SINGLE_LIDAR_SCAN
                            }
                            setEditorState({ isOpen: true, layout: tempLayout, blank: false })

                          }}
                        />
                      </Col>

                    ) : <Col md={3}>
                      <LayoutListItem
                        layout={{}}
                        hideDropdown={true}
                        onDelete={() => { }}
                        isDesignLayout={true}
                        promoteToDesign={() => {

                        }}
                        onHeaderClick={() => {

                          setEditorState({ isOpen: true, layout: null, blank: true })
                        }}
                      />
                    </Col>
                  }


                </Row>
              </div>
            </InfiniteScroll>
        }
        {editorState.isOpen &&
          <Editor
            editorState={editorState}
            setEditorState={setEditorState}
            isDesignLayout={true}
            afterSaveSuccess={() => {
              getFloorplanList()
            }}
          />
        }
      </div>
    </div >
  );
};

DesignLayout.propTypes = {};

DesignLayout.defaultProps = {};

export default DesignLayout;
