export const LAYOUT_LIST_OPTIONS = [
  { value: "all", label: "All Layouts" },
  { value: "Single LiDAR Scan", label: "Single LiDAR Scan" },
  { value: "Merged LiDAR Scans", label: "Merged LiDAR Scans" },
  { value: "Image", label: "Image" }
];

export const LAYOUT_TYPE = ["-", "Image", "Single LiDAR Scan", "Merged LiDAR Scans", "Design"]
export const LAYOUT_TYPES = {
  'NONE': 0,
  'IMAGE': 1,
  "SINGLE_LIDAR_SCAN": 2,
  "MERGED_LIDAR_SCAN": 3,
  "DESIGN": 4
}

export const FETCH_LIMIT_LAYOUTS = 30;

export const EDITORS = {
  SnappingEditor: 1,
  WallAnnotationsEditor: 2,
  AccessPointsEditor: 3,
  AnalysisEditor: 4,
  DrawLines: 5,
  CableDrop: 6,
  SwitchEditor: 7
};

export const INFRA_TYPE_GROUPS = ["Ceiling", "Wall Plate", "Outdoor"];

export const CANVAS_FEATURES = {
  WALL_COLORS: 1,
  ZOOM_WIDGETS: 2,
  AP_VIEW_AND_WIDGETS: 3,
  LAYOUT_MANIPULATION_WIDGETS: 4,
  NO_AXIS: 5,
  SHOW_LAYOUT_IMAGE: 6,
  DRAW_LINES: 7,
  SHOW_LINES: 8,
  MOVE_CABLE_DROP: 9,
  SHOW_CABLE_DROP: 10,
  SHOW_FOOTER: 11,
  SHOW_AP: 12,
  SWITCH: 13
};

export const CANVAS_EVENTS = {
  WALL_EVENTS: 1,
  LAYOUT_EVENTS: 2,
  AP_EVENTS: 3
};

export const WALL_COLORS = {
  "brick wall": "#CB553D",
  "concrete wall": "#95D77F",
  "dry wall": "#918179",
  "steel wall": "#9FA4A9",
  "wooden wall": "#E39645"
}
export const FAKE_WALL_DATA = {
  dimensions: {
    length: 0.0,
    width: 0.0,
    height: 0.0,
    area: 10.0
  },
  components: [{
    id: "FAKE_COMPONENT",
    walls: [
      {
        id: "FAKE_WALL",
        loc: [
          0.0,
          0.0,
          1,
          1,
        ],
        material: "steel wall"
      }
    ]
  }]
}

export const COLORS = {
  PRIMARY: "#5279CE",
  CABLE_DROP_COLOR: "#acacac",
  AP_COLOR: "#ffcc18"
}

export const SUPPORTED_IMAGE_TYPES = ["image/jpeg", "image/svg+xml", "image/png", "image/webp"]