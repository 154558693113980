import { createSlice } from "@reduxjs/toolkit"

const traceRouteInitialData = {

    data: {}

}

const traceRouteSlice = createSlice({
    name: "traceRoute",
    initialState: { ...traceRouteInitialData },
    reducers: {
        setTraceRouteData(state, action) {
            state.data = action.payload;
        }
    }
})


export const traceRouteReducer = traceRouteSlice.reducer;
const traceRouteAction = traceRouteSlice.actions;
export default traceRouteAction;