import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ChartCardWithProgress from "./ChartCardWithProgress";
import Tasks from "../../../assets/images/icons/TasksIcon.svg"
import { useEffect, useState } from "react";

const VenueTasksCard = ({ series, trend, isLoading, labelStyle, total, main, setMain, height, className, onMetricClick, onTotalClick }) => {
  const activeOrgId = useSelector(state => state.activeOrg.data.orgId);
  const permissions = useSelector(store => store?.rbac?.permissions);
  const navigate = useNavigate();
  const [mainColor, setMainColor] = useState('')
  useEffect(() => {
    //  Series = [ActiveAlarms, Online, Offline,Pending]
    // main = [0,1,2,3]
    let mainIndex = main || 0 // if main==null use 0

    if ((mainIndex == 0) && series[mainIndex] > 0 && total > 0) { // for Active Alarms and offline color
      setMainColor('')
    }
    else if (mainIndex == 1 && series[mainIndex] > 0 && total > 0) { // for online color
      setMainColor('text-success')
    }
    else {
      setMainColor('')
    }

  }, [series, main, total])
  return <ChartCardWithProgress className={className ?? ""} mainColor={mainColor} icon={Tasks} labelStyle={labelStyle} height={height}
    trend={trend} main={main ?? 0} setMain={setMain} isLoading={isLoading} title="Tasks" totalVal={total}
    colors={["#BFBFBF", "#28A745"]}
    labels={["Pending Tasks", "Completed Tasks"]} series={series}
    progressBar={true}
    onMetricClick={onMetricClick} onTotalClick={onTotalClick}
    AddAction={
      permissions?.manageVenue?.create
        ? () => {
          navigate(`/organization/${activeOrgId}/venues/add`);
        }
        : null
    }
    SearchAction={
      permissions?.manageVenue?.view
        ? () => {
          navigate(`/organization/${activeOrgId}/venues`);
        }
        : null
    }
  />
}

export default VenueTasksCard;