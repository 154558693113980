/***
 *
 * Controller class for user.
 * @file SidePanel.js
 * @description SidePanel component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React, { useEffect } from "react";
// import PropTypes from 'prop-types';
import "./SidePanel.scss";

const SidePanel = (props) => {
  const {isOpen, setIsOpen, children} = props;

  useEffect(() => {
    if(isOpen){
      document.getElementById("topNav").style.zIndex = 39;
    }
    else{
      document.getElementById("topNav").style.zIndex = 41;
    }
  }, [isOpen])
  
  
  return (
    <div className="SidePanel" role="dialog" data-testid="SidePanel">
      <div className={`innerDiv ${isOpen ? "move-in":""}`} role="dialog">
        {children}
        </div>
      {isOpen ? (
      <div className='modal-backdrop fade show SidePanelBackDrop'
        onClick={() => { 
          setIsOpen(false)
      }}>
      </div>
      ) : null}
    </div>
  );
};

SidePanel.propTypes = {};

SidePanel.defaultProps = {};

export default SidePanel;
